import { userConstants } from '../_constants';

export function taskcreate(state = {}, action) {
    switch (action.type) {
 
            case userConstants.CREATE_TASK_REQUEST:
                return {
                  loading: true
                };
              case userConstants.CREATE_TASK_SUCCESS:
                return {
                  list: action.task
                };
              case userConstants.CREATE_TASK_FAILURE:
                return { 
                  error: action.error
                };
                
                case userConstants.GET_TASK_REQUEST:
                  return{
                   tasks:action.tasklist
                 };
                 case userConstants.GET_TASK_SUCCESS:
                    return{
                      tasks:action.taskidlist
                   };
                 case userConstants.GET_TASK_FAILURE:
                       return{
                         error: action.error
                   };

                   case userConstants.UPDATE_TASK_REQUEST:
                    return {
                      loading: true
                    };
                  case userConstants.UPDATE_TASK_SUCCESS:
                    return {
                      list: action.task
                    };
                  case userConstants.UPDATE_TASK_FAILURE:
                    return { 
                      error: action.error
                    };
              case userConstants.DELETE_TASK_SUCCESS:
                  return{
                    task:action.user
                };

                case userConstants.UPDATE_TASKSTATUS_SUCCESS:
                  return{
                    task:action.task
                };

      default:
      return state
    }

}


export function tasklist(state = {}, action) {
  switch (action.type) {
  case userConstants.LIST_TASK_REQUEST:
    return{
     list:action.list
   };
   case userConstants.LIST_TASK_SUCCESS:
      return{
        
       list:action.list
     };
     case userConstants.LIST_TASK_FAILURE:
         return{
           error: action.error
       };
       default:
      return state
}
}

export function alluserlist(state = {}, action) {
  switch (action.type) {
  case userConstants.GET_ALLUSERS_REQUEST:
   
    return{
     list:action.list
   };
   case userConstants.GET_ALLUSERS_SUCCESS:
     
      return{
        
       list:action.list
     };
     case userConstants.GET_ALLUSERS_FAILURE:
         return{
           error: action.error
       };
       default:
      return state
}
}
import React, { Component } from 'react';
import Select, { components } from "react-select";
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import {withRouter} from 'react-router-dom'
import { userActions } from '../../_actions';
import { loadingButton, removeLoadingButton } from '../../_functions';

class SpecialRow extends Component{
    constructor(props){
        super(props);
        this.state = { _specialities: [] };
    }
    
    initialize(){
        const { dispatch, ticket_id, isLastClick, jsUcfirst, unit, data } = this.props;
        if(data){
            if(this.refs && this.refs[`details_${unit}`])
                this.refs[`details_${unit}`].value = data.details;
            if(this.refs && this.refs[`cust_paid_${unit}`])
                this.refs[`cust_paid_${unit}`].value = data.customer_paid;
            if(this.refs && this.refs[`eroute_paid_${unit}`])
                this.refs[`eroute_paid_${unit}`].value = data.eroute_paid;
            // if(this.refs && this.refs[`cr_dr_${unit}`]){
            //     this.refs[`cr_dr_${unit}`].checked = false;
            //     if(data.paid_status === "cr"){
            //         this.refs[`cr_dr_${unit}`].checked = true;
            //     }
            // }
            if(this.refs && this.refs[`id_${unit}`])
                this.refs[`id_${unit}`].value = data.id;
            if(this.refs && this.refs[`remarks_${unit}`])
                this.refs[`remarks_${unit}`].value = data.remarks;
            if(this.refs && this.refs[`paid_by_${unit}`]){
                let tempSelectVal = { label: jsUcfirst(data.paid_by), value: data.paid_by.toLowerCase() };
                if(data.paid_by_vendor){
                    tempSelectVal = { label: data.paid_by_vendor.name, value: data.paid_by_vendor.id }
                }
                this.refs[`paid_by_${unit}`].select.setValue(tempSelectVal);
            }
            let selectedObj = this.state.selectedObj || {};
            selectedObj[unit] = data.paid_by;
            this.setState({ selectedObj: selectedObj });
        }
    }

    componentDidMount(){
        this.initialize();
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const { _specialities } = this.state;
    //     if(prevState._specialities != _specialities){

    //     }
    // }

    saveClick(target, unit){
        const { notify, ticket_id, validateTicketCreationForm } = this.props;
        let saveButton = document.querySelector(target);

        if(!ticket_id){
            validateTicketCreationForm(saveButton);
        }else{
            let tr = saveButton.parentNode.parentNode;
            loadingButton(saveButton);
            tr.style.pointerEvents = 'none';
            this.removeInvalid(this.refs[`details_${unit}`]);
            this.removeInvalid(this.refs[`cust_paid_${unit}`]);
            this.removeInvalid(this.refs[`eroute_paid_${unit}`]);
            this.removeInvalid(document.querySelector(`.paid_by${unit}`));
            let custPaid = this.refs[`cust_paid_${unit}`].value.trim();
            let eRoutePaid = this.refs[`eroute_paid_${unit}`].value.trim();
            let numberRegexp = /^(?:\d*\.\d{1,2}|\d+)$/;

            if(this.refs[`details_${unit}`].value.trim() != "" && this.refs[`cust_paid_${unit}`].value.trim() != "" && this.refs[`eroute_paid_${unit}`].value.trim() != "" && this.state.selectedObj && this.state.selectedObj[unit]){
                if(!numberRegexp.test(custPaid) || !numberRegexp.test(eRoutePaid)){
                    if(!numberRegexp.test(custPaid))
                        this.addInvalid(this.refs[`cust_paid_${unit}`]);
                    if(!numberRegexp.test(eRoutePaid))
                        this.addInvalid(this.refs[`eroute_paid_${unit}`]);
                }else{
                    const { dispatch } = this.props;
                    let specialServiceData = {
                        "ticket_id": ticket_id,
                        "details": this.refs[`details_${unit}`].value,
                        "customer_paid": this.refs[`cust_paid_${unit}`].value,
                        "eroute_paid": this.refs[`eroute_paid_${unit}`].value,
                        "paid_by": this.state.selectedObj[unit],
                        "paid_status": "cr",
                        "remarks": this.refs[`remarks_${unit}`].value
                    };
                    if(this.refs[`id_${unit}`].value){
                        specialServiceData["id"] = this.refs[`id_${unit}`].value;
                        dispatch(userActions.updateSpecial(specialServiceData)).then(() => {
                            removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
                            notify("tc", "", `Special Service created successfully`);
                        });
                    }else{
                        dispatch(userActions.createSpecial(specialServiceData)).then(() => {
                            const { special } = this.props;
                            const { create } = special;
                            const { data } = create;
                            if(this.refs && this.refs[`id_${unit}`])
                                this.refs[`id_${unit}`].value = data.id;
                            removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
                            notify("tc", "", `Special Service updated successfully`);
                        });
                    }
                }
            } else if(this.refs[`details_${unit}`].value.trim() === "" || this.refs[`cust_paid_${unit}`].value.trim() === "" || this.refs[`eroute_paid_${unit}`].value.trim() === "" || !this.state.selectedObj || !this.state.selectedObj[unit]){
                if(this.refs[`details_${unit}`].value.trim() === ""){
                    this.addInvalid(this.refs[`details_${unit}`]);
                }
                if(this.refs[`cust_paid_${unit}`].value.trim() === ""){
                    this.addInvalid(this.refs[`cust_paid_${unit}`]);
                }
                if(this.refs[`eroute_paid_${unit}`].value.trim() === ""){
                    this.addInvalid(this.refs[`eroute_paid_${unit}`]);
                }
                if(!this.state.selectedObj || !this.state.selectedObj[unit]){
                    this.addInvalid(document.querySelector(`.paid_by${unit}`));
                }
                removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
            }
            
            if(!numberRegexp.test(custPaid) || !numberRegexp.test(eRoutePaid)){
                if(!numberRegexp.test(custPaid))
                    this.addInvalid(this.refs[`cust_paid_${unit}`]);
                if(!numberRegexp.test(eRoutePaid))
                    this.addInvalid(this.refs[`eroute_paid_${unit}`]);
                removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
            }
            tr.style.pointerEvents = 'unset';
        }
    }

    addInvalid(ele){
        ele.classList.remove('is-valid');
        ele.classList.add('is-invalid');
    }

    removeInvalid(ele){
        ele.classList.remove('is-invalid');
        ele.classList.add('is-valid');
    }

    deleteClick(target, elem, isLast, unit){
        let deleteButton = document.querySelector(target);
        
        if(!isLast){
            deleteButton.parentNode.parentNode.style.pointerEvents = 'none';
            loadingButton(deleteButton);
        }else{
            if(deleteButton.nextSibling){
                deleteButton.nextSibling.click();
                // deleteButton.click();
                elem.parentNode.removeChild(elem);
            }
        }
        const { dispatch, notify } = this.props;
        if(this.refs && this.refs[`id_${unit}`] && this.refs[`id_${unit}`].value){
            let specialServiceData = { id: this.refs[`id_${unit}`].value };
            dispatch(userActions.deleteSpecial(specialServiceData)).then(() => {
                notify("tc", "", `Special Service deleted successfully`);
                if(elem && !isLast) {
                    elem.parentNode.removeChild(elem);
                }
            });
        }else if(elem && !isLast) {
            notify("tc", "", `Special Service deleted successfully`);
            elem.parentNode.removeChild(elem);
        }
    }

    handleChange(event, isReq, regex){
        let { unit, setStater, specialState } = this.props;

        let elem = event.target;
        let value = elem.value;
        this.removeInvalid(elem);
        
        specialState = (specialState) ? specialState : {};
        specialState.ticket = (specialState.ticket) ? specialState.ticket : {};
        specialState.ticket.data = (specialState.ticket.data) ? specialState.ticket.data : [];
        specialState.ticket.data[unit] = (specialState.ticket.data[unit]) ? specialState.ticket.data[unit] : {};
        specialState.ticket.data[unit].customer_paid = value;
        setStater('specialState', specialState);

        if(!value && isReq){
            this.addInvalid(elem);
        }else if(regex && value){
            var regexp = new RegExp(regex);
            if(!regexp.test(value)){
                this.addInvalid(elem);
            }
        }
    }

    handleSelectChange(value, unit){
        let selected = value && value.value;
        let selectedObj = this.state.selectedObj || {};
        selectedObj[unit] = selected;
        this.setState({ selectedObj: selectedObj });
        this.removeInvalid(document.querySelector(`.paid_by${unit}`));
        if(!selected)
            this.addInvalid(document.querySelector(`.paid_by${unit}`));
    }

    render() {
        const { Option } = components;
        const IconOption = (props) => {
            let iconimg = '';
            let bagde = '';
            let imgSrc = require("assets/img/theme/team-1.jpg");
            if(props.data.badge){
                iconimg = (<div className="col-2 p-0"><img src={imgSrc} className="avatar avatar-xs rounded"/> </div>);
                bagde = (<div className="col-2 p-0 w-100 d-flex text-right align-items-center"><span className="badge badge-dark badge-pill text-white">{props.data.badge}</span></div>);
            }
            return (
                <Option {...props} key={`${props.data.label}_${props.data.id}`} selected={`${props.data.selected}`} className="d-flex align-items-center">
                <div className="row col p-0 m-0">
                    {iconimg}
                    <div className="col px-0 mx-1 d-flex align-items-center">{props.data.label}</div>
                    {bagde}
                </div>
                </Option>
            );
        }

        const { unit, optiondata, isLast, isLastClick } = this.props;
        
        return (
            <tr className="special_tr" ref={`tr_${unit}`}>
                <td className="px-1 py-1"> <input onChange={(e) => this.handleChange(e, 1, '')} type="hidden" ref={`id_${unit}`} className={`id${unit} form-control form-control-sm`}/> <input onChange={(e) => this.handleChange(e, 1, /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _,.&]*)$/)} type="text" ref={`details_${unit}`} className={`details${unit} form-control form-control-sm`}/> </td>
                <td className="px-1 py-1"> <input onChange={(e) => this.handleChange(e, 1, /^(?:\d*\.\d{1,2}|\d+)$/)} type="text" ref={`cust_paid_${unit}`} className={`cust_paid${unit} form-control form-control-sm`}/> </td>
                <td className="px-1 py-1"> <input onChange={(e) => this.handleChange(e, 1, /^(?:\d*\.\d{1,2}|\d+)$/)} type="text" ref={`eroute_paid_${unit}`} className={`eroute_paid${unit} form-control form-control-sm`}/> </td>
                <td className="px-1 py-1">

                <Select name={`paid_by${unit}`} onChange={(value) => this.handleSelectChange(value, unit)} ref={`paid_by_${unit}`} className={`paid_by${unit} form-control form-control-sm p-0 min-w-120-px`}  isClearable placeholder={`Select Paid By`} components={{ Option: IconOption }} isSearchable isMulti={false} options={optiondata}/>
                </td>
                <td className="px-1 py-1"> <input onChange={(e) => this.handleChange(e, 0, '')} ref={`remarks_${unit}`} type="text" className={`remarks${unit} form-control form-control-sm`}/> </td>
                {/* <td className="px-1 py-1">
                <label className="custom-toggle">
                    <input onChange={(e) => this.handleChange(e, 0, '')} ref={`cr_dr_${unit}`} type="checkbox" name={`cr_dr${unit}`} />
                    <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="DR"
                    data-label-on="CR"
                    />
                </label>
                </td> */}
                <td className="px-1 py-1 text-right">
                <button className={`btn btn-success btn-sm mx-0 btn_sv_${unit} specialSaveBtn`} onClick={(e) => this.saveClick(`.btn_sv_${unit}`, unit)}><i className="fas fa-save"></i></button>
                <button className={`btn btn-danger btn-sm mx-1  btn_dl_${unit}`} onClick={() => this.deleteClick(`.btn_dl_${unit}`, this.refs[`tr_${unit}`], isLast, unit)}><i className="fas fa-trash-alt"></i></button>
                {(isLast)? (<button className="btn btn-primary btn-sm mx-0" onClick={() => isLastClick()}><i className="fas fa-plus"></i></button>) : ''}
                </td>
            </tr>
        );
    }
}

function mapStateToProps(state) {
    const { special } = state;
    return { special };
}

const SpecialRowSectionPage = connect(mapStateToProps)(SpecialRow);
export { SpecialRowSectionPage as SpecialRow };
import React, { Component } from 'react';
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import {withRouter} from 'react-router-dom'
import { userActions, placesActions } from '../../_actions';
import { loadingButton, removeLoadingButton } from '../../_functions';
import moment from "moment";
import ReactDatetime from "react-datetime";
import classnames from "classnames";

class FoodRow extends Component{
    constructor(props){
        super(props);
        this.state = {
          states : {}
        };
    }

    componentDidMount(){
      const { unit, jsUcfirst, oStartData, oEndData, expenses_data } = this.props;
      const { states } = this.state;
      if(expenses_data){
        Object.keys(expenses_data).forEach(key => {
          states[`startDate_${key}`] = (expenses_data[key]['expenses']['start_date']) ? expenses_data[key]['expenses']['start_date'] : "";
          states[`endDate_${key}`] = (expenses_data[key]['expenses']['end_date']) ? expenses_data[key]['expenses']['end_date'] : "";
          states[`places_food_${key}`] = (expenses_data[key]['expenses']['destination'] && expenses_data[key]['expenses']['destination'].length > 0) ? { value: expenses_data[key]['expenses']['destination'][0]['id'], label: expenses_data[key]['expenses']['destination'][0]['name'] } : "";
          states[`vendor_food_${key}`] = (expenses_data[key]['expenses']['vendor'] && expenses_data[key]['expenses']['vendor'].length > 0) ? { value: expenses_data[key]['expenses']['vendor'][0]['id'], label: expenses_data[key]['expenses']['vendor'][0]['name'] } : "";
          
          let amtPaid = document.querySelector(`.amount_paid_${key}`);
          if(amtPaid)
            amtPaid.value = (expenses_data[key]['expenses']['amount']) ? expenses_data[key]['expenses']['amount'] : "";
        });
      }
      
      this.setState({ states: states });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { expenses_data } = this.props;
      const { states } = this.state;
      if(expenses_data !== prevProps.expenses_data){
        Object.keys(expenses_data).forEach(key => {
          states[`startDate_${key}`] = (expenses_data[key]['expenses']['start_date']) ? expenses_data[key]['expenses']['start_date'] : "";
          states[`endDate_${key}`] = (expenses_data[key]['expenses']['end_date']) ? expenses_data[key]['expenses']['end_date'] : "";
          states[`places_food_${key}`] = (expenses_data[key]['expenses']['destination'] && expenses_data[key]['expenses']['destination'].length > 0) ? { value: expenses_data[key]['expenses']['destination'][0]['id'], label: expenses_data[key]['expenses']['destination'][0]['name'] } : "";
          states[`vendor_food_${key}`] = (expenses_data[key]['expenses']['vendor'] && expenses_data[key]['expenses']['vendor'].length > 0) ? { value: expenses_data[key]['expenses']['vendor'][0]['id'], label: expenses_data[key]['expenses']['vendor'][0]['name'] } : "";
          
          let amtPaid = document.querySelector(`.amount_paid_${key}`);
          if(amtPaid)
            amtPaid.value = (expenses_data[key]['expenses']['amount']) ? expenses_data[key]['expenses']['amount'] : "";
        });
        this.setState({ states: states });
      }
    }

    validEndData(current, unit) {
      const { states } = this.state;
      const { oStartData, oEndData } = this.props;
      let prevUnit = unit;
      prevUnit = parseInt(unit) - 1;

      if(!states[`startDate_${unit}`] && prevUnit < 0)
        return current.isAfter( moment(oStartData).subtract(1, 'day')) && current.isBefore( moment(oEndData).add( 1, 'day' ) );
      else if(!states[`startDate_${unit}`] && prevUnit >= 0 && unit > 0){
        let prevEndtDate = document.querySelector(`.end_date_food_${prevUnit}`);
        if(!prevEndtDate){
          return current.isAfter( moment(oStartData).subtract(1, 'day') ) && current.isBefore( moment(oEndData).add( 1, 'day' ) )
        }
        prevEndtDate = prevEndtDate.value;
        return current.isAfter(moment(prevEndtDate).add( 1, 'day' )) && current.isBefore( moment(oEndData).add( 1, 'day' ) );
      } else if(states[`startDate_${unit}`] && prevUnit >= 0 && unit > 0){
        let prevEndtDate = document.querySelector(`.end_date_food_${prevUnit}`);
        if(!prevEndtDate){
          return current.isAfter( moment(oStartData).subtract(1, 'day')) && current.isBefore( moment(oEndData).add( 1, 'day' ) )
        }
        prevEndtDate = prevEndtDate.value;
        var today = moment(states[`startDate_${unit}`]);
        return current.isAfter( moment(today._d).subtract(1, 'day') ) && current.isAfter(moment(prevEndtDate)) && current.isBefore( moment(oEndData).add( 1, 'day' ) );
      }
      
      var today = moment(states[`startDate_${unit}`]);
      return current.isAfter( moment(today._d).subtract(1, 'day') ) && current.isAfter( moment(oStartData).subtract(1, 'day')) && current.isBefore( moment(oEndData).add( 1, 'day' ) );
    }
    
    validStartData(current, unit) {
      const { states } = this.state;
      const { oStartData, oEndData } = this.props;
      let prevUnit = unit;
      prevUnit = parseInt(unit) - 1;

      if(!states[`endDate_${unit}`] && prevUnit < 0){
        return current.isAfter( moment(oStartData).subtract( 1, 'day' ) ) && current.isBefore( moment(oEndData).add( 1, 'day' ) );
      }else if(!states[`endDate_${unit}`] && prevUnit >= 0 && unit > 0){
        let prevEndtDate = document.querySelector(`.end_date_food_${prevUnit}`);
        if(!prevEndtDate){
          return current.isAfter( moment(oStartData).subtract( 1, 'day' ) ) && current.isBefore( moment(oEndData).add( 1, 'day' ) )
        }

        prevEndtDate = prevEndtDate.value;
        return current.isBefore(moment(oEndData).add( 1, 'day' )) && current.isAfter( moment(prevEndtDate) );
      } else if(states[`endDate_${unit}`] && prevUnit >= 0 && unit > 0){
        let prevEndtDate = document.querySelector(`.end_date_food_${prevUnit}`);
        if(!prevEndtDate){
          return current.isAfter( moment(oStartData).subtract( 1, 'day' ) ) && current.isBefore( moment(oEndData).add( 1, 'day' ) )
        }
        prevEndtDate = prevEndtDate.value;
        var today = moment(states[`endDate_${unit}`]);

        return current.isBefore( today ) && current.isAfter( moment(prevEndtDate) ) && current.isBefore(moment(oEndData).add( 1, 'day' ));
      }else if(states[`endDate_${unit}`]){
        var today = moment(states[`endDate_${unit}`]);
        return current.isBefore( moment(today).add( 1, 'day' ) );
      }
      
      var today = moment(states[`endDate_${unit}`]);
      return current.isBefore( today ) && current.isAfter( moment(oStartData) ) && current.isBefore( moment(oEndData).add( 1, 'day' ) );
    }

    addInvalid(ele){
        ele.classList.remove('is-valid');
        ele.classList.add('is-invalid');
    }

    removeInvalid(ele){
        ele.classList.remove('is-invalid');
        ele.classList.add('is-valid');
    }

    lastClick(isLastClick, unit){
        const { states } = this.state;
        const { oEndData } = this.props;
        let places = document.querySelector(`.places_food_${unit}`);
        let endDate = document.querySelector(`.end_date_food_${unit}`);
        let startDate = document.querySelector(`.start_date_food_${unit}`);
        let vendor = document.querySelector(`.vendor_food_${unit}`);
        let amount = document.querySelector(`.amount_paid_${unit}`);
        
        // let startDatePrev = startDate.previousSibling;
        // let endDatePrev = endDate.previousSibling;

        // this.removeInvalid(startDatePrev);
        this.removeInvalid(startDate);
        // this.removeInvalid(endDatePrev);
        this.removeInvalid(endDate);
        this.removeInvalid(places);
        this.removeInvalid(vendor);
        this.removeInvalid(amount);
        if((states[`endDate_${unit}`] || states[`startDate_${unit}`]) && states[`places_food_${unit}`] && states[`vendor_food_${unit}`] && amount.value !== "" && !isNaN(amount.value)){

            if(states[`endDate_${unit}`] && !states[`startDate_${unit}`])
              states[`startDate_${unit}`] = states[`endDate_${unit}`];
            if(!states[`startDate_${unit}`] && states[`startDate_${unit}`])
              states[`endDate_${unit}`] = states[`startDate_${unit}`];
              
            let { expenses_data, setStater, unit } = this.props;
            expenses_data = (expenses_data) ? expenses_data : {};
            expenses_data[unit] = (expenses_data[unit]) ? expenses_data[unit] : {};
            expenses_data[unit].expenses = (expenses_data[unit].expenses) ? expenses_data[unit].expenses : {};
            expenses_data[unit].expenses.amount = !isNaN(amount.value) ? parseFloat(amount.value).toFixed(2) : 0;
            expenses_data[unit].expenses.start_date = (states[`startDate_${unit}`]) ? states[`startDate_${unit}`] : (states[`endDate_${unit}`]) ? states[`endDate_${unit}`] : '';
            expenses_data[unit].expenses.end_date = (states[`endDate_${unit}`]) ? states[`endDate_${unit}`] : (states[`startDate_${unit}`]) ? states[`startDate_${unit}`] : '';
            expenses_data[unit].expenses.destination = [];
            expenses_data[unit].expenses.destination[0] = (states[`places_food_${unit}`]) ? { id: states[`places_food_${unit}`].value, name: states[`places_food_${unit}`].label } : '';
            expenses_data[unit].expenses.vendor = [];
            expenses_data[unit].expenses.vendor[0] = (states[`vendor_food_${unit}`]) ? { id: states[`vendor_food_${unit}`].value, name: states[`vendor_food_${unit}`].label } : '';
            setStater('expenses_data', expenses_data);
            
            if(oEndData !== expenses_data[unit].expenses.end_date)
              isLastClick();
        }else{
            if(!states[`endDate_${unit}`] && !states[`startDate_${unit}`]){
                // this.addInvalid(endDatePrev);
                this.addInvalid(endDate);
            }
            if(!states[`startDate_${unit}`] && !states[`endDate_${unit}`]){
                // this.addInvalid(startDatePrev);
                this.addInvalid(startDate);
            }
            if(!states[`places_food_${unit}`])
                this.addInvalid(places);
            if(!states[`vendor_food_${unit}`])
                this.addInvalid(vendor);
            if(amount.value === "")
                this.addInvalid(amount);
        }

        this.setState({ states: states });
    }

    handleNumberChange(e) {
      let elem = e.target;
      let data = elem.value;
      this.removeInvalid(elem);
      if(!/^[1-9]\d*(\.\d+)?$/.test(data)){
        this.addInvalid(elem);
      }
    }

    deleteClick(e, unit, _isLast){
      const { tourExpensesInsert, expenses_data, dispatch, e_ticket_id, customerId, tour_id_init, food_places_init_chk, notify } = this.props;
      let elem = e.currentTarget;
      let rowElem = elem.parentNode.parentNode.parentNode;
      if(rowElem){
        if(!_isLast)
          rowElem.parentNode.removeChild(rowElem);
        let rowDataInsert = {
          "ticket_id": e_ticket_id,
          "customer_id": customerId,
          "tour_id": tour_id_init,
          "category": (food_places_init_chk) ? 'food' : 'vendor',
          "food_expenses":{}
        };
        document.querySelectorAll('.foodRow_st').forEach((v, i) => {
          let startDateTemp = document.querySelectorAll(`.foodRow_st`)[i].children[0].children[1].children[0].children[0];
          let endDateTemp = document.querySelectorAll(`.foodRow_st`)[i].children[1].children[0].children[0].children[0];
          let placesTemp = document.querySelectorAll(`.foodRow_st`)[i].children[2].children[0];
          let vendorTemp = document.querySelectorAll(`.foodRow_st`)[i].children[3].children[0];
          let pricesTemp = document.querySelectorAll(`.foodRow_st`)[i].children[4].children[0].children[0];
          if(startDateTemp && endDateTemp && placesTemp && vendorTemp && pricesTemp && startDateTemp.value && endDateTemp.value && placesTemp.childNodes[1].value && vendorTemp.childNodes[1].value && pricesTemp.value){
            rowDataInsert.food_expenses[i] = {
              "start_date": startDateTemp.value,
              "end_date": endDateTemp.value,
              "place_id": placesTemp.children[1].value,
              "vendor_id": vendorTemp.children[1].value,
              "amount": pricesTemp.value
            }
          }
        });

        if(rowDataInsert && rowElem.children[0].children[0].value){
          // const { expenses_data } = this.state;
          if((tourExpensesInsert && tourExpensesInsert.tourExpenses && tourExpensesInsert.tourExpenses.food && tourExpensesInsert.tourExpenses.food.length > 0) || (expenses_data && Object.keys(expenses_data).length > 0)){
            dispatch(userActions.tourExpensesUpdate(rowDataInsert)).then(() => {
              notify("tc", "", "Expenses has been updated successfully");
            });
          }else{
            dispatch(userActions.tourExpensesInsert(rowDataInsert)).then(() => {
              notify("tc", "", "Expenses has been created successfully");
            });
          }
        }
      }
    }

    addNewPlace(_options, _stateName, _value, _stateValue, _rootStateValue){
      if(_value && _value.__isNew__){
        const { dispatch } = this.props;
        dispatch(placesActions.placesCreate({ name: _value.label })).then(() => {
          const { placesCreate, setStater } = this.props;
          const { states } = this.state;
          const { place } = placesCreate;
          const { data } = place;
          const { id, name } = data;
          if(id && name){
            _options.push({ value: id, label: name });
            states[`${_stateValue}`] = { value: id, label: name };
            this.setState({ [`${_stateName}`] : _options, states: states });
            setStater('tourPlaceslistOption', _options);
          }
        });
      }
    }

    render() {
        const { Option } = components;
        const IconOption = (props) => {
            let iconimg = '';
            let bagde = '';
            let imgSrc = require("assets/img/theme/team-1.jpg");
            if(props.data.badge){
                iconimg = (<div className="col-2 p-0"><img src={imgSrc} className="avatar avatar-xs rounded"/> </div>);
                bagde = (<div className="col-2 p-0 w-100 d-flex text-right align-items-center"><span className="badge badge-dark badge-pill text-white">{props.data.badge}</span></div>);
            }
            return (
                <Option {...props} key={`${props.data.label}_${props.data.id}`} selected={`${props.data.selected}`} className="d-flex align-items-center">
                <div className="row col p-0 m-0">
                    {iconimg}
                    <div className="col px-0 mx-1 d-flex align-items-center">{props.data.label}</div>
                    {bagde}
                </div>
                </Option>
            );
        }

        const { renderDateInput, isLast, isLastClick, unit, optiondata } = this.props;
        let { vendorsOption } = this.props;
        if(vendorsOption)
          vendorsOption = vendorsOption.filter((v, i) => { return v.value !== 'e-route'; });
        
        return (
            <div className="row my-1 mx-0 foodRow_st">
            <div className="col-md-2 mb-lg-0 mb-2  pl-0 pr-lg-1 pr-0">
              <input type="hidden" ref={`foodRow_id_${unit}`} className={`food_id_${unit}`} value={this.props.expenses_data && this.props.expenses_data[unit] && this.props.expenses_data[unit].expenses && this.props.expenses_data[unit].expenses.id || ''} />
              <ReactDatetime
                ref={`start_date_food_${unit}`}
                inputProps={{
                  placeholder: "Start Date",
                  className: `form-control form-control-sm start_date_food_${unit}`, readOnly: true
                }}
                isValidDate={(current) => this.validStartData(current, unit)}
                renderInput={ renderDateInput() }
                timeFormat={false}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    this.state.states[`startDate_${unit}`] &&
                    this.state.states[`endDate_${unit}`] &&
                    moment(this.state.states[`startDate_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " start-date";
                  } else if (
                    this.state.states[`startDate_${unit}`] &&
                    this.state.states[`endDate_${unit}`] &&
                    new Date(moment(this.state.states[`startDate_${unit}`])._d + "") <
                      new Date(currentDate._d + "") &&
                    new Date(moment(this.state.states[`endDate_${unit}`])._d + "") >
                      new Date(currentDate._d + "")
                  ) {
                    classes += " middle-date";
                  } else if (
                    this.state.states[`endDate_${unit}`] &&
                    moment(this.state.states[`endDate_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                value={this.props.expenses_data && this.props.expenses_data[unit] && this.props.expenses_data[unit].expenses && this.props.expenses_data[unit].expenses.start_date || this.state.states[`startDate_${unit}`] || ''}
                onChange={e => { const { states } = this.state; states[`startDate_${unit}`] = moment(e).format('YYYY-MM-DD'); this.setState({ states: states }); let elem = document.querySelector(`.start_date_food_${unit}`); this.removeInvalid(elem); if(!states[`startDate_${unit}`]){ this.addInvalid(elem); } }}
              />
            </div>
            <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-1 pr-0">
              <ReactDatetime
                ref={`end_date_food_${unit}`}
                inputProps={{
                  placeholder: "End Date",
                  className: `form-control form-control-sm end_date_food_${unit}`, readOnly: true
                }}
                isValidDate={(current) => this.validEndData(current, unit)}
                renderInput={ renderDateInput() }
                timeFormat={false}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    this.state.states[`startDate_${unit}`] &&
                    this.state.states[`endDate_${unit}`] &&
                    moment(this.state.states[`startDate_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " start-date";
                  } else if (
                    this.state.states[`startDate_${unit}`] &&
                    this.state.states[`endDate_${unit}`] &&
                    new Date(moment(this.state.states[`startDate_${unit}`])._d + "") <
                      new Date(currentDate._d + "") &&
                    new Date(moment(this.state.states[`endDate_${unit}`])._d + "") >
                      new Date(currentDate._d + "")
                  ) {
                    classes += " middle-date";
                  } else if (
                    this.state.states[`endDate_${unit}`] &&
                    moment(this.state.states[`endDate_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                value={this.props.expenses_data && this.props.expenses_data[unit] && this.props.expenses_data[unit].expenses && this.props.expenses_data[unit].expenses.end_date || this.state.states[`endDate_${unit}`] || ''}
                onChange={e => { const { states } = this.state; states[`endDate_${unit}`] = moment(e).format('YYYY-MM-DD'); this.setState({ states: states }); let elem = document.querySelector(`.end_date_food_${unit}`); this.removeInvalid(elem); if(!states[`endDate_${unit}`]){ this.addInvalid(elem); } }}
              />
            </div>

            <div className={classnames({ "col-md-3 pl-0 pr-lg-1 pr-0 d-flex mb-lg-0 mb-2": true })}>
              <CreatableSelect ref={`places_food_${unit}`} id={`places`} className={`form-control form-control-sm p-0 select_${`places`} places_food_${unit}`} isClearable placeholder={`Select Place`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`places`} options={optiondata} value={this.state.states[`places_food_${unit}`]} onChange={(value) => { const { states } = this.state; states[`places_food_${unit}`] = value; this.setState({ states: states }); let elem = document.querySelector(`.places_food_${unit}`); this.addInvalid(elem); if(value){ this.removeInvalid(elem);  this.addNewPlace(optiondata, 'optiondata', value, `places_food_${unit}`, `states`); } }} />
            </div>

            <div className="col-md-3 mb-lg-0 mb-2 pl-0 pr-lg-1 pr-0">
              <Select ref={`vendor_food_${unit}`} id={`vendor`} className={`form-control form-control-sm p-0 select_${`vendor`} vendor_food_${unit}`} isClearable placeholder={`Select Vendor`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`vendor`} options={vendorsOption} value={this.state.states[`vendor_food_${unit}`]} onChange={(value) => { const { states } = this.state; states[`vendor_food_${unit}`] = value; this.setState({ states: states }); let elem = document.querySelector(`.vendor_food_${unit}`); this.addInvalid(elem); if(value){ this.removeInvalid(elem); }}} />
            </div>

            <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-1 pr-0 d-flex">
              <span className="w-100">
                <input type="text" className={`form-control form-control-sm amount_paid_${unit}`} placeholder="Amt. Paid" onChange={(e) => this.handleNumberChange(e)} />
              </span>
              <span className="ml-1 d-flex">
                {(isLast) ? (<button className="btn btn-sm btn-primary" onClick={() => this.lastClick(isLastClick, unit)}><i className="fas fa-plus"></i></button>) : (<button className="btn btn-sm btn-danger mx-0" onClick={(e) => this.deleteClick(e, unit, isLast)}><i className="fas fa-minus"></i></button>)}
              </span>
            </div>
            
          </div>
        );
    }
}

function mapStateToProps(state) {
    const { special, placesCreate } = state;
    return { special, placesCreate };
}

const FoodRowSectionPage = connect(mapStateToProps)(FoodRow);
export { FoodRowSectionPage as FoodRow };
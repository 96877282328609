import React from "react";
import { connect } from 'react-redux';
// import Select, { components } from "react-select";
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import ReactDatetime from "react-datetime";
import Select1 from 'rc-select';
import Select, { components } from "react-select";
import { loadingButton, removeLoadingButton } from '../../_functions';
import { globalConstants } from "../../_constants";
import { localTime, getFilePathExtension } from "../../_functions";
import moment from "moment";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Table,
  Col,
  ListGroupItem,
  ListGroup,
  Collapse,
  UncontrolledCollapse
} from "reactstrap";
import { InsuranceRow } from "../../_components/common/insurance_row";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { _pettycashCreate } from '../../_data/pettycashCreate';
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';
import { userActions } from '../../_actions';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { reverse } from "dns";
import { tourGroupsUpdate } from "../../_reducers/tour.reducers";
import { isFulfilled } from "q";
import { convertCompilerOptionsFromJson, couldStartTrivia } from "typescript";
import { isArray } from 'util';

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      _currentPage: 1,
      _pageCount: 10,
      _searchText: '',
      _recordsFiltered: null,
      openedCollapses: [],
      validState: true,
      ticektdetails: []

    };
  }
  componentDidMount() {
    const { dispatch, match } = this.props;
    fetch(
      "http://eroute.bestdemo.site/backend/public/api/v1/tickets/list/" + match.params.id)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ ticektdetails: json });
      })
    // dispatch(userActions.getTicket(match.params.id)).then((resp) => {
    //   console.log(resp, 'match.params.id')
    //   const { ticket, authentication } = this.props;
    //   console.log(ticket, 'test');
    // });
    //   dispatch(userActions.getvendorservice(match.params.id)).then(() => {
    //       let GetVendorVal = this.props.vendorservice.vendorservice.data;
    //       let pushSpeArr ={services:[{ 'name':"Special"}]} ;
    //       GetVendorVal && GetVendorVal[0] && GetVendorVal[0].services_ticket.push(pushSpeArr);
    //       this.setState({ InitialVal : GetVendorVal });
    //  });
  }
  updatespecial(e, id, service_name, currenamount) {
    let Amount = currenamount;
    let elem = document.querySelector(`.Service_${service_name + id}`);
    if (currenamount != '') {
      Amount = this.state && this.state.AmountData && this.state.AmountData['UpdateValue_' + service_name + id];
    }
    if (elem.value == "" || !this.state.validState) {
      this.addInvalid(elem);
    }
    else {
      const { dispatch } = this.props;
      let Tablename = 4;
      if (service_name.toString().trim().toLowerCase() == "insurance")
        Tablename = 3;
      if (service_name.toString().trim().toLowerCase() == "special")
        Tablename = 2;
      if (service_name.toString().trim().toLowerCase() == "currencies")
        Tablename = 1;
      let FinalArr = {
        id: id, table_name: Tablename, eroute_amount: Amount
      };
      let newState = (this.state && this.state.AmountData) ? this.state.AmountData : [];
      newState['UpdateValue_' + service_name + id] = Number(Amount).toFixed(2);
      dispatch(userActions.updatevendorservice(FinalArr)).then(() => {
        this.notify("tc", "Successfully Updated", "Vendor Billing");
        this.setState({ AmountData: newState });
      });
    }
  }
  updateAmount(e, id, service_name, currenamount) {
    let Amount = currenamount;
    let elem = document.querySelector(`.Service_${service_name + id}`);
    if (currenamount != '') {
      Amount = this.state && this.state.AmountData && this.state.AmountData['UpdateValue_' + service_name + id];
    }
    if (elem.value == "" || !this.state.validState) {
      this.addInvalid(elem);
    }
    else {
      const { dispatch } = this.props;
      let Tablename = 4;
      if (service_name.toString().trim().toLowerCase() == "insurance")
        Tablename = 3;
      if (service_name.toString().trim().toLowerCase() == "special")
        Tablename = 2;
      if (service_name.toString().trim().toLowerCase() == "currencies")
        Tablename = 1;
      let FinalArr = {
        id: id, table_name: Tablename, eroute_amount: Amount
      };
      let newState = (this.state && this.state.AmountData) ? this.state.AmountData : [];
      newState['UpdateValue_' + service_name + id] = Number(Amount).toFixed(2);
      dispatch(userActions.updatevendorservice(FinalArr)).then(() => {
        this.notify("tc", "Successfully Updated", "Vendor Billing");
        this.setState({ AmountData: newState });
      });
    }
  }
  addInvalid(ele) {
    ele.classList.remove('is-valid');
    ele.classList.add('is-invalid');
  }
  notify(place, message, Title) {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {Title}
          </span>
          <br />
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: "default",
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  }
  removeInvalid(ele) {
    ele.classList.remove('is-invalid');
    ele.classList.add('is-valid');
  }
  ServiceChange(e, servicename, id, isReq, regexp, amount) {
    let newState = (this.state && this.state.AmountData) ? this.state.AmountData : [];
    let elem = e.target;
    let value = elem.value;
    this.removeInvalid(elem);
    if (!value && isReq) {
      this.addInvalid(elem);
      this.setState({ validState: false });
    }
    else {
      this.setState({ validState: true });
    }
    if (regexp && value) {
      if (!regexp.test(value)) {
        this.addInvalid(elem);
        this.setState({ validState: false });
      }

      if (Number(value) > Number(amount)) {
        this.addInvalid(elem);
        this.setState({ validState: false });
      }
    }
    else {
      this.setState({ validState: true });
    }
    newState['UpdateValue_' + servicename + id] = value;
    this.setState({ AmountData: newState });
  }
  collapsesToggle(collapse, id, service_name) {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      setTimeout(() => { this.setState({ openedCollapses: [], ServiceName: '', ServiceId: '' }); }, 250);
    } else {
      setTimeout(() => { this.setState({ openedCollapses: [collapse], ServiceName: service_name, ServiceId: id }); }, 250);
    }
  };
  jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    const { ticektdetails } = this.state;
    return (
      <>
        {this.state.alert}
        <NotificationAlert ref="notificationAlert" />
        <SimpleHeader name="Ticket Details" parentName="Ticket Details" />
        <Container className="mt--6" fluid>
          <Row>

            <div className="col-md-6 px-1 ">
              <div className="card-wrapper shadow-lg">
                <Card className="mb-2">
                  <CardHeader className="pb-0 border-0 "><h3 className="mb-0">Customer Details</h3></CardHeader>
                  <CardBody className="py-3 px-4" >
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Cust Id</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['customer_details'][0]['id']}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Cust Name</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['customer_details'][0]['firstname']}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Cust Email</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['customer_details'][0]['email']}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Cust Phone  number</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['customer_details'][0]['phone']}</span>
                    </div>
                  </CardBody>
                </Card>

              </div>
            </div>
            <div className="col-md-6 px-1 ">
              <div className="card-wrapper shadow-lg">
                <Card className="mb-2">
                  <CardHeader className="pb-0 border-0 "><h3 className="mb-0">Ticket Details</h3></CardHeader>
                  <CardBody className="py-3 px-4" >
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Ticket Id</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['id']}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Ticket Status</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['status'][0]['name']}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Refered By</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['customer_details'][0]['referred_by']}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">POC number</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['poc_number']}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label className="form-control-label mb-0 d-flex justify-content-between w-25"><span className="w-90">Next Follow Up</span><span>:</span></label>
                      <span className="ml-1 text-sm"> {ticektdetails && ticektdetails[0] && ticektdetails[0]['next_follow_up']}</span>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

          </Row>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { authentication, vendorservice } = state;
  return {
    authentication, vendorservice
  };
}

const connectedService = connect(mapStateToProps)(Service);
export { connectedService as Service };
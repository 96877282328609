import { userConstants } from '../_constants';

export function maintenancecreate(state = {}, action) {
    switch (action.type) {
 
            case userConstants.CREATE_MAINTENANCE_REQUEST:
                return {
                  loading: true
                };
              case userConstants.CREATE_MAINTENANCE_SUCCESS:
                return {
                    list: action.maintenance
                };
              case userConstants.CREATE_MAINTENANCE_FAILURE:
                return { 
                  error: action.error
                };
                
                case userConstants.GET_MAINTENANCE_REQUEST:
                  return{
                   maintenance:action.mainidlist
                 };
                 case userConstants.GET_MAINTENANCE_SUCCESS:
                    return{
                        maintenance:action.mainidlist
                   };
                 case userConstants.GET_MAINTENANCE_FAILURE:
                       return{
                         error: action.error
                   };

                   case userConstants.UPDATE_MAINTENANCE_REQUEST:
                    return {
                      loading: true
                    };
                  case userConstants.UPDATE_MAINTENANCE_SUCCESS:
                    return {
                        list: action.maintenance
                    };
                  case userConstants.UPDATE_MAINTENANCE_FAILURE:
                    return { 
                      error: action.error
                    };
              case userConstants.DELETE_MAINTENANCE_SUCCESS:
                  return{
                    maintenance:action.maintenance
                };

         default:
      return state
    }
}

export function maintenancelist(state = {}, action) {
  switch (action.type) {
  case userConstants.LIST_MAINTENANCE_REQUEST:
      return{
     list:action.list
   };
   case userConstants.LIST_MAINTENANCE_SUCCESS:
        return{
        
       list:action.list
     };
     case userConstants.LIST_MAINTENANCE_FAILURE:
         return{
           error: action.error
       };
       default:
      return state
}
}


import React from "react";
import { connect } from 'react-redux';
// import Select, { components } from "react-select";
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import ReactDatetime from "react-datetime";
import Select1 from 'rc-select';
import Select, { components } from "react-select";
import moment from "moment";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Table,
  Col,
  Badge,
} from "reactstrap";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from 'react-router-dom';
import List from "list.js";
import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { _taskCreate } from '../../_data/taskCreate';
import { _MaintenanceCreate } from '../../_data/maintenanceCreate';
import { usercreateActions } from '../../_actions';
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';
import { userActions } from '../../_actions';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { reverse } from "dns";
import { removeInvalid, loadingButton, removeLoadingButton } from "../../_functions";
const { SearchBar } = Search;

class Maintenance extends React.Component {
  constructor(props) {
    super(props);

    let stater = {};
   stater.create = false;
   _MaintenanceCreate.forEach((v, i) => {
      stater[`${v.name}`] = '';
      stater[`${v.name}State`] = '';
    });
    this.state = {
      alert: null,
      loading: true,
      paginationList: [],
      _currentPage: 1,
      _pageCount: 10,
      _searchText: '',
      _recordsFiltered: null,
       mainCreation: stater,
      _MaintenanceCreate: []
    };

  }

  cancel() {
    let newState = this.state.mainCreation;
     newState.create = true;
    Object.keys(newState).forEach((key) => {
      newState[`${key}State`] = '';
      newState[`${key}`] = '';
    });
    this.setState({
      editid: '',
      mainCreation: newState,
    });
  }

  componentWillUnmount(){
    document.querySelector('.navbar-nav .accounts-menu') && document.querySelector('.navbar-nav .accounts-menu').classList.remove('active');
  }

componentDidMount() {
  document.querySelector('.navbar-nav .accounts-menu') && document.querySelector('.navbar-nav .accounts-menu').classList.add('active');
    const {dispatch, match, authentication } = this.props;
    let { user } = authentication;
    const userrole = user && user.user.role;
    const name = userrole;
    const isAdmin = name.toString().trim().toLowerCase() === "admin";
    const isManager = name.toString().trim().toLowerCase() === "mananger";
    const isEmployee = name.toString().trim().toLowerCase() === "staff";
    if(!isAdmin){
      return this.props.history.push('/admin/dashboard');
    }

    const { _currentPage, _searchText, _pageCount } = this.state;

    dispatch(userActions.listMaintenance(_currentPage, _searchText, _pageCount)).then(() => {

      var filter=this.props.maintenancelist.list.recordsFiltered;
      this.setState({ mainlist:this.props.maintenancelist, _recordsFiltered: filter, loading: false  });
   });

    this.setState({ _MaintenanceCreate: _MaintenanceCreate });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {

    const { dispatch } = this.props;
    const { loading, _currentPage, _searchText, _pageCount } = this.state;
    if(_currentPage != prevState._currentPage || _searchText != prevState._searchText || _pageCount != prevState._pageCount) {
       dispatch(userActions.listMaintenance(_currentPage, _searchText, _pageCount)).then(() => {
        var filter=this.props.maintenancelist.list.recordsFiltered;
        this.setState({ mainlist:this.props.maintenancelist, _recordsFiltered: filter, loading: false  });

      });
    }

    if (this.state && this.state.editid) {
      if(prevState.editvalue != this.state.editvalue ){
         let newState = this.state.mainCreation;
         newState.create = true;
         const {editvalue} = this.state;
         newState.balance=editvalue && editvalue[0] && editvalue[0].balance;
         newState.date=editvalue && editvalue[0] && editvalue[0].date;
         newState.amount_entry=editvalue && editvalue[0] && editvalue[0].amount_entry;

         this.setState({
          mainCreation:newState
         });
       }
     }
  }

onDateChange = (value, idSelector) => {
  value = (value && value._d);
  let newStates = this.state.mainCreation;
  newStates[`${idSelector}`] = value;
  newStates[`${idSelector}State`] = 'invalid';
  this.setState({
    mainCreation: newStates
  });
  if(value){
    newStates[`${idSelector}State`] = 'valid';
    this.setState({
      mainCreation: newStates
    });
  }
};

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  confirmedAlert = (id) => {
    const { dispatch } = this.props;
    const {_currentPage, _searchText, _pageCount }=this.state;
     dispatch(userActions.deleteMaintenance(id)).then(() => {
      dispatch(userActions.listMaintenance(_currentPage, _searchText, _pageCount)).then(() => {
        var filter=this.props.maintenancelist.list.recordsFiltered;
        this.setState({ mainlist:this.props.maintenancelist, _recordsFiltered: filter, loading: false  });
      });

    });

    var content = "Maintenance has been deleted";
    this.setState({
      alert: (
        <ReactBSAlert
          success
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-200px" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary btn-sm"
          confirmBtnText="Ok"
          btnSize=""
        >
          {content}
    </ReactBSAlert>
      )
    });
  };

  delete(id) {
    var title = (<>Are you sure want to delete</>);
    var canceltext = "Yes, delete it!"
    var content = "You won't be able to revert this!";

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          closeOnClickOutside={false}
          showCancel
          style={{ display: "block", marginTop: "-200px" }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.confirmedAlert(id)}
          showCancel
          confirmBtnBsStyle="secondary btn-sm"
          confirmBtnText="Cancel"
          cancelBtnBsStyle="danger btn-sm"
          cancelBtnText={canceltext}
          btnSize=""
        >
          {content}
        </ReactBSAlert>
      )
    });
  }

  notify(place, message, Title) {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {Title}
          </span>
          <br />
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: "default",
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  }


  onShowSizeChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
  }

  onChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
  }


 searchDataPagination(e) {
    this.setState({ _searchText: e.target.value.trim(), loading: true, _currentPage: 1 });
  }

  validateMaintenanceCreationForm = _event => {
    let elem = _event.currentTarget;
    const { dispatch,authentication } = this.props;
    let newState = this.state.mainCreation;
    newState.create = true;
    this.state &&  this.state._MaintenanceCreate &&  this.state._MaintenanceCreate.forEach((v, i) => {
      if(newState[`${v.name}`] && newState[`${v.name}`].toString().trim() == "" && v.isReq){

        newState[`${v.name}State`] = v.invalid;
        newState[`${v.name}Feedback`]=v.invalidFeedback;
        newState.create = false;
      }
      if(v.regex && newState[`${v.name}`] && newState[`${v.name}`].toString().trim() !== ""){
        let  regex = new RegExp(v.regex);
      if(newState[`${v.name}`] &&  !regex.test(newState[`${v.name}`].toString().trim())){
        newState[v.name + "Feedback"] = v.regexInvalid;
        newState[v.name + "State"] = "invalid";
        newState.create = false;
      }else{
        newState[v.name  + "State"] = "valid";
      }
      if(this.state && this.state.negativebal && this.state.negativebal == 2){
        newState["balanceFeedback"] = "Negative Value didn't accept";
        newState["balanceState"] = "invalid";
        newState.create = false;
       }else{
        newState["balanceState"] = "valid";
       }
      }

    });
    this.setState({ mainCreation: newState });
    if(this.state.mainCreation.create) {
      elem && loadingButton(elem);
  if(this.state.editid){
    var bal=this.state.mainlist.list.data[0].balance;
        var datas1={
           date: this.state.mainCreation.date ? moment(this.state.mainCreation.date).format("YYYY-MM-DD"): this.state.editvalue[0].date,
            balance:this.state.mainCreation.balance,
            amount_entry:this.state.mainCreation.amount_entry,
            updated_by:authentication.user.user.id,
            count:this.state._pageCount,
            page:this.state._currentPage,
            id:this.state.editid
           };
        dispatch(userActions.updateMaintenance(datas1)).then(() => {

         if(this.props.maintenancecreate.error){
          let data =this.props.maintenancecreate.error.response.data;
          newState.create = true;
          Object.keys(data).forEach((key) => {

           newState[`${key}State`] = 'invalid';

           newState[`${key}Feedback`] = data[key];
           newState.create = false;
          });
          var message = "Maintenance Not updated";
          this.setState({
            mainCreation: newState,
            loading: false
          });

          elem && removeLoadingButton(elem);
         }else{
          var message = "Maintenance Has Been Updated Successfully";
          let newState = this.state.mainCreation;
          newState.create = true;
          Object.keys(newState).forEach((key) => {
            newState[`${key}State`] = '';
            newState[`${key}`] = '';
          });

          this.setState({
            mainlist:this.props.maintenancecreate,
            _recordsFiltered:this.props.maintenancecreate.list.recordsFiltered,
             editid:''
          });
        }

        let Title = "Maintenance Update";
        this.notify("tc", message, Title);
        elem && removeLoadingButton(elem);
        });
    }else{
      var datas={
            date: this.state.mainCreation.date ? this.state.mainCreation.date.toJSON().substring(0, 10) : '',
            balance:document.querySelector('.balance').value,
            amount_entry:this.state.mainCreation.amount_entry,
            updated_by:authentication.user.user.id,
            count:this.state._pageCount,
            page:this.state._currentPage
      };

     dispatch(userActions.createMaintenance(datas)).then(() => {
      let newState = this.state.mainCreation;
       if(this.props.maintenancecreate.error){
        let data =this.props.maintenancecreate.error.response.data;
        newState.create = true;
        Object.keys(data).forEach((key) => {
          newState[`${key}State`] = 'invalid';

         newState[`${key}Feedback`] = data[key];
         newState.create = false;
        });
        var message = "Maintenance Not Created";
        this.setState({
          editid: '',
          mainCreation: newState,
          loading: false
        });

        elem && removeLoadingButton(elem);
       }else{
        var message = "Maintenance Has Been Created Successfully";

        let newState = this.state.mainCreation;
        newState.create = true;
        Object.keys(newState).forEach((key) => {
          newState[`${key}State`] = '';
          newState[`${key}`] = '';
        });

      this.setState({
         mainlist:this.props.maintenancecreate,
        _recordsFiltered:this.props.maintenancecreate.list.recordsFiltered
           });

        let Title = "Maintenance Creation";
        this.notify("tc", message, Title);
        elem && removeLoadingButton(elem);
          }
      });
    }
  }
}

mainChange(e, stateName, isReq, regexp, regexInvalid,invalidFeedback){
    let newState = this.state.mainCreation;
    newState[stateName] = e.target.value;
    newState[stateName + "State"] = "valid";

    if(e.target.value.trim() === "" && isReq) {
      newState[stateName + "State"] = "invalid";
      newState[stateName + "Feedback"] = invalidFeedback;
      this.setState({ defaultbal:this.state.mainlist.list.data[0].balance });
      newState.create = false;
    }else if(e.target.value.trim() !== "" && regexp){
     let  regex = new RegExp(regexp);
      if(!regex.test(e.target.value.trim())){
        newState[stateName + "Feedback"] = regexInvalid;
        newState[stateName + "State"] = "invalid";
        newState.create = false;
      }else{
        newState[stateName + "State"] = "valid";
      }
    }
     if(stateName == "amount_entry"){
        var getbalancevalue = document.querySelector('.balance').value;
         var amountvalue = e.target.value;
         var getbalancevalue = document.querySelector('.balance').value;
         try{
          let cond=Math.sign(amountvalue === -1) ? '-' : '+' + amountvalue;
          var gettotal = eval( this.state.mainlist.list.data[0].balance + cond);
          this.setState({ defaultbal:Number.parseFloat(gettotal).toFixed(2) });
          if(Math.sign(parseFloat(Number.parseFloat(gettotal).toFixed(2))) === -1){
            newState["balanceFeedback"] = "Negative Value didn't accept";
            newState["balanceState"] = "invalid";
            this.setState({negativebal:2});
           }else{
            newState["balanceState"] = "valid";
            this.setState({negativebal:1});
           }
         }
         catch(e){
         }

    }

    this.setState({
      mainCreation: newState,
      });
  }

edit(id){
    let { dispatch } = this.props;

    dispatch(userActions.getMaintenance(id)).then(() => {

      var data=this.props.maintenancecreate.maintenance;
     this.setState({
      editid: id,
      editvalue:data,
      loading: false,
       });
    });
 }
 validRemainder = (current) => {
  var today = moment();
  return current.isAfter( today );
 }

render() {
  const {loading,  _currentPage, _pageCount, _searchText, _recordsFiltered,mainlist}=this.state;

  const {authentication}=this.props;
    let recordsFiltered = 0;
    let count = _pageCount;
    let pageCount = 0;
    let currentPage = _currentPage;
    const listTable = [];


      const pagination = (
      <div className="row col-12 m-0 p-0 paginationrow mb-3 d-flex justify-content-between">
        <div className="col-md-3 col-sm-12 align-items-center d-flex pl-0"><h3>Maintenance List</h3></div>
        <div className="col-md-3 col-sm-12 align-items-center d-flex pl-0">
          <input type="text" value={_searchText} onChange={(e) => { this.searchDataPagination(e) }} className="form-control form-control-sm" placeholder="Search..." />
        </div>
        <div className="col-md-7 col-sm-12 align-items-center justify-content-center d-flex">
        </div>
      </div>
    );

    if(mainlist && mainlist.list && mainlist.list.data && mainlist.list.recordsFiltered) {

      recordsFiltered = mainlist.list.recordsFiltered;
      pageCount = Math.ceil(recordsFiltered / count);

    }
    let startVal = ((_currentPage * _pageCount) - (_pageCount - 1));

    mainlist && mainlist.list && mainlist.list.data && mainlist.list.data.forEach((v, i) => {
      if(v.id!=1){
          listTable.push(
            <tr key={i}>
              <td className="p-1 text-left text-center id">{startVal + i}</td>
              <td className="p-1 ">{v.date}</td>
              <td className="p-1 ">{v.balance}</td>
              <td className="p-1 ">{v.amount_entry}</td>

            {/* <td className="p-1">
          <Button className="btn btn-sm btn-success" size="sm" onClick={() => this.edit(v.id)}>
                  <i className="fas fa-pencil-alt"></i></Button>
                <Button color="danger" size="sm" onClick={() => this.delete(v.id)}><i className="fas fa-trash-alt"></i></Button>

              </td> */}
            </tr>
          );
        }
    });
    return (
      <>
        {this.state.alert}
        <NotificationAlert ref="notificationAlert" />
        {(loading) ? (<i className="fas fa-circle-notch fa-spin display-3 sticky-top float-left" style={{ margin: '0.5rem 1rem', marginTop: -70 }}></i>) : ''}
        <SimpleHeader name="Maintenance" parentName="Money" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col-md-3 px-1">
              <div className="card-wrapper">
                <Card>
                  <CardHeader><h3>Edit / Create Maintenance</h3></CardHeader>
                <CardBody>
                 <Form className="needs-validation" ref="maintenance" noValidate onSubmit={this.validateMaintenanceCreationForm}>
                      <div className="row mc-minimize mb-3" >
                            {this.state && this.state._MaintenanceCreate && this.state._MaintenanceCreate.map((v, i) => {
                                                        return (
                                <Col key={i} className="mb-2" md={12}>
                                    <label className="form-control-label " htmlFor={v.name} > {v.label} </label>
                                  {(v.type == "date") ?
                                  <ReactDatetime  className={`form-control form-control-sm p-0 h-auto
                                  ${(this.state.mainCreation[v.state] === 'valid') ? 'is-valid' : ((this.state.mainCreation[v.state] === 'invalid')) ? 'is-invalid': '' }`}
                                    value={this.state.mainCreation[v.name] || ""}
                                    isValidDate={this.validRemainder}
                                    inputProps={{ placeholder: v.placeholder, className: `form-control form-control-sm border-0`, readOnly: true }}
                                    timeFormat={false}  onChange={value => this.onDateChange(value, v.name)  }
                                    /> : ''}
                                    {/* readOnly={(v.name == "balance") ? true : false} */}
                                   {(v.type == "input") ?
                                    (<><Input id={v.name} className={`form-control-sm ${(v.name  == "balance") ? 'balance' :'' }`}  readOnly={(v.name == "balance") ? true : false}
                                    placeholder={ v.label } type="text" valid={this.state.mainCreation[v.state] === v.valid} invalid={this.state.mainCreation[v.state] === v.invalid}
                                    value={(v.name == "balance") ? (this.state && this.state.defaultbal) ? this.state.defaultbal :  mainlist && mainlist.list && mainlist.list.data &&  mainlist.list.data[0] &&  mainlist.list.data[0].balance : this.state.mainCreation[v.name] || ""}
                                    onChange={e => this.mainChange(e, v.name,v.isReq,v.regex,v.regexInvalid,v.invalidFeedback)} /></>) : ''}

                                  <div className="valid-feedback"></div>
                                    <div className="invalid-feedback"> {(this.state.mainCreation[`${ v.name }Feedback`]) ? this.state.mainCreation[`${v.name}Feedback`] : v.invalidFeedback} </div>
                                </Col>
                                )
                            })}
                       <Col md="12">
                          <div className="d-flex justify-content-start">
                              <Button
                                className="mt-1 form-control-sm mr-1"
                                color="primary"
                                type="button" size="sm" onClick={(e) => this.validateMaintenanceCreationForm(e)}>
                               {(this.state && this.state.editid) ? 'Update' :'Create'}
                              </Button>
                              <Button
                                className="mt-1 form-control-sm"
                                color="warning"
                                type="button" size="sm" onClick={() => this.cancel()}>
                                Reset
                              </Button>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                </div>
                </div>

            <div className="col-md-9 px-1">
              <div className="card-wrapper">

                <Card body className="pb-5">
                {pagination}
                  <div className="table-responsive" ref="">

                    <Table hover className="align-items-center table-flush wallettable">
                      <thead className="thead-light">
                        <tr>
                          <th className="sort px-1 py-2 text-center"  data-sort="" scope="col">
                            S.No
                         </th>
                         <th className="sort px-1 py-2 "   data-sort="" scope="col">
                          Date
                         </th>
                          <th className="sort px-1 py-2 " data-sort="" scope="col">
                          Balance
                         </th>
                        <th className="sort px-1 py-2" data-sort="" scope="col">
                        Amount Entry
                        </th>
                       {/* <th className="">Action</th> */}
                       </tr>
                      </thead>
                      <tbody className="list">
                      {listTable.length > 0 && listTable || ((loading) ? (<tr><td colSpan="9" className="text-center">Processing..</td></tr>) : (<tr><td colSpan="9" className="text-center">No Rows Found</td></tr>))}
                      </tbody>
                    </Table>
               </div>
               <div className="row paginationrow col p-0 m-0 mt-4 d-flex justify-content-left align-items-left">
                <Pagination
                  selectComponentClass={Select1}
                  showSizeChanger
                  defaultPageSize={_pageCount}
                  defaultCurrent={_currentPage}
                  onShowSizeChange={(current, pageSize) => this.onShowSizeChange(current, pageSize)}
                  onChange={(current, pageSize) => this.onChange(current, pageSize)}
                  total={_recordsFiltered}
                  locale={localeInfo}
                />
              </div>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { maintenancecreate,
    maintenancelist,authentication } = state;
  return {
    maintenancecreate,
  maintenancelist,authentication
  };
}

const connectedMaintenance = connect(mapStateToProps)(Maintenance);
export { connectedMaintenance as Maintenance };
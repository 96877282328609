import styles from './remainderform.module.css'
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Modal from 'react-modal';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
    Input
} from 'reactstrap';
import styled, { css } from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import ReactDatetimeClass from 'react-datetime';
import { Portal } from 'react-overlays';
import Popup from 'react-animated-popup';
import defaultGreyPlusImage from '../../assets/img/users/default-user.PNG';
import Grid from '@mui/material/Grid';


const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{children}</Portal>;
};

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
        props.disappear &&
        css`
      display: block; /* show */
      padding-top: 250px;
    `}
`;




const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
        <components.Menu {...props}>
            {/* {optionSelectedLength < 1 ? (
        props.children
      ) : (
        <div>Maximum one Assignee</div>
      )} */}
            {props.children}
        </components.Menu>
    );
};

const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        display: 'none',
        height: '35px',
        position: 'relative',
    }),
    option: (styles, { isDisabled }) => {
        return {
            ...styles,
            margin: '0px',
        };
    },
    menu: (provided, state) => ({
        ...provided,
        // width: 210,
        position: 'absolute',
        bottom: 40,
        backgroundColor: 'white',
        width: '300px',
    }),
};




const EditRemainder = () => {
    let history = useHistory();
    const isValidNewOption = (inputValue, selectValue) =>
        inputValue.length > 0 && selectValue.length < 1;
    let [dept, setDept] = useState([]);
    let [deptCode, setDeptCode] = useState([]);
    let [deptCodeName, setDeptCodeName] = useState([]);
    let [code, setCode] = useState([]);
    let [selectShow, setSelectShow] = useState(false);
    let [customerId, setCustomerId] = useState('');
    let [loading, setLoading] = useState(false);
    let [menuOpen, setMenuOpen] = useState(true);
    let [depart, setDepart] = useState([]);
    let [selectedDepart, setSelectedDepart] = useState([]);
    let [deptmenuOpen, setDeptMenuOpen] = useState(false);
    let [oldDept, setOldDept] = useState([]);

    let [user, setUser] = useState({
        remainder_description: '',
        remainder_date: '',
    });



    let [userError, setUserError] = useState({
        remainder_descriptionError: '',
        remainder_dateError: '',
    });

    const location = useLocation();
    let { id } = useParams();
    console.log(id, 'edit remainder id ')

    useEffect(() => {
        let EditRemainder = `${process.env.REACT_APP_BACKEND_URL}api/edit_remainder/?userid=${id}`;
        axios
            .get(EditRemainder)
            .then((res) => {
                console.log(res.data)
                res.data.map((data) => {

                    let new_date=moment(data.remainder_date).format("MMMM Do YYYY hh:mm a")
                    console.log(new_date,'new date')
                    setUser({
                      ...user,
                      remainder_description: data.remainder_description,

                      remainder_date: moment(data.remainder_date).format(
                        'MMMM Do YYYY hh:mm a'
                      ),
                    });
                    console.log(data.remainder_date, 'remainder date from the database')
                    console.log(data.remainder_description, 'remainder descritption from the data base')
                })
            })
    },[]);

    useEffect(() => {
        console.log('outer-document.body.classList', document.body.classList.value);
        if (document.body.classList.value == 'g-sidenav-show g-sidenav-pinned') {
            console.log(
                'inner-document.body.classList',
                document.body.classList.value
            );
            // document.getElementById('lastname').classList.add('ml-6');
        }
    }, [document.body.classList.value]);

    let [avatar, setAvatar] = useState(null);
    let [assigneeImage, setAssigneeImage] = useState(null);

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (depart.length == 6) {
            setDeptMenuOpen(false);
        }
    }, [depart]);

    let validateremainder_description = (event) => {
        setUser({ ...user, remainder_description: event.target.value });
        setUserError({ ...userError, remainder_descriptionError: '' });
    };

    let validateLastName = (event) => {
        setUser({ ...user, lastname: event.target.value });
        let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        !regExp.test(event.target.value)
            ? setUserError({
                ...userError,
                lastnameError: 'Enter a proper Lastname',
            })
            : setUserError({ ...userError, lastnameError: '' });
    };

    let validatePhoneNumber = (event) => {
        setUser({ ...user, phone: event.target.value });
        let regExp =
            /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,4}$/im;
        !regExp.test(event.target.value)
            ? setUserError({
                ...userError,
                phonenumberError: 'Enter a proper Phone number',
            })
            : setUserError({ ...userError, phonenumberError: '' });
    };



    //click outside
    const [clickedOutside, setClickedOutside] = useState(false);
    const myRef = useRef();

    const handleClickInside = () => setClickedOutside(false);




    let validateEnquiryClosureDate = (date) => {
        console.log('closre_date_validate', date);
        setUser({
            ...user,
            remainder_date: date,
        });
        setUserError({
            ...userError,
            remainder_dateError: '',
        });
    };

    // useEffect(() => {
    //     let dept = localStorage.getItem('department');
    //     let deptParse = JSON.parse(dept);
    //     console.log('dept---list', deptParse);
    //     deptParse.map((de) => {
    //         console.log('eachdeptcode', de.code);
    //     });
    // }, []);

    useEffect(() => {
        let indexArr = localStorage.getItem('indexArr');
        let indexArrParse = JSON.parse(indexArr);
        console.log('56789eoiueioeeeeeeeeeeeuoeiieoieiie', indexArrParse);
        setDept(indexArrParse);
        let codename = [];
        let deptNewCode = [];
        let deptVarCode = [];
        if (indexArrParse != null) {
            indexArrParse.map((name) => {
                console.log('Mmmmmmmmmmm', name);
                codename.push(name.deptname);
                deptNewCode.push(name.code);
                deptVarCode.push(name.code);
                selectedDepart.push(name.code);
            });
        }
        setCode(selectedDepart);
        console.log(deptCode);
        console.log('NAMANAMAMAMAMAMAMAMAMA', codename);
        getUsers(selectedDepart.toString());
    }, []);



    let getUsers = (selectedDepart) => {

        let dataUrl = `${process.env.REACT_APP_BACKEND_URL}api/get-users?array=${selectedDepart && selectedDepart.length > 0 ? selectedDepart : null
            }`;
        axios
            .get(dataUrl)
            .then((res) => {
                console.log('dept-code-res84384938490', res.data);
                setDeptCode(res.data);
            })
            .catch((err) => {
                console.log(err, 'deptcode-eror894388304');
            });



    };

    let handleRemoveDept = (de, depCode) => {
        console.log('deptcodename', de);
        console.log('deptblablabla', dept);
        console.log('deptblablabla_deptCode', depCode);
        console.log('deptblablabla_oldDeptpar', oldDept);
        setSelectedDepart([...selectedDepart].concat(code.code));
        let deptArr = dept.filter((dep) => dep.deptname != de);
        let deptSOArr = dept.filter((dep) => dep.deptname == de);
        console.log('blalalalala', deptArr);
        console.log('deptSOArrblalalalala', deptSOArr);
        var index = selectedDepart.indexOf(depCode);
        var tempDep = [...selectedDepart];
        if (index >= 0) {
            tempDep.splice(index, 1);
        }
        setDept(deptArr);
        oldDept.map((old) => {
            old.code != depCode && setOldDept([...oldDept].concat(deptSOArr));
        })

        setSelectedDepart(tempDep);
        let string = JSON.stringify(deptArr);
        localStorage.setItem('indexArr', string);
        getUsers(tempDep.toString());
    };



    const aquaticCreatures = [];
    deptCode.map((code) => {
        let objCode = {
            label: code.remainder_description,
            value: code.remainder_description,
            image: code.profilephoto.substr(7),
            email: code.email,
            id: code.id,
        };
        aquaticCreatures.push(objCode);
    });

    const handleConvert = () => {
        setAvatar(null);
        setSelectShow(true);
    };

    let submitRegistration = async (event) => {
        var result = 0;

        let tempuserError = {
            remainder_descriptionError: '',
            remainder_dateError: '',

        };

        try {
            event.preventDefault();
            console.log('outer-console');
            if (user.remainder_description == '') {
                result = 1;
                console.log('inner-console');
                tempuserError.remainder_descriptionError = 'Remainder Description field cannot be empty';
            }

            if (user.remainder_date == '') {
                result = 1;
                console.log('inner-console');
                tempuserError.remainder_dateError =
                    'Remainder date field cannot be empty';
            }

            if (result == 1) {
                toast('Could not submit with error fields');
                setUserError({
                    ...userError,
                    remainder_descriptionError: tempuserError.remainder_descriptionError
                        ? tempuserError.remainder_descriptionError
                        : '',
                    remainder_dateError: tempuserError.remainder_dateError
                        ? tempuserError.remainder_dateError
                        : '',
                });

            } else if (result == 0) {
                setLoading(true);
                let formDataUrl = `${process.env.REACT_APP_BACKEND_URL}api/update_remainder/?id=${id}`;
                await axios
                    .post(formDataUrl, user)
                    .then((res) => {
                        toast('Remainder Edit Successfully');
                        setLoading(false);
                        history.goBack();
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log('FORMDAA-URLresponse', error.response.data);
                    });
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     if (dept.length == 0) {
    //         setIsOpen(true);
    //     }
    // }, [dept]);

    useEffect(() => {
        let user = localStorage.getItem('user');
        if (user) {
            let parseUser = JSON.parse(user);
            console.log('LOGGEDUSEROUEIRUIOEUIOR', parseUser.id);
        }
    }, []);

    // const [startDate, setStartDate] = useState(
    //   setHours(setMinutes(new Date(), 30), 16)
    // );

    const yesterday = moment().subtract(1, 'day');
    const disablePastDt = (current) => {
        return current.isAfter(yesterday);
    };

    //for getting all the department
    useEffect(() => {
        let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/department`;
        axios
            .get(dataURL)
            .then((res) => {
                // let deptArr = dept.filter((dep) => dep.deptname != de);
                var tempArray = [];
                let preArr = localStorage.getItem('indexArr');
                console.log('PREARRAAOUIEUIOEOIOEIOEIOEO', preArr);
                let parsePreArr = JSON.parse(preArr);
                if (parsePreArr == null) {
                    setIsOpen(true);
                }
                for (var j = 0; j < res.data.length; j++) {
                    var flag = 0;
                    console.log(parsePreArr, 'dfsdvfsfsdfsdf');
                    console.log(parsePreArr.length, 'dfsdvfsfsdfsdf1');
                    for (var i = 0; i < parsePreArr.length; i++) {
                        console.log(res.data[j].code, 'res.data[j].code');
                        console.log(parsePreArr[i].code, 'dept[i].codedept[i].code');
                        if (res.data[j].code == parsePreArr[i].code) flag = 1;
                    }
                    if (flag == 0) tempArray.push(res.data[j]);
                }
                console.log(tempArray, 'tempArraytempArray');
                setOldDept(tempArray);
                console.log('OLD_DEPART_collection', res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    let departmentClicked = (code) => {
        console.log(code, 'raw_rwa_war_code');
        setDept([...dept].concat(code));
        setOldDept([...oldDept].filter((old) => old.code != code.code))
        // let newRaDept = [];
        // let newTRaDept = oldDept.filter((old) => old.code == code.code );
        // // let newTRaDept = oldDept.map((od) => {
        // //     if(od.code != code.code){
        // //       newRaDept.push(od);
        // //     }
        // // })
        // setOldDept(newTRaDept);
        console.log(code.code, 'codecode');
        console.log(selectedDepart, 'selectedDepartselectedDepart');
        let tempDepts = [...selectedDepart];
        tempDepts.push(code.code);
        setSelectedDepart(tempDepts);
        console.log(tempDepts, 'ajsfjsdfselectedDepartselectedDepart');
        // setDeptMenuOpen(false);
        getUsers(tempDepts.toString());
    };

    let imageUrl = `${process.env.REACT_APP_BACKEND_URL}Images/`;

    return (
      <div style={{ height: '100%', backgroundColor: 'rgb(245 239 239)' }}>
        <style>
          {
            '\
          .rdtPicker {\
              top: 0px !important;\
          }'
          }
        </style>
        {/* <span className={styles.xVisible} style={{ textAlign: 'left' }}>
          <Link
            onClick={() => {
              setDept([]);
            }}
            style={{ float: 'right' }}
            to={'/admin/enquiry'}
          >
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '24px',
                color: 'black',
                paddingRight: '20px',
              }}
            >
              X
            </span>
          </Link>
        </span> */}
        <section>
          <DarkBackground disappear={loading}>
            <LoadingOverlay
              active={true}
              // spinner={<BounceLoader />}
              spinner={true}
              // text="Loading your content..."
            ></LoadingOverlay>
          </DarkBackground>
            <div className={styles.formContainerParent}>
              <div className="row w-auto m-auto d-flex align-items-center justify-content-center">
                <div className="col-md-6">
                  <div className={styles.formContainer}>
                    <div className={styles.formGroup}>
                      <div className="row">
                        <div className="col-md-6">
                          <ReactDatetimeClass
                            isValidDate={disablePastDt}
                            popperContainer={CalendarContainer}
                            inputProps={{
                              placeholder: 'Remainder Date',
                              readOnly: true,
                            }}
                            timeFormat={true}
                            value={user.remainder_date}
                            dateFormat="MMMM Do YYYY"
                            onChange={validateEnquiryClosureDate}
                            renderDay={(props, currentDate, selectedDate) => {
                              return <td {...props}>{currentDate.date()}</td>;
                            }}
                          />
                          {userError != '' &&
                          userError.remainder_dateError != '' ? (
                            <p
                              style={{
                                textAlign: 'left !important',
                                fontSize: '12.8px',
                              }}
                              className="text-danger mt-1 text-left"
                            >
                              {userError.remainder_dateError}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.formGroup}>
                      <div className="row">
                        <div className="col-md-6">
                          <textarea
                            type="text"
                            name="remainder_description"
                            value={user.remainder_description}
                            onChange={validateremainder_description}
                            placeholder={`Remainder Description`}
                            className={`form-control ${
                              userError.remainder_descriptionError.length > 0
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          {userError.remainder_descriptionError.length > 0 ? (
                            <p
                              style={{ textAlign: 'left', fontSize: '12.8px' }}
                              className="text-danger text-left"
                            >
                              {userError.remainder_descriptionError}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ paddingBottom: '33px' }}
                      className={styles.btnCon}
                    >
                      <button
                        onClick={submitRegistration}
                        className="btn btn-warning m-2"
                      >
                        Save
                      </button>
                      <Link
                        onClick={() => {
                          setDept([]);
                        }}
                        to={'/admin/remainder'}
                        className="btn btn-dark m-2"
                      >
                        Close
                      </Link>
                    </div>
                    <div style={{ marginBottom: '40px' }}></div>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </div>
    );
};

export default EditRemainder;

import React from "react";
import { connect } from 'react-redux';
// import Select, { components } from "react-select";
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import Select from 'rc-select';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Table,
  Col,
  Badge,
} from "reactstrap";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from 'react-router-dom';
import List from "list.js";
import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { _walletCreate } from '../../_data/walletCreate';
import { walletActions } from '../../_actions';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { history } from '../../_helpers';
import NotificationAlert from "react-notification-alert";

import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

class listWallet extends React.Component {
  constructor(props) {
    super(props);

    let stater = {};
    stater.create = false;
    _walletCreate.forEach((v, i) => {
      stater[`${v.name}`] = '';
      stater[`${v.name}State`] = '';
    });

    this.state = {
      alert: null,
      loading: true,
      paginationList: [],
      _currentPage: 1,
      _pageCount: 10,
      _searchText: '',
      _recordsFiltered: null,
      walletCreation: stater,
      _walletCreateState: []
    };

  }

  cancel() {
    let newState = this.state.walletCreation;
    newState.create = true;
    Object.keys(newState).forEach((key) => {
      newState[`${key}State`] = '';
      newState[`${key}`] = '';
    });

    this.setState({ editid: '', walletCreation: newState });
  }

  componentWillUnmount() {
    document.querySelector('.navbar-nav .wallet-menu') && document.querySelector('.navbar-nav .wallet-menu .nav-link').classList.remove('active');
  }

  componentDidMount() {
    document.querySelector('.navbar-nav .wallet-menu') && document.querySelector('.navbar-nav .wallet-menu .nav-link').classList.add('active');
    const { dispatch, authentication } = this.props;
    let { user } = authentication;
    const userrole = user && user.user.role;
    const name = userrole;
    const isAdmin = name.toString().trim().toLowerCase() === "admin";
    const isManager = name.toString().trim().toLowerCase() === "mananger";
    const isEmployee = name.toString().trim().toLowerCase() === "staff";
    if (!isAdmin) {
      return this.props.history.push('/admin/dashboard');
    }

    const { _currentPage, _searchText, _pageCount } = this.state;

    new List(this.refs["first-list"], {
      valueNames: ["id", "wallet_name", "credit_limit", "amount_remaining"],
      listClass: "list"
    });

    dispatch(walletActions.listWalletlimit(_currentPage, _searchText, _pageCount)).then(() => {
      var filter = this.props.list.list.recordsFiltered;
      this.setState({
        list: this.props.list,
        _recordsFiltered: filter, loading: false
      });
    });
    // var filter=this.props.wallet.list.recordsFiltered;

    this.setState({
      list: this.props.wallet,
      _walletCreateState: _walletCreate,
      //  _recordsFiltered: filter, loading: false
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch } = this.props;
    const { loading, _currentPage, _searchText, _pageCount } = this.state;
    if (_currentPage != prevState._currentPage || _searchText != prevState._searchText || _pageCount != prevState._pageCount) {
      dispatch(walletActions.listWalletlimit(_currentPage, _searchText, _pageCount)).then(() => {

        var filter = this.props.list.list.recordsFiltered;
        this.setState({
          list: this.props.list,
          _recordsFiltered: filter, loading: false
        });
      });


    }

    if (!this.state.editid)
      return null;
    const { id } = this.state.editid;
    if (this.state.editid) {

      if (prevState.wallet != this.state.wallet) {

        const { list, wallet } = this.state;
        let newState = this.state.walletCreation;

        newState.create = true;
        newState.wallet_name = wallet.wallet[0].wallet_name;
        newState.credit_limit = wallet.wallet[0].credit_limit;
        newState.amount_remaining = wallet.wallet[0].amount_remaining;
        this.setState({
          walletCreation: newState,
          list: this.state.list,
          wallet: wallet
        });
      }
    }
    else {
      if (this.props.list !== prevProps.list) {
        this.setState({
          _walletCreateState: _walletCreate
        });
      }
    }
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  confirmedAlert = (id, name) => {
    const { dispatch } = this.props;
    const { loading, _currentPage, _searchText, _pageCount } = this.state;
    dispatch(walletActions.deleteWallet(id)).then(() => {
      dispatch(walletActions.listWalletlimit(_currentPage, _searchText, _pageCount)).then(() => {
        var listvalue1 = this.props.list;
        var filter = this.props.list.list.recordsFiltered;
        let listvalue = listvalue1;
        this.setState({
          list: listvalue,
          _recordsFiltered: filter,
          loading: false
        });
      });
    });
    var content = "Your Wallet:" + name.trim() + "has been deleted";
    this.setState({
      alert: (
        <ReactBSAlert
          success
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-200px" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary btn-sm"
          confirmBtnText="Ok"
          btnSize=""
        >
          {content}

        </ReactBSAlert>
      )
    });
  };
  delete(id, name) {
    var title = (<>Are you sure want to delete <br /> Wallet: {name.trim()} ?</>);
    var canceltext = "Yes, delete it!"
    var content = "You won't be able to revert this!";

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          closeOnClickOutside={false}
          showCancel
          style={{ display: "block", marginTop: "-200px" }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.confirmedAlert(id, name)}
          showCancel
          confirmBtnBsStyle="secondary btn-sm"
          confirmBtnText="Cancel"
          cancelBtnBsStyle="danger btn-sm"
          cancelBtnText={canceltext}
          btnSize=""
        >
          {content}
        </ReactBSAlert>
      )
    });

  }
  notify(place, message, Title) {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {Title}
          </span>
          <br />
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: "default",
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  }



  onShowSizeChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
  }

  onChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
  }


  searchDataPagination(e) {
    this.setState({ _searchText: e.target.value.trim(), loading: true, _currentPage: 1 });
  }


  edit(id) {
    let { dispatch, wallet } = this.props;
    const { loading, _currentPage, _searchText, _pageCount } = this.state;
    wallet['list'] = (this.state.list) ? this.state.list : wallet['list'];
    if (id) {
      let newState = this.state.walletCreation;
      newState.create = true;
      newState.wallet_nameState = '';
      newState.credit_limitState = '';
      newState.amount_remainingState = '';

      dispatch(walletActions.GetWallet(id, wallet)).then(() => {

        var filter = this.state._recordsFiltered;
        var wallets = this.props.wallet.wallet;

        this.setState({
          editid: id,
          list: this.state.list,
          wallet: wallets,
          style: '1',
          _recordsFiltered: filter,
          //  _walletCreateState:states,
          walletCreation: newState,
          loading: false
        });
      });
    }
  }

  walletCreationForm = (e, stateName, isReq, regexp, regexInvalid, invalidFeedback) => {
    let newState = this.state.walletCreation;
    newState[stateName] = e.target.value;
    newState[stateName + "State"] = "valid";
    if (e.target.value.trim() === "" && isReq) {
      newState[stateName + "State"] = "invalid";
      newState[stateName + "Feedback"] = invalidFeedback;
      newState.create = false;
    } else if (e.target.value.trim() !== "" && regexp) {
      var regex = new RegExp(regexp);
      if (!regex.test(e.target.value.trim())) {
        newState[stateName + "Feedback"] = regexInvalid;
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      walletCreation: newState
    });
  };
  validatewalletCreationForm = () => {
    const { dispatch } = this.props;
    let newState = this.state.walletCreation;

    let states = this.state._walletCreateState;

    states[0].invalidFeedback = 'Wallet Name is Required.';
    states[1].invalidFeedback = 'Credit Limit is Required.';
    states[2].invalidFeedback = 'Amount Remaining is Required.';

    if (states[0].invalidFeedback == '' && this.state.editid != '') {
      this.setState({
        _walletCreateState: states

      });
    }

    newState.create = true;
    _walletCreate.forEach((v, i) => {
      // && v.isReq
      if (newState[`${v.name}`].toString().trim() === "") {
        newState[`${v.name}State`] = v.invalid;
        newState[`${v.name}Feedback`] = v.invalidFeedback;
        newState.create = false;
      } else if (v.regex && newState[`${v.name}`].toString().trim() !== "") {
        var regex = new RegExp(v.regex);
        if (!regex.test(newState[`${v.name}`].toString().trim())) {
          newState[`${v.name}Feedback`] = v.regexInvalid;
          newState[`${v.name}State`] = v.invalid;
          newState.create = false;
        } else {
          newState[`${v.name}State`] = v.valid;
        }
      } else {
        newState[`${v.name}State`] = v.valid;
      }
    });
    this.setState({
      walletCreation: newState
    });

    if (this.state.editid) {
      if (this.state.walletCreation.create) {
        let walletData = {
          wallet_name: this.state.walletCreation.wallet_name,
          credit_limit: this.state.walletCreation.credit_limit,
          amount_remaining: this.state.walletCreation.amount_remaining,
          id: this.state.editid
        };
        let walletlist1 = this.props.list.list;
        const { loading, _currentPage, _searchText, _pageCount } = this.state;
        dispatch(walletActions.editWallet(walletData, walletlist1)).then(() => {

          let newState = this.state.walletCreation;

          if (this.props.wallet.error) {

            let data = this.props.wallet.error.response.data;
            newState.create = true;
            Object.keys(data).forEach((key) => {
              newState[`${key}State`] = 'invalid';
              newState[`${key}Feedback`] = data[key];
              newState.create = false;
            });

            this.state.wallet = data;
            //  let newStates = this.state.walletCreation;
            //     newStates.create = true;
            //     newStates.wallet_name = walletData.wallet_name;
            //     newStates.credit_limit = walletData.credit_limit;
            //     newStates.amount_remaining = walletData.amount_remaining;
            this.setState({
              //   list: this.state.list,
              //   wallet: walletData,
              //   editid:this.state.editid,
              //  _recordsFiltered: this.state._recordsFiltered,
              //   loading: false ,
              //   editid:walletData.id,
              //  walletCreation: newState,
            });
            var message = "Wallet Not Updated";
          } else {
            var message = "Wallet Has Been Updated Successfully";
            var newState1 = this.state.walletCreation;
            newState1.create = true;
            newState1.wallet_name = '';
            newState1.credit_limit = '';
            newState1.amount_remaining = '';

            newState1.wallet_nameState = '';
            newState1.credit_limitState = '';
            newState1.amount_remainingState = '';

            var style = '1';
            var updata = walletData;
            var listvalue = this.props.list.list.data;

            var climit1 = walletData.credit_limit;
            var am1 = walletData.amount_remaining;
            var clfix1 = parseFloat(Math.round(climit1 * 100) / 100).toFixed(2);
            var arfix1 = parseFloat(Math.round(am1 * 100) / 100).toFixed(2);
            Object.keys(listvalue).forEach((key) => {
              if (listvalue[key].id == this.state.editid) {
                listvalue[key].wallet_name = walletData.wallet_name;
                listvalue[key].credit_limit = clfix1;
                listvalue[key].amount_remaining = arfix1;
              }
            });

            this.setState({
              list: this.state.list,
              wallet: listvalue,
              walletCreation: newState1,
              //style:style,
              editid: '',
              _recordsFiltered: this.state._recordsFiltered,
              loading: false
            });
          }
          let Title = "Wallet Update";
          this.notify("tc", message, Title);

        });
      }
    }
    else {
      if (this.state.walletCreation.create) {
        let walletlist = this.props.list;
        let walletData = {
          wallet_name: this.state.walletCreation.wallet_name,
          credit_limit: this.state.walletCreation.credit_limit,
          amount_remaining: this.state.walletCreation.amount_remaining
        };
        const { loading, _currentPage, _searchText, _pageCount } = this.state;
        dispatch(walletActions.createWallet(walletData, walletlist)).then(() => {

          let newState = this.state.walletCreation;
          if (this.props.wallet.error) {
            let data = this.props.wallet.error.response.data;
            newState.create = true;
            Object.keys(data).forEach((key) => {
              newState[`${key}State`] = 'invalid';
              newState[`${key}Feedback`] = data[key];
              newState.create = false;
            });
            var message = "Wallet Not Created";
            this.setState({
              editid: '',
              list: this.state.list,
              //wallet: walletlist,
              walletCreation: newState,
              loading: false
            });
          } else {
            var message = "Wallet Has Been Created Successfully";

            let newState = this.state.walletCreation;
            newState.create = true;
            newState.wallet_name = '';
            newState.credit_limit = '';
            newState.amount_remaining = '';
            newState.wallet_nameState = '';
            newState.credit_limitState = '';
            newState.amount_remainingState = '';

            var getcurrentdata = this.props.wallet.items.data;
            var climit = getcurrentdata.credit_limit;
            var am = getcurrentdata.amount_remaining;
            var clfix = parseFloat(Math.round(climit * 100) / 100).toFixed(2);
            var arfix = parseFloat(Math.round(am * 100) / 100).toFixed(2);

            var dblist = this.state.list.list.data;
            var pagecount = this.state._pageCount;
            if (dblist.length >= pagecount) {
              dblist.pop();
            }

            dblist.reverse();
            dblist.push({
              id: getcurrentdata.id,
              wallet_name: getcurrentdata.wallet_name,
              credit_limit: clfix,
              amount_remaining: arfix,
              updated_at: getcurrentdata.updated_at,
              created_at: getcurrentdata.created_at,
            });
            dblist.reverse();

            var filter = this.state._recordsFiltered + 1;
            var style = '1';
            this.setState({
              editid: '',
              list: walletlist,
              wallet: walletlist,
              walletCreation: newState,
              //  style:style,
              _recordsFiltered: filter,
              loading: false
            });
          }

          let Title = "Wallet Creation";
          this.notify("tc", message, Title);

        });

      }
    }
  }



  render() {
    const { loading, _currentPage, _pageCount, _searchText, _recordsFiltered } = this.state;

    const listTable = [];
    let { wallet, list } = this.props;

    if (this.state.list) {
      list = this.state.list;
    }
    if (!this.state.editid) {
      var buttoncreate = "Create";
    } else {
      var buttoncreate = "Update";
    }

    let recordsFiltered = 0;
    let count = _pageCount;
    let pageCount = 0;
    let currentPage = _currentPage;

    if (list && list.list && list.list.data && list.list.recordsFiltered) {
      recordsFiltered = list.list.recordsFiltered;
      pageCount = Math.ceil(recordsFiltered / count);

    }
    if (this.state.style) {
      var styles = {
        // border: '1px solid #dee2e6'
      };
    }

    let styles1 = {

      width: '100px',

    };

    const pagination = (
      <div className="row col-md-12 m-0 p-0 paginationrow mb-3 d-flex justify-content-between">
        <div className="col-md-3 px-0 mb-2 mb-lg-0"><h3>Wallet List</h3></div>
        <div className="col-md-3 col-sm-12 align-items-center px-0 mb-2 mb-lg-0">
          <input type="text" value={_searchText} onChange={(e) => { this.searchDataPagination(e) }} className="form-control form-control-sm" placeholder="Search..." />
        </div>
        <div className="col-md-7 col-sm-12 align-items-center justify-content-center d-flex">
        </div>
      </div>
    );
    let startVal = ((_currentPage * _pageCount) - (_pageCount - 1));
    list && list.list && list.list.data && list.list.data.forEach((v, i) => {
      listTable.push(
        <tr key={i}>
          <td className="p-1 text-center id">{startVal + i}</td>
          <td className="p-1 wallet_name">{v.wallet_name}</td>
          <td className="p-1 text-right credit_limit">{v.credit_limit}</td>
          <td className="p-1 text-right amount_remaining">{v.amount_remaining}</td>

          <td className="p-1 text-right">

            <Button className="btn btn-sm btn-success" size="sm" onClick={() => this.edit(v.id)}> <i className="fas fa-pencil-alt"></i></Button>

            <Button color="danger" size="sm" onClick={() => this.delete(v.id, v.wallet_name)}><i className="fas fa-trash-alt"></i></Button>

          </td>
        </tr>
      );
    });
    if (!this.state._walletCreateState)
      return null;
    return (
      <>
        {this.state.alert}
        <NotificationAlert ref="notificationAlert" />
        {(loading) ? (<i className="fas fa-circle-notch fa-spin display-3 sticky-top float-left" style={{ margin: '0.5rem 1rem', marginTop: -70 }}></i>) : ''}
        <SimpleHeader name="Manage Wallet" parentName="Wallet" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col-md-3 px-1">
              <div className="card-wrapper">
                <Card className="mb-2">
                  <CardHeader><h3>Edit / Create Wallet</h3></CardHeader>
                  <CardBody>
                    <Form className="needs-validation" ref="wallet" noValidate onSubmit={this.validatewalletCreationForm}>
                      <div className="form-row mc-minimize" >
                        {this.state._walletCreateState.map((v, i) => {
                          return (
                            <Col key={i} className="mb-2" md={12}>
                              <label className="form-control-label " htmlFor={v.name} > {v.label} </label>
                              {(v.type == "input") ?
                                (<><Input id={v.name} style={styles} className="form-control-sm" placeholder={v.label} type="text"
                                  valid={this.state.walletCreation[v.state] === v.valid}
                                  invalid={this.state.walletCreation[v.state] === v.invalid}
                                  value={this.state.walletCreation[v.name] || ""}
                                  onChange={e => this.walletCreationForm(e, v.name, v.isReq, v.regex, v.regexInvalid, v.invalidFeedback)} /></>) : ''}
                              <div className="valid-feedback"></div>
                              <div className="invalid-feedback">
                                {(this.state.walletCreation[`${v.name}Feedback`]) ? this.state.walletCreation[`${v.name}Feedback`] : v.invalidFeedback}
                              </div>
                            </Col>
                          )
                        })}

                        <Col md="12">
                          <div className="d-flex align-items-center justify-content-start m-0">
                            <Button
                              className="mt-1 form-control-sm"
                              color="primary"
                              type="button" size="sm" onClick={this.validatewalletCreationForm}>
                              {buttoncreate}
                            </Button>
                            <Button
                              className="mt-1 ml-1 form-control-sm"
                              color="warning"
                              type="button" size="sm" onClick={() => this.cancel()}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-md-9 px-1">
              <div className="card-wrapper">
                <Card body className="mb-2 pb-5">
                  {pagination}
                  <div className="table-responsive" ref="">
                    <Table hover className="align-items-center table-flush wallettable">
                      <thead className="thead-light">
                        <tr>
                          <th className="sort px-1 py-2 text-center" data-sort="id" scope="col">
                            S.No
                          </th>
                          <th className="sort px-1 py-2" data-sort="wallet_name" scope="col">
                            Wallet Name
                          </th>
                          <th className="sort px-1 py-2 text-right" data-sort="credit_limit" scope="col">
                            Credit limit
                          </th>
                          <th className="sort px-1 py-2 text-right" data-sort="amount_remaining" scope="col">
                            Amount Remaining
                          </th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {listTable.length > 0 && listTable || (<tr><td colSpan="9" className="text-center">No Rows Found</td></tr>)}
                      </tbody>
                    </Table>
                  </div>
                  <div className="row paginationrow col p-0 m-0 mt-4 d-flex justify-content-left align-items-left">

                    <Pagination
                      selectComponentClass={Select}
                      showSizeChanger
                      defaultPageSize={_pageCount}
                      defaultCurrent={_currentPage}
                      onShowSizeChange={(current, pageSize) => this.onShowSizeChange(current, pageSize)}
                      onChange={(current, pageSize) => this.onChange(current, pageSize)}
                      total={_recordsFiltered}
                      locale={localeInfo}
                    />

                  </div>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { wallet, walletedit, list, authentication } = state;
  return { wallet, walletedit, list, authentication };
}

// export default connect(mapStateToProps)(listWallet);
const connectedlistWallet = connect(mapStateToProps)(listWallet);
export { connectedlistWallet as listWallet };
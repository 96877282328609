/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
// style={{marginBottom : '28px', marginTop : '-23px'}} 
class Calendar extends React.Component {
  render() {
    return (
      <>
        <>
          <div id="res_footer">
            <style>
              {
                '\
          .footer {\
              padding: 0px !important;\
          }'
              }
            </style>
            <footer
              style={{
                backgroundColor: '#F8F8F8',
                borderTop: '1px solid #E7E7E7',
                textAlign: 'center',
                // padding: '20px',
                position: 'fixed',
                left: '0',
                bottom: '0',
                // height: '60px',
                width: '100%',
                zIndex: '999',
              }}
              className="footer pt-0"
            >
              <Row className="align-items-center justify-content-lg-between">
                <div style={{ margin: 'auto' }}>
                  <div
                    style={{ padding: '20px' }}
                    className="copyright text-center text-lg-left text-muted"
                  >
                    © {new Date().getFullYear()}{' '}
                    <a
                      className="font-weight-bold ml-1 text-center"
                      href="https://www.askantech.com/"
                      target="_blank"
                    >
                      Askan Technologies
                    </a>
                  </div>
                </div>
              </Row>
            </footer>
          </div>
        </>
      </>
    );
  }
}

export default Calendar;

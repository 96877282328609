import { userConstants } from '../_constants';

export function insurance(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_INSURANCE_REQUEST:
      return {
        create: action.insurance
      };
    case userConstants.CREATE_INSURANCE_SUCCESS:
      return {
        create: action.insurance
      };
    case userConstants.CREATE_INSURANCE_FAILURE:
      return {};
    case userConstants.UPDATE_INSURANCE_REQUEST:
      return {
        update: action.insurance
      };
    case userConstants.UPDATE_INSURANCE_SUCCESS:
      return {
        update: action.insurance
      };
    case userConstants.UPDATE_INSURANCE_FAILURE:
      return {};
    case userConstants.DELETE_INSURANCE_REQUEST:
      return {
        delete: action.insurance
      };
    case userConstants.DELETE_INSURANCE_SUCCESS:
      return {
        delete: action.insurance
      };
    case userConstants.DELETE_INSURANCE_FAILURE:
      return {};

    case userConstants.LIST_INSURANCE_REQUEST:
      return {
        list: action.insurance
      };
    case userConstants.LIST_INSURANCE_SUCCESS:
        
      return {
        list: action.insurance
      };
    case userConstants.LIST_INSURANCE_FAILURE:
      return {};
    default:
      return state
  }
}
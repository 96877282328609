import React from "react";
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardTitle, Table, Button } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { Autocomplete } from '../../_components/common/autoComplete';
import { userActions } from "../../_actions/user.actions";
import Select from 'rc-select';

import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';

class CustomerHistory extends React.Component {
    state = { loading: true, _page: 1, _count: 10, _totalRecords: 0, _totalRecordsFiltered: 0, _custList: [], _searchText: '', _hotelsCount: 0, _passportsCount: 0, _ticketsCount: 0, _toursCount: 0, _visasCount: 0, _vehiclesCount: 0, _clearValue: false, _inputFocus: false, _cUser: '' };
    slideInputLeft = (e, reactionClass, triggerClass) => {
        e.preventDefault();
        if(e.currentTarget.classList.contains('input-clear-icon'))
            this.setState({ _clearValue: true, _inputFocus: false });
        else
            this.setState({ _clearValue: false, _inputFocus: true });
        let reactionElem = document.querySelector(reactionClass);
        let triggerElem = document.querySelector(triggerClass);
        if(triggerElem){
            triggerElem.classList.toggle('d-flex');
            triggerElem.classList.toggle('d-none');
        }if(reactionElem){
            reactionElem.classList.toggle('inputSlideLeft');
            reactionElem.classList.toggle('d-none');
        }
    }

    setStatesModalFull = (_customerDetails) => {
        const { hotels, passports, tickets, tours, visas } = _customerDetails;
        let _ticketsCount = tickets.length;
        let _passportsCount = 0;
        let _visasCount = 0;
        let _toursCount = 0;
        let _hotelsCount = 0;
        _ticketsCount && tickets.forEach((v, i) => {
            v.services_ticket.forEach((vv, ii) => {
                vv.services.forEach((vvv, iii) => {
                    if(vvv.name.trim().toLowerCase() === "visa")
                        _visasCount = _visasCount + 1;
                    else if(vvv.name.trim().toLowerCase() === "tour")
                        _toursCount = _toursCount + 1;
                    else if(vvv.name.trim().toLowerCase() === "passport")
                        _passportsCount = _passportsCount + 1;
                    else if(vvv.name.trim().toLowerCase() === "hotel")
                        _hotelsCount = _hotelsCount + 1;
                });
            });
        });
        this.setState({ _ticketsCount: _ticketsCount, _passportsCount: _passportsCount, _visasCount: _visasCount, _toursCount: _toursCount, _hotelsCount: _hotelsCount, _cUser: (_customerDetails.firstname) ? ((_customerDetails.lastname) ? `${_customerDetails.firstname} ${_customerDetails.lastname}` : _customerDetails.firstname) : '' });
    }

    componentWillUnmount() {
        document.querySelector('.navbar-nav .history-menu') && document.querySelector('.navbar-nav .history-menu .nav-link').classList.remove('active');
    }

    componentDidMount() {
        document.querySelector('.navbar-nav .history-menu') && document.querySelector('.navbar-nav .history-menu .nav-link').classList.add('active');

        const { user } = this.props;
        const userrole = user && user.user.role;
        const name = userrole;
        const isAdmin = name.toString().trim().toLowerCase() === "admin";
        const isManager = name.toString().trim().toLowerCase() === "mananger";
        const isEmployee = name.toString().trim().toLowerCase() === "staff";
        if(!isAdmin){
          return this.props.history.push('/admin/dashboard');
        }

        let customerData = { 'page': this.state._page, 'count': this.state._count };
        this.customerDispatch(customerData);
    }

    resetCustomerHistory(){
        if(!document.querySelector('.search-input-wrapper.d-none'))
            document.querySelector('.input-clear-icon').click();
        this.setState({ _cUser: '', _ticketsCount: 0, _passportsCount: 0, _visasCount: 0, _toursCount: 0, _hotelsCount: 0, _clearValue: true, _inputFocus: false });
    }

    customerDispatch(_customerData){
        const { dispatch } = this.props;
        dispatch(userActions.getCustomerList(_customerData)).then(() => {
            const { customersList } = this.props;
            const { customers } = customersList;
            const { datas } = customers;
            const { data, totalRecordsFiltered, totalRecords } = datas;
            this.setState({ _totalRecords: totalRecords, _totalRecordsFiltered: totalRecordsFiltered, _custList: data, loading: false });
        });
    }

    searchDataPagination = (_event) => {
        this.setState({ loading: true, _searchText: _event.target.value, _page: 1 });
        let customerData = { 'page': 1, 'count': this.state._count, 'search': _event.target.value };
        this.customerDispatch(customerData);
    }

    onChange = (_current, _pageSize) => {
        this.setState({ loading: true, _count: _pageSize, _page: _current });
        let customerData = { 'page': _current, 'count': _pageSize };
        this.customerDispatch(customerData);
    }

    onShowSizeChange = (_current, _pageSize) => {
        const { _searchText } = this.state;
        this.setState({ loading: true, _count: _pageSize, _page: 1 });
        let customerData = { 'page': 1, 'count': _pageSize, 'search': _searchText };
        this.customerDispatch(customerData);
    }

    render(){
        const { loading, _custList, _page, _count, _searchText, _totalRecordsFiltered, _totalRecords, _hotelsCount, _passportsCount, _ticketsCount, _toursCount, _visasCount, _vehiclesCount, _clearValue, _inputFocus, _cUser } = this.state;
        const _start = (_page * _count) - _count + 1;
        const listTable = [];
        _custList && _custList.forEach((v, i) => {
            listTable.push(
                <tr key={i}>
                    <td className="p-1 text-left cust_id">{_start + i}</td>
                    <td className="p-1 cust_name"><small className="d-block">{v.firstname || "-"}</small>{v.phone || v.secondary || "-"}</td>
                    <td className="p-1 cust_email">{v.email.toString().toLowerCase() || "-"}</td>
                    <td className="p-1 address"> {(v.address || v.state || v.city || v.zipcode) ? (`${v.address} ${v.state} ${v.city} ${v.zipcode}`) : '-'} </td>
                    <td className="p-1 dob">{v.dob || "-"}</td>
                    <td className="p-1 text-right actions">
                        <Button color="primary" size="sm" onClick={(e) => this.setStatesModalFull(v)}><i className="fas fa-eye"></i></Button>
                    </td>
                </tr>
            );
        });
        let filteredData = [];
        if(_totalRecords != _totalRecordsFiltered && _totalRecordsFiltered)
            filteredData.push(<small className="text-info small">Filtered {_totalRecordsFiltered} from {_totalRecords} records.</small>);

        const pagination = (
            <div className="row col-md-12 m-0 p-0 paginationrow mb-3 d-flex justify-content-between">
                <div className="col-md-6 px-0 mb-2 mb-lg-0"><h3>Customer List {filteredData}</h3></div>
                <div className="col-md-3 col-sm-12 align-items-center px-0 mb-2 mb-lg-0">
                    <input type="text" value={_searchText} onChange={(e) => { this.searchDataPagination(e) }} className="form-control form-control-sm" placeholder="Search..."/>
                </div>
                <div className="col-md-7 col-sm-12 align-items-center justify-content-center d-flex">
                </div>
            </div>
        );

        return (
            <>
                {(loading) ? (<i className="fas fa-circle-notch fa-spin display-3 sticky-top float-left" style={{ margin: '0.5rem 1rem', marginTop: -70 }}></i>) : ''}
                <SimpleHeader name="History" parentName="Customer" />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col md="12" className="px-1">
                            <Card className="mb-2">
                                <CardBody>
                                    <span className="search-wrapper d-flex justify-content-start align-items-center mb-4">
                                        <span className="search-icon align-items-center d-flex btn btn-secondary border-radius-25-px" onClick={(e) => { this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon'); }}>
                                            <p className="mb-0 mr-1">Search Customers</p>
                                            <img src={require('../../assets/img/icons/search.png')} height={22} width={22} className="cursor-pointer" />
                                        </span>
                                        <span className="search-input-wrapper w-75 d-none align-items-center">
                                            <Autocomplete setStatesModalFull={(obj) => this.setStatesModalFull(obj)} additionalClasses="d-inline border-radius-25-px" big={true} suggestions={['One', 'Two', 'Three']} clearValue={_clearValue} _inputFocus={_inputFocus} />
                                            <span className="input-clear-icon cursor-pointer ml--4" onClick={(e) => this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon')}>&times;</span>
                                        </span>
                                    </span>
                                    <Row>
                                        {_cUser ? (<div className="mb-lg-0 mb-2 px-1 py-2 d-flex w-100 justify-content-between align-items-center ">
                                            <span> <h3>{_cUser}</h3> </span>
                                            <span> <Button className="btn btn-sm btn-danger" onClick={() => this.resetCustomerHistory()}>Reset</Button> </span>
                                        </div>) : ''}
                                        <Col md="6" xl="2" className="mb-lg-0 mb-2 px-1">
                                            <Card className="bg-gradient-primary border-0 mb-0">
                                                <CardBody className="px-2">
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                                            Tickets
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                                                            {_ticketsCount === 0 ? `--` : _ticketsCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto col">
                                                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                                            <i className="fas fa-ticket-alt"></i>
                                                        </div>
                                                    </div>
                                                </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6" xl="2" className="mb-lg-0 mb-2 px-1">
                                            <Card className="bg-gradient-info border-0 mb-0">
                                                <CardBody className="px-2">
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                                            Passports
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                                                            {_passportsCount === 0 ? `--` : _passportsCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto col">
                                                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                            <i className="fas fa-passport"></i>
                                                        </div>
                                                    </div>
                                                </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6" xl="2" className="mb-lg-0 mb-2 px-1">
                                            <Card className="bg-gradient-danger border-0 mb-0">
                                                <CardBody className="px-2">
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                                            Visas
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                                                            {_visasCount === 0 ? `--` : _visasCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto col">
                                                        <div className="icon icon-shape bg-gradient-pink text-white rounded-circle shadow">
                                                            <i className="fas fa-fighter-jet"></i>
                                                        </div>
                                                    </div>
                                                </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6" xl="2" className="mb-lg-0 mb-2 px-1">
                                            <Card className="bg-gradient-default border-0 mb-0">
                                                <CardBody className="px-2">
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                                            Tours
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                                                            {_toursCount === 0 ? `--` : _toursCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto col">
                                                        <div className="icon icon-shape bg-gradient-purple text-white rounded-circle shadow">
                                                            <i className="fas fa-suitcase-rolling"></i>
                                                        </div>
                                                    </div>
                                                </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6" xl="2" className="mb-lg-0 mb-2 px-1">
                                            <Card className="bg-gradient-success border-0 mb-0">
                                                <CardBody className="px-2">
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                                            Hotels
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                                                            {_hotelsCount === 0 ? `--` : _hotelsCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto col">
                                                        <div className="icon icon-shape bg-gradient-secondary text-black rounded-circle shadow">
                                                            <i className="fas fa-hotel"></i>
                                                        </div>
                                                    </div>
                                                </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6" xl="2" className="mb-lg-0 mb-2 px-1">
                                            <Card className="bg-gradient-warning border-0 mb-0">
                                                <CardBody className="px-2">
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 text-white">
                                                            Vehicles
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                                                            {_vehiclesCount === 0 ? `--` : _vehiclesCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-auto col">
                                                        <div className="icon icon-shape bg-gradient-gray-dark text-white rounded-circle shadow">
                                                            <i className="fas fa-car-crash"></i>
                                                        </div>
                                                    </div>
                                                </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    {pagination}
                                    <div className="table-responsive" ref="first-list">
                                        <Table hover className="align-items-center table-flush table-customers">
                                        <thead className="thead-light">
                                            <tr>
                                            <th className="sort px-1 py-2 text-center" data-sort="ticket_id" scope="col"> # </th>
                                            <th className="sort px-1 py-2" data-sort="cust_name" scope="col"> Details </th>
                                            <th className="sort px-1 py-2" data-sort="cust_email" scope="col"> Email </th>
                                            <th className="sort px-1 py-2" scope="col" data-sort="address"> Address </th>
                                            <th className="sort px-1 py-2" data-sort="dob" scope="col"> D.O.B. </th>
                                            <th scope="col" className="px-1 py-2 text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list">
                                            {listTable.length > 0 && listTable || ((loading) ? (<tr><td colSpan="9" className="text-center">Processing..</td></tr>) : (<tr><td colSpan="9" className="text-center">No Rows Found</td></tr>))}
                                        </tbody>
                                        </Table>
                                    </div>
                                    <div className="pagination-list mt-3">
                                        <Pagination selectComponentClass={Select} showSizeChanger defaultPageSize={_count} defaultCurrent={_page} onShowSizeChange={(current, pageSize) => this.onShowSizeChange(current, pageSize)} onChange={(current, pageSize) => this.onChange(current, pageSize)} total={_totalRecordsFiltered} locale={localeInfo}/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn, loggedIn, user } = state.authentication;
    const { customersList } = state;
    return { loggingIn, loggedIn, user, customersList };
}

const connectedCustomerHistory = connect(mapStateToProps)(CustomerHistory);
export { connectedCustomerHistory as CustomerHistory };
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
// node.js library that concatenates classes (strings)
import classnames from 'classnames';
// javascipt plugin for creating charts
import { Chart } from 'chart.js';
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

// core components
import CardsHeader from '../../../components/Headers/CardsHeaderFunc.jsx';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from '../../../variables/charts.jsx';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import styles from '../../../_views/_enquiry/enquiry.module.css';
import assStyles from './assigneeDashboard.module.css';
// import cardStyles from './assigneeDashboard.module.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function DashboardFuncAssignee() {
  let [monthCount, setMonthCount] = useState([]);

  let [loading, setLoading] = useState(false);
  let [usersList, setUsersList] = useState([]);

  let [open, setOpen] = useState('0');
  let [inProgress, setInProgress] = useState('0');
  let [hold, setHold] = useState('0');
  let [completed, setCompleted] = useState('0');
  let [month, setMonth] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]);
  let [dataRecords, setDataRecords] = useState([]);

  let getEnquiryStatusCount = () => {
    let user = localStorage.getItem('user');
    let parseUser = JSON.parse(user);
    let id = parseUser.id
    let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/getAll-enquiry-status-count-dept?id=${id}`;
    axios
      .get(dataURL)
      .then((res) => {
        console.log('HEARDERcARD_COUNT', res.data.res1);
        setOpen(res.data.res1[0].open);
        setInProgress(res.data.res1[0].in_progress);
        setHold(res.data.res1[0].hold);
        setCompleted(res.data.res1[0].completed);
        // let monthCount = []
        // res.data.res2.map((monData) => {
        //   console.log('mondata_mondata', monData);
        //   month.map((m) => {
        //    if(m == monData.EnquiryCreatedDate){
        //     console.log(monData.EnquiryCreatedDate, 'ifMOnthecont');
        //     monthCount.push(monData.EnquiryCount)
        //    }else{

        //       monthCount.push('0');
        //     console.log('elseMonthCount');
        //    }

        //   })

        // })
        month.map((m) => {
          console.log(m, 'monthttt');
          let flag = 0;
          res.data.res2.map((monData) => {
            if (m == monData.EnquiryCreatedDate) {
              flag = 1;
              console.log(monData.EnquiryCreatedDate, 'ifMOnthecont');
              monthCount.push(monData.EnquiryCount);
            }
          });
          if (flag == 0) monthCount.push('0');
        });
        console.log('array_moncount', monthCount);
      })
      .catch((err) => {
        console.log('_hearcount_eror', err);
      });
  };

  useEffect(() => {
    getEnquiryStatusCount();
  }, []);

  //get the list of users
  // let getAllUsersList = () => {
  //   setLoading(true);
  //   let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/get-users-list-dashboard`;
  //   axios
  //     .get(dataURL)
  //     .then((res) => {
  //       console.log('ENQUIRYLISTING_GETTING_VALUE', res.data.res1);
  //       setUsersList(res.data.res1);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log('ENQUIRYLISTING_GETTING_ERROR', error);
  //     });
  // };

  // useEffect(() => {
  //   getAllUsersList();
  // }, []);

  // Colors
  var colors = {
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529',
    },
    theme: {
      default: '#172b4d',
      primary: '#5e72e4',
      secondary: '#f4f5f7',
      info: '#11cdef',
      success: '#2dce89',
      danger: '#f5365c',
      warning: '#fb6340',
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
  };

  // Example 1 of Chart inside src/views/dashboards/Dashboard.jsx
  let chartExample1 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[700],
              zeroLineColor: colors.gray[700],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || '';
            var yLabel = item.yLabel;
            var content = '';

            if (data.datasets.length > 1) {
              content += label;
            }

            content += yLabel + ' tickets';
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Performance',
            data: monthCount,
          },
        ],
      };
    },
    data2: (canvas) => {
      return {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Performance',
            data: monthCount,
          },
        ],
      };
    },
  };

  // Methods
  var mode = 'light';

  var fonts = {
    base: 'Open Sans',
  };
  // Chart.js global options
  function chartOptions() {
    // Options
    var options = {
      defaults: {
        global: {
          responsive: true,
          maintainAspectRatio: false,
          defaultColor: mode === 'dark' ? colors.gray[700] : colors.gray[600],
          defaultFontColor:
            mode === 'dark' ? colors.gray[700] : colors.gray[600],
          defaultFontFamily: fonts.base,
          defaultFontSize: 13,
          layout: {
            padding: 0,
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 16,
            },
          },
          elements: {
            point: {
              radius: 0,
              backgroundColor: colors.theme['primary'],
            },
            line: {
              tension: 0.4,
              borderWidth: 4,
              borderColor: colors.theme['primary'],
              backgroundColor: colors.transparent,
              borderCapStyle: 'rounded',
            },
            rectangle: {
              backgroundColor: colors.theme['warning'],
            },
            arc: {
              backgroundColor: colors.theme['primary'],
              borderColor: mode === 'dark' ? colors.gray[800] : colors.white,
              borderWidth: 4,
            },
          },
          tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
          },
        },
        doughnut: {
          cutoutPercentage: 83,
          legendCallback: function (chart) {
            var data = chart.data;
            var content = '';

            data.labels.forEach(function (label, index) {
              var bgColor = data.datasets[0].backgroundColor[index];

              content += '<span class="chart-legend-item">';
              content +=
                '<i class="chart-legend-indicator" style="background-color: ' +
                bgColor +
                '"></i>';
              content += label;
              content += '</span>';
            });

            return content;
          },
        },
      },
    };

    // yAxes
    Chart.scaleService.updateScaleDefaults('linear', {
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        color: mode === 'dark' ? colors.gray[900] : colors.gray[300],
        drawBorder: false,
        drawTicks: false,
        lineWidth: 1,
        zeroLineWidth: 1,
        zeroLineColor: mode === 'dark' ? colors.gray[900] : colors.gray[300],
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
      },
      ticks: {
        beginAtZero: true,
        padding: 10,
        callback: function (value) {
          if (!(value % 5)) {
            return value;
          }
        },
      },
    });

    // xAxes
    Chart.scaleService.updateScaleDefaults('category', {
      gridLines: {
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      ticks: {
        padding: 20,
      },
      maxBarThickness: 10,
    });

    return options;
  }

  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState('data1');
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === 'data1' ? 'data2' : 'data1');
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  let imageUrl = `${process.env.REACT_APP_BACKEND_URL}Images/`;
  let history = useHistory();

  return (
    <>
      <style>
        {
          '\
          .react-multiple-carousel__arrow::before {\
            font-size: 13px !important;\
          }'
        }
        {
          '\
          .react-multiple-carousel__arrow::after {\
            font-size: 13px !important;\
          }'
        }
        {
          '\
          .react-multiple-carousel__arrow {\
            min-width: 28px !important;\
            min-height: 28px !important;\
          }'
        }
      </style>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {/* <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{' '} */}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    <i className="fas fa-home text-white" />
                    {/* </a> */}
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    <span className="text-white">Dashboards</span>
                    {/* </a> */}
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem> */}
                </Breadcrumb>
              </Col>
              {/* <Col className="text-right" lg="6" xs="5">
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  New
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Filters
                </Button>
              </Col> */}
            </Row>
            {/* DESKTOP RESPONSIVE CARD */}
            <Row className={assStyles.hideCardSection}>
              <Col md="6" xl="3">
                <Card
                  onClick={() => {
                    history.push({
                      pathname:
                        window.screen.width < 768
                          ? '/admin/enquiry-res-table'
                          : '/admin/enquiry',
                      state: { tStatus: 1 },
                    });
                  }}
                  style={{ padding: '20px 0px 6px', cursor: 'pointer' }}
                  className="card-stats"
                >
                  <CardBody>
                    <Row className={assStyles.cardReverse}>
                      <div className="col-md-9">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Open
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{open}</span>
                      </div>
                      <div className="col-md-3">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <img
                            style={{ width: '34px' }}
                            src={require('../../../assets/fonts/open.png')}
                            alt=""
                          />
                        </div>
                      </div>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      paddingRight: '10px',
                    }}
                  >
                    <button
                      onClick={() => {
                        history.push({
                          pathname:
                            window.screen.width < 768
                              ? '/admin/enquiry-res-table'
                              : '/admin/enquiry',
                          state: { tStatus: 1 },
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                      className="btn btn-warning btn-sm"
                    >
                      View
                    </button>
                  </div>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  onClick={() => {
                    history.push({
                      pathname:
                        window.screen.width < 768
                          ? '/admin/enquiry-res-table'
                          : '/admin/enquiry',
                      state: { tStatus: 2 },
                    });
                  }}
                  style={{ padding: '20px 0px 6px', cursor: 'pointer' }}
                  className="card-stats"
                >
                  <CardBody>
                    <Row className={assStyles.cardReverse}>
                      <div className="col-md-9">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Progress
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {inProgress}
                        </span>
                      </div>
                      <div className="col-md-3">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35" />
                        </div>
                      </div>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      paddingRight: '10px',
                    }}
                  >
                    <button
                      onClick={() => {
                        history.push({
                          pathname:
                            window.screen.width < 768
                              ? '/admin/enquiry-res-table'
                              : '/admin/enquiry',
                          state: { tStatus: 2 },
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                      className="btn btn-success btn-sm"
                    >
                      View
                    </button>
                  </div>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  onClick={() => {
                    history.push({
                      pathname:
                        window.screen.width < 768
                          ? '/admin/enquiry-res-table'
                          : '/admin/enquiry',
                      state: { tStatus: 4 },
                    });
                  }}
                  style={{ padding: '20px 0px 6px', cursor: 'pointer' }}
                  className="card-stats"
                >
                  <CardBody>
                    <Row className={assStyles.cardReverse}>
                      <div className="col-md-9">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Hold
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{hold}</span>
                      </div>
                      <div className="col-md-3">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          {/* <i className="ni ni-chart-pie-35" /> */}
                          <img
                            style={{ width: '34px' }}
                            src={require('../../../assets/fonts/pending.png')}
                            alt=""
                          />
                        </div>
                      </div>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      paddingRight: '10px',
                    }}
                  >
                    <button
                      onClick={() => {
                        history.push({
                          pathname:
                            window.screen.width < 768
                              ? '/admin/enquiry-res-table'
                              : '/admin/enquiry',
                          state: { tStatus: 4 },
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                      className="btn btn-warning btn-sm"
                    >
                      View
                    </button>
                  </div>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  onClick={() => {
                    history.push({
                      pathname:
                        window.screen.width < 768
                          ? '/admin/enquiry-res-table'
                          : '/admin/enquiry',
                      state: { tStatus: 6 },
                    });
                  }}
                  style={{ padding: '20px 0px 6px', cursor: 'pointer' }}
                  className="card-stats"
                >
                  <CardBody>
                    <Row className={assStyles.cardReverse}>
                      <div className="col-md-9">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Completed
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {completed}
                        </span>
                      </div>
                      <div className="col-md-3">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          {/* <i className="fa fa-calendar-check-o" /> */}
                          <img
                            style={{ width: '34px' }}
                            src={require('../../../assets/fonts/completed.png')}
                            alt=""
                          />
                        </div>
                      </div>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      paddingRight: '10px',
                    }}
                  >
                    <button
                      onClick={() => {
                        history.push({
                          pathname:
                            window.screen.width < 768
                              ? '/admin/enquiry-res-table'
                              : '/admin/enquiry',
                          state: { tStatus: 6 },
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                      className="btn btn-success btn-sm"
                    >
                      View
                    </button>
                  </div>
                </Card>
              </Col>
            </Row>
            {/* MOBILE RESPONSIVE CARD */}
            <Row className={assStyles.hideMobCardSection}>
              <Carousel
                // removeArrowOnDeviceType={['tablet', 'mobile']}
                responsive={responsive}
              >
                <div>
                  <Card
                    style={{ padding: '0px 0px', margin: '5px' }}
                    className="card-stats"
                  >
                    <CardBody>
                      <Row className={assStyles.cardReverse}>
                        <div
                          style={{ padding: '5px 20px' }}
                          className="col-md-9"
                        >
                          <div className="h2 font-weight-bold mb-0">{open}</div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Open
                          </CardTitle>
                        </div>
                        <div className="col-md-3">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/open.png')}
                              alt=""
                            />
                          </div>
                        </div>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                      <div style={{ float: 'right' }}>
                        <button
                          onClick={() => {
                            history.push({
                              pathname:
                                window.screen.width < 768
                                  ? '/admin/enquiry-res-table'
                                  : '/admin/enquiry',
                              state: { tStatus: 1 },
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                          className="btn btn-warning btn-sm"
                        >
                          View
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div>
                  <Card
                    style={{ padding: '0px 0px', margin: '5px' }}
                    className="card-stats"
                  >
                    <CardBody>
                      <Row className={assStyles.cardReverse}>
                        <div
                          style={{ padding: '5px 20px' }}
                          className="col-md-9"
                        >
                          <div className="h2 font-weight-bold mb-0">
                            {inProgress}
                          </div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Progress
                          </CardTitle>
                        </div>
                        <div className="col-md-3">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-chart-pie-35" />
                          </div>
                        </div>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                      <div style={{ float: 'right' }}>
                        <button
                          onClick={() => {
                            history.push({
                              pathname:
                                window.screen.width < 768
                                  ? '/admin/enquiry-res-table'
                                  : '/admin/enquiry',
                              state: { tStatus: 2 },
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                          className="btn btn-success btn-sm"
                        >
                          View
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div>
                  <Card
                    style={{ padding: '0px 0px', margin: '5px' }}
                    className="card-stats"
                  >
                    <CardBody>
                      <Row className={assStyles.cardReverse}>
                        <div
                          style={{ padding: '5px 20px' }}
                          className="col-md-9"
                        >
                          <div className="h2 font-weight-bold mb-0">{hold}</div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Hold
                          </CardTitle>
                        </div>
                        <div className="col-md-3">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            {/* <i className="ni ni-chart-pie-35" /> */}
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/pending.png')}
                              alt=""
                            />
                          </div>
                        </div>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                      <div style={{ float: 'right' }}>
                        <button
                          onClick={() => {
                            history.push({
                              pathname:
                                window.screen.width < 768
                                  ? '/admin/enquiry-res-table'
                                  : '/admin/enquiry',
                              state: { tStatus: 4 },
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                          className="btn btn-warning btn-sm"
                        >
                          View
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div>
                  <Card
                    style={{ padding: '0px 0px', margin: '5px' }}
                    className="card-stats"
                  >
                    <CardBody>
                      <Row className={assStyles.cardReverse}>
                        <div
                          style={{ padding: '5px 20px' }}
                          className="col-md-9"
                        >
                          <div className="h2 font-weight-bold mb-0">
                            {completed}
                          </div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Completed
                          </CardTitle>
                        </div>
                        <div className="col-md-3">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            {/* <i className="fa fa-calendar-check-o" /> */}
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/completed.png')}
                              alt=""
                            />
                          </div>
                        </div>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{' '}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                      <div style={{ float: 'right' }}>
                        <button
                          onClick={() => {
                            history.push({
                              pathname:
                                window.screen.width < 768
                                  ? '/admin/enquiry-res-table'
                                  : '/admin/enquiry',
                              state: { tStatus: 6 },
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                          className="btn btn-success btn-sm"
                        >
                          View
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Carousel>
            </Row>
          </div>
        </Container>
      </div>
      <Container style={{ marginBottom: '40px' }} className="mt--6" fluid>
        <Row className="align-items-center justify-content-center">
          <Col xl="8">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 text-white mb-0">Sales value</h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      {/* <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames('py-2 px-3', {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames('py-2 px-3', {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DashboardFuncAssignee;

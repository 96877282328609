import { userConstants } from '../_constants';

export function hotelExpensesInsert(state = {}, action) {
  switch (action.type) {
    case userConstants.TOUR_EXPENSES_INSERT_REQUEST:
      return {
        data: action.hotelExpenses
      };
    case userConstants.TOUR_EXPENSES_INSERT_SUCCESS:
      return {
        data: action.hotelExpenses
      };
    case userConstants.TOUR_EXPENSES_INSERT_FAILURE:
      return {};
    default:
      return state
  }
}
export function hoteldelete(state = {}, action){
  switch (action.type) {
  case userConstants.DELETE_HOTEL_SUCCESS:
    return{
      data:action.hotel
  };
  default:
    return state
}
}
export function hotelExpensesUpdate(state = {}, action) {
  switch (action.type) {
    case userConstants.TOUR_EXPENSES_UPDATE_REQUEST:
      return {
        data: action.hotelExpenses
      };
    case userConstants.TOUR_EXPENSES_UPDATE_SUCCESS:
      return {
        data: action.hotelExpenses
      };
    case userConstants.TOUR_EXPENSES_UPDATE_FAILURE:
      return {};
    default:
      return state
  }
}
export function hotellist(state = {}, action){
  switch (action.type) {
    case userConstants.GET_HOTEL_REQUEST:
      return {
        data: action.hotellist
      };
    case userConstants.GET_HOTEL_SUCCESS:
      return {
        data: action.hotellist
      };
    case userConstants.GET_HOTEL_FAILURE:
      return {};
    default:
      return state
  }
}
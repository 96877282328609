import React, { Component } from "react";
import { connect } from 'react-redux';
import { FormGroup, Form, Input, Row, Col,Button,ButtonGroup, ListGroupItem,
    ListGroup,  } from "reactstrap";
import { Link } from 'react-router-dom';
import ReadMoreAndLess from 'react-read-more-less';
import moment from "moment";
import classnames from "classnames";

import { userActions } from "../../_actions";
import { isArray } from "util";

class Notification extends Component {
    state = { loading: true, _page: 1, nData: [], nTotal: 0, nDataList: [],states:{}, radios: 0 };

    jsucwords = str => {
        str = str.trim();
        return str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase();
        });
    }

    loadMore = e => {
        e.preventDefault();
        const { user, type } = this.props;
        const { id } = user && user.user;
        let _type = (type === "ticket") ? 0 : 1;
        let notificationData = { user_id: id, page: this.state._page + 1, count: 5, type: _type };
        this.setState({ _page: this.state._page + 1, loading: true });
        this.notificationDispatch(notificationData);
    }

    triggertaskd(e,v){
        const {dispatch,user}=this.props;
        this.setState({ loading:true });

        if(e.target.checked==true){
            var user_id=user.user.id;
      var notifi_status=1;
        }else{
          var user_id=null;
       var notifi_status=0;
       }

       dispatch(userActions.updatetaskstatus(v,user_id,notifi_status)).then(() => {
            var getstatus=this.state.getstatus;
                let notificationData = { user_id: user.user.id, page:1, count: 5, type:1,getstatus:getstatus };
                this.notificationDispatch1(notificationData);
            });

       }

    notificationDispatch(notificationData){
        const { dispatch, type } = this.props;
        const { nDataList, nData } = this.state;
        // dispatch(userActions.listNotification(notificationData)).then(() => {
        //     const { notificationlist } = this.props;
        //     const { notification } = notificationlist;
        //     const { notifications } = notification;
        //     const { data, total } = notifications;
        //     nData && isArray(nData) && nData.length > 0 && data && data.forEach((v, i) => { nData.push(v); });
        //     // if(total)
        //         this.setState({ nData: (nData.length > 0) ? nData : data, nTotal: total, nDataList: nDataList, loading: false, _isShowMore: (nData.length > 0) ? nData.length < total : data.length < total });
        // });
    }

    notificationDispatch1(notificationData){
        const { dispatch, type } = this.props;
        const { nDataList, nData } = this.state;
        // dispatch(userActions.listNotification(notificationData)).then(() => {
        //     const { notificationlist } = this.props;
        //     const { notification } = notificationlist;
        //     const { notifications } = notification;
        //     const { data, total } = notifications;
        //     //nData && isArray(nData) && nData.length > 0 && data && data.forEach((v, i) => { nData.push(v); });
        // //  if(total)
        //         this.setState({ nData: data, nTotal: total, nDataList: nDataList, loading: false, _isShowMore: (nData.length > 0) ? nData.length < total : data.length < total });
        // });
    }

    componentDidMount() {
        const { user, type } = this.props;
        const { id } = user && user.user;
        let _type = (type === "ticket") ? 0 : 1;
        let notificationData = { user_id: id, page: this.state._page, count: 5, type: _type };

        this.notificationDispatch(notificationData);
    }

    getallnoti(e,rtype){
         this.setState({
            radios:rtype,
            getstatus:rtype
        });
        const { user, type } = this.props;
        const { id } = user && user.user;
        let _type = (type === "ticket") ? 0 : 1;
        let notificationData = { user_id: id, page: 1, count: 5, type:_type,getstatus:rtype };

        this.notificationDispatch1(notificationData);
    }

    render() {
        const { type } = this.props;
        const { nData, _isShowMore, loading } = this.state;
        let nDataList = [];
        nData && nData.forEach((v, i) => {
            if(v.type==1)
            if(v.notification_status==1)
            var classnamest='checklist-item-danger checklist-item';
            else
            var classnamest='checklist-item-success checklist-item ';
            nDataList.push(<div key={`notify_${type}_${(v.type == 0 ) ? v.tickets.id : (v.tasks) ? v.tasks.id : i}_${i}`} className={`border rounded my-1 p-2 ${(v.notification_status)  ? 'notified': '' } `} >
            {/*  to={(v.type == 0) ? `/admin/tickets/edit/${v.tickets.id}` : ``} */}
            <Row className={`align-items-center ${classnamest} mx-0`}>
                <div className=" checklist-info w-100">
                {/* <Col className="col-auto"> */}
                    {/* <img alt="..." className="avatar rounded-circle" src={require("assets/img/theme/team-4.jpg")} /> */}
                    {/* <span className={(v.type == 0) ? 'notifi ticket-notifi' :

                    ''

                    }></span> */}
                {/* </Col> */}

                <div className="col ml--2">

                    <div className={`d-flex justify-content-between align-items-center`} >
                    <div className={(v.notification_status == 0) ? '' : `text-decoration-line-through`} >
                    {(v.type == 0) ? `Ticket ${v.tickets.id}` : `Task ${v.tasks.id}`}
                    </div>
                        <div className="text-right text-muted text-decoration-none">
                            <small className="text-decoration-none">{moment.duration(moment(new Date()).diff(moment(v.updated_at))).humanize()} ago</small>
                            {(!v.notification_status) ? (<>
                            {/* <span className="notifed-trigger" onClick={(e) => this.triggerNotified(e, v)}> <i className="fas fa-eye"></i> </span>  */}
                            </>) : ''}
                            <div>


                     </div>



                  {(v.type==1) ?
                    (v.notification_status==1) ?  (
                        <span className={`notifed-trigger` } >


                  <div className="custom-control custom-checkbox custom-checkbox-danger">
                    <input
                      className="custom-control-input"
                      defaultChecked
                      id={`chk-todo-task-${v.tasks.id}`}
                      type="checkbox"
                      onChange={(e) => this.triggertaskd(e, v.tasks.id)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`chk-todo-task-${v.tasks.id}`}
                    />
                  </div>

                         {/* <label className="custom-toggle">
                        <input type="checkbox"  defaultChecked className={ `task_${v.tasks.id}  ` } onChange={(e) => this.triggertaskd(e, v.tasks.id)}/>
                        <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span></label> */}


                        </span>
                         ) :  (
                        <span className={`notifed-trigger` } >


                <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input
                      className="custom-control-input"

                      id={`chk-todo-task-${v.tasks.id}`}
                      type="checkbox"
                      onChange={(e) => this.triggertaskd(e, v.tasks.id)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`chk-todo-task-${v.tasks.id}`}
                    />
                  </div>

                         {/* <label className="custom-toggle"> <input type="checkbox"  className={ `task_${v.tasks.id}` } onChange={(e) => this.triggertaskd(e, v.tasks.id)}/>
                        <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span></label>
                         */}

                        </span>
                         )
                    : ''}

                        </div>
                    </div>

                 <div className="text-sm mb-0 w-80 checklist-title">
                        <ReadMoreAndLess
                             ref={this.ReadMore}
                            className="read-more-content"
                            charLimit={28}
                            readMoreText="more"
                            readLessText=" less"
                        >
                            {(v.type == 0) ? `Have a look at ticket ${v.tickets.id}` :

                            this.jsucwords(v.tasks ? v.tasks.description +' Remainder Date:'+ v.tasks.date

                            : '')}
                        </ReadMoreAndLess>
                    </div>
                </div>
                </div>
            </Row>
        </div>);
        });
        return (
            <>
              <div className="card rounded mb-0">
                  <div className="card-header">
                      <div className="d-flex justify-content-between w-100 align-items-center">
                      <div>
                        <h6 className="surtitle">Notification</h6>
                        <h5 className="h3 mb-0">{this.jsucwords(type)} Notification</h5>
                      </div>
                      {(type=='task') ?
                        (<ButtonGroup className="btn-group-toggle mb-2" data-toggle="buttons">
                          <Button size="sm" className={classnames({ active: this.state.radios === 0 }) } color="secondary" onClick={(e) => this.getallnoti(e,0) }>
                            <input
                              autoComplete="off"
                              name="options"
                              type="radio"
                              value={this.state.radios === 0}
                              onClick={(e) => this.getallnoti(e,'All')}
                            />
                            All
                          </Button>
                          <Button size="sm" className={classnames({ active: this.state.radios === 1 }) } color="secondary" onClick={(e) => this.getallnoti(e,1)}>
                            <input
                              autoComplete="off"
                              name="options"
                              type="radio"
                              value={this.state.radios === 1}

                            />
                            WIP
                          </Button>
                          <Button size="sm" className={classnames({ active: this.state.radios === 2 }) } color="secondary" onClick={(e) => this.getallnoti(e,2)}>
                            <input
                              autoComplete="off"
                              name="options"
                              type="radio"
                              value={this.state.radios === 2}
                              />
                            Completed
                          </Button>
                        </ButtonGroup>):''}
                        </div>
                  </div>
                  <div className="card-body max-height-358px overflow-y-scroll mb-3">
                {loading ? ( <span className="position-absolute loading-notification"><i className="fas fa-spin fa-circle-notch"></i></span> ) : ''}
                <div>
                  {/*  && nDataList.length > 0 */}

        </div>
                {(nDataList.length > 0) ? (<>{nDataList} </>) : (<p className="text-center">No new {type} notifications.</p>)}
              </div>
              <div className="card-footer">
                <span className={`d-flex w-100 justify-content-center m-0 ${_isShowMore ? '' : 'invisible'}`}><a className="btn btn-primary btn-sm text-white" onClick={(e) => { this.loadMore(e); }}>Show More.</a></span>
              </div>
              </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { loggingIn, loggedIn, user } = state.authentication;
    const { notificationlist } = state;
    return { loggingIn, loggedIn, user, notificationlist };
}

const connectedNotificationPage = connect(mapStateToProps)(Notification);
export { connectedNotificationPage as Notification };
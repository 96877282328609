import { userConstants } from '../_constants';
import { serviceConstants } from '../_constants';

export function customerservice(state = {}, action) {
  switch (action.type) {
    case serviceConstants.GET_SERVICE_CUSTOMER_REQUEST:
      return {
        services: action.datas
      };
    case serviceConstants.GET_SERVICE_CUSTOMER_SUCCESS:
      return {
        services: action.datas
      };
    case serviceConstants.GET_SERVICE_CUSTOMER_FAILURE:
      return {};
    case serviceConstants.UPDATE_SERVICE_REQUEST:
      return {
        services: action.datas
      };
    case serviceConstants.UPDATE_SERVICE_SUCCESS:
      return {
        services: action.datas
      };
    case serviceConstants.UPDATE_SERVICE_FAILURE:
      return {};
    default:
      return state
  }
}
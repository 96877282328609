import moment from 'moment';
import Chart from "chart.js";

let mode = "light";
export const loadingButton = (element) => {
    element.setAttribute('data-inhtml', element.innerHTML);
    setTimeout(() => {
      element.classList.add('isLoading');
      element.setAttribute('disabled', true);
      if(element.clientHeight > 0 && element.clientWidth > 0){
          element.style.height = `${element.clientHeight + 2}px`;
          element.style.width = `${element.clientWidth + 2}px`;
      }
      element.innerHTML = '';
      element.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i>';
    }, 500);
}

export const first = (element) => { for (let i in element) return element[i]; }
export const last = (element) => { return element.slice(-1)[0]; }

export const removeLoadingButton = (element, content) => {
    let originalContent = element.getAttribute('data-inhtml');
    content = (originalContent) ? originalContent : content;
    element.innerHTML = content;
    setTimeout(() => {
      element.classList.remove('isLoading');
      element.removeAttribute('disabled');
      element.removeAttribute('data-inhtml');
    }, 500);
}

export const addInvalid = (element) => {
    element.classList.remove('is-valid');
    element.classList.add('is-invalid');
}

export const removeInvalid = (element) => {
    element.classList.remove('is-invalid');
    element.classList.add('is-valid');
}

export const getBase64 = async (file) => {
    var reader = new FileReader();
    await reader.readAsDataURL(file);
    reader.onload = function () { return reader.result; };
    reader.onerror = function (error) { return null; };
}

export const localTime=(dates,format,returnformat)=>{
    let date = moment.utc(dates,format);
      let local = date.local().format(returnformat);
    local=moment(local,returnformat).subtract(12,'hours');
    local=local.add(1,'day');
    local=local.add(30,'minutes');
    return local=local.format(returnformat);

}
export const getFilePathExtension=(path) =>{
	var filename = path.split('\\').pop().split('/').pop();
	var lastIndex = filename.lastIndexOf(".");
	if (lastIndex < 1) return "";
    return filename.substr(lastIndex + 1);
}

export const parseOptions = (parent, options) => {
    for (var item in options) {
        if (typeof options[item] !== "object") {
            parent[item] = options[item];
        } else {
            parseOptions(parent[item], options[item]);
        }
    }
}

export const chartOptions = () => {
    var options = {
      defaults: {
        global: {
          responsive: true,
          maintainAspectRatio: false,
          defaultColor: mode === "dark" ? '#525f7f' : '#8898aa',
          defaultFontColor: mode === "dark" ? '#525f7f' : '#8898aa',
          defaultFontFamily: "Open Sans",
          defaultFontSize: 13,
          layout: {
            padding: 0
          },
          legend: {
            display: false,
            position: "bottom",
            labels: {
              usePointStyle: true,
              padding: 16
            }
          },
          elements: {
            point: {
              radius: 0,
              backgroundColor: '#172b4d'
            },
            line: {
              tension: 0.4,
              borderWidth: 4,
              borderColor: '#172b4d',
              backgroundColor: "transparent",
              borderCapStyle: "rounded"
            },
            rectangle: {
              backgroundColor: "#fb6340"
            },
            arc: {
              backgroundColor: '#172b4d',
              borderColor: mode === "dark" ? "#32325d" : "#FFFFFF",
              borderWidth: 4
            }
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false
          }
        },
        doughnut: {
          cutoutPercentage: 83,
          legendCallback: function(chart) {
            var data = chart.data;
            var content = "";
  
            data.labels.forEach(function(label, index) {
              var bgColor = data.datasets[0].backgroundColor[index];
  
              content += '<span class="chart-legend-item">';
              content +=
                '<i class="chart-legend-indicator" style="background-color: ' +
                bgColor +
                '"></i>';
              content += label;
              content += "</span>";
            });
  
            return content;
          }
        }
      }
    };
  
    // yAxes
    Chart.scaleService.updateScaleDefaults("linear", {
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        color: mode === "dark" ? '#212529' : '#dee2e6',
        drawBorder: false,
        drawTicks: false,
        lineWidth: 1,
        zeroLineWidth: 1,
        zeroLineColor: mode === "dark" ? '#212529' : '#dee2e6',
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2]
      },
      ticks: {
        beginAtZero: true,
        padding: 10,
        callback: function(value) {
          if (!(value % 10)) {
            return value;
          }
        }
      }
    });
  
    // xAxes
    Chart.scaleService.updateScaleDefaults("category", {
      gridLines: {
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false
      },
      ticks: {
        padding: 20
      },
      maxBarThickness: 10
    });
  
    return options;
}
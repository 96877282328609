import Axios from "axios";
import { toast } from 'react-toastify';
import { setAuthToken } from "../../utils/setAuthToken";
import { GET_USER_INFO_FAILURE, GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER } from "./auth.actionTypes";

// login a user
let loginUser = (sendUser, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_USER_REQUEST });
      let config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let loginDataUrl = `${process.env.REACT_APP_BACKEND_URL}api/login`;
      let response = await Axios.post(
        loginDataUrl,
        JSON.stringify(sendUser),
        config
      );
      console.log('LOGIN_RESPONSE_primary message', response.data);
      if(response.data.status == 0){
        dispatch({ type: LOGIN_USER_FAILURE, payload: response.data.msg });
        toast('Invalid Email or Password!..');
      }else{
        dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data });
        toast('Login successfully!..');
        //   dispatch(setAlert('Login Success', 'success'));
        // if (localStorage.token) {
        //   setAuthToken(localStorage.getItem('token'));
        // }
        if (window.screen.width < 768) {
          if (
            response.data.user.role == 'Admin' ||
            response.data.user.role == 'Frontdesk'
          ) {
            history.push('/admin/dashboard');
          }
          else if(response.data.user.role == 'Accountant'){
              history.push('/admin/enquiry-res-table');
          }
          else {
            history.push('/admin/dashboardAssignee');
          }
        } else {
          if (
            response.data.user.role == 'Admin' ||
            response.data.user.role == 'Frontdesk'
          ) {
            history.push('/admin/dashboard');
          } else if (response.data.user.role == 'Accountant') {
            history.push('/admin/enquiry');
          } else {
            history.push('/admin/dashboardAssignee');
          }    
        }
      }
      
    } catch (error) {
      console.log(error.response.data.errors);
      //   let errorList = error.response.data.errors;
      //   await errorList.forEach((alert) =>
      //     // dispatch(setAlert(alert.msg, 'danger'))
      //   );
      dispatch({ type: LOGIN_USER_FAILURE, payload: error });
    }
  };
};


// get User Info
// let getUserInfo = () => {
//     return async (dispatch) => {
//         try {
//             if(localStorage.token){
//                 setAuthToken(localStorage.getItem('token'));
//             }
//             dispatch({type : GET_USER_INFO_REQUEST});
//             let response = await Axios.get('/user/');
//             dispatch({type : GET_USER_INFO_SUCCESS , payload : response.data});
//         }
//         catch (error) {
//             dispatch({type : GET_USER_INFO_FAILURE , payload : error});
//         }
//     }
// };


// logOutUser
let logOut = (history) => {
    return async (dispatch) => {
        try {
            dispatch({type : LOGOUT_USER});
            // dispatch(setAlert('Logout is Success' , 'success'));
            history.push('/');
        }
        catch (error) {
            console.error(error);
        }
    };
};

export {  loginUser, logOut};

export const _ticketCreate = [
    {
        "name": "firstname",
        "label": "First Name",
        "id": "first_name",
        "class": "form-control-sm",
        "state": "firstnameState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "First Name is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "input",
        "isReq": true,
        "regex": /^[a-zA-Z ]*$/,
        "regexInvalid": "Only alphabets accepted."
    },
    {
        "name": "lastname",
        "label": "Last Name",
        "id": "last_name",
        "class": "form-control-sm",
        "state": "lastnameState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Last Name is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "input",
        "isReq": false,
        "regex": /^[a-zA-Z ]*$/,
        "regexInvalid": "Only alphabets accepted."
    },
    {
        "name": "email",
        "label": "Email",
        "id": "email",
        "class": "form-control-sm",
        "state": "emailState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Email is Required.",
        "validFeedback": "",
        "bs4Size": "4",
        "type": "input",
        "isReq": true,
        "regex": /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "regexInvalid": "Email is not valid."
    },
    {
        "name": "phone",
        "label": "Phone",
        "id": "phone",
        "class": "form-control-sm",
        "state": "phoneState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Phone is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "input",
        "isReq": true,
        "regex": /^\d{10}$/gi,
        "regexInvalid": "Phone no. is not valid."
    },
    {
        "name": "dob",
        "label": "DOB",
        "id": "dob",
        "class": "form-control-sm",
        "state": "dobState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "DOB is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "date",
        "placeholder": "27/08/1995",
        "isReq": false
    },
    // {
    //     "name": "service",
    //     "label": "Service",
    //     "id": "service",
    //     "class": "form-control-sm",
    //     "state": "serviceState",
    //     "valid": "is-valid",
    //     "invalid": "is-invalid",
    //     "icon": "fas fa-user",
    //     "iconPosition": "prepend",
    //     "invalidFeedback": "Service is Required.",
    //     "validFeedback": "",
    //     "bs4Size": "4",
    //     "type": "select",
    //     "option": [
    //         { value: 'chocolate', label: 'Chocolate' },
    //         { value: 'strawberry', label: 'Strawberry' },
    //         { value: 'vanilla', label: 'Vanilla' }
    //     ],
    //     "placeholder": "Select service",
    //     "multiple": true,
    //     "isReq": true
    // },
    // {
    //     "name": "department",
    //     "label": "Department",
    //     "id": "department",
    //     "class": "form-control-sm",
    //     "state": "departmentState",
    //     "valid": "is-valid",
    //     "invalid": "is-invalid",
    //     "icon": "fas fa-user",
    //     "iconPosition": "prepend",
    //     "invalidFeedback": "Department is Required.",
    //     "validFeedback": "",
    //     "bs4Size": "3",
    //     "type": "select",
    //     "option": [
    //         { value: 'chocolate', label: 'Chocolate' },
    //         { value: 'strawberry', label: 'Strawberry' },
    //         { value: 'vanilla', label: 'Vanilla' }
    //     ],
    //     "placeholder": "Select Department",
    //     "multiple": false,
    //     "isReq": true
    // },
    {
        "name": "assignedTo",
        "label": "Assigned To",
        "id": "assignedTo",
        "class": "form-control-sm",
        "state": "assignedToState",
        "valid": "is-valid",
        "invalid": "is-invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Assigned To is Required.",
        "validFeedback": "",
        "bs4Size": "3",
        "type": "select",
        "option": [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ],
        "placeholder": "Select Assigned To",
        "multiple": false,
        "isReq": false
    },
    {
        "name": "status",
        "label": "Status",
        "id": "status",
        "class": "form-control-sm",
        "state": "statusState",
        "valid": "is-valid",
        "invalid": "is-invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Status is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "select",
        "option": [
            // { value: 'chocolate', label: 'Chocolate' },
            // { value: 'strawberry', label: 'Strawberry' },
            // { value: 'vanilla', label: 'Vanilla' }
        ],
        "placeholder": "Select status",
        "multiple": false,
        "isReq": true
    },
    {
        "name": "address",
        "label": "Address",
        "id": "address",
        "class": "form-control-sm",
        "state": "addressState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Address is Required.",
        "validFeedback": "",
        "bs4Size": "4",
        "type": "input",
        "isReq": false,
        "regex": /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _,.&]*)$/,
        "regexInvalid": "Only alphanumeric values accepted."
    },
    {
        "name": "state",
        "label": "State",
        "id": "state",
        "class": "form-control-sm",
        "state": "stateState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "State is Required.",
        "validFeedback": "",
        "bs4Size": "3",
        "type": "input",
        "isReq": false,
        "regex": /^[a-zA-Z ]*$/,
        "regexInvalid": "Only alphabets accepted."
    },
    {
        "name": "city",
        "label": "City",
        "id": "city",
        "class": "form-control-sm",
        "state": "cityState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "City is Required.",
        "validFeedback": "",
        "bs4Size": "3",
        "type": "input",
        "isReq": false,
        "regex": /^[a-zA-Z ]*$/,
        "regexInvalid": "Only alphabets accepted."
    },
    {
        "name": "zipcode",
        "label": "Pin Code",
        "id": "zipcode",
        "class": "form-control-sm",
        "state": "zipcodeState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Pin Code is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "input",
        "isReq": false,
        "regex": /^(\d{4}|\d{5}|\d{6})$/,
        "regexInvalid": "ZipCode is not valid."
    },
    {
        "name": "poc",
        "label": "Point of Contact",
        "id": "poc",
        "class": "form-control-sm",
        "state": "pocState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "POC is Required.",
        "validFeedback": "",
        "bs4Size": "4",
        "type": "input",
        "placeholder": "Point of Contact",
        "regex": /^[a-zA-Z ]*$/,
        "regexInvalid": "Only alphabets accepted."
    },
    {
        "name": "poc_number",
        "label": "POC Number",
        "id": "poc_number",
        "class": "form-control-sm",
        "state": "poc_numberState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "POC Number is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "input",
        "placeholder": "9876543210",
        "regex": /^\d{10}$/gi,
        "regexInvalid": "Phone no. is not valid.",
    },
    {
        "name": "referred_by",
        "label": "Referred By",
        "id": "referred_by",
        "class": "form-control-sm",
        "state": "referred_byState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Referred is Required.",
        "validFeedback": "",
        "bs4Size": "3",
        "type": "input",
        "regex": /^[a-zA-Z ]*$/,
        "isReq": true,
        "regexInvalid": "Only alphabets accepted."
    },
    {
        "name": "referral_contact",
        "label": "Referral Ph.",
        "id": "referral_contact",
        "class": "form-control-sm",
        "state": "referral_contactState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "Referral Phone No. is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "input",
        "placeholder": "9876543210",
        "regex": /^\d{10}$/gi,
        "regexInvalid": "Referral Phone no. is not valid."
    },
]
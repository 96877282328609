import React, { Component } from 'react';
import Select, { components } from "react-select";
import { connect } from 'react-redux';
import moment from "moment";
import ReactDatetime from "react-datetime";
import classnames from "classnames";

class HotelRow extends Component{
  constructor(props){
      super(props);
      this.state = { states1 : {}, isLastrow:false };
  }

  static defaultProperty = {
    expenses_data1: {}
  };

  componentDidMount(){
    const { unit, jsUcfirst, oStartData, oEndData, expenses_data1 } = this.props;
    let { states1 } = this.state;
    if(expenses_data1 && expenses_data1[unit]){
      states1 = (states1) ? states1 : {};
      let key = unit;
      states1[`hoid_${key}`] = (expenses_data1[key]['expenses']['id']) ? expenses_data1[key]['expenses']['id'] : "";
      states1[`startDate_hotel_${key}`] = (expenses_data1[key]['expenses']['start_date']) ? expenses_data1[key]['expenses']['start_date'] : "";
      states1[`endDate_hotel_${key}`] = (expenses_data1[key]['expenses']['end_date']) ? expenses_data1[key]['expenses']['end_date'] : "";
      states1[`hotel_name_${key}`] = (expenses_data1[key]['expenses']['hotel_name']) ? expenses_data1[key]['expenses']['hotel_name'] : "";
      states1[`amount_paid_hotel_${key}`] = (expenses_data1[key]['expenses']['amount']) ? expenses_data1[key]['expenses']['amount'] : "";
      states1[`places_hotel_${key}`] = (expenses_data1[key].expenses.places && expenses_data1[key]['expenses']['places'].length > 0) ? { value: expenses_data1[key]['expenses']['places'][0]['id'], label: expenses_data1[key]['expenses']['places'][0]['name'] } : "";
      states1[`vendor_hotel_${key}`] = (expenses_data1[key].expenses.vendor && expenses_data1[key]['expenses']['vendor'].length > 0) ? { value: expenses_data1[key]['expenses']['vendor'][0]['id'], label: expenses_data1[key]['expenses']['vendor'][0]['name'] } : "";
      this.setState({ states1: states1,statusvalue:true });
    }
  }

  validEndData(current, unit) {
    const { states1 } = this.state;
    return current.isAfter( moment(states1[`startDate_hotel_${unit}`]).subtract( 1, 'day' ) );
  }

  validStartData(current, unit) {
    const { states1 } = this.state;
   if(states1[`endDate_hotel_${unit}`] == undefined || states1[`endDate_hotel_${unit}`] == ""){
    return current.isAfter(moment().subtract( 1, 'day' ));
   }else{
    return  current.isAfter(moment().subtract( 1, 'day' )) && current.isBefore(moment(states1[`endDate_hotel_${unit}`]));
   }
  }

  changeinput(ele,clsname,unit){
    let { states1 } = this.state;
    let { expenses_data1, setStater } = this.props;
    states1 = (states1) ? states1 : {};
    expenses_data1 = (expenses_data1) ? expenses_data1 : {};
    expenses_data1[unit] = (expenses_data1[unit]) ? expenses_data1[unit] : {};
    expenses_data1[unit]['expenses'] = (expenses_data1[unit]['expenses']) ? expenses_data1[unit]['expenses'] : {};
    let elem = ele.currentTarget;
    let regex1 = /^\d{0,12}(\.\d{1,2})?$/;
    let inputvalue = document.querySelector(`${clsname}_${unit}`);
    if(clsname === '.hotel_name'){
      regex1 = /^(?:[A-Za-z]+)(?:[A-Za-z ]*)$/;
      states1[`hotel_name_${unit}`] = inputvalue.value;
      expenses_data1[unit]['expenses']['hotel_name'] = inputvalue.value;
    }else{
      states1[`amount_paid_hotel_${unit}`] = inputvalue.value;
      expenses_data1[unit]['expenses']['amount'] = inputvalue.value;
    }
    let regex = new RegExp(regex1);
    let statVal = false;
    if(!regex.test(inputvalue.value.trim())){
        this.addInvalid(elem);
    }else{
      this.removeInvalid(elem);
      statVal = true;
    }
    setStater('expenses_data1', expenses_data1);
    this.setState({ statusvalue: statVal, states1: states1 });
  }
      
  addInvalid(ele){
    ele.classList.remove('is-valid');
    ele.classList.add('is-invalid');
  }
  removevalidinvalid(ele){
    ele.classList.remove('is-valid');
    ele.classList.remove('is-invalid');
  }

  removeInvalid(ele){
      ele.classList.remove('is-invalid');
      ele.classList.add('is-valid');
  }

  async remove(_event, hoid, unit1, isLast, target){
    let { states1 } = this.state;
    let { expenses_data1, setStater, getdelete } = this.props;
    let rows = document.querySelectorAll(`.hotelRow_st`);
    states1 = {};
    expenses_data1 = (expenses_data1) ? expenses_data1 : {};
    let hotelID = (expenses_data1[unit1] && expenses_data1[unit1].expenses.id) ? expenses_data1[unit1].expenses.id : '';
    if(hotelID){
      let removev = document.querySelector(`.hoidd_${unit1}`);
      getdelete(hotelID, unit1, removev);
    }
    delete expenses_data1[unit1];
    let tempi = 0;
    await Object.keys(expenses_data1).forEach(key => {
      expenses_data1[tempi] = expenses_data1[key];
      tempi = tempi + 1;
    });

    await Object.keys(expenses_data1).forEach(key => {
      states1[`hoid_${key}`] = (expenses_data1[key]['expenses']['id']) ? expenses_data1[key]['expenses']['id'] : "";
      states1[`startDate_hotel_${key}`] = (expenses_data1[key]['expenses']['start_date']) ? expenses_data1[key]['expenses']['start_date'] : "";
      states1[`endDate_hotel_${key}`] = (expenses_data1[key]['expenses']['end_date']) ? expenses_data1[key]['expenses']['end_date'] : "";
      states1[`hotel_name_${key}`] = (expenses_data1[key]['expenses']['hotel_name']) ? expenses_data1[key]['expenses']['hotel_name'] : "";
      states1[`amount_paid_hotel_${key}`] = (expenses_data1[key]['expenses']['amount']) ? expenses_data1[key]['expenses']['amount'] : "";
      states1[`places_hotel_${key}`] = (expenses_data1[key]['expenses']['places'] && expenses_data1[key]['expenses']['places'].length > 0) ? { value: expenses_data1[key]['expenses']['places'][0]['id'], label: expenses_data1[key]['expenses']['places'][0]['name'] } : "";
      states1[`vendor_hotel_${key}`] = (expenses_data1[key]['expenses']['vendor'] && expenses_data1[key]['expenses']['vendor'].length > 0) ? { value: expenses_data1[key]['expenses']['vendor'][0]['id'], label: expenses_data1[key]['expenses']['vendor'][0]['name'] } : "";
    });
      
    expenses_data1[tempi] = {};
    expenses_data1[tempi]['expenses'] = {};
    expenses_data1[tempi]['expenses']['id'] = '';
    expenses_data1[tempi]['expenses']['start_date'] = '';
    expenses_data1[tempi]['expenses']['end_date'] = '';
    expenses_data1[tempi]['expenses']['hotel_name'] = '';
    expenses_data1[tempi]['expenses']['amount'] = '';
    expenses_data1[tempi]['expenses']['places'] = '';
    expenses_data1[tempi]['expenses']['vendor'] = '';

    states1[`hoid_${tempi}`] = '';
    states1[`startDate_hotel_${tempi}`] = '';
    states1[`endDate_hotel_${tempi}`] = '';
    states1[`hotel_name_${tempi}`] = '';
    states1[`amount_paid_hotel_${tempi}`] = '';
    states1[`places_hotel_${tempi}`] = '';
    states1[`vendor_hotel_${tempi}`] = '';

    setTimeout(() => {
      setStater('expenses_data1', expenses_data1);
      rows.length > 1 && setStater('hoexpensesCount', rows.length - 1);
      rows.length === 1  && setStater('hoexpensesCount', 1);
      this.setState({ states1: states1 });
    }, 500);
  }

  lastClick1(isLastClick1, unit){
    const { states1 } = this.state;
    let { expenses_data1, setStater } = this.props;
    // .childNodes[1].value
    let placeshotel = document.querySelector(`.places_hotel_${unit}`);
    let endDatehotel = document.querySelector(`.endDate_hotel_${unit}`);
    let startDatehotel = document.querySelector(`.startDate_hotel_${unit}`);
    let vendorhotel = document.querySelector(`.vendor_hotel_${unit}`);
    let amounthotel = document.querySelector(`.amount_paid_hotel_${unit}`);
    let hotel_name=document.querySelector(`.hotel_name_${unit}`);
    this.removeInvalid(startDatehotel);
    this.removeInvalid(hotel_name);
    this.removeInvalid(endDatehotel);
    this.removeInvalid(placeshotel);
    this.removeInvalid(vendorhotel);
    this.removeInvalid(amounthotel);
    //  && this.state.statusvalue === true
    if((placeshotel.childNodes[1].value.trim() !== "" || endDatehotel.value.trim() !== "") && startDatehotel.value.trim() !== "" && vendorhotel.childNodes[1].value.trim() !== "" && amounthotel.value.trim() !== "" && hotel_name.value.trim() !== ""){
      expenses_data1 = (expenses_data1) ? expenses_data1 : {};
      expenses_data1[unit + 1] = {};
      expenses_data1[unit + 1]['expenses'] = {};
      expenses_data1[unit + 1]['expenses']['id'] = '';
      expenses_data1[unit + 1]['expenses']['start_date'] = '';
      expenses_data1[unit + 1]['expenses']['end_date'] = '';
      expenses_data1[unit + 1]['expenses']['hotel_name'] = '';
      expenses_data1[unit + 1]['expenses']['amount'] = '';
      expenses_data1[unit + 1]['expenses']['places'] = '';
      expenses_data1[unit + 1]['expenses']['vendor'] = '';

      setStater('expenses_data1', expenses_data1);
      isLastClick1();
    } else{
      if(endDatehotel.value.trim() === "" && startDatehotel.value.trim() === ""){
          this.addInvalid(endDatehotel);
          this.addInvalid(startDatehotel);
      }
      if(placeshotel.childNodes[1].value.trim() === "")
          this.addInvalid(placeshotel);
      if(vendorhotel.childNodes[1].value.trim() === "")
          this.addInvalid(vendorhotel);
      if(amounthotel.value.trim() === "")
          this.addInvalid(amounthotel);
      if(hotel_name.value.trim() === "")
      this.addInvalid(hotel_name);
    }
  }

 render() {
        const { Option } = components;
        const IconOption = (props) => {
            let iconimg = '';
            let bagde = '';
            let imgSrc = require("assets/img/theme/team-1.jpg");
            if(props.data.badge){
                iconimg = (<div className="col-2 p-0"><img src={imgSrc} className="avatar avatar-xs rounded"/> </div>);
                bagde = (<div className="col-2 p-0 w-100 d-flex text-right align-items-center"><span className="badge badge-dark badge-pill text-white">{props.data.badge}</span></div>);
            }
            return (
                <Option {...props} key={`${props.data.label}_${props.data.id}`} selected={`${props.data.selected}`} className="d-flex align-items-center">
                <div className="row col p-0 m-0">
                    {iconimg}
                    <div className="col px-0 mx-1 d-flex align-items-center">{props.data.label}</div>
                    {bagde}
                </div>
                </Option>
            );
        }

        const { renderDateInput, getdelete, isGetid, isLast, unit, isLastClick1, optiondata, expenses_data1 } = this.props;
        let { vendorsOption } = this.props;

        if(vendorsOption)
          vendorsOption = vendorsOption.filter((v, i) => { return v.value !== 'e-route'; });

        return (
            <div className={`row my-1 mx-0 hotelRow_st hoidd_${unit}`}> 
            <div className="col-md-2 mb-lg-0 mb-2  pl-0 pr-lg-1 pr-0">
              <input type="hidden" ref={`hotelRow_id`} className={`hotel_id`} />
              <input type="hidden" className={`hoteldeletele_${unit} gethidden`} value={unit}/>
              <input type="hidden" ref={`hoid_${unit}`} className={`hoid_${unit}`} value={ expenses_data1 && expenses_data1[unit] && expenses_data1[unit]['expenses']['id'] || ''}/>
              <ReactDatetime
                ref={`startDate_hotel_${unit}`}
                inputProps={{
                  placeholder: "Start Date",
                  className: `form-control form-control-sm startDate_hotel_${unit}`,readOnly:true
                }}
               isValidDate={(current) => this.validStartData(current, unit)}
                renderInput={ renderDateInput() }
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    this.state.states1[`startDate_hotel_${unit}`] &&
                    this.state.states1[`endDate_hotel_${unit}`] &&
                    moment(this.state.states1[`startDate_hotel_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " start-date";
                  } else if (
                    this.state.states1[`startDate_hotel_${unit}`] &&
                    this.state.states1[`endDate_hotel_${unit}`] &&
                    new Date(moment(this.state.states1[`startDate_hotel_${unit}`])._d + "") <
                      new Date(currentDate._d + "") &&
                    new Date(moment(this.state.states1[`endDate_hotel_${unit}`])._d + "") >
                      new Date(currentDate._d + "")
                  ) {
                    classes += " middle-date";
                  } else if (
                    this.state.states1[`endDate_hotel_${unit}`] &&
                    moment(this.state.states1[`endDate_hotel_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td> 
                  );
                }}
                value={ expenses_data1 && expenses_data1[unit]['expenses']['start_date'] || '' }
                onChange={e => { const { states1 } = this.state; 
                let { expenses_data1, setStater } = this.props;
                expenses_data1 = (expenses_data1) ? expenses_data1 : {};
                expenses_data1[unit] = (expenses_data1[unit]) ? expenses_data1[unit] : {};
                expenses_data1[unit]['expenses'] = (expenses_data1[unit]['expenses']) ? expenses_data1[unit]['expenses'] : {};
                expenses_data1[unit]['expenses']['start_date'] = moment(e).format('YYYY-MM-DD');
                setStater('expenses_data1', expenses_data1);
                states1[`startDate_hotel_${unit}`] = moment(e).format('YYYY-MM-DD'); 

                this.removeInvalid(document.querySelector(`.startDate_hotel_${unit}`));                
                this.setState({ states1: states1 }); }}
              />
            </div>
            <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-1 pr-0">
              <ReactDatetime
                ref={`endDate_hotel_${unit}`}
                inputProps={{
                  placeholder: "End Date",
                  className: `form-control form-control-sm endDate_hotel_${unit}`,readOnly:true
                }}
                isValidDate={(current) => this.validEndData(current, unit)}
                renderInput={ renderDateInput() }
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    this.state.states1[`startDate_hotel_${unit}`] &&
                    this.state.states1[`endDate_hotel_${unit}`] &&
                    moment(this.state.states1[`startDate_hotel_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " start-date";
                  } else if (
                    this.state.states1[`startDate_hotel_${unit}`] &&
                    this.state.states1[`endDate_hotel_${unit}`] &&
                    new Date(moment(this.state.states1[`startDate_hotel_${unit}`])._d + "") <
                      new Date(currentDate._d + "") &&
                    new Date(moment(this.state.states1[`endDate_hotel_${unit}`])._d + "") >
                      new Date(currentDate._d + "")
                  ) {
                    classes += " middle-date";
                  } else if (
                    this.state.states1[`endDate_hotel_${unit}`] &&
                    moment(this.state.states1[`endDate_hotel_${unit}`])._d + "" === currentDate._d + ""
                  ) {
                    classes += " end-date";
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                value={expenses_data1 && expenses_data1[unit]['expenses']['end_date'] || ''}
                onChange={e => { const { states1 } = this.state;
                this.removeInvalid(document.querySelector(`.endDate_hotel_${unit}`));    
                 states1[`endDate_hotel_${unit}`] = moment(e).format('YYYY-MM-DD'); 
                 let { expenses_data1, setStater } = this.props;
                  expenses_data1 = (expenses_data1) ? expenses_data1 : {};
                  expenses_data1[unit] = (expenses_data1[unit]) ? expenses_data1[unit] : {};
                  expenses_data1[unit]['expenses'] = (expenses_data1[unit]['expenses']) ? expenses_data1[unit]['expenses'] : {};
                  expenses_data1[unit]['expenses']['end_data'] = moment(e).format('YYYY-MM-DD');
                  setStater('expenses_data1', expenses_data1);
                 this.setState({ states1: states1 }); }}
              />
            </div>

            <div className={classnames({ "col-md-2 pl-0 pr-lg-1 pr-0 d-flex mb-lg-0 mb-2": true })}>
              <Select ref={`places_hotel_${unit}`} id={`places`} className={`form-control form-control-sm p-0 select_${`places`} places_hotel_${unit}`} isClearable placeholder={`Select Place`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`places`} options={optiondata} value={(expenses_data1 && expenses_data1[unit].expenses.places && expenses_data1[unit]['expenses']['places'].length > 0 && { value: expenses_data1[unit]['expenses']['places'][0]['id'], label: expenses_data1[unit]['expenses']['places'][0]['name'] })} 
              onChange={(value) => { const { states1 } = this.state; states1[`places_hotel_${unit}`] = value;
              let { expenses_data1, setStater } = this.props;
              expenses_data1 = (expenses_data1) ? expenses_data1 : {};
              expenses_data1[unit] = (expenses_data1[unit]) ? expenses_data1[unit] : {};
              expenses_data1[unit]['expenses'] = (expenses_data1[unit]['expenses']) ? expenses_data1[unit]['expenses'] : {};
              expenses_data1[unit]['expenses']['places'] = [];
              expenses_data1[unit]['expenses']['places'][0] = (value) ? { id: value.value, name  : value.label } : '';
              setStater('expenses_data1', expenses_data1);
              this.removeInvalid(document.querySelector(`.places_hotel_${unit}`));   
               this.setState({ states1: states1 }); }} />
            </div>

            <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-1 pr-0">
              <Select ref={`vendor_hotel_${unit}`} id={`vendor`} className={`form-control form-control-sm p-0 select_${`vendor`} 
              vendor_hotel_${unit}`} isClearable placeholder={`Select Vendor`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`vendor`} options={vendorsOption} value={(expenses_data1 && expenses_data1[unit].expenses.vendor && expenses_data1[unit]['expenses']['vendor'].length > 0 && { value: expenses_data1[unit]['expenses']['vendor'][0]['id'], label: expenses_data1[unit]['expenses']['vendor'][0]['name'] })} 
              onChange={(value) => { const { states1 } = this.state; states1[`vendor_hotel_${unit}`] = value; 
              let { expenses_data1, setStater } = this.props;
              expenses_data1 = (expenses_data1) ? expenses_data1 : {};
              expenses_data1[unit] = (expenses_data1[unit]) ? expenses_data1[unit] : {};
              expenses_data1[unit]['expenses'] = (expenses_data1[unit]['expenses']) ? expenses_data1[unit]['expenses'] : {};
              expenses_data1[unit]['expenses']['vendor'] = [];
              expenses_data1[unit]['expenses']['vendor'][0] = (value) ? { id: value.value, name  : value.label } : '';
              setStater('expenses_data1', expenses_data1);
              this.removeInvalid(document.querySelector(`.vendor_hotel_${unit}`));   
              this.setState({ states1: states1 }); }} />
            </div>
            <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-1 pr-0">
            <input type="text" className={`form-control form-control-sm hotel_name_${unit} mr-1`} placeholder="Hotel Name" 
            onChange={e => this.changeinput(e,`.hotel_name`,unit)} value={expenses_data1 && expenses_data1[unit]['expenses']['hotel_name'] || ''} />
            </div>

            <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-1 pr-0 d-flex">
              <span className="w-100"> 
              <input type="text" className={`form-control form-control-sm amount_paid_hotel_${unit} mr-1`} placeholder="Amt. Paid"  
              onChange={e => this.changeinput(e,`.amount_paid_hotel`,unit)} value={expenses_data1 && expenses_data1[unit]['expenses']['amount'] || ''} /></span>
              <span className="ml-1" >

              <button className={`btn btn-sm btn-danger btn_dl_${unit}`} onClick={(e) => this.remove(e, this.state.states1[`hoid_${unit}`],unit,isLast,`btn_dl_${unit}`)}><i className="fas fa-trash"></i></button> 
              </span>

              {(isLast || this.state.isLastrow) ? (
              <span className="ml-1" > <button className="btn btn-sm btn-primary" onClick={() => this.lastClick1(isLastClick1,unit)}><i className="fas fa-plus"></i></button></span>) : ''}
            </div>
            
          </div>
        );
    }
}

function mapStateToProps(state) {
    const { special } = state;
    return {
        special
    };
}

const HotelRowSectionPage = connect(mapStateToProps)(HotelRow);
export { HotelRowSectionPage as HotelRow };
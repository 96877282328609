import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// import Dropzone from "dropzone";
import { history } from '../../_helpers';
import { isArray, isObject } from "util";
import isImage from "is-image";
import { loadingButton, removeLoadingButton, addInvalid, removeInvalid } from '../../_functions';
import moment from "moment";
import { withRouter } from 'react-router-dom';

// reactstrap components
import { FormGroup, Button, Card, CardHeader, CardBody, Alert, Form, Input, Nav, CardTitle, NavItem, NavLink, TabContent, TabPane, Collapse, Container, Modal, Row, Col } from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { SpecialRow } from "../../_components/common/special_row";
import { InsuranceRow } from "../../_components/common/insurance_row";
import { TourRow } from "../../_components/common/tour_row";
import { FoodRow } from "../../_components/common/food_row";
import { VehicleRow } from "../../_components/common/vehicleRow";
import { HotelRow } from "../../_components/common/hotel_row";

import { _ticketCreate } from '../../_data/ticketCreate';
import { userActions, serviceActions, placesActions, vehicleActions } from '../../_actions';
import { connect } from 'react-redux';
import { CommentSection } from "../../_components/common/commentSection";
import { globalConstants } from "../../_constants";
import NotificationAlert from "react-notification-alert";
import { isDate } from "util";
import { ModalFull } from "../../_components/common/modal_full";
import { Autocomplete } from '../../_components/common/autoComplete';

class Edit extends React.Component {
  constructor(props) {
    super(props);
    let _this = this;
    let stater = {};
    stater.create = false;
    _ticketCreate.forEach((v, i) => {
      stater[`${v.name}`] = '';
      stater[`${v.name}State`] = '';
    });

    this.toggle = this.toggle.bind(this);

    this.state = { ticketCreation: stater, _ticketCreateState: [], ticketDetails: null, openedCollapses: 'collapse1', billing: [], loading: true, service_tax_percent: 8, gst_percent: 16, doc_type: [], serviceState: {}, serviceCustomer: {}, specialCount: 1, hotelCount: 1, tourCount: 1, expensesCount: 1, deletestate: [], hoexpensesCount: 1, deletearray: [], fullModal: false, billingFull: {}, units: [], tourPlaceslistOption: [], grpOverAllTotal: 0, getid: false, statusOptions: [], InCount: 1, vehicleOptions: [], _vehicleList: {}, sOdometer: 0, eOdometer: 0, vehicleStart: { state: '', value: '' }, vehicleEnd: { state: '', value: '' }, vTiming: 0, kmChargeText1: '', kmChargeText2: '', kmChargeValue: 0, cChargeText1: '', cChargeText2: '', cChargeValue: 0, dChargeText1: '', dChargeText2: '', dChargeValue: 0, oTotal: 0, _vehicleCount: 1, ticket_status_temp: [], assignedOptions: [] };
  }

  validFinStartDate = (current, _endDate) => {
    let today = moment();
    let todays = '';
    if (moment(_endDate)._isValid && moment(_endDate) !== today) {
      todays = moment(_endDate);
    } else
      return current.isAfter(today);
    return current.isAfter(today) && current.isBefore(todays);
  }

  validFinEndDate = (current, _startDate) => {
    let today = moment();
    let todays = ''
    if (moment(_startDate)._isValid && moment(_startDate) !== today) {
      todays = moment(_startDate);
    }
    return current.isAfter(today) && current.isAfter(todays);
  }

  toggle(tab, stater) {
    if (this.state[stater] !== tab) {
      let staters = this.state;
      staters[`${stater}`] = tab;
      this.setState({ staters });
    }
  }

  onRadioBtnClick(data, rSelected) {
    this.setState({ [`${data}Selected`]: rSelected });
  }

  validateTicketCreationForm = () => {
    const { dispatch, customer, ticket } = this.props;
    let { billing, ticketDetails, e_cust_id } = this.state;
    // let { services_ticket } = ticketDetails;
    let tempState = true;
    let newState = this.state.ticketCreation;
    newState.create = true;
    if (ticket && ticket.data && ticket.data[0]) {
      var tick_details = ticket.data[0];

    }


    _ticketCreate.forEach((v, i) => {
      if (!newState[`${v.name}`] && v.isReq) {
        newState[`${v.name}State`] = v.invalid;
        newState.create = false;
      } else if (newState[`${v.name}`] && newState[`${v.name}`] == "" && v.isReq) {
        newState[`${v.name}State`] = v.invalid;
        newState.create = false;
      } else if (v.regex && newState[`${v.name}`] != "") {
        var regex = new RegExp(v.regex);
        if (!regex.test(newState[`${v.name}`])) {
          newState[`${v.name}State`] = v.invalid;
          newState.create = false;
        } else {
          newState[`${v.name}State`] = v.valid;
        }
      } else {
        newState[`${v.name}State`] = v.valid;
      }
    });

    this.setState({
      ticketCreation: newState,
      billing: billing
    });

    // if(!tempState){
    //   this.openBilling();
    // }

    const { data } = ticket;

    if (this.state.ticketCreation.create) {
      // document.querySelector('.save-button').style.height = `${document.querySelector('.save-button').clientHeight}px`;
      // document.querySelector('.save-button').style.width = `${document.querySelector('.save-button').clientWidth}px`;
      // document.querySelector('.save-button').innerHTML = '<i className="fas fa-circle-notch fa-spin"></i>';

      loadingButton(document.querySelector('.save-button'));

      let userData = {
        firstname: this.state.ticketCreation.firstname,
        lastname: this.state.ticketCreation.lastname,
        email: this.state.ticketCreation.email,
        phone: this.state.ticketCreation.phone,
        address: this.state.ticketCreation.address,
        state: this.state.ticketCreation.state,
        city: this.state.ticketCreation.city,
        dob: (this.state.ticketCreation.dob) ? (isDate(this.state.ticketCreation.dob) ? this.state.ticketCreation.dob.toJSON().substring(0, 10) : this.state.ticketCreation.dob) : '',
        zipcode: this.state.ticketCreation.zipcode,
        id: e_cust_id ? e_cust_id : tick_details.customer_id,
        referred_by: this.state.ticketCreation.referred_by,
        referral_contact: this.state.ticketCreation.referral_contact

      };
      dispatch(userActions.updateCustomer(userData)).then(() => {
        this.postUpdateCustomer();
      });
    }
  };

  postUpdateCustomer() {
    const { customer, dispatch, authentication, ticket } = this.props;
    const { data } = ticket;
    const { e_cust_id } = this.state;
    if (ticket && ticket.data && ticket.data[0]) {
      var tick_details = ticket.data[0];
    }

    let customerId = e_cust_id ? e_cust_id : tick_details.customer_id;
    let ticketData = {
      // deptcode: (this.state.department_temp && this.state.department_temp.value) ? this.state.department_temp.value : (this.state.ticketCreation.department.value) ? this.state.ticketCreation.department.value : this.state.ticketCreation.department,
      ticket_status: (this.state.status_temp && this.state.status_temp.value) ? this.state.status_temp.value : (this.state.ticketCreation.status.value) ? this.state.ticketCreation.status.value : this.state.ticketCreation.status,
      poc: this.state.ticketCreation.poc,
      poc_number: this.state.ticketCreation.poc_number,
      created_by: authentication.user.user.id,
      next_follow_up: (this.state.ticketCreation.nfu) ? (isDate(this.state.ticketCreation.nfu) ? this.state.ticketCreation.nfu.toJSON().substring(0, 10) : this.state.ticketCreation.nfu) : '',
      customer_id: customerId,
      // assigned_to: (this.state.ticketCreation.assignedTo.value) ? (this.state.ticketCreation.assignedTo.value) : ((this.state.ticketCreation.assignedTo) ? this.state.ticketCreation.assignedTo : data.data.assigned_to),
      id: tick_details.id
    };
    dispatch(userActions.updateTicket(ticketData)).then(() => {
      // this.postTicketUpdate();
      return this.props.history.push('/admin/tickets/list');

    });

  };

  postTicketUpdate() {
    const { ticket, authentication, dispatch, customer } = this.props;
    const { ticketCreation, services_temp, e_ticket_id, e_cust_id } = this.state;

    const { service } = ticketCreation;
    let customerId = e_cust_id ? e_cust_id : (customer && customer.update && customer.update.data && customer.update.data.id) ? customer.update.data.id : '';
    if (e_ticket_id) {
      this.notificationApi(e_ticket_id);
      let ticket_id = e_ticket_id;
      let commentData = {
        ticket_id: ticket_id,
        comments: `Ticket ${ticket_id} was updated by ${authentication.user.user.name} in the departmnent ${authentication.user.user.deptcode} at ${moment(new Date()).format("Do MMMM YY hh:mm a")}`,
        commented_by: authentication.user.user.id,
        assigned_to: this.state.ticketCreation.assignedTo.value ? this.state.ticketCreation.assignedTo.value : this.state.ticketCreation.assignedTo
      }

      this.getServiceStatuses().then((res) => {
        if (res) {
          let ticketServicesData = {
            service_id: services_temp,
            ticket_id: ticket_id,
            servicesStatus: res,
            assigned_to: this.state.ticketCreation.assignedTo.value ? this.state.ticketCreation.assignedTo.value : this.state.ticketCreation.assignedTo,
            customer_id: customerId
          };
          dispatch(userActions.updateServiceTickets(ticketServicesData)).then(() => {
            // this.notify("tc", "", "Ticket Services updated successfully.");
          });
        }
      });

      dispatch(userActions.createComments(commentData)).then(() => {
        this.postCommentCreate();
      });
    }
  }

  async postCommentCreate() {
    const { comment, ticket, dispatch, authentication } = this.props;
    const { e_ticket_id } = this.state;

    // if(comment && comment.comment && comment.comment.data && comment.comment.data.id){
    //   // history.push(`ticket/${ticket.ticket.data.id}`);
    // }
    let logData = {
      ticket_id: e_ticket_id,
      assigned_to: this.state.ticketCreation.assignedTo.value ? this.state.ticketCreation.assignedTo.value : this.state.ticketCreation.assignedTo,
      status_id: this.state.ticketCreation.status.value ? this.state.ticketCreation.status.value : this.state.ticketCreation.status,
      assigned_by: authentication.user.user.id,
    }
    await dispatch(userActions.createLogs(logData)).then(() => {
      // this.notify("tc", "", "Logs added successfully.");
      this.postLogsCreate();
    });

    // document.querySelector('.save-button').style.height = `auto`;
    // document.querySelector('.save-button').style.width = `auto`;
    // document.querySelector('.save-button').innerHTML = 'Save';
    // window.location.href = `/admin/tickets/edit/${ticket.data.data.id}`;
  }

  async postLogsCreate() {
    const { dispatch, logs, ticket, customer } = this.props;
    const { doc_type, e_cust_id } = this.state;
    // let customerId = ticket.data.data.customer_id;
    let customerId = e_cust_id ? e_cust_id : (customer && customer.update && customer.update.data && customer.update.data.id) ? customer.update.data.id : '';
    if (this.state.billing.length > 0 || this.state.doc_type.length > 0) {
      if (this.state.doc_type.length > 0) {
        await doc_type.forEach((v, i) => {
          if (v.files) {
            const docData = new FormData();
            docData.append('document_name', v.files);
            docData.append('customer_id', customerId);
            docData.append('document_id', i);
            if (v.document_id) {
              docData.append('id', v.document_id);
              dispatch(userActions.updateUploadDocument(docData)).then(() => {
                // this.notify("tc", "", `Document ${i} uploaded successfully.`);
                // document.querySelector('.ticket-create-form').reset();
                // history.go(`admin/tickets/edit/${ticket.ticket.data.id}`);
              });
            } else {
              dispatch(userActions.uploadDocument(docData)).then(() => {
                // this.notify("tc", "", `Document ${i} uploaded successfully.`);
                // document.querySelector('.ticket-create-form').reset();
                // history.go(`admin/tickets/edit/${ticket.ticket.data.id}`);
              });
            }
          }
        });
      }

      if (this.state.billing.length > 0) {
        let { billing, ticketCreation, services_temp, services_temp_arr, e_ticket_id } = this.state;
        const { service } = ticketCreation;
        // let { services_ticket } = ticketDetails;

        let kj = 0;
        services_temp_arr.forEach((v, i) => {
          let gstAmtTmp = billing[services_temp[kj]] && billing[services_temp[kj]][`gst_amount`] || 0;
          let billingData = {
            'ticket_id': e_ticket_id,
            'service_id': services_temp[kj],
            'customer_id': customerId,
            'package_amount': (billing[services_temp[kj]] && (this.parserFloat(billing[services_temp[kj]][`price`]) + this.parserFloat(gstAmtTmp))) || '',
            'service_charge': billing[services_temp[kj]] && billing[services_temp[kj]][`service`] || 0,
            'service_tax_amount': billing[services_temp[kj]] && billing[services_temp[kj]][`service_tax`] || 0,
            'gst_amount': this.parserFloat(gstAmtTmp),
            'name': billing[services_temp[kj]] && billing[services_temp[kj]][`invoice_buyer_name`] || '',
            'amount_received': billing[services_temp[kj]] && billing[services_temp[kj]][`paid`] || '',
            'amount_pending': billing[services_temp[kj]] && billing[services_temp[kj]][`bal`] || '',
            'e_mop': billing[services_temp[kj]] && billing[services_temp[kj]][`mop`] || '',
            'e_paid_amount': billing[services_temp[kj]] && billing[services_temp[kj]][`e_paid_amount`] || 0, //e_paid_amount
            'invoice_to': billing[services_temp[kj]] && billing[services_temp[kj]][`invoice_toData`] && billing[services_temp[kj]][`invoice_toData`].value || '',
            'amount_paid_by': billing[services_temp[kj]] && billing[services_temp[kj]][`amount_paid_byData`] && billing[services_temp[kj]][`amount_paid_byData`].value || '',
            'vendor_name': billing[services_temp[kj]] && !isArray(billing[services_temp[kj]][`vendor_name`]) && billing[services_temp[kj]][`vendor_name`] || '',
            'invoice_buyer_name': billing[services_temp[kj]] && billing[services_temp[kj]][`invoice_buyer_name`] || '',
            'other_amount': billing[services_temp[kj]] && billing[services_temp[kj]][`others`] || 0
          };

          if (billing[services_temp[kj]] && billing[services_temp[kj]]['id'] || (this.state && this.state.HotelEpaidAmt)) {
            billingData['id'] = billing[services_temp[kj]]['id'];
            dispatch(userActions.updateBilling(billingData)).then(() => {
              // this.notify("tc", "", `Billing ${i+1} updated successfully.`);
            });
          } else {
            dispatch(userActions.createBilling(billingData)).then(() => {
              const { billing } = this.props;
              const { document } = billing;
              if (document && document.data) {
                const { data } = document;
                const { id } = data;
                billing[services_temp[kj]]['id'] = id;
                this.setState({ billing: billing });
              }
              // this.notify("tc", "", `Billing ${i+1} created successfully.`);
              // document.querySelector('.ticket-create-form').reset();
              // history.go(`admin/tickets/edit/${ticket.ticket.data.id}`);
            });
          }
          kj = kj + 1;
        });
      }

      this.finishTickerCreate();
    } else {
      this.finishTickerCreate();
    }
  }
  async getdelete1(id, unit1, elem) {
    const { dispatch } = this.props;
    const { expenses_data3 } = (this.state.expenses_data3) ? this.state : { expenses_data3: {} };
    const { expenses_data4 } = (this.state.expenses_data4) ? this.state : { expenses_data4: {} };
    var data1 = this.state.expenses_data1;
    var datas = [];
    if (data1 != undefined) {
      const { dispatch, ticket } = this.props;


      const { data } = ticket;
      let customerId = (isArray(data.data)) ? data.data[0].customer_id : data.data.customer_id;
      Object.keys(data1).forEach(i => { if (unit1 != i) { expenses_data3[i] = data1[i]; datas.push(data1[i]); } });
      let j = 0;
      Object.keys(expenses_data3).forEach(i => { j += 1; expenses_data4[j - 1] = expenses_data3[i]; });
      let lengthva = document.querySelectorAll(`.hotelRow_st`).length;
      var reflect = Reflect.ownKeys(expenses_data4);
      var countsho = this.state.hoexpensesCount - 1;
      if (reflect.length == 0) {
        countsho = 1;
        this.setState({ update_data: 'delete' });
      }
      if (id) {
        await dispatch(userActions.hoteldelete(id)).then(() => {
          //this.setState({ expenses_data1:expenses_data3 });
          this.notify("tc", "", "Hotel has been deleted successfully");
        });
      }
    }
  }

  vehicleSave = _class => {
    let elem = document.querySelector(`${_class}`);
    elem && loadingButton(elem);
    const { e_ticket_id, e_cust_id, select_vehicle, vehicleStart, vehicleEnd, vehicle_id, vehicle_pack_id, _vehiclePackage, _dataFin, eOdometer, sOdometer, vTiming, oDiscount, kmChargeText2, kmChargeText1, kmChargeValue, cChargeText1, cChargeText2, cChargeValue, dChargeText1, dChargeText2, dChargeValue, oTotal, toggle_vehicle_facility, vehicle_stage_3_id, kmChargeOrig, cChargeOrig, dChargeOrig } = this.state;

    const { dispatch } = this.props;
    if (_class === '.vehicle_save_stage_1') {
      let vehicle = document.querySelector('.select_vehicle');
      vehicle && removeInvalid(vehicle);
      if (vehicle && vehicle.childNodes[1].value !== '' && vehicleStart.value && vehicleEnd.value) {
        let vehicleData = { ticket_id: e_ticket_id, customer_id: e_cust_id, vehicle: JSON.stringify(select_vehicle), vehicle_start: vehicleStart.value, vehicle_end: vehicleEnd.value, stage: 1 }
        if (vehicle_id) {
          vehicleData.id = vehicle_id;
          dispatch(vehicleActions.vehicleUpdateStage1(vehicleData)).then(() => {
            elem && removeLoadingButton(elem, 'Save');
          });
        } else {
          dispatch(vehicleActions.vehicleSaveStage1(vehicleData)).then(() => {
            const { vehicleStages } = this.props;
            const { stage1 } = vehicleStages;
            const { data } = stage1;
            const { id } = data;
            this.setState({ vehicle_id: id });
            elem && removeLoadingButton(elem, 'Save');
          });
        }
      } else {
        vehicle && vehicle.childNodes[1].value === '' && addInvalid(vehicle);
        if (vehicleStart.value === '')
          this.setState({ vehicleStart: { value: vehicleStart.value, state: 'invalid' } });
        else if (vehicleEnd.value === '')
          this.setState({ vehicleEnd: { value: vehicleEnd.value, state: 'invalid' } });
      }
    } else if (_class === '.vehicle_save_stage_2') {
      let vehiclePackData = { vehicle_init: vehicle_id, pack_id: _vehiclePackage, facility: (toggle_vehicle_facility) ? 'A/C' : 'Non A/C', vehicle_data: JSON.stringify(_dataFin), stage: 2 };
      if (vehicle_pack_id) {
        vehiclePackData.id = vehicle_pack_id;
        dispatch(vehicleActions.vehicleUpdateStage2(vehiclePackData)).then(() => {
          elem && removeLoadingButton(elem, 'Save');
        });
      } else {
        dispatch(vehicleActions.vehicleSaveStage2(vehiclePackData)).then(() => {
          const { vehicleStages } = this.props;
          const { stage2 } = vehicleStages;
          const { data } = stage2;
          const { id } = data;
          this.setState({ vehicle_pack_id: id });
          elem && removeLoadingButton(elem, 'Save');
        });
      }
    } else if (_class === '.vehicle_save_stage_3') {
      let vehicleStage3 = { vehicle_init: vehicle_id, stage: 3, timing: vTiming, sodometer: sOdometer, eodometer: eOdometer, distance: ((eOdometer - sOdometer) || '0'), km_actual: kmChargeValue, km_original: kmChargeOrig, car_actual: cChargeValue, car_original: cChargeOrig, driver_actual: dChargeValue, driver_original: dChargeOrig, discount: oDiscount, total: oTotal };
      if (vehicle_stage_3_id) {
        vehicleStage3.id = vehicle_stage_3_id;
        dispatch(vehicleActions.vehicleUpdateStage3(vehicleStage3)).then(() => {
          elem && removeLoadingButton(elem, 'Save');
        });
      } else {
        dispatch(vehicleActions.vehicleSaveStage3(vehicleStage3)).then(() => {
          const { vehicleStages } = this.props;
          const { stage3 } = vehicleStages;
          const { data } = stage3;
          const { id } = data;
          this.setState({ vehicle_stage_3_id: id });
          elem && removeLoadingButton(elem, 'Save');
        });
      }
    } else {
      elem && removeLoadingButton(elem, 'Save');
    }
  }

  finishTickerCreate() {
    const { e_ticket_id } = this.state;
    const ticket_id = e_ticket_id;
    // document.querySelector('.toggle').click();

    // document.querySelector('.save-button').style.height = `${document.querySelector('.save-button').clientHeight}px`;
    // document.querySelector('.save-button').style.width = `${document.querySelector('.save-button').clientWidth}px`;
    // document.querySelector('.save-button').innerHTML = 'Save';

    this.notify("tc", "", "Ticket updated successfully.");
    setTimeout(() => {
      // this.forceUpdate();
      // this.props.history.push(`/admin/tickets/edit/${ticket_id}`);
      this.initialize();
      removeLoadingButton(document.querySelector('.save-button'), 'Save');
      // window.location.href = `/admin/tickets/edit/${ticket_id}`;
    }, 2500);
  }

  onServiceDateChange = (value, key, idSelector, isReq) => {
    value = (value && value._d);
    let newStates = this.state.serviceState;
    newStates[`${key}_${idSelector}`] = value.toJSON().substring(0, 10);
    newStates[`${key}_${idSelector}State`] = 'valid';
    if (isReq && !value)
      newStates[`${key}_${idSelector}State`] = 'invalid';
    this.setState({
      serviceState: newStates
    });
  };

  onDateChange = (value, idSelector) => {
    value = (value && value._d && moment(value._d).format('YYYY-MM-DD'));
    value = isDate(value) ? value.toJSON().substring(0, 10) : value;
    let newStates = this.state.ticketCreation;
    newStates[`${idSelector}`] = value;
    newStates[`${idSelector}State`] = 'invalid';
    this.setState({
      ticketCreation: newStates
    });
    if (value) {
      newStates[`${idSelector}State`] = 'valid';
      this.setState({
        ticketCreation: newStates
      });
    }
  };

  onServiceSelectChange = (value, key, idSelector, isReq) => {
    let sVal = null;
    let sValue = null;
    if (value) {
      if (Array.isArray(value)) {
        sVal = [];
        sValue = [];
        value.forEach((v, i) => { sVal.push(v.value); sValue[v.value] = v.label; });
      } else {
        sVal = '';
        sValue = '';
        sVal = value.value;
        sValue = value.label;
      }
    }
    let newStates = this.state.serviceState;
    newStates[`${key}_${idSelector}`] = sVal;
    newStates[`${key}_${idSelector}Value`] = sValue;
    newStates[`${key}_${idSelector}State`] = 'is-valid';
    if ((sVal == null || sVal === '') && isReq) { newStates[`${key}_${idSelector}State`] = 'is-invalid'; }
    this.setState({ serviceState: newStates });
  };

  onBillSelectChange = (_v, value, i, idSelector, service) => {
    if (idSelector == "amount_paid_by" && value && value.value === 'vendor' && service !== 'passport') {
      const { dispatch } = this.props;
      dispatch(userActions.getVendorList(service)).then(() => {
        const { vendor } = this.props;
        const { list } = vendor;
        const { data } = list;
        let vendorArr = this.state.vendorList || [];
        data.forEach((v, i) => {
          vendorArr.push({ value: v.id, label: v.name });
        })
        this.setState({
          vendorList: vendorArr
        });
      });
    }

    let sVal = (value) ? value.value : '';
    let sValue = (value) ? value.label : '';
    let { billing } = this.state;
    billing[_v.service_id] = (billing[_v.service_id]) ? billing[_v.service_id] : {};

    billing[_v.service_id][`${idSelector}`] = sVal;
    billing[_v.service_id][`${idSelector}Data`] = (value) ? value : '';
    billing[_v.service_id][`${idSelector}Value`] = sValue;
    this.setState({
      billing: billing
    });

  }

  onSelectChange = (value, idSelector) => {
    if (idSelector == "department") {
      if (this.refs && this.refs.select_assignedTo) {
        this.refs.select_assignedTo.select.clearValue();
      }
      if (value && !Array.isArray(value) && value.deptcode) {
        const { dispatch } = this.props;
        const { ticketCreation, _ticketCreateState } = this.state;

        // this.refs.select_assignedTo.select.clearValue();
        ticketCreation['departmentState'] = '';
        ticketCreation['assignedToState'] = '';
        this.setState({ ticketCreation: ticketCreation });
        dispatch(userActions.listDepartmentAssigned(value.deptcode)).then(() => {
          const { assigned } = this.props;

          _ticketCreateState[7].option = [];//(_ticketCreateState[7].option) ? _ticketCreateState[7].option : [];
          assigned && assigned.assigned && assigned.assigned.data && assigned.assigned.data.forEach((i, v) => {
            _ticketCreateState[7].option[i.id] = { value: i.id, label: i.name.trim(), badge: `${i.ticketslist.length}`, selected: (_ticketCreateState[7].value == i.id) };
          });
          ticketCreation["assignedTo"] = _ticketCreateState[7].option[_ticketCreateState[7].value];
          if (this.refs && this.refs.select_assignedTo && !this.refs.select_assignedTo.state.value) {
            this.refs.select_assignedTo.select.setValue(ticketCreation["assignedTo"]);
          }
          this.setState({ ticketCreation: ticketCreation, _ticketCreateState: _ticketCreateState });
        });

      }
    }

    let sVal = null;
    let sValObj = {};
    let sValue = null;
    if (value) {
      if (Array.isArray(value)) {
        sVal = [];
        sValue = [];
        value.forEach((v, i) => { sValObj[i] = v.value; sVal.push(v.value); sValue[v.value] = v.label; });
      } else {
        sVal = '';
        sValue = '';
        sVal = value.value;
        sValue = value.label;
      }
    }
    let newStates = this.state.ticketCreation;

    newStates[`${idSelector}`] = sVal;
    newStates[`${idSelector}Value`] = sValue;
    newStates[`${idSelector}State`] = 'is-invalid';
    this.setState({
      ticketCreation: newStates
    });
    if (sVal != null && sVal != '') {
      newStates[`${idSelector}State`] = 'is-valid';
      this.setState({
        ticketCreation: newStates,
      });
    }

    if (idSelector == "service") {
      let afterSelector = document.querySelectorAll('.css-1rhbuit-multiValue');
      let additionalData = 0;
      if (!this.state.services_temp)
        this.refs.select_service.select.setValue(value);
      if (afterSelector && afterSelector.length > 0) {
        additionalData = sVal && sVal.length - 3;
        // , extraData:additionalData
        let counter = document.querySelector('.css-1rhbuit');
        if (additionalData > 0) {
          if (!counter) {
            let lastDataChild = afterSelector[2];
            let div = document.createElement('div');
            div.classList.add('css-1rhbuit');
            div.innerHTML = `<span>+ ${additionalData}</span>`;
            if (lastDataChild)
              lastDataChild.parentNode.appendChild(div);
          } else {
            counter.innerHTML = `+ ${additionalData}`;
          }
        } else if (counter) { counter.parentNode.removeChild(counter); }
      }
      this.setState({ services_temp: sValObj });
    }
    if (idSelector == "status") {
      const { statusOptions } = this.state;
      var placeholder = "";
      statusOptions.forEach((v, i) => {
        if (v.value == value.value) {
          this.setState({ ticket_status_temp: [{ "value": value.value, "label": v.label }] })
        }
      });
      this.setState({ status_temp: value });
    }
    if (idSelector == "assignedTo") {
      const { assignedOptions } = this.state;
      var placeholder = "";
      assignedOptions.forEach((v, i) => {
        if (v.value == value.value) {
          this.setState({ assigned_temp: [{ "value": value.value, "label": v.label }] })
        }
      });
      this.setState({ assigned_temp: value });
    }
    if (idSelector == "department") {
      this.setState({ department_temp: value });
    }
  };

  regExpFromString(q) {
    let flags = q.replace(/.*\/([gimuy]*)$/, '$1');
    if (flags === q) flags = '';
    let pattern = (flags ? q.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1') : q);
    try { return new RegExp(pattern, flags); } catch (e) { return null; }
  }

  serviceStateForm = (e, key, stateName, isReq, regexp, regexInvalid, invalidFeedback, regstate) => {
    // let elem = e.target;
    // elem.value = e.target.value;
    let newState = this.state.serviceState;
    let serviceCustomer = this.state.serviceCustomer;
    let KEY = this.jsUcfirst(key);
    newState[`${key}_${stateName}`] = e.target.value;
    serviceCustomer[KEY] = (serviceCustomer[KEY]) ? serviceCustomer[KEY] : {};
    serviceCustomer[KEY]['data'] = (serviceCustomer[KEY]['data']) ? serviceCustomer[KEY]['data'] : [];
    serviceCustomer[KEY]['data'][0] = (serviceCustomer[KEY]['data'][0]) ? serviceCustomer[KEY]['data'][0] : {};
    serviceCustomer[KEY]['data'][0][key] = (serviceCustomer[KEY]['data'][0][key]) ? serviceCustomer[KEY]['data'][0][key] : {};
    serviceCustomer[KEY]['data'][0][key][stateName] = e.target.value;
    newState[`${key}_${stateName}State`] = "valid";
    if (e.target.value.trim() === "" && isReq) {
      newState[`${key}_${stateName}State`] = "invalid";
      newState[`${key}_${stateName}Feedback`] = invalidFeedback;
      newState.create = false;
    } else if (e.target.value.trim() !== "" && regexp) {
      let regex = this.regExpFromString(regexp);
      // if(`${key}_${stateName}`== 'currencies_amount' || `${key}_${stateName}`== 'currencies_customer_exchange_rate' || `${key}_${stateName}`== 'currencies_eroute_rate' )
      //   regex=/^[1-9]\d*(\.\d+)?$/;
      regex = new RegExp(regex);
      if (!regex.test(e.target.value.trim())) {
        newState[`${key}_${stateName}` + "Feedback"] = regexInvalid;
        newState[`${key}_${stateName}` + "State"] = "invalid";
        newState.create = false;
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({ serviceCustomer: serviceCustomer, serviceState: newState });
  };

  ticketCreationForm = (e, stateName, isReq, regexp, regexInvalid) => {
    // ticketCreationForm = (e, stateName) => {
    let newState = this.state.ticketCreation;
    newState[stateName] = e.target.value;
    newState[stateName + "Feedback"] = "";
    newState[stateName + "State"] = "valid";
    if (e.target.value.trim() === "" && isReq) {
      newState[stateName + "State"] = "invalid";
    } else if (e.target.value.trim() !== "" && regexp) {
      var regex = new RegExp(regexp);
      if (!regex.test(e.target.value.trim())) {
        newState[stateName + "Feedback"] = regexInvalid;
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({
      ticketCreation: newState
    });
  };

  setVehiclePrices = () => {
    setTimeout(() => {
      let { eOdometer, sOdometer, vTiming, oDiscount, _vehiclePackage, _dataFin, akmCharge, aCarCharge, aDriverCharge } = this.state;
      eOdometer = parseFloat(eOdometer);
      sOdometer = parseFloat(sOdometer);
      vTiming = parseFloat(vTiming);
      oDiscount = parseFloat(oDiscount);
      akmCharge = parseFloat(akmCharge);
      aCarCharge = parseFloat(aCarCharge);
      aDriverCharge = parseFloat(aDriverCharge);
      if (_dataFin && _vehiclePackage) {
        let kmChargeText1 = ``;
        let kmChargeText2 = ``;
        let kmChargeValue = 0;
        let kmChargeOrig = 0;
        let cChargeText2 = `x ${Math.ceil((vTiming) / 24)}`;
        let cChargeValue = `${aCarCharge * (Math.ceil((vTiming) / 24))}`;
        let cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
        let cChargeOrig = _dataFin.crent * (Math.ceil((vTiming) / 24));
        let dChargeText2 = `x ${Math.ceil((vTiming) / 24)}`;
        let dChargeValue = `${aDriverCharge * (Math.ceil((vTiming) / 24))}`;
        let dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
        let dChargeOrig = _dataFin.drent * (Math.ceil((vTiming) / 24));

        if (_vehiclePackage && _vehiclePackage === 1) {
          kmChargeText2 = `x ${Math.ceil((vTiming) / _dataFin.hours)}`;
          kmChargeValue = `${akmCharge * (Math.ceil((vTiming) / _dataFin.hours))}`;
          kmChargeText1 = `${akmCharge} ${kmChargeText2} = ${kmChargeValue}`;
          kmChargeOrig = _dataFin.perkm * (Math.ceil((vTiming) / _dataFin.hours));
          cChargeText2 = `x ${Math.ceil((vTiming) / _dataFin.hours)}`;
          cChargeValue = `${aCarCharge * (Math.ceil((vTiming) / _dataFin.hours))}`;
          cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
          cChargeOrig = _dataFin.crent * (Math.ceil((vTiming) / _dataFin.hours));
          dChargeText2 = `x ${Math.ceil((vTiming) / _dataFin.hours)}`;
          dChargeValue = `${aDriverCharge * (Math.ceil((vTiming) / _dataFin.hours))}`;
          dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
          dChargeOrig = _dataFin.drent * (Math.ceil((vTiming) / _dataFin.hours));
        } else if (_vehiclePackage && _vehiclePackage === 2) {
          kmChargeText2 = `x ${Math.ceil((vTiming) / 12)}`;
          kmChargeValue = `${_dataFin.hours * (Math.ceil((vTiming) / 12))}`;
          kmChargeText1 = `${_dataFin.hours} ${kmChargeText2} = ${kmChargeValue}`;
          kmChargeOrig = _dataFin.hours * (Math.ceil((vTiming) / 12));
          cChargeText2 = `x ${Math.ceil((vTiming) / 12)}`;
          cChargeValue = `${aCarCharge * (Math.ceil((vTiming) / 12))}`;
          cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
          cChargeOrig = _dataFin.crent * (Math.ceil((vTiming) / 12));
          dChargeText2 = `x ${Math.ceil((vTiming) / 12)}`;
          dChargeValue = `${aDriverCharge * (Math.ceil((vTiming) / 12))}`;
          dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
          dChargeOrig = _dataFin.drent * (Math.ceil((vTiming) / 12));
        } else if (_vehiclePackage && _vehiclePackage === 3) {
          kmChargeText2 = `x ((${eOdometer - sOdometer}) / 350) x 350`;
          kmChargeValue = `${akmCharge * (Math.ceil((eOdometer - sOdometer) / 350)) * 350}`;
          kmChargeText1 = `${akmCharge} ${kmChargeText2} = ${kmChargeValue}`;
          kmChargeOrig = _dataFin.perkm * (Math.ceil((eOdometer - sOdometer) / 350)) * 350;
          cChargeText2 = `x ((${eOdometer - sOdometer}) / 350)`;
          cChargeValue = `${aCarCharge * (Math.ceil((eOdometer - sOdometer) / 350))}`;
          cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
          cChargeOrig = _dataFin.crent * (Math.ceil((eOdometer - sOdometer) / 350));
          dChargeText2 = `x ((${eOdometer - sOdometer}) / 350)`;
          dChargeValue = `${aDriverCharge * (Math.ceil((eOdometer - sOdometer) / 350))}`;
          dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
          dChargeOrig = _dataFin.drent * (Math.ceil((eOdometer - sOdometer) / 350));
        }
        kmChargeValue = isNaN(kmChargeValue) ? '0' : kmChargeValue;
        cChargeValue = isNaN(cChargeValue) ? '0' : cChargeValue;
        dChargeValue = isNaN(dChargeValue) ? '0' : dChargeValue;
        oDiscount = isNaN(oDiscount) ? '0' : oDiscount;
        let oTotal = (parseFloat(kmChargeValue) + parseFloat(cChargeValue) + parseFloat(dChargeValue)) - parseFloat(oDiscount || 0);
        this.setState({
          kmChargeText2: kmChargeText2, kmChargeText1: kmChargeText1, kmChargeValue: kmChargeValue, cChargeText1: cChargeText1, cChargeText2: cChargeText2, cChargeValue: cChargeValue, dChargeText1: dChargeText1, dChargeText2: dChargeText2, dChargeValue: dChargeValue, oTotal: oTotal, dChargeOrig: dChargeOrig, cChargeOrig: cChargeOrig, kmChargeOrig: kmChargeOrig
        });
      }
    }, 300);
  }

  initialize() {
    document.querySelector('.navbar-nav .ticket-menu') && document.querySelector('.navbar-nav .ticket-menu').classList.add('active');
    const { dispatch, match } = this.props;

    // dispatch(userActions.getTicketSpecial(match.params.id)).then(() => {
    //   const { special } = this.props;
    //   if(special && special.ticket && special.ticket.data && isArray(special.ticket.data) && special.ticket.data.length > 0)
    //     this.setState({ specialCount: special.ticket.data.length, specialState: special });
    // });
    // dispatch(vehicleActions.vehicleList({})).then(() => {
    //   const { vehicleOptions, _vehicleList } = this.state;
    //   const { vehicle } = this.props;
    //   const { list } = vehicle;
    //   const { data } = list;
    //   data.forEach((v, i) => { vehicleOptions.push({ label: v.name, value: v.id }); _vehicleList[v.id] = v; });
    //   this.setState({ vehicleOptions: vehicleOptions, _vehicleList: _vehicleList });
    // });
    // dispatch(userActions.getInsurance(match.params.id)).then(() => {
    //   const { insurance } = this.props;
    //   if(insurance && insurance.list && insurance.list.data && isArray(insurance.list.data) && insurance.list.data.length > 0)
    //     this.setState({ InCount: insurance.list.data.length, insuranceState: insurance });
    // });
    // dispatch(userActions.listVendors()).then(() => {
    //   const { vendor } = this.props;
    //   if(vendor && vendor.list && vendor.list.data){
    //     let vendorinit = [ { value: 'e-route', label: 'E-Route' } ];
    //     vendor.list.data.forEach(v => { vendorinit.push({ value: v.id, label: v.name }); });
    //     this.setState({ vendorinit: vendorinit });
    //   }
    // });
    dispatch(userActions.listDepartment());
    // dispatch(userActions.listService());
    dispatch(userActions.listStatus()).then(() => {
      const { statusOptions } = this.state;
      const { status } = this.props;
      const { data } = (status && status.status) ? status.status : [];
      status && status.status.forEach((v, i) => { statusOptions.push({ label: v.name, value: v.id }); });
    });
    dispatch(userActions.listAssignedTo()).then(() => {
      const { assignedOptions } = this.state;
      const { assignedTo } = this.props;
      console.log(assignedTo,'assignedTo')
      const { data } = (assignedTo && assignedTo.assignedTo) ? assignedTo.assignedTo : [];
      assignedTo && assignedTo.assignedTo.forEach((v, i) => { assignedOptions.push({ label: v.name, value: v.id }); });
    });
    // dispatch(userActions.listDocumentType());
    // dispatch(userActions.listTourTypes());
    // dispatch(userActions.listTourPlaces()).then(() => {
    //   let { tourPlaceslistOption } = this.state;
    //   const { tourPlaceslist } = this.props;
    //   tourPlaceslist && tourPlaceslist.data && tourPlaceslist.data.data && tourPlaceslist.data.data.length > 0 && tourPlaceslist.data.data.forEach((v, i) => { tourPlaceslistOption.push({ label: v.name, value: v.id }); });
    // });
    dispatch(userActions.getTicket(match.params.id)).then(() => {
      const { ticket, authentication } = this.props;
      const { data } = ticket;
      const { assigned_to } = (data.data && data.data[0]) ? data.data[0] : { assigned_to: '0' };
      let { vehiclestages } = (data.data && data.data[0]) ? data.data[0] : { vehiclestages: '0' };
      let { user } = authentication;
      let { id } = user.user;
      if (ticket && ticket.data && ticket.data[0]) {
        var tick_details = ticket.data[0];
        console.log(tick_details);
        var ticket_status = [{ value: tick_details.ticket_status, label: tick_details.status[0]['name'] }];
        this.setState({ ticket_status_temp: ticket_status });
      }
      let _ticketDetails = data && data.data && data.data[0] || [];
      // let tourArr = _ticketDetails && _ticketDetails.services_ticket.filter(v => { return v.services && v.services[0] && v.services[0].name === "Tour" });
      // let hotelArr = _ticketDetails && _ticketDetails.services_ticket.filter(v => { return v.services && v.services[0] && v.services[0].name === "Hotel" });
      // tourArr && isArray(tourArr) && tourArr.length > 0 && this.setState({ select_tour_status: { value: tourArr[0].status[0].id, label: tourArr[0].status[0].name } });
      // hotelArr && isArray(hotelArr) && hotelArr.length > 0 && this.setState({ select_hotel_status: { value: hotelArr[0].status[0].id, label: hotelArr[0].status[0].name } });

      const userrole = user && user.user.role;
      const name = userrole;
      const isAdmin = name.toString().trim().toLowerCase() === "admin";
      const isManager = name.toString().trim().toLowerCase() === "mananger";
      const isEmployee = name.toString().trim().toLowerCase() === "staff";
      if (isEmployee && assigned_to != id) {
        return this.props.history.push('/admin/dashboard');
      }
      const { approved } = (data.data && data.data[0]) ? data.data[0] : { approved: '0' };

      let vStage4 = vehiclestages && vehiclestages.stage4;
      vStage4 && delete vehiclestages["stage4"];
      let vStage3 = vehiclestages && vehiclestages.stage3;
      vStage3 && delete vehiclestages["stage3"];
      let vStage2 = vehiclestages && vehiclestages.stage2;
      vStage2 && delete vehiclestages["stage2"];
      let vStage1 = vehiclestages;

      let vData = (vStage2 && vStage2.vehicle_data) ? JSON.parse(vStage2.vehicle_data) : '';
      let oTotal = (vStage3 && vStage3.total) ? vStage3.total : 0;

      this.setState({
        approved: approved == 1,
        select_vehicle: (vStage1 && vStage1.vehicle) ? JSON.parse(vStage1.vehicle) : '',
        vehicle_id: (vStage1 && vStage1.id) ? vStage1.id : '',
        toggle_vehicle_facility: (vStage2 && vStage2.facility === "A/C") ? true : false,
        _vehiclePackage: (vStage2 && vStage2.pack_id) ? parseInt(vStage2.pack_id) : '',
        vehicle_pack_id: (vStage2 && vStage2.id) ? vStage2.id : '',
        sOdometer: (vStage3 && vStage3.sodometer) ? vStage3.sodometer : '',
        eOdometer: (vStage3 && vStage3.eodometer) ? vStage3.eodometer : '',
        vehicle_stage_3_id: (vStage3 && vStage3.id) ? vStage3.id : '',
        oDiscount: (vStage3 && vStage3.discount) ? vStage3.discount : 0,
        distance: (vStage3 && vStage3.eodometer) - (vStage3 && vStage3.sodometer) || 0,
        oTotal: this.parserFloat(oTotal).toFixed(2),
        vTiming: (vStage3 && vStage3.timing) ? vStage3.timing : 0,
        kmChargeOrig: (vStage3 && vStage3.km_original) ? vStage3.km_original : 0,
        cChargeOrig: (vStage3 && vStage3.car_original) ? vStage3.car_original : 0,
        dChargeOrig: (vStage3 && vStage3.driver_original) ? vStage3.driver_original : 0,
        _dataPack: vData,
        _dataFin: vData,
        aDriverCharge: vData.drent,
        oDriverCharge: vData.drent,
        aCarCharge: vData.crent,
        oCarCharge: vData.crent,
        akmCharge: vData.perkm,
        okmCharge: vData.perkm,
        vehicleStart: { value: (vStage1 && vStage1.vehicle_start) ? vStage1.vehicle_start : '', state: (vStage1 && vStage1.vehicle_start) ? 'is-valid' : 'is-invalid' },
        vehicleEnd: { value: (vStage1 && vStage1.vehicle_end) ? vStage1.vehicle_end : '', state: (vStage1 && vStage1.vehicle_end) ? 'is-valid' : 'is-invalid' },
        vStage4: vStage4
      });
      this.setVehiclePrices();
      const { e_cust_id } = this.state;
      const { customer_id } = (data.data && data.data[0]) ? data.data[0] : { customer_id: '' };
      const { notification } = (data.data && data.data[0]) ? data.data[0] : { notification: '' };
      if (notification && notification.id)
        this.setState({ e_cust_id: e_cust_id ? e_cust_id : customer_id, e_notify_id: notification.id });
      else
        this.setState({ e_cust_id: e_cust_id ? e_cust_id : customer_id });
    });
    // dispatch(userActions.listTourCount(match.params.id)).then(() => {
    //   const { tourCountlist } = this.props;
    //   const { data } = tourCountlist;
    //   if(data && data.data && data.data[0]){
    //     this.setState({ showTourRows: true, startDate_places_init: data.data[0].start_date, endDate_places_init: data.data[0].end_date, tourCount: (data.data[0].tour_places.length) ? data.data[0].tour_places.length : 1, tour_id_init: data.data[0].id, food_places_init_chk: data.data[0].food_by, expensesCount: (data.data[0].food_expneses.length) ? data.data[0].food_expneses.length : 1 });
    //     if(data.data[0].tour_type && Object.keys(data.data[0].tour_type).length > 0){
    //       this.setState({ select_tour_type: { label: data.data[0].tour_type.name, value: data.data[0].tour_type.id } });
    //     }
    //     if(data.data[0].destination && Object.keys(data.data[0].destination).length > 0){
    //       this.setState({ places_tour_init: { label: data.data[0].destination.name, value: data.data[0].destination.id } });
    //     }
    //     if(data.data[0].tour && data.data[0].tour && data.data[0].tour.id){
    //       this.setState({ tour_tour_id: data.data[0].tour.id, select_group_type: (data.data[0].tour.group_type && data.data[0].tour.group_type[0]) ? { label: data.data[0].tour.group_type[0].name, value: data.data[0].tour.group_type[0].id } : '', select_tour_themes: { label: data.data[0].tour.tour_themes.name, value: data.data[0].tour.tour_themes.id }, group_count: data.data[0].tour.group_count, pay_type: data.data[0].tour.pay_type });
    //     }
    //     if(data.data[0].group_count && data.data[0].group_count && data.data[0].group_count.length > 0){
    //       const { group_data } = (this.state.group_data) ? this.state.group_data : { group_data: {} };
    //       let grpOverAllTotal = 0;
    //       let grpOverAllTotalInd = 0;
    //       data.data[0].group_count.forEach((vv, ii) => {
    //         let tempPaidCC = 0;
    //         let tempPaidDD = 0;
    //         let tempPaidDDInd = 0;
    //         let unit = ii + 1;
    //         vv.tour_groups && vv.tour_groups.forEach((v, i) => {
    //           if(v.customers && v.customers[0] && v.customers[0].billing && v.customers[0].billing[0] && v.customers[0].billing[0].amount_received  && parseFloat(v.customers[0].billing[0].amount_received) > 0){
    //             tempPaidCC = tempPaidCC + 1;
    //             tempPaidDD = tempPaidDD + parseFloat(v.customers[0].billing[0].amount_received);
    //             grpOverAllTotal = grpOverAllTotal + parseFloat(v.customers[0].billing[0].amount_received);
    //             if(i == 0){
    //               tempPaidDDInd = tempPaidDDInd + parseFloat(v.customers[0].billing[0].amount_received);
    //               grpOverAllTotalInd = grpOverAllTotalInd + parseFloat(v.customers[0].billing[0].amount_received);
    //             }
    //           }
    //         });
    //         group_data[unit] = { group_count: vv.group_count, group_name: vv.group_name, group_id: vv.id, groupPaid: tempPaidCC, grpTotal: parseFloat(tempPaidDD).toFixed(2), grpTotalInd: parseFloat(tempPaidDDInd).toFixed(2) };
    //       });
    //       this.setState({ group_data: group_data, grpOverAllTotal: grpOverAllTotal, grpOverAllTotalInd: grpOverAllTotalInd });
    //     }
    //     if(data.data[0].food_expneses && data.data[0].food_expneses && data.data[0].food_expneses.length > 0){
    //       const { expenses_data } = (this.state.expenses_data) ? this.state : { expenses_data: {} };
    //       data.data[0].food_expneses.forEach((v, i) => {
    //         expenses_data[i] = { expenses: v };
    //       });
    //       this.setState({ expenses_data: expenses_data });
    //     }
    //   }
    // });
    // dispatch(userActions.getTicketHotel(match.params.id)).then(() => {
    //   const { hotellist }=this.props;
    //   const { data } = hotellist;

    //   if(data.data && data.data && data.data.length > 0){
    // const { expenses_data1 } = (this.state.expenses_data1) ? this.state : { expenses_data1: {} };
    //   data.data.forEach((v, i) => {
    //     expenses_data1[i] = { expenses: v };
    //   });
    //   this.setState({
    //     expenses_data1: expenses_data1,
    //     hoexpensesCount:(data.data.length) ? data.data.length : data.data.length ,
    //     lengthcount:(data.data.length) ? data.data.length : data.data.length

    //      });

    //   }
    // });

    // dispatch(serviceActions.listVisaTypes()).then(() => {
    //   const { listVisaTypes } = this.props;
    //   const { visaTypes } = listVisaTypes;
    //   const { data } = visaTypes;
    //   let visaTypesInint = [];
    //   data && data.forEach((v, i) => { visaTypesInint.push({ label: v.name, value: v.id }); });
    //   this.setState({ visaTypesInint: visaTypesInint });
    // });
  }

  componentWillUnmount() {
    document.querySelector('.navbar-nav .ticket-menu') && document.querySelector('.navbar-nav .ticket-menu').classList.remove('active');
  }

  componentDidMount() {
    this.initialize();
  }

  parserFloat(data) {
    return isNaN(parseFloat(data)) ? 0 : parseFloat(data)
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  validEndData2 = (current) => {
    const { startDate_places_init } = this.state;
    var today = moment(startDate_places_init);
    if (!startDate_places_init)
      return current.isAfter(today);
    today = moment(startDate_places_init);
    return current.isAfter(today);
  }

  validStartData2 = (current) => {
    const { endDate_places_init } = this.state;
    var today = moment();
    if (!endDate_places_init)
      return current.isAfter(today);
    today = moment(endDate_places_init);
    return current.isBefore(today);
  }

  validEndData = (current) => {
    const { startDate } = this.state;
    if (!startDate)
      return true;
    var today = moment(startDate);
    return current.isAfter(today);
  }

  validStartData = (current) => {
    const { endDate } = this.state;
    if (!endDate)
      return true;
    var today = moment(endDate);
    return current.isBefore(today);
  }

  renderDateInput(props, openCalendar, closeCalendar) {
    return (
      // <div className="input-group">
      //   <div className="input-group-prepend">
      //     <span className="input-group-text py-0 px-2"><i className="far fa-calendar"></i></span>
      //   </div>
      <input {...props} />
      // </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { status, department, service, match, ticket, dispatch, serviceFields } = this.props;
    const { ticketDetails, loading, ticketCreation, doc_type, serviceCustomer, startDate_places_init, endDate_places_init, food_places_init_chk, pay_type, vStage4, oTotal } = this.state;




    if (this.props !== prevProps) {
      _ticketCreate[0].value = '';
      _ticketCreate[1].value = '';
      _ticketCreate[2].value = '';
      _ticketCreate[3].value = '';
      _ticketCreate[4].value = '';
      _ticketCreate[5].value = [];
      _ticketCreate[6].value = [];
      // _ticketCreate[7].value = [];
      _ticketCreate[8].value = [];
      _ticketCreate[9].value = '';
      _ticketCreate[10].value = '';
      _ticketCreate[11].value = '';
      _ticketCreate[12].value = '';
      _ticketCreate[13].value = '';
      // _ticketCreate[14].value = '';
      // _ticketCreate[15].value = '';

      const { data } = ticket;
      let _ticketDetails = null
      ticketCreation["service"] = [];
      if (ticket && ticket.data && ticket.data[0]) {
        _ticketDetails = ticket.data[0];
        _ticketCreate[0].value = (_ticketDetails.customer_details[0].firstname != null) ? _ticketDetails.customer_details[0].firstname : "";
        _ticketCreate[1].value = (_ticketDetails.customer_details[0].lastname != null) ? _ticketDetails.customer_details[0].lastname : "";
        _ticketCreate[2].value = (_ticketDetails.customer_details[0].email != null) ? _ticketDetails.customer_details[0].email : "";
        _ticketCreate[4].value = (_ticketDetails.customer_details[0].phone != null) ? _ticketDetails.customer_details[0].phone : "";
        _ticketCreate[9].value = (_ticketDetails.customer_details[0].address != null) ? _ticketDetails.customer_details[0].address : "";
        _ticketCreate[10].value = (_ticketDetails.customer_details[0].state != null) ? _ticketDetails.customer_details[0].state : "";
        _ticketCreate[11].value = (_ticketDetails.customer_details[0].city != null) ? _ticketDetails.customer_details[0].city : "";
        _ticketCreate[12].value = (_ticketDetails.customer_details[0].zipcode != null) ? _ticketDetails.customer_details[0].zipcode : "";
        _ticketCreate[4].value = (_ticketDetails.customer_details[0].dob != null) ? _ticketDetails.customer_details[0].dob : "";
        _ticketCreate[6].value = _ticketDetails.department && _ticketDetails.department.id;
        let data42312 = {};
        if (!this.state.services_temp)
          this.setState({ services_temp: data42312, services_temp_val: _ticketCreate[5].value, services_temp_arr: _ticketDetails.services_ticket });
        // _ticketCreate[8].value = _ticketDetails.ticket_status;
        _ticketCreate[6].value = _ticketDetails.ticket_status;
        _ticketCreate[7].value = _ticketDetails.assigned_to;
        _ticketCreate[13].value = (_ticketDetails.poc != null) ? _ticketDetails.poc : "";
        // _ticketCreate[14].value = (_ticketDetails.poc_number != null) ? _ticketDetails.poc_number : "";
        // _ticketCreate[15].value =  ticketCreation["referred_by"] ? ticketCreation["referred_by"] : (_ticketDetails.customer_details[0].referred_by != null) ? _ticketDetails.customer_details[0].referred_by : "";
        // _ticketCreate[16].value = ticketCreation["referred_by"] ? ticketCreation["referred_by"] : (_ticketDetails.customer_details[0].referral_contact != null) ? _ticketDetails.customer_details[0].referral_contact : "";
        ticketCreation["firstname"] = ticketCreation["firstname"] ? ticketCreation["firstname"] : (_ticketDetails.customer_details[0].firstname != null) ? _ticketDetails.customer_details[0].firstname : "";
        ticketCreation["lastname"] = ticketCreation["lastname"] ? ticketCreation["lastname"] : (_ticketDetails.customer_details[0].lastname != null) ? _ticketDetails.customer_details[0].lastname : "";
        ticketCreation["email"] = ticketCreation["email"] ? ticketCreation["email"] : (_ticketDetails.customer_details[0].email != null) ? _ticketDetails.customer_details[0].email : "";
        ticketCreation["phone"] = ticketCreation["phone"] ? ticketCreation["phone"] : (_ticketDetails.customer_details[0].phone != null) ? _ticketDetails.customer_details[0].phone : "";
        ticketCreation["address"] = ticketCreation["address"] ? ticketCreation["address"] : (_ticketDetails.customer_details[0].address != null) ? _ticketDetails.customer_details[0].address : "";
        ticketCreation["state"] = ticketCreation["state"] ? ticketCreation["state"] : (_ticketDetails.customer_details[0].state != null) ? _ticketDetails.customer_details[0].state : "";
        ticketCreation["city"] = ticketCreation["city"] ? ticketCreation["city"] : (_ticketDetails.customer_details[0].city != null) ? _ticketDetails.customer_details[0].city : "";
        ticketCreation["zipcode"] = ticketCreation["zipcode"] ? ticketCreation["zipcode"] : (_ticketDetails.customer_details[0].zipcode != null) ? _ticketDetails.customer_details[0].zipcode : "";
        ticketCreation["dob"] = ticketCreation["dob"] ? ticketCreation["dob"] : (_ticketDetails.customer_details[0].dob != null) ? _ticketDetails.customer_details[0].dob : "";

        ticketCreation["status"] = _ticketDetails.ticket_status;
        if (this.refs && this.refs.select_status && !this.refs.select_status.state.value) {
          this.refs.select_status.select.setValue(_ticketDetails.ticket_status);
        }

        ticketCreation["poc"] = ticketCreation["poc"] ? ticketCreation["poc"] : (_ticketDetails.poc != null) ? _ticketDetails.poc : "";
        ticketCreation["poc_number"] = ticketCreation["poc_number"] ? ticketCreation["poc_number"] : (_ticketDetails.poc_number != null) ? _ticketDetails.poc_number : "";
        ticketCreation["referred_by"] = ticketCreation["referred_by"] ? ticketCreation["referred_by"] : (_ticketDetails.customer_details[0].referred_by != null) ? _ticketDetails.customer_details[0].referred_by : "";
        ticketCreation["referral_contact"] = ticketCreation["referred_by"] ? _ticketDetails.customer_details[0].referral_contact : (_ticketDetails.customer_details[0].referral_contact != null) ? _ticketDetails.customer_details[0].referral_contact : "";

        // document.querySelector('.select_department').setValue([ _ticketCreate[9].value ], 'clear');
        // this.setState({ 'onlyFirst': true });
      }

      // _ticketCreate[5].option = [];
      // _ticketCreate[6].option = [];
      // _ticketCreate[7].option = [];
      // _ticketCreate[8].option = [];

      // department && department.department && department.department.data && department.department.data.forEach((i, v) => {
      //   _ticketCreate[6].option[i.id] = { value: i.id, label: i.deptname.trim(), deptcode: i.code, selected: (_ticketCreate[6].value == i.id) };
      // });

      // service && service.service && service.service.data && service.service.data.forEach((i, v) => {
      //   _ticketCreate[5].option[i.id] = { value: i.id, label: i.name.trim(), selected: (_ticketCreate[5].value == i.id) };
      // });
      status && status.status && status.status.forEach((i, v) => {
        _ticketCreate[6].option[i.id] = { value: i.id, label: i.name.trim(), selected: (_ticketCreate[6].value == i.id) ? true : false };
      });
      let billingData = this.state.billing;
      let cusPaid = 0;
      vStage4 && vStage4.forEach((v, i) => { cusPaid = cusPaid + parseFloat(v.cuspaid); });
      billingData[9] = (billingData[9]) ? billingData[9] : {};
      billingData[9].price = (this.parserFloat(oTotal) + (this.state.vStage4Pay ? this.parserFloat(this.state.vStage4Pay) : this.parserFloat(cusPaid))).toFixed(2);

      this.setState({ billing: billingData, e_ticket_id: match.params.id, _ticketCreateState: _ticketCreate, ticketDetails: _ticketDetails, ticketCreation: ticketCreation, doc_type: doc_type, vStage4Pay: this.parserFloat(cusPaid).toFixed(2) });
    }

    if (this.state.vStage4Pay !== prevState.vStage4Pay) {
      let billingData = this.state.billing;
      const { oTotal, vStage4Pay } = this.state;
      billingData[9] = (billingData[9]) ? billingData[9] : {};
      billingData[9].price = (this.parserFloat(oTotal) + (vStage4Pay ? this.parserFloat(vStage4Pay) : 0)).toFixed(2);
      this.setState({ billing: billingData });
    }
  }

  doAction(e, _item, _action) {
    e.stopPropagation();
  };

  handleBillingInputsFullBlur(i, e, v, action) {
    let taxPercent = this.parserFloat(this.state.service_tax_percent) / 100;
    let taxAmount = this.parserFloat(document.querySelector(`.billing_full_${i}_service`).value) * taxPercent;
    let gstAmount = taxAmount * (this.parserFloat(this.state.gst_percent) / 100);
    document.querySelector(`.billing_full_${i}_service_tax`).value = taxAmount;
    // document.querySelector(`.billing_full_${i}_price`).value
    let billpriceval = (this.parserFloat(document.querySelector(`.billing_full_${i}_o_price`).value) + this.parserFloat(document.querySelector(`.billing_full_${i}_service`).value) + this.parserFloat(document.querySelector(`.billing_full_${i}_service_tax`).value) + this.parserFloat(document.querySelector(`.billing_full_${i}_others`).value));
    // document.querySelector(`.billing_full_${i}_bal`).value
    let billbalval = this.parserFloat(billpriceval) - this.parserFloat(document.querySelector(`.billing_full_${i}_paid`).value);

    let { billingFull } = this.state;
    billingFull[i] = (billingFull[i]) ? billingFull[i] : {};
    billingFull[i]['invoice_buyer_name'] = (document.querySelector(`.billing_full_${i}_invoice_buyer_name`)) ? document.querySelector(`.billing_full_${i}_invoice_buyer_name`).value : ((billingFull[i]['invoice_buyer_name']) ? billingFull[i]['invoice_buyer_name'] : '');
    billingFull[i]['others'] = this.parserFloat(document.querySelector(`.billing_full_${i}_others`).value).toFixed(2) || 0;
    billingFull[i]['o_price'] = this.parserFloat(document.querySelector(`.billing_full_${i}_o_price`).value).toFixed(2) || 0;
    billingFull[i]['service'] = this.parserFloat(document.querySelector(`.billing_full_${i}_service`).value).toFixed(2) || 0;
    billingFull[i]['paid'] = this.parserFloat(document.querySelector(`.billing_full_${i}_paid`).value).toFixed(2) || 0;
    billingFull[i]['service_tax'] = this.parserFloat(document.querySelector(`.billing_full_${i}_service_tax`).value).toFixed(2) || 0;
    billingFull[i]['price'] = billpriceval.toFixed(2) || 0;
    billingFull[i]['bal'] = billbalval.toFixed(2) || 0;
    billingFull[i]['gst_amount'] = 0;
    billingFull[i]['e_paid_amount'] = (document.querySelector(`.billing_full_${i}_e_paid_amount`)) ? (document.querySelector(`.billing_full_${i}_e_paid_amount`).value) : billingFull[i]['e_paid_amount'];
    this.setState({ billingFull: billingFull });
  }

  handleBillingInputsFullChange(i, e, v, action) {
    let taxPercent = this.parserFloat(this.state.service_tax_percent) / 100;
    let taxAmount = this.parserFloat(document.querySelector(`.billing_full_${i}_service`).value) * taxPercent;
    let gstAmount = taxAmount * (this.parserFloat(this.state.gst_percent) / 100);
    document.querySelector(`.billing_full_${i}_service_tax`).value = taxAmount;
    // document.querySelector(`.billing_full_${i}_price`).value
    let billpriceval = (this.parserFloat(document.querySelector(`.billing_full_${i}_o_price`).value) + this.parserFloat(document.querySelector(`.billing_full_${i}_service`).value) + this.parserFloat(document.querySelector(`.billing_full_${i}_service_tax`).value) + this.parserFloat(document.querySelector(`.billing_full_${i}_others`).value));
    // document.querySelector(`.billing_full_${i}_bal`).value
    let billbalval = this.parserFloat(billpriceval) - this.parserFloat(document.querySelector(`.billing_full_${i}_paid`).value);

    let { billingFull } = this.state;
    billingFull[i] = (billingFull[i]) ? billingFull[i] : {};
    billingFull[i]['invoice_buyer_name'] = (document.querySelector(`.billing_full_${i}_invoice_buyer_name`)) ? document.querySelector(`.billing_full_${i}_invoice_buyer_name`).value : ((billingFull[i]['invoice_buyer_name']) ? billingFull[i]['invoice_buyer_name'] : '');
    billingFull[i]['others'] = document.querySelector(`.billing_full_${i}_others`).value || 0;
    billingFull[i]['o_price'] = document.querySelector(`.billing_full_${i}_o_price`).value || 0;
    billingFull[i]['service'] = document.querySelector(`.billing_full_${i}_service`).value || 0;
    billingFull[i]['paid'] = this.parserFloat(document.querySelector(`.billing_full_${i}_paid`).value) || 0;
    billingFull[i]['service_tax'] = this.parserFloat(document.querySelector(`.billing_full_${i}_service_tax`).value).toFixed(2) || 0;
    billingFull[i]['price'] = billpriceval.toFixed(2) || 0;
    billingFull[i]['bal'] = billbalval.toFixed(2) || 0;
    billingFull[i]['gst_amount'] = 0;
    billingFull[i]['e_paid_amount'] = (document.querySelector(`.billing_full_${i}_e_paid_amount`)) ? (document.querySelector(`.billing_full_${i}_e_paid_amount`).value) : billingFull[i]['e_paid_amount'];
    this.setState({ billingFull: billingFull });
  }

  handleBillingTourInputsChange(i, e, v, action) {
    let { billing, grpOverAllTotalInd, grpOverAllTotal, services_temp_arr, specialState } = this.state;
    grpOverAllTotalInd = (grpOverAllTotalInd) ? grpOverAllTotalInd : 0;
    grpOverAllTotal = (grpOverAllTotal) ? grpOverAllTotal : 0;
    let _val = e.currentTarget.value;
    let services_ticket = services_temp_arr;

    let SpecialPrice = 0, GroupIndPrice = (this.state.pay_type) ? grpOverAllTotalInd : grpOverAllTotal, FoodVendorPrice = 0, HotelPrice = 0, VehiclePrice = 0, OtaTotal = 0;
    services_ticket && isArray(services_ticket) && services_ticket.forEach((v, i) => {
      if (v.services && v.services[0] && v.services[0].name && v.services[0].name.toLowerCase().trim() === "tour") {
        specialState && specialState.ticket && specialState.ticket.data && specialState.ticket.data.forEach(special => {
          SpecialPrice = SpecialPrice + this.parserFloat(special.customer_paid);
        });
        this.state.expenses_data && Object.keys(this.state.expenses_data).forEach(expenses => {
          FoodVendorPrice = FoodVendorPrice + this.parserFloat(this.state.expenses_data[expenses].expenses.amount);
        });
        this.state.expenses_data1 && Object.keys(this.state.expenses_data1).forEach(expenses1 => {
          HotelPrice = HotelPrice + this.parserFloat(this.state.expenses_data1[expenses1].expenses.amount);
        });
        SpecialPrice = this.parserFloat(SpecialPrice).toFixed(2);
        GroupIndPrice = this.parserFloat(GroupIndPrice).toFixed(2);
        FoodVendorPrice = this.parserFloat(FoodVendorPrice).toFixed(2);
        HotelPrice = this.parserFloat(HotelPrice).toFixed(2);
        VehiclePrice = this.parserFloat(VehiclePrice).toFixed(2);
        OtaTotal = this.parserFloat(this.parserFloat(SpecialPrice) + this.parserFloat(GroupIndPrice) + this.parserFloat(FoodVendorPrice) + this.parserFloat(HotelPrice) + this.parserFloat(VehiclePrice)).toFixed(2);
      }
    });
    billing = (billing) ? billing : {};
    billing[v.service_id] = (billing[v.service_id]) ? billing[v.service_id] : {};
    billing[v.service_id]['invoice_buyer_name'] = '';
    billing[v.service_id]['others'] = 0;
    billing[v.service_id]['price'] = (v.service_id === 9) ? (billing[v.service_id]['price'] ? billing[v.service_id]['price'] : 0) : this.parserFloat(_val);
    billing[v.service_id]['service'] = 0;
    billing[v.service_id]['service_tax'] = 0;
    billing[v.service_id]['e_paid_amount'] = 0;
    billing[v.service_id]['gst_amount'] = 0;
    billing[v.service_id]['paid'] = (v.service_id === 9) ? this.parserFloat(_val) : OtaTotal;
    billing[v.service_id]['o_price'] = 0;
    billing[v.service_id]['bal'] = (v.service_id === 9) ? ((this.parserFloat(billing[v.service_id]['price']) || 0) - this.parserFloat(_val)).toFixed(2) : (this.parserFloat(this.parserFloat(OtaTotal) - this.parserFloat(_val)).toFixed(2));
    this.setState({ billing: billing });
  }

  handleBillingInputsChange(i, e, v, action) {
    let taxPercent = this.parserFloat(this.state.service_tax_percent) / 100;
    let taxAmount = this.parserFloat(this.refs[`billing_${i}_service`].value) * taxPercent;
    let gstAmount = taxAmount * (this.parserFloat(this.state.gst_percent) / 100);
    this.refs[`billing_${i}_service_tax`].value = taxAmount;
    // this.refs[`billing_${i}_price`].value
    let billpriceval = (this.parserFloat(this.refs[`billing_${i}_o_price`].value) + this.parserFloat(this.refs[`billing_${i}_service`].value) + this.parserFloat(this.refs[`billing_${i}_service_tax`].value) + this.parserFloat(this.refs[`billing_${i}_others`].value));
    // this.refs[`billing_${i}_bal`].value
    let billbalval = this.parserFloat(billpriceval) - this.parserFloat(this.refs[`billing_${i}_paid`].value);
    let priceData = this.refs[`billing_${i}_o_price`].value;
    let regex = /^[0-9]\d{0,9}(\.\d{1,2})?%?$/gi;//;
    if (!regex.test(priceData))
      priceData = this.parserFloat(priceData).toFixed(2);
    // priceData = this.parse;

    let { billing } = this.state;
    billing[v.service_id] = (billing[v.service_id]) ? billing[v.service_id] : {};
    billing[v.service_id]['invoice_buyer_name'] = (this.refs[`billing_${i}_invoice_buyer_name`]) ? this.refs[`billing_${i}_invoice_buyer_name`].value : ((billing[v.service_id]['invoice_buyer_name']) ? billing[v.service_id]['invoice_buyer_name'] : '');
    billing[v.service_id]['others'] = this.parserFloat(this.refs[`billing_${i}_others`].value) || 0;
    billing[v.service_id]['o_price'] = priceData || '';
    billing[v.service_id]['service'] = this.parserFloat(this.refs[`billing_${i}_service`].value) || 0;
    billing[v.service_id]['paid'] = this.parserFloat(this.refs[`billing_${i}_paid`].value) || 0;
    billing[v.service_id]['service_tax'] = this.parserFloat(this.refs[`billing_${i}_service_tax`].value).toFixed(2) || 0;
    billing[v.service_id]['price'] = this.parserFloat(billpriceval).toFixed(2) || 0;
    billing[v.service_id]['bal'] = this.parserFloat(billbalval).toFixed(2) || 0;
    billing[v.service_id]['gst_amount'] = this.parserFloat(gstAmount).toFixed(2) || 0;
    billing[v.service_id]['e_paid_amount'] = (this.refs[`billing_${i}_e_paid_amount`]) ? (this.refs[`billing_${i}_e_paid_amount`].value) : billing[v.service_id]['e_paid_amount'];
    this.setState({ billing: billing });
  }

  saveServices(_type, _fields) {
    const { ticket, dispatch, service } = this.props;
    let { serviceState, serviceCustomer } = this.state;
    let serviceValid = true;
    let saveService = document.querySelector(`.save${_type}Service`);
    if (saveService) {
      loadingButton(saveService);
    }
    let serviceObj = {};
    serviceObj[`${_type}`] = {};
    let _TYPE = this.jsUcfirst(_type);

    _fields.forEach((v, i) => {
      if (serviceCustomer && serviceCustomer[_TYPE] && serviceCustomer[_TYPE]['data'] && serviceCustomer[_TYPE]['data'][0][_type] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].toString() == "" && v.isReq) {
        serviceState[`${_type}_${v.state}`] = v.invalid;
        serviceValid = false;
      } else if ((serviceCustomer && serviceCustomer[_TYPE] && serviceCustomer[_TYPE]['data'] && serviceCustomer[_TYPE]['data'][0][_type] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].toString() != "")) {
        if (v.regex) {
          let regex = this.regExpFromString(v.regex);

          regex = new RegExp(regex);
          if (!regex.test(serviceState && serviceState[`${_type}_${v.name}`] && serviceState[`${_type}_${v.name}`].toString().trim())) {
            serviceState[`${v.name}` + "Feedback"] = v.regexInvalid;
            serviceState[`${v.name}` + "State"] = "invalid";
            serviceValid = false;
          } else {
            serviceState[`${_type}_${v.state}`] = v.valid;
            serviceObj[`${_type}`][`${v.name}`] = isObject(serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`]) ?
              serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].id : serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`];
            if (serviceValid)
              serviceValid = true;
          }
        } else {
          serviceState[`${_type}_${v.state}`] = v.valid;
          serviceObj[`${_type}`][`${v.name}`] = isObject(serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`]) ? serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].id : serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`];
          if (serviceValid)
            serviceValid = true;
        }
      } else if ((serviceState && serviceState[`${_type}_${v.name}`] && serviceState[`${_type}_${v.name}`].toString().trim() !== "") || !v.isReq) {
        serviceState[`${_type}_${v.state}`] = v.valid;
        serviceObj[`${_type}`][`${v.name}`] = serviceState[`${_type}_${v.name}`];
        if (serviceValid)
          serviceValid = true;
      } else {
        serviceState[`${_type}_${v.state}`] = v.invalid;
        serviceValid = false;
      }
    });

    this.setState({ serviceState: serviceState });

    if (serviceValid) {
      if (saveService) {
        loadingButton(saveService, 'Save');
        serviceObj[`${_type}`].customer_id = ticket.data.data[0].customer_id;
        if (serviceCustomer && serviceCustomer[_TYPE] && serviceCustomer[_TYPE]['data'][0][_type] && serviceCustomer[_TYPE]['data'][0][_type]['id']) {
          if (_type === "currencies") {
            serviceObj[`${_type}`]['ticket_id'] = this.state.e_ticket_id;
            serviceObj[`${_type}`]['currency_type'] = serviceState[`${_type}_currency_type`];
            serviceObj[`${_type}`]['handled_by'] = serviceState[`${_type}_handled_by`];

            var buy_sell = 'Buy';
            let buy_sellData = document.querySelector('.toggle_currencies_buy_sell');
            if (buy_sellData && buy_sellData.checked) {
              buy_sell = 'Sell';
            }
            serviceObj[`${_type}`]['buy_sell'] = buy_sell;
          }
          serviceObj[`${_type}`].status = (serviceState[`${_type}_status`]) ? serviceState[`${_type}_status`] : serviceObj[`${_type}`].status;
          serviceObj[`${_type}`].id = serviceCustomer[_TYPE]['data'][0][_type]['id'];
          dispatch(serviceActions.updateAction(_type, serviceObj[`${_type}`])).then(() => {
            // this.notify("tc", "", `${this.jsUcfirst(_type)} updated successfully.`);
            // this.initialize();
            this.saveServicesStatus().then(() => {
              removeLoadingButton(saveService, 'Save');
            });
          });
        } else {

          if (_type === "currencies") {
            serviceObj[`${_type}`]['ticket_id'] = this.state.e_ticket_id;
            var buy_sell = 'Buy';
            let buy_sellData = document.querySelector('.toggle_currencies_buy_sell');
            if (buy_sellData && buy_sellData.checked) {
              buy_sell = 'Sell';
            }
            serviceObj[`${_type}`]['buy_sell'] = buy_sell;
          }
          dispatch(serviceActions.createAction(_type, serviceObj[`${_type}`])).then(() => {
            // this.notify("tc", "", `${this.jsUcfirst(_type)} created successfully.`);
            // window.location.reload();
            const { services } = this.props;
            const { data } = services.services;
            const { id } = data;
            serviceCustomer[_TYPE]['data'][0][_type]['id'] = id;
            this.setState({ serviceCustomer: serviceCustomer });
            // const { service }
            // this.props.history.push(`/admin/tickets/edit/${this.state.e_ticket_id}`);
            // this.initialize();

            // ;
            // history.location = history.location;
            this.saveServicesStatus().then(() => {
              removeLoadingButton(saveService, 'Save');
            });
          });
        }
      }
    } else {
      if (saveService) {
        removeLoadingButton(saveService, 'Save');
      }
    }
  }

  notify = (place, title, message) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: "default",
      icon: "ni ni-bell-55",
      autoDismiss: 15
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  addDocType(e, sName) {
    e.preventDefault();
    const { ticketCreation } = this.state;
    const { doc_type } = this.state;
    if (ticketCreation.doc_type != null) {
      doc_type[ticketCreation.doc_type] = {
        'id': ticketCreation.doc_type,
        'value': ticketCreation.doc_typeValue
      };

      this.setState({
        [`${sName}`]: doc_type
      });
    }
  }

  openBrowseButton(e, value, key) {
    e.preventDefault();
    document.querySelector(`.${value}_file_${key}`).click();
  }

  deleteAttachment(e, value, key) {
    loadingButton(e.target);
    e.preventDefault();
    let { doc_type } = this.state;
    const { dispatch } = this.props;
    let document_data = {
      id: doc_type[key].id
    }
    dispatch(userActions.deleteDocument(document_data)).then(() => {
      delete doc_type[key];
      this.setState({
        doc_type: doc_type
      });
    });
  }

  loadImage(e, value, key) {
    let img = document.querySelector(`.${value}_img_${key}`);
    let preview = document.querySelector(`.${value}_preview_${key}`);
    const { doc_type } = this.state;
    preview.classList.add('d-none');
    preview.classList.remove('d-flex');
    img.src = '';
    doc_type[key].files = '';
    if (e.target && e.target.files && e.target.files[0]) {
      img.src = '...';
      img.alt = '...';
      if (e.target.files[0].type.indexOf('image') !== -1) {
        img.src = URL.createObjectURL(e.target.files[0]);
      }
      doc_type[key].files = e.target.files[0];
      preview.classList.remove('d-none');
      preview.classList.add('d-flex');
    }

    this.setState({
      doc_type: doc_type
    });
  }

  ucwords(string) {
    string = string.trim();
    let strarr = string.split();
    let returnstr = '';
    strarr.forEach((v, i) => {
      returnstr = `${returnstr} ${this.jsUcfirst(v)}`;
    });
    return returnstr.trim();
  }

  jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  openBilling() {
    this.setState({ billingModal: true, 'billing_error': '' });
  }

  closeBilling() {
    let { billing, services_temp_arr } = this.state;
    let tempState = true;
    let tempi = '';
    services_temp_arr.forEach((v, i) => {
      let temp_serv = v.services && isArray(v.services) && v.services.length > 0 && v.services[0] && v.services[0]['name'] && v.services[0]['name'].toLowerCase();
      if (temp_serv !== 'tour' && temp_serv !== 'vehicle' && temp_serv !== 'currencies') {
        billing[v.service_id] = (billing[v.service_id]) ? billing[v.service_id] : {};

        billing[v.service_id]['mopState'] = (billing[v.service_id]['mop']) ? 'valid' : 'invalid';
        billing[v.service_id]['amount_paid_byState'] = (billing[v.service_id]['amount_paid_by']) ? 'valid' : 'invalid';
        billing[v.service_id]['invoice_toState'] = (billing[v.service_id]['invoice_to']) ? 'valid' : 'invalid';
        billing[v.service_id]['invoice_buyer_nameState'] = (billing[v.service_id]['invoice_buyer_name']) ? 'valid' : 'invalid';
        billing[v.service_id]['vendor_nameState'] = (billing[v.service_id]['amount_paid_by'] == 'vendor') ? ((billing[v.service_id]['vendor_name'] && billing[v.service_id]['vendor_name'] != null) ? 'valid' : 'invalid') : 'valid';

        if (billing[v.service_id]['mopState'] === 'invalid' || billing[v.service_id]['amount_paid_byState'] === 'invalid' || billing[v.service_id]['invoice_toState'] === 'invalid' || billing[v.service_id]['invoice_buyer_nameState'] === 'invalid' || billing[v.service_id]['vendor_nameState'] === 'invalid') {
          tempState = false;
          tempi = (tempi === '') ? i : tempi;
        }
      }
    });
    if (tempState) {
      this.setState({ 'billing_error': '' });
      this.toggleModal("billingModal");
    } else {
      this.setState({
        'openedCollapses': [`collapse${tempi}`],
        'billing_error': (<Alert color="warning" className="m-0 mt--5">
          <strong>Warning!</strong> <br />Required Fields are missing. You may lose the data. <br />Do you want to continue?
          <br /> <button className="btn btn-secondary btn-sm mt--3 float-right" onClick={() => { this.setState({ 'billing_error': '' }) }}>Cancel</button> <button className="btn btn-secondary btn-sm mt--3 mr-1 float-right" onClick={() => { this.toggleModal("billingModal"); this.setState({ 'openedCollapses': '' }); }}>Ok</button>
        </Alert>)
      });
    }
  }

  validDob = (current) => {
    var today = moment();
    return current.isBefore(today);
  }

  validFuture = (current) => {
    var today = moment();
    return current.isAfter(today);
  }

  initializeTour(e) {
    let elem = e.target;
    loadingButton(elem);
    let tour_tour_id = document.querySelector('.tour_tour_id');
    let tour_id = document.querySelector('.tour_id_init');
    let select_tour_themes = document.querySelector('.select_tour_themes');
    let select_group_type = document.querySelector('.select_group_type');
    let ind_grp_pay = document.querySelector('.ind_grp_pay');
    let group_count = document.querySelector('.group_count');
    removeInvalid(select_tour_themes);
    removeInvalid(select_group_type);
    removeInvalid(group_count);
    let grpType = true;
    if (this.state.select_tour_themes && this.state.select_tour_themes.value === 2)
      grpType = select_group_type.childNodes[1].value;

    if (select_tour_themes.childNodes[1].value && grpType && parseInt(group_count.value) > 0) {
      const { e_ticket_id } = this.state;
      const { dispatch } = this.props;
      let tourInsertData = {
        "ticket_id": e_ticket_id,
        "tour_id": tour_id.value,
        "tour_themes": select_tour_themes.childNodes[1].value,
        "group_count": parseInt(group_count.value),
        "group_type": (this.state.select_tour_themes && this.state.select_tour_themes.value === 2) ? select_group_type.childNodes[1].value : '',
        "pay_type": (ind_grp_pay.checked) ? 0 : 1
      }
      if (tour_tour_id.value.trim() != "") {
        tourInsertData["id"] = tour_tour_id.value;
        dispatch(userActions.updateTour(tourInsertData)).then(() => {
          removeLoadingButton(elem, "Go");
          const { tourUpdate } = this.props;
          const { data } = tourUpdate;
          const { tour } = data;
          this.saveServicesStatus().then(() => {
            if (tour) {
              this.notify("tc", "", `Tour Details has been updated successfully.`);
            }
          });
        });
      } else {
        dispatch(userActions.createTour(tourInsertData)).then(() => {
          removeLoadingButton(elem, "Go");
          const { tourCreate } = this.props;
          const { data } = tourCreate;
          const { tour } = data;
          this.saveServicesStatus().then(() => {
            if (tour) {
              this.setState({ group_count: parseInt(group_count.value), tour_tour_id: tour.id });
              this.notify("tc", "", `Tour Details has been created successfully.`);
            }
          });
        });
      }
    } else {
      removeLoadingButton(elem, "Go");
      if (select_tour_themes.childNodes[1].value === "")
        addInvalid(select_tour_themes);
      if (select_group_type.childNodes[1].value === "")
        addInvalid(select_group_type);
      if (group_count.value === "" || parseInt(group_count.value) < 0)
        addInvalid(group_count);
    }
  }

  saveTour(e) {
    let elem = e.target;
    loadingButton(elem);

    let save = true;
    let grpRows = document.querySelectorAll('.grpRow_tc');
    let grpCount = {};
    grpRows.forEach((v, i) => {
      let unit = i + 1;
      let grpTemp = document.querySelector(`.group_name_${unit}`);
      let mcTemp = document.querySelector(`.member_count_${unit}`);

      grpTemp && removeInvalid(grpTemp);
      mcTemp && removeInvalid(mcTemp);
      if (grpTemp && grpTemp.value.trim() !== "" && mcTemp && mcTemp.value.trim() !== "") {
        if (!/^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _,.&]*)$/.test(grpTemp.value)) {
          save = false;
          addInvalid(grpTemp);
        }
        if (!/^[1-9]\d*(d+)?$/.test(mcTemp.value)) {
          save = false;
          addInvalid(mcTemp);
        }

        grpCount[i] = { group_count: parseInt(mcTemp.value.trim()), group_name: grpTemp.value.trim() };
      } else {
        save = false;
        if (grpTemp && grpTemp.value.trim() === "") {
          addInvalid(grpTemp);
        }
        if (mcTemp && mcTemp.value.trim() === "") {
          addInvalid(mcTemp);
        }
      }
    });

    if (save) {
      let tour_tour_id = document.querySelector('.tour_tour_id');
      let tour_id = document.querySelector('.tour_id_init');
      let select_tour_themes = document.querySelector('.select_tour_themes');
      let select_group_type = document.querySelector('.select_group_type');
      let ind_grp_pay = document.querySelector('.ind_grp_pay');
      let group_count = document.querySelector('.group_count');
      const { e_ticket_id } = this.state;
      const { tourCountlist, tourCountCreate, dispatch } = this.props;
      let tourInsertData = {
        "ticket_id": e_ticket_id,
        "tour_id": tour_id.value,
        "tour_themes": select_tour_themes.childNodes[1].value,
        "group_count": parseInt(group_count.value),
        "group_type": select_group_type.childNodes[1].value,
        "pay_type": (ind_grp_pay.checked) ? 0 : 1
      }
      if (tour_tour_id.value.trim() != "") {
        tourInsertData["id"] = tour_tour_id.value;
        dispatch(userActions.updateTour(tourInsertData)).then(() => {
          removeLoadingButton(elem, "Go");
          const { tourUpdate } = this.props;
          const { data } = tourUpdate;
          const { tour } = data;
          this.saveServicesStatus().then(() => {
            if (tour) {
              this.notify("tc", "", `Tour Details has been updated successfully.`);
            }
          });
        });
      }

      let groupInsertData = {
        tour_id: tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].id || tourCountCreate && tourCountCreate.data && tourCountCreate.data.tour.id,
        group_counts: grpCount
      }

      if (tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].group_count && tourCountlist.data.data[0].group_count.length > 0) {
        dispatch(userActions.updateGroupCount(groupInsertData)).then(() => {
          removeLoadingButton(elem, "Save");
          let { group_data } = this.state;
          const { updateGroupCount } = this.props;
          const { data } = updateGroupCount;
          const { group_count } = data;
          if (group_count) {
            group_count.forEach((v, i) => {
              if (v.id) {
                // let tempGrpId = document.querySelectorAll(`.grpRow_tc`)[i].children[0].children[0];
                // if(tempGrpId){
                //   tempGrpId.value = v.id;
                group_data = (group_data) ? group_data : {};
                group_data[i + 1] = (group_data[i + 1]) ? group_data[i + 1] : {};
                group_data[i + 1]['group_id'] = v.id;
                // }
              }
            });
            this.setState({ group_data: group_data });
            this.saveServicesStatus().then(() => {
              this.notify("tc", "", `Groups has been updated successfully.`);
            });
          }
        });
      } else {
        dispatch(userActions.createGroupCount(groupInsertData)).then(() => {
          removeLoadingButton(elem, "Save");
          let { group_data } = this.state;
          const { createGroupCount } = this.props;
          const { data } = createGroupCount;
          const { group_count } = data;
          if (group_count) {
            group_count.forEach((v, i) => {
              if (v.id) {
                // let tempGrpId = document.querySelectorAll(`.grpRow_tc`)[i].children[0].children[0];
                // if(tempGrpId){
                //   tempGrpId.value = v.id;
                group_data = (group_data) ? group_data : {};
                group_data[i + 1] = (group_data[i + 1]) ? group_data[i + 1] : {};
                group_data[i + 1]['group_id'] = v.id;
                // }
              }
            });
            this.setState({ group_data: group_data });
            this.saveServicesStatus().then(() => {
              this.notify("tc", "", `Groups has been created successfully.`);
            });
          }
        });
      }
    } else {
      removeLoadingButton(elem, "Save");
    }
  }

  async getServiceStatuses() {
    const { services_temp, select_tour_status, select_hotel_status, serviceState } = this.state;
    let servicesStatus = [];
    await Object.keys(services_temp).forEach(key => {
      if (services_temp[key] === 1) { servicesStatus.push(select_tour_status && select_tour_status.value || 1); }
      else if (services_temp[key] === 6) { servicesStatus.push(select_hotel_status && select_hotel_status.value || 1); }
      else if (services_temp[key] === 2) { servicesStatus.push(serviceState && serviceState['passport_status'] || 1); }
      else if (services_temp[key] === 3) { servicesStatus.push(serviceState && serviceState['visa_status'] || 1); }
      else { servicesStatus.push(1); }
    });
    return servicesStatus;
  }

  async saveServicesStatus() {
    const { dispatch } = this.props;
    const { services_temp, ticketCreation, e_ticket_id, e_cust_id } = this.state;
    await this.getServiceStatuses().then((res) => {
      if (res) {
        let ticketServicesData = {
          service_id: services_temp,
          servicesStatus: res,
          ticket_id: e_ticket_id,
          assigned_to: this.state.ticketCreation.assignedTo.value ? this.state.ticketCreation.assignedTo.value : this.state.ticketCreation.assignedTo,
          customer_id: e_cust_id
        };
        dispatch(userActions.updateServiceTickets(ticketServicesData));
      }
    });
  }

  saveTourPlaces(e) {
    let elem = e.target;
    loadingButton(elem);
    let tourPlacesData = {};
    document.querySelectorAll('.tourRow_st').forEach((v, i) => {
      let places = document.querySelectorAll(`.tourRow_st`)[i].children[2].children[0];
      let endDate = document.querySelectorAll(`.tourRow_st`)[i].children[1].children[0].children[0].children[0];
      let startDate = document.querySelectorAll(`.tourRow_st`)[i].children[0].children[1].children[0].children[0];
      let tourRowId = document.querySelectorAll(`.tourRow_st`)[i].children[0].children[0];
      if (startDate && endDate && places && startDate.value && endDate.value && places.childNodes[1].value) {
        tourPlacesData[i] = {
          "start_date": startDate.value,
          "end_date": endDate.value,
          "place_id": places.childNodes[1].value
        }
      }
    });

    if (tourPlacesData && Object.keys(tourPlacesData).length > 0) {
      const { tourCountCreate, tourCountlist } = this.props;
      let tourPlacesCreateData = {
        tour_id: tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].id || tourCountCreate && tourCountCreate.data && tourCountCreate.data.tour.id,
        tour_places: tourPlacesData
      }
      const { dispatch } = this.props;
      if (tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].tour_places && tourCountlist.data.data[0].tour_places.length > 0) {
        dispatch(userActions.updateTourPlaces(tourPlacesCreateData)).then(() => {
          const { tourPlacesUpdate } = this.props;
          this.saveServicesStatus().then(() => {
            this.notify("tc", "", `Places has been updated successfully.`);
            removeLoadingButton(elem, "Save");
          });
        });
      } else {
        dispatch(userActions.createTourPlaces(tourPlacesCreateData)).then(() => {
          const { tourPlacesCreate } = this.props;
          this.saveServicesStatus().then(() => {
            if (isObject(tourCountCreate) && Object.keys(tourCountCreate).length > 0) {
              this.notify("tc", "", `Places has been created successfully.`);
            }
            removeLoadingButton(elem, "Save");
          });
        });
      }
    } else {
      removeLoadingButton(elem, "Save");
    }
  }

  showPlaceRows(e) {
    let elem = e.target;
    loadingButton(elem);
    let tour_id = document.querySelector('.tour_id_init');
    let place_id = document.querySelector('.places_tour_init');
    let select_tour_type = document.querySelector('.select_tour_type');
    let foodPlaces = document.querySelector('.food_places_init');
    let startDate = document.querySelector('.start_date_tour_places_init');
    // let startDatePrev = startDate.previousSibling;
    let endDate = document.querySelector('.end_date_tour_places_init');
    // let endDatePrev = endDate.previousSibling;
    removeInvalid(startDate);
    // removeInvalid(startDatePrev);
    removeInvalid(endDate);
    // removeInvalid(endDatePrev);
    removeInvalid(select_tour_type);
    removeInvalid(place_id);
    if (this.state.endDate_places_init && this.state.startDate_places_init && select_tour_type.childNodes[1].value && place_id.childNodes[1].value) {
      const { dispatch, ticket } = this.props;
      const { data } = ticket;
      if (data && data.data[0] && data.data[0].id) {
        let tourCountCreateData = {
          "tour_type": select_tour_type.childNodes[1].value,
          "ticket_id": ticket.data.data[0].id,
          "place_id": place_id.childNodes[1].value,
          "customer_id": ticket.data.data[0].customer_id,
          "start_date": this.state.startDate_places_init,
          "end_date": this.state.endDate_places_init,
          "food_by": (foodPlaces.checked) ? 1 : 0
        }
        if (tour_id.value) {
          tourCountCreateData['id'] = tour_id.value;
          dispatch(userActions.updateTourCount(tourCountCreateData)).then(() => {
            removeLoadingButton(elem, "Save");
            const { tourCountUpdate } = this.props;
            const { data } = tourCountUpdate;
            const { tour } = data;
            if (tour) {
              this.saveServicesStatus().then(() => {
                this.notify("tc", "", `Places Management updated successfully.`);
              });
            }
          });
        } else {
          dispatch(userActions.createTourCount(tourCountCreateData)).then(() => {
            removeLoadingButton(elem, "Save");
            const { tourCountCreate } = this.props;
            const { data } = tourCountCreate;
            const { tour } = data;
            if (tour) {
              tour_id.value = tour.id;
              this.setState({ tour_id_init: tour.id });
              this.setState({ showTourRows: true });
              this.saveServicesStatus().then(() => {
                this.notify("tc", "", `Places Management created successfully.`);
              });
            }
          });
        }
      }
    } else {
      removeLoadingButton(elem, "Save");
      if (select_tour_type.childNodes[1].value.trim() === "") {
        addInvalid(select_tour_type);
      }
      if (place_id.childNodes[1].value.trim() === "") {
        addInvalid(place_id);
      }
      if (startDate.value.trim() === "") {
        addInvalid(startDate);
        // addInvalid(startDatePrev);
      } if (endDate.value.trim() === "") {
        addInvalid(endDate);
        // addInvalid(endDatePrev);
      }
    }
  }

  createGroup(e, unit) {
    let elem = e.target;
    loadingButton(elem);
    const { tourCountlist, tourCountCreate, dispatch } = this.props;
    let gnTemp = document.querySelector(`.group_name_${unit}`);
    let mcTemp = document.querySelector(`.member_count_${unit}`);
    let grpId = document.querySelector(`.group_id_${unit}`);
    let tour_id = tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].id || tourCountCreate && tourCountCreate.data && tourCountCreate.data.tour.id;

    removeInvalid(gnTemp);
    removeInvalid(mcTemp);
    if (mcTemp.value && !isNaN(mcTemp.value) && gnTemp.value) {
      dispatch(userActions.tourGroupsList(grpId.value, tour_id)).then(() => {
        this.toggleFullModal(gnTemp.value, mcTemp.value, grpId.value, unit);
        removeLoadingButton(elem, "Go");
      });
    } else {
      removeLoadingButton(elem, "Go");
      if (isNaN(mcTemp.value) || mcTemp.value === '')
        addInvalid(mcTemp);
      if (gnTemp.value === '')
        addInvalid(gnTemp);
    }
  }

  toggleFullModal(gnTemp, mcTemp, grpId, unit) {
    this.setState({ fullModal: !this.state.fullModal, grpName: gnTemp, memCount: mcTemp, currCount: 1, grpId: grpId, groupC: unit });
  }

  shouldComponentUpdate() {
    return true;
  }

  notificationApi(ticket_id) {
    const { e_notify_id, ticketCreation } = this.state;
    const { dispatch } = this.props;
    let notificationData = {
      t_id: ticket_id,
      type: 0,
      notification_status: 0,
      assigned_to: ticketCreation.assignedTo.value ? ticketCreation.assignedTo.value : ticketCreation.assignedTo
    };
    if (!e_notify_id) {
      dispatch(userActions.createNotification(notificationData)).then(() => {
        const { notificationcreate } = this.props;
        const { notification } = notificationcreate;
        const { data } = notification;
        const { id } = data;
        this.setState({ e_notify_id: id });
      });
    } else {
      notificationData.id = e_notify_id;
      dispatch(userActions.updateNotification(notificationData))
    }
  }

  closeFullModal() {
    const { tour_id_init, groupDetails, grpName, memCount, grpId } = this.state;
    let groupInsertData = {
      "tour_id": tour_id_init,
      "tour_groups": groupDetails
    }
    this.toggleFullModal(grpName, memCount, grpId, '');
  }
  hotel_delete(elemClass) {
    let elem = document.querySelector(`${elemClass}`);
    const { deletearray } = this.state.deletearray;
    const { dispatch } = this.props;
    let rowDatadelete = {};

    if (elem)
      loadingButton(elem);
    this.state && this.state.deletearray && this.state.deletearray.forEach((v, i) => {
      rowDatadelete[i] = {
        "id": v
      }
    });
    if (rowDatadelete) {
      dispatch(userActions.hoteldelete(rowDatadelete)).then(() => {
        if (elem)
          removeLoadingButton(elem, "delete");
        this.notify("tc", "", "Hotel has been deleted successfully");
      });
    }

  }
  hotel_save(elemClass) {
    let elem = document.querySelector(`${elemClass}`);
    const { dispatch, ticket } = this.props;
    const { data } = ticket;
    let customerId = (isArray(data.data)) ? data.data[0].customer_id : data.data.customer_id;

    if (elem)
      loadingButton(elem);
    let rows = document.querySelectorAll(`.hotelRow_st`);
    let rowSave = true;
    let rowDataInsert = {
      "ticket_id": this.state.e_ticket_id,
      "customer_id": customerId,
      "tour_id": "0",
      "category": 'hotel',
      "food_expenses": {}
    };
    var tempStartDate = '';
    var tempEndDate = '';
    var tempPlace = '';
    var tempVendor = '';
    var tempAmount = '';
    var tempHotelname = '';

    rows.forEach((v, i) => {
      let tempStartDate = document.querySelectorAll(`.hotelRow_st`)[i].children[0].children[3].children[0].children[0];
      let tempEndDate = document.querySelectorAll(`.hotelRow_st`)[i].children[1].children[0].children[0].children[0];
      let tempPlace = document.querySelectorAll(`.hotelRow_st`)[i].children[2].children[0].childNodes[1];
      let tempVendor = document.querySelectorAll(`.hotelRow_st`)[i].children[3].children[0].childNodes[1];
      let tempAmount = document.querySelectorAll(`.hotelRow_st`)[i].children[5].children[0].children[0];
      let tempHotelname = document.querySelectorAll(`.hotelRow_st`)[i].children[4].children[0];
      let rowsLen = document.querySelectorAll(`.hotelRow_st`).length;
      let gethiddenclass = document.querySelectorAll(`.hotelRow_st`)[i].children[0].children[1];

      if (tempHotelname.value != '' && tempAmount.value != '') {
        var regex = new RegExp(/^(?:[A-Za-z]+)(?:[A-Za-z ]*)$/);
        var regex2 = new RegExp(/^\d{0,12}(\.\d{1,2})?$/);
        if (!regex.test(tempHotelname.value.trim())) {
          var namestatus = true;
        }
        else if (!regex2.test(tempAmount.value.trim())) {
          var amtstatus = true;
        }
      }

      removeInvalid(tempStartDate);
      removeInvalid(tempEndDate);
      removeInvalid(tempPlace);
      removeInvalid(tempHotelname);
      removeInvalid(tempVendor);
      removeInvalid(tempAmount);

      if ((tempStartDate.value != '' || tempEndDate.value != '') && tempHotelname.value != '' && tempPlace.value != '' && tempVendor.value != '' && tempAmount.value != '' && !namestatus && !amtstatus) {
        var startdate = tempStartDate.value;
        var enddate = tempEndDate.value;

        if (tempStartDate.value != '' && tempEndDate.value == '') {
          enddate = tempStartDate.value;
        } else if (tempEndDate.value != '' && tempStartDate.value == '') {
          startdate = tempEndDate.value;
        }
        rowDataInsert.food_expenses[i] = {
          "start_date": startdate,
          "end_date": enddate,
          "place_id": tempPlace.value,
          "vendor_id": tempVendor.value,
          "amount": tempAmount.value,
          "hotel_name": tempHotelname.value,
          "gethidden_value": gethiddenclass.value
        };

      } else {
        //  if(!isLastRow){
        if (elem)
          removeLoadingButton(elem, "Save");
        rowSave = false;
      }

      if (tempStartDate.value === "" && tempEndDate.value === "") {

        addInvalid(tempStartDate);
        addInvalid(tempEndDate);
      }

      if (tempPlace.value === "")
        addInvalid(tempPlace);
      if (tempVendor.value === "")
        addInvalid(tempVendor);
      if (tempAmount.value === "" || amtstatus)
        addInvalid(tempAmount);
      if (tempHotelname.value === "" || namestatus)
        addInvalid(tempHotelname);
    });

    if (rowSave) {
      this.saveServicesStatus();
      const { hotelExpensesInsert } = this.props;
      const { expenses_data1 } = this.state;

      if (((hotelExpensesInsert && hotelExpensesInsert.data && hotelExpensesInsert.data.food
        && hotelExpensesInsert.data.food.length > 0) ||
        (expenses_data1 && expenses_data1[0]['expenses'] && expenses_data1[0]['expenses']['id'] && expenses_data1[0]['expenses']['id'] != ""))) {
        dispatch(userActions.hotelExpensesUpdate(rowDataInsert, 'hotel')).then(() => {
          const { expenses_data2 } = (this.state.expenses_data2) ? this.state : { expenses_data2: {} };
          let ob2 = this.state.expenses_data1;
          var hoepaidamout = 0;
          var hiddenfact = rowDataInsert.food_expenses;
          this.props.hotelExpensesUpdate.data.getup.forEach((v, i) => { hoepaidamout = hoepaidamout + parseFloat(v.amount); expenses_data2[hiddenfact[i]['gethidden_value']] = { expenses: v }; });
          this.setState({ expenses_data1: expenses_data2, update_data: 3 });
          this.state && this.state.billing && this.state.billing.forEach((va, i) => {
            if (va.service_id == 6) {
              va.e_paid_amount = hoepaidamout;
              let billlingup = {
                'id': va.id, 'ticket_id': this.state.e_ticket_id, 'customer_id': customerId, 'service_id': va.service_id,
                'package_amount': Number(va.price) + Number(va.gst_amount), 'service_charge': va.service, 'service_tax_amount': va.service_tax,
                'amount_received': va.paid, 'amount_pending': va.bal, 'e_paid_amount': hoepaidamout, 'other_amount': va.others, 'gst_amount': Number(va.gst_amount),
                'e_mop': va.mop, 'invoice_to': va.invoice_to, 'amount_paid_by': va.amount_paid_by, 'vendor_name': "", 'invoice_buyer_name': va.invoice_buyer_name
              };
              dispatch(userActions.updateBilling(billlingup));
              this.setState({ HotelEpaidAmt: hoepaidamout });
            }

          });
          if (elem)
            removeLoadingButton(elem, "Save");
          this.notify("tc", "", "Hotel has been updated successfully");
        });
      } else {
        dispatch(userActions.hotelExpensesInsert(rowDataInsert, 'hotel')).then(() => {
          const { expenses_datas } = (this.state.expenses_datas) ? this.state : { expenses_datas: {} };
          var data1 = this.props.hotelExpensesInsert.data.food;
          var hoepaidamout = 0;
          var hiddenfact = rowDataInsert.food_expenses;
          data1.forEach((v, i) => { hoepaidamout = hoepaidamout + parseFloat(v.amount); expenses_datas[hiddenfact[i]['gethidden_value']] = { expenses: v }; });
          this.setState({ update_data: 3, expenses_data1: expenses_datas });
          let { billing } = this.state;
          let billdata = { 'ticket_id': this.state.e_ticket_id, 'customer_id': customerId, 'service_id': 6, 'package_amount': 0, 'service_charge': 0, 'service_tax_amount': 0, 'amount_received': 0, 'amount_pending': 0, 'e_paid_amount': hoepaidamout, 'other_amount': 0 };
          billing && billing.forEach((va, i) => {
            if (va.service_id == 6) {
              va.id && this.setState({ getid: true });
              if (this.state.getid == true) { va.e_paid_amount = hoepaidamout; }
              let billlingup1 = {
                'id': va.id, 'ticket_id': this.state.e_ticket_id, 'customer_id': customerId,
                'service_id': va.service_id, 'package_amount': Number(va.price) + Number(va.gst_amount),
                'service_charge': va.service, 'service_tax_amount': va.service_tax, 'amount_received': va.paid,
                'amount_pending': va.bal, 'e_paid_amount': hoepaidamout, 'other_amount': va.others, 'gst_amount': Number(va.gst_amount),
                'e_mop': va.mop, 'invoice_to': va.invoice_to, 'amount_paid_by': va.amount_paid_by, 'vendor_name': "", 'invoice_buyer_name': va.invoice_buyer_name
              };
              dispatch(userActions.updateBilling(billlingup1));
              this.setState({ HotelEpaidAmt: hoepaidamout });
            }
          });
          if (!this.state.getid) {
            dispatch(userActions.createBilling(billdata)).then(() => {
              var getbillstate = this.state.billing;
              let serviceState_tempArr = this.state.services_temp_arr;
              var arr = { 'id': this.props.billing.document.data.id, 'service_id': 6, 'e_paid_amount': hoepaidamout, 'ticket_id': this.state.e_ticket_id, 'customer_id': customerId, 'price': 0, 'service': 0, 'service_tax': 0, 'paid': 0, 'bal': 0, 'others': 0, 'gst_amount': 0 };
              serviceState_tempArr.push(arr);
              if (this.state && this.state.billing && isArray(this.state.billing)) {
                getbillstate.push(arr);
              }
              this.setState({ billing: getbillstate, HotelEpaidAmt: hoepaidamout, services_temp_arr: serviceState_tempArr });
            });
          }
          if (elem)
            removeLoadingButton(elem, "Save");
          this.notify("tc", "", "Hotel has been created successfully");
        });
      }
    }
  }

  tourStage3Save(elemClass) {
    let elem = document.querySelector(`${elemClass}`);
    const { dispatch, ticket } = this.props;
    const { data } = ticket;
    let customerId = (isArray(data.data)) ? data.data[0].customer_id : data.data.customer_id;

    if (elem)
      loadingButton(elem);
    let rows = document.querySelectorAll(`.foodRow_st`);
    let rowSave = true;
    let rowDataInsert = {
      "ticket_id": this.state.e_ticket_id,
      "customer_id": customerId,
      "tour_id": this.state.tour_id_init,
      "category": (this.state.food_places_init_chk) ? 'food' : 'vendor',
      "food_expenses": {}
    };
    rows.forEach((v, i) => {
      let tempStartDate = document.querySelector(`.start_date_food_${i}`);
      let tempEndDate = document.querySelector(`.end_date_food_${i}`);
      let tempPlace = document.querySelector(`.places_food_${i}`);
      let tempVendor = document.querySelector(`.vendor_food_${i}`);
      let tempAmount = document.querySelector(`.amount_paid_${i}`);
      let foodRows = document.querySelectorAll('.foodRow_st');
      let rowsLen = document.querySelectorAll(`.foodRow_st`).length;
      let isLastRow = rowsLen === i + 1 && document.querySelector(`.end_date_food_${rowsLen - 2}`).value === this.state.endDate_places_init;

      removeInvalid(tempStartDate);
      removeInvalid(tempEndDate);
      removeInvalid(tempPlace);
      removeInvalid(tempVendor);
      removeInvalid(tempAmount);
      if (tempStartDate.value != '' && tempEndDate.value != '' && tempPlace.children[1].value != '' && tempVendor.children[1].value != '' && tempAmount.value != '') {
        rowDataInsert.food_expenses[i] = {
          "start_date": tempStartDate.value,
          "end_date": tempEndDate.value,
          "place_id": tempPlace.children[1].value,
          "vendor_id": tempVendor.children[1].value,
          "amount": tempAmount.value
        };
      } else {
        if (!isLastRow) {
          if (elem)
            removeLoadingButton(elem, "Save");
          rowSave = false;
        }
        if (!isLastRow && tempStartDate.value === "")
          addInvalid(tempStartDate);
        if (!isLastRow && tempEndDate.value === "")
          addInvalid(tempEndDate);
        if (!isLastRow && tempPlace.children[1].value === "")
          addInvalid(tempPlace);
        if (!isLastRow && tempVendor.children[1].value === "")
          addInvalid(tempVendor);
        if (!isLastRow && tempAmount.value === "")
          addInvalid(tempAmount);
      }
    });

    if (rowSave) {
      const { tourExpensesInsert } = this.props;
      const { expenses_data } = this.state;
      if ((tourExpensesInsert && tourExpensesInsert.tourExpenses && tourExpensesInsert.tourExpenses.food && tourExpensesInsert.tourExpenses.food.length > 0) || (expenses_data && Object.keys(expenses_data).length > 0)) {
        dispatch(userActions.tourExpensesUpdate(rowDataInsert)).then(() => {
          const { tourExpensesUpdate } = this.props;
          const { data } = tourExpensesUpdate;
          const { getup } = data;

          getup && getup.forEach((v, i) => {
            let tempInput = document.querySelectorAll('.foodRow_st')[i].children[0].children[0];
            tempInput.value = v.id;
            expenses_data['id'] = v.id;
          });
          this.setState({ expenses_data: expenses_data });
          this.saveServicesStatus().then(() => {
            if (elem)
              removeLoadingButton(elem, "Save");
            this.notify("tc", "", "Expenses has been updated successfully");
          });
        });
      } else {
        dispatch(userActions.tourExpensesInsert(rowDataInsert)).then(() => {
          const { tourExpensesInsert } = this.props;
          this.saveServicesStatus().then(() => {
            if (elem)
              removeLoadingButton(elem, "Save");
            this.notify("tc", "", "Expenses has been created successfully");
          });
        });
      }
    }

  }

  validData = (current, name) => {
    if (name === 'date_of_expiry') {
      return this.validFuture(current);
    } else if (name === 'date_of_issue') {
      return this.validDob(current);
    }
  }

  toggleCommentArea = e => {
    let target = e.currentTarget;
    let mainItem = e.currentTarget.previousSibling;
    let sideItem = e.currentTarget.nextSibling;
    if (mainItem.className.indexOf('col-lg-12') === -1) {
      target.childNodes[0].classList.remove('fa-chevron-right');
      target.childNodes[0].classList.add('fa-chevron-left');
      sideItem.classList.add('w-0');
      mainItem.classList.remove('col-lg-8');
      mainItem.classList.add('col-lg-12');
    } else {
      target.childNodes[0].classList.remove('fa-chevron-left');
      target.childNodes[0].classList.add('fa-chevron-right');
      sideItem.classList.remove('w-0');
      mainItem.classList.remove('col-lg-12');
      mainItem.classList.add('col-lg-8');
    }
  }
  ontogglehandlechange(e, key, name, selector) {
    let newstate = this.state.serviceState;
    newstate[`${key}_${name}`] = e.target.checked;
    newstate[`${key}_${name}Value`] = name;
    newstate[`${key}_${name}State`] = 'is-valid';
    this.setState({ serviceState: newstate });
  }

  setStaterModalFull(stateName, data) {
    let states = this.state;
    states[`${stateName}`] = data;
    this.setState({ states });
    if (stateName === 'group_data') {
      let grpOverAllTotal = 0;
      let grpOverAllTotalInd = 0;
      data && Object.keys(data).forEach(key => {
        grpOverAllTotal = grpOverAllTotal + parseFloat(data[key].grpTotal);
        grpOverAllTotalInd = grpOverAllTotalInd + this.parserFloat(data[key].grpTotalInd);
      });
      this.setState({ grpOverAllTotal: grpOverAllTotal, grpOverAllTotalInd: grpOverAllTotalInd });
    }
  }


  slideInputLeft(e, reactionClass, triggerClass) {
    e.preventDefault();
    let reactionElem = document.querySelector(reactionClass);
    let triggerElem = document.querySelector(triggerClass);
    if (triggerElem) {
      triggerElem.classList.toggle('d-flex');
      triggerElem.classList.toggle('d-none');
    } if (reactionElem) {
      reactionElem.classList.toggle('inputSlideLeft');
      reactionElem.classList.toggle('d-none');
    }
  }

  setStatesModalFull(value) {
    const { active, address, city, dob, email, firstname, id, lastname, passport, phone, secondary, state, visa, zipcode } = value;
    const customerId = id;
    let { ticketCreation, e_ticket_id, serviceCustomer, serviceState } = this.state;
    if (passport) {
      const { id, nationality, passport_no, passport_purpose, place_of_birth, place_of_issue, date_of_issue, date_of_expiry, status } = passport;
      const passportId = id;
      let statusId = '';
      let statusName = '';
      let passportPurposeId = '';
      let passportPurposeName = '';
      if (passport_purpose) {
        const { id, name } = passport_purpose;
        passportPurposeId = id;
        passportPurposeName = name;
      }
      if (status) {
        const { id, name } = status;
        statusId = id;
        statusName = name;
      }

      serviceCustomer = (serviceCustomer) ? serviceCustomer : {};
      serviceCustomer['passport'] = (serviceCustomer['passport']) ? serviceCustomer['passport'] : {};
      serviceCustomer['passport']['data'] = (serviceCustomer['passport']['data']) ? serviceCustomer['passport']['data'] : {};
      serviceCustomer['passport']['data'][0] = (serviceCustomer['passport']['data'][0]) ? serviceCustomer['passport']['data'][0] : {};
      serviceCustomer['passport']['data'][0]['passport'] = (serviceCustomer['passport']['data'][0]['passport']) ? serviceCustomer['passport']['data'][0]['passport'] : {};

      serviceCustomer['passport']['data'][0]['passport'][`id`] = passportId;
      serviceCustomer['passport']['data'][0]['passport'][`nationality`] = nationality;
      serviceCustomer['passport']['data'][0]['passport'][`passport_no`] = passport_no;
      serviceCustomer['passport']['data'][0]['passport'][`place_of_birth`] = place_of_birth;
      serviceCustomer['passport']['data'][0]['passport'][`date_of_expiry`] = date_of_expiry;
      serviceCustomer['passport']['data'][0]['passport'][`date_of_issue`] = date_of_issue;
      serviceCustomer['passport']['data'][0]['passport'][`place_of_issue`] = place_of_issue;
      serviceCustomer['passport']['data'][0]['passport'][`status`] = (status) ? { value: statusId, label: statusName } : '';
      serviceCustomer['passport']['data'][0]['passport'][`passport_purpose`] = (passport_purpose) ? { value: passportPurposeId, label: passportPurposeName } : '';


      serviceState = (serviceState) ? serviceState : {};
      serviceState[`${'passport'}_${`nationalityState`}`] = nationality ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`nationality`}`] = nationality;
      serviceState[`${'passport'}_${`statusState`}`] = (status) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`status`}`] = (status) ? statusId : '';
      serviceState[`${'passport'}_${`passport_noState`}`] = (passport_no) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`passport_no`}`] = passport_no;
      serviceState[`${'passport'}_${`place_of_birthState`}`] = (place_of_birth) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`place_of_birth`}`] = place_of_birth;
      serviceState[`${'passport'}_${`date_of_expiryState`}`] = (date_of_expiry) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`date_of_expiry`}`] = date_of_expiry;
      serviceState[`${'passport'}_${`date_of_issueState`}`] = (date_of_issue) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`date_of_issue`}`] = date_of_issue;
      serviceState[`${'passport'}_${`place_of_issueState`}`] = (place_of_issue) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`place_of_issue`}`] = place_of_issue;
      serviceState[`${'passport'}_${`passport_purposeState`}`] = (passport_purpose) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`passport_purpose`}`] = (passport_purpose) ? passportPurposeId : '';
    }

    // if(visa){
    //   const { country_destination, courier_number, email_id, entry, id, no_of_days, status, visa_type, filed_by, vendor_id, vendor } = visa;
    //   const visaId = id;
    //   let statusId = '';
    //   let statusName = '';
    //   let visaTypeId = '';
    //   let visaTypeName = '';
    //   let vendorId = '';
    //   let vendorName = '';
    //   if(visa_type){
    //     const { id, name } = visa_type;
    //     visaTypeId = id;
    //     visaTypeName = name;
    //   }
    //   if(status){
    //     const { id, name } = status;
    //     statusId = id;
    //     statusName = name;
    //   }
    //   if(vendor){
    //     const { id, name } = vendor;
    //     vendorId = id;
    //     vendorName = name;
    //   }


    //   serviceCustomer = (serviceCustomer) ? serviceCustomer : {};
    //   serviceCustomer['visa'] = (serviceCustomer['visa']) ? serviceCustomer['visa'] : {};
    //   serviceCustomer['visa']['data'] = (serviceCustomer['visa']['data']) ? serviceCustomer['visa']['data'] : {};
    //   serviceCustomer['visa']['data'][0] = (serviceCustomer['visa']['data'][0]) ? serviceCustomer['visa']['data'][0] : {};
    //   serviceCustomer['visa']['data'][0]['visa'] = (serviceCustomer['visa']['data'][0]['visa']) ? serviceCustomer['visa']['data'][0]['visa'] : {};
    //   serviceCustomer['visa']['data'][0]['visa'][`id`] = visaId;
    //   serviceCustomer['visa']['data'][0]['visa'][`country_destination`] = country_destination;
    //   serviceCustomer['visa']['data'][0]['visa'][`courier_number`] = courier_number;
    //   serviceCustomer['visa']['data'][0]['visa'][`email_id`] = email_id;
    //   serviceCustomer['visa']['data'][0]['visa'][`entry`] = entry;
    //   serviceCustomer['visa']['data'][0]['visa'][`no_of_days`] = no_of_days;
    //   serviceCustomer['visa']['data'][0]['visa'][`status`] = (status) ? { value: statusId, label: statusName } : '';
    //   serviceCustomer['visa']['data'][0]['visa'][`visa_type_id`] = (visa_type) ? { value: visaTypeId, label: visaTypeName } : '';
    //   serviceCustomer['visa']['data'][0]['visa'][`filed_by`] = (filed_by === "e-route") ? { value: "e-route", label: "E-route" } : { value: "vendor", label: "Vendor" };
    //   serviceCustomer['visa']['data'][0]['visa'][`vendor_id`] = (filed_by === "e-route") ? '' : { value: vendorId, label: vendorName }

    //   serviceState = (serviceState) ? serviceState : {};
    //   serviceState[`${'visa'}_${`country_destinationState`}`] = country_destination ? 'valid' : 'invalid';
    //   serviceState[`${'visa'}_${`country_destination`}`] = country_destination;
    //   serviceState[`${'visa'}_${`statusState`}`] = (status) ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`status`}`] = (status) ? statusId : '';
    //   serviceState[`${'visa'}_${`courier_numberState`}`] = (courier_number) ? 'valid' : 'invalid';
    //   serviceState[`${'visa'}_${`courier_number`}`] = courier_number;
    //   serviceState[`${'visa'}_${`email_idState`}`] = (email_id) ? 'valid' : 'invalid';
    //   serviceState[`${'visa'}_${`email_id`}`] = email_id;
    //   serviceState[`${'visa'}_${`visa_type_idState`}`] = (visa_type) ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`visa_type_id`}`] = (visa_type) ? visaTypeId : '';
    //   serviceState[`${'visa'}_${`filed_byState`}`] = (filed_by) ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`filed_by`}`] = filed_by;
    //   serviceState[`${'visa'}_${`vendor_idState`}`] = (vendor) ? 'is-valid' : (filed_by === "e-route") ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`vendor_id`}`] = (vendor) ? vendorId : '';

    //   serviceState[`${'visa'}_${`entryState`}`] = (entry) ? 'valid' : 'valid';
    //   serviceState[`${'visa'}_${`entry`}`] = entry;
    //   serviceState[`${'visa'}_${`no_of_daysState`}`] = (no_of_days) ? 'valid' : 'valid';
    //   serviceState[`${'visa'}_${`no_of_days`}`] = no_of_days;
    // }

    _ticketCreate.forEach((v, i) => {
      if (value && value[v.name]) {
        let val = value[v.name].toString().trim();
        ticketCreation[v.name] = val;
        ticketCreation[`${v.state}`] = v.valid;

        if (v.isReq && val === "") {
          ticketCreation[`${v.name}Feedback`] = v.invalidFeedback;
          ticketCreation[`${v.state}`] = v.invalid;
        } else if (v.regex && val !== "") {
          var regex = new RegExp(v.regex);
          if (!regex.test(val)) {
            ticketCreation[`${v.name}Feedback`] = v.regexInvalid;
            ticketCreation[`${v.state}`] = v.invalid;
          }
        }
      } else if ((!ticketCreation[v.name] || ticketCreation[v.name].toString().trim() === "") && v.isReq) {
        ticketCreation[v.name] = "";
        ticketCreation[`${v.state}`] = v.invalid;
        ticketCreation[`${v.name}Feedback`] = v.invalidFeedback;
      } else {
        ticketCreation[v.name] = (ticketCreation[v.name]) ? ticketCreation[v.name] : "";
        ticketCreation[`${v.state}`] = (ticketCreation[`${v.state}`]) ? ticketCreation[`${v.state}`] : "";
      }
    });
    if (!e_ticket_id)
      ticketCreation[`status`] = { value: 1, label: 'Open' };
    this.setState({ ticketCreation: ticketCreation, e_cust_id: customerId, serviceCustomer: serviceCustomer, serviceState: serviceState });
  }

  addNewPlace(_options, _stateName, _value, _stateValue) {
    if (_value && _value.__isNew__) {
      const { dispatch } = this.props;
      dispatch(placesActions.placesCreate({ name: _value.label })).then(() => {
        const { placesCreate } = this.props;
        const { place } = placesCreate;
        const { data } = place;
        const { id, name } = data;
        if (id && name) {
          _options.push({ value: id, label: name });
          this.setState({ [`${_stateName}`]: _options, [`${_stateValue}`]: { value: id, label: name } });
        }
      });
    }
  }

  selectVehiclePackage = (_event, _value) => {
    _event.preventDefault();
    const { _vehiclePackage, _vehicleList, toggle_vehicle_facility, select_vehicle, akmCharge, aCarCharge, aDriverCharge } = this.state;
    let _data = toggle_vehicle_facility && _vehicleList[select_vehicle['value']].vehicle_ac_info;
    if (!toggle_vehicle_facility)
      _data = !toggle_vehicle_facility && _vehicleList[select_vehicle['value']].vehicle_non_ac_info;

    let _dataFin = { 'price': _data.crent_fixed, 'name': 'Fixed', 'crent': _data.crent_fixed, 'drent': _data.drent_fixed, 'perkm': _data.perkm_fixed, 'hours': _data.hours_fixed, 'km': _data.km_fixed, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };

    if (_value && _value === 1) {
      _dataFin = { 'price': _data.crent_hourly, 'time': _data.hours_hourly, 'name': 'Hourly', 'crent': _data.crent_hourly, 'drent': _data.drent_hourly, 'perkm': _data.perkm_hourly, 'hours': _data.hours_hourly, 'km': _data.km_hourly, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };
    } else if (_value && _value === 2) {
      _dataFin = { 'price': (_data.crent_daily + _data.drent_daily + _data.perkm_daily * 350), 'name': 'Daily', 'crent': _data.crent_daily, 'drent': _data.drent_daily, 'perkm': _data.perkm_daily, 'hours': _data.hours_daily, 'km': _data.km_daily, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };
    } else if (_value && _value === 3) {
      _dataFin = { 'price': (_data.crent_kilometer + _data.drent_kilometer + _data.perkm_kilometer * 350), 'name': 'Kilometer', 'crent': _data.crent_kilometer, 'drent': _data.drent_kilometer, 'perkm': _data.perkm_kilometer, 'hours': _data.hours_kilometer, 'km': _data.km_kilometer, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };
    }

    this.setState({ _vehiclePackage: (_vehiclePackage === _value) ? '' : _value, _dataPack: _vehiclePackage !== _value ? _dataFin : '', aDriverCharge: _vehiclePackage !== _value ? _dataFin.drent : '', oDriverCharge: _vehiclePackage !== _value ? _dataFin.drent : '', aCarCharge: _vehiclePackage !== _value ? _dataFin.crent : '', oCarCharge: _vehiclePackage !== _value ? _dataFin.crent : '', akmCharge: _vehiclePackage !== _value ? _dataFin.perkm : '', okmCharge: _vehiclePackage !== _value ? _dataFin.perkm : '', _dataFin: _dataFin });
    this.setVehiclePrices();

    // kmChargeText1: '', kmChargeText2: '', kmChargeValue: 0, cChargeText1: '', cChargeText2: '', cChargeValue: 0, dChargeText1: '', dChargeText2: '', dChargeValue: 0
  }

  ontoggleshandlechange = (e, selector) => {
    this.setState({ [`${selector}`]: e.target.checked });
    this.setVehiclePrices();
  }

  getDifference(_start, _end, _i) {
    let __end = moment(_end, 'YYYY-MM-DD h:mm a');
    let __start = moment(_start, 'YYYY-MM-DD h:mm a');
    let hours = __start.diff(__end, 'hours');
    this.setState({ vTiming: (hours) ? hours : 0 });
  }

  validateActual = (_stateName, _value, _regex, _stateInvalid) => {
    let statusState = 'is-valid';
    if (_value && !_regex.test(_value))
      statusState = 'is-invalid';
    else if (!_value)
      statusState = 'is-invalid';
    this.setState({ [`${_stateName}`]: _value, [`${_stateInvalid}`]: statusState });
  }

  render() {
    const { ticketDetails, _ticketCreateState, loading, doc_type, services_temp, services_temp_arr, specialState, grpOverAllTotal, grpOverAllTotalInd, tourPlaceslistOption, statusOptions, vehiclestages, toggle_vehicle_facility, vehicleStart, vehicleEnd, _dataPack, sOdometer, eOdometer, vTiming, kmChargeOrig, akmChargeFocus, akmChargeState, akmCharge, kmChargeText2, kmChargeValue, cChargeOrig, aCarChargeFocus, aCarChargeState, aCarCharge, cChargeText2, cChargeValue, dChargeOrig, aDriverChargeFocus, aDriverChargeState, aDriverCharge, dChargeText2, dChargeValue, oDiscountFocus, oDiscountState, oDiscount, oTotal, select_vehicle, _vehicleList, _vehiclePackage, vehicleOptions, vStage4, ticket_status_temp } = this.state;

    const { authentication, tourTypeslist, ticket } = this.props;

    // if(loading){
    //   return (<i className="fas fa-circle-notch fa-spin display-3 sticky-top float-left" style={{ margin: '0.5rem 1rem', marginTop: -70 }}></i>);
    // }

    const services_ticket = services_temp_arr;//ticketDetails && ticketDetails.services_ticket;
    const servicesLinks = [];
    const serviceStatusLinks = [];
    const servicesPanes = [];
    const savePanes = [];
    const doc_type_options = [];
    const doc_type_list = [];

    const { Option } = components;
    const IconOption = (props) => {
      let iconimg = '';
      let bagde = '';
      let imgSrc = require("assets/img/theme/team-1.jpg");
      if (props.data.badge) {
        iconimg = (<div className="col-2 p-0"><img src={imgSrc} className="avatar avatar-xs rounded" /> </div>);
        bagde = (<div className="col-2 p-0 w-100 d-flex text-right align-items-center"><span className="badge badge-dark badge-pill text-white">{props.data.badge}</span></div>);
      }
      return (
        <Option {...props} key={`${props.data.label}_${props.data.id}`} selected={`${props.data.selected}`} className="d-flex align-items-center">
          <div className="row col p-0 m-0">
            {iconimg}
            <div className="col px-0 mx-1 d-flex align-items-center">{props.data.label}</div>
            {bagde}
          </div>
        </Option>
      );
    }

    doc_type && doc_type.forEach((v, i) => {
      let imgWrapClass = 'd-none';
      let imgSrc = '';
      let imgAlt = '';
      if (v.files) {
        imgSrc = "...";
        imgAlt = "...";
        if (v.files.type.indexOf('image') !== -1) {
          imgSrc = URL.createObjectURL(v.files);
        }
        imgWrapClass = 'd-flex';
      } else if (v.doc_name) {
        imgSrc = "...";
        imgAlt = "...";
        if (isImage(v.doc_name)) {
          imgSrc = `${globalConstants.DOC_URL}${v.doc_name}`;
          imgWrapClass = 'd-flex';
        }
      }

      doc_type_list.push(
        <div className="row mt-4 mx-5 py-2 px-3 border rounded" key={i}>
          <div className="col-3 pl-0 d-flex align-items-center">
            <label className="form-control-label mb-0" htmlFor={`${v.value.replace(/ /g, '_')}_${i}`}>{v.value}</label>
          </div>
          <div className="col-3 d-none">
            <input type="file" className={`${v.value.replace(/ /g, '_')}_file_${i}`} onChange={(e) => { this.loadImage(e, v.value.replace(/ /g, '_'), i) }} name={`${v.value.replace(/ /g, '_')}_file_${i}`} />
          </div>
          <div className={`col d-flex align-items-center ${imgWrapClass} ${v.value.replace(/ /g, '_')}_preview_${i}`}>
            <div className="avatar avatar-sm mr-2">
              {(imgSrc === "...") ? (<div className={`${v.value.replace(/ /g, '_')}_img_${i} h-100 avatar-img rounded`}>{imgSrc}</div>) : (<img className={`${v.value.replace(/ /g, '_')}_img_${i} h-100 avatar-img rounded`} src={`${imgSrc}`} alt={`${imgAlt}`} />)}
            </div>
            <Button className="border-0 h-100" type="button" size="sm" onClick={(e) => this.openBrowseButton(e, v.value.replace(/ /g, '_'), i)} color="primary" name={`${v.value.replace(/ /g, '_')}_browse_${i}`}>
              <span className="btn-inner--icon">
                <i className="ni ni-cloud-upload-96" />
              </span>
              <span className="btn-inner--text"> Browse</span>
            </Button>
            <Button className="py-1 border-0 h-100" size="sm" type="button" color="danger" onClick={(e) => { this.deleteAttachment(e, v.value.replace(/ /g, '_'), i) }} name={`${v.value.replace(/ /g, '_')}_delete_${i}`}>
              <span className="btn-inner--icon">
                <i className="fas fa-trash"></i>
              </span>
            </Button>
          </div>
        </div>
      );
    });

    const { documenttype } = this.props;
    const billingAccordians = [];
    let billingFullAccordians = false;
    if (documenttype && documenttype.documenttype && documenttype.documenttype.data) {
      documenttype.documenttype.data.map((v, i) => {
        doc_type_options[i] = { value: v.id, label: v.document_type };
      });
    }
    var hasTourService = 0;
    let hasVehicleService = 0;
    services_ticket && isArray(services_ticket) && services_ticket.forEach((v, i) => {
      if (v.service_id == 1) { hasTourService = 1; }
      if (v.service_id == 9) { hasVehicleService = 1; }
    });



    let serviceLnks = [];
    let keyi = 0;
    let yrki = 0;
    const { serviceFlds, serviceCustomer, serviceState } = this.state;

    let servicesT = [];
    if (this.state.ticketCreation && this.state.ticketCreation.service) {
      servicesT = this.state.ticketCreation.service.filter((serv) => {
        return serv.label && serv.label.trim().toLowerCase() === "tour";
      });
    }
    let tourTypeslistOption = [];
    tourTypeslist && tourTypeslist.data && tourTypeslist.data.data && tourTypeslist.data.data.length > 0 && tourTypeslist.data.data.forEach((v, i) => {
      tourTypeslistOption.push({ label: v.name, value: v.id });
    });

    let food_places_init_inp = document.querySelector('.food_places_init');
    let tourTypeOptions = tourTypeslistOption;
    if (food_places_init_inp && food_places_init_inp.checked) {
      tourTypeOptions = tourTypeOptions.filter(v => { return v.value != 2 });
    }
    let { food_places_init } = (this.state.food_places_init) ? this.state : { food_places_init: tourTypeOptions };

    let tourRowstemp = [];
    let foodRowstemp = [];
    let hotelRowstemp = [];
    let unit = "places_init";
    const { tourCountlist } = this.props;
    for (let tempu = 0; tempu < this.state.tourCount; tempu++) {
      tourRowstemp.push(<TourRow notify={(pos, title, text) => this.notify(pos, title, text)} tourCountlist={this.props.tourCountlist} tourCountCreate={this.props.tourCountCreate} oStartData={this.state.startDate_places_init} oEndData={this.state.endDate_places_init} setStater={(stateName, data) => { this.setState({ [`${stateName}`]: data }); }} jsUcfirst={(text) => this.jsUcfirst(text)} key={tempu} data={tourCountlist && tourCountlist.data && tourCountlist.data.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].tour_places && tourCountlist.data.data[0].tour_places[tempu] || {}} unit={tempu} renderDateInput={() => this.renderDateInput} optiondata={tourPlaceslistOption} isLast={tempu === this.state.tourCount - 1} isLastClick={() => this.setState({ tourCount: this.state.tourCount + 1 })} />);
    }
    let userRole = (authentication && authentication.user && authentication.user.user && authentication.user.user.userrole && authentication.user.user.userrole.role && authentication.user.user.userrole.role.name);
    let approveDisabled = (userRole === 'Manager') ? false : true;

    return (
      <>
        <NotificationAlert style={{ zIndex: '9999999999' }} ref="notificationAlert" />
        {/* {(loading) ? (
          <i className="fas fa-circle-notch fa-spin display-3 sticky-top float-left" style={{ margin: '0.5rem 1rem', marginTop: -70 }}></i>
        ) : ''} */}

        <SimpleHeader name="Edit Ticket" parentName="Tickets" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col-lg-8 px-1">
              <div className={classnames({ "col-md-12": true, "px-0": true })}>
                <div className="card-wrapper">
                  <Card className="mb-2">
                    <CardBody>
                      {/* <span className="search-wrapper d-flex justify-content-center align-items-center mb-3">
                        <span className="search-icon align-items-center d-flex">
                          <p className="mb-0 mr-1">Search Customers</p>
                          <img src={require('../../assets/img/icons/search.png')} height={22} width={22} className="cursor-pointer" onClick={(e) => { this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon'); }} />
                        </span>
                        <span className="search-input-wrapper w-75 d-none align-items-center">
                          <Autocomplete setStatesModalFull={(obj) => this.setStatesModalFull(obj)} additionalClasses="d-inline" suggestions={['One', 'Two', 'Three']} />
                          <span className="input-clear-icon cursor-pointer ml--4" onClick={(e) => this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon')}>&times;</span>
                        </span>
                      </span> */}
                      <Form className="needs-validation" noValidate onSubmit={this.validateTicketCreationForm}>
                        <div className="form-row mc-minimize" ref="ticket" onClick={(e) => this.doAction(e, "", "hide")}>
                          {this.state._ticketCreateState.map((v, i) => {
                            let rowLimit = 5;
                            if (i < rowLimit) {
                              let last_item_size = v.bs4Size;
                              let dots = '';
                              if ((i + 1) == rowLimit && !this.state.collapse) {
                                dots = (<span>
                                  <label className="form-control-label invisible">More</label>
                                  <Button className="toggle d-block" color="link" size="sm" onClick={(e) => { e.preventDefault(); this.setState({ 'collapse': true }); }} > ... </Button></span>);
                              }
                              return (
                                <Col key={i} className="mb-2 d-flex" md={last_item_size}>
                                  <span className="w-100">
                                    <label className="form-control-label" htmlFor={v.name} > {v.label}</label>

                                    {(v.type == "input") ?
                                      (<><Input bsSize="sm" id={v.name} placeholder={v.label} type="text" valid={this.state.ticketCreation[v.state] === v.valid} invalid={this.state.ticketCreation[v.state] === v.invalid} value={`${this.state.ticketCreation[v.name]}`} onChange={e => this.ticketCreationForm(e, v.name, v.isReq, v.regex, v.regexInvalid)} /></>) : ''}

                                    {/* {(v.type == "select" && v.multiple === true) ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])} select_${v.name}`} isClearable placeholder={v.placeholder} components={{ Option: IconOption }} isSearchable isMulti={v.multiple} name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} /></>) : ''} */}

                                    {(v.type == "select" && v.multiple === false && v.name == 'status') ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])} select_${v.name}`} isClearable placeholder={v.placeholder} components={{ Option: IconOption }} isSearchable name={v.name} options={v.option} defaultValue={ticket_status_temp} value={ticket_status_temp} onChange={(value) => { this.onSelectChange(value, v.name); }} /></>) : ''}
                                    {(v.type == "select" && v.multiple === false && v.name == 'assignedTo') ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])} select_${v.name}`} isClearable placeholder={v.placeholder} components={{ Option: IconOption }} isSearchable name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name); }} /></>) : ''}

                                    {(v.type == "date") ? <ReactDatetime isValidDate={this.validDob} closeOnSelect={true} className={`form-control form-control-sm p-0 h-auto ${(this.state.ticketCreation[v.state] === 'valid') ? 'is-valid' : ((this.state.ticketCreation[v.state] === 'invalid')) ? 'is-invalid' : ''}`} inputProps={{ placeholder: v.placeholder, className: `form-control form-control-sm border-0`, value: `${`${this.state.ticketCreation[v.name]}`}`, readOnly: true }} timeFormat={false} onChange={value => this.onDateChange(value, v.name)} /> : ''}

                                    {(v.type == "button") ? <div className="form-control form-control-sm p-0 h-auto border-0"><Button className={`form-control form-control-sm border-0`} color="success" type="button" onClick={(e) => { this.doAction(e, v.label, "show") }}>{v.placeholder}</Button></div> : ''}

                                    <div className="valid-feedback"></div>
                                    <div className="invalid-feedback">
                                      {(this.state.ticketCreation[`${v.name}Feedback`]) ? this.state.ticketCreation[`${v.name}Feedback`] : v.invalidFeedback}
                                    </div>
                                  </span>
                                  {dots}
                                </Col>
                              );
                            }
                          })}

                          <Collapse isOpen={this.state.collapse}>
                            <div className="form-row px-1">
                              {this.state._ticketCreateState.map((v, i) => {
                                var rowLimit = (this.state.collapse) ? 5 : 5;
                                if (i >= rowLimit) {
                                  return (
                                    <Col key={i} className="mb-2" md={v.bs4Size}>
                                      <label className="form-control-label" htmlFor={v.name} > {v.label} </label>

                                      {(v.type == "input") ? (<><Input bsSize="sm" id={v.name} placeholder={v.label} type="text" valid={this.state.ticketCreation[v.state] === v.valid} invalid={this.state.ticketCreation[v.state] === v.invalid} value={`${this.state.ticketCreation[v.name]}`} onChange={e => this.ticketCreationForm(e, v.name, v.isReq, v.regex, v.regexInvalid)} /></>) : ''}

                                      {/* {(v.type == "select" && v.multiple === true) ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])} select_${v.name}`} isClearable placeholder={v.placeholder} components={{ Option: IconOption }} isSearchable isMulti={v.multiple} name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} /></>) : ''} */}

                                      {(v.type == "select" && v.multiple === false && v.name == 'status') ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])} select_${v.name}`} isClearable placeholder={v.placeholder} components={{ Option: IconOption }} isSearchable name={v.name} options={v.option} defaultValue={ticket_status_temp} value={ticket_status_temp} onChange={(value) => { this.onSelectChange(value, v.name); }} /></>) : ''}
                                      {(v.type == "select" && v.multiple === false && v.name == 'assignedTo') ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])} select_${v.name}`} isClearable placeholder={v.placeholder} components={{ Option: IconOption }} isSearchable name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name); }} /></>) : ''}
                                      {(v.type == "date") ? <ReactDatetime isValidDate={this.validDob} closeOnSelect={true} className={`form-control form-control-sm p-0 h-auto ${(this.state.ticketCreation[v.state] === 'valid') ? 'is-valid' : ((this.state.ticketCreation[v.state] === 'invalid')) ? 'is-invalid' : ''}`} inputProps={{ placeholder: v.placeholder, className: `form-control form-control-sm border-0`, readOnly: true }} timeFormat={false} value={`${this.state.ticketCreation[v.name]}`} onChange={value => this.onDateChange(value, v.name)} /> : ''}

                                      {(v.type == "button") ? <div className="form-control form-control-sm p-0 h-auto border-0"><Button className={`form-control form-control-sm border-0`} color="success" type="button" onClick={(e) => { this.doAction(e, v.label, "show") }}>{v.placeholder}</Button></div> : ''}

                                      <div className="valid-feedback"></div>
                                      <div className="invalid-feedback">
                                        {(this.state.ticketCreation[`${v.name}Feedback`]) ? this.state.ticketCreation[`${v.name}Feedback`] : v.invalidFeedback}
                                      </div>
                                    </Col>
                                  );
                                }
                              })}
                            </div>
                          </Collapse>
                        </div>
                        {(this.state.collapse) ? (
                          <Button className="mt-2 toggle" color="link" size="sm" onClick={(e) => { e.preventDefault(); if (this.state.collapse) { this.setState({ 'collapse': false }); } }} > ..less </Button>) : ''}
                        {/* <Button className="mt-2 ml-0" color="primary" type="button" size="sm" onClick={(e) => { this.setState({documentModal: true}); }} > Document </Button>
                      <Button className="mt-2 add-billing-button" color="primary" size="sm" type="button" onClick={(e) => { this.openBilling() }} > Billing </Button> */}
                        <Button className="mt-2 save-button" color="primary" type="button" ref="saveButton" size="sm" onClick={this.validateTicketCreationForm} > Save </Button>
                        <div className="btn-group-toggle d-inline btn-group-approve" data-toggle="buttons">
                          {approveDisabled && hasTourService ? (<Button className={classnames({ 'active': this.state.approved == 1, 'mt-2': true })} color="secondary" size="sm" tag="label" disabled>
                            <input autoComplete="off" type="checkbox" value={this.state.approved == 1} />
                            {this.state.approved == 1 ? (<><i className="fas fa-check"></i> Approved</>) : `Waiting for Approval`}
                          </Button>) : (hasTourService) ? (<Button className={classnames({ 'active': this.state.approved == 1, 'mt-2': true })} color="secondary" size="sm" tag="label">
                            <input autoComplete="off" type="checkbox" value={!this.state.approved == 1} onChange={() => { const { dispatch } = this.props; const { e_ticket_id } = this.state; let ticketData = { id: e_ticket_id, approved: this.state.approved == 1 ? '0' : '1' }; dispatch(userActions.updateTicket(ticketData)); this.setState({ approved: !this.state.approved == 1 }); }} />
                            {this.state.approved == 1 ? (<><i className="fas fa-check"></i> Approved</>) : `Mark as Approved`}
                          </Button>) : ''}
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            <a className="btn btn-primary rounded-circle btn-sm position-absolute text-white toggleCommentArea" onClick={(e) => this.toggleCommentArea(e)}><i className="fas fa-chevron-right"></i></a>
            <div className="col-lg-4 px-1">
              <div className="card-wrapper">
                <CommentSection isValidDate={this.validFuture} ticketDetails={ticketDetails} />
              </div>
            </div>
          </Row>
          <ModalFull tourType={this.state.select_tour_type} isIndBilling={!this.state.pay_type} groupC={this.state.groupC} group_data={this.state.group_data} isOpen={this.state.fullModal} closeModal={() => this.closeFullModal()} grpName={this.state.grpName} memCount={this.state.memCount} currCount={(this.state.currCount) ? this.state.currCount : 1} incrementCurrCount={() => { this.setState({ currCount: (this.state.currCount) ? this.state.currCount + 1 : 2 }); }} decrementCurrCount={() => { this.setState({ currCount: (this.state.currCount) ? this.state.currCount - 1 : 1 }); }} IconOption={IconOption} tourId={this.state.tour_id_init} groupDetails={(this.state.groupDetails) ? this.state.groupDetails : {}} groupConcatDetails={(groupDetails) => { this.setState({ groupDetails: groupDetails }); }} grpId={this.state.grpId} ticket_id={this.state.e_ticket_id} tourGroupsList={this.props.tourGroupsList} setCurrCount={(curr) => { this.setState({ currCount: curr }); }} notify={(position, title, message) => this.notify(position, title, message)} billingFullAccordians={billingFullAccordians} handleBillingInputsChange={(i, e, v, u) => this.handleBillingInputsFullChange(i, e, v, u)} handleBillingInputsBlur={(i, e, v, u) => this.handleBillingInputsFullBlur(i, e, v, u)} collapsesToggle={(c) => this.collapsesToggle(c)} openedCollapses={this.state.openedCollapses} billingFull={this.state.billingFull} setStater={(stateName, data) => this.setStaterModalFull(stateName, data)} parserFloat={(c) => this.parserFloat(c)} doc_type_options={doc_type_options} vendorOptions={this.state.vendorinit} visaTypeOptions={this.state.visaTypesInint} validDob={this.validDob} validFuture={this.validFuture} />
        </Container>
      </>
    );
  }
}


function mapStateToProps(state) {
  const { status, assigned, department, service, special, customer, authentication, ticket, comment, documenttype, serviceFields, customerservice, vendor, tourCountCreate, tourCountUpdate, tourPlacesCreate, tourPlacesUpdate, tourCountlist, tourCreate, tourTypeslist, tourPlaceslist, tourUpdate, createGroupCount, updateGroupCount, tourGroupsList, tourExpensesInsert, tourExpensesUpdate, services, listVisaTypes, hotelExpensesInsert, hotelExpensesUpdate, hotellist, billing, notificationcreate, notificationupdate, placesCreate, insurance, vehicle } = state;
  return { vendor, status, assigned, department, service, special, customer, authentication, ticket, comment, documenttype, serviceFields, customerservice, tourCountCreate, tourCountUpdate, tourPlacesCreate, tourPlacesUpdate, tourTypeslist, tourPlaceslist, tourCountlist, tourCreate, tourUpdate, createGroupCount, updateGroupCount, tourGroupsList, tourExpensesInsert, tourExpensesUpdate, services, listVisaTypes, hotelExpensesInsert, hotelExpensesUpdate, hotellist, billing, notificationcreate, notificationupdate, placesCreate, insurance, vehicle };
}

const connectedEditPage = connect(mapStateToProps)(withRouter(Edit));
export { connectedEditPage as Edit };

// export default CreateTicket;
export const _MaintenanceCreate=[

{
    "name": "date",
    "label": "Date",
    "id": "date",
    "class": "form-control-sm",
    "state": "dateState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The date field is Required",
    "validFeedback": "",
    "bs4Size": "2",
    "type": "date",
    "isReq":true,
   
},
{
    "name": "balance",
    "label": "Balance",
    "id": "balance",
    "class": "form-control-sm",
    "state": "balanceState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The Balance field is Required.",
    "validFeedback": "",
    "bs4Size": "5",
    "type": "input",
    "isReq":true,
    "regexInvalid":"Need Valid Decimal Format.",
    "regex":/^\d{0,12}(\.\d{1,2})?$/
},
{
    "name": "amount_entry",
    "label": "Amount Entry",
    "id": "amount_entry",
    "class": "form-control-sm",
    "state": "amount_entryState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The Amount Entry field is Required.",
    "validFeedback": "",
    "bs4Size": "5",
    "type": "input",
    "isReq":true,
    "regexInvalid":"Need Valid Decimal Format.",
    "regex":/^-?\d{0,12}(\.\d{1,2})?$/
   
}
];

import { globalConfig } from '../_config';
import { globalConstants } from '../_constants';

export function authHeader() {
    const options = {
        url: `/oauth/token`,
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        data: {
            "grant_type": "client_credentials",
            "scope": "*",
            "client_id": globalConstants.CLIENT_ID,
            "client_secret": globalConstants.CLIENT_SECRET
        },
    };

    globalConfig(options).then(handleResponse).then(data => {
        localStorage.setItem('oauth', JSON.stringify(data));
        return { 'Authorization': 'Bearer ' + data.token };
    });
}

function handleResponse(response) {
    if(response.status === 200){
        return response.data;
    }else{
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
}
import { userConstants } from '../_constants';

export function vendor(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_VENDOR_LIST_REQUEST:
      return {
        list: action.data
      };
    case userConstants.GET_VENDOR_LIST_SUCCESS:
      return {
        list: action.data
      };
    case userConstants.GET_VENDOR_LIST_FAILURE:
      return { 
        error: action.error
      };
    case userConstants.LIST_VENDORS_REQUEST:
      return {
        list: action.vendors
      };
    case userConstants.LIST_VENDORS_SUCCESS:
      return {
        list: action.vendors
      };
    case userConstants.LIST_VENDORS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}
import { walletConstants } from '../_constants';
import { cpus } from 'os';

export function wallet(state = {}, action) {
  switch (action.type) {
    case walletConstants.CREATE_WALLET_REQUEST:
      return {
        loading: true
      };
    case walletConstants.CREATE_WALLET_SUCCESS:
      return {
        items: action.wallet
      };
    case walletConstants.CREATE_WALLET_FAILURE:
      return { 
        error: action.error
      };
        case walletConstants.GET_WALLET_REQUEST:
         return{
          wallet:action.datavalue
        };
        case walletConstants.GET_WALLET_SUCCESS:
           return{
            wallet:action.datavalue
          };
          case walletConstants.GET_WALLET_FAILURE:
              return{
                error: action.error
            };
            case walletConstants.DELETE_WALLET:
                return{
                  wallet:action.wallet
              };
              case walletConstants.EDIT_WALLET_REQUEST:
                  return {
                    loading: true
                  };
                case walletConstants.EDIT_WALLET_SUCCESS:
                  return {
                    items: action.wallet
                  };
                case walletConstants.EDIT_WALLET_FAILURE:
                  return { 
                    error: action.error
                  };
    default:
      return state
  }
}

export function list(state = {}, action) {
  switch (action.type) {
  case walletConstants.LIST_WALLET_REQUEST:
   
    return{
     list:action.list
   };
   case walletConstants.LIST_WALLET_SUCCESS:
     
      return{
        
       list:action.list
     };
     case walletConstants.LIST_WALLET_FAILURE:
         return{
           error: action.error
       };
       default:
      return state
}
}
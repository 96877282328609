import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from "moment";
import axios from 'axios';
import styled, { css } from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Pagination,
    Col,
    UncontrolledTooltip,
} from "reactstrap";
import { globalConstants } from "../../_constants";
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
        props.disappear &&
        css`
      display: block; /* show */
      padding-top : 250px;
    `}
`;

export default class Remainder extends React.Component {
    state = {
        remainder_data: [], totalCountRemainder: '', pageValueCount  : '', pageNumber: '1', loading: false
    }

    onPageChange = (page) => {
        console.log(page, 'Multiple arguments_page');

        let pageNumber = page.selected + 1;
        this.setState({
            pageNumber: page.selected + 1
        })
        console.log(this.state.pageNumber, 'page number for remainder')
        this.getAllRemainder(pageNumber);
    };

    RemainderCheck(event, remainderId) {
        let completed_status = ''
        this.setState({ loading: true })

        if (event.target.checked) {
            completed_status = 1
            var element = document.getElementById("chk-todo-task-" + remainderId);
            element.parentNode.parentNode.parentNode.classList.add("checklist-item-checked");
            console.log("the check box is checked", remainderId)
        } else if (!event.target.checked) {
            var element = document.getElementById("chk-todo-task-" + remainderId);
            element.parentNode.parentNode.parentNode.classList.remove("checklist-item-checked");
            this.setState({
                loading: true,
                completed_status: 0
            })
            console.log("the check box is Unchecked", remainderId)
        }
        axios
            .get(globalConstants.BASEURL + `api/Remove_Remainder/?id=${remainderId}&completed_status=${completed_status}`)
            .then((res) => {
                console.log("new the check box is Unchecked", remainderId)

                let tempRemainder = this.state.remainder_data

                tempRemainder.map((data) => {

                    if (data.id == remainderId) {

                        data.completed_status = completed_status
                        console.log('unchecked successfully')
                        console.log(data.completed_status, 'new comeplete status')

                        this.setState({
                            tempRemainder: data.completed_status
                        })
                    }
                })

                this.setState({ loading: false })

            })
    }
    getAllRemainder(pageNumber) {
        axios
            .get(globalConstants.BASEURL + `api/getAllRemainder/?pagecount=${pageNumber}`)
            .then((res) => {
                console.log(res, 'reminder data')
                console.log(res.data.res2, 'reminder all Data')
                console.log(res.data.res1[0].remainder_count / 10, 'Remainder Data Count From the database')
                this.setState({
                  loading: false,
                  remainder_data: res.data.res2,
                  totalCountRemainder: res.data.res1[0].remainder_count / 10,
                  pageValueCount : res.data.res1[0].remainder_count
                });
            })
    }
    componentDidMount() {
        this.setState({
            loading: true
        })
        this.getAllRemainder(this.state.pageNumber);
    }


    render() {
        return (
          <>
            {/* <pre>{JSON.stringify(this.state.remainder_data)}</pre> */}
            <DarkBackground disappear={this.state.loading}>
              <LoadingOverlay active={true} spinner={true}></LoadingOverlay>
            </DarkBackground>
            <div className="m-3 pl-3">
              <Link
                to={'/admin/create/remainder'}
                className="btn btn-dark"
                color="primary"
                type="button"
              >
                Create Remainder
              </Link>
            </div>

            <Card className="col-md-6" style={{ margin: 'auto' }}>
              <CardHeader>
                <h5 className="h3 mb-0">Remainder</h5>
              </CardHeader>
              <CardBody className="p-0">
                <ListGroup data-toggle="checklist" flush>
                  {this.state.remainder_data.map((data) => (
                    <ListGroupItem className="checklist-entry flex-column align-items-start py-4 px-4">
                      <div
                        className={
                          data.completed_status == 1
                            ? 'checklist-item checklist-item-success checklist-item-checked'
                            : 'checklist-item checklist-item-success'
                        }
                      >
                        <div className="checklist-info">
                          <h5
                            className="checklist-title mb-0"
                            style={{ overflowWrap: 'anywhere' }}
                          >
                            {data.remainder_description}
                          </h5>
                          <small>
                            {moment(data.remainder_date).format(
                              'Do MMMM YYYY h:mma'
                            )}
                          </small>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div className="custom-control custom-checkbox custom-checkbox-success">
                            <input
                              value={data.completed_status}
                              type="hidden"
                            />
                            <input
                              className="custom-control-input"
                              id={'chk-todo-task-' + data.id}
                              type="checkbox"
                              onChange={(event) =>
                                this.RemainderCheck(event, data.id)
                              }
                              checked={
                                data.completed_status == 1 ? true : false
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={'chk-todo-task-' + data.id}
                            />
                          </div>
                          <div>
                            <Link
                              to={{
                                pathname: `/admin/remainder-edit/${data.id}`,
                              }}
                            >
                              <i
                                class="fa fa-pen"
                                style={{
                                  backgroundColor: 'rgb(0, 214, 134)',
                                  color: 'white',
                                  padding: 6,
                                  margin: 2,
                                  borderRadius: 2,
                                  fontSize: 12,
                                  cursor: 'pointer',
                                }}
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
            <div
              className="py-5 table-responsive col-md-6"
              style={{ margin: 'auto' }}
            >
              {this.state.remainder_data == 0 ? (
                <>
                  <h2 style={{ textAlign: 'center', marginTop: '85px' }}>
                    No records found
                  </h2>
                </>
              ) : (
                <>
                  {this.state.pageValueCount <= 10 &&
                  this.state.pageNumber <= 1 ? null : (
                    <ReactPaginate
                      previousLabel={'<<'}
                      nextLabel={'>>'}
                      breakLabel={'...'}
                      pageCount={this.state.totalCountRemainder}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={this.onPageChange}
                      containerClassName={'pagination justify-content-end'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      activeClassName={'active'}
                    />
                  )}
                </>
              )}
            </div>
          </>
        );
    }
}
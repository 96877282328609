import React from "react";
// react plugin that prints a given react component
// import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import { globalConstants } from "../../_constants";
import classnames from "classnames";
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import Select from 'rc-select';
import Select2 from "react-select";
import ReactDatetime from "react-datetime";
import { components } from "react-select";
import moment from 'moment';
// react component used to create sweet alerts
// import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import { Button, Media, Card, Table, Container, Row, Badge, PaginationLink, PaginationItem } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import { history } from '../../_helpers';
import { Link } from 'react-router-dom';
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';
import { isObject } from "util";
import { loadingButton, removeLoadingButton } from "../../_functions";

// const { SearchBar } = Search;

class List extends React.Component {
  state = { alert: null, loading: true, paginationList: [], _search: [], _currentPage: 1, _pageCount: 10, _searchText: '', _recordsFiltered: null, _departments: [], listTable: [] };

  onShowSizeChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
    setTimeout(() => { this.listTick(); }, 500);
  }

  onChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
    setTimeout(() => { this.listTick(); }, 500);
  }

  componentWillUnmount() {
    document.querySelector('.navbar-nav .ticket-menu') && document.querySelector('.navbar-nav .ticket-menu').classList.remove('active');
  }

  componentDidMount() {
    document.querySelector('.navbar-nav .ticket-menu') && document.querySelector('.navbar-nav .ticket-menu').classList.add('active');
    const { dispatch, authentication } = this.props;
    let { user } = authentication;
    let { id } = user.user;

    const { _currentPage, _searchText, _pageCount, _search, startDate, endDate, select_dept_v } = this.state;
    new List(this.refs["first-list"], {
      valueNames: ["ticket_id", "cust_name", "cust_email", "assigned_to_name", "poc", "poc_number", "next_folow_up", "status"],
      listClass: "list"
    });
    dispatch(userActions.listTickets(_currentPage, _searchText, _pageCount, id, _search, startDate, endDate)).then(() => {
      dispatch(userActions.getTicketStatus('', _searchText, startDate, endDate, id)).then(() => {
        const { ticket, ticketStatus } = this.props;
        const { status } = ticketStatus && ticketStatus.ticketStatus;
        if (status) {
          this.setState({ _status: status });
        }
        if (ticket && ticket.list && ticket.list.data && ticket.list.data.recordsFiltered) {
          this.setState({ _recordsFiltered: ticket.list.data.recordsFiltered, loading: false });
        } else {
          this.setState({ _recordsFiltered: 0, loading: false });
        }
        this.ticketList();
      });
    });
    dispatch(userActions.listDepartment()).then(() => {
      const { department } = this.props;
      if (department && department.department && department.department.data && department.department.data.length > 0) {
        let departmentD = [];
        department.department.data.forEach(v => { departmentD.push({ value: v.id, label: v.deptname }) });
        this.setState({ _departments: departmentD });
      }
    });
  }

  ticketList = () => {
    const { ticket, authentication } = this.props;
    console.log(ticket, 'ticket')
    const { _currentPage, _pageCount } = this.state;
    // const GetAccountRole = authentication.user.user.getdepart.deptname;
    let { user } = authentication;
    const userrole = user && user.user.role;
    const name = userrole;
    const isAccounts = name.toString().trim().toLowerCase() === "accounts";
    const isAdmin = name.toString().trim().toLowerCase() === "admin";
    let listTable = [];
    let startVal = ((_currentPage * _pageCount) - (_pageCount - 1));
    ticket && ticket.list && ticket.list.data && ticket.list.data.data.forEach((v, i) => {
      console.log(v.customer_details[0])
      listTable.push(
        <tr key={i}>
          <td className="p-1 text-left ticket_id">{startVal + i}</td>
          <td className="p-1 cust_name">{v.customer_details && v.customer_details[0].firstname || "-"}</td>
          <td className="p-1 cust_email">{v.customer_details && v.customer_details[0].email || "-"}</td>
          {/* <td className="p-1 assigned_to_name">
            <Badge color="warning" className="p-1" href="#badge" onClick={(e) => e.preventDefault()} pill>
              <img src={require('../../assets/img/theme/team-4.jpg')} className="avatar avatar-xs rounded-circle mr-1" alt={`${v.assignedtoticket && v.assignedtoticket.name}`} />
              {v.assignedtoticket && v.assignedtoticket.name}
            </Badge>
          </td> */}
          <td className="p-1 poc_number"><small className="d-block">{v.poc || v.customer_details && v.customer_details[0].firstname || "-"}</small>{v.poc_number || v.customer_details && v.customer_details[0].phone || "-"}</td>
          {/* <td className="p-1 dept">{v.department && v.department.deptname || "-"}</td> */}
          {/* <td className="p-1 text-center next_folow_up">{moment(v.next_follow_up)._isValid ? moment(v.next_follow_up).format("YYYY-MM-DD") : "-"}</td> */}
          <td className="p-1 text-right status"><Badge color={`${globalConstants.TICKETS.COLORS[`${v.status && v.status[0].name && v.status[0].name.trim().replace(/ /g, '_').toLowerCase()}`] || "primary"}`} href="#badge" onClick={(e) => e.preventDefault()} className="color-white">{v.status && v.status[0].name && v.status[0].name || "-"}</Badge></td>
          <td className="p-1 text-right">
            {(isAccounts || isAdmin) ? (<Link className="btn btn-sm btn-info mr-1" to={`/admin/tickets/service/${(v.id) ? v.id : v.ticket_id}`} color="success" size="sm"><i class="fas fa-eye"></i></Link>) : ''}
            <Link className="btn btn-sm btn-success mr-1 ml-0" to={`/admin/tickets/edit/${(v.id) ? v.id : v.ticket_id}`} color="success" size="sm"><i className="fas fa-pencil-alt"></i></Link>
            <Button color="danger" className="ml-0 mr-0" size="sm" onClick={(e) => this.deleteTicket(e, v.id)}><i className="fas fa-trash-alt"></i></Button>
          </td>
        </tr>
      );
    });
    this.setState({ listTable: listTable });
  }

  listTick() {
    const { dispatch, authentication } = this.props;
    if (isObject(authentication) && Object.keys(authentication).length === 0)
      return null;
    let { user } = authentication;
    let { id } = user.user;
    const { _currentPage, _searchText, _pageCount, _search, startDate, endDate, select_dept_v } = this.state;
    dispatch(userActions.listTickets(_currentPage, _searchText, _pageCount, id, _search, startDate, endDate)).then(() => {
      dispatch(userActions.getTicketStatus((select_dept_v) ? select_dept_v.value : '', _searchText, startDate, endDate, id)).then(() => {
        const { ticket, ticketStatus } = this.props;
        const { status } = ticketStatus && ticketStatus.ticketStatus;
        if (status) {
          this.setState({ _status: status });
        }
        if (ticket && ticket.list && ticket.list.data && ticket.list.data.recordsFiltered) {
          this.setState({ _recordsFiltered: ticket.list.data.recordsFiltered, loading: false });
        } else {
          this.setState({ _recordsFiltered: 0, loading: false });
        }
        this.ticketList();
      });
    });
  }

  reset() {
    this.setState({ _currentPage: 1, _searchText: '', _search: '', startDate: '', endDate: '', loading: true, select_dept_v: '' });
    setTimeout(() => { this.listTick(); }, 500);
  }

  searchData(data, column) {
    const { dispatch, authentication } = this.props;
    if (isObject(authentication) && Object.keys(authentication).length === 0)
      return null;
    let { user } = authentication;
    let { id } = user.user;

    let { _currentPage, _searchText, _pageCount, _search, startDate, endDate, select_dept_v } = this.state || {};
    let search = _search || [];
    if (data) {
      search[column] = search[column] || [];
      search[column]['column'] = column;
      search[column]['data'] = data;
    } else {
      delete search[column];
    }
    this.setState({ _search: search, loading: true, _currentPage: 1 });
    dispatch(userActions.listTickets(_currentPage, _searchText, _pageCount, id, _search, startDate, endDate)).then(() => {
      dispatch(userActions.getTicketStatus(column !== 7 ? data : (select_dept_v ? select_dept_v.value : ''), _searchText, startDate, endDate, id)).then(() => {
        const { ticket, ticketStatus } = this.props;
        const { status } = ticketStatus && ticketStatus.ticketStatus;
        if (status) {
          this.setState({ _status: status });
        }
        if (ticket && ticket.list && ticket.list.data && ticket.list.data.recordsFiltered) {
          this.setState({ _recordsFiltered: ticket.list.data.recordsFiltered, loading: false });
        } else {
          this.setState({ _recordsFiltered: 0, loading: false });
        }
        this.ticketList();
      });
    });
  }

  searchDataPagination(e) {
    this.setState({ _searchText: e.target.value.trim(), loading: true, _currentPage: 1 });
    setTimeout(() => { this.listTick(); }, 500);
  }

  renderDateInput(props, openCalendar, closeCalendar) {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text py-0 px-2"><i className="far fa-calendar"></i></span>
        </div>
        <input {...props} />
      </div>
    );
  }

  validEndData = (current) => {
    const { startDate } = this.state;
    if (!startDate)
      return true;
    var today = moment(startDate);
    return current.isAfter(today);
  }

  validStartData = (current) => {
    const { endDate } = this.state;
    if (!endDate)
      return true;
    var today = moment(endDate);
    return current.isBefore(today);
  }

  deleteTicket = (e, _ticketId) => {
    let ticketData = { id: _ticketId };
    let elem = e.currentTarget;
    elem && loadingButton(elem);
    const { dispatch, authentication } = this.props;
    const { _currentPage, _searchText, _search, startDate, endDate, select_dept_v, _pageCount } = this.state;
    let { user } = authentication;
    let { id } = user.user;

    dispatch(userActions.deleteTicket(ticketData)).then(() => {
      const { ticketDelete } = this.props;
      window.location.reload();
      // if (ticketDelete && ticketDelete == 200) {
      //   window.location.reload();
      //   // dispatch(userActions.deleteNotification(ticketData));
      //   // dispatch(userActions.listTickets(_currentPage, _searchText, _pageCount, id, _search, startDate, endDate)).then(() => {
      //   //   dispatch(userActions.getTicketStatus('', _searchText, startDate, endDate, id)).then(() => {
      //   //     const { ticket, ticketStatus } = this.props;
      //   //     const { status } = ticketStatus && ticketStatus.ticketStatus;
      //   //     if (status) { this.setState({ _status: status }); }
      //   //     if (ticket && ticket.list && ticket.list.data && ticket.list.data.recordsFiltered) {
      //   //       this.setState({ _recordsFiltered: ticket.list.data.recordsFiltered, loading: false });
      //   //     } else { this.setState({ _recordsFiltered: 0, loading: false }); }
      //   //     this.ticketList();
      //   //     elem && removeLoadingButton(elem, '');
      //   //   });
      //   // });
      // }
    });
    // _ticketId
    // let row = e.currentTarget.parentNode.parentNode;
    // row && row.remove();
  }

  render() {
    const { ticket } = this.props;
    const { loading, paginationList, _currentPage, _pageCount, _searchText, _recordsFiltered, _departments, _status, listTable } = this.state;
    // const listTable = [];
    const { Option } = components;
    const IconOption = (props) => {
      let iconimg = '';
      let bagde = '';
      let imgSrc = require("assets/img/theme/team-1.jpg");
      if (props.data.badge) {
        iconimg = (<div className="col-2 p-0"><img src={imgSrc} className="avatar avatar-xs rounded" /> </div>);
        bagde = (<div className="col-2 p-0 w-100 d-flex text-right align-items-center"><span className="badge badge-dark badge-pill text-white">{props.data.badge}</span></div>);
      }
      return (
        <Option {...props} key={`${props.data.label}_${props.data.id}`} selected={`${props.data.selected}`} className="d-flex align-items-center">
          <div className="row col p-0 m-0">
            {iconimg}
            <div className="col px-0 mx-1 d-flex align-items-center">{props.data.label}</div>
            {bagde}
          </div>
        </Option>
      );
    }

    const statuses = [];
    _status && _status.forEach((vv, ii) => {
      statuses.push(<Badge key={ii} color={globalConstants.TICKETS.COLORS[vv.name.trim().toLowerCase().replace(/ /g, '_')]} className="p-1 px-2 h-100 d-flex justify-content-center align-items-center font-10px" href="#badge" onClick={(e) => { e.preventDefault(); this.searchData(vv.ticket_status, 7); }}>
        {(vv.name === "Work in progress") ? "WIP" : vv.name} ({vv.total})
      </Badge>);
    });

    const pagination = (
      <div className="row col-12 m-0 p-0 paginationrow mb-3">
        {/* <div className="col-md-2 col-12 align-items-center d-flex p-lg-0 px-0">
          <input type="text" value={_searchText} onChange={(e) => { this.searchDataPagination(e) }} className="form-control form-control-sm" placeholder="Search..."/>
        </div>
        <div className="col-md-4 col-12 align-items-center justify-content-lg-start justify-content-center d-flex px-md-2 px-0 m-md-0 my-2 text-right">
          {statuses}
        </div> */}
        <div className="col-md-2 col-12 align-items-center d-flex pl-md-2 px-0 m-md-0 mb-2">
          <ReactDatetime
            inputProps={{
              placeholder: "Start Date",
              className: "form-control form-control-sm border-left-0", readOnly: true
            }}
            closeOnSelect={true}
            isValidDate={this.validStartData}
            renderInput={this.renderDateInput}
            timeFormat={false}
            renderDay={(props, currentDate, selectedDate) => {
              let classes = props.className;
              if (
                this.state.startDate &&
                this.state.endDate &&
                moment(this.state.startDate)._d + "" === currentDate._d + ""
              ) {
                classes += " start-date";
              } else if (
                this.state.startDate &&
                this.state.endDate &&
                new Date(moment(this.state.startDate)._d + "") <
                new Date(currentDate._d + "") &&
                new Date(moment(this.state.endDate)._d + "") >
                new Date(currentDate._d + "")
              ) {
                classes += " middle-date";
              } else if (
                this.state.endDate &&
                moment(this.state.endDate)._d + "" === currentDate._d + ""
              ) {
                classes += " end-date";
              }
              return (
                <td {...props} className={classes}>
                  {currentDate.date()}
                </td>
              );
            }}
            value={this.state.startDate}
            onChange={e => { this.setState({ startDate: moment(e).format('YYYY-MM-DD'), loading: true }); setTimeout(() => { this.listTick(); }, 500); }}
          />
        </div>
        <div className="col-md-2 col-12 align-items-center d-flex pl-md-2 px-0 m-md-0 mb-2">
          <ReactDatetime
            inputProps={{
              placeholder: "End Date",
              className: "form-control form-control-sm border-left-0", readOnly: true
            }}
            isValidDate={this.validEndData}
            closeOnSelect={true}
            renderInput={this.renderDateInput}
            timeFormat={false}
            renderDay={(props, currentDate, selectedDate) => {
              let classes = props.className;
              if (
                this.state.startDate &&
                this.state.endDate &&
                moment(this.state.startDate)._d + "" === currentDate._d + ""
              ) {
                classes += " start-date";
              } else if (
                this.state.startDate &&
                this.state.endDate &&
                new Date(moment(this.state.startDate)._d + "") <
                new Date(currentDate._d + "") &&
                new Date(moment(this.state.endDate)._d + "") >
                new Date(currentDate._d + "")
              ) {
                classes += " middle-date";
              } else if (
                this.state.endDate &&
                moment(this.state.endDate)._d + "" === currentDate._d + ""
              ) {
                classes += " end-date";
              }
              return (
                <td {...props} className={classes}>
                  {currentDate.date()}
                </td>
              );
            }}
            value={this.state.endDate}
            onChange={e => { this.setState({ endDate: moment(e).format('YYYY-MM-DD'), loading: true }); setTimeout(() => { this.listTick(); }, 500); }}
          />
        </div>
        <div className="col-md-2 col-12 align-items-center d-flex pl-md-2 px-0 m-md-0 mb-2">
          {/* <span className="w-100">
          <Select2 ref={`select_department`} id="departments" className={`form-control form-control-sm p-0 select_department`} isClearable placeholder="Select department" components={{ Option: IconOption }} isSearchable isMulti={false} name="departments" value={this.state.select_dept_v} options={_departments} onChange={(value) => { this.setState({ select_dept_v: (value) ? value : '' }); this.searchData((value) ? value.value : '', 5); }} />
          </span> */}

          <span className="ml-1"><button className="btn btn-sm btn-primary" href="#badge" onClick={(e) => { e.preventDefault(); this.reset(); }}>
            Reset
          </button></span>
        </div>
      </div>
    );
    // else{
    //   return (<></>);
    // }
    return (
      <>
        {(loading) ? (<i className="fas fa-circle-notch fa-spin display-3 sticky-top float-left" style={{ margin: '0.5rem 1rem', marginTop: -70 }}></i>) : ''}
        <SimpleHeader name="List" parentName="Tickets" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card body className="pb-5">
                {this.state.alert}
                {pagination}
                <div className="table-responsive" ref="first-list">
                  <Table hover className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th className="sort px-1 py-2 text-center" data-sort="ticket_id" scope="col"> # </th>
                        <th className="sort px-1 py-2" data-sort="cust_name" scope="col"> Cust. Name </th>
                        <th className="sort px-1 py-2" data-sort="cust_email" scope="col"> Cust. Email </th>
                        {/* <th className="sort px-1 py-2" scope="col" data-sort="assigned_to_name"> Assigned To </th> */}
                        <th className="sort px-1 py-2" data-sort="poc" scope="col"> Contact No. </th>
                        {/* <th className="sort px-1 py-2" data-sort="poc_number" scope="col"> Dept. </th> */}
                        {/* <th className="sort px-1 py-2 text-center" data-sort="next_folow_up" scope="col"> Follow Up </th> */}
                        <th className="sort px-1 py-2 text-right" data-sort="status" scope="col"> Status </th>
                        <th scope="col" className="px-1 py-2 text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {listTable.length > 0 && listTable || ((loading) ? (<tr><td colSpan="9" className="text-center">Processing..</td></tr>) : (<tr><td colSpan="9" className="text-center">No Rows Found</td></tr>))}
                    </tbody>
                  </Table>
                </div>

                <div className="row paginationrow col p-0 m-0 mt-4 d-flex justify-content-left align-items-left">
                  <Pagination
                    selectComponentClass={Select}
                    showSizeChanger
                    defaultPageSize={_pageCount}
                    defaultCurrent={_currentPage}
                    onShowSizeChange={(current, pageSize) => this.onShowSizeChange(current, pageSize)}
                    onChange={(current, pageSize) => this.onChange(current, pageSize)}
                    total={_recordsFiltered}
                    locale={localeInfo}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { ticket, department, authentication, ticketStatus, ticketDelete } = state;
  return { ticket, department, authentication, ticketStatus, ticketDelete };
}

const connectedListPage = connect(mapStateToProps)(List);
export { connectedListPage as List };
import { vehicleConstants } from '../_constants';
import { vehicleService } from '../_services';

export const vehicleActions = {
    vehicleList,
    vehicleInsert,
    vehicleUpdate,
    vehicleDelete,
    vehicleRestore,
    vehicleImagesList,
    vehicleImagesInsert,
    vehicleImagesUpdate,
    vehicleImagesDelete,
    vehicleImagesRestore,
    vehicleAcInfoList,
    vehicleAcInfoInsert,
    vehicleAcInfoUpdate,
    vehicleAcInfoDelete,
    vehicleAcInfoRestore,
    vehicleNonAcInfoList,
    vehicleNonAcInfoInsert,
    vehicleNonAcInfoUpdate,
    vehicleNonAcInfoDelete,
    vehicleNonAcInfoRestore,
    vehicleSaveStage1,
    vehicleUpdateStage1,
    vehicleSaveStage2,
    vehicleUpdateStage2,
    vehicleSaveStage3,
    vehicleUpdateStage3,
    vehicleSaveStage4,
    vehicleUpdateStage4,
    vehicleDeleteItemStage4
};

function vehicleList(data){
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleList(data).then(
            vehicleList => dispatch(success(vehicleList)),
            error  => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.LIST_VEHICLE_REQUEST } }
    function success(vehicleList) { return { type: vehicleConstants.LIST_VEHICLE_SUCCESS, vehicleList } }
    function failure(error) { return { type: vehicleConstants.LIST_VEHICLE_FAILURE, error }  }
}

function vehicleInsert(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleInsert(data).then(
            vehicleInsert => dispatch(success(vehicleInsert)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.INSERT_VEHICLE_REQUEST } }
    function success(vehicleInsert) { return { type: vehicleConstants.INSERT_VEHICLE_SUCCESS, vehicleInsert } }
    function failure(error) { return { type: vehicleConstants.INSERT_VEHICLE_FAILURE, error } }
}

function vehicleUpdate(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleUpdate(data).then(
            vehicleUpdate => dispatch(success(vehicleUpdate)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.UPDATE_VEHICLE_REQUEST } }
    function success(vehicleUpdate) { return { type: vehicleConstants.UPDATE_VEHICLE_SUCCESS, vehicleUpdate } }
    function failure(error) { return { type: vehicleConstants.UPDATE_VEHICLE_FAILURE, error } }
}

function vehicleDelete(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleDelete(data).then(
            vehicleDelete => dispatch(success(vehicleDelete)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.DELETE_VEHICLE_REQUEST } }
    function success(vehicleDelete) { return { type: vehicleConstants.DELETE_VEHICLE_SUCCESS, vehicleDelete } }
    function failure(error) { return { type: vehicleConstants.DELETE_VEHICLE_FAILURE, error } }
}

function vehicleRestore(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleRestore(data).then(
            vehicleRestore => dispatch(success(vehicleRestore)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.RESTORE_VEHICLE_REQUEST } }
    function success(vehicleRestore) { return { type: vehicleConstants.RESTORE_VEHICLE_SUCCESS, vehicleRestore } }
    function failure(error) { return { type: vehicleConstants.RESTORE_VEHICLE_FAILURE, error } }
}

function vehicleImagesList(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleImagesList(data).then(
            vehicleImagesList => dispatch(success(vehicleImagesList)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.LIST_VEHICLE_IMAGES_REQUEST } }
    function success(vehicleImagesList) { return { type: vehicleConstants.LIST_VEHICLE_IMAGES_SUCCESS, vehicleImagesList } }
    function failure(error) { return { type: vehicleConstants.LIST_VEHICLE_IMAGES_FAILURE, error } }
}

function vehicleImagesInsert(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleImagesInsert(data).then(
            vehicleImagesInsert => dispatch(success(vehicleImagesInsert)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.INSERT_VEHICLE_IMAGES_REQUEST } }
    function success(vehicleImagesInsert) { return { type: vehicleConstants.INSERT_VEHICLE_IMAGES_SUCCESS, vehicleImagesInsert } }
    function failure(error) { return { type: vehicleConstants.INSERT_VEHICLE_IMAGES_FAILURE, error } }
}

function vehicleImagesUpdate(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleImagesUpdate(data).then(
            vehicleImagesUpdate => dispatch(success(vehicleImagesUpdate)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.UPDATE_VEHICLE_IMAGES_REQUEST } }
    function success(vehicleImagesUpdate) { return { type: vehicleConstants.UPDATE_VEHICLE_IMAGES_SUCCESS, vehicleImagesUpdate } }
    function failure(error) { return { type: vehicleConstants.UPDATE_VEHICLE_IMAGES_FAILURE, error } }
}

function vehicleImagesDelete(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleImagesDelete(data).then(
            vehicleImagesDelete => dispatch(success(vehicleImagesDelete)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.DELETE_VEHICLE_IMAGES_REQUEST } }
    function success(vehicleImagesDelete) { return { type: vehicleConstants.DELETE_VEHICLE_IMAGES_SUCCESS, vehicleImagesDelete } }
    function failure(error) { return { type: vehicleConstants.DELETE_VEHICLE_IMAGES_FAILURE, error } }
}

function vehicleImagesRestore(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleImagesRestore(data).then(
            vehicleImagesRestore => dispatch(success(vehicleImagesRestore)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.RESTORE_VEHICLE_IMAGES_REQUEST } }
    function success(vehicleImagesRestore) { return { type: vehicleConstants.RESTORE_VEHICLE_IMAGES_SUCCESS, vehicleImagesRestore } }
    function failure(error) { return { type: vehicleConstants.RESTORE_VEHICLE_IMAGES_FAILURE, error } }
}

function vehicleAcInfoList(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleAcInfoList(data).then(
            vehicleAcInfoList => dispatch(success(vehicleAcInfoList)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.LIST_VEHICLE_ACINFO_REQUEST } }
    function success(vehicleAcInfoList) { return { type: vehicleConstants.LIST_VEHICLE_ACINFO_SUCCESS, vehicleAcInfoList } }
    function failure(error) { return { type: vehicleConstants.LIST_VEHICLE_ACINFO_FAILURE, error } }
}

function vehicleAcInfoInsert(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleAcInfoInsert(data).then(
            vehicleAcInfoInsert => dispatch(success(vehicleAcInfoInsert)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.INSERT_VEHICLE_ACINFO_REQUEST } }
    function success(vehicleAcInfoInsert) { return { type: vehicleConstants.INSERT_VEHICLE_ACINFO_SUCCESS, vehicleAcInfoInsert } }
    function failure(error) { return { type: vehicleConstants.INSERT_VEHICLE_ACINFO_FAILURE, error } }
}

function vehicleAcInfoUpdate(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleAcInfoUpdate(data).then(
            vehicleAcInfoUpdate => dispatch(success(vehicleAcInfoUpdate)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.UPDATE_VEHICLE_ACINFO_REQUEST } }
    function success(vehicleAcInfoUpdate) { return { type: vehicleConstants.UPDATE_VEHICLE_ACINFO_SUCCESS, vehicleAcInfoUpdate } }
    function failure(error) { return { type: vehicleConstants.UPDATE_VEHICLE_ACINFO_FAILURE, error } }
}

function vehicleAcInfoDelete(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleAcInfoDelete(data).then(
            vehicleAcInfoDelete => dispatch(success(vehicleAcInfoDelete)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.DELETE_VEHICLE_ACINFO_REQUEST } }
    function success(vehicleAcInfoDelete) { return { type: vehicleConstants.DELETE_VEHICLE_ACINFO_SUCCESS, vehicleAcInfoDelete } }
    function failure(error) { return { type: vehicleConstants.DELETE_VEHICLE_ACINFO_FAILURE, error } }
}

function vehicleAcInfoRestore(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleAcInfoRestore(data).then(
            vehicleAcInfoRestore => dispatch(success(vehicleAcInfoRestore)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.RESTORE_VEHICLE_ACINFO_REQUEST } }
    function success(vehicleAcInfoRestore) { return { type: vehicleConstants.RESTORE_VEHICLE_ACINFO_SUCCESS, vehicleAcInfoRestore } }
    function failure(error) { return { type: vehicleConstants.RESTORE_VEHICLE_ACINFO_FAILURE, error } }
}

function vehicleNonAcInfoList(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleNonAcInfoList(data).then(
            vehicleNonAcInfoList => dispatch(success(vehicleNonAcInfoList)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.LIST_VEHICLE_NONACINFO_REQUEST } }
    function success(vehicleNonAcInfoList) { return { type: vehicleConstants.LIST_VEHICLE_NONACINFO_SUCCESS, vehicleNonAcInfoList } }
    function failure(error) { return { type: vehicleConstants.LIST_VEHICLE_NONACINFO_FAILURE, error } }
}

function vehicleNonAcInfoInsert(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleNonAcInfoInsert(data).then(
            vehicleNonAcInfoInsert => dispatch(success(vehicleNonAcInfoInsert)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.INSERT_VEHICLE_NONACINFO_REQUEST } }
    function success(vehicleNonAcInfoInsert) { return { type: vehicleConstants.INSERT_VEHICLE_NONACINFO_SUCCESS, vehicleNonAcInfoInsert } }
    function failure(error) { return { type: vehicleConstants.INSERT_VEHICLE_NONACINFO_FAILURE, error } }
}

function vehicleNonAcInfoUpdate(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleNonAcInfoUpdate(data).then(
            vehicleNonAcInfoUpdate => dispatch(success(vehicleNonAcInfoUpdate)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.UPDATE_VEHICLE_NONACINFO_REQUEST } }
    function success(vehicleNonAcInfoUpdate) { return { type: vehicleConstants.UPDATE_VEHICLE_NONACINFO_SUCCESS, vehicleNonAcInfoUpdate } }
    function failure(error) { return { type: vehicleConstants.UPDATE_VEHICLE_NONACINFO_FAILURE, error } }
}

function vehicleNonAcInfoDelete(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleNonAcInfoDelete(data).then(
            vehicleNonAcInfoDelete => dispatch(success(vehicleNonAcInfoDelete)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.DELETE_VEHICLE_NONACINFO_REQUEST } }
    function success(vehicleNonAcInfoDelete) { return { type: vehicleConstants.DELETE_VEHICLE_NONACINFO_SUCCESS, vehicleNonAcInfoDelete } }
    function failure(error) { return { type: vehicleConstants.DELETE_VEHICLE_NONACINFO_FAILURE, error } }
}

function vehicleNonAcInfoRestore(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleNonAcInfoRestore(data).then(
            vehicleNonAcInfoRestore => dispatch(success(vehicleNonAcInfoRestore)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.RESTORE_VEHICLE_NONACINFO_REQUEST } }
    function success(vehicleNonAcInfoRestore) { return { type: vehicleConstants.RESTORE_VEHICLE_NONACINFO_SUCCESS, vehicleNonAcInfoRestore } }
    function failure(error) { return { type: vehicleConstants.RESTORE_VEHICLE_NONACINFO_FAILURE, error } }
}

function vehicleSaveStage1(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleSaveStage1(data).then(
            vehicleSaveStage1 => dispatch(success(vehicleSaveStage1)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_STAGE_ONE_REQUEST } }
    function success(vehicleSaveStage1) { return { type: vehicleConstants.VEHICLE_STAGE_ONE_SUCCESS, vehicleSaveStage1 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_STAGE_ONE_FAILURE, error } }
}

function vehicleUpdateStage1(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleUpdateStage1(data).then(
            vehicleUpdateStage1 => dispatch(success(vehicleUpdateStage1)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_ONE_REQUEST } }
    function success(vehicleUpdateStage1) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_ONE_SUCCESS, vehicleUpdateStage1 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_ONE_FAILURE, error } }
}

function vehicleSaveStage2(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleSaveStage2(data).then(
            vehicleSaveStage2 => dispatch(success(vehicleSaveStage2)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_STAGE_TWO_REQUEST } }
    function success(vehicleSaveStage2) { return { type: vehicleConstants.VEHICLE_STAGE_TWO_SUCCESS, vehicleSaveStage2 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_STAGE_TWO_FAILURE, error } }
}

function vehicleUpdateStage2(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleUpdateStage2(data).then(
            vehicleUpdateStage2 => dispatch(success(vehicleUpdateStage2)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_TWO_REQUEST } }
    function success(vehicleUpdateStage2) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_TWO_SUCCESS, vehicleUpdateStage2 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_TWO_FAILURE, error } }
}

function vehicleSaveStage3(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleSaveStage3(data).then(
            vehicleSaveStage3 => dispatch(success(vehicleSaveStage3)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_STAGE_THREE_REQUEST } }
    function success(vehicleSaveStage3) { return { type: vehicleConstants.VEHICLE_STAGE_THREE_SUCCESS, vehicleSaveStage3 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_STAGE_THREE_FAILURE, error } }
}

function vehicleUpdateStage3(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleUpdateStage3(data).then(
            vehicleUpdateStage3 => dispatch(success(vehicleUpdateStage3)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_THREE_REQUEST } }
    function success(vehicleUpdateStage3) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_THREE_SUCCESS, vehicleUpdateStage3 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_THREE_FAILURE, error } }
}

function vehicleSaveStage4(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleSaveStage4(data).then(
            vehicleSaveStage4 => dispatch(success(vehicleSaveStage4)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_STAGE_FOUR_REQUEST } }
    function success(vehicleSaveStage4) { return { type: vehicleConstants.VEHICLE_STAGE_FOUR_SUCCESS, vehicleSaveStage4 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_STAGE_FOUR_FAILURE, error } }
}

function vehicleUpdateStage4(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleUpdateStage4(data).then(
            vehicleUpdateStage4 => dispatch(success(vehicleUpdateStage4)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_FOUR_REQUEST } }
    function success(vehicleUpdateStage4) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_FOUR_SUCCESS, vehicleUpdateStage4 } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_UPDATE_STAGE_FOUR_FAILURE, error } }
}

function vehicleDeleteItemStage4(data) {
    return async dispatch => {
        dispatch(request());
        await vehicleService.vehicleDeleteItemStage4(data).then(
            stage4Item => dispatch(success(stage4Item)),
            error => dispatch(failure(error))
        );
    };
    function request() { return { type: vehicleConstants.VEHICLE_DELETE_ITEM_STAGE_FOUR_REQUEST } }
    function success(stage4Item) { return { type: vehicleConstants.VEHICLE_DELETE_ITEM_STAGE_FOUR_SUCCESS, stage4Item } }
    function failure(error) { return { type: vehicleConstants.VEHICLE_DELETE_ITEM_STAGE_FOUR_FAILURE, error } }
}
import { serviceConstants } from '../_constants';
import { serviceService } from '../_services';

export const serviceActions = {
    createAction,
    updateAction,
    getServiceAction,
    listVisaTypes,
};

function createAction(service, data){
    return async dispatch => {
        dispatch(request());
        await serviceService.createService(service, data)
            .then(
                data => dispatch(success(data)),
                error  => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceConstants.CREATE_SERVICE_REQUEST } }
    function success(data) { return { type: serviceConstants.CREATE_SERVICE_SUCCESS, data } }
    function failure(error) { return { type: serviceConstants.CREATE_SERVICE_FAILURE, error } }
}

function updateAction(service, data){
    return async dispatch => {
        dispatch(request());
        await serviceService.updateService(service, data)
            .then(
                data => dispatch(success(data)),
                error  => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceConstants.UPDATE_SERVICE_REQUEST } }
    function success(data) { return { type: serviceConstants.UPDATE_SERVICE_SUCCESS, data } }
    function failure(error) { return { type: serviceConstants.UPDATE_SERVICE_FAILURE, error } }
}

function getServiceAction(service, user_id, serviceCustomer){
    return async dispatch => {
        dispatch(request());
        await serviceService.getCustomerService(service, user_id)
            .then(
                data => dispatch(success(service, data, serviceCustomer)),
                error  => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceConstants.GET_SERVICE_CUSTOMER_REQUEST } }
    function success(service, data, datas) { datas[`${service}`] = data; return { type: serviceConstants.GET_SERVICE_CUSTOMER_SUCCESS, datas } }
    function failure(error) { return { type: serviceConstants.GET_SERVICE_CUSTOMER_FAILURE, error } }
}

function listVisaTypes(){
    return async dispatch => {
        dispatch(request());
        await serviceService.listVisaTypes()
            .then(
                visaTypes => dispatch(success(visaTypes)),
                error  => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceConstants.LIST_VISA_TYPE_REQUEST } }
    function success(visaTypes) { return { type: serviceConstants.LIST_VISA_TYPE_SUCCESS, visaTypes } }
    function failure(error) { return { type: serviceConstants.LIST_VISA_TYPE_FAILURE, error } }
}
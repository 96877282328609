import React, { useState, useEffect, useMemo, useRef } from 'react';
import passport from '../../assets/departments/passport.PNG';
import visa from '../../assets/departments/visa.PNG';
import tour from '../../assets/departments/tour.PNG';
import vehicles from '../../assets/departments/vehicles.PNG';
import general from '../../assets/departments/general.PNG';
import otherServices from '../../assets/departments/other_services.PNG';
import styles from '../_enquiry/enquiry.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { json } from 'body-parser';
import { toast } from 'react-toastify';
import { Pagination, Table } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
  ColumnToggle,
} from 'react-bootstrap-table2-toolkit';
// react component used to create sweet alerts
import ReactBSAlert from 'react-bootstrap-sweetalert';
// reactstrap components
import {
  ButtonGroup,
  Tooltip,
  Popover,
  PopoverBody,
  UncontrolledTooltip,
} from 'reactstrap';

import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
} from 'reactstrap';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';
import moment from 'moment';
import ShowMoreText from 'react-show-more-text';
import styled, { css } from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
import Popup from 'react-animated-popup';
import ReactPaginate from 'react-paginate';
import { globalConstants } from '../../_constants';
import 'firebase/compat/messaging';
import { TextField } from '@mui/material';
// import { globalConstants } from '../../_constants';

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
      padding-top: 250px;
    `}
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%;',
    height: '100%',
  },
};

let Userslist = () => {

  //  onMessageListener()
  //    .then((payload) => {
  //     //  setShow(true);
  //      let local = localStorage.user;
  //      let newLocal = JSON.parse(local);
  //      console.log(newLocal.id);
  //      axios
  //        .get(
  //          globalConstants.BASEURL +
  //            `api/notification_count/?userid=${newLocal.id}`
  //        )
  //        .then((res) => {
  //          console.log(
  //            'notification_count_data_firebase',
  //            res.data[0].notification_count
  //          );
  //          document.getElementById('notificationCount').innerHTML =
  //            res.data[0].notification_count;
  //        })
  //        .catch((err) => {
  //          console.log(err, 'notification-err-for-count-firebase');
  //        });
  //     //  setNotification({
  //     //    title: payload.notification.title,
  //     //    body: payload.notification.body,
  //     //  });
  //      console.log(payload, 'used as substitution values similar to ');
  //      toast(() => (
  //        <>
  //          <div style={{ fontWeight: 'bold', color: 'black' }}>
  //            {' '}
  //            {payload.notification.title}
  //          </div>
  //          <div style={{ fontSize: '14px', color: 'black' }}>
  //            {payload.notification.body}
  //          </div>
  //          <Link
  //            to={{
  //              pathname: `/admin/enquiry-edit/${payload.data['gcm.notification.ticketId']}/${payload.data['gcm.notification.custId']}`,
  //            }}
  //            className="btn btn-dark btn-sm"
  //          >
  //            View
  //          </Link>
  //        </>
  //      ));
  //    })
  //    .catch((err) => console.log('failed: ', err));

  //  useEffect(() => {
  //    onMessageListener();
  //  }, []);
  const { ToggleList } = ColumnToggle;


  let [usersList, setUsersList] = useState([]);



  let [department, setDepartment] = useState([]);
  let [deptCode, setDeptCode] = useState([]);
  let [deptName, setDeptName] = useState([]);
  let [departmentList, setDepartmentList] = useState([]);
  let [toggleDept, setToggleDept] = useState(false);
  let [enquiryData, setEnquiryData] = useState([]);
  let [customerData, setCustomerData] = useState([]);
  let [apiStatus, setApiStatus] = useState('1');
  let [popover, setPopOver] = useState({});
  let [loading, setLoading] = useState(false);
  let [popmenuOpen, setPopMenuOpen] = useState(false);
  let [proceedDelete, setProceedDelete] = useState(false);
  let [search, setSearch] = useState('');
   let [apiSearch, setApiSearch] = useState('');
let [searchFocus, setSearchFocus] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const myRef = useRef();

  const searchText = (e) => {
    setSearch(e.target.value.trim());
    setPageNumber('1');
  };

  useEffect(() => {
    const timeoutid = setTimeout(() => {
      setApiSearch(search);
    }, 500);
    return () => clearTimeout(timeoutid);
  }, [search]);

  useEffect(() => {
    setLoading(true);
    let dataUrl = `${process.env.REACT_APP_BACKEND_URL}api/department`;
    axios
      .get(dataUrl)
      .then((res) => {
        console.log('department=responseoeoriop', res.data);
        setLoading(false);
        setDepartment(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, 'department-err');
      });
  }, [departmentList]);

  let handleClick = (e, code, name) => {
    console.log('SHOWDOW_BOX', e.target.tagName);
    if (e.target.tagName == 'IMG') {
      if (deptName.length > 0) {
        console.log('true');
        var index = deptName.indexOf(name);
        var index2 = deptCode.indexOf(code);
        console.log('indexOf', index);
        console.log('indexOf2', index2);
        if (index !== -1 && index2 !== -1) {
          deptName.splice(index, 1);
          deptCode.splice(index2, 1);
          e.target.style.boxShadow = 'none';
          if (e.target.alt == 'Visa') {
            e.target.style.borderColor = '#ebeb73';
          }
          if (e.target.alt == 'Passport') {
            e.target.style.borderColor = '#f19595';
          }
          if (e.target.alt == 'Taxi') {
            e.target.style.borderColor = '#ebeb73';
          }
          if (e.target.alt == 'Tourism') {
            e.target.style.borderColor = 'lightgreen';
          }
          if (e.target.alt == 'Vehicle') {
            e.target.style.borderColor = '#d5a958';
          }
          if (e.target.alt == 'Others') {
            e.target.style.borderColor = '#b679e9';
          }
          console.log(deptName, '2345678');
          setDeptName([...deptName]);
          setDeptCode([...deptCode]);
        } else {
          console.log('BOX-SHADOW', e.target);
          e.target.style.boxShadow = '5px 5px 5px grey';
          if (e.target.alt == 'Visa') {
            e.target.style.borderColor = '#ffbb00';
          }
          if (e.target.alt == 'Passport') {
            e.target.style.borderColor = 'red';
          }
          if (e.target.alt == 'Taxi') {
            e.target.style.borderColor = '#ffbb00';
          }
          if (e.target.alt == 'Tourism') {
            e.target.style.borderColor = 'green';
          }
          if (e.target.alt == 'Vehicle') {
            e.target.style.borderColor = 'rgb(255, 123, 0)';
          }
          if (e.target.alt == 'Others') {
            e.target.style.borderColor = '#33005e';
          }
          console.log('false');
          setDeptName([...deptName].concat(name));
          setDeptCode([...deptCode].concat(code));
        }
      } else {
        console.log('false');
        e.target.style.boxShadow = '5px 5px 5px grey';
        if (e.target.alt == 'Visa') {
          e.target.style.borderColor = '#ffbb00';
        }
        if (e.target.alt == 'Passport') {
          e.target.style.borderColor = 'red';
        }
        if (e.target.alt == 'Taxi') {
          e.target.style.borderColor = '#ffbb00';
        }
        if (e.target.alt == 'Tourism') {
          e.target.style.borderColor = 'green';
        }
        if (e.target.alt == 'Vehicle') {
          e.target.style.borderColor = 'rgb(255, 123, 0)';
        }
        if (e.target.alt == 'Others') {
          e.target.style.borderColor = '#33005e';
        }
        setDeptName([...deptName].concat(name));
        setDeptCode([...deptCode].concat(code));
      }
    }
  };

  let handleStartBtn = () => {
    let result = deptName.reduce(function (result, field, index) {
      result[deptCode[index]] = field;
      return result;
    }, {});
    console.log('merget two arrays as key value pairs', result);

    let indexArr = [];
    department.map((depart) => {
      deptName.map((name) => {
        if (depart.deptname == name) {
          indexArr.push(depart);
        }
      });
    });
    console.log('INDEXARR', indexArr);
    let indexArrStr = JSON.stringify(indexArr);
    localStorage.setItem('indexArr', indexArrStr);
    let resString = JSON.stringify(result);
    // let pushArrayString = JSON.stringify(pushArray);
    localStorage.setItem('departmentArray', resString);
    // localStorage.setItem('departmentArray21', pushArrayString);
    // toast('Wow so easy!', 'success');
    console.log(department);
    let selectDept = {
      deptName,
      deptCode,
    };
    let stringDept = JSON.stringify(selectDept);
    localStorage.setItem('departmentListSelet', stringDept);
    localStorage.setItem('departmentList', deptName);
    let parseDept = JSON.stringify(department);
    localStorage.setItem('department', parseDept);
    localStorage.setItem('deptcode', deptCode);
    // localStorage.setItem('deptImg')
    // axios.put('/enquiry-form-update', {deptName}).then().catch();
  };

  const classLister =
    (styleObject) =>
    (...classList) =>
      classList.reduce((list, myClass) => {
        let output = list;
        if (styleObject[myClass]) {
          if (list) output += ' '; // appends a space if list is not empty
          output += styleObject[myClass];
          //Above: append 'myClass' from styleObject to the list if it is defined
        }
        return output;
      }, '');

  const classes = classLister(styles);

  let [pageNumber, setPageNumber] = useState('1');
  let [pageValueNumber, setPageValueNumber] = useState('');

  let onPageChange = (page) => {
    console.log(page, 'Multiple arguments_page');
    setPageNumber(page.selected + 1);
  };

  //get the list of enquires
  let getAllCustomerEnquiryUsers = () => {
    setLoading(true);
    let dataURL =
      `${process.env.REACT_APP_BACKEND_URL}api/getAll-customer-and-enquiry-and-users`;
    axios
      .get(dataURL)
      .then((res) => {
        console.log('ENQUIRYLISTING_GETTING_VALUE', res.data);
        setEnquiryData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('ENQUIRYLISTING_GETTING_ERROR', error);
      });
  };

  useEffect(() => {
    getAllCustomerEnquiryUsers();
  }, []);


  let [totalCountEnquiry, setTotalCountEnquiry] = useState('');

  let limit = 5;

  //get the list of users
  let getAllUsersList = () => {
    setLoading(true);
    let dataURL = `${
      process.env.REACT_APP_BACKEND_URL
    }api/get-users-list?pageCount=${
      pageNumber
    }&search=${apiSearch}`;
    axios
      .get(dataURL)
      .then((res) => {
        // setInProgress(res.data.res1[0].in_progress);
        
         if (res.data.status == 0) {
           setLoading(false);
          setApiStatus(res.data.status);
         }else{
          console.log('ENQUIRYLISTING_GETTING_VALUE', res.data.res1);
          setUsersList(res.data.res1);
          setPageValueNumber(res.data.res2[0].count);
          setApiStatus(res.data.status);
           res.data.res2.map((d) => {
             console.log(
               'innner_ENQUIRYLISTING_TOTAL_ENTUIQYEGETTIGOUIO79298_VALUE',
               d.count
             );
             setTotalCountEnquiry(Math.ceil(d.count / limit));
             // setAssingeeFilterId("");
           });
           setLoading(false);
         }
      })
      .catch((error) => {
        setLoading(false);
        console.log('ENQUIRYLISTING_GETTING_ERROR', error);
      });
  };

  useEffect(() => {
    getAllUsersList();
  }, [pageNumber, apiSearch]);

  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand('copy');
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand('Copy');
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{' '}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
                console.log('66666666666666666666', e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{' '}
          entries.
        </label>
      </div>
    ),
  });

  const { SearchBar } = Search;

  let [row, setRow] = useState([]);
  let [comment, setComment] = useState([]);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log('EVENT', e);
      console.log('EVENT-ROW', row);
      setRow(row);
      console.log('EVENT_ROW_INDEX', rowIndex);
    },
  };

  const getComments = (id) => {
    setLoading(true);
    setIsOpen(true);
    console.log('GETCOMMENTS_ROW', row.id);
    let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/get-single-comment/${id}`;
    axios
      .get(dataURL)
      .then((res) => {
        console.log('GET_COMMNT', res.data);
        setComment(res.data);
        setLoading(false);
        setIsOpen(true);
      })
      .catch((error) => {
        // setComment([{"name":"alt","profilephoto" : "image","comments" : "No comments to show"}]);
        setLoading(false);
        // setIsOpen(true);
        toast('No comments to show');
        console.log('GET_COMENT_EROR', error);
      });
  };

  // const [tooltipOpen, setTooltipOpen] = useState(false);

  // const toggle = () => setTooltipOpen(!tooltipOpen);

  const [isTargetReady, setIsTargetReady] = useState(false);

  // Target element ref.
  const tooltipRef = useRef(null);

  // Hook to recognize that the target is ready.
  useEffect(() => {
    const targetElement = tooltipRef.current;
    if (targetElement) {
      setIsTargetReady(true);
    }
  }, [tooltipRef.current]);

  const deleteEnquiry = (id) => {
      setLoading(true);
      let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/delete-user/${id}`;
      axios
        .delete(dataURL)
        .then((res) => {
          console.log('DELETE_USERS', res.data);
          if(res.data.status == 0){
            toast(res.data.msg);
          }else{
            toast('User deleted successfully');
            setPopMenuOpen(false);
            getAllUsersList();
          }
          
          // setComment(res.data);
          setLoading(false);
          // setIsOpen(true);
        })
        .catch((error) => {
          // setComment([{"name":"alt","profilephoto" : "image","comments" : "No comments to show"}]);
          setLoading(false);
          setPopMenuOpen(false);
          // toast('Cannot delete enquiry before its closed');
          console.log('GET_COMENT_EROR', error);
        });
    
  };

  return (
    <>
      <style>
        {
          '\
         .pagination {\
            display : flex !important;\
            padding-left : 0px !important;\
            list-style : none !important;\
            border-radius : 0.375rem !important;\
            flex-wrap : wrap !important;\
          }'
        }
      </style>
      <>
        <section id="department-section">
          {/* <pre>{JSON.stringify(apiStatus)}</pre> */}
          {/* <pre>{JSON.stringify(pageValueNumber)}</pre> */}
          {/* <pre>{JSON.stringify(JSON.stringify(usersList.length))}</pre> */}
          <DarkBackground disappear={loading}>
            <LoadingOverlay
              active={true}
              // spinner={<BounceLoader />}
              spinner={true}
              // text="Loading your content..."
            ></LoadingOverlay>
          </DarkBackground>
          {/* <div className={styles.mainContainer}>
            <div className="container">
              <div className={styles.departmentContainer}>
                {department.map(
                  (dept) =>
                    dept.visibility == 0 && (
                      <div
                        onClick={(e) =>
                          handleClick(e, dept.code, dept.deptname)
                        }
                        className={classes('deptItem', `${dept.deptname}`)}
                      >
                        <img
                          src={require(`../../assets/img/department/${dept.deptIcon}`)}
                          alt={dept.deptname}
                        />
                        {dept.deptname == 'Others' ? (
                          <h3>Other Services</h3>
                        ) : (
                          <h3>{dept.deptname}</h3>
                        )}
                      </div>
                    )
                )}
              </div>
              {deptName.length > 0 && (
                <div className={styles.startBtnCon}>
                  <Link to="/admin/enquiry-form">
                    <button
                      onClick={handleStartBtn}
                      className="btn btn-warning btn-md"
                    >
                      Start
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div> */}
          <div className="m-3 pl-3">
            <Link
              to={'/admin/create/user'}
              className="btn btn-dark"
              color="primary"
              type="button"
            >
              Create Users
            </Link>
          </div>
        </section>

        {/* table desktop section */}
        <section className={styles.hideTableSection}>
          <Popup
            className="sticky-top"
            visible={popmenuOpen}
            onClose={() => setPopMenuOpen(false)}
            animationDuration
            style={{
              width: '500px',
              padding: '30px',
              display: 'flex',
              flexWrap: 'wrap',
              position: 'fixed',
              top: '0px',
            }}
          >
            <div>
              <h2>Confirm...</h2>
              <h3>Are you sure want to delete?</h3>
            </div>
            <div>
              <button
                style={{ cursor: 'pointer' }}
                className="btn btn-success"
                onClick={() => {
                  setPopMenuOpen(false);
                  deleteEnquiry(row.id);
                }}
              >
                Yes
              </button>
              <button
                style={{ cursor: 'pointer' }}
                className="btn btn-danger"
                onClick={() => setPopMenuOpen(false)}
              >
                No
              </button>
            </div>
          </Popup>
          <Container className="mt-10" fluid>
            <Row>
              <div style={{ width: '100%' }}>
                <Card>
                  <ToolkitProvider
                    data={usersList}
                    keyField="id"
                    columnToggle
                    columns={[
                      {
                        dataField: 'id',
                        text: 'User Id',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{`${row.id}`}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'deptcode',
                        text: 'Code',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.deptcode}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'role',
                        text: 'Role',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{`${row.role}`}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'name',
                        text: 'Name',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.firstname}</div>
                              <div>{row.lastname}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'email',
                        text: 'Email',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.email}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'phone',
                        text: 'Phone',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.phone}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'deptcode',
                        text: 'Department',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              {row.role == 'Visa' && (
                                <p className={styles.tableTicketStatusOpen}>
                                  Visa
                                </p>
                              )}
                              {row.role == 'Passport' && (
                                <p className={styles.tableTicketStatusProcess}>
                                  Passport
                                </p>
                              )}
                              {row.role == 'Taxi' && (
                                <p className={styles.tableTicketStatusClosed}>
                                  Taxi
                                </p>
                              )}
                              {row.role == 'Tourism' && (
                                <p className={styles.tableTicketStatusHold}>
                                  Tourism
                                </p>
                              )}
                              {row.role == 'Vehicle' && (
                                <p className={styles.tableTicketStatusHold}>
                                  Vehicle
                                </p>
                              )}
                              {row.role == 'Others' && (
                                <p className={styles.tableTicketStatusHold}>
                                  Others
                                </p>
                              )}
                              {row.role == 'Accountant' && (
                                <p className={styles.tableTicketStatusHold}>
                                  Accountant
                                </p>
                              )}
                              {row.role == 'Frontdesk' && (
                                <p className={styles.tableTicketStatusHold}>
                                  Frontdesk
                                </p>
                              )}
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'joindate',
                        text: 'Joindate',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>
                                {moment(row.joindate).format('MMM Do YY')}
                              </div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'salary',
                        text: 'Salary',
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.salary}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'Actions',
                        text: 'Actions',
                        isDummyField: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>
                                <div>
                                  {/* <span>
                                  <i
                                    style={{
                                      backgroundColor: '#00b4f6',
                                      color: 'white',
                                      padding: '6px',
                                      margin: '2px',
                                      borderRadius: '2px',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                    className="fa fa-eye"
                                  />
                                </span> */}
                                  <span>
                                    <Link
                                      to={{
                                        pathname: `/admin/users-edit/${row.id}`,
                                      }}
                                    >
                                      <i
                                        style={{
                                          backgroundColor: '#00d686',
                                          color: 'white',
                                          padding: '6px',
                                          margin: '2px',
                                          borderRadius: '2px',
                                          fontSize: '12px',
                                          cursor: 'pointer',
                                        }}
                                        className="fa fa-pen"
                                      />
                                    </Link>
                                  </span>
                                  <span>
                                    <i
                                      onClick={() => {
                                        setPopMenuOpen(!popmenuOpen);
                                        // deleteEnquiry(
                                        //   row.id,
                                        //   row.ticket_status
                                        // );
                                      }}
                                      style={{
                                        backgroundColor: '#ff004f',
                                        color: 'white',
                                        padding: '6px',
                                        margin: '2px',
                                        borderRadius: '2px',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                      }}
                                      className="fa fa-trash"
                                    />
                                  </span>
                                </div>
                                <div>
                                  {/* <span>
                                  <i
                                    onClick={() => {
                                      setPopMenuOpen(!popmenuOpen);
                                      // deleteEnquiry(
                                      //   row.id,
                                      //   row.ticket_status
                                      // );
                                    }}
                                    style={{
                                      backgroundColor: '#ff004f',
                                      color: 'white',
                                      padding: '6px',
                                      margin: '2px',
                                      borderRadius: '2px',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                    className="fa fa-trash"
                                  />
                                </span> */}
                                  {/* <span>
                                  <i
                                    style={{
                                      backgroundColor: 'blue',
                                      color: 'white',
                                      padding: '6px',
                                      margin: '2px',
                                      borderRadius: '2px',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      // setIsOpen(true);
                                      getComments(row.id);
                                    }}
                                    className="fa fa-list"
                                  />
                                </span> */}
                                </div>
                              </div>
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        {/* <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div> */}
                        <div
                          style={{
                            width: '300px',
                            paddingBottom: '25px',
                            paddingLeft: '20px',
                          }}
                          class="input-group-merge input-group"
                        >
                          <div class="input-group-prepend">
                            <span
                              style={{
                                borderTopLeftRadius: '25px',
                                borderBottomLeftRadius: '25px',
                                borderColor: searchFocus ? 'blue' : '',
                              }}
                              class="input-group-text"
                            >
                              <i className="fa fa-search"></i>
                            </span>
                          </div>
                          <input
                            style={{
                              borderTopRightRadius: '25px',
                              borderBottomRightRadius: '25px',
                            }}
                            onFocus={() => setSearchFocus(true)}
                            onBlur={() => setSearchFocus(false)}
                            placeholder={'Search Here'}
                            type="search"
                            value={search}
                            onChange={searchText}
                            class="form-control m-0"
                            id="searchId"
                          />
                          <UncontrolledTooltip
                            autohide={false}
                            position={'top'}
                            target="searchId"
                          >
                            Search by Firstname, Lastname, Email and Mobile
                          </UncontrolledTooltip>
                        </div>
                        {/* <ToggleList {...props.columnToggleProps} /> */}
                        {/* <hr /> */}
                        {apiStatus == 0 ? (
                          <>
                            <h2
                              style={{ textAlign: 'center', marginTop: '85px' }}
                            >
                              No records found
                            </h2>
                          </>
                        ) : (
                          <>
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              // pagination={pagination}
                              bordered={false}
                              rowEvents={rowEvents}
                            />
                            {pageValueNumber <= 5 && pageNumber <= 1 ? null : (
                              <ReactPaginate
                                previousLabel={'<<'}
                                nextLabel={'>>'}
                                breakLabel={'...'}
                                pageCount={totalCountEnquiry}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={onPageChange}
                                containerClassName={
                                  'pagination justify-content-end'
                                }
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                activeClassName={'active'}
                              />
                            )}
                          </>
                        )}

                        <div style={{ width: '100%' }} ref={myRef}>
                          {/* <button onClick={openModal}>Open Modal</button> */}
                          <Modal
                            isOpen={modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            // onRequestClose={closeModal}
                            className={styles.ReactModal__Content}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            {/* <DarkBackground disappear={loading}>
                                <LoadingOverlay
                                  active={true}
                                  // spinner={<BounceLoader />}
                                  spinner={true}
                                  text="Loading your content..."
                                ></LoadingOverlay>
                              </DarkBackground> */}
                            <Card style={{ minHeight: '80%' }}>
                              <CardHeader>
                                <h5 className="h3 mb-0">
                                  Latest notifications
                                  <span
                                    onClick={() => setIsOpen(false)}
                                    style={{
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    X
                                  </span>
                                </h5>
                              </CardHeader>
                              <CardBody>
                                <div
                                  className="timeline timeline-one-side"
                                  data-timeline-axis-style="dashed"
                                  data-timeline-content="axis"
                                >
                                  {comment.length > 0 ? (
                                    <>
                                      {comment.map((comt) => (
                                        <div className="timeline-block">
                                          <span className="timeline-step mt-2">
                                            <img
                                              ref={tooltipRef}
                                              width={'70px'}
                                              id={row.id}
                                              style={{
                                                borderRadius: '50%',
                                                padding: '5px',
                                                width: '55.80px',
                                                marginTop: '17px',
                                                // height: '51.85px',
                                              }}
                                              src={require(`../../assets/img/users/${comt.profilephoto}`)}
                                              alt="Profile"
                                            />
                                          </span>
                                          <div className="timeline-content">
                                            <div className="d-flex justify-content-between pt-1">
                                              <div>
                                                <span className="text-muted text-sm font-weight-bold">
                                                  {comt.name}
                                                </span>
                                              </div>
                                              <div className="text-right">
                                                <small className="text-muted">
                                                  <i className="fas fa-clock mr-1" />
                                                  {/* {new Date(comt.created_at)
                                                    .toLocaleString(undefined, {
                                                      dateStyle: 'long',
                                                      // day: 'numeric',
                                                      // month: 'long',
                                                      // year: 'numeric',
                                                    })
                                                    .toString()} */}
                                                  {moment(
                                                    comt.created_at
                                                  ).format(
                                                    'Do MMMM YYYY, h:mm a'
                                                  )}
                                                </small>
                                              </div>
                                            </div>
                                            <h6 className="text-sm mt-1 mb-0">
                                              <ShowMoreText
                                                lines={1}
                                                more="Show more"
                                                less="Show less"
                                                className="content-css"
                                                anchorClass="my-anchor-css-class"
                                                // onClick={
                                                //   executeOnClick
                                                // }
                                                expanded={false}
                                                width={400}
                                                truncatedEndingComponent={
                                                  '... '
                                                }
                                              >
                                                {comt.comments}
                                              </ShowMoreText>
                                            </h6>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <h1>No comments to show</h1>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </Modal>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            </Row>
          </Container>
        </section>
        {/* table mobile section */}
        <section className={styles.hideMobTableSection}>
          <Popup
            className="sticky-top"
            visible={popmenuOpen}
            onClose={() => setPopMenuOpen(false)}
            animationDuration
            style={{
              width: '500px',
              padding: '30px',
              display: 'flex',
              flexWrap: 'wrap',
              position: 'fixed',
              top: '0px',
            }}
          >
            <div>
              <h2>Confirm...</h2>
              <h3>Are you sure want to delete?</h3>
            </div>
            <div>
              <button
                style={{ cursor: 'pointer' }}
                className="btn btn-success"
                onClick={() => {
                  setPopMenuOpen(false);
                  deleteEnquiry(row.id);
                }}
              >
                Yes
              </button>
              <button
                style={{ cursor: 'pointer' }}
                className="btn btn-danger"
                onClick={() => setPopMenuOpen(false)}
              >
                No
              </button>
            </div>
          </Popup>
          <Container className="mt-10" fluid>
            <Row>
              <div style={{ width: '100%' }}>
                <Card>
                  <ToolkitProvider
                    data={usersList}
                    keyField="id"
                    columnToggle
                    columns={[
                      {
                        dataField: 'id',
                        text: 'User Id',
                        sort: true,
                        hidden: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{`${row.id}`}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'role',
                        text: 'Role',
                        sort: true,
                        style: {
                          // width: '20%',
                          paddingRight: '0px',
                          paddingLeft: '18px',
                        },

                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <>
                                <div
                                  style={
                                    {
                                      // color: '#a2a9ba',
                                      // fontSize: '16px',
                                      // fontWeight: 'bold',
                                    }
                                  }
                                >{`${row.role}`}</div>
                                <div>{`${row.deptcode}`}</div>
                              </>
                            </>
                          );
                        },
                      },
                      // {
                      //   dataField: 'deptcode',
                      //   text: 'Code',
                      //   hidden: true,
                      //   sort: true,
                      //   formatter: (cell, row) => {
                      //     console.log(row);
                      //     return (
                      //       <>
                      //         <div>{row.deptcode}</div>
                      //       </>
                      //     );
                      //   },
                      // },
                      // {
                      //   dataField: 'role',
                      //   text: 'Role',
                      //   sort: true,
                      //   formatter: (cell, row) => {
                      //     console.log(row);
                      //     return (
                      //       <>
                      //         <div>{`${row.role}`}</div>
                      //       </>
                      //     );
                      //   },
                      // },
                      {
                        dataField: 'name',
                        text: 'Name',
                        sort: true,
                        style: {
                          // width: '20%',
                          paddingRight: '0px',
                          paddingLeft: '18px',
                        },

                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <>
                                <div
                                  style={
                                    {
                                      // color: '#a2a9ba',
                                      // fontSize: '16px',
                                      // fontWeight: 'bold',
                                    }
                                  }
                                >{`${row.firstname}`}</div>
                                {row.role == 'Visa' && (
                                  <p className={styles.tableTicketStatusOpen}>
                                    Visa
                                  </p>
                                )}
                                {row.role == 'Passport' && (
                                  <p
                                    className={styles.tableTicketStatusProcess}
                                  >
                                    Passport
                                  </p>
                                )}
                                {row.role == 'Taxi' && (
                                  <p className={styles.tableTicketStatusClosed}>
                                    Taxi
                                  </p>
                                )}
                                {row.role == 'Tourism' && (
                                  <p className={styles.tableTicketStatusHold}>
                                    Tourism
                                  </p>
                                )}
                                {row.role == 'Vehicle' && (
                                  <p className={styles.tableTicketStatusHold}>
                                    Vehicle
                                  </p>
                                )}
                                {row.role == 'Others' && (
                                  <p className={styles.tableTicketStatusHold}>
                                    Others
                                  </p>
                                )}
                                {row.role == 'Accountant' && (
                                  <p className={styles.tableTicketStatusHold}>
                                    Accountant
                                  </p>
                                )}
                                {row.role == 'Frontdesk' && (
                                  <p className={styles.tableTicketStatusHold}>
                                    Frontdesk
                                  </p>
                                )}
                              </>
                            </>
                          );
                        },
                      },
                      // {
                      //   dataField: 'name',
                      //   text: 'Name',
                      //   sort: true,
                      //   formatter: (cell, row) => {
                      //     console.log(row);
                      //     return (
                      //       <>

                      //         <div>{row.lastname}</div>
                      //       </>
                      //     );
                      //   },
                      // },
                      {
                        dataField: 'email',
                        text: 'Email',
                        hidden: true,
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.email}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'phone',
                        text: 'Phone',
                        sort: true,
                        hidden: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.phone}</div>
                            </>
                          );
                        },
                      },
                      // {
                      //   dataField: 'deptcode',
                      //   text: 'Department',
                      //   sort: true,
                      //   formatter: (cell, row) => {
                      //     console.log(row);
                      //     return (
                      //       <>
                      //         {row.role == 'Visa' && (
                      //           <p className={styles.tableTicketStatusOpen}>
                      //             Visa
                      //           </p>
                      //         )}
                      //         {row.role == 'Passport' && (
                      //           <p className={styles.tableTicketStatusProcess}>
                      //             Passport
                      //           </p>
                      //         )}
                      //         {row.role == 'Taxi' && (
                      //           <p className={styles.tableTicketStatusClosed}>
                      //             Taxi
                      //           </p>
                      //         )}
                      //         {row.role == 'Tourism' && (
                      //           <p className={styles.tableTicketStatusHold}>
                      //             Tourism
                      //           </p>
                      //         )}
                      //         {row.role == 'Vehicle' && (
                      //           <p className={styles.tableTicketStatusHold}>
                      //             Vehicle
                      //           </p>
                      //         )}
                      //         {row.role == 'Others' && (
                      //           <p className={styles.tableTicketStatusHold}>
                      //             Others
                      //           </p>
                      //         )}
                      //         {row.role == 'Accountant' && (
                      //           <p className={styles.tableTicketStatusHold}>
                      //             Accountant
                      //           </p>
                      //         )}
                      //         {row.role == 'Frontdesk' && (
                      //           <p className={styles.tableTicketStatusHold}>
                      //             Frontdesk
                      //           </p>
                      //         )}
                      //       </>
                      //     );
                      //   },
                      // },
                      {
                        dataField: 'joindate',
                        text: 'Joindate',
                        hidden: true,
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>
                                {moment(row.joindate).format('MMM Do YY')}
                              </div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'salary',
                        text: 'Salary',
                        hidden: true,
                        sort: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>{row.salary}</div>
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'Actions',
                        text: 'Actions',
                        isDummyField: true,
                        formatter: (cell, row) => {
                          console.log(row);
                          return (
                            <>
                              <div>
                                <div>
                                  {/* <span>
                                  <i
                                    style={{
                                      backgroundColor: '#00b4f6',
                                      color: 'white',
                                      padding: '6px',
                                      margin: '2px',
                                      borderRadius: '2px',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                    className="fa fa-eye"
                                  />
                                </span> */}
                                  <span>
                                    <Link
                                      to={{
                                        pathname: `/admin/users-edit/${row.id}`,
                                      }}
                                    >
                                      <i
                                        style={{
                                          backgroundColor: '#00d686',
                                          color: 'white',
                                          padding: '6px',
                                          margin: '2px',
                                          borderRadius: '2px',
                                          fontSize: '12px',
                                          cursor: 'pointer',
                                        }}
                                        className="fa fa-pen"
                                      />
                                    </Link>
                                  </span>
                                  <span>
                                    <i
                                      onClick={() => {
                                        setPopMenuOpen(!popmenuOpen);
                                        // deleteEnquiry(
                                        //   row.id,
                                        //   row.ticket_status
                                        // );
                                      }}
                                      style={{
                                        backgroundColor: '#ff004f',
                                        color: 'white',
                                        padding: '6px',
                                        margin: '2px',
                                        borderRadius: '2px',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                      }}
                                      className="fa fa-trash"
                                    />
                                  </span>
                                </div>
                                <div>
                                  {/* <span>
                                  <i
                                    onClick={() => {
                                      setPopMenuOpen(!popmenuOpen);
                                      // deleteEnquiry(
                                      //   row.id,
                                      //   row.ticket_status
                                      // );
                                    }}
                                    style={{
                                      backgroundColor: '#ff004f',
                                      color: 'white',
                                      padding: '6px',
                                      margin: '2px',
                                      borderRadius: '2px',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                    className="fa fa-trash"
                                  />
                                </span> */}
                                  {/* <span>
                                  <i
                                    style={{
                                      backgroundColor: 'blue',
                                      color: 'white',
                                      padding: '6px',
                                      margin: '2px',
                                      borderRadius: '2px',
                                      fontSize: '12px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      // setIsOpen(true);
                                      getComments(row.id);
                                    }}
                                    className="fa fa-list"
                                  />
                                </span> */}
                                </div>
                              </div>
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <div
                          style={{
                            width: '280px',
                            paddingBottom: '25px',
                            paddingLeft: '20px',
                          }}
                          class="input-group-merge input-group"
                        >
                          <div class="input-group-prepend">
                            <span
                              style={{
                                borderTopLeftRadius: '25px',
                                borderBottomLeftRadius: '25px',
                                borderColor: searchFocus ? 'blue' : '',
                              }}
                              class="input-group-text"
                            >
                              <i className="fa fa-search"></i>
                            </span>
                          </div>
                          <input
                            style={{
                              borderTopRightRadius: '25px',
                              borderBottomRightRadius: '25px',
                            }}
                            onFocus={() => setSearchFocus(true)}
                            onBlur={() => setSearchFocus(false)}
                            placeholder={'Search By Name, Email, Phone'}
                            type="search"
                            value={search}
                            onChange={searchText}
                            class="form-control m-0"
                            id="searchId"
                          />
                          <UncontrolledTooltip
                            autohide={false}
                            // position={'top'}
                            target="searchId"
                          >
                            Search by Firstname, Lastname, Email and Mobile
                          </UncontrolledTooltip>
                        </div>
                        {/* <ToggleList {...props.columnToggleProps} /> */}
                        {/* <hr /> */}
                        {apiStatus == 0 ? (
                          <>
                            <h2
                              style={{ textAlign: 'center', marginTop: '85px' }}
                            >
                              No records found
                            </h2>
                          </>
                        ) : (
                          <>
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              // pagination={pagination}
                              bordered={false}
                              rowEvents={rowEvents}
                            />
                            {pageValueNumber <= 5 && pageNumber <= 1 ? null : (
                              <ReactPaginate
                                previousLabel={'<<'}
                                nextLabel={'>>'}
                                breakLabel={'...'}
                                pageCount={totalCountEnquiry}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={onPageChange}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                activeClassName={'active'}
                              />
                            )}
                          </>
                        )}
                        <div style={{ width: '100%' }} ref={myRef}>
                          {/* <button onClick={openModal}>Open Modal</button> */}
                          <Modal
                            isOpen={modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            // onRequestClose={closeModal}
                            className={styles.ReactModal__Content}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            {/* <DarkBackground disappear={loading}>
                                <LoadingOverlay
                                  active={true}
                                  // spinner={<BounceLoader />}
                                  spinner={true}
                                  text="Loading your content..."
                                ></LoadingOverlay>
                              </DarkBackground> */}
                            <Card style={{ minHeight: '80%' }}>
                              <CardHeader>
                                <h5 className="h3 mb-0">
                                  Latest notifications
                                  <span
                                    onClick={() => setIsOpen(false)}
                                    style={{
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    X
                                  </span>
                                </h5>
                              </CardHeader>
                              <CardBody>
                                <div
                                  className="timeline timeline-one-side"
                                  data-timeline-axis-style="dashed"
                                  data-timeline-content="axis"
                                >
                                  {comment.length > 0 ? (
                                    <>
                                      {comment.map((comt) => (
                                        <div className="timeline-block">
                                          <span className="timeline-step mt-2">
                                            <img
                                              ref={tooltipRef}
                                              width={'70px'}
                                              id={row.id}
                                              style={{
                                                borderRadius: '50%',
                                                padding: '5px',
                                                width: '55.80px',
                                                marginTop: '17px',
                                                // height: '51.85px',
                                              }}
                                              src={require(`../../assets/img/users/${comt.profilephoto}`)}
                                              alt="Profile"
                                            />
                                          </span>
                                          <div className="timeline-content">
                                            <div className="d-flex justify-content-between pt-1">
                                              <div>
                                                <span className="text-muted text-sm font-weight-bold">
                                                  {comt.name}
                                                </span>
                                              </div>
                                              <div className="text-right">
                                                <small className="text-muted">
                                                  <i className="fas fa-clock mr-1" />
                                                  {/* {new Date(comt.created_at)
                                                    .toLocaleString(undefined, {
                                                      dateStyle: 'long',
                                                      // day: 'numeric',
                                                      // month: 'long',
                                                      // year: 'numeric',
                                                    })
                                                    .toString()} */}
                                                  {moment(
                                                    comt.created_at
                                                  ).format(
                                                    'Do MMMM YYYY, h:mm a'
                                                  )}
                                                </small>
                                              </div>
                                            </div>
                                            <h6 className="text-sm mt-1 mb-0">
                                              <ShowMoreText
                                                lines={1}
                                                more="Show more"
                                                less="Show less"
                                                className="content-css"
                                                anchorClass="my-anchor-css-class"
                                                // onClick={
                                                //   executeOnClick
                                                // }
                                                expanded={false}
                                                width={400}
                                                truncatedEndingComponent={
                                                  '... '
                                                }
                                              >
                                                {comt.comments}
                                              </ShowMoreText>
                                            </h6>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <h1>No comments to show</h1>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </Modal>
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            </Row>
          </Container>
        </section>
      </>
    </>
  );
};

export default Userslist;

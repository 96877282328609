import { userConstants, UsercreateConstants } from '../_constants';
import { base64 } from '../_functions';

let user = localStorage.getItem('user');
let parseUser;
if(user){
  parseUser = JSON.parse(user);
}
const initialState = parseUser ? { loggedIn: true, parseUser } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        user: []
      };
    case UsercreateConstants.UPDATE_REDUX_USER_SUCCESS:
      if (action.user.profilephoto || action.user.name) {
        if (action.user.profilephoto)
          state.user.user.profilephoto = action.user.profilephoto;
        if (action.user.name)
          state.user.user.name = action.user.name;
        localStorage.setItem('user', JSON.stringify(state.user));
      }
      return {
        loggedIn: true,
        user: state.user
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
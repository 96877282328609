import { vehicleConstants } from '../_constants';

export function vehicle(state = {}, action) {
    switch (action.type) {
        case vehicleConstants.LIST_VEHICLE_REQUEST:
            return { listLoading: true };
        case vehicleConstants.LIST_VEHICLE_SUCCESS:
            return { list: action.vehicleList };
        case vehicleConstants.LIST_VEHICLE_FAILURE:
            return { listError: action.error };

        case vehicleConstants.INSERT_VEHICLE_REQUEST:
            return { insertLoading: true };
        case vehicleConstants.INSERT_VEHICLE_SUCCESS:
            return { insert: action.vehicleInsert };
        case vehicleConstants.INSERT_VEHICLE_FAILURE:
            return { insertError: action.error };

        case vehicleConstants.UPDATE_VEHICLE_REQUEST:
            return { updateLoading: true };
        case vehicleConstants.UPDATE_VEHICLE_SUCCESS:
            return { update: action.vehicleUpdate };
        case vehicleConstants.UPDATA_VEHICLE_FAILURE:
            return { updateError: action.error };

        case vehicleConstants.DELETE_VEHICLE_REQUEST:
            return { deleteLoading: true };
        case vehicleConstants.DELETE_VEHICLE_SUCCESS:
            return { delete: action.vehicleDelete };
        case vehicleConstants.DELETE_VEHICLE_FAILURE:
            return { deleteError: action.error };

        case vehicleConstants.RESTORE_VEHICLE_REQUEST:
            return { restoreLoading: true };
        case vehicleConstants.RESTORE_VEHICLE_SUCCESS:
            return { restore: action.vehicleRestore };
        case vehicleConstants.RESTORE_VEHICLE_FAILURE:
            return { restoreError: action.error };
        default:
            return state
    }
}

export function vehicleImages(state = {}, action) {
    switch (action.type) {
        case vehicleConstants.LIST_VEHICLE_IMAGES_REQUEST:
            return { listLoading: true };
        case vehicleConstants.LIST_VEHICLE_IMAGES_SUCCESS:
            return { list: action.vehicleImagesList };
        case vehicleConstants.LIST_VEHICLE_IMAGES_FAILURE:
            return { listError: action.error };

        case vehicleConstants.INSERT_VEHICLE_IMAGES_REQUEST:
            return { insertLoading: true };
        case vehicleConstants.INSERT_VEHICLE_IMAGES_SUCCESS:
            return { insert: action.vehicleImagesInsert };
        case vehicleConstants.INSERT_VEHICLE_IMAGES_FAILURE:
            return { insertError: action.error };

        case vehicleConstants.UPDATE_VEHICLE_IMAGES_REQUEST:
            return { updateLoading: true };
        case vehicleConstants.UPDATE_VEHICLE_IMAGES_SUCCESS:
            return { update: action.vehicleImagesUpdate };
        case vehicleConstants.UPDATA_VEHICLE_IMAGES_FAILURE:
            return { updateError: action.error };

        case vehicleConstants.DELETE_VEHICLE_IMAGES_REQUEST:
            return { deleteLoading: true };
        case vehicleConstants.DELETE_VEHICLE_IMAGES_SUCCESS:
            return { delete: action.vehicleImagesDelete };
        case vehicleConstants.DELETE_VEHICLE_IMAGES_FAILURE:
            return { deleteError: action.error };

        case vehicleConstants.RESTORE_VEHICLE_IMAGES_REQUEST:
            return { restoreLoading: true };
        case vehicleConstants.RESTORE_VEHICLE_IMAGES_SUCCESS:
            return { restore: action.vehicleImagesRestore };
        case vehicleConstants.RESTORE_VEHICLE_IMAGES_FAILURE:
            return { restoreError: action.error };
        default:
            return state
    }
}

export function vehicleAcInfo(state = {}, action) {
    switch (action.type) {
        case vehicleConstants.LIST_VEHICLE_ACINFO_REQUEST:
            return { listLoading: true };
        case vehicleConstants.LIST_VEHICLE_ACINFO_SUCCESS:
            return { list: action.vehicleAcInfoList };
        case vehicleConstants.LIST_VEHICLE_ACINFO_FAILURE:
            return { listError: action.error };

        case vehicleConstants.INSERT_VEHICLE_ACINFO_REQUEST:
            return { insertLoading: true };
        case vehicleConstants.INSERT_VEHICLE_ACINFO_SUCCESS:
            return { insert: action.vehicleAcInfoInsert };
        case vehicleConstants.INSERT_VEHICLE_ACINFO_FAILURE:
            return { insertError: action.error };

        case vehicleConstants.UPDATE_VEHICLE_ACINFO_REQUEST:
            return { updateLoading: true };
        case vehicleConstants.UPDATE_VEHICLE_ACINFO_SUCCESS:
            return { update: action.vehicleAcInfoUpdate };
        case vehicleConstants.UPDATA_VEHICLE_ACINFO_FAILURE:
            return { updateError: action.error };

        case vehicleConstants.DELETE_VEHICLE_ACINFO_REQUEST:
            return { deleteLoading: true };
        case vehicleConstants.DELETE_VEHICLE_ACINFO_SUCCESS:
            return { delete: action.vehicleAcInfoDelete };
        case vehicleConstants.DELETE_VEHICLE_ACINFO_FAILURE:
            return { deleteError: action.error };

        case vehicleConstants.RESTORE_VEHICLE_ACINFO_REQUEST:
            return { restoreLoading: true };
        case vehicleConstants.RESTORE_VEHICLE_ACINFO_SUCCESS:
            return { restore: action.vehicleAcInfoRestore };
        case vehicleConstants.RESTORE_VEHICLE_ACINFO_FAILURE:
            return { restoreError: action.error };
        default:
            return state
    }
}

export function vehicleNonAcInfo(state = {}, action) {
    switch (action.type) {
        case vehicleConstants.LIST_VEHICLE_NONACINFO_REQUEST:
            return { listLoading: true };
        case vehicleConstants.LIST_VEHICLE_NONACINFO_SUCCESS:
            return { list: action.vehicleNonAcInfoList };
        case vehicleConstants.LIST_VEHICLE_NONACINFO_FAILURE:
            return { listError: action.error };

        case vehicleConstants.INSERT_VEHICLE_NONACINFO_REQUEST:
            return { insertLoading: true };
        case vehicleConstants.INSERT_VEHICLE_NONACINFO_SUCCESS:
            return { insert: action.vehicleNonAcInfoInsert };
        case vehicleConstants.INSERT_VEHICLE_NONACINFO_FAILURE:
            return { insertError: action.error };

        case vehicleConstants.UPDATE_VEHICLE_NONACINFO_REQUEST:
            return { updateLoading: true };
        case vehicleConstants.UPDATE_VEHICLE_NONACINFO_SUCCESS:
            return { update: action.vehicleNonAcInfoUpdate };
        case vehicleConstants.UPDATA_VEHICLE_NONACINFO_FAILURE:
            return { updateError: action.error };

        case vehicleConstants.DELETE_VEHICLE_NONACINFO_REQUEST:
            return { deleteLoading: true };
        case vehicleConstants.DELETE_VEHICLE_NONACINFO_SUCCESS:
            return { delete: action.vehicleNonAcInfoDelete };
        case vehicleConstants.DELETE_VEHICLE_NONACINFO_FAILURE:
            return { deleteError: action.error };

        case vehicleConstants.RESTORE_VEHICLE_NONACINFO_REQUEST:
            return { restoreLoading: true };
        case vehicleConstants.RESTORE_VEHICLE_NONACINFO_SUCCESS:
            return { restore: action.vehicleNonAcInfoRestore };
        case vehicleConstants.RESTORE_VEHICLE_NONACINFO_FAILURE:
            return { restoreError: action.error };
        default:
            return state
    }
}


export function vehicleStages(state = {}, action) {
    switch (action.type) {
        case vehicleConstants.VEHICLE_STAGE_ONE_REQUEST:
            return { stage1Loading: true };
        case vehicleConstants.VEHICLE_STAGE_ONE_SUCCESS:
            return { stage1: action.vehicleSaveStage1 };
        case vehicleConstants.VEHICLE_STAGE_ONE_FAILURE:
            return { stage1Error: action.error };
        
        case vehicleConstants.VEHICLE_STAGE_TWO_REQUEST:
            return { stage2Loading: true };
        case vehicleConstants.VEHICLE_STAGE_TWO_SUCCESS:
            return { stage2: action.vehicleSaveStage2 };
        case vehicleConstants.VEHICLE_STAGE_TWO_FAILURE:
            return { stage2Error: action.error };
        
        case vehicleConstants.VEHICLE_STAGE_THREE_REQUEST:
            return { stage3Loading: true };
        case vehicleConstants.VEHICLE_STAGE_THREE_SUCCESS:
            return { stage3: action.vehicleSaveStage3 };
        case vehicleConstants.VEHICLE_STAGE_THREE_FAILURE:
            return { stage3Error: action.error };
        
        case vehicleConstants.VEHICLE_STAGE_FOUR_REQUEST:
            return { stage4Loading: true };
        case vehicleConstants.VEHICLE_STAGE_FOUR_SUCCESS:
            return { stage4: action.vehicleSaveStage4 };
        case vehicleConstants.VEHICLE_STAGE_FOUR_FAILURE:
            return { stage4Error: action.error };
        
        default:
            return state
    }
}

export function vehicleUpdateStages(state = {}, action) {
    switch (action.type) {
        case vehicleConstants.VEHICLE_UPDATE_STAGE_ONE_REQUEST:
            return { stage1Loading: true };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_ONE_SUCCESS:
            return { stage1: action.vehicleUpdateStage1 };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_ONE_FAILURE:
            return { stage1Error: action.error };
        
        case vehicleConstants.VEHICLE_UPDATE_STAGE_TWO_REQUEST:
            return { stage2Loading: true };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_TWO_SUCCESS:
            return { stage2: action.vehicleUpdateStage2 };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_TWO_FAILURE:
            return { stage2Error: action.error };
        
        case vehicleConstants.VEHICLE_UPDATE_STAGE_THREE_REQUEST:
            return { stage3Loading: true };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_THREE_SUCCESS:
            return { stage3: action.vehicleUpdateStage3 };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_THREE_FAILURE:
            return { stage3Error: action.error };
        
        case vehicleConstants.VEHICLE_UPDATE_STAGE_FOUR_REQUEST:
            return { stage4Loading: true };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_FOUR_SUCCESS:
            return { stage4: action.vehicleUpdateStage4 };
        case vehicleConstants.VEHICLE_UPDATE_STAGE_FOUR_FAILURE:
            return { stage4Error: action.error };
        
        default:
            return state
    }
}
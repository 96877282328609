import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Button, Container, Row, Col } from "reactstrap";

class TimelineHeader extends React.Component {
  goToUrl = (_e, _payload) => {
    // window.location.href = _url;
    _payload();
  }

  render() {
    const { buttons } = this.props;
    let buttonsDisp = [];
    {buttons && Object.keys(buttons).forEach(v => {
      buttonsDisp.push(<Button key={`button_${v}`} className="btn-neutral" color="default" size="sm" onClick={(e) => this.goToUrl(e, buttons[v])}> {v} </Button>);
    })}
    return (
      <>
        <div
          style={{ backgroundColor: '#fb6340' }}
          className="header header-dark pb-6 content__title content__title--calendar"
        >
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{' '}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    {this.props.name ? (
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <i className="fas fa-home" />
                        </a>
                      </BreadcrumbItem>
                    ) : (
                      ''
                    )}
                    {this.props.parentName ? (
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          {this.props.parentName}
                        </a>
                      </BreadcrumbItem>
                    ) : null}
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  {buttonsDisp}
                  {/* <Button className="btn-neutral" color="default" size="sm">
                    Filters
                  </Button> */}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  buttons: PropTypes.object
};

export default TimelineHeader;

import { userConstants } from '../_constants';

export function ticketStatus(state = {}, action) {
  switch (action.type){
    case userConstants.LIST_TICKET_STATUS_REQUEST:
      return {
        ticketStatus: action.ticketStatus
      }
    case userConstants.LIST_TICKET_STATUS_SUCCESS:
      return {
        ticketStatus: action.ticketStatus
      }
    case userConstants.LIST_TICKET_STATUS_FAILURE:
      return {
        ticketStatus: {}
      }
    default:
      return state
  }
}

export function ticketDelete(state = {}, action) {
  switch (action.type){
    case userConstants.DELETE_TICKET_REQUEST:
      return {
        ticketDelete: action.ticket
      }
    case userConstants.DELETE_TICKET_SUCCESS:
      return {
        ticketDelete: action.ticket
      }
    case userConstants.DELETE_TICKET_FAILURE:
      return {
        ticketDelete: {}
      }
    default:
      return state
  }
}

export function ticketGraph(state = {}, action){
  switch (action.type) {
    case userConstants.TICKET_MONTH_GRAPH_REQUEST:
      return { isLoading: true };
    case userConstants.TICKET_MONTH_GRAPH_SUCCESS:
      return { isLoading: false, ticketGraph: action.graph };
    case userConstants.TICKET_MONTH_GRAPH_FAILURE:
      return { isLoading: false, error: action.error };
    default:
      return state
  }
}

export function ticket(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_TICKET_REQUEST:
      return {
        ticket: action.ticket
      };
    case userConstants.CREATE_TICKET_SUCCESS:
      return {
        ticket: action.ticket
      };
    case userConstants.CREATE_TICKET_FAILURE:
      return {
        ticket: action
      };
    case userConstants.LIST_TICKETS_REQUEST:
      return {
        list: action.tickets
      }
    case userConstants.LIST_TICKETS_SUCCESS:
      return {
        list: action.tickets
      }
    case userConstants.LIST_TICKETS_FAILURE:
      return {
        list: {}
      }
    case userConstants.GET_TICKET_REQUEST:
      return {
        data: action.data
      }
    case userConstants.GET_TICKET_SUCCESS:
      return {
        data: action.data
      }
    case userConstants.GET_TICKET_FAILURE:
      return {
        data: {}
      }
    case userConstants.UPDATE_TICKET_REQUEST:
      return {
        data: action.data
      }
    case userConstants.UPDATE_TICKET_SUCCESS:
      return {
        data: action.data
      }
    case userConstants.UPDATE_TICKET_FAILURE:
      return {
        data: {}
      }
    default:
      return state
  }
}
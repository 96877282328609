export const _taskCreate=[{
    "name": "department",
    "label": "Department",
    "id": "department",
    "class": "form-control-sm",
    "state": "departmentState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The department field is Required.",
    "validFeedback": "",
    "bs4Size": "2",
    "type": "select",
    "multiple": false,
    "isReq":true,
    },
    {
    "name": "assigned_to",
    "label": "Assigned To",
    "id": "assigned_to",
    "class": "form-control-sm",
    "state": "assigned_toState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The assigned to field is Required.",
    "validFeedback": "",
    "bs4Size": "2",
    "type": "select",
    "multiple": false,
    "isReq":true,
},
{
    "name": "status",
    "label": "Status",
    "id": "status",
    "class": "form-control-sm",
    "state": "statusState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The status field is Required.",
    "validFeedback": "",
    "bs4Size": "2",
    "type": "select",
    "multiple": false,
    "isReq":true,
   
},
{
    "name": "reminder_date",
    "label": "Remainder Date",
    "id": "reminder_date",
    "class": "form-control-sm",
    "state": "reminder_dateState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "",
    "validFeedback": "",
    "bs4Size": "2",
    "type": "date",
    "isReq":false,
   
},
{
    "name": "description",
    "label": "Description",
    "id": "description",
    "class": "form-control-sm",
    "state": "descriptionState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The description field is Required.",
    "validFeedback": "",
    "bs4Size": "5",
    "type": "input",
    "isReq":true,
   
}
];

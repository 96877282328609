import React, { useEffect, useState } from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
// core components
import AuthHeader from 'components/Headers/AuthHeader.jsx';
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import axios from 'axios';
import { toast } from 'react-toastify';
// import {useDispatch} from "react-redux";
import { loginUser } from '../../../redux/authUser/auth.actions';
import { useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
// import {Link , useHistory} from 'react-router-dom';
// import {loginUser} from "../../../../redux/users/users.actions";

const Login = () => {
  var firebaseConfig = {
    apiKey: 'AIzaSyCKX-lnUjR2D_iAKpSLPWFksIc6drgOS6I',
    authDomain: 'push-notify-8d4d0.firebaseapp.com',
    projectId: 'push-notify-8d4d0',
    storageBucket: 'push-notify-8d4d0.appspot.com',
    messagingSenderId: '224308682995',
    appId: '1:224308682995:web:eeee7668cdef5746df9786',
  };

  // initializeApp(firebaseConfig);

  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  let dispatch = useDispatch();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);

  let history = useHistory();

  const [fcmToken, setFcmToken] = useState('');
  const getTokens = () => {
    getToken(messaging, {
      vapidKey:
        'BKvCkZVKh_4GS45ePbJy08KThrVOCy9D3B8O_1ipKxElWQuaGEo8IdoL8aD3pPZnvnhN2lGqz_o7tYZJFjW2CgI',
    })
      .then((currentToken) => {
        console.log('Login fcm_token token for client: ', currentToken);
        setFcmToken(currentToken);
        // return currentToken;
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  };

  useEffect(() => {
    getTokens();
    let temp = localStorage.getItem('user');
    if (temp) {
      history.push('/admin/enquiry');
    }
  }, []);

  let [user, setUser] = useState({
    email: '',
    password: '',
  });

  let [hidden, setHidden] = useState(true);

  let [userError, setUserError] = useState({
    emailError: '',
    passwordError: '',
  });

  let validateEmail = (event) => {
    setUser({ ...user, email: event.target.value });
    let regExp = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    !regExp.test(event.target.value)
      ? setUserError({ ...userError, emailError: 'Enter a proper Email' })
      : setUserError({ ...userError, emailError: '' });
  };

  let validatePassword = (event) => {
    setUser({ ...user, password: event.target.value });
    // let regExp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (event.target.value.length <= 0) {
      setUserError({
        ...userError,
        // passwordError:
        //   'It should have one uppercase, one lowercase, one number, one special character',
        passwordError:
          'Enter proper password and password field cannot be empty',
      });
    } else {
      setUserError({ ...userError, passwordError: '' });
    }
  };

  let handleEyeClick = (e) => {
    setHidden(!hidden);
  };

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);

  let submitLogin = async (event) => {
    try {
      event.preventDefault();
      if (
        user.email !== '' &&
        user.password !== '' &&
        userError.emailError === '' &&
        userError.passwordError === ''
      ) {
        console.log('stdOut+fcm_token', fcmToken);
        let sendUser = {
          email: user.email,
          password: user.password,
          fcmToken: fcmToken ? fcmToken : '',
          // fcmToken:  '',
        };
        dispatch(loginUser(sendUser, history));
      } else {
        toast('Could not submit with error fields!..');
        console.log('error occured');
      }
    } catch (error) {
      toast('Invalid Email or Password!..');
    }
  };

  return (
    <>
      <style>
        {
          '\
          .custom-control-alternative .custom-control-label::before {\
              border: 0 !important;\
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%); !important;\
    background-color : #adb5bd !important; \
          }'
        }
      </style>
      {/* <pre className="text-white">{JSON.stringify(user)}</pre> */}
      <AuthHeader
        title=""
        lead=""
        image={require('assets/img/theme/logo11.png')}
      />
      <Container className="mt--13 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <p>Sign in with credentials</p>
                </div>
                {/* {loginAlert ? (
                  <div className="alert alert-danger">
                    <p className="mb-0">
                      {loginAlert}{' '}
                      <span
                        className="cursor-pointer float-right"
                        onClick={() => this.setState({ loginAlert: '' })}
                      >
                        &times;
                      </span>
                    </p>
                  </div>
                ) : (
                  ''
                )} */}
                <Form role="form">
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        style={{ margin: '0px' }}
                        placeholder="Email"
                        name="email"
                        value={user.email}
                        onChange={validateEmail}
                        type="email"
                        className={`form-control ${
                          userError.emailError.length > 0 ? 'is-invalid' : ''
                        }`}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                      />
                    </InputGroup>
                    {userError.emailError.length > 0 ? (
                      <small className="text-danger">
                        {userError.emailError}
                      </small>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        style={{ margin: '0px' }}
                        placeholder="Password"
                        name="password"
                        value={user.password}
                        onChange={validatePassword}
                        type={hidden ? 'password' : 'text'}
                        className={`form-control ${
                          userError.passwordError.length > 0 ? 'is-invalid' : ''
                        }`}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          {hidden ? (
                            <i onClick={handleEyeClick} className="fa fa-eye" />
                          ) : (
                            <i
                              onClick={handleEyeClick}
                              className="fa fa-eye-slash"
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {userError.passwordError.length > 0 ? (
                      <small className="text-danger">
                        {userError.passwordError}
                      </small>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                  <Row className="mt-3">
                    <Col
                      xs="6"
                      className="custom-control custom-control-alternative custom-checkbox"
                    >
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Remember me</span>
                      </label>
                    </Col>
                    <Col xs="6" className="text-right">
                      {/* <Link to="/auth/forgot-password" className="text-light">
                        <small>Forgot password?</small>
                      </Link> */}
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="my-4 loginButton"
                      color="info"
                      type="submit"
                      onClick={submitLogin}
                    >
                      {' '}
                      Sign in{' '}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;

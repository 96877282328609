import { UsercreateConstants } from '../_constants';

export function usercreate(state = {}, action) {
    switch (action.type) {

        case UsercreateConstants.GET_USER_FIELDS_REQUEST:
            return {
              loading: true
            };
          case UsercreateConstants.GET_USER_FIELDS_SUCCESS:
           
            return {
              columns: action.columns
            };
          case UsercreateConstants.GET_USER_FIELDS_FAILURE:
            return { 
              error: action.error
            };

            case UsercreateConstants.CREATE_USER_REQUEST:
                return {
                  loading: true
                };
              case UsercreateConstants.CREATE_USER_SUCCESS:
                return {
                  items: action.user
                };
              case UsercreateConstants.CREATE_USER_FAILURE:
                return { 
                  error: action.error
                };
                case UsercreateConstants.GET_USER_REQUEST:
                  return{
                   users:action.userdata
                 };
                 case UsercreateConstants.GET_USER_SUCCESS:
                    return{
                      users:action.userdata
                   };
                 case UsercreateConstants.GET_USER_FAILURE:
                       return{
                         error: action.error
                   };

                   case UsercreateConstants.EDIT_USER_REQUEST:
                    return {
                      loading: true
                    };
                  case UsercreateConstants.EDIT_USER_SUCCESS:
                    return {
                      items: action.edituser
                    };
                  case UsercreateConstants.EDIT_USER_FAILURE:
                    return { 
                      error: action.error
                    };

                    case UsercreateConstants.GET_PRODEPNAME_REQUEST:
                      return{
                       userspro:action.userspro
                     };
                     case UsercreateConstants.GET_PRODEPNAME_SUCCESS:
                        return{
                          userspro:action.userspro
                       };
                     case UsercreateConstants.GET_PRODEPNAME_FAILURE:
                           return{
                             error: action.error
                       };
                       
                       case UsercreateConstants.GET_ROLE_REQUEST:
                        return{
                         usersrole:action.userrole
                       };
                       case UsercreateConstants.GET_ROLE_SUCCESS:
                          return{
                            usersrole:action.userrole
                         };
                       case UsercreateConstants.GET_ROLE_FAILURE:
                             return{
                               error: action.error
                         };
      

                case UsercreateConstants.DELETE_USER:
                  return{
                    wallet:action.user
                };

      default:
      return state
    }

}


export function userlist(state = {}, action) {
  switch (action.type) {
  case UsercreateConstants.LIST_USER_REQUEST:
   
    return{
     list:action.list
   };
   case UsercreateConstants.LIST_USER_SUCCESS:
     
      return{
        
       list:action.list
     };
     case UsercreateConstants.LIST_USER_FAILURE:
         return{
           error: action.error
       };
       default:
      return state
}
}
import { walletConstants } from '../_constants';
import { walletService } from '../_services';

export const walletActions = {
    createWallet,
    listWallet,
    GetWallet,
    deleteWallet,
    editWallet,
    listWalletlimit
};

function createWallet(data,listwallet){
    return async dispatch => {
        dispatch(request());
        await walletService.createWallet(data)

            .then(
                wallet => dispatch(success(wallet,listwallet)),
                error  => dispatch(failure(error))
            );
    };
    function request() { return { type: walletConstants.CREATE_WALLET_REQUEST } }
    function success(wallet,listwallet) {  
        listwallet["wallet"]=listwallet;
     return { type: walletConstants.CREATE_WALLET_SUCCESS, wallet } 
    }
    function failure(error) { 
        
        return { type: walletConstants.CREATE_WALLET_FAILURE, error } 
    }
}

function editWallet(data,walletlist1){
    return async dispatch => {
        dispatch(request());
       
        await walletService.editWallet(data)
        .then(
                wallet => dispatch(success(wallet,walletlist1)),
                error  => dispatch(failure(error))
            );
    };
    function request() { return { type: walletConstants.EDIT_WALLET_REQUEST } }

    function success(wallet,walletlist1) { 
       // walletlist1["wallet"]=walletlist1;
      
        return { type: walletConstants.EDIT_WALLET_SUCCESS, walletlist1 } }

    function failure(error) { return { type: walletConstants.EDIT_WALLET_FAILURE, error } }
}

function listWalletlimit(_currentPage, _searchText, _pageCount){
   
    return async dispatch => {
    dispatch(request());
       await walletService.listWalletlimit(_currentPage, _searchText, _pageCount)
          .then(list => {
               dispatch(success(list.data))
          })
          .catch(error => {
            failure(error);
          });
      };

  function request() {
   
    return { type: walletConstants.LIST_WALLET_REQUEST } }
    function success(list) {
   
         return { type: walletConstants.LIST_WALLET_SUCCESS, list } 
        }
    function failure(error) { return { type: walletConstants.LIST_WALLET_FAILURE },error }
}


function listWallet(){
    return async dispatch => {
    dispatch(request());
       await walletService.listWallet()
          .then(wallet => {
               dispatch(success(wallet.data))
          })
          .catch(error => {
            failure(error);
          });
      };

  function request() { return { type: walletConstants.LIST_WALLET_REQUEST } }
    function success(walletlist) {
       
         return { type: walletConstants.LIST_WALLET_SUCCESS, walletlist } 
        }
    function failure(error) { return { type: walletConstants.LIST_WALLET_FAILURE },error }
}

function GetWallet(id,datavalue){
  
    return async dispatch => {
    dispatch(request());
       await walletService.getList(id)
          .then(wallet => {
               dispatch(success(wallet.data,datavalue))
          })
          .catch(error => {
            failure(error);
          });
      };

  function request() { return { type: walletConstants.GET_WALLET_REQUEST } }
    function success(walletedit,datavalue) {
        datavalue["wallet"]=walletedit;
         return { type: walletConstants.GET_WALLET_SUCCESS,datavalue } 
        }
    function failure(error) { return { type: walletConstants.GET_WALLET_FAILURE },error }
}

function deleteWallet(id){
    return async dispatch => {
        
           await walletService.deleteWallet(id)
              .then(wallet => {
                   dispatch(success('success deleted'))
              });
                };
          function success() {
      
            return { type: walletConstants.DELETE_WALLET } 
           }
}
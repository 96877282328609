import React from "react";
import { connect } from 'react-redux';
// import Select, { components } from "react-select";
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import ReactDatetime from "react-datetime";
import Select1 from 'rc-select';
import Select, { components } from "react-select";
import moment from "moment";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Table,
  Col,
  Badge,
} from "reactstrap";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from 'react-router-dom';
import List from "list.js";
import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { _taskCreate } from '../../_data/taskCreate';
import { usercreateActions } from '../../_actions';
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';
import { userActions } from '../../_actions';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { reverse } from "dns";
const { SearchBar } = Search;

class Createtask extends React.Component {
  constructor(props) {
    super(props);

    let stater = {};
   stater.create = false;
    _taskCreate.forEach((v, i) => {
      stater[`${v.name}`] = '';
      stater[`${v.name}State`] = '';
    });
    this.state = {
      alert: null,
      loading: true,
      paginationList: [],
      _currentPage: 1,
      _pageCount: 10,
      _searchText: '',
      _recordsFiltered: null,
      taskCreation: stater,
      _taskCreateState: []
    };

  }

  cancel() {
    let newState = this.state.taskCreation;
    if(this.refs && this.refs.select_department)
      this.refs.select_department.select.clearValue();
    if(this.refs && this.refs.select_assigned_to)
      this.refs.select_assigned_to.select.clearValue();
    if(this.refs && this.refs.select_status)
      this.refs.select_status.select.clearValue();

    newState.create = true;
    Object.keys(newState).forEach((key) => {
      newState[`${key}State`] = '';
      newState[`${key}`] = '';
    });
    this.setState({
      editid: '',
      taskCreation: newState,
    });
  }

  componentWillUnmount(){
    document.querySelector('.navbar-nav .task-menu') && document.querySelector('.navbar-nav .task-menu .nav-link').classList.remove('active');
  }

componentDidMount() {
  document.querySelector('.navbar-nav .task-menu') && document.querySelector('.navbar-nav .task-menu .nav-link').classList.add('active');
    const {dispatch, match, authentication } = this.props;
    let { user } = authentication;
    const userrole = user && user.user.role;
    const name = userrole;
    const isAdmin = name.toString().trim().toLowerCase() === "admin";
    const isManager = name.toString().trim().toLowerCase() === "mananger";
    const isEmployee = name.toString().trim().toLowerCase() === "staff";
    if(!isAdmin){
      return this.props.history.push('/admin/dashboard');
    }

    const { _currentPage, _searchText, _pageCount } = this.state;

    if(match && match.params && match.params.id){
      var getid = match.params.id;
    }

    dispatch(userActions.listDepartment()).then(() => {
      var departmlist=this.props.department.department.data;  var obj = {};
      Object.keys(departmlist).forEach((key) => {
        obj[departmlist[key].code] = departmlist[key].deptname;
        this.setState({ deptlist:obj });
      });
    });

    dispatch(userActions.listStatus()).then(() => {
      var statusli=this.props.status.status.data;var obj = {};
      Object.keys(statusli).forEach((key) => {
        obj[statusli[key].id] = statusli[key].name;
        this.setState({ statusli:obj });
      });
    });

    dispatch(userActions.alluserslist()).then(() => {
      var alluserlist=this.props.alluserlist.list.data;var obj1 = {};
      Object.keys(alluserlist).forEach((key) => {
        obj1[alluserlist[key].id] = alluserlist[key].name;
        this.setState({ alluserlist:obj1 });
      });
    });

    dispatch(userActions.gettasklist(_currentPage, _searchText, _pageCount)).then(() => {
      var filter=this.props.tasklist.list.recordsFiltered;
      this.setState({ tasklist:this.props.tasklist, _recordsFiltered: filter, loading: false  });
    });
    this.setState({ _taskCreateState: _taskCreate, });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {

    const { status, assigned, department,dispatch,match } = this.props;
    const { loading, _currentPage, _searchText, _pageCount } = this.state;
    if(_currentPage != prevState._currentPage || _searchText != prevState._searchText || _pageCount != prevState._pageCount) {
       dispatch(userActions.gettasklist(_currentPage, _searchText, _pageCount)).then(() => {
        var filter=this.props.tasklist.list.recordsFiltered;

       this.setState({
          tasklist:this.props.tasklist,
          _recordsFiltered: filter,
          loading: false
        });
      });
    }
    if (this.props !== prevProps){
    _taskCreate[0].option = [];
    _taskCreate[1].option = [];
    _taskCreate[2].option = [];

    department && department.department && department.department.data && department.department.data.forEach((i, v) => {
        _taskCreate[0].option[v] = { value: i.code, label: i.deptname.trim(), deptcode: i.code.trim() };
      });
      status && status.status && status.status.data && status.status.data.forEach((i, v) => {
        _taskCreate[2].option[v] = { value: i.id, label: i.name.trim() };
      });


        var arrpush={ id:0};
       assigned && assigned.assigned && assigned.assigned.data &&  assigned.assigned.data.unshift(arrpush);

       assigned && assigned.assigned && assigned.assigned.data && assigned.assigned.data.forEach((i, v) => {
        if(i.id == 0){
         _taskCreate[1].option[0] = { value:'All',label:'All',badge:'' };
        }else{
          let dataOption = `${i.name.trim()}`;
        _taskCreate[1].option[v] = { value: i.id, label: dataOption, badge: `${i.ticketslist.length}` };
        }
      });

     this.setState({
        _taskCreateState: _taskCreate,
     });

    }

    if (this.state && this.state.editid) {
      if(prevState.editvalue != this.state.editvalue ){
         let newState = this.state.taskCreation;
         newState.create = true;
         const {editvalue} = this.state;
         newState.description=editvalue && editvalue[0] && editvalue[0].description;
         newState.reminder_date=editvalue && editvalue[0] && editvalue[0].date;
         if(editvalue[0] && editvalue[0].department &&  editvalue[0].department.code &&  editvalue[0].department.code!=''){
          if(this.refs &&  this.refs[`select_department`])
          {
           this.refs[`select_department`].select.setValue({
            value: editvalue[0].department.code ,
            label:  editvalue[0].department.deptname
          });
          }
        }

        if(editvalue[0] && editvalue[0].status &&  editvalue[0].status[0].id &&  editvalue[0].status[0].id!=''){
          if(this.refs &&  this.refs[`select_status`])
          {
           this.refs[`select_status`].select.setValue({
            value: editvalue[0].status[0].id,
            label:  editvalue[0].status[0].name
          });
          }
        }

      if(this.state && this.state.editstatus && this.state.editstatus == '1'){
          if(editvalue[0] && editvalue[0].users &&  editvalue[0].users.id && editvalue[0].users.id!=''){
            if(this.refs &&  this.refs[`select_assigned_to`])
            {
             this.refs[`select_assigned_to`].select.setValue({
              value:'All',
              label: 'All'
            });
            }
          }
       }else{
        if(editvalue[0] && editvalue[0].users &&  editvalue[0].users.id &&  editvalue[0].users.id!=''){
          if(this.refs &&  this.refs[`select_assigned_to`])
          {
           this.refs[`select_assigned_to`].select.setValue({
            value: editvalue[0].users.id ,
            label:  editvalue[0].users.name
          });
          }
        }
       }
         this.setState({
          taskCreation:newState
         });
       }
     }
  }

  onSelectChange = (value, idSelector) => {
    let sVal = null;
    let sValue = null;
    if(idSelector == "department"){
      if(value && value.deptcode){
        const { dispatch } = this.props;
        const { taskCreation } = this.state;

        this.refs.select_assigned_to.select.clearValue();

        taskCreation['departmentState'] = '';
        taskCreation['assigned_toState'] = '';
        this.setState({ taskCreation: taskCreation });
        dispatch(userActions.listDepartmentAssigned(value.deptcode));
      }
    }
    if(value){
        if(Array.isArray(value)){
          sVal = [];
          sValue = [];
          value.forEach((v, i) => { sVal.push(v.value); sValue[v.value] = v.label; });
        }else{
          sVal = '';
          sValue = '';
          sVal = value.value;
          sValue = value.label;
        }
      }
    let newStates = this.state.taskCreation;
    newStates[`${idSelector}`] = sVal;
    newStates[`${idSelector}Value`] = sValue;
    newStates[`${idSelector}State`] = 'is-invalid';
    this.setState({
        taskCreation: newStates
    });

    if(sVal != null && sVal != ''){
      newStates[`${idSelector}State`] = 'is-valid';
      this.setState({
        taskCreation: newStates
      });
    }
}

onDateChange = (value, idSelector) => {
  value = (value && value._d);
  let newStates = this.state.taskCreation;
  newStates[`${idSelector}`] = value;
  newStates[`${idSelector}State`] = 'invalid';
  this.setState({
    taskCreation: newStates
  });
  if(value){
    newStates[`${idSelector}State`] = 'valid';
    this.setState({
      taskCreation: newStates
    });
  }
};

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  confirmedAlert = (id,task_status,dept,task_id) => {
    const { dispatch } = this.props;
    const {_currentPage, _searchText, _pageCount }=this.state;
     dispatch(userActions.deletetask(id,task_status,dept,task_id)).then(() => {
      dispatch(userActions.gettasklist(_currentPage, _searchText, _pageCount)).then(() => {
       this.setState({
          tasklist: this.props.tasklist,
        _recordsFiltered:  this.props.tasklist.list.recordsFiltered,
         loading: false
        });

      });

    });

    var content = "Task has been deleted";
    this.setState({
      alert: (
        <ReactBSAlert
          success
          closeOnClickOutside={false}
          style={{ display: "block", marginTop: "-200px" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary btn-sm"
          confirmBtnText="Ok"
          btnSize=""
        >
          {content}
    </ReactBSAlert>
      )
    });
  };

  delete(id,task_status,dept,task_id) {
    var title = (<>Are you sure want to delete</>);
    var canceltext = "Yes, delete it!"
    var content = "You won't be able to revert this!";

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          closeOnClickOutside={false}
          showCancel
          style={{ display: "block", marginTop: "-200px" }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.confirmedAlert(id,task_status,dept,task_id)}
          showCancel
          confirmBtnBsStyle="secondary btn-sm"
          confirmBtnText="Cancel"
          cancelBtnBsStyle="danger btn-sm"
          cancelBtnText={canceltext}
          btnSize=""
        >
          {content}
        </ReactBSAlert>
      )
    });
  }

  notify(place, message, Title) {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {Title}
          </span>
          <br />
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: "default",
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  }


  onShowSizeChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
  }

  onChange(current, pageSize) {
    this.setState({ _pageCount: pageSize, loading: true, _currentPage: current });
  }


 searchDataPagination(e) {
    this.setState({ _searchText: e.target.value.trim(), loading: true, _currentPage: 1 });
  }

  validatetaskCreationForm = () => {
    const { dispatch,authentication } = this.props;
    let newState = this.state.taskCreation;
    newState.create = true;





 if(this.state.editid){

 var datas1={
      department_id:this.state.taskCreation.department,
      assigned_to:this.state.taskCreation.assigned_to,
      status:this.state.taskCreation.status,
      date: this.state.taskCreation.reminder_date ? moment(this.state.taskCreation.reminder_date).format("YYYY-MM-DD"): this.state.editvalue[0].date,
      description:this.state.taskCreation.description,
      user_id:authentication.user.user.id,
      task_id:this.state.task_id,
      count:this.state._pageCount,
      page:this.state._currentPage

    };
        dispatch(userActions.updatetask(datas1)).then(() => {

         if(this.props.taskcreate.error){
          let data =this.props.taskcreate.error.response.data;
          newState.create = true;
          Object.keys(data).forEach((key) => {
           if(key=='department_id'){
             key ='department';
            }
            if(key=='department' || key=='assigned_to' || key=='status'){
              newState[`${key}State`] = 'is-invalid';
            }else{
              newState[`${key}State`] = 'invalid';
            }
           newState[`${key}Feedback`] = data[key];
           newState.create = false;
          });
          var message = "Task Not updated";
          this.setState({
            editid: '',
            taskCreation: newState,
            loading: false
          });

         }else{
          var message = "Task Has Been Updated Successfully";
          if(this.refs && this.refs.select_department)
          this.refs.select_department.select.clearValue();
          if(this.refs && this.refs.select_assigned_to)
          this.refs.select_assigned_to.select.clearValue();
          if(this.refs && this.refs.select_status)
          this.refs.select_status.select.clearValue();

          let newState = this.state.taskCreation;
          newState.create = true;
          Object.keys(newState).forEach((key) => {
            newState[`${key}State`] = '';
            newState[`${key}`] = '';
          });


          this.setState({
            tasklist:this.props.taskcreate,
            _recordsFiltered:this.props.taskcreate.list.recordsFiltered,
             editid:''
          });
        }

        let Title = "Task Creation";
        this.notify("tc", message, Title);
        });
    }else{
      var datas={
        department_id:this.state.taskCreation.department,
        assigned_to:this.state.taskCreation.assigned_to,
        status:this.state.taskCreation.status,
        date: this.state.taskCreation.reminder_date ? this.state.taskCreation.reminder_date.toJSON().substring(0, 10) : '',
        description:this.state.taskCreation.description,
        user_id:authentication.user.user.id,
        count:this.state._pageCount,
        page:this.state._currentPage
      };

     dispatch(userActions.createtask(datas)).then(() => {
      let newState = this.state.taskCreation;
       if(this.props.taskcreate.error){
        let data =this.props.taskcreate.error.response.data;
        newState.create = true;
        Object.keys(data).forEach((key) => {
         if(key=='department_id'){
           key ='department';
          }
          if(key=='department' || key=='assigned_to' || key=='status'){
            newState[`${key}State`] = 'is-invalid';
          }else{
            newState[`${key}State`] = 'invalid';
          }
         newState[`${key}Feedback`] = data[key];
         newState.create = false;
        });
        var message = "Task Not Created";
        this.setState({
          editid: '',
          taskCreation: newState,
          loading: false
        });

       }else{
        var message = "Task Has Been Created Successfully";
        if(this.refs && this.refs.select_department)
        this.refs.select_department.select.clearValue();
        if(this.refs && this.refs.select_assigned_to)
        this.refs.select_assigned_to.select.clearValue();
        if(this.refs && this.refs.select_status)
        this.refs.select_status.select.clearValue();

        let newState = this.state.taskCreation;
        newState.create = true;
        Object.keys(newState).forEach((key) => {
          newState[`${key}State`] = '';
          newState[`${key}`] = '';
        });

      this.setState({
         tasklist:this.props.taskcreate,
        _recordsFiltered:this.props.taskcreate.list.recordsFiltered
           });
        let Title = "Task Creation";
        this.notify("tc", message, Title);

          }
      });
    }
}

  taskChange(e,stateName,isReq,invalidFeedback){
    let newState = this.state.taskCreation;
    newState[stateName] = e.target.value;
    newState[stateName + "State"] = "valid";

    if(e.target.value.trim() === "" && isReq) {
      newState[stateName + "State"] = "invalid";
      newState[stateName + "Feedback"]=invalidFeedback;
      newState.create = false;
    }

    this.setState({
      taskCreation: newState,
      });
  }

edit(id,status,department,task_id){
    let { dispatch } = this.props;
    dispatch(userActions.listDepartmentAssigned(department));
    dispatch(userActions.gettaskidlist(id)).then(() => {
      var data=this.props.taskcreate.tasks;
     this.setState({
      editstatus:status,
      editid: id,
      editvalue:data,
      task_id:task_id,
      loading: false,
       });
    });
 }
 validRemainder = (current) => {
  var today = moment();
  return current.isAfter( today );
 }

render() {
  const {loading,  _currentPage, _pageCount, _searchText, _recordsFiltered,tasklist}=this.state;
  const {authentication}=this.props;
    let recordsFiltered = 0;
    let count = _pageCount;
    let pageCount = 0;
    let currentPage = _currentPage;
    const listTable = [];
    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
          {children}
        </components.SingleValue>
      );

      const { Option } = components;
      const IconOption = (props) => {

        let iconimg = '';
        let bagde = '';
        let imgSrc = require("assets/img/theme/team-1.jpg");


        if(props.data.badge){
          iconimg = (<span className="mr-1"><img src={imgSrc} className="avatar rounded"/> </span>);
           bagde = (<span className="ml-1 badge badge-dark badge-pill text-white d-flex text-right">{props.data.badge}</span>);
        }
        return (
          <Option {...props} key={`${props.data.label}_${props.data.id}`} className="d-flex align-items-center">
            {iconimg}
            <span className="mx-1">{props.data.label}</span>
            {bagde}
          </Option>
        );
      }

      const pagination = (
      <div className="row col-12 m-0 p-0 paginationrow mb-3 d-flex justify-content-between">
        <div className="col-md-3 col-sm-12 align-items-center d-flex pl-0"><h3>Task List</h3></div>
        <div className="col-md-3 col-sm-12 align-items-center d-flex pl-0">
          <input type="text" value={_searchText} onChange={(e) => { this.searchDataPagination(e) }} className="form-control form-control-sm" placeholder="Search..." />
        </div>
        <div className="col-md-7 col-sm-12 align-items-center justify-content-center d-flex">
        </div>
      </div>
    );

    if(tasklist && tasklist.list && tasklist.list.data && tasklist.list.recordsFiltered) {

      recordsFiltered = tasklist.list.recordsFiltered;
      pageCount = Math.ceil(recordsFiltered / count);

    }
    let startVal = ((_currentPage * _pageCount) - (_pageCount - 1));

    tasklist && tasklist.list && tasklist.list.data && tasklist.list.data.forEach((v, i) => {
          listTable.push(
        <tr key={i}>
          <td className="p-1 text-left text-center id">{startVal + i}</td>
          <td className="p-1">{v.id}</td>
          <td className="p-1 ">{this.state && this.state.deptlist && this.state.deptlist[v.department_id]}</td>
          <td className="p-1 usernamee" title={this.state && this.state.alluserlist && this.state.alluserlist[v.users.id]}>{this.state && this.state.alluserlist && this.state.alluserlist[v.users.id]}</td>
          <td className="p-1 ">{this.state && this.state.statusli && this.state.statusli[v.status[0].id]}</td>
          <td className="p-1 ">{v.date}</td>
          <td className="p-1 descc" title={v.description}>{v.description}</td>
          <td className="p-1 text-right">
      <Button className="btn btn-sm btn-success" size="sm" onClick={() => this.edit(v.id,v.task_status,v.department_id,v.task_id)}>
               <i className="fas fa-pencil-alt"></i></Button>
             <Button color="danger" size="sm" onClick={() => this.delete(v.id,v.task_status,v.department_id,v.task_id)}><i className="fas fa-trash-alt"></i></Button>

          </td>
        </tr>
      );
    });

    return (
      <>
        {this.state.alert}
        <NotificationAlert ref="notificationAlert" />
        {(loading) ? (<i className="fas fa-circle-notch fa-spin display-3 sticky-top float-left" style={{ margin: '0.5rem 1rem', marginTop: -70 }}></i>) : ''}
        <SimpleHeader name="Manage Task" parentName="Task" />

        <Container className="mt--6" fluid>
          <Row>
            <div className="col-md-3 px-1">
              <div className="card-wrapper">

                <Card>
                  <CardHeader><h3>Edit / Create Task</h3></CardHeader>
                  <CardBody>
                 <Form className="needs-validation" ref="task" noValidate onSubmit={this.validatetaskCreationForm}>
                      <div className="row mc-minimize mb-3" >
                            {this.state && this.state._taskCreateState && this.state._taskCreateState.map((v, i) => {
                                let styleData = {};
                                if(v.name === "description"){ styleData = { height: 60}; }
                                return (
                                <Col key={i} className="mb-2" md={12}>
                                    <label className="form-control-label " htmlFor={v.name} > {v.label} </label>

                                    {(v.type == "select" && v.multiple === false) ?
                                   (<><Select ref={`select_${v.name}`} id={v.name}
                                    className={`form-control form-control-sm p-0 ${(this.state.taskCreation[v.state])}`} isClearable
                                    placeholder={v.placeholder}
                                    styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }}
                                    components={{ Option: IconOption }} isSearchable name={v.name} options={v.option}
                                    onChange={(value) => { this.onSelectChange(value, v.name) }} /></>) : ''}

                              {/* {(v.type == "select" && v.multiple === true) ? (<><Select ref={`select_${v.name}`} id={v.name}
                              className={`form-control form-control-sm p-0 ${(this.state.taskCreation[v.state])}`}
                              isClearable placeholder={v.placeholder}
                              styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }}
                              components={{ Option: IconOption }}
                              isSearchable isMulti={v.multiple} name={v.name} options={v.option}
                              onChange={(value) => { this.onSelectChange(value, v.name) }} /></>) : ''} */}

                                {(v.type == "date") ?
                                  <ReactDatetime  className={`form-control form-control-sm p-0 h-auto
                                  ${(this.state.taskCreation[v.state] === 'valid') ? 'is-valid' : ((this.state.taskCreation[v.state] === 'invalid')) ? 'is-invalid': '' }`}
                                    value={this.state.taskCreation[v.name] || ""}
                                    isValidDate={this.validRemainder}
                                    inputProps={{ placeholder: v.placeholder, className: `form-control form-control-sm border-0`, readOnly: true }}
                                    timeFormat={false}  onChange={value => this.onDateChange(value, v.name)  }

                                      /> : ''}

   {/* //  h-100 */}
                                    {(v.type == "input") ?
                                    (<><Input id={v.name} style={ styleData } className="form-control-sm" placeholder={v.label} type="text" valid={this.state.taskCreation[v.state] === v.valid} invalid={this.state.taskCreation[v.state] === v.invalid} value={this.state.taskCreation[v.name] || ""} onChange={e => this.taskChange(e, v.name,v.isReq,v.invalidFeedback)} /></>) : ''}

                                    <div className="valid-feedback"></div>
                                    <div className="invalid-feedback"> {(this.state.taskCreation[`${v.name}Feedback`]) ? this.state.taskCreation[`${v.name}Feedback`] : v.invalidFeedback} </div>
                                </Col>
                                )
                            })}


                        <Col md="12">
                          <div className="d-flex justify-content-start">
                              <Button
                                className="mt-1 form-control-sm mr-1"
                                color="primary"
                                type="button" size="sm" onClick={this.validatetaskCreationForm}>
                             {(this.state && this.state.editid) ? 'Update' :'Create'}
                              </Button>
                              <Button
                                className="mt-1 form-control-sm"
                                color="warning"
                                type="button" size="sm" onClick={() => this.cancel()}>
                                Cancel
                              </Button>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                </div>
                </div>

            <div className="col-md-9 px-1">
              <div className="card-wrapper">

                <Card body className="pb-5">
                {pagination}
                  <div className="table-responsive" ref="">

                    <Table hover className="align-items-center table-flush wallettable">
                      <thead className="thead-light">
                        <tr>
                          <th className="sort px-1 py-2 text-center"  data-sort="" scope="col">
                            S.No
                </th>
                          <th className="sort px-1 py-2 " data-sort=" " scope="col">
                            Task Id
                </th>
                          <th className="sort px-1 py-2 "   data-sort="" scope="col">
                            Department
                  </th>
                          {/* <th className="sort px-1 py-2 " data-sort="" scope="col">
                           Assigned To
                 </th> */}
                 <th className="sort px-1 py-2" data-sort="" scope="col">
                           Status
                 </th>
                 <th className="sort px-1 py-2" data-sort="" scope="col">
                           Remainder Date
                            </th>
                 <th className="sort px-1 py-2 descc" data-sort="" scope="col">
                           Description
                 </th>
                 {/* {(authentication && authentication.userrole &&  authentication.userrole.role */}
         {/* && authentication.userrole.role.name  */}
         {/* && authentication.userrole.role.name.toString().toLowerCase() == "admin") */}
             {/* ? (<th className="text-right">Action</th>): ""} */}
             <th className="text-right">Action</th>

                        </tr>
                      </thead>
                      <tbody className="list">
                      {listTable.length > 0 && listTable || ((loading) ? (<tr><td colSpan="9" className="text-center">Processing..</td></tr>) : (<tr><td colSpan="9" className="text-center">No Rows Found</td></tr>))}
                      </tbody>
                    </Table>
               </div>
                  <div className="row paginationrow col p-0 m-0 mt-4 d-flex justify-content-left align-items-left">
                <Pagination
                  selectComponentClass={Select1}
                  showSizeChanger
                  defaultPageSize={_pageCount}
                  defaultCurrent={_currentPage}
                  onShowSizeChange={(current, pageSize) => this.onShowSizeChange(current, pageSize)}
                  onChange={(current, pageSize) => this.onChange(current, pageSize)}
                  total={_recordsFiltered}
                  locale={localeInfo}
                />
              </div>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { status, assigned, department,tasklist,
    taskcreate,alluserlist,authentication } = state;
  return {
    status, assigned, department,tasklist,
    taskcreate,alluserlist,authentication
  };
}

const connectedCreatetask = connect(mapStateToProps)(Createtask);
export { connectedCreatetask as Createtask };
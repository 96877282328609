import { userConstants } from '../_constants';

export function pettycreate(state = {}, action) {
    switch (action.type) {
 
            case userConstants.CREATE_PETTYCASH_REQUEST:
                return {
                  loading: true
                };
              case userConstants.CREATE_PETTYCASH_SUCCESS:
                return {
                    list: action.pettycash
                };
              case userConstants.CREATE_PETTYCASH_FAILURE:
                return { 
                  error: action.error
                };
                
                case userConstants.GET_PETTYCASH_REQUEST:
                  return{
                    pettycash:action.idlist
                 };
                 case userConstants.GET_PETTYCASH_SUCCESS:
                    return{
                        pettycash:action.idlist
                   };
                 case userConstants.GET_PETTYCASH_FAILURE:
                       return{
                         error: action.error
                   };

                   case userConstants.UPDATE_PETTYCASH_REQUEST:
                    return {
                      loading: true
                    };
                  case userConstants.UPDATE_PETTYCASH_SUCCESS:
                    return {
                        list: action.pettycash
                    };
                  case userConstants.UPDATE_PETTYCASH_FAILURE:
                    return { 
                      error: action.error
                    };
              case userConstants.DELETE_PETTYCASH_SUCCESS:
                  return{
                    pettycash:action.pettycash
                };
                case userConstants.DELETE_PETTYCASH_IMG_SUCCESS:
                  return{
                    pettycash:action.pettycash
                };
                case userConstants.CREATE_PETTYCASH_IMG_REQUEST:
                    return {
                      loading: true
                    };
                  case userConstants.CREATE_PETTYCASH_IMG_SUCCESS:
                    return {
                      list: action.data
                    };
                  case userConstants.CREATE_PETTYCASH_IMG_FAILURE:
                    return { 
                      error: action.error
                    };
                    case userConstants.UPDATE_PETTYCASH_IMG_REQUEST:
                        return {
                          loading: true
                        };
                      case userConstants.UPDATE_PETTYCASH_IMG_SUCCESS:
                        return {
                            list: action.pettycash
                        };
                      case userConstants.UPDATE_PETTYCASH_IMG_FAILURE:
                        return { 
                          error: action.error
                        };

                        case userConstants.CREATE_TRACK_REQUEST:
                          return {
                            loading: true
                          };
                        case userConstants.CREATE_TRACK_SUCCESS:
                          return {
                              track: action.track
                          };
                        case userConstants.CREATE_TRACK_FAILURE:
                          return { 
                            error: action.error
                          };

                          case userConstants.GET_PETTYCASH_MAX_BAL_REQUEST:
                            return{
                              maxbal:action
                           };
                           case userConstants.GET_PETTYCASH_MAX_BAL_SUCCESS:
                              return{
                                maxbal:action.maxbal
                             };
                           case userConstants.GET_PETTYCASH_MAX_BAL_FAILURE:
                                 return{
                                   error: action.error
                             };
         default:
      return state
    }
}

export function pettylist(state = {}, action) {
  switch (action.type) {
  case userConstants.LIST_PETTYCASH_REQUEST:
      return{
     list:action.list
   };
   case userConstants.LIST_PETTYCASH_SUCCESS:
        return{
        
       list:action.list
     };
     case userConstants.LIST_PETTYCASH_FAILURE:
         return{
           error: action.error
       };
       default:
      return state
}
}


import React, { Component } from 'react';
import { Button, Modal, Row, Col, Input, Card, CardHeader, CardBody, Collapse, Form, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import moment, { isDate } from "moment";
import ReactDatetime from "react-datetime";
import { connect } from 'react-redux';
import { userActions, serviceActions } from '../../_actions';
import { loadingButton, removeLoadingButton, addInvalid, removeInvalid } from '../../_functions';
import Select from "react-select";
import { _customerCreate } from '../../_data/customerCreate';
import classnames from "classnames";
import { isArray, isObject } from 'util';//, isImage
import isImage from "is-image";
import { Autocomplete } from '../../_components/common/autoComplete';

import { globalConstants } from '../../_constants';

class ModalFull extends Component{
    constructor(props){
        super(props);
        this.state = {
            left: 0,
            search: '',
            searchFocussed: false,
            tGrpList: [],
            doc_type: '',
            doc_typeList: {},
        };
    }
    
    onNextClick() {
        const { currCount, memCount, incrementCurrCount } = this.props;
        if(memCount > currCount){
            incrementCurrCount();
            this.setState({ left: `-${currCount * 100}%` });
        }
    }
    
    onPrevClick() {
        const { currCount, decrementCurrCount } = this.props;
        if(1 < currCount){
            decrementCurrCount();
            this.setState({ left: `-${(currCount - 2) * 100}%` });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        // billingFull[ii] &&billingFull[ii][`paid`]
        const { currCount, tourGroupsList, setStater, billingFull, parserFloat } = this.props;
        const { tGrpList, activeTabFullModal, tour_fullModal_ActiveTabFullModal, doc_typeList } = this.state;
        if(prevProps.currCount != currCount && currCount === 1){ this.setState({ left: `0` }); }
        if(prevProps.tourGroupsList != tourGroupsList && tourGroupsList.data && tourGroupsList.data.data && isArray(tourGroupsList.data.data)){
            if(tourGroupsList.data.data){
                tourGroupsList.data.data.forEach((v, i) => {
                    if(v && v.customers && v.customers[0] && v.customers[0].visa && v.customers[0].visa.id){
                        if(v.customers[0].visa.visa_type_id && v.customers[0].visa.visa_type){
                            tourGroupsList.data.data[i].customers[0].visa.visa_type_idVal = { label: v.customers[0].visa.visa_type.name, value: v.customers[0].visa.visa_type.id };
                        }
                        if(v.customers[0].visa.filed_by === "e-route"){
                            tourGroupsList.data.data[i].customers[0].visa.filed_byVal = { label: "E-Route", value: "e-route" };
                        }else if(v.customers[0].visa.filed_by !== "" && v.customers[0].visa.vendor_id){
                            tourGroupsList.data.data[i].customers[0].visa.filed_byVal = { label: v.customers[0].visa.vendor_id.name, value: v.customers[0].visa.vendor_id.id };
                        }
                    }
                });
            }
            this.setState({ tGrpList: tourGroupsList.data.data });
        }

        if((prevState.tGrpList.passport != tGrpList.passport) || (prevState.tGrpList.visa != tGrpList.visa)){
            this.setState({ tGrpList });
        }

        if(prevState.tGrpList != tGrpList){
            let billingFull = (billingFull) ? billingFull : {};
            tGrpList.forEach((v, i) => {
                if(v.billing && isArray(v.billing) && v.billing.length > 0){
                    billingFull[i] = (billingFull[i]) ? billingFull[i] : {};
                    billingFull[i]['id'] = (billingFull[i]['id']) ? billingFull[i]['id'] : v.billing[0].id;
                    billingFull[i]['others'] = (billingFull[i]['others']) ? billingFull[i]['others'] : v.billing[0].other_amount;
                    billingFull[i]['price'] = (billingFull[i]['price']) ? billingFull[i]['price'] : v.billing[0].package_amount;
                    billingFull[i]['service'] = (billingFull[i]['service']) ? billingFull[i]['service'] : v.billing[0].service_charge;
                    billingFull[i]['paid'] = (billingFull[i]['paid']) ? billingFull[i]['paid'] : v.billing[0].amount_received;
                    // billingFull[i]['o_price'] = (billingFull[i]['o_price']) ? billingFull[i]['o_price'] : v.billing[0].package_amount;
                    billingFull[i]['service_tax'] = (billingFull[i]['service_tax']) ? billingFull[i]['service_tax'] : v.billing[0].service_tax_amount;
                    billingFull[i]['bal'] = billingFull[i]['price'] - billingFull[i]['paid'];
                    billingFull[i]['gst_amount'] = 0;//(billingFull[i]['gst_amount']) ? billingFull[i]['gst_amount'] : v.billing[0].gst_amount;
                    billingFull[i]['e_paid_amount'] = (billingFull[i]['e_paid_amount']) ? billingFull[i]['e_paid_amount'] : v.billing[0].e_paid_amount;
                    
                    billingFull[i]['o_price'] = billingFull[i]['price'] - billingFull[i]['others'] - billingFull[i]['service'] - billingFull[i]['service_tax'];
                    billingFull[i]['o_price'] = parseFloat(billingFull[i]['o_price']).toFixed(2);
                }
                if(v.documents && isArray(v.documents) && v.documents.length > 0){
                    v.documents.forEach((vv, i) => {
                        doc_typeList[v.customer_id] = (doc_typeList[v.customer_id]) ? doc_typeList[v.customer_id] : {};
                        doc_typeList[v.customer_id][vv.document_id] = (doc_typeList[v.customer_id][vv.document_id]) ? doc_typeList[v.customer_id][vv.document_id] : (vv.document_names.length > 0) ? {
                            label: vv.document_names[0].document_type,
                            value: vv.document_id,
                            document_id: vv.id,
                            doc_name: vv.document_name
                        } : '';
                    });
                }
            });
            this.setState({ doc_typeList: doc_typeList });
            setStater(`billingFull`, billingFull);
        }
    }

    inputChange(e, name, isReq, regex, regexInvalid, ii, data){
        let { tGrpList } = (this.state.tGrpList) ? this.state : { tGrpList: {} };
        tGrpList[ii] = (tGrpList[ii]) ? tGrpList[ii] : {};
        tGrpList[ii]['customers'] = (tGrpList[ii]['customers']) ? tGrpList[ii]['customers'] : {};
        tGrpList[ii]['customers'][0] = (tGrpList[ii]['customers'][0]) ? tGrpList[ii]['customers'][0] : {};
        tGrpList[ii]['customers'][0][`${name}`] = e.target.value;
        tGrpList[ii]['customers'][0][data.state] = data.valid;
        tGrpList[ii]['customers'][0][`${name}Feedback`] = '';
        if(isReq && e.target.value.trim() === ''){
            tGrpList[ii]['customers'][0][data.state] = data.invalid;
            tGrpList[ii]['customers'][0][`${name}Feedback`] = data.invalidFeedback;
        }else if(regex){
            let regexp = new RegExp(regex);
            if(!regexp.test(e.target.value)){
                tGrpList[ii]['customers'][0][data.state] = data.invalid;
                tGrpList[ii]['customers'][0][`${name}Feedback`] = regexInvalid;
            }
        }
        this.setState({ tGrpList : tGrpList });
    }

    onDateChange(value, name, isReq, ii){
        let { tGrpList } = (this.state.tGrpList) ? this.state : { tGrpList: {} };
        tGrpList[ii] = (tGrpList[ii]) ? tGrpList[ii] : {};
        tGrpList[ii]['customers'] = (tGrpList[ii]['customers']) ? tGrpList[ii]['customers'] : {};
        tGrpList[ii]['customers'][0] = (tGrpList[ii]['customers'][0]) ? tGrpList[ii]['customers'][0] : {};
        tGrpList[ii]['customers'][0][`${name}`] = moment(value).format("YYYY-MM-DD");
        this.setState({ tGrpList : tGrpList });
    }

    onSelectChange(value, stateName, customer_id) {
        let states = this.state;
        states[`${stateName}`] = (states[`${stateName}`]) ? states[`${stateName}`] : {};
        states[`${stateName}`][`${customer_id}`] = value;
        this.setState({ states });
    }

    addDocType(e, stateName, customer_id) {
        let states = this.state;
        states[`${stateName}List`][`${customer_id}`] = (states[`${stateName}List`][`${customer_id}`]) ? states[`${stateName}List`][`${customer_id}`] : {};
        states[`${stateName}List`][`${customer_id}`][states[`${stateName}`][`${customer_id}`]['value']] = states[`${stateName}`][`${customer_id}`];
        this.setState({ states });
    }

    onSaveItem(e, unit){
        let elem = e.target;
        loadingButton(elem);
        const { currCount, memCount, tourId, groupConcatDetails, groupDetails, grpId, notify, setStater, parserFloat } = this.props;
        // let currentCustomer = document.querySelector(`.customer_${unit}`);
        let group_id = grpId;//document.querySelector(`.group_id_${grpId}`);
        let customer_id = document.querySelector(`.customer_id_${currCount - 1}`);
        let firstname = document.querySelector(`.firstname_${currCount - 1}`);
        let lastname = document.querySelector(`.lastname_${currCount - 1}`);
        let email = document.querySelector(`.email_${currCount - 1}`);
        let phone = document.querySelector(`.phone_${currCount - 1}`);
        let dob = document.querySelector(`.dob_${currCount - 1} input`);
        let address = document.querySelector(`.address_${currCount - 1}`);
        let state = document.querySelector(`.state_${currCount - 1}`);
        let city = document.querySelector(`.city_${currCount - 1}`);
        let zipcode = document.querySelector(`.zipcode_${currCount - 1}`);
        
        removeInvalid(firstname);
        removeInvalid(email);
        removeInvalid(phone);

        if(firstname.value !== "" && email.value !== "" && phone.value !== ""){
            // do regex validation
            let customerData = { "firstname": firstname.value, "lastname": lastname.value, "email": email.value, "phone": phone.value, "secondary": "".value, "address": address.value, "state": state.value, "city": city.value, "dob": dob.value, "zipcode": zipcode.value };
            const { dispatch } = this.props;
            if(customer_id.value !== ""){
                customerData["id"] = customer_id.value;
                let tourGroupCusRelId = document.querySelector(`.tour_group_cus_id_${customer_id.value}_${grpId}`);
                if(tourGroupCusRelId.value === ''){
                    let tourGroupsInsertData = { "customer_id": customer_id.value, "group_id": group_id, "tour_id": tourId };
                    dispatch(userActions.tourGroupsInsert(tourGroupsInsertData)).then(() => {
                        const { tourGroupsInsert } = this.props;
                        const { doc_typeList, tGrpList } = this.state;
                        if(tourGroupsInsert.data.tour_groups.id){
                            tourGroupCusRelId.value = tourGroupsInsert.data.tour_groups.id;
                            tGrpList[currCount-1].id = tourGroupsInsert.data.tour_groups.id;
                            this.setState({ tGrpList: tGrpList });
                            dispatch(userActions.updateCustomer(customerData)).then(() => {
                                const { billingFull, ticket_id } = this.props;
                                const { doc_typeList, tGrpList } = this.state;
                                if(Object.keys(billingFull).length > 0){
                                    let i = currCount - 1;
                                    let gstAmtTmp = billingFull[i] && billingFull[i][`gst_amount`] || 0;
                                    let billingData = {
                                        'ticket_id': ticket_id,
                                        'service_id': 1,
                                        'customer_id': customer_id.value,
                                        'package_amount': (billingFull[i] && (parserFloat(billingFull[i][`price`]) + parserFloat(gstAmtTmp))) || '',
                                        'service_charge': billingFull[i] && billingFull[i][`service`] || '',
                                        'service_tax_amount': billingFull[i] && billingFull[i][`service_tax`] || '',
                                        'gst_amount': parserFloat(gstAmtTmp),
                                        'name': billingFull[i] && billingFull[i][`invoice_buyer_name`] || '',
                                        'amount_received': billingFull[i] && billingFull[i][`paid`] || '',
                                        'amount_pending': billingFull[i] && billingFull[i][`bal`] || '',
                                        'e_mop': billingFull[i] && billingFull[i][`mop`] || '',
                                        'e_paid_amount': billingFull[i] && billingFull[i][`e_paid_amount`] || 0, //e_paid_amount
                                        'invoice_to': billingFull[i] && billingFull[i][`invoice_toData`] && billingFull[i][`invoice_toData`].value || '',
                                        'amount_paid_by': billingFull[i] && billingFull[i][`amount_paid_byData`] && billingFull[i][`amount_paid_byData`].value || '',
                                        'vendor_name': billingFull[i] && !isArray(billingFull[i][`vendor_name`]) && billingFull[i][`vendor_name`] || '',
                                        'invoice_buyer_name': billingFull[i] && billingFull[i][`invoice_buyer_name`] || '',
                                        'other_amount': billingFull[i] && billingFull[i][`others`] || ''
                                    };
                                    
                                    if(billingFull[i] && billingFull[i]['id']){
                                        billingData['id'] = billingFull[i]['id'];
                                        dispatch(userActions.updateBilling(billingData)).then(() => {
                                            if(!doc_typeList[customer_id.value] || Object.keys(doc_typeList[customer_id.value]).length === 0)
                                                removeLoadingButton(elem, "Save");
                                            notify("tc", "", "Customer details has been updated successfully.");
                                        });
                                    }else{
                                        dispatch(userActions.createBilling(billingData)).then(() => {
                                            if(!doc_typeList[customer_id.value] || Object.keys(doc_typeList[customer_id.value]).length === 0)
                                                removeLoadingButton(elem, "Save");
                                            const { billing } = this.props;
                                            const { document } = billing;
                                            const { data } = document;
                                            const { id } = data;
                                            billingFull[i]['id'] = id;
                                            setStater(`billingFull`, billingFull);
                                            notify("tc", "", "Customer details has been updated successfully.");
                                        });
                                    }
                                }
                                
                                    
                                if(Object.keys(doc_typeList[customer_id.value]).length > 0){
                                    Object.keys(doc_typeList[customer_id.value]).forEach((key) => {
                                        if(doc_typeList[customer_id.value][key].files){
                                        const docData = new FormData();
                                        docData.append('document_name',doc_typeList[customer_id.value][key].files);
                                        docData.append('customer_id',customer_id.value);
                                        docData.append('document_id',key);
                                        if(doc_typeList[customer_id.value][key].document_id){
                                            docData.append('id',doc_typeList[customer_id.value][key].document_id);
                                            dispatch(userActions.updateUploadDocument(docData)).then(() => {
                                                if(!tGrpList[currCount - 1].customers[0].passport || (tGrpList[currCount - 1].customers[0].passport && Object.keys(tGrpList[currCount - 1].customers[0].passport).length === 0))
                                                    removeLoadingButton(elem, "Save");
                                                notify("tc", "", "Customer details has been updated successfully.");
                                            });
                                        } else {
                                            dispatch(userActions.uploadDocument(docData)).then(() => {
                                                const { document } = this.props;
                                                const { data } = document.document;
                                                const { id } = data;
        
                                                doc_typeList[customer_id.value][key].document_id = id;
                                                this.setState({ doc_typeList: doc_typeList });
                                                
                                                if(!tGrpList[currCount - 1].customers[0].passport || (tGrpList[currCount - 1].customers[0].passport && Object.keys(tGrpList[currCount - 1].customers[0].passport).length === 0))
                                                    removeLoadingButton(elem, "Save");
                                                notify("tc", "", "Customer details has been updated successfully.");
                                            });
                                        }
                                        }
                                    });
                                }
                                
                                if(tGrpList[currCount - 1].customers[0].passport && Object.keys(tGrpList[currCount - 1].customers[0].passport).length > 0){
                                    let tempPassportNo = document.querySelector(`#passport_modal_full_passport_no_${currCount - 1}`);
                                    let tempNationality = document.querySelector(`#passport_modal_full_nationality_${currCount - 1}`);
                                    let tempPlaceOfBirth = document.querySelector(`#passport_modal_full_place_of_birth_${currCount - 1}`);
                                    let tempPlaceOfIssue = document.querySelector(`#passport_modal_full_place_of_issue_${currCount - 1}`);
                                    let tempDateOfIssue = document.querySelector(`#passport_modal_full_date_of_issue_${currCount - 1}`);
                                    let tempDateOfExpiry = document.querySelector(`#passport_modal_full_date_of_expiry_${currCount - 1}`);
        
                                    removeInvalid(tempPassportNo);
                                    removeInvalid(tempNationality);
                                    removeInvalid(tempPlaceOfBirth);
                                    removeInvalid(tempPlaceOfIssue);
                                    removeInvalid(tempDateOfIssue);
                                    removeInvalid(tempDateOfExpiry);
        
                                    if(tempPassportNo.value !== "" && tempNationality.value !== "" && tempPlaceOfBirth.value !== "" && tempPlaceOfIssue.value !== "" && tempDateOfIssue.value !== "" && tempDateOfExpiry.value !== ""){
                                        let alphabets_and_numbers = new RegExp(globalConstants.REGEX.alphabets_and_numbers);
                                        let only_alphabets = new RegExp(globalConstants.REGEX.only_alphabets);
                                        if(!alphabets_and_numbers.test(tempPassportNo.value) || !only_alphabets.test(tempNationality.value) || !only_alphabets.test(tempPlaceOfBirth.value) || !only_alphabets.test(tempPlaceOfIssue.value)){
                                            removeLoadingButton(elem, "Save");
                                            if(!alphabets_and_numbers.test(tempPassportNo.value))
                                                addInvalid(tempPassportNo);
                                            if(!only_alphabets.test(tempNationality.value))
                                                addInvalid(tempNationality);
                                            if(!only_alphabets.test(tempPlaceOfBirth.value))
                                                addInvalid(tempPlaceOfBirth);
                                            if(!only_alphabets.test(tempPlaceOfIssue.value))
                                                addInvalid(tempPlaceOfIssue);
                                        }else{
                                            let passportInsertObj = {
                                                "customer_id": customer_id.value,
                                                "passport_no": tGrpList[currCount - 1].customers[0].passport.passport_no,
                                                "nationality": tGrpList[currCount - 1].customers[0].passport.nationality,
                                                "place_of_birth": tGrpList[currCount - 1].customers[0].passport.place_of_birth,
                                                "place_of_issue": tGrpList[currCount - 1].customers[0].passport.place_of_issue,
                                                "date_of_issue":  tGrpList[currCount - 1].customers[0].passport.date_of_issue,
                                                "date_of_expiry": tGrpList[currCount - 1].customers[0].passport.date_of_expiry,
                                                "status": tGrpList[currCount - 1].customers[0].passport.status ? tGrpList[currCount - 1].customers[0].passport.status : 0
                                            };
                                            if(tGrpList[currCount - 1].customers[0].passport.id){
                                                passportInsertObj["id"] = tGrpList[currCount - 1].customers[0].passport.id;
                                                dispatch(serviceActions.updateAction('passport', passportInsertObj)).then(() => {
                                                    if(!tGrpList[currCount - 1].customers[0].visa || (tGrpList[currCount - 1].customers[0].visa && Object.keys(tGrpList[currCount - 1].customers[0].visa).length === 0))
                                                        removeLoadingButton(elem, 'Save');
                                                    notify("tc", "", "Passport details has been updated successfully.");
                                                });
                                            }else{
                                                dispatch(serviceActions.createAction('passport', passportInsertObj)).then(() => {
                                                    const { services } = this.props;
                                                    const { data } = services.services;
                                                    const { id } = data;
                                                    tGrpList[currCount - 1].customers[0].passport.id = id;
                                                    this.setState({ tGrpList: tGrpList });
                                                    if(!tGrpList[currCount - 1].customers[0].visa || (tGrpList[currCount - 1].customers[0].visa && Object.keys(tGrpList[currCount - 1].customers[0].visa).length === 0))
                                                        removeLoadingButton(elem, 'Save');
                                                    notify("tc", "", "Passport details has been created successfully.");
                                                });
                                            }
                                        }
                                    }else{
                                        removeLoadingButton(elem, 'Save');
                                        if(tempPassportNo.value === "")
                                            addInvalid(tempPassportNo);
                                        if(tempNationality.value === "")
                                            addInvalid(tempNationality);
                                        if(tempPlaceOfBirth.value === "")
                                            addInvalid(tempPlaceOfBirth);
                                        if(tempPlaceOfIssue.value === "")
                                            addInvalid(tempPlaceOfIssue);
                                        if(tempDateOfIssue.value === "")
                                            addInvalid(tempDateOfIssue);
                                        if(tempDateOfExpiry.value === "")
                                            addInvalid(tempDateOfExpiry);
                                    }
                                }
                                
                                if(tGrpList[currCount - 1].customers[0].visa && Object.keys(tGrpList[currCount - 1].customers[0].visa).length > 0){
                                    let tempFiledBy = document.querySelector(`#visa_modal_full_filed_by_${currCount - 1}`);
                                    let tempEmailId = document.querySelector(`#visa_modal_full_email_id_${currCount - 1}`);
                                    let tempCourierNo = document.querySelector(`#visa_modal_full_courier_no_${currCount - 1}`);
                                    let tempCourierDst = document.querySelector(`#visa_modal_full_courier_destination_${currCount - 1}`);
                                    let tempVisaType = document.querySelector(`#visa_modal_full_visa_type_id_${currCount - 1}`);
                                    let tempNoOfDays = document.querySelector(`#visa_modal_full_no_of_days_${currCount - 1}`);
                                    let tempEntry = document.querySelector(`#visa_modal_full_entry_${currCount - 1}`);
                                    
                                    removeInvalid(tempFiledBy);
                                    removeInvalid(tempEmailId);
                                    removeInvalid(tempCourierNo);
                                    removeInvalid(tempCourierDst);
                                    removeInvalid(tempVisaType);
                                    removeInvalid(tempNoOfDays);
                                    removeInvalid(tempEntry);
        
                                    if(tempFiledBy.children[1].value !== "" && tempEmailId.value !== "" && tempCourierNo.value !== "" && tempCourierDst.value !== "" && tempVisaType.children[1].value !== "" && tempNoOfDays.value !== "" && tempEntry.value !== ""){
                                        let alphabets_and_numbers = new RegExp(globalConstants.REGEX.alphabets_and_numbers);
                                        let only_alphabets = new RegExp(globalConstants.REGEX.only_alphabets);
                                        let only_numbers = new RegExp(globalConstants.REGEX.only_numbers);
                                        let email_id = new RegExp(globalConstants.REGEX.email_id);
        
                                        if(!email_id.test(tempEmailId.value) || !alphabets_and_numbers.test(tempCourierNo.value) || !only_alphabets.test(tempCourierDst.value) || !only_numbers.test(tempNoOfDays.value) || !alphabets_and_numbers.test(tempEntry.value)){
        
                                            removeLoadingButton(elem, "Save");
                                            if(!email_id.test(tempEmailId.value))
                                                addInvalid(tempEmailId);
                                            if(!alphabets_and_numbers.test(tempCourierNo.value))
                                                addInvalid(tempCourierNo);
                                            if(!only_alphabets.test(tempCourierDst.value))
                                                addInvalid(tempCourierDst);
                                            if(!only_numbers.test(tempNoOfDays.value))
                                                addInvalid(tempNoOfDays);
                                            if(!alphabets_and_numbers.test(tempEntry.value))
                                                addInvalid(tempEntry);
                                        }else{
                                            let visaInsertObj = {
                                                "customer_id": customer_id.value,
                                                "filed_by": (tGrpList[currCount - 1].customers[0].visa.filed_byVal.value === "e-route") ? "e-route" : "vendor",
                                                "email_id": tGrpList[currCount - 1].customers[0].visa.email_id,
                                                "courier_number": tGrpList[currCount - 1].customers[0].visa.courier_number,
                                                "country_destination": tGrpList[currCount - 1].customers[0].visa.country_destination,
                                                "visa_type_id": tGrpList[currCount - 1].customers[0].visa.visa_type_idVal.value,
                                                "vendor_id": tGrpList[currCount - 1].customers[0].visa.filed_byVal.value,
                                                "no_of_days": tGrpList[currCount - 1].customers[0].visa.no_of_days,
                                                "entry": tGrpList[currCount - 1].customers[0].visa.entry,
                                                "status": tGrpList[currCount - 1].customers[0].visa.status ? tGrpList[currCount - 1].customers[0].visa.status : 0
                                            };
                                            if(tGrpList[currCount - 1].customers[0].visa.id){
                                                visaInsertObj["id"] = tGrpList[currCount - 1].customers[0].visa.id;
                                                dispatch(serviceActions.updateAction('visa', visaInsertObj)).then(() => {
                                                    removeLoadingButton(elem, 'Save');
                                                    notify("tc", "", "Visa details has been updated successfully.");
                                                });
                                            }else{
                                                dispatch(serviceActions.createAction('visa', visaInsertObj)).then(() => {
                                                    const { services } = this.props;
                                                    const { data } = services.services;
                                                    const { id } = data;
                                                    tGrpList[currCount - 1].customers[0].visa.id = id;
                                                    this.setState({ tGrpList: tGrpList });
                                                    removeLoadingButton(elem, 'Save');
                                                    notify("tc", "", "Visa details has been created successfully.");
                                                });
                                            }
                                        }
                                    }else{
                                        removeLoadingButton(elem, "Save");
                                        if(tempFiledBy.children[1].value === "")
                                            addInvalid(tempFiledBy);
                                        if(tempEmailId.value === "")
                                            addInvalid(tempEmailId);
                                        if(tempCourierNo.value === "")
                                            addInvalid(tempCourierNo);
                                        if(tempCourierDst.value === "")
                                            addInvalid(tempCourierDst);
                                        if(tempVisaType.children[1].value === "")
                                            addInvalid(tempVisaType);
                                        if(tempNoOfDays.value === "")
                                            addInvalid(tempNoOfDays);
                                        if(tempEntry.value === "")
                                            addInvalid(tempEntry);
                                    }
                                }
                                
                                // else{
                                    removeLoadingButton(elem, "Save");
                                    notify("tc", "", "Customer details has been updated successfully.");
                                // }
                            });
                        }
                    });
                }else{
                    let tourGroupsUpdateData = { "id": tourGroupCusRelId.value, "customer_id": customer_id.value, "group_id": group_id, "tour_id": tourId };
                    dispatch(userActions.tourGroupsUpdate(tourGroupsUpdateData)).then(() => {
                        dispatch(userActions.updateCustomer(customerData)).then(() => {
                            const { billingFull, ticket_id } = this.props;
                            const { doc_typeList, tGrpList } = this.state;
                            let grpTotalAmt = 0;
                            let grpTotalAmtInd = 0;
                            billingFull && Object.keys(billingFull).length > 0  && Object.keys(billingFull).forEach(key => {
                                if(!this.props.isIndBilling && key == 0){
                                    grpTotalAmtInd = grpTotalAmtInd + parseFloat(billingFull[key] && billingFull[key][`paid`] || 0);
                                }
                                grpTotalAmt = grpTotalAmt + parseFloat(billingFull[key] && billingFull[key][`paid`] || 0);
                            });
                            if(Object.keys(billingFull).length > 0){
                                let i = currCount - 1;
                                let gstAmtTmp = billingFull[i] && billingFull[i][`gst_amount`] || 0;
                                let billingData = {
                                    'ticket_id': ticket_id,
                                    'service_id': 1,
                                    'customer_id': customer_id.value,
                                    'package_amount': (billingFull[i] && (parserFloat(billingFull[i][`price`]) + parserFloat(gstAmtTmp))) || '',
                                    'service_charge': billingFull[i] && billingFull[i][`service`] || '',
                                    'service_tax_amount': billingFull[i] && billingFull[i][`service_tax`] || '',
                                    'gst_amount': parserFloat(gstAmtTmp),
                                    'name': billingFull[i] && billingFull[i][`invoice_buyer_name`] || '',
                                    'amount_received': billingFull[i] && billingFull[i][`paid`] || '',
                                    'amount_pending': billingFull[i] && billingFull[i][`bal`] || '',
                                    'e_mop': billingFull[i] && billingFull[i][`mop`] || '',
                                    'e_paid_amount': billingFull[i] && billingFull[i][`e_paid_amount`] || 0, //e_paid_amount
                                    'invoice_to': billingFull[i] && billingFull[i][`invoice_toData`] && billingFull[i][`invoice_toData`].value || '',
                                    'amount_paid_by': billingFull[i] && billingFull[i][`amount_paid_byData`] && billingFull[i][`amount_paid_byData`].value || '',
                                    'vendor_name': billingFull[i] && !isArray(billingFull[i][`vendor_name`]) && billingFull[i][`vendor_name`] || '',
                                    'invoice_buyer_name': billingFull[i] && billingFull[i][`invoice_buyer_name`] || '',
                                    'other_amount': billingFull[i] && billingFull[i][`others`] || ''
                                };
                                
                                if(billingFull[i] && billingFull[i]['id']){
                                    billingData['id'] = billingFull[i]['id'];
                                    dispatch(userActions.updateBilling(billingData)).then(() => {
                                        let { groupC, group_data, setStater } = this.props;
                                        group_data = (group_data) ? group_data : {};
                                        group_data[groupC] = (group_data[groupC]) ? group_data[groupC] : {};
                                        group_data[groupC].grpTotal = parseFloat(grpTotalAmt).toFixed(2);
                                        group_data[groupC].grpTotalInd = parseFloat(grpTotalAmtInd).toFixed(2);
                                        setStater('group_data', group_data);
                                        if(!doc_typeList[customer_id.value] || Object.keys(doc_typeList[customer_id.value]).length === 0)
                                            removeLoadingButton(elem, "Save");
                                        notify("tc", "", "Customer details has been updated successfully.");
                                    });
                                }else{
                                    dispatch(userActions.createBilling(billingData)).then(() => {
                                        if(!doc_typeList[customer_id.value] || Object.keys(doc_typeList[customer_id.value]).length === 0)
                                            removeLoadingButton(elem, "Save");
                                        let { groupC, group_data, setStater } = this.props;
                                        group_data = (group_data) ? group_data : {};
                                        group_data[groupC] = (group_data[groupC]) ? group_data[groupC] : {};
                                        group_data[groupC].grpTotal = parseFloat(grpTotalAmt).toFixed(2);
                                        group_data[groupC].grpTotalInd = parseFloat(grpTotalAmtInd).toFixed(2);
                                        setStater('group_data', group_data);
                                        const { billing } = this.props;
                                        const { document } = billing;
                                        const { data } = document;
                                        const { id } = data;
                                        billingFull[i]['id'] = id;
                                        setStater(`billingFull`, billingFull);
                                        notify("tc", "", "Customer details has been updated successfully.");
                                    });
                                }
                            }
                            
                                
                            if(doc_typeList && doc_typeList[customer_id.value] && Object.keys(doc_typeList[customer_id.value]).length > 0){
                                Object.keys(doc_typeList[customer_id.value]).forEach((key) => {
                                    if(doc_typeList[customer_id.value][key].files){
                                    const docData = new FormData();
                                    docData.append('document_name',doc_typeList[customer_id.value][key].files);
                                    docData.append('customer_id',customer_id.value);
                                    docData.append('document_id',key);
                                    if(doc_typeList[customer_id.value][key].document_id){
                                        docData.append('id',doc_typeList[customer_id.value][key].document_id);
                                        dispatch(userActions.updateUploadDocument(docData)).then(() => {
                                            if(!tGrpList[currCount - 1].customers[0].passport || (tGrpList[currCount - 1].customers[0].passport && Object.keys(tGrpList[currCount - 1].customers[0].passport).length === 0))
                                                removeLoadingButton(elem, "Save");
                                            notify("tc", "", "Customer details has been updated successfully.");
                                        });
                                    } else {
                                        dispatch(userActions.uploadDocument(docData)).then(() => {
                                            const { document } = this.props;
                                            const { data } = document.document;
                                            const { id } = data;

                                            doc_typeList[customer_id.value][key].document_id = id;
                                            this.setState({ doc_typeList: doc_typeList });
                                            
                                            if(!tGrpList[currCount - 1].customers[0].passport || (tGrpList[currCount - 1].customers[0].passport && Object.keys(tGrpList[currCount - 1].customers[0].passport).length === 0))
                                                removeLoadingButton(elem, "Save");
                                            notify("tc", "", "Customer details has been updated successfully.");
                                        });
                                    }
                                    }
                                });
                            }
                            
                            if(tGrpList[currCount - 1].customers[0].passport && Object.keys(tGrpList[currCount - 1].customers[0].passport).length > 0){
                                let tempPassportNo = document.querySelector(`#passport_modal_full_passport_no_${currCount - 1}`);
                                let tempNationality = document.querySelector(`#passport_modal_full_nationality_${currCount - 1}`);
                                let tempPlaceOfBirth = document.querySelector(`#passport_modal_full_place_of_birth_${currCount - 1}`);
                                let tempPlaceOfIssue = document.querySelector(`#passport_modal_full_place_of_issue_${currCount - 1}`);
                                let tempDateOfIssue = document.querySelector(`#passport_modal_full_date_of_issue_${currCount - 1}`);
                                let tempDateOfExpiry = document.querySelector(`#passport_modal_full_date_of_expiry_${currCount - 1}`);

                                removeInvalid(tempPassportNo);
                                removeInvalid(tempNationality);
                                removeInvalid(tempPlaceOfBirth);
                                removeInvalid(tempPlaceOfIssue);
                                removeInvalid(tempDateOfIssue);
                                removeInvalid(tempDateOfExpiry);

                                if(tempPassportNo.value !== "" && tempNationality.value !== "" && tempPlaceOfBirth.value !== "" && tempPlaceOfIssue.value !== "" && tempDateOfIssue.value !== "" && tempDateOfExpiry.value !== ""){
                                    let alphabets_and_numbers = new RegExp(globalConstants.REGEX.alphabets_and_numbers);
                                    let only_alphabets = new RegExp(globalConstants.REGEX.only_alphabets);
                                    if(!alphabets_and_numbers.test(tempPassportNo.value) || !only_alphabets.test(tempNationality.value) || !only_alphabets.test(tempPlaceOfBirth.value) || !only_alphabets.test(tempPlaceOfIssue.value)){
                                        removeLoadingButton(elem, "Save");
                                        if(!alphabets_and_numbers.test(tempPassportNo.value))
                                            addInvalid(tempPassportNo);
                                        if(!only_alphabets.test(tempNationality.value))
                                            addInvalid(tempNationality);
                                        if(!only_alphabets.test(tempPlaceOfBirth.value))
                                            addInvalid(tempPlaceOfBirth);
                                        if(!only_alphabets.test(tempPlaceOfIssue.value))
                                            addInvalid(tempPlaceOfIssue);
                                    }else{
                                        let passportInsertObj = {
                                            "customer_id": customer_id.value,
                                            "passport_no": tGrpList[currCount - 1].customers[0].passport.passport_no,
                                            "nationality": tGrpList[currCount - 1].customers[0].passport.nationality,
                                            "place_of_birth": tGrpList[currCount - 1].customers[0].passport.place_of_birth,
                                            "place_of_issue": tGrpList[currCount - 1].customers[0].passport.place_of_issue,
                                            "date_of_issue":  tGrpList[currCount - 1].customers[0].passport.date_of_issue,
                                            "date_of_expiry": tGrpList[currCount - 1].customers[0].passport.date_of_expiry,
                                            "status": tGrpList[currCount - 1].customers[0].passport.status ? tGrpList[currCount - 1].customers[0].passport.status : 0
                                        };
                                        if(tGrpList[currCount - 1].customers[0].passport.id){
                                            passportInsertObj["id"] = tGrpList[currCount - 1].customers[0].passport.id;
                                            dispatch(serviceActions.updateAction('passport', passportInsertObj)).then(() => {
                                                if(!tGrpList[currCount - 1].customers[0].visa || (tGrpList[currCount - 1].customers[0].visa && Object.keys(tGrpList[currCount - 1].customers[0].visa).length === 0))
                                                    removeLoadingButton(elem, 'Save');
                                                notify("tc", "", "Passport details has been updated successfully.");
                                            });
                                        }else{
                                            dispatch(serviceActions.createAction('passport', passportInsertObj)).then(() => {
                                                const { services } = this.props;
                                                const { data } = services.services;
                                                const { id } = data;
                                                tGrpList[currCount - 1].customers[0].passport.id = id;
                                                this.setState({ tGrpList: tGrpList });
                                                if(!tGrpList[currCount - 1].customers[0].visa || (tGrpList[currCount - 1].customers[0].visa && Object.keys(tGrpList[currCount - 1].customers[0].visa).length === 0))
                                                    removeLoadingButton(elem, 'Save');
                                                notify("tc", "", "Passport details has been created successfully.");
                                            });
                                        }
                                    }
                                }else{
                                    removeLoadingButton(elem, 'Save');
                                    if(tempPassportNo.value === "")
                                        addInvalid(tempPassportNo);
                                    if(tempNationality.value === "")
                                        addInvalid(tempNationality);
                                    if(tempPlaceOfBirth.value === "")
                                        addInvalid(tempPlaceOfBirth);
                                    if(tempPlaceOfIssue.value === "")
                                        addInvalid(tempPlaceOfIssue);
                                    if(tempDateOfIssue.value === "")
                                        addInvalid(tempDateOfIssue);
                                    if(tempDateOfExpiry.value === "")
                                        addInvalid(tempDateOfExpiry);
                                }
                            }
                            
                            if(tGrpList[currCount - 1].customers[0].visa && Object.keys(tGrpList[currCount - 1].customers[0].visa).length > 0){
                                let tempFiledBy = document.querySelector(`#visa_modal_full_filed_by_${currCount - 1}`);
                                let tempEmailId = document.querySelector(`#visa_modal_full_email_id_${currCount - 1}`);
                                let tempCourierNo = document.querySelector(`#visa_modal_full_courier_no_${currCount - 1}`);
                                let tempCourierDst = document.querySelector(`#visa_modal_full_courier_destination_${currCount - 1}`);
                                let tempVisaType = document.querySelector(`#visa_modal_full_visa_type_id_${currCount - 1}`);
                                let tempNoOfDays = document.querySelector(`#visa_modal_full_no_of_days_${currCount - 1}`);
                                let tempEntry = document.querySelector(`#visa_modal_full_entry_${currCount - 1}`);
                                
                                removeInvalid(tempFiledBy);
                                removeInvalid(tempEmailId);
                                removeInvalid(tempCourierNo);
                                removeInvalid(tempCourierDst);
                                removeInvalid(tempVisaType);
                                removeInvalid(tempNoOfDays);
                                removeInvalid(tempEntry);

                                if(tempFiledBy.children[1].value !== "" && tempEmailId.value !== "" && tempCourierNo.value !== "" && tempCourierDst.value !== "" && tempVisaType.children[1].value !== "" && tempNoOfDays.value !== "" && tempEntry.value !== ""){
                                    let alphabets_and_numbers = new RegExp(globalConstants.REGEX.alphabets_and_numbers);
                                    let only_alphabets = new RegExp(globalConstants.REGEX.only_alphabets);
                                    let only_numbers = new RegExp(globalConstants.REGEX.only_numbers);
                                    let email_id = new RegExp(globalConstants.REGEX.email_id);

                                    if(!email_id.test(tempEmailId.value) || !alphabets_and_numbers.test(tempCourierNo.value) || !only_alphabets.test(tempCourierDst.value) || !only_numbers.test(tempNoOfDays.value) || !alphabets_and_numbers.test(tempEntry.value)){

                                        removeLoadingButton(elem, "Save");
                                        if(!email_id.test(tempEmailId.value))
                                            addInvalid(tempEmailId);
                                        if(!alphabets_and_numbers.test(tempCourierNo.value))
                                            addInvalid(tempCourierNo);
                                        if(!only_alphabets.test(tempCourierDst.value))
                                            addInvalid(tempCourierDst);
                                        if(!only_numbers.test(tempNoOfDays.value))
                                            addInvalid(tempNoOfDays);
                                        if(!alphabets_and_numbers.test(tempEntry.value))
                                            addInvalid(tempEntry);
                                    }else{
                                        let visaInsertObj = {
                                            "customer_id": customer_id.value,
                                            "filed_by": (tGrpList[currCount - 1].customers[0].visa.filed_byVal.value === "e-route") ? "e-route" : "vendor",
                                            "email_id": tGrpList[currCount - 1].customers[0].visa.email_id,
                                            "courier_number": tGrpList[currCount - 1].customers[0].visa.courier_number,
                                            "country_destination": tGrpList[currCount - 1].customers[0].visa.country_destination,
                                            "visa_type_id": tGrpList[currCount - 1].customers[0].visa.visa_type_idVal.value,
                                            "vendor_id": tGrpList[currCount - 1].customers[0].visa.filed_byVal.value,
                                            "no_of_days": tGrpList[currCount - 1].customers[0].visa.no_of_days,
                                            "entry": tGrpList[currCount - 1].customers[0].visa.entry,
                                            "status": tGrpList[currCount - 1].customers[0].visa.status ? tGrpList[currCount - 1].customers[0].visa.status : 0
                                        };
                                        if(tGrpList[currCount - 1].customers[0].visa.id){
                                            visaInsertObj["id"] = tGrpList[currCount - 1].customers[0].visa.id;
                                            dispatch(serviceActions.updateAction('visa', visaInsertObj)).then(() => {
                                                removeLoadingButton(elem, 'Save');
                                                notify("tc", "", "Visa details has been updated successfully.");
                                            });
                                        }else{
                                            dispatch(serviceActions.createAction('visa', visaInsertObj)).then(() => {
                                                const { services } = this.props;
                                                const { data } = services.services;
                                                const { id } = data;
                                                tGrpList[currCount - 1].customers[0].visa.id = id;
                                                this.setState({ tGrpList: tGrpList });
                                                removeLoadingButton(elem, 'Save');
                                                notify("tc", "", "Visa details has been created successfully.");
                                            });
                                        }
                                    }
                                }else{
                                    removeLoadingButton(elem, "Save");
                                    if(tempFiledBy.children[1].value === "")
                                        addInvalid(tempFiledBy);
                                    if(tempEmailId.value === "")
                                        addInvalid(tempEmailId);
                                    if(tempCourierNo.value === "")
                                        addInvalid(tempCourierNo);
                                    if(tempCourierDst.value === "")
                                        addInvalid(tempCourierDst);
                                    if(tempVisaType.children[1].value === "")
                                        addInvalid(tempVisaType);
                                    if(tempNoOfDays.value === "")
                                        addInvalid(tempNoOfDays);
                                    if(tempEntry.value === "")
                                        addInvalid(tempEntry);
                                }
                            }
                            // else{
                                removeLoadingButton(elem, "Save");
                                notify("tc", "", "Customer details has been updated successfully.");
                            // }
                        });
                    });
                }
            }else{
                dispatch(userActions.createCustomer(customerData)).then(() => {
                    const { customer, billingFull, ticket_id } = this.props;
                    const { data, error } = customer.customer;
                    if(error){
                        const { response } = error;
                        const { data } = response;
                        data && isObject(data) && Object.keys(data).forEach(key => {
                            let tempElem = document.querySelector(`.${key}_${currCount - 1}`);
                            if(tempElem){
                                addInvalid(tempElem);
                                if(elem)
                                    removeLoadingButton(elem, "Save");
                                let { tGrpList } = this.state;
                                tGrpList = (tGrpList) ? tGrpList : {};
                                tGrpList[currCount - 1] = (tGrpList[currCount - 1]) ? tGrpList[currCount - 1] : {};
                                tGrpList[currCount - 1].customers = (tGrpList[currCount - 1].customers) ? tGrpList[currCount - 1].customers : [];
                                tGrpList[currCount - 1].customers[0] = (tGrpList[currCount - 1].customers[0]) ? tGrpList[currCount - 1].customers[0] : {};
                                tGrpList[currCount - 1].customers[0][`${key}Feedback`] = data[key];
                                this.setState({tGrpList: tGrpList});
                            }
                        });
                    }else if(data && data.id){
                        customer_id.value = data.id;
                        const { tGrpList } = this.state;
                        tGrpList[currCount - 1].customers[0]['id'] = data.id;
                        this.setState({ tGrpList: tGrpList });

                        let tourGroupsInsertData = { "customer_id": customer_id.value, "group_id": group_id, "tour_id": tourId };
                        dispatch(userActions.tourGroupsInsert(tourGroupsInsertData)).then(() => {
                            const { tourGroupsInsert } = this.props;
                            if(tourGroupsInsert.data.tour_groups.id){
                                tGrpList[currCount-1].id = tourGroupsInsert.data.tour_groups.id
                                this.setState({ tGrpList: tGrpList });
                            }
                                
                            if(Object.keys(billingFull).length > 0){
                                let i = currCount - 1;
                                let gstAmtTmp = billingFull[i] && billingFull[i][`gst_amount`] || 0;
                                let billingData = {
                                    'ticket_id': ticket_id,
                                    'service_id': 1,
                                    'customer_id': data.id,
                                    'package_amount': (billingFull[i] && (parserFloat(billingFull[i][`price`]) + parserFloat(gstAmtTmp))) || '',
                                    'service_charge': billingFull[i] && billingFull[i][`service`] || '',
                                    'service_tax_amount': billingFull[i] && billingFull[i][`service_tax`] || '',
                                    'gst_amount': parserFloat(gstAmtTmp),
                                    'name': billingFull[i] && billingFull[i][`invoice_buyer_name`] || '',
                                    'amount_received': billingFull[i] && billingFull[i][`paid`] || '',
                                    'amount_pending': billingFull[i] && billingFull[i][`bal`] || '',
                                    'e_mop': billingFull[i] && billingFull[i][`mop`] || '',
                                    'e_paid_amount': billingFull[i] && billingFull[i][`e_paid_amount`] || 0, //e_paid_amount
                                    'invoice_to': billingFull[i] && billingFull[i][`invoice_toData`] && billingFull[i][`invoice_toData`].value || '',
                                    'amount_paid_by': billingFull[i] && billingFull[i][`amount_paid_byData`] && billingFull[i][`amount_paid_byData`].value || '',
                                    'vendor_name': billingFull[i] && !isArray(billingFull[i][`vendor_name`]) && billingFull[i][`vendor_name`] || '',
                                    'invoice_buyer_name': billingFull[i] && billingFull[i][`invoice_buyer_name`] || '',
                                    'other_amount': billingFull[i] && billingFull[i][`others`] || ''
                                };
                                
                                if(billingFull[i] && billingFull[i]['id']){
                                    billingData['id'] = billingFull[i]['id'];
                                    dispatch(userActions.updateBilling(billingData)).then(() => {
                                        removeLoadingButton(elem, "Save");
                                        notify("tc", "", "Customer details has been inserted successfully.");
                                        const { tourGroupsInsert, billing } = this.props;
                                    });
                                }else{
                                    dispatch(userActions.createBilling(billingData)).then(() => {
                                        removeLoadingButton(elem, "Save");
                                        const { billing } = this.props;
                                        const { document } = billing;
                                        const { data } = document;
                                        const { id } = data;
                                        billingFull[i]['id'] = id;
                                        setStater(`billingFull`, billingFull);
                                        notify("tc", "", "Customer details has been inserted successfully.");
                                    });
                                }
                            } 
                            // else {
                                removeLoadingButton(elem, "Save");
                                notify("tc", "", "Customer details has been inserted successfully.");
                            // }
                        });
                    }
                });
            }
        }else{
            // do required validation
            removeLoadingButton(elem, "Save");
            if(firstname.value === ""){
                addInvalid(firstname);
            } if(email.value === ""){
                addInvalid(email);
            } if(phone.value === ""){
                addInvalid(phone);
            }
        }
    }

    goToSlide(e, unit){
        unit = unit + 1;
        const { currCount, memCount, setCurrCount } = this.props;
        if(currCount != unit){
            this.setState({ left: `-${(unit - 1) * 100}%` });
            setCurrCount(unit);
        }
    }

    openBrowseButton(e, value, key, customer_id) {
        e.preventDefault();
        document.querySelector(`.${value}_file_modal_full_${customer_id}_${key}`).click();
    }

    deleteAttachment(e, value, key, customer_id) {
        let elem = document.querySelector(`button[name="${value}_delete_modal_full_${customer_id}_${key}"]`);
        if(elem)
            loadingButton(elem);
        e.preventDefault();
        let { doc_type, doc_typeList } = this.state;
        const { dispatch } = this.props;
        doc_typeList[customer_id] = (doc_typeList[customer_id]) ? doc_typeList[customer_id] : {};
        doc_typeList[customer_id][key] = (doc_typeList[customer_id][key]) ? doc_typeList[customer_id][key] : {};
        delete doc_typeList[customer_id][key];
        this.setState({ doc_typeList: doc_typeList });
        
        if(doc_typeList[customer_id][key] && doc_typeList[customer_id][key].id){
            let document_data = {
                id: doc_typeList[customer_id][key].id
            }
            delete doc_typeList[customer_id][key];
            dispatch(userActions.deleteDocument(document_data)).then(() => {
                this.setState({
                    doc_typeList: doc_typeList
                });
            });
        }
        // else{
        //     var element = document.querySelector(`.doc_lis_modal_full_${key}`);
        //     if(element)
        //         element.parentNode.removeChild(element);
        // }

    }

    loadImage(e, value, key, customer_id) {
      let img = document.querySelector(`.${value}_img_modal_full_${customer_id}_${key}`);
      let preview = document.querySelector(`.${value}_preview_modal_full_${customer_id}_${key}`);
      const { doc_typeList } = this.state;
      preview.classList.add('d-none');
      preview.classList.remove('d-flex');
      img.src = '';
      doc_typeList[customer_id] = (doc_typeList[customer_id]) ? doc_typeList[customer_id] : {};
      doc_typeList[customer_id][key] = (doc_typeList[customer_id][key]) ? doc_typeList[customer_id][key] : {};
      doc_typeList[customer_id][key].files = '';
      if(e.target && e.target.files && e.target.files[0]){
        img.src = '...';
        img.alt = '...';
        if(e.target.files[0].type.indexOf('image') !== -1){
          img.src = URL.createObjectURL(e.target.files[0]);
        }
        doc_typeList[customer_id][key].files = e.target.files[0];
        preview.classList.remove('d-none');
        preview.classList.add('d-flex');
      }
  
      this.setState({
        doc_typeList: doc_typeList
      });
    }

    getDocuments(customer_id) {
        this.setState({ 'activeTabFullModal': 4, [`tour_fullModal_ActiveTabFullModal`] : 4 });
    }

    handleDateChanges(value, type, key, iteration, isReq, regex){
        value = value._d;
        let { tGrpList } = this.state;
        tGrpList = (tGrpList) ? tGrpList : [];
        tGrpList[iteration] = (tGrpList[iteration]) ? tGrpList[iteration] : {};
        tGrpList[iteration].customers = (tGrpList[iteration].customers) ? tGrpList[iteration].customers : [];
        tGrpList[iteration].customers[0] = (tGrpList[iteration].customers[0]) ? tGrpList[iteration].customers[0] : {};
        tGrpList[iteration].customers[0][type] = isObject(tGrpList[iteration].customers[0][type]) ? tGrpList[iteration].customers[0][type] : {};
        tGrpList[iteration].customers[0][type][`${key}`] = isDate(value) ? moment(value).format("YYYY-MM-DD") : '';
        tGrpList[iteration].customers[0][type][`${key}State`] = 'is-valid';
        if(isReq && !value){
            tGrpList[iteration].customers[0][type][`${key}State`] = 'is-invalid';
        }

        this.setState({ tGrpList: tGrpList });
    }

    handleSelectChanges(value, type, key, iteration, isReq, regex){
        let { tGrpList } = this.state;
        tGrpList = (tGrpList) ? tGrpList : [];
        tGrpList[iteration] = (tGrpList[iteration]) ? tGrpList[iteration] : {};
        tGrpList[iteration].customers = (tGrpList[iteration].customers) ? tGrpList[iteration].customers : [];
        tGrpList[iteration].customers[0] = (tGrpList[iteration].customers[0]) ? tGrpList[iteration].customers[0] : {};
        tGrpList[iteration].customers[0][type] = isObject(tGrpList[iteration].customers[0][type]) ? tGrpList[iteration].customers[0][type] : {};
        tGrpList[iteration].customers[0][type][`${key}Val`] = value;
        tGrpList[iteration].customers[0][type][`${key}State`] = 'is-valid';
        if(isReq && !value){
            tGrpList[iteration].customers[0][type][`${key}State`] = 'is-invalid';
        }

        this.setState({ tGrpList: tGrpList });
    }

    handleChanges(e, type, key, iteration, isReq, regex){
        let { tGrpList } = this.state;
        tGrpList = (tGrpList) ? tGrpList : [];
        tGrpList[iteration] = (tGrpList[iteration]) ? tGrpList[iteration] : {};
        tGrpList[iteration].customers = (tGrpList[iteration].customers) ? tGrpList[iteration].customers : [];
        tGrpList[iteration].customers[0] = (tGrpList[iteration].customers[0]) ? tGrpList[iteration].customers[0] : {};
        tGrpList[iteration].customers[0][type] = isObject(tGrpList[iteration].customers[0][type]) ? tGrpList[iteration].customers[0][type] : {};
        tGrpList[iteration].customers[0][type][key] = e.target.value;
        tGrpList[iteration].customers[0][type][`${key}State`] = 'is-valid';
        if(isReq && e.target.value === ''){
            tGrpList[iteration].customers[0][type][`${key}State`] = 'is-invalid';
        }else if(regex &&  e.target.value !== ''){
            var regexp = new RegExp(regex);
            if(!regexp.test(tGrpList[iteration].customers[0][type][key])){
                tGrpList[iteration].customers[0][type][`${key}State`] = 'is-invalid';
            }
        }

        this.setState({ tGrpList: tGrpList });
    }

    slideInputLeft(e, reactionClass, triggerClass){
        e.preventDefault();
        let reactionElem = document.querySelector(reactionClass);
        let triggerElem = document.querySelector(triggerClass);
        if(triggerElem)
            triggerElem.classList.toggle('d-none');
        if(reactionElem)
            reactionElem.classList.toggle('inputSlideLeft');
    }

    setStatesModalFull(value){
        const { currCount } = this.props;
        let { tGrpList } = this.state;
        tGrpList = (tGrpList) ? tGrpList : [];
        tGrpList[currCount-1] = (tGrpList[currCount-1]) ? tGrpList[currCount-1] : {};
        tGrpList[currCount-1].customer_id = value.id;

        tGrpList[currCount-1].customers = (tGrpList[currCount-1].customers) ? tGrpList[currCount-1].customers : [];
        tGrpList[currCount-1].customers[0] = (tGrpList[currCount-1].customers[0]) ? tGrpList[currCount-1].customers[0] : {};
        tGrpList[currCount-1].customers[0].id = value.id;
        tGrpList[currCount-1].customers[0].firstname = value.firstname;
        tGrpList[currCount-1].customers[0].lastname = value.lastname;
        tGrpList[currCount-1].customers[0].email = value.email;
        tGrpList[currCount-1].customers[0].address = value.address;
        tGrpList[currCount-1].customers[0].city = value.city;
        tGrpList[currCount-1].customers[0].state = value.state;
        tGrpList[currCount-1].customers[0].zipcode = value.zipcode;
        tGrpList[currCount-1].customers[0].secondary = value.secondary;
        tGrpList[currCount-1].customers[0].dob = value.dob;
        tGrpList[currCount-1].customers[0].active = value.active;
        tGrpList[currCount-1].customers[0].deleted_at = value.deleted_at;
        tGrpList[currCount-1].customers[0].created_at = value.created_at;
        tGrpList[currCount-1].customers[0].updated_at = value.updated_at;
        tGrpList[currCount-1].customers[0].phone = value.phone;
        tGrpList[currCount-1].customers[0].passport = (value.passport !== null) ? value.passport : {};
        if(value.passport === null){
            tGrpList[currCount-1].customers[0].passport.id = '';
            tGrpList[currCount-1].customers[0].passport.customer_id = '';
            tGrpList[currCount-1].customers[0].passport.date_of_expiry = '';
            tGrpList[currCount-1].customers[0].passport.date_of_issue = '';
            tGrpList[currCount-1].customers[0].passport.nationality = '';
            tGrpList[currCount-1].customers[0].passport.passport_no = '';
            tGrpList[currCount-1].customers[0].passport.passport_purpose = '';
            tGrpList[currCount-1].customers[0].passport.place_of_birth = '';
            tGrpList[currCount-1].customers[0].passport.place_of_issue = '';
            tGrpList[currCount-1].customers[0].passport.status = '';
        }else{
            tGrpList[currCount-1].customers[0].passport.id = (tGrpList[currCount-1].customers[0].passport.id) ? tGrpList[currCount-1].customers[0].passport.id : '';
            tGrpList[currCount-1].customers[0].passport.customer_id = (tGrpList[currCount-1].customers[0].passport.customer_id) ? tGrpList[currCount-1].customers[0].passport.customer_id : '';
            tGrpList[currCount-1].customers[0].passport.date_of_expiry = (tGrpList[currCount-1].customers[0].passport.date_of_expiry) ? tGrpList[currCount-1].customers[0].passport.date_of_expiry : '';
            tGrpList[currCount-1].customers[0].passport.date_of_issue = (tGrpList[currCount-1].customers[0].passport.date_of_issue) ? tGrpList[currCount-1].customers[0].passport.date_of_issue : '';
            tGrpList[currCount-1].customers[0].passport.nationality = (tGrpList[currCount-1].customers[0].passport.nationality) ? tGrpList[currCount-1].customers[0].passport.nationality : '';
            tGrpList[currCount-1].customers[0].passport.passport_no = (tGrpList[currCount-1].customers[0].passport.passport_no) ? tGrpList[currCount-1].customers[0].passport.passport_no : '';
            tGrpList[currCount-1].customers[0].passport.passport_purpose = (tGrpList[currCount-1].customers[0].passport.passport_purpose) ? tGrpList[currCount-1].customers[0].passport.passport_purpose : '';
            tGrpList[currCount-1].customers[0].passport.place_of_birth = (tGrpList[currCount-1].customers[0].passport.place_of_birth) ? tGrpList[currCount-1].customers[0].passport.place_of_birth : '';
            tGrpList[currCount-1].customers[0].passport.place_of_issue = (tGrpList[currCount-1].customers[0].passport.place_of_issue) ? tGrpList[currCount-1].customers[0].passport.place_of_issue : '';
            tGrpList[currCount-1].customers[0].passport.status = (tGrpList[currCount-1].customers[0].passport.status) ? tGrpList[currCount-1].customers[0].passport.status : '';
        }
        tGrpList[currCount-1].customers[0].visa = {};
        tGrpList[currCount-1].customers[0].visa.id = '';
        tGrpList[currCount-1].customers[0].visa.no_of_days = '';
        tGrpList[currCount-1].customers[0].visa.courier_number = '';
        tGrpList[currCount-1].customers[0].visa.country_destination = '';
        tGrpList[currCount-1].customers[0].visa.customer_id = '';
        tGrpList[currCount-1].customers[0].visa.email_id = '';
        tGrpList[currCount-1].customers[0].visa.entry = '';
        tGrpList[currCount-1].customers[0].visa.filed_by = '';
        tGrpList[currCount-1].customers[0].visa.filed_byVal = '';
        tGrpList[currCount-1].customers[0].visa.visa_type_id = '';
        tGrpList[currCount-1].customers[0].visa.visa_type_idVal = '';
        
        this.setState({ tGrpList: tGrpList });
    }

    render() {
        const { isOpen, closeModal, grpName, memCount, currCount, grpId, ticket_id, tourGroupsList, billingFullAccordians, handleBillingInputsChange, handleBillingInputsBlur, collapsesToggle, openedCollapses, billingFull, parserFloat, doc_type_options, IconOption, isIndBilling, vendorOptions, visaTypeOptions, validDob, validFuture, tourType } = this.props;
        const { tGrpList, doc_typeList } = this.state;
        
        let modalContent = [];
        let doc_type_list = [];
        let sidebarContent = [];
        Object.keys(doc_typeList).forEach((key) => {
            let ik = 0;
            if(doc_typeList[key]){
                Object.keys(doc_typeList[key]).forEach((key1) => {
                    if(doc_typeList[key][key1]){
                        let customer_id = key;//(tGrpList && tGrpList[ik] && tGrpList[ik].customers && tGrpList[ik].customers[0] && tGrpList[ik].customers[0]['id']);

                        let imgWrapClass = 'd-none';
                        let imgSrc = '';
                        let imgAlt = '';
                        if(doc_typeList[key][key1].files){
                            imgSrc = "...";
                            imgAlt = "...";
                            if(doc_typeList[key][key1].files.type.indexOf('image') !== -1){
                                imgSrc = URL.createObjectURL(doc_typeList[key][key1].files);
                            }
                            imgWrapClass = 'd-flex';
                        }else if(doc_typeList[key][key1].doc_name){
                            imgSrc = "...";
                            imgAlt = "...";
                            if(isImage(doc_typeList[key][key1].doc_name)){
                                imgSrc = `${globalConstants.DOC_URL}${doc_typeList[key][key1].doc_name}`;
                                imgWrapClass = 'd-flex';
                            }
                        }
                        doc_type_list[key] = (doc_type_list[key]) ? doc_type_list[key] : [];
                        if(doc_typeList[key][key1]['label']){
                            doc_type_list[key].push(
                                <div className={`row mt-3 mx-0 py-2 px-3 border rounded doc_lis_modal_full_${doc_typeList[key][key1]['value']}`} key={doc_typeList[key][key1]['value']}>
                                    <div className="col-3 pl-0 d-flex align-items-center">
                                        <label className="form-control-label mb-0" htmlFor={`${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_modal_full_${doc_typeList[key][key1]['value']}`}>{doc_typeList[key][key1]['label']}</label>
                                    </div>
                                    <div className="col-3 d-none">
                                        <input type="file" className={`${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_file_modal_full_${customer_id}_${doc_typeList[key][key1]['value']}`} onChange={(e) => { this.loadImage(e, doc_typeList[key][key1]['label'].replace(/ /g, '_'), doc_typeList[key][key1]['value'], customer_id) } } name={`${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_file_modal_full_${customer_id}_${doc_typeList[key][key1]['value']}`}/>
                                    </div>
                                    <div className={`col d-flex align-items-center ${imgWrapClass} ${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_preview_modal_full_${customer_id}_${doc_typeList[key][key1]['value']}`}>
                                        <div className="avatar avatar-sm mr-2">
                                        {(imgSrc === "...") ? (<div className={`${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_img_modal_full_${customer_id}_${doc_typeList[key][key1]['value']} h-100 avatar-img rounded`}>{imgSrc}</div>) : (<img className={`${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_img_modal_full_${customer_id}_${doc_typeList[key][key1]['value']} h-100 avatar-img rounded`} src={`${imgSrc}`} alt={`${imgAlt}`}/>)}
                                        </div>
                                        <Button className="border-0 h-100" type="button" size="sm" onClick={(e) => this.openBrowseButton(e, doc_typeList[key][key1]['label'].replace(/ /g, '_'), doc_typeList[key][key1]['value'], customer_id)} color="primary" name={`${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_browse_modal_full_${customer_id}_${doc_typeList[key][key1]['value']}`}>
                                        <span className="btn-inner--icon">
                                            <i className="ni ni-cloud-upload-96" />
                                        </span>
                                        <span className="btn-inner--text"> Browse</span>
                                        </Button>
                                        <Button className="py-1 border-0 h-100" size="sm" type="button" color="danger" onClick={(e) => { this.deleteAttachment(e, doc_typeList[key][key1]['label'].replace(/ /g, '_'), doc_typeList[key][key1]['value'], customer_id) }} name={`${doc_typeList[key][key1]['label'].replace(/ /g, '_')}_delete_modal_full_${customer_id}_${doc_typeList[key][key1]['value']}`}>
                                        <span className="btn-inner--icon">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                        </Button>
                                    </div>
                                </div>);
                        }
                        ik = ik + 1;
                    }
                });
            }
        });

        let prevButton = (<Button color="primary" type="button" size="sm" className="float-right" onClick={() => this.onPrevClick()}> Prev </Button>);
        let nextButton = (<Button color="primary" type="button" size="sm" className="float-right" onClick={() => this.onNextClick()}> Next </Button>);
        if(currCount === 1)
            prevButton = (<Button color="primary" type="button" disabled size="sm" className="float-right" onClick={() => this.onPrevClick()}> Prev </Button>);
        if(currCount == memCount)
            nextButton = (<Button color="primary" type="button" disabled size="sm" className="float-right" onClick={() => this.onNextClick()}> Next </Button>);

        for(let ii = 0; ii < memCount; ii++){
            let tempid = (tGrpList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['phone']) ? tGrpList[ii].customers[0]['phone'] : '-';
            let customer_id = (tGrpList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['id']);
            let tempfname = tGrpList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['firstname'];
            let templname = tGrpList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['lastname'];
            let uname = (tempfname) ? ((templname) ? `${tempfname} ${templname}` : tempfname) : '';

            if(tempid && tempid.toString().toLowerCase().indexOf(this.state.search.toString().toLowerCase()) !== -1 || uname && uname.toString().toLowerCase().indexOf(this.state.search.toString().toLowerCase()) !== -1){
                sidebarContent[ii] = (<div className="alert-with-icon alert alert-secondary fade show px-3 py-2 cursor-pointer" onClick={(e) => { this.goToSlide(e, ii); }} role="alert">
                    <span data-notify="message">
                        <div className="alert-text">
                            <span className="alert-title" data-notify="title">
                                <span className="d-block"><i className="fas fa-phone fa-phone-alt mr-1"></i> <small>{tempid}</small></span>
                                <span className="d-block"><i className="fas fa-user mr-1"></i> {uname}</span>
                            </span>
                        </div>
                    </span>
                </div>)
            }
            
            modalContent[ii] = (<div key={ii} className="px-3 cc-slide-box-width col-md-12">
                <Row>
                    {(customer_id) ? (
                    <Col className="mb-2" md={2}>
                        <label className="form-control-label"> Customer ID </label>
                        <Input type="text" className="form-control-sm" value={customer_id} disabled />
                        <Input type="hidden" className={`form-control-sm tour_group_cus_id_${customer_id}_${grpId}`} value={tGrpList[ii].id} disabled />
                    </Col>) : ''}
                    <Col className="mb-2" md={2}>
                        <label className="form-control-label"> Ticket ID </label>
                        <Input type="text" className="form-control-sm" value={ticket_id} disabled />
                    </Col>
                    {(grpId) ? (<Col className="mb-2" md={2}>
                        <label className="form-control-label"> Group ID </label>
                        <Input type="text" className="form-control-sm" value={grpId} disabled />
                    </Col>) : ''}
                    <Col className="mb-2" md={2}>
                        <label className="form-control-label"> Group Count </label>
                        <Input type="text" className="form-control-sm" value={memCount} disabled />
                    </Col>
                </Row>
                <Row className={`customer_${ii}`}>
                <input type="hidden" value="" className={`customer_id_${ii}`}  value={customer_id} />
                {_customerCreate.map((v, i) => {
                    return (
                        <Col key={i} className="mb-2" md={v.bs4Size}>
                            <label className="form-control-label" htmlFor={v.name} > {v.label} </label>
                            
                            {(v.type == "input") ? 
                            (<><Input bsSize="sm" id={v.name} valid={tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii]['customers'][0][`${v.state}`] === 'valid'} invalid={tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii]['customers'][0][`${v.state}`] === 'invalid'} className={`${v.name}_${ii}`} placeholder={v.label} type="text" onChange={e => this.inputChange(e, v.name, v.isReq, v.regex, v.regexInvalid, ii, v) } value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0][`${v.name}`]} /></>) : ''}
                            
                            {(v.type == "date") ? <ReactDatetime isValidDate={validDob} closeOnSelect={true} className={`form-control form-control-sm p-0 h-auto ${v.name}_${ii}`} inputProps={{ placeholder: v.placeholder, className: `form-control form-control-sm border-0 cc-rdt-pos-right`, readOnly: true }} timeFormat={false} onChange={value => this.onDateChange(value, v.name, v.isReq, ii) } value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0][`${v.name}`]} /> : ''}

                            <div className="valid-feedback"></div>
                            <div className="invalid-feedback">{tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii]['customers'][0][`${v.name}Feedback`]}</div>
                        </Col>
                    );
                })}
                <Col md="12">
                    {customer_id ? (
                    <Card className="mt-3 mb-2 border-0 rounded">
                        <CardBody className="p-4">
                            <div>
                                <Nav tabs className="nav-pills border-0">
                                    {(tourType && tourType.value !== 1) ?
                                    (<><NavItem className="pr-1">
                                        <NavLink className={classnames({ active: (this.state.activeTabFullModal === 1), ' border-0 rounded py-': true })} onClick={() => { this.setState({ 'activeTabFullModal': 1, [`tour_fullModal_ActiveTabFullModal`]: 1 }); }}> {`Passport`} </NavLink>
                                    </NavItem>
                                    <NavItem className="pr-1">
                                        <NavLink className={classnames({ active: (this.state.activeTabFullModal === 2), ' border-0 rounded py-': true })} onClick={() => { this.setState({ 'activeTabFullModal': 2, [`tour_fullModal_ActiveTabFullModal`]: 2 }); }}> {`Visa`} </NavLink>
                                    </NavItem></>) : ''}
                                    {billingFullAccordians && (customer_id) ? (
                                    <NavItem className="pr-1">
                                        <NavLink className={classnames({ active: (this.state.activeTabFullModal === 3), ' border-0 rounded py-': true })} onClick={() => { this.setState({ 'activeTabFullModal': 3, [`tour_fullModal_ActiveTabFullModal`]: 3 }); }}> {`Billing`} </NavLink>
                                    </NavItem>
                                    ) : ''}
                                    {customer_id ? (
                                        <NavItem className="pr-1">
                                            <NavLink className={classnames({ active: (this.state.activeTabFullModal === 4), ' border-0 rounded py-': true })} onClick={() => this.getDocuments(customer_id)}> {`Documents`} </NavLink>
                                        </NavItem>
                                    ) : ''}
                                </Nav>
                            </div>
                            <div>
                                <TabContent activeTab={this.state.tour_fullModal_ActiveTabFullModal}>
                                    <TabPane tabId={1} className={ classnames({ "d-block": (this.state.tour_fullModal_ActiveTabFullModal === 1) }) }>
                                        <Row className="my-1">
                                            <div className="col-md-12">
                                                <div className="my-2 card card-body">
                                                    <div className="card-title">BASIC INFORMATION : </div>
                                                    <form className="">
                                                        <div className="row">
                                                            <input id={`passport_modal_full_id_${ii}`} ref={`passport_modal_full_id_${ii}`} type="hidden" className="form-control-sm form-control" value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['id']}/>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`passport_modal_full_passport_no_${ii}`}> Passport No </label>
                                                                <input id={`passport_modal_full_passport_no_${ii}`} ref={`passport_modal_full_passport_no_${ii}`} placeholder="Passport No" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['passport_noState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['passport_no']} onChange={(e) => this.handleChanges(e, 'passport', 'passport_no', ii, 1, /^[a-zA-Z0-9]*$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`passport_modal_full_nationality_${ii}`}> Nationality </label>
                                                                <input id={`passport_modal_full_nationality_${ii}`} ref={`passport_modal_full_nationality_${ii}`} placeholder="Nationality" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['nationalityState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['nationality']} onChange={(e) => this.handleChanges(e, 'passport', 'nationality', ii, 1, /^[a-zA-Z]*$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`passport_modal_full_place_of_birth_${ii}`}>  Place Of Birth </label>
                                                                <input id={`passport_modal_full_place_of_birth_${ii}`} ref={`passport_modal_full_place_of_birth_${ii}`} placeholder="Place Of Birth" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['place_of_birthState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['place_of_birth']} onChange={(e) => this.handleChanges(e, 'passport', 'place_of_birth', ii, 1, /^[a-zA-Z]*$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`passport_modal_full_place_of_issue_${ii}`}>  Place Of Issue </label>
                                                                <input id={`passport_modal_full_place_of_issue_${ii}`} ref={`passport_modal_full_place_of_issue_${ii}`} placeholder="Place Of Issue" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['place_of_issueState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['place_of_issue']} onChange={(e) => this.handleChanges(e, 'passport', 'place_of_issue', ii, 1, /^[a-zA-Z]*$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`passport_modal_full_date_of_issue_${ii}`}>  Date Of Issue </label>
                                                                <ReactDatetime isValidDate={validDob} closeOnSelect={true} className={`form-control form-control-sm p-0 h-auto border-0 ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['date_of_issueState']}`} inputProps={{ placeholder: 'Date Of Issue', className: `form-control form-control-sm cc-rdt-pos-top`, id: `passport_modal_full_date_of_issue_${ii}`, value: `${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['date_of_issue'] || ''}`, readOnly: true }} timeFormat={false} onChange={value => this.handleDateChanges(value, 'passport', 'date_of_issue', ii, 1, '') }/>
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`passport_modal_full_date_of_expiry_${ii}`}>  Date Of Expiry </label>
                                                                <ReactDatetime isValidDate={validFuture} closeOnSelect={true} className={`form-control form-control-sm p-0 border-0 h-auto ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['date_of_expiryState']}`} inputProps={{ placeholder: 'Date Of Expiry', className: `form-control form-control-sm cc-rdt-pos-top cc-rdt-pos-right`, id: `passport_modal_full_date_of_expiry_${ii}`, value: `${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['passport'] && tGrpList[ii].customers[0]['passport']['date_of_expiry'] || ''}`, readOnly: true }} timeFormat={false} onChange={value => this.handleDateChanges(value, 'passport', 'date_of_expiry', ii, 1, '') }/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId={2}  className={ classnames({ "d-block": (this.state.tour_fullModal_ActiveTabFullModal === 2) }) }>
                                        <Row className="my-1">
                                            <div className="col-md-12">
                                                <div className="my-2 card card-body">
                                                    <div className="card-title">BASIC INFORMATION : </div>
                                                    <form className="">
                                                        <div className="row">
                                                            <input id={`visa_modal_full_id_${ii}`} ref={`visa_modal_full_id_${ii}`} type="hidden" className="form-control-sm form-control" value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['id']}/>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`visa_modal_full_filed_by_${ii}`}>  Filed By </label>

                                                                <Select ref={`visa_modal_full_filed_by_${ii}`} id={`visa_modal_full_filed_by_${ii}`} className={`form-control form-control-sm p-0 visa_modal_full_filed_by_${ii} ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['filed_byState']}`} isClearable placeholder={`Select Filed By`} components={{ Option: IconOption }} isSearchable isMulti={0} name={`visa_modal_full_filed_by_${ii}`} options={vendorOptions} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['filed_byVal']} onChange={(value) => { this.handleSelectChanges(value, `visa`, 'filed_by', ii, 1, '') }} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`visa_modal_full_email_id_${ii}`}>  Email Id </label>
                                                                <input id={`visa_modal_full_email_id_${ii}`} ref={`visa_modal_full_email_id_${ii}`} placeholder="Email Id" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['email_idState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['email_id']} onChange={(e) => this.handleChanges(e, 'visa', 'email_id', ii, 1, /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`visa_modal_full_courier_no_${ii}`}>  Courier No </label>
                                                                <input id={`visa_modal_full_courier_no_${ii}`} ref={`visa_modal_full_courier_no_${ii}`} placeholder="Courier No" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['courier_numberState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['courier_number']} onChange={(e) => this.handleChanges(e, 'visa', 'courier_number', ii, 1, /^[a-zA-Z]*$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`visa_modal_full_courier_destination_${ii}`}>  Courier Dest. </label>
                                                                <input id={`visa_modal_full_courier_destination_${ii}`} ref={`visa_modal_full_courier_destination_${ii}`} placeholder="Courier Dest." type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['country_destinationState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['country_destination']} onChange={(e) => this.handleChanges(e, 'visa', 'country_destination', ii, 1, /^[a-zA-Z]*$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`visa_modal_full_visa_type_${ii}`}>  Visa Type </label>

                                                                <Select ref={`visa_modal_full_visa_type_id_${ii}`} id={`visa_modal_full_visa_type_id_${ii}`} className={`form-control form-control-sm p-0 visa_modal_full_visa_type_id_${ii} ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['visa_type_idState']}`} isClearable placeholder={`Select Visa Type`} components={{ Option: IconOption }} isSearchable isMulti={0} name={`visa_modal_full_visa_type_id_${ii}`} options={visaTypeOptions} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['visa_type_idVal']} onChange={(value) => { this.handleSelectChanges(value, `visa`, 'visa_type_id', ii, 1, '') }} />

                                                                {/* <input id={`visa_modal_full_visa_type_${ii}`} ref={`visa_modal_full_visa_type_${ii}`} placeholder="Visa Type" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['visa_type_idState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['visa_type_id']} onChange={(e) => this.handleChanges(e, 'visa', 'visa_type_id', ii, 1, '')} /> */}
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`visa_modal_full_no_of_days_${ii}`}> No. Of Days </label>
                                                                <input id={`visa_modal_full_no_of_days_${ii}`} ref={`visa_modal_full_no_of_days_${ii}`} placeholder="No. Of Days" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['no_of_daysState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['no_of_days']} onChange={(e) => this.handleChanges(e, 'visa', 'no_of_days', ii, 1, /^\d+$/)} />
                                                            </div>
                                                            <div className="mb-2 col-md-3">
                                                                <label className="form-control-label" htmlFor={`visa_modal_full_entry_${ii}`}> Entry </label>
                                                                <input id={`visa_modal_full_entry_${ii}`} ref={`visa_modal_full_entry_${ii}`} placeholder="Entry" type="text" className={`form-control-sm form-control ${tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['entryState']}`} value={tourGroupsList && tGrpList[ii] && tGrpList[ii].customers && tGrpList[ii].customers[0] && tGrpList[ii].customers[0]['visa'] && tGrpList[ii].customers[0]['visa']['entry']} onChange={(e) => this.handleChanges(e, 'visa', 'entry', ii, 1, /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/)} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Row>
                                    </TabPane>
                                    {billingFullAccordians && (customer_id) ? (
                                    <TabPane tabId={3}  className={ classnames({ "d-block": (this.state.tour_fullModal_ActiveTabFullModal === 3) }) }>
                                        <Row className="my-1">
                                            <Col md="12">
                                                <Card className="card-plain mt-3 mb-2">
                                                    <CardHeader role="tab" onClick={() => collapsesToggle(`collapse_full_${ii}`)} aria-expanded={openedCollapses.includes( `collapse_full_${ii}` )} className="py-2 border-0 rounded cursor-pointer" >
                                                        <div className="row m-0  height-md-30px">
                                                            <div className="col-md-3 col-sm-12 height-30px pl-0">
                                                                <h3 className="mb-0 d-block">
                                                                <em><small>{`Tour`}</small></em> : <span className="text-success float-right">{billingFull[ii] && billingFull[ii][`price`] || 0}</span>
                                                                </h3>
                                                            </div>
                                                            <div className="col-1 text-center d-none d-md-block border-left">
                                                            </div>
                                                            <div className="col-md-3 col-sm-12 height-30px pl-0 pr-lg-0">
                                                                <h3 className="mb-0 d-block">
                                                                    <em><small>Customer Paid</small></em> : <span className="text-primary float-right w-50"> <input type="number" className={`form-control form-control-sm cc-form-control text-primary text-right billing_full_${ii}_paid`} value={billingFull[ii] &&billingFull[ii][`paid`] || ''} ref={`billing_full_${ii}_paid`} onChange={e => { handleBillingInputsChange(ii, e, "", 'paid') }} onBlur={(e) => handleBillingInputsBlur(ii, e, "", 'paid')} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} />
                                                                </span>
                                                                </h3>
                                                            </div>
                                                            <div className="col-1 text-center d-none d-md-block border-right">
                                                            </div>
                                                            <div className="col-md-3 col-sm-12 height-30px">
                                                                <h3 className="mb-0 d-block">
                                                                    <em><small>Balance</small></em> : <span className={ classnames({ "text-info": (billingFull[ii] && billingFull[ii][`bal`] || 0) >= 0, "text-danger": (billingFull[ii] && billingFull[ii][`bal`] || 0) < 0 , "float-right": true }) }>{billingFull[ii] && parserFloat(billingFull[ii][`bal`]).toFixed(2) || 0}</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    <Collapse role="tabpanel" isOpen={openedCollapses.includes(`collapse_full_${ii}`)} >
                                                        <CardBody>
                                                            <Form className="needs-validation" noValidate>
                                                                <div className="form-row mb-2">
                                                                    <Col md="6" sm="12"> </Col>
                                                                    <Col md="6" sm="12">
                                                                        <Col className="d-flex justify-content-start align-items-center">
                                                                            <Col md="7" sm="12" className="justify-content-end d-flex">
                                                                            <label className="form-control-label mb-0 mr-2">{`Tour`}: </label></Col><Col md="5" sm="12">
                                                                            <input type="text" className={`form-control form-control-sm cc-form-control billing_full_${ii}_o_price`} value={billingFull[ii] && billingFull[ii]['o_price'] || 0} ref={`billing_full_${ii}_o_price`} onChange={e => { handleBillingInputsChange(ii, e, "", 'o_price') }} onBlur={(e) =>handleBillingInputsBlur(ii, e, "", 'o_price')} /></Col>
                                                                        </Col>
                                                                        
                                                                        <Col className="d-flex justify-content-start align-items-center">
                                                                            <Col md="7" sm="12" className="justify-content-end d-flex">
                                                                            <label className="form-control-label mb-0 mr-2">Others: </label></Col><Col md="5" sm="12">
                                                                            <input type="text" className={`form-control form-control-sm cc-form-control billing_full_${ii}_others`} value={billingFull[ii] && billingFull[ii]['others'] || 0} ref={`billing_full_${ii}_others`} onChange={e => { handleBillingInputsChange(ii, e, "", 'others') }} onBlur={(e) =>handleBillingInputsBlur(ii, e, "", 'others')} /></Col>
                                                                        </Col>
                                                                        
                                                                        <Col className="d-flex justify-content-start align-items-center">
                                                                            <Col md="7" sm="12" className="justify-content-end d-flex">
                                                                            <label className="form-control-label mb-0 mr-2">Service Charge: </label></Col><Col md="5" sm="12">
                                                                            <input type="text" className={`form-control form-control-sm cc-form-control billing_full_${ii}_service`} value={billingFull[ii] && billingFull[ii]['service'] || 0} ref={`billing_full_${ii}_service`} onChange={e => { handleBillingInputsChange(ii, e, "", 'service') }} onBlur={(e) =>handleBillingInputsBlur(ii, e, "", 'service')} /></Col>
                                                                        </Col>
                                                                        
                                                                        <Col className="d-flex justify-content-start align-items-center">
                                                                            <Col md="7" sm="12" className="justify-content-end d-flex">
                                                                            <label className="form-control-label mb-0 mr-2 font-italic">Service Tax: </label></Col><Col md="5" sm="12">
                                                                            <input type="text" readOnly ref={`billing_full_${ii}_service_tax`} className={`form-control form-control-sm cc-form-control billing_full_${ii}_service_tax`} value={billingFull[ii] && billingFull[ii]['service_tax'] || 0} /></Col>
                                                                        </Col>
                                                                    </Col>
                                                                </div>
                                                            </Form>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>) : ''}
                                    {customer_id ? (
                                    <TabPane tabId={4}  className={ classnames({ "d-block":  (this.state.tour_fullModal_ActiveTabFullModal === 4) }) }>
                                        <Row className="my-3">
                                            <div className="col-md-12">
                                                <div className="card mb-0 py-3 px-1">
                                                    <Row className="mx-0">
                                                        <div className="col-5">
                                                            <label htmlFor="doc_type" className="form-control-label">Document Type:</label>
                                                            <Select ref={`select_doc_type`} id={`doc_type`} className={`form-control form-control-sm p-0 select_doc`} isClearable placeholder={`Select Document Type`} components={{ Option: IconOption }} isSearchable isMulti={0} name={`doc_type`} options={doc_type_options} value={this.state.doc_type[`${customer_id}`]} onChange={(value) => { this.onSelectChange(value, `doc_type`, customer_id) }} />
                                                        </div>
                                                        <div className="col-3">
                                                            <label htmlFor="doc_type" className="form-control-label invisible d-block">Document Type:</label>
                                                            <Button
                                                                className="doc_type_add mt-1"
                                                                color="primary"
                                                                type="button"
                                                                size="sm"
                                                                onClick={(e) => this.addDocType(e, `doc_type`, customer_id)}
                                                            >
                                                                Add Document
                                                            </Button>
                                                        </div>
                                                        <div className="col-12">
                                                        {doc_type_list[customer_id]}
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Row>
                                    </TabPane>) : ''}
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>) : ''}
                </Col>
                
                <Col md="12">
                    <hr className="mb-2 mt-4"/>
                    <button className="btn btn-sm btn-success" onClick={(e) => this.onSaveItem(e, ii) }>Save</button>
                    {isIndBilling ? (<>{nextButton}{prevButton}</>) : ''}
                </Col>
                </Row>
                </div>);
        }
        return (
            <Modal
                className="modal-full modal-cc-full"
                isOpen={isOpen}
                toggle={() => { closeModal() }}
                >
                <div className="modal-header">
                    <h5 className="modal-title w-100" id="exampleModalLabel">
                        <span>{grpName} - {(isIndBilling) ? (`Customer ${currCount} of ${memCount}`) : 'Group Incharge' }</span>
                        <span className="search-wrapper ml-2">
                            <span className="search-icon">
                                <img src={require('../../assets/img/icons/search.png')} height={22} width={22} className="cursor-pointer" onClick={(e) => { this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon'); }} />
                            </span>
                            <span className="search-input-wrapper ml-lg-8 ml-0">
                                <Autocomplete setStatesModalFull={(obj) => this.setStatesModalFull(obj)} additionalClasses="col-md-6 d-inline" suggestions={['One', 'Two', 'Three']} />
                                <span className="input-clear-icon cursor-pointer ml--4" onClick={(e) => this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon')}>&times;</span>
                            </span>
                        </span>
                    </h5>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => closeModal()}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                    <div className={ classnames({ "col-md-9 px-0": isIndBilling, "col-md-12 px-0": !isIndBilling }) } style={{ overflow: 'hidden' }}>
                        <div className="bounceInLeft" style={{ width: `${100 * memCount}%`, display: 'flex', position: 'relative', left: `${this.state.left}`, transition: 'left cubic-bezier(.215,.61,.355,1) 0.3s' }}>
                            {modalContent}
                        </div>
                    </div>
                    {(isIndBilling) ? (
                    <div className="col-md-3">
                        <div className={ classnames({ "input-group mb-2 input-group-merge": true, "focused": this.state.searchFocussed || false }) }>
                            <input type="text" className="form-control form-control-sm border-right-0 px-2" placeholder="Search..." value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }); }} onFocus={() => { this.setState({ searchFocussed: true }) }} onBlur={() => { this.setState({ searchFocussed: false }) }} />
                            <div className="input-group-append">
                                <span className="input-group-text py-0 px-2">
                                    <i className="fas fa-search"></i>
                                </span>
                            </div>
                        </div>

                        {/* <InputGroup size="sm" className="mb-2">
                            <Input type="text" className="form-control form-control-sm" onChange={(e) => { this.setState({ search: e.target.value }); }} placeholder="Search..." />
                            <InputGroupAddon addonType="append"><i className="fas fa-search"></i></InputGroupAddon>
                        </InputGroup> */}
                        {(sidebarContent.length === 0) ? "No data found.." : sidebarContent}
                    </div>
                    ) : ''}
                    </Row>
                </div>
                <div className="modal-footer">
                    
                </div>
                </Modal>
        );
    }
}

function mapStateToProps(state) {
    const { customer, tourGroupsInsert, billing, document, services, customersList } = state;
    return { customer, tourGroupsInsert, billing, document, services, customersList };
}

const ModalFullSectionPage = connect(mapStateToProps)(ModalFull);
export { ModalFullSectionPage as ModalFull };
import { userConstants } from '../_constants';

export function billing(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_BILLING_REQUEST:
      return {
        document: action.data
      };
    case userConstants.CREATE_BILLING_SUCCESS:
      return {
        document: action.data
      };
    case userConstants.CREATE_BILLING_FAILURE:
      return {};
    default:
      return state
  }
}
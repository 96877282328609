export const _pettycashCreate = [
    {
        "name": "date",
        "label": "Date",
        "id": "date",
        "class": "form-control-sm",
        "state": "dateState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "",
        "validFeedback": "",
        "bs4Size": "3",
        "type": "date",
        "isReq":true,
       
    },
    {
    "name": "expenses_type",
    "label": "Expenses Type",
    "id": "expenses_type",
    "class": "form-control-sm",
    "state": "expenses_typeState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The Expenses Type field is Required.",
    "validFeedback": "",
    "bs4Size": "3",
    "type": "select",
    "multiple": false,
    "isReq":true,
    "option": [
        { value: 'Staff Welfare Expenses', label: 'Staff Welfare Expenses' },
        { value: 'Office Maintenance', label: 'Office Maintenance' }
    ],
},
{
    "name": "pay_mode",
    "label": "Pay Mode",
    "id": "pay_mode",
    "class": "form-control-sm",
    "state": "pay_modeState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The Pay Mode field is Required.",
    "validFeedback": "",
    "bs4Size": "3",
    "type": "select",
    "multiple": false,
    "isReq":true,
    "option": [
        { value: 'Cash', label: 'Cash' },
        { value: 'Cheque', label: 'Cheque' },
        { value: 'Debit/Credit Card', label: 'Debit/Credit Card' }
    ],
},
// {
//     "name": "status",
//     "label": "Status",
//     "id": "status",
//     "class": "form-control-sm",
//     "state": "statusState",
//     "valid": "valid",
//     "invalid": "invalid",
//     "icon": "fas fa-user",
//     "iconPosition": "prepend",
//     "invalidFeedback": "The status field is Required.",
//     "validFeedback": "",
//     "bs4Size": "3",
//     "type": "select",
//     "multiple": false,
//     "isReq":true,
   
// },
{
    "name": "spent_amount",
    "label": "Spent Amount",
    "id": "spent_amount",
    "class": "form-control-sm",
    "state": "spent_amountState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "The Spent Amount field is Required.",
    "validFeedback": "",
    "bs4Size": "3",
    "type": "input",
    "isReq":true,
    "regex":/^\d{0,12}(\.\d{1,2})?$/,
    "regexInvalid":"Need Valid Decimal Format."
   
},
{
    "name": "invoice_no",
    "label": "Invoice No",
    "id": "invoice_no",
    "class": "form-control-sm",
    "state": "invoice_noState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "",
    "validFeedback": "",
    "bs4Size": "3",
    "type": "input",
    "isReq":false,
    "regex":/^[a-zA-Z0-9]{3,20}$/,
    "regexInvalid":"Need Valid Format."
   
},
{
    "name": "paid_to",
    "label": "Paid To",
    "id": "paid_to",
    "class": "form-control-sm",
    "state": "paid_toState",
    "valid": "valid",
    "invalid": "invalid",
    "icon": "fas fa-user",
    "iconPosition": "prepend",
    "invalidFeedback": "",
    "validFeedback": "",
    "bs4Size": "3",
    "type": "input",
    "isReq":false,
    "regex":/^[a-zA-Z ]*$/,
    "regexInvalid":"Need Valid Format."
    },
    {
        "name": "tax",
        "label": "Tax",
        "id": "tax",
        "class": "form-control-sm",
        "state": "taxtState",
        "valid": "valid",
        "invalid": "invalid",
        "icon": "fas fa-user",
        "iconPosition": "prepend",
        "invalidFeedback": "The Tax field is Required.",
        "validFeedback": "",
        "bs4Size": "2",
        "type": "togglebox",
        "isReq":true
        },
        {
            "name": "gst",
            "label": "GST No.",
            "id": "gst",
            "class": "form-control-sm",
            "state": "gstState",
            "valid": "valid",
            "invalid": "invalid",
            "icon": "fas fa-user",
            "iconPosition": "prepend",
            "invalidFeedback": "Gst No field is required",
            "validFeedback": "",
            "bs4Size": "2",
            "type": "input",
            "isReq":false,
            "regex":/^[a-zA-Z0-9 ]{12,20}$/,
            "regexInvalid":"Need Valid Format."
            },
           {
            "name": "description",
            "label": "Description",
            "id": "description",
            "class": "form-control-sm",
            "state": "descriptionState",
            "valid": "valid",
            "invalid": "invalid",
            "icon": "fas fa-user",
            "iconPosition": "prepend",
            "invalidFeedback": "The description field is Required.",
            "validFeedback": "",
            "bs4Size": "5",
            "type": "input",
            "isReq":true,
        
        },
        
];

// import Alternative from "views/pages/dashboards/Alternative.jsx";
// import Buttons from "views/pages/components/Buttons.jsx";
// import Calendar from "views/pages/Calendar.jsx";
// import Cards from "views/pages/components/Cards.jsx";
// import Charts from "views/pages/Charts.jsx";
// import Components from "views/pages/forms/Components.jsx";
// import Dashboard from "views/pages/dashboards/Dashboard.jsx";
// import Elements from "views/pages/forms/Elements.jsx";
// import Google from "views/pages/maps/Google.jsx";
// import Grid from "views/pages/components/Grid.jsx";
// import Icons from "views/pages/components/Icons.jsx";
// import Lock from "views/pages/examples/Lock.jsx";
// import Excel from "./_views/_excel/Excel.jsx";
import  Login  from "views/pages/examples/Login.jsx"; 
import { listWallet } from "./_views/_wallet/_list";
import { CustomerHistory } from "./_views/_history/_index";
import  Userslist  from "./_views/_user/_create";
import { NotificationList } from "./_views/_notification/_list";
import Dashboard from "./_views/_dashboard/dashboard";
// import Notifications from "views/pages/components/Notifications.jsx";
// import Pricing from "views/pages/examples/Pricing.jsx";
// import Profile from "views/pages/examples/Profile.jsx";
// import ReactBSTables from "views/pages/tables/ReactBSTables.jsx";
// import Register from "views/pages/examples/Register.jsx";
// import Sortable from "views/pages/tables/Sortable.jsx";
// import Tables from "views/pages/tables/Tables.jsx";
// import Timeline from "views/pages/examples/Timeline.jsx";
// import Typography from "views/pages/components/Typography.jsx";
// import Validation from "views/pages/forms/Validation.jsx";
// import Vector from "views/pages/maps/Vector.jsx";
// import Widgets from "views/pages/Widgets.jsx";
// import ForgotPassword from "./views/pages/examples/ForgotPassword";

// import TicketList from './_views/_tickets/_list';
import { Create, Edit, List, Service } from './_views/_tickets';
import { Createtask } from './_views/_task/_create';
import { Myprofile } from './_views/_profile/_create';
import { VehiclesManage } from './_views/_vehicles/_index';
import { Pettycash } from './_views/_accounts/pettycash';
import { Maintenance } from './_views/_accounts/maintenance';
import Remainder from './_views/_remainder/remainder';
import EnquiryList from "./_views/_enquiry/_create";
import EnquiryForm from "./_views/_enquiryForm/_createForm";
import EditEnquiry from "./_views/_enquiry/_editEnquiry";
import EnquiryResTable from "./_views/_enquiry/_resTable";
import CreateUser from "./_views/_user/createUser";
import EditUsers from "./_views/_user/_editUsers";
import DashboardFunc from './views/pages/dashboards/DashboardFunc';
import CreateRemainder from './_views/_remainder/createRemainder';
import EditRemainder from './_views/_remainder/editRemainder';
import DashboardFuncAssignee from "./views/pages/dashboards/DashboardFuncAssignee";

const routes = [
  {
    path: '/remainder-edit/:id',
    name: 'Remainder-Edit',
    icon: 'fas fa-question-circle text-primary',
    component: EditRemainder,
    isHidden: true,
    layout: '/admin',
    class_name: 'Remainder-menu',
    isEmployeeVisible: false,
  },
  {
    path: '/remainder',
    name: 'Remainder',
    icon: 'ni ni-shop text-primary',
    component: Remainder,
    layout: '/admin',
    isHidden: true,
    isEmployeeVisible: true,
  },
  {
    path: '/create/remainder',
    name: 'Remainder-Create',
    icon: 'fas fa-users text-primary',
    component: CreateRemainder,
    layout: '/admin',
    isHidden: true,
    isEmployeeVisible: false,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-shop text-primary',
    component: DashboardFunc,
    layout: '/admin',
    isHidden: true,
    isEmployeeVisible: true,
    class_name: 'dashboard-menu',
  },
  {
    path: '/dashboardAssignee',
    name: 'Dashboard',
    icon: 'ni ni-shop text-primary',
    component: DashboardFuncAssignee,
    layout: '/admin',
    isHidden: true,
    isEmployeeVisible: true,
    class_name: 'dashboard-ass-menu',
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-shop text-primary',
    component: Login,
    layout: '/auth',
    isHidden: true,
    isEmployeeVisible: true,
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: 'fas fa-layer-group text-primary',
  //   component: Dashboard,
  //   layout: '/admin',
  //   class_name: 'dashboard-menu',
  //   isEmployeeVisible: true,
  // },
  // {
  //   path: '/wallet',
  //   name: 'Wallet',
  //   icon: 'ni ni-briefcase-24 text-primary',
  //   component: listWallet,
  //   layout: '/admin',
  //   class_name: 'wallet-menu',
  //   isEmployeeVisible: false,
  //   isHidden: true,
  // },
  {
    path: '/user',
    name: 'User',
    icon: 'fas fa-users text-primary',
    component: Userslist,
    layout: '/admin',
    class_name: 'user-menu',
    isEmployeeVisible: false,
  },

  {
    path: '/create/user',
    name: 'User-Create',
    icon: 'fas fa-users text-primary',
    component: CreateUser,
    isHidden: true,
    layout: '/admin',
    class_name: 'user-menu',
    isEmployeeVisible: false,
  },
  {
    path: '/users-edit/:id',
    name: 'User-Edit',
    icon: 'fas fa-question-circle text-primary',
    component: EditUsers,
    isHidden: true,
    layout: '/admin',
    class_name: 'user-menu',
    isEmployeeVisible: false,
  },
  // {
  //   path: '/vehicles',
  //   name: 'Vehicles',
  //   icon: 'fas fa-truck-pickup text-primary',
  //   component: VehiclesManage,
  //   layout: '/admin',
  //   class_name: 'vehicle-menu',
  //   isEmployeeVisible: true,
  //   isHidden: true,
  // },
  // {
  //   path: '/myprofile',
  //   name: 'profile',
  //   icon: 'fas fa-users text-primary',
  //   component: Myprofile,
  //   layout: '/admin',
  //   isHidden: true,
  //   isEmployeeVisible: true,
  // },
  // {
  //   collapse: true,
  //   name: 'Tickets',
  //   icon: 'ni ni-book-bookmark text-primary',
  //   state: 'ticketsCollapse',
  //   class_name: 'ticket-menu',
  //   isHidden: true,
  //   isEmployeeVisible: true,
  //   views: [
  //     {
  //       views: [
  //         {
  //           path: '/tickets/create',
  //           name: 'Create Tickets',
  //           component: Create,
  //           layout: '/admin',
  //           isEmployeeVisible: true,
  //         },
  //         {
  //           path: '/tickets/list',
  //           name: 'List Tickets',
  //           component: List,
  //           layout: '/admin',
  //           isEmployeeVisible: true,
  //         },
  //         {
  //           path: '/tickets/edit/:id',
  //           name: 'Edit Ticket',
  //           component: Edit,
  //           layout: '/admin',
  //           isHidden: true,
  //           isEmployeeVisible: true,
  //         },
  //         {
  //           path: '/tickets/service/:id',
  //           name: 'Service Ticket',
  //           component: Service,
  //           layout: '/admin',
  //           isHidden: true,
  //           isEmployeeVisible: true,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: '/notifications',
  //   name: 'Notifications',
  //   icon: 'fas fa-bell text-primary',
  //   component: NotificationList,
  //   layout: '/admin',
  //   class_name: 'notification-menu',
  //   isEmployeeVisible: true,
  //   isHidden: true,
  // },
  // {
  //   path: '/task/:id',
  //   name: 'Task',
  //   icon: 'ni ni-briefcase-24 text-primary',
  //   component: Createtask,
  //   layout: '/admin',
  //   isHidden: true,
  //   isEmployeeVisible: false,
  // },
  // {
  //   path: '/task',
  //   name: 'Task',
  //   icon: 'ni ni-briefcase-24 text-primary',
  //   component: Createtask,
  //   layout: '/admin',
  //   class_name: 'task-menu',
  //   isEmployeeVisible: false,
  //   isHidden: true,
  // },
  // {
  //   collapse: true,
  //   name: 'Accounts',
  //   icon: 'ni ni-book-bookmark text-primary',
  //   state: 'accountsCollapse',
  //   class_name: 'accounts-menu',
  //   isEmployeeVisible: true,
  //   isHidden: true,
  //   isEmployeeRole: true,
  //   views: [
  //     {
  //       views: [
  //         {
  //           path: '/accounts/pettycash',
  //           name: 'Petty Cash',
  //           component: Pettycash,
  //           layout: '/admin',
  //           isEmployeeVisible: true,
  //         },
  //         {
  //           path: '/accounts/maintenance',
  //           name: 'Money Maintenance',
  //           component: Maintenance,
  //           layout: '/admin',
  //           isEmployeeVisible: false,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: '/customer-history',
  //   name: 'Customer History',
  //   icon: 'fas fa-history text-primary',
  //   component: CustomerHistory,
  //   layout: '/admin',
  //   class_name: 'history-menu',
  //   isEmployeeVisible: false,
  //   isHidden: true,
  // },
  {
    path: '/enquiry',
    name: 'Enquiry',
    icon: 'fas fa-question-circle text-primary',
    component: EnquiryList,
    layout: '/admin',
    class_name: 'user-menu',
    isEmployeeVisible: true,
  },
  {
    path: '/enquiry-form',
    name: 'EnquiryForm',
    icon: 'fas fa-question-circle text-primary',
    component: EnquiryForm,
    isHidden: true,
    layout: '/admin',
    class_name: 'user-menu',
    isEmployeeVisible: true,
  },
  {
    path: '/enquiry-edit/:id/:custId',
    name: 'EnquiryEdit',
    icon: 'fas fa-question-circle text-primary',
    component: EditEnquiry,
    isHidden: true,
    layout: '/admin',
    class_name: 'user-menu',
    isEmployeeVisible: true,
  },
  {
    path: '/enquiry-res-table',
    name: 'EnquiryTable',
    icon: 'fa fa-table',
    component: EnquiryResTable,
    isHidden: true,
    layout: '/admin',
    class_name: 'user-menu',
    isEmployeeVisible: true,
  },
];

export default routes;

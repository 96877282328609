import { combineReducers } from 'redux';

import { authentication } from './authentication.reducers';
import { authUserReducer } from '../redux/authUser/auth.reducer';
import { users } from './users.reducers';
import { service, services, listVisaTypes } from './service.reducers';
import { status } from './status.reducers';
import { assigned } from './assigned.reducers';
import { department } from './department.reducers';
import { alert } from './alert.reducers';
import { billing } from './billing.reducers';
import { comment } from './comment.reducers';
import { customer, customersList } from './customer.reducers';
import { document } from './document.reducers';
import { expenses } from './expenses.reducers';
import { logs } from './logs.reducers';
import { ticketService } from './ticketService.reducers';
import { ticket, ticketStatus, ticketDelete, ticketGraph } from './ticket.reducers';
import { documenttype } from './documenttype.reducers';
import { vendor } from './vendor.reducers';
import { serviceFields } from './serviceFields.reducers';
import { customerservice } from './customerservice.reducers';
import { wallet,list } from './wallet.reducers';
import { special } from './special.reducers';
import { usercreate, userlist } from './usercreate.reducers';
import { notificationcreate, notificationupdate, notificationlist } from './notification.reducers';
import { tourCountCreate, tourPlacesCreate, tourCountlist, tourPlacesUpdate, tourCreate, tourCountUpdate, tourTypeslist, tourPlaceslist, tourUpdate, createGroupCount, updateGroupCount, tourGroupsInsert, tourGroupsUpdate, tourGroupsList, tourExpensesInsert, tourExpensesUpdate } from './tour.reducers';
import { hotelExpensesInsert,  hoteldelete,  hotelExpensesUpdate,  hotellist } from './hotel.reducers';
import {tasklist,taskcreate,alluserlist} from './task.reducers';
import { placesCreate } from './places.reducers';
import { insurance } from './insurance.reducers';
import { vehicle, vehicleImages, vehicleAcInfo, vehicleNonAcInfo, vehicleStages, vehicleUpdateStages } from './vehicles.reducers';
import { maintenancecreate,maintenancelist } from './maintenance.reducers';
import { pettylist,pettycreate } from './pettycash.reducers';
import { vendorservice } from './vendorservice.reducers';
// import { request } from './request.reducers';

const rootReducer = combineReducers({
  authentication,
  authUserReducer,
  users,
  service,
  services,
  listVisaTypes,
  status,
  assigned,
  department,
  alert,
  billing,
  comment,
  customer,
  customersList,
  document,
  expenses,
  logs,
  ticketService,
  ticket,
  ticketStatus,
  ticketDelete,
  ticketGraph,
  serviceFields,
  documenttype,
  customerservice,
  vendor,
  special,
  wallet,
  list,
  tourCountCreate,
  tourCountUpdate,
  tourTypeslist,
  tourPlaceslist,
  tourPlacesCreate,
  tourPlacesUpdate,
  tourCreate,
  tourCountlist,
  tourUpdate,
  createGroupCount,
  updateGroupCount,
  tourGroupsInsert,
  tourGroupsUpdate,
  tourGroupsList,
  tourExpensesInsert,
  tourExpensesUpdate,
  usercreate,
  userlist,
  hotelExpensesInsert,
  hoteldelete,
  hotelExpensesUpdate,
  hotellist,
  notificationcreate, notificationupdate, notificationlist,
  tasklist,
  taskcreate,
  alluserlist,
  placesCreate,
  insurance,
  vehicle, vehicleImages, vehicleAcInfo, vehicleNonAcInfo, vehicleStages, vehicleUpdateStages,
  maintenancecreate,
  maintenancelist,
  pettylist,
  pettycreate,
  vendorservice
});
// request,

export default rootReducer;
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import moment from "moment";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import style from '../../_views/_enquiry/enquiry.module.css';
import styles from './adminnavbar.module.css';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { Link, useHistory, withRouter } from 'react-router-dom';
// reactstrap components
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, ButtonDropdown, Form, Input, InputGroupAddon, InputGroupText, InputGroup, ListGroupItem, ListGroup, Media, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { globalConstants } from "../../_constants";
import { toast } from 'react-toastify';
import axios from 'axios';

class AdminNavbar extends React.Component {
  state = {
    nData: [],
    nTotal: 0,
    userId: '',
    notification_Total_count: 0,
  };
  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  };

  handleLogout(e) {
    const { loggedIn, user, dispatch } = this.props;
    toast('Logout successfully!..');
    e.preventDefault();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.props.history.push('/login');
    window.location.reload();
  }

  handleUserProfile(e) {
    const { loggedIn, user, dispatch } = this.props;
    e.preventDefault();
    this.props.history.push(`/admin/users-edit/${this.state.userId}`);
    window.location.reload();
  }

  componentDidMount() {
    let local = localStorage.user;
    if (local) {
      
      let newLocal = JSON.parse(local);
      this.setState({loginUserFirst : newLocal.firstname})
      this.setState({loginUserLast : newLocal.lastname})
      console.log(newLocal.id);
      axios
        .get(
          globalConstants.BASEURL +
            `api/notification_count/?userid=${newLocal.id}`
        )
        .then((res) => {
          console.log(
            'notification_count_data',
            res.data[0].notification_count
          );
          this.setState({notification_Total_count: res.data[0].notification_count});
        })
        .catch((err) => {
          console.log(err, 'notification-err-for-count');
        });
    }

    const user = localStorage.getItem('user');
    if (user) {
      console.log('admin_navbar_login_user', user);
      let parseUser = JSON.parse(user);
      console.log('parserUerParserUser', parseUser.id);
      this.setState({
        userId: parseUser.id,
      });
    }
  }

  // componentDidMount(){
  //   // const { loggedIn, user, dispatch } = this.props;
  //   const user = localStorage.getItem('user');
  //   console.log('admin_navbar_login_user', user);
  //   let parseUser = JSON.parse(user);
  //   const token = localStorage.getItem('token');
  //   if(!user){
  //     return this.props.history.push('/');
  //   }
  //   const { id } = parseUser && parseUser.id;
  //   let notificationData = { user_id: id, status: 0, page: 1, count: 5 };

  //   // dispatch(userActions.listNotification(notificationData)).then(() => {
  //   //   const { notificationlist } = this.props;
  //   //   const { notification } = notificationlist;
  //   //   const { notifications } = notification;
  //   //   const { data, total } = notifications;
  //   //   if(total)
  //   //     this.setState({ nData: data, nTotal: total });
  //   // });
  // }

  // componentDidUpdate(prevProps, prevState, snapShot){
  //   // const { loggedIn, user } = this.props;
  //    const user = localStorage.getItem('user');
  //    console.log('admin_navbar_login_user', user);
  //    let parseUser = JSON.parse(user);
  //    const token = localStorage.getItem('token');
  //   const {role} = parseUser;
  //   if(!user){ return this.props.history.push('/'); }
  // }

  jsucwords(str) {
    str = str.toLowerCase().trim();
    return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
  }
  triggertaskd(e, v) {
    const { dispatch, user } = this.props;
    this.setState({
      loading: true,
    });

    if (e.target.checked == true) {
      var user_id = user.user.id;
      var notifi_status = 1;
    } else {
      var user_id = null;
      var notifi_status = 0;
    }
    dispatch(userActions.updatetaskstatus(v, user_id, notifi_status)).then(
      () => {
        let notificationData = { user_id: user.user.id, page: 1, count: 5 };
        this.notificationDispatch1(notificationData);
      }
    );
  }

  notificationDispatch1(notificationData) {
    const { dispatch, type } = this.props;
    const { nDataList, nData } = this.state;
    dispatch(userActions.listNotification(notificationData)).then(() => {
      const { notificationlist } = this.props;
      const { notification } = notificationlist;
      const { notifications } = notification;
      const { data, total } = notifications;
      this.setState({
        nData: data,
        nTotal: total,
        nDataList: nDataList,
        loading: false,
        _isShowMore:
          nData.length > 0 ? nData.length < total : data.length < total,
      });
    });
  }

  triggerNotified(e, v) {
    e.preventDefault();
    e.stopPropagation();
    const { dispatch } = this.props;
    let elem = e.currentTarget;

    let notificationData = {
      id: v.id,
      t_id: v.t_id,
      type: v.type,
      notification_status: 1,
      assigned_to: v.assigned_to,
    };
    dispatch(userActions.updateNotification(notificationData)).then(() => {
      if (elem) {
        let elemRemove =
          elem.parentNode.parentNode.parentNode.parentNode.parentNode;
        elemRemove.parentNode.removeChild(elemRemove);
      }
      this.setState({ nTotal: this.state.nTotal - 1 });
    });
  }

  imgClick() {
    const { user } = this.props;
    let userName = user && user.user && user.user.name;
    this.setState({ Usernames: userName });
  }

  removeNotification(ticket_id, customer_id, type, id) {
    console.log(type, 'type of remove');
    console.log(ticket_id, 'remove notification id');
    console.log(id, 'dash notification id');
    console.log(this.state.notification_data, 'notifcation remove data');
    if (type === 'remove') {
      let notification_data = this.state.notification_data;
      let new_notification_data = notification_data.filter(
        (item) => item.notify_id !== id
      );
      console.log(new_notification_data, 'new notification Data');
      // let admin_notification_data = this.state.admin_notification_data;
      // let new_admin_notification_data = admin_notification_data.filter(
      //   (item) => item.id !== ticket_id
      // )
      // console.log(new_admin_notification_data, 'new admin notification Data');
      this.setState({
        notification_count: this.state.notification_Total_count - 1,
        // notification_count: this.state.notification_Total_count - 1,
        notification_Total_count: this.state.notification_Total_count - 1,
        notification_data: new_notification_data,
        // admin_notification_data: new_admin_notification_data,
      });
      axios
        .get(
          globalConstants.BASEURL +
            `api/notification_remove/?removeNotificationid=${ticket_id}&notifyId=${id}`
        )
        .catch((err) => {
          console.log(err, 'notification-remove-error');
        });
    } else if (type === 'view') {
      document.getElementById('hide-notification-menu').style.display = 'none';
      let notification_data = this.state.notification_data;
      let new_notification_data = notification_data.filter(
        (item) => item.notify_id !== id
      );
      console.log(new_notification_data, 'new notification Data');
      //  let admin_notification_data = this.state.admin_notification_data;
      //  let new_admin_notification_data = admin_notification_data.filter(
      //    (item) => item.id !== ticket_id
      //  );
      //  console.log(new_admin_notification_data, 'new admin notification Data');
      this.setState({
        notification_count: this.state.notification_Total_count - 1,
        notification_Total_count: this.state.notification_Total_count - 1,
        notification_data: new_notification_data,
        // admin_notification_data: new_admin_notification_data,
      });
      axios
        .get(
          globalConstants.BASEURL +
            `api/notification_remove/?removeNotificationid=${ticket_id}&notifyId=${id}`
        )
        .then((res) => {
          this.props.history.push(
            `/admin/enquiry-edit/${ticket_id}/${customer_id}`
          );
          window.location.reload();
        })
        .catch((err) => {
          console.log(err, 'notification-remove-error');
        });
    }
  }

  removeAdminNotification(id, customer_id, type, notify_id) {
    console.log(type, 'type of remove');
    console.log(id, 'remove notification id');
    console.log(this.state.admin_notification_data, 'notifcation remove data');
    if (type === 'remove') {
      let admin_notification_data = this.state.admin_notification_data;
      let new_admin_notification_data = admin_notification_data.filter(
        (item) => item.notify_id !== notify_id
      );
      console.log(new_admin_notification_data, 'new notification Data');
      // let admin_notification_data = this.state.admin_notification_data;
      // let new_admin_notification_data = admin_notification_data.filter(
      //   (item) => item.id !== ticket_id
      // )
      // console.log(new_admin_notification_data, 'new admin notification Data');
      this.setState({
        notification_count: this.state.notification_count -1,
        // notification_count: this.state.notification_Total_count - 1,
        notification_Total_count: this.state.notification_Total_count -1 ,
        // notification_data: new_notification_data,
        admin_notification_data: new_admin_notification_data,
      });
      axios
        .get(
          globalConstants.BASEURL +
            `api/admin_notification_remove/?removeNotificationid=${id}&notifyId=${notify_id}`
        )
        .catch((err) => {
          console.log(err, 'notification-remove-error');
        });
    } else if (type === 'view') {
      document.getElementById('hide-notification-menu').style.display = 'none';
      let admin_notification_data = this.state.admin_notification_data;
      let new_admin_notification_data = admin_notification_data.filter(
        (item) => item.notify_id !== notify_id
      );
      console.log(new_admin_notification_data, 'new notification Data');
      // let admin_notification_data = this.state.admin_notification_data;
      // let new_admin_notification_data = admin_notification_data.filter(
      //   (item) => item.id !== ticket_id
      // )
      // console.log(new_admin_notification_data, 'new admin notification Data');
      this.setState({
        notification_count: this.state.notification_count-1 ,
        // notification_count: this.state.notification_Total_count - 1,
        notification_Total_count: this.state.notification_Total_count,
        // notification_data: new_notification_data,
        admin_notification_data: new_admin_notification_data,
      });
      axios
        .get(
          globalConstants.BASEURL +
            `api/admin_notification_remove/?removeNotificationid=${id}&notifyId=${notify_id}`
        )
        .then((res) => {
          this.props.history.push(`/admin/remainder-edit/${id}`);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err, 'notification-remove-error');
        });
    }
  }

  clear_all() {
    let notification_data = 0;
    console.log(notification_data, 'clear all notifcation data count');
    let local = localStorage.user;
    let newLocal = JSON.parse(local);
    console.log(newLocal.id);
    axios.get(
      globalConstants.BASEURL +
        `api/notification_clear_All/?clearAllNotificationid=${newLocal.id}`
    );
    this.setState({
      notification_count: notification_data,
      notification_data: [],
      notification_Total_count: notification_data,
    });
  }

  notification_list(type) {
    this.setState({
      loading: true,
    });
    console.log(type, 'request type of the notification');
    // console.log(globalConstants, "globalConstants");
    console.log(localStorage.user, 'local Data');
    let local = localStorage.user;
    let newLocal = JSON.parse(local);
    console.log(newLocal.id);
    axios
      .get(
        globalConstants.BASEURL +
          `api/notification_list/?userid=${newLocal.id}&type=${type}`
      )
      .then((res) => {
        console.log('notification_data', res.data.res1);
        this.setState({
          notification_data: res.data.res1,
          admin_notification_data: res.data.res3,
          notification_Total_count: res.data.res2[0].total_notification_count,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err, 'notification-err');
      });

    // Axios(globalConstants.BASEURL + 'api/notification_list', {
    //   method: 'GET',
    // }).then((data) {
    //   console.log('notifcation', data.data);
    //   this.setState({ notification_data: data.data });
    // })
  }

  

  triggerAction(e, v) {
    e.preventDefault();
    e.stopPropagation();
    let url = '/';
    if (v.type == 0) {
      url = `${url}admin/tickets/edit/${v.tickets.id}`;
    }
    window.location.href = `${url}`;
  }

  render() {
    let user = localStorage.getItem('user');
    let parseUser = JSON.parse(user);
    // const { loggedIn, user } = this.props;
    const { nData, nTotal } = this.state;
    let urlPattern =
      /(blob|ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (!user) return null;

    let nDataList = [];
    nData &&
      nData.forEach((v, i) => {
        //  to={(v.type == 0) ? `/admin/tickets/edit/${v.tickets.id}` : ``}
        nDataList.push(
          <ListGroupItem
            tag={`a`}
            key={`notify_bell_${i}`}
            className="list-group-item list-group-item-action"
          >
            {/* onClick={(e) => this.triggerAction(e,v)}  */}
            <Row className="align-items-center">
              <Col className="col-auto">
                <img
                  alt="..."
                  className="avatar rounded-circle"
                  src={require('assets/img/theme/team-4.jpg')}
                />
                <span
                  className={
                    v.type == 0 ? 'notifi ticket-notifi' : 'notifi task-notifi'
                  }
                ></span>
              </Col>
              <div className="col ml--2">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="mb-0 text-sm">
                      {this.jsucwords(v.assigned_to.name)}
                    </h4>
                  </div>
                  <div className="text-right text-muted">
                    <small>
                      {moment
                        .duration(moment(new Date()).diff(moment(v.updated_at)))
                        .humanize()}{' '}
                      ago
                    </small>
                    {/* <span className="notifed-trigger" onClick={(e) => this.triggerNotified(e, v)}>
                    <i className="fas fa-eye"></i>
                  </span> */}
                    {v.type == 1 ? (
                      v.notification_status == 1 ? (
                        <span className={`notifed-trigger`}>
                          <div className="custom-control custom-checkbox custom-checkbox-danger">
                            <input
                              className="custom-control-input"
                              defaultChecked
                              id={`chk-todo-task-${v.tasks.id}`}
                              type="checkbox"
                              onChange={(e) => this.triggertaskd(e, v.tasks.id)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`chk-todo-task-${v.tasks.id}`}
                            />
                          </div>
                        </span>
                      ) : (
                        <span className={`notifed-trigger`}>
                          <div className="custom-control custom-checkbox custom-checkbox-danger">
                            <input
                              className="custom-control-input"
                              id={`chk-todo-task-${v.tasks.id}`}
                              type="checkbox"
                              onChange={(e) => this.triggertaskd(e, v.tasks.id)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`chk-todo-task-${v.tasks.id}`}
                            />
                          </div>
                        </span>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <p className="text-sm mb-0">
                  {`${
                    v.type == 0
                      ? `Have a look at ticket ${
                          v && v.tickets && v.tickets.id
                        }`
                      : `Task ${v.tasks.id}: ${this.jsucwords(
                          v.tasks.description
                        )}`
                  }`}
                </p>
              </div>
            </Row>
          </ListGroupItem>
        );
      });

    return (
      <>
        <Navbar
          style={{ backgroundColor: '#fb6340' }}
          className={classnames(
            'navbar-top navbar-expand border-bottom'
            // { 'navbar-dark bg-info': this.props.theme === 'dark' },
            // { 'navbar-light bg-secondary': this.props.theme === 'light' }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={false}>
              {/* <Form
                className={classnames(
                  "navbar-search form-inline mr-sm-3",
                  { "navbar-search-light": this.props.theme === "dark" },
                  { "navbar-search-dark": this.props.theme === "light" }
                )}
              >
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search" type="text" />
                  </InputGroup>
                </FormGroup>
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={this.closeSearch}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </Form> */}

              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      'pr-3 sidenav-toggler',
                      { active: this.props.sidenavOpen },
                      { 'sidenav-toggler-dark': this.props.theme === 'dark' }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      {/* <i className="sidenav-toggler-line" /> */}
                      {/* <i className="sidenav-toggler-line" /> */}
                      {/* <i className="sidenav-toggler-line" /> */}
                      <i className="fa fa-bars fa-1x" />
                    </div>
                  </div>
                </NavItem>
                {/* <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem> */}
                <UncontrolledDropdown nav>
                  {/* <pre>{JSON.stringify(this.state.notification_data)}</pre> */}
                  <DropdownToggle
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.notification_list('recent')}
                    className="notification-badge-wrap nav-link position-relative"
                    color=""
                    tag="a"
                  >
                    {/* {this.state.notification_Total_count ? (
                      <small className="notification-badge position-absolute badge bg-primary p-1">
                        {this.state.notification_Total_count}
                      </small>
                    ) : (
                      ''
                    )} */}
                    <i className="ni ni-bell-55 text-white" />
                    <span
                      className={style.notificationCount}
                      id="notificationCount"
                    >
                      {this.state.notification_Total_count}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu
                    id="hide-notification-menu"
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div
                      className="px-3 py-3"
                      style={{
                        height: 488,
                        overflow: 'scroll',
                        overflowX: 'hidden',
                      }}
                    >
                      <h6 className="text-sm text-muted m-0">
                        {
                          <>
                            You have{' '}
                            <strong className="text-info">
                              {this.state.notification_Total_count}
                            </strong>{' '}
                            notifications.
                            {/* <ButtonDropdown
                              direction="left"
                              className="float-right no-icon"
                              isOpen={this.state.dropdownOpen}
                              toggle={() =>
                                this.setState({
                                  dropdownOpen: !this.state.dropdownOpen,
                                })
                              }
                            >
                              <DropdownToggle className="btn-link btn-sm" caret>
                                <i className="fas fa-info-circle"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem header>
                                  Notification Info
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                  <div className="position-relative notifi-info">
                                    <span
                                      className={'notifi ticket-notifi'}
                                    ></span>{' '}
                                    <span className="notifi-text">
                                      Ticket Notification
                                    </span>
                                  </div>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                  <div className="position-relative notifi-info">
                                    <span
                                      className={'notifi task-notifi'}
                                    ></span>{' '}
                                    <span className="notifi-text">
                                      Task Notification
                                    </span>
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown> */}
                          </>
                        }
                      </h6>
                      {this.state.loading ? (
                        <div className={style.loaderContainer}>
                          <div className={style.spinner}></div>
                        </div>
                      ) : (
                        <>
                          {this.state.notification_Total_count > 0 ? (
                            <>
                              {this.state.admin_notification_data &&
                              this.state.admin_notification_data.length > 0 ? (
                                <ul className="list-group list-group-flush">
                                  {this.state.admin_notification_data.map(
                                    (data) => (
                                      <div
                                        className="list-group-item-action list-group-item"
                                        style={{ padding: 15, border: 'none' }}
                                      >
                                        <div
                                          className={
                                            data.notifications_type == 'Enquiry'
                                              ? 'checklist-item checklist-item-success'
                                              : 'checklist-item checklist-item-warning'
                                          }
                                        >
                                          <div className="col ml--2">
                                            <div className="d-flex justify-content-between align-items-center">
                                              <h4
                                                className="mb-0"
                                                style={{ fontSize: 12 }}
                                              >
                                                {data.notification_body}
                                              </h4>
                                              <button
                                                type="button"
                                                class="btn-outline-danger"
                                                style={{
                                                  borderRadius: 5,
                                                  fontSize: 11,
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                  this.removeAdminNotification(
                                                    data.ticket_id,
                                                    data.customer_id,
                                                    'remove',
                                                    data.notify_id
                                                  )
                                                }
                                              >
                                                Remove
                                              </button>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                              {/* <p
                                                className={
                                                  style.notificationTime
                                                }
                                              >
                                                {moment(data.created_at).format(
                                                  'Do MMMM YYYY h:mma'
                                                )}
                                              </p> */}
                                              <button
                                                onClick={() =>
                                                  this.removeAdminNotification(
                                                    data.ticket_id,
                                                    data.customer_id,
                                                    'view',
                                                    data.notify_id
                                                  )
                                                }
                                                type="button"
                                                class="btn-outline-info"
                                                style={{
                                                  borderRadius: 5,
                                                  fontSize: 11,
                                                  cursor: 'pointer',
                                                }}
                                              >
                                                View
                                              </button>
                                              {/* <p class="mb-0" style={{ color: '#11cdef' }}>View</p> */}
                                              {/* </Link> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </ul>
                              ) : // <h3>no admin notification</h3>
                              null}
                              {this.state.notification_data &&
                              this.state.notification_data.length > 0 ? (
                                <ul className="list-group list-group-flush">
                                  {this.state.notification_data.map((data) => (
                                    <div
                                      className="list-group-item-action list-group-item"
                                      style={{ padding: 15, border: 'none' }}
                                    >
                                      <div
                                        className={
                                          data.notifications_type == 'Enquiry'
                                            ? 'checklist-item checklist-item-success'
                                            : 'checklist-item checklist-item-warning'
                                        }
                                      >
                                        <div className="col ml--2">
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h4
                                              className="mb-0"
                                              style={{ fontSize: 12 }}
                                            >
                                              {data.notification_body}
                                            </h4>
                                            <button
                                              type="button"
                                              class="btn-outline-danger"
                                              style={{
                                                borderRadius: 5,
                                                fontSize: 11,
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                this.removeNotification(
                                                  data.ticket_id,
                                                  data.customer_id,
                                                  'remove',
                                                  data.notify_id
                                                )
                                              }
                                            >
                                              Remove
                                            </button>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center">
                                            {/* <p
                                              className={style.notificationTime}
                                            >
                                              {moment(data.created_at).format(
                                                'Do MMMM YYYY h:mma'
                                              )}
                                            </p> */}
                                            {data.notification_title ==
                                            'Reassigned Ticket' ? null : (
                                              <button
                                                onClick={() =>
                                                  this.removeNotification(
                                                    data.ticket_id,
                                                    data.customer_id,
                                                    'view',
                                                    data.notify_id
                                                  )
                                                }
                                                type="button"
                                                class="btn-outline-info"
                                                style={{
                                                  borderRadius: 5,
                                                  fontSize: 11,
                                                  cursor: 'pointer',
                                                }}
                                              >
                                                View
                                              </button>
                                            )}
                                            {/* <p class="mb-0" style={{ color: '#11cdef' }}>View</p> */}
                                            {/* </Link> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </ul>
                              ) : // <h3>no enquiry notification</h3>
                              null}
                            </>
                          ) : (
                            <p
                              style={{
                                textAlign: 'center',
                                top: 190,
                                position: 'relative',
                              }}
                            >
                              No New Notification
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    {/* <ListGroup flush>{nDataList}</ListGroup> */}
                    <div class="col ml--2">
                      <div
                        class="d-flex"
                        style={{
                          alignItems: 'baseline',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          className="text-center text-info font-weight-bold py-3"
                          onClick={(e) => this.notification_list('all')}
                          style={{ cursor: 'pointer', paddingLeft: 10 }}
                        >
                          View all
                        </p>
                        <p
                          className="text-center font-weight-bold py-3"
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => this.clear_all()}
                        >
                          Clear All
                        </p>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a">
                    <i className="ni ni-ungroup" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-lg dropdown-menu-dark bg-default"
                    right
                  >
                    <Row className="shortcuts px-4">
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a"
                      >
                        <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                          <i className="ni ni-calendar-grid-58" />
                        </span>
                        <small>Calendar</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a"
                      >
                        <span className="shortcut-media avatar rounded-circle bg-gradient-orange">
                          <i className="ni ni-email-83" />
                        </span>
                        <small>Email</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a"
                      >
                        <span className="shortcut-media avatar rounded-circle bg-gradient-info">
                          <i className="ni ni-credit-card" />
                        </span>
                        <small>Payments</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a"
                      >
                        <span className="shortcut-media avatar rounded-circle bg-gradient-green">
                          <i className="ni ni-books" />
                        </span>
                        <small>Reports</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a"
                      >
                        <span className="shortcut-media avatar rounded-circle bg-gradient-purple">
                          <i className="ni ni-pin-3" />
                        </span>
                        <small>Maps</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a"
                      >
                        <span className="shortcut-media avatar rounded-circle bg-gradient-yellow">
                          <i className="ni ni-basket" />
                        </span>
                        <small>Shop</small>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="nav-link pr-0 pl-0"
                    color=""
                    tag="a"
                  >
                    <Media className="align-items-center">
                      <span
                        style={{ cursor: 'pointer' }}
                        className="avatar avatar-sm rounded-circle"
                        onClick={(e) => this.imgClick(e)}
                      >
                        {/* <div
                          // onClick={handleConvert}
                          className={styles.companyHeaderAvatar}
                          style={{
                            backgroundImage:
                              parseUser && parseUser.profilephoto
                                ? `${
                                    process.env.REACT_APP_BACKEND_URL
                                  }Images/${parseUser.profilephoto.substr(7)}`
                                : require('assets/img/theme/team-4.jpg'),
                            backgroundSize: '100% 100%',
                          }}
                        ></div> */}
                        <img
                          className="avatar-sm"
                          alt="..."
                          src={
                            parseUser && parseUser.profilephoto
                              ? `${
                                  process.env.REACT_APP_BACKEND_URL
                                }Images/${parseUser.profilephoto.substr(7)}`
                              : require('assets/img/theme/team-4.jpg')
                          }
                        />
                      </span>
                      {/* <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {user && user.user && user.user.name}
                        </span>
                      </Media> */}
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h5 className="text-overflow text-capitalize m-0 prousername">
                        Welcome {this.state.loginUserFirst}{' '}
                        {this.state.loginUserLast}
                        {this.state && this.state.Usernames
                          ? this.jsucwords(this.state.Usernames.toLowerCase())
                          : user &&
                            user.user &&
                            user.user.name &&
                            this.jsucwords(user.user.name.toLowerCase())}
                        !
                      </h5>
                    </DropdownItem>
                    <Link
                      className="dropdown-item"
                      onClick={(e) => this.handleUserProfile(e)}
                      // to={`/admin/users-edit/${this.state.userId}`}
                    >
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </Link>
                    {/* <DropdownItem
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="ni ni-settings-gear-65" />
                      <span>Settings</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="ni ni-calendar-grid-58" />
                      <span>Activity</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="ni ni-support-16" />
                      <span>Support</span>
                    </DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => this.handleLogout(e)}
                    >
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

// export default AdminNavbar;
function mapStateToProps(state) {
  const { loggingIn, loggedIn, user } = state.authentication;
  const { notificationlist } = state;
  return { loggingIn, loggedIn, user, notificationlist };
}

const connectedAdminNavbar = connect(mapStateToProps)(AdminNavbar);
export { connectedAdminNavbar as AdminNavbar };

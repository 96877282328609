import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from 'react-router-dom';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.0.0';
// Overwriter css
import './assets/css/style.css';
import Remainder from './_views/_remainder/remainder';
import CreateRemainder from './_views/_remainder/createRemainder';
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import EnquiryForm from './_views/_enquiryForm/_createForm';
import EditEnquiry from './_views/_enquiry/_editEnquiry';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './web.config';
import EnquiryResTable from './_views/_enquiry/_resTable';
import CreateUser from './_views/_user/createUser';
import Userslist from './_views/_user/_create';
import EditUsers from './_views/_user/_editUsers';
import EnquiryList from './_views/_enquiry/_create';
import PrivateRoute from './utils/PrivateRoute';
import EditRemainder from './_views/_remainder/editRemainder';
// import firebase, { onMessageListener } from './firebase';
import 'firebase/compat/messaging';
import axios from 'axios';
import { globalConstants } from './_constants';
// import { toast } from 'react-toastify';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

const App = () => {
  let user = localStorage.getItem('user');
  let role;
  let parseUser;
  if (user) {
    parseUser = JSON.parse(user);
    //  setRole(parseUser.role);
    role = parseUser.role;
  }

  const [fcmToken, setFcmToken] = useState('');
  const getTokens = () => {
    getToken(messaging, {
      vapidKey:
        'BKvCkZVKh_4GS45ePbJy08KThrVOCy9D3B8O_1ipKxElWQuaGEo8IdoL8aD3pPZnvnhN2lGqz_o7tYZJFjW2CgI',
    })
      .then((currentToken) => {
        let user = localStorage.getItem('user');
        let id;
        let parseUser;
        if (user) {
          parseUser = JSON.parse(user);
          id = parseUser.id;
        }
        console.log(
          'Local app page fcm_token token for client: ',
          currentToken
        );
        setFcmToken(currentToken);
        axios
          .put(`${process.env.REACT_APP_BACKEND_URL}api/fcmMain?id=${id}`, {
            fcmToken: currentToken,
          })
          .then((res) => {
            console.log('resc_fcm_loal', res.data);
          })
          .catch((error) => {
            console.log('errorc_fcm_local', error);
          });
      })
      .catch((err) => {
        console.log(
          'An error occurred while retrieving token in local page app. ',
          err
        );
        // catch error while creating client token
      });
  };

  useEffect(() => {
    getTokens();
    // let temp = localStorage.getItem('user');
    // if (temp) {
    //   history.push('/admin/enquiry');
    // }
  }, []);

  var firebaseConfig = {
    apiKey: 'AIzaSyCKX-lnUjR2D_iAKpSLPWFksIc6drgOS6I',
    authDomain: 'push-notify-8d4d0.firebaseapp.com',
    projectId: 'push-notify-8d4d0',
    storageBucket: 'push-notify-8d4d0.appspot.com',
    messagingSenderId: '224308682995',
    appId: '1:224308682995:web:eeee7668cdef5746df9786',
  };
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  // setInterval(() => {
  // let lister = () => {
  //     onMessageListener()
  //       .then((payload) => {
  //         // setShow(true);
  //         let local = localStorage.user;
  //         let newLocal = JSON.parse(local);
  //         console.log(newLocal.id);
  //         axios
  //           .get(
  //             globalConstants.BASEURL +
  //               `api/notification_count/?userid=${newLocal.id}`
  //           )
  //           .then((res) => {
  //             console.log(
  //               'notification_count_data_firebase',
  //               res.data[0].notification_count
  //             );
  //             document.getElementById('notificationCount').innerHTML =
  //               res.data[0].notification_count;
  //           })
  //           .catch((err) => {
  //             console.log(err, 'notification-err-for-count-firebase');
  //           });
  //         // setNotification({
  //         //   title: payload.notification.title,
  //         //   body: payload.notification.body,
  //         // });
  //         console.log(
  //           payload,
  //           'from app.js used as substitution values similar to '
  //         );
  //         toast(() => (
  //           <>
  //             <div style={{ fontWeight: 'bold', color: 'black' }}>
  //               {' '}
  //               {payload.notification.title}
  //             </div>
  //             <div style={{ fontSize: '14px', color: 'black' }}>
  //               {payload.notification.body}
  //             </div>
  //             <Link
  //               to={{
  //                 pathname: `/admin/enquiry-edit/${payload.data['gcm.notification.ticketId']}/${payload.data['gcm.notification.custId']}`,
  //               }}
  //               className="btn btn-dark btn-sm"
  //             >
  //               View
  //             </Link>
  //           </>
  //         ));
  //       })
  //       .catch((err) => console.log('failed: ', err));
  // }

  // }, 1000);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      let local = localStorage.user;
      let newLocal = JSON.parse(local);
      console.log(newLocal.id);
      axios
        .get(
          globalConstants.BASEURL +
            `api/notification_count/?userid=${newLocal.id}`
        )
        .then((res) => {
          console.log(
            'notification_count_data_firebase',
            res.data[0].notification_count
          );
          document.getElementById('notificationCount').innerHTML =
            res.data[0].notification_count;
        })
        .catch((err) => {
          console.log(err, 'notification-err-for-count-firebase');
        });
      // setNotification({
      //   title: payload.notification.title,
      //   body: payload.notification.body,
      // });
      console.log(
        payload,
        'from app.js used as substitution values similar to '
      );
      toast(() => (
        <>
          <div style={{ fontWeight: 'bold', color: 'black' }}>
            {' '}
            {payload.notification.title}
          </div>
          <div style={{ fontSize: '14px', color: 'black' }}>
            {payload.notification.body}
          </div>
          {payload.notification.body ==
          'Your ticket is reassigned to another user.' ? null : (
            <a
              // onClick={() => window.location.reload()}
              href={`${payload.data['gcm.notification.url']}`}
              className="btn btn-dark btn-sm"
            >
              View
            </a>
          )}
        </>
      ));
    });
    // lister();
  }, []);
  if (parseUser && parseUser.role == 'Admin') {
    console.log("adming")
     return (
       <div>
         <BrowserRouter>
           <ToastContainer autoClose={false} />
           <Switch>
             <Route
               exact
               path="/"
               render={() => {
                 return parseUser ? (
                   <Redirect to="/admin/enquiry" />
                 ) : (
                   <Redirect to="/login" />
                 );
               }}
             />
             <Route
               exact
               path="/admin"
               render={() => {
                 return parseUser ? (
                   <Redirect to="/admin/enquiry" />
                 ) : (
                   <Redirect to="/login" />
                 );
               }}
             />
             <Route
               path="/admin"
               render={(props) => <AdminLayout {...props} />}
             />

             <Route path="/admin/user" render={<Userslist />} />
             {parseUser && parseUser.role == 'Admin' && (
               <Route path="/admin/create/user" render={<CreateUser />} />
             )}
             <Route path="/admin/enquiry-form" render={<EnquiryForm />} />
             <Route path="/admin/enquiry" render={<EnquiryList />} />
             <Route
               path="/admin/enquiry-res-table"
               render={<EnquiryResTable />}
             />
             <Route path="/admin/remainder" render={<Remainder />} />
             <Route
               path="/admin/create/remainder"
               render={<CreateRemainder />}
             />
             <Route
               path="/admin/remainder-edit/:id"
               render={<EditRemainder />}
             />
             <Route
               path="/admin/enquiry-edit/:id/:custId"
               render={<EditEnquiry />}
             />
             <Route path="/admin/users-edit/:id" render={<EditUsers />} />
             {/* <PrivateRoute path="/" render={<Login />} /> */}
             {/* <Route path="/login" render={(props) => <AuthLayout {...props} />} /> */}
             <Route
               path="/login"
               render={(props) => {
                 return parseUser ? (
                   <Redirect to="/admin/enquiry" />
                 ) : (
                   <AuthLayout {...props} />
                 );
               }}
             />
             <Redirect from="*" to="/" />
           </Switch>
         </BrowserRouter>
       </div>
     );
  } else {
    console.log("teste");
     return (
       <div>
         <BrowserRouter>
           <ToastContainer autoClose={false} />
           <Switch>
             <Route
               exact
               path="/"
               render={() => {
                 return parseUser ? (
                   <Redirect to="/admin/enquiry" />
                 ) : (
                   <Redirect to="/login" />
                 );
               }}
             />
             <Route
               exact
               path="/admin"
               render={() => {
                 return parseUser ? (
                   <Redirect to="/admin/enquiry" />
                 ) : (
                   <Redirect to="/login" />
                 );
               }}
             />
             <Route 
               path="/admin"
               render={(props) => <AdminLayout {...props} />}
             />
             <Route exact path="/admin/user" render={<Userslist />} />
             <Route exact path="/admin/enquiry-form" render={<EnquiryForm />} />
             <Route exact path="/admin/enquiry" render={<EnquiryList />} />
             <Route
               path="/admin/enquiry-res-table"
               render={<EnquiryResTable />}
             />
             <Route path="/admin/remainder" render={<Remainder />} />
             <Route exact 
               path="/admin/create/remainder"
               render={<CreateRemainder />}
             />
             <Route exact
               path="/admin/remainder-edit/:id"
               render={<EditRemainder />}
             />
             <Route exact
               path="/admin/enquiry-edit/:id/:custId"
               render={<EditEnquiry />}
             />
             <Route path="/admin/users-edit/:id" render={<EditUsers />} />
             {/* <PrivateRoute path="/" render={<Login />} /> */}
             {/* <Route path="/login" render={(props) => <AuthLayout {...props} />} /> */}
             <Route
               path="/login"
               render={(props) => {
                 return parseUser ? (
                   <Redirect to="/admin/enquiry" />
                 ) : (
                   <AuthLayout {...props} />
                 );
               }}
             />
             <Route 
               path="*"
               render={(props) => {
                 return <Redirect to="/" />;
               }}
             />{' '}
           </Switch>
         </BrowserRouter>
       </div>
     );
  }
 
};

export default App;

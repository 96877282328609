import { userConstants } from '../_constants';

export function tourCountlist(state = {}, action) {
  switch (action.type) {
    case userConstants.LIST_TOUR_COUNT_REQUEST:
      return {
        data: action.tourCount
      };
    case userConstants.LIST_TOUR_COUNT_SUCCESS:
      return {
        data: action.tourCount
      };
    case userConstants.LIST_TOUR_COUNT_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourCountCreate(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_TOUR_COUNT_REQUEST:
      return {
        data: action.tourCount
      };
    case userConstants.CREATE_TOUR_COUNT_SUCCESS:
      return {
        data: action.tourCount
      };
    case userConstants.CREATE_TOUR_COUNT_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourCountUpdate(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATE_TOUR_COUNT_REQUEST:
      return {
        data: action.tourCount
      };
    case userConstants.UPDATE_TOUR_COUNT_SUCCESS:
      return {
        data: action.tourCount
      };
    case userConstants.UPDATE_TOUR_COUNT_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourExpensesInsert(state = {}, action) {
  switch (action.type) {
    case userConstants.TOUR_EXPENSES_INSERT_REQUEST:
      return {
        data: action.tourExpenses
      };
    case userConstants.TOUR_EXPENSES_INSERT_SUCCESS:
      return {
        data: action.tourExpenses
      };
    case userConstants.TOUR_EXPENSES_INSERT_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourExpensesUpdate(state = {}, action) {
  switch (action.type) {
    case userConstants.TOUR_EXPENSES_UPDATE_REQUEST:
      return {
        data: action.tourExpenses
      };
    case userConstants.TOUR_EXPENSES_UPDATE_SUCCESS:
      return {
        data: action.tourExpenses
      };
    case userConstants.TOUR_EXPENSES_UPDATE_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourGroupsList(state = {}, action) {
  switch (action.type) {
    case userConstants.LIST_GROUPS_REQUEST:
      return {
        data: action.tourGroups
      };
    case userConstants.LIST_GROUPS_SUCCESS:
      return {
        data: action.tourGroups
      };
    case userConstants.LIST_GROUPS_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourGroupsInsert(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_GROUPS_INSERT_REQUEST:
      return {
        data: action.tourGroups
      };
    case userConstants.CREATE_GROUPS_INSERT_SUCCESS:
      return {
        data: action.tourGroups
      };
    case userConstants.CREATE_GROUPS_INSERT_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourGroupsUpdate(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATE_GROUPS_UPDATE_REQUEST:
      return {
        data: action.tourGroups
      };
    case userConstants.UPDATE_GROUPS_UPDATE_SUCCESS:
      return {
        data: action.tourGroups
      };
    case userConstants.UPDATE_GROUPS_UPDATE_FAILURE:
      return {};
    default:
      return state
  }
}

export function createGroupCount(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_GROUP_COUNT_REQUEST:
      return {
        data: action.groupCount
      };
    case userConstants.CREATE_GROUP_COUNT_SUCCESS:
      return {
        data: action.groupCount
      };
    case userConstants.CREATE_GROUP_COUNT_FAILURE:
      return {};
    default:
      return state
  }
}

export function updateGroupCount(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATE_GROUP_COUNT_REQUEST:
      return {
        data: action.groupCount
      };
    case userConstants.UPDATE_GROUP_COUNT_SUCCESS:
      return {
        data: action.groupCount
      };
    case userConstants.UPDATE_GROUP_COUNT_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourCreate(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_TOUR_REQUEST:
      return {
        data: action.tourCreate
      };
    case userConstants.CREATE_TOUR_SUCCESS:
      return {
        data: action.tourCreate
      };
    case userConstants.CREATE_TOUR_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourUpdate(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATE_TOUR_REQUEST:
      return {
        data: action.tourUpdate
      };
    case userConstants.UPDATE_TOUR_SUCCESS:
      return {
        data: action.tourUpdate
      };
    case userConstants.UPDATE_TOUR_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourTypeslist(state = {}, action) {
  switch (action.type) {
    case userConstants.LIST_TOUR_TYPE_REQUEST:
      return {
        data: action.tourTypes
      };
    case userConstants.LIST_TOUR_TYPE_SUCCESS:
      return {
        data: action.tourTypes
      };
    case userConstants.LIST_TOUR_TYPE_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourPlaceslist(state = {}, action) {
  switch (action.type) {
    case userConstants.LIST_TOUR_PLACES_REQUEST:
      return {
        data: action.tourPlaces
      };
    case userConstants.LIST_TOUR_PLACES_SUCCESS:
      return {
        data: action.tourPlaces
      };
    case userConstants.LIST_TOUR_PLACES_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourPlacesCreate(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_TOUR_PLACES_REQUEST:
      return {
        data: action.tourPlaces
      };
    case userConstants.CREATE_TOUR_PLACES_SUCCESS:
      return {
        data: action.tourPlaces
      };
    case userConstants.CREATE_TOUR_PLACES_FAILURE:
      return {};
    default:
      return state
  }
}

export function tourPlacesUpdate(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATE_TOUR_PLACES_REQUEST:
      return {
        data: action.tourPlaces
      };
    case userConstants.UPDATE_TOUR_PLACES_SUCCESS:
      return {
        data: action.tourPlaces
      };
    case userConstants.UPDATE_TOUR_PLACES_FAILURE:
      return {};
    default:
      return state
  }
}
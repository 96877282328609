import React, { Component } from 'react';
import Select, { components } from "react-select";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { loadingButton, removeLoadingButton } from '../../_functions';

class InsuranceRow extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedObj:{}
        };
    
    }
    
    initialize(){
        const { dispatch, ticket_id, isLastClickin, jsUcfirst, unit, data } = this.props;
        if(data){
            if(this.refs && this.refs[`inname_${unit}`])
                this.refs[`inname_${unit}`].value = data.name;
            if(this.refs && this.refs[`inamount_${unit}`])
                this.refs[`inamount_${unit}`].value = data.amount;

            if(this.refs && this.refs[`innumber_${unit}`])
                this.refs[`innumber_${unit}`].value = data.number;
           
            if(this.refs && this.refs[`inid_${unit}`])
                this.refs[`inid_${unit}`].value = data.id;

            if(this.refs && this.refs[`invendor_id_${unit}`]){
                let tempSelectVal = { label: jsUcfirst(data.vendor_id), value: data.vendor_id.toLowerCase() };
                if(data.paid_by_vendor){
                    tempSelectVal = { label:data.paid_by_vendor.name, value:  data.paid_by_vendor.id }
                }
                this.refs[`invendor_id_${unit}`].select.setValue(tempSelectVal);
            }
      
            let selectedObj = this.state && this.state.selectedObj || {};
            selectedObj[unit] = data.vendor_id;
            this.setState({ selectedObj: selectedObj });
        }
    }

    componentDidMount(){
        this.initialize();
    }

    saveClick1(target, unit){
        const { notify, ticket_id, validateTicketCreationForm,getbilling,customer_id,billingstater,creatbillingState } = this.props;
        let saveButton = document.querySelector(target);
        if(!ticket_id){
            validateTicketCreationForm(saveButton);
        }else{
            let tr = saveButton.parentNode.parentNode;
            loadingButton(saveButton);
            tr.style.pointerEvents = 'none';

            this.removeInvalid(this.refs[`inname_${unit}`]);
            this.removeInvalid(this.refs[`innumber_${unit}`]);
            this.removeInvalid(this.refs[`inamount_${unit}`]);
            this.removeInvalid(document.querySelector(`.invendor_id${unit}`));

            let custPaid = this.refs[`inamount_${unit}`].value.trim();
            let insnum = this.refs[`innumber_${unit}`].value.trim();
            let namesref = this.refs[`inname_${unit}`].value.trim();

            // let eRoutePaid = this.refs[`amount_${unit}`].value.trim();
            let numberRegexp =/^\d{0,12}(\.\d{1,2})?$/;
            let insurnumber=/^[a-zA-Z0-9]{8,22}$/;
            let nameergex=/^[a-zA-Z ]*$/;
       
            if(this.refs[`inname_${unit}`].value.trim() != "" && this.refs[`inamount_${unit}`].value.trim() != "" 
            && this.refs[`innumber_${unit}`].value.trim() != ""  && this.state.selectedObj && this.state.selectedObj[unit] && this.state.selectedObj[unit]!=null){
                if(!numberRegexp.test(custPaid) || !insurnumber.test(insnum) || !nameergex.test(namesref)){
                    
                    if(!numberRegexp.test(custPaid))
                        this.addInvalid(this.refs[`inamount_${unit}`]);
                        if(!insurnumber.test(insnum))
                            this.addInvalid(this.refs[`innumber_${unit}`]);
                        if(!nameergex.test(namesref))
                            this.addInvalid(this.refs[`inname_${unit}`]);
                        removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
                }else{
                    const { dispatch } = this.props;
                    let specialServiceData = {
                        "ticket_id": ticket_id,
                        "name": this.refs[`inname_${unit}`].value,
                        "number": this.refs[`innumber_${unit}`].value,
                        "amount": this.refs[`inamount_${unit}`].value,
                        "vendor_id": this.state.selectedObj[unit],

                      };

                    if(this.refs[`inid_${unit}`].value){
                        specialServiceData["id"] = this.refs[`inid_${unit}`].value;
                        dispatch(userActions.updateInsurance(specialServiceData)).then(() => {
                            var inepaidamout=0;
                            var countvalues=document.querySelectorAll('.insurance_tr');
                            this.refs[`inamount_${unit}`].value = parseFloat(this.refs[`inamount_${unit}`].value).toFixed(2);
  
                            for(var i=0; i <= countvalues.length - 1; i++){
                                inepaidamout = Number(inepaidamout) +  Number(document.querySelector(`.inamount${document.querySelectorAll('.insurance_tr')[i].children[0].value}`).value); 
                            }
  
                            getbilling && getbilling.forEach((va,i)=>{
                                if(va.service_id == 8){
                                 va.e_paid_amount=inepaidamout;
                                  if(va.id){
                                 this.setState({billstatus:1});}
                               
                                 let billlingup={
                                     'id':va.id, 
                                     'ticket_id':ticket_id,
                                     'customer_id': customer_id,
                                     'service_id':va.service_id,
                                     'package_amount': Number(va.price) + Number(va.gst_amount),
                                     'service_charge':va.service,
                                     'service_tax_amount':va.service_tax,
                                     'amount_received':va.paid,
                                     'amount_pending':va.bal,
                                     'e_paid_amount': inepaidamout,
                                     'other_amount':va.others,
                                     'gst_amount': Number(va.gst_amount),
                                     'e_mop':va.mop,
                                     'invoice_to':va.invoice_to,
                                     'amount_paid_by':va.amount_paid_by,
                                     'vendor_name': '',
                                     'invoice_buyer_name':va.invoice_buyer_name
                                  };
                                  dispatch(userActions.updateBilling(billlingup));
                                  creatbillingState('InsuranceEpaidAmt',inepaidamout);
                             }
                            });

                            removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
                            notify("tc", "", `Insurance Service updated successfully`);
                        });
                    }else{
                        dispatch(userActions.createInsurance(specialServiceData)).then(() => {
                            const { insurance } = this.props;
                            const { create } = insurance;
                            const { data } = create;
                            this.refs[`inamount_${unit}`].value = parseFloat(this.refs[`inamount_${unit}`].value).toFixed(2);
                            var inepaidamout=0;
                         
                            var countvalues=document.querySelectorAll('.insurance_tr');
                            for(var i=0; i <= countvalues.length - 1; i++){
                                inepaidamout = Number(inepaidamout) +  Number(document.querySelector(`.inamount${document.querySelectorAll('.insurance_tr')[i].children[0].value}`).value); 
                            }
                            let billdata={
                                'ticket_id': ticket_id,
                                'customer_id': customer_id,
                                'service_id': 8,
                                'package_amount':0,
                                'service_charge':0,
                                'service_tax_amount':0,
                                'amount_received':0,
                                'amount_pending':0,
                                'e_paid_amount': inepaidamout,
                                'other_amount':0
                            };
                            getbilling && getbilling.forEach((va,i)=>{
                                if(va.service_id == 8){
                                    va.e_paid_amount=inepaidamout;
                                    if(va.id){ this.setState({billstatus:1}); }
                                    let billlingup={
                                        'id':va.id, 
                                        'ticket_id':ticket_id,
                                        'customer_id': customer_id,
                                        'service_id':va.service_id,
                                        'package_amount': Number(va.price) + Number(va.gst_amount),
                                        'service_charge':va.service,
                                        'service_tax_amount':va.service_tax,
                                        'amount_received':va.paid,
                                        'amount_pending':va.bal,
                                        'e_paid_amount': inepaidamout,
                                        'other_amount':va.others,
                                        'gst_amount': Number(va.gst_amount),
                                        'e_mop':va.mop,
                                        'invoice_to':va.invoice_to,
                                        'amount_paid_by':va.amount_paid_by,
                                        'vendor_name': '',
                                        'invoice_buyer_name':va.invoice_buyer_name
                                    };
                                    dispatch(userActions.updateBilling(billlingup));
                                    creatbillingState('InsuranceEpaidAmt',inepaidamout);
                                }
                            });
                            if(!this.state.billstatus){
                                dispatch(userActions.createBilling(billdata)).then(()=>{
                                    var arr={
                                    'id':this.props.billing.document.data.id,
                                    'service_id': 8,
                                    'e_paid_amount':inepaidamout,'price':0,
                                    'service':0,'service_tax':0,'paid':0,
                                    'bal':0,'others':0,'gst_amount': 0
                                    };
                                    billingstater('billing',arr);
                                    creatbillingState('InsuranceEpaidAmt',inepaidamout);
                                });
                            }
                            if(this.refs && this.refs[`inid_${unit}`])
                                this.refs[`inid_${unit}`].value = data.id;

                            removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
                            notify("tc", "", `Insurance Service created successfully`);
                        });
                    }
                }
            } else if(this.refs[`inname_${unit}`].value.trim() === "" || this.refs[`innumber_${unit}`].value.trim() === "" || this.refs[`inamount_${unit}`].value.trim() === ""  || !this.state.selectedObj || !this.state.selectedObj[unit]){
                if(this.refs[`inname_${unit}`].value.trim() === ""){
                    this.addInvalid(this.refs[`inname_${unit}`]);
                }
                if(this.refs[`inamount_${unit}`].value.trim() === ""){
                    this.addInvalid(this.refs[`inamount_${unit}`]);
                }
                if(this.refs[`innumber_${unit}`].value.trim() === ""){
                    this.addInvalid(this.refs[`innumber_${unit}`]);
                }
               
                if(!this.state.selectedObj || !this.state.selectedObj[unit]){
                    this.addInvalid(document.querySelector(`.invendor_id${unit}`));
                }
                removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
            }
            
            if(!numberRegexp.test(custPaid) ){
                if(!numberRegexp.test(custPaid))
                    this.addInvalid(this.refs[`inamount_${unit}`]);
                removeLoadingButton(saveButton, '<i class="fas fa-save"></i>');
            }
            tr.style.pointerEvents = 'unset';
        }
    }

    addInvalid(ele){
        ele.classList.remove('is-valid');
        ele.classList.add('is-invalid');
    }

    removeInvalid(ele){
        ele.classList.remove('is-invalid');
        ele.classList.add('is-valid');
    }

    deleteClick1(target, elem, isLast, unit){
        let deleteButton = document.querySelector(target);
        
        if(!isLast){
            deleteButton.parentNode.parentNode.style.pointerEvents = 'none';
            loadingButton(deleteButton);
        }else{
            if(deleteButton.nextSibling){
                deleteButton.nextSibling.click();
                // deleteButton.click();
                elem.parentNode.removeChild(elem);
            }
        }
        const { dispatch, notify } = this.props;
        if(this.refs && this.refs[`inid_${unit}`] && this.refs[`inid_${unit}`].value){
            let specialServiceData = { id: this.refs[`inid_${unit}`].value };
            dispatch(userActions.deleteInsurance(specialServiceData)).then(() => {
                notify("tc", "", `Insurance Service deleted successfully`);
                if(elem && !isLast) {
                    elem.parentNode.removeChild(elem);
                }
            });
        }else if(elem && !isLast) {
            notify("tc", "", `removed successfully`);
            elem.parentNode.removeChild(elem);
        }
    }

    handleChange(event, isReq, regex){
        let { unit, setStater, insuranceState } = this.props;

        let elem = event.target;
        let value = elem.value;
        this.removeInvalid(elem);
        
        insuranceState = (insuranceState) ? insuranceState : {};
        insuranceState.list = (insuranceState.list) ? insuranceState.list : {};
        insuranceState.list.data = (insuranceState.list.data) ? insuranceState.list.data : [];
        insuranceState.list.data[unit] = (insuranceState.list.data[unit]) ? insuranceState.list.data[unit] : {};
        insuranceState.list.data[unit].amount = value;
        setStater('insuranceState', insuranceState);

        if(!value && isReq){
            this.addInvalid(elem);
        }else if(regex && value){
            var regexp = new RegExp(regex);
            if(!regexp.test(value)){
                this.addInvalid(elem);
            }
        }
    }

    handleSelectChange(value, unit){
        let selected = value && value.value;
        let selectedObj = this.state && this.state.selectedObj || {};
        selectedObj[unit] = selected;
        this.setState({ selectedObj: selectedObj });
        this.removeInvalid(document.querySelector(`.invendor_id${unit}`));
        if(!selected)
            this.addInvalid(document.querySelector(`.invendor_id${unit}`));
    }

    render() {
        const { Option } = components;
        const IconOption = (props) => {
            let iconimg = '';
            let bagde = '';
            let imgSrc = require("assets/img/theme/team-1.jpg");
            if(props.data.badge){
                iconimg = (<div className="col-2 p-0"><img src={imgSrc} className="avatar avatar-xs rounded"/> </div>);
                bagde = (<div className="col-2 p-0 w-100 d-flex text-right align-items-center"><span className="badge badge-dark badge-pill text-white">{props.data.badge}</span></div>);
            }
            return (
                <Option {...props} key={`${props.data.label}_${props.data.id}`} selected={`${props.data.selected}`} className="d-flex align-items-center">
                <div className="row col p-0 m-0">
                    {iconimg}
                    <div className="col px-0 mx-1 d-flex align-items-center">{props.data.label}</div>
                    {bagde}
                </div>
                </Option>
            );
        }

        const { unit, optiondata, isLast, isLastClickin } = this.props;
        
        return (
            <tr className={`insurance_tr`} ref={`tr_${unit}`}>
                <td className="px-1 py-1"> <input onChange={(e) => this.handleChange(e, 1, '')} type="hidden" ref={`inid_${unit}`} className={`inid${unit} getidcl form-control form-control-sm`}/> 
                <input type="hidden" className="getrows" value={unit} />
                <input onChange={(e) => this.handleChange(e, 1, /^[a-zA-Z ]*$/)} type="text" ref={`inname_${unit}`} className={`inname${unit} form-control form-control-sm`}/> </td>
              
                <td className="px-1 py-1"> <input onChange={(e) => this.handleChange(e, 1, /^[a-zA-Z0-9]{8,22}$/)} type="text" ref={`innumber_${unit}`} className={`innumber${unit} form-control form-control-sm`}/> </td>
                <td className="px-1 py-1"> <input onChange={(e) => this.handleChange(e, 1, /^\d{0,12}(\.\d{1,2})?$/)} type="text" ref={`inamount_${unit}`} className={`inamount${unit} form-control form-control-sm`}/> </td>
                <td className="px-1 py-1">

                <Select name={`invendor_id${unit}`} onChange={(value) => this.handleSelectChange(value, unit)} ref={`invendor_id_${unit}`} className={`invendor_id${unit} form-control form-control-sm p-0 min-w-120-px`}  isClearable placeholder={`Select Paid By`} components={{ Option: IconOption }} isSearchable isMulti={false} options={optiondata}/>
                </td>

                <td className="px-1 py-1 ">
                <button className={`btn btn-success btn-sm mx-0 btn_sv1_${unit} insuranceSaveBtn`} onClick={(e) => this.saveClick1(`.btn_sv1_${unit}`, unit)}><i className="fas fa-save"></i></button>
                <button className={`btn btn-danger btn-sm mx-1  btn_dlin_${unit}`} onClick={() => this.deleteClick1(`.btn_dlin_${unit}`, this.refs[`tr_${unit}`], isLast, unit)}><i className="fas fa-trash-alt"></i></button>
                {(isLast)? (<button className="btn btn-primary btn-sm mx-0" onClick={() => isLastClickin()}><i className="fas fa-plus"></i></button>) : ''}
                </td>
            </tr>
        );
    }
}

function mapStateToProps(state) {
    const { insurance } = state;
    return { insurance };
}

const InsuranceRowSectionPage = connect(mapStateToProps)(InsuranceRow);
export { InsuranceRowSectionPage as InsuranceRow };
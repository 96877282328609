import { userConstants } from '../_constants';

export function notificationcreate(state = {}, action) {
    switch (action.type) {
        case userConstants.CREATE_NOTIFICATION_REQUEST:
            return {
              loading: true
            };
        case userConstants.CREATE_NOTIFICATION_SUCCESS:
            return {
                notification: action.notification
            };
        case userConstants.CREATE_NOTIFICATION_FAILURE:
            return { 
                error: action.error
            };

        default:
            return state
    }
}

export function notificationupdate(state = {}, action) {
    switch (action.type) {
        case userConstants.UPDATE_NOTIFICATION_REQUEST:
            return {
              loading: true
            };
        case userConstants.UPDATE_NOTIFICATION_SUCCESS:
            return {
                notification: action.notification
            };
        case userConstants.UPDATE_NOTIFICATION_FAILURE:
            return { 
                error: action.error
            };

        default:
            return state
    }
}

export function notificationlist(state = {}, action) {
    switch (action.type) {
        case userConstants.LIST_NOTIFICATION_REQUEST:
            return {
              loading: true
            };
        case userConstants.LIST_NOTIFICATION_SUCCESS:
            return {
                notification: action.notification
            };
        case userConstants.LIST_NOTIFICATION_FAILURE:
            return { 
                error: action.error
            };

        default:
            return state
    }
}
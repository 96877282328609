import { userConstants } from '../_constants';

export function comment(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_COMMENT_REQUEST:
      return {
        comment: action.data
      };
    case userConstants.CREATE_COMMENT_SUCCESS:
      return {
        comment: action.data
      };
    case userConstants.CREATE_COMMENT_FAILURE:
      return {};
    default:
      return state
  }
}
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import styles from './form.module.css';
import Select from 'react-select';
import { components } from 'react-select';
import { toast } from 'react-toastify';
import {useHistory} from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
// import Datetime from 'react-datetime';
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
import ReactLoading from 'react-loading';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import {
  Col,
  Input
} from 'reactstrap';
import styled, { css } from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import ReactDatetimeClass from 'react-datetime';
import { Portal } from 'react-overlays';
import Popup from 'react-animated-popup';
import './formStyle.css';
import defaultGreyPlusImage from '../../assets/img/users/default-user.PNG';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from '../../components/components/MDBox';
import MDTypography from '../../components/components/MDTypography';

// NewUser page components
import FormField from '../../layouts/components/FormField';
import { TextareaAutosize } from '@mui/material';

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
};

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
      padding-top: 250px;
    `}
`;




const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {/* {optionSelectedLength < 1 ? (
        props.children
      ) : (
        <div>Maximum one Assignee</div>
      )} */}
      {props.children}
    </components.Menu>
  );
};

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    display: 'none',
    height: '35px',
    position: 'relative',
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      margin: '0px',
    };
  },
  menu: (provided, state) => ({
    ...provided,
    // width: 210,
    position: 'absolute',
    bottom: 40,
    backgroundColor: 'white',
    width: '300px',
  }),
};


const EnquiryForm = () => {
  let history = useHistory();
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 1;
  // let code = localStorage.getItem('deptcode');
  let [dept, setDept] = useState([]);
  let [deptCode, setDeptCode] = useState([]);
  let [deptCodeName, setDeptCodeName] = useState([]);
  let [code, setCode] = useState([]);
  let [selectShow, setSelectShow] = useState(false);
  let [customerId, setCustomerId] = useState('');
  let [loading, setLoading] = useState(false);
  let [menuOpen, setMenuOpen] = useState(true);
  let [depart, setDepart] = useState([]);
  let [selectedDepart, setSelectedDepart] = useState([]);
  let [deptmenuOpen, setDeptMenuOpen] = useState(false);
  let [oldDept, setOldDept] = useState([]);
  let [paymentStatus, setPaymentStatus] = useState(false);

  let paymentChangeStatus = (e) => {
    setPaymentStatus(!paymentStatus);
  };

  let [user, setUser] = useState({
    // deptCode: deptVal,
    firstname: '',
    lastname: '',
    phone: '',
  });

  let [enquiry, setEnquiry] = useState({
    deptcode: dept.toString(),
    referred_by: '',
    referral_contact: '',
    enquiry_details: '',
    closure_date: '',
    assigned_to: [],
  });

  let [userError, setUserError] = useState({
    firstnameError: '',
    lastnameError: '',
    phonenumberError: '',
  });

  let [enquiryError, setEnquiryError] = useState({
    refError: '',
    ref_detailsError: '',
    enq_detailsError: '',
    closure_dateError: '',
    assigneeError: '',
  });

  useEffect(() => {
    console.log('outer-document.body.classList', document.body.classList.value);
    if (document.body.classList.value == 'g-sidenav-show g-sidenav-pinned') {
      console.log(
        'inner-document.body.classList',
        document.body.classList.value
      );
      // document.getElementById('lastname').classList.add('ml-6');
    }
  }, [document.body.classList.value]);

  let [avatar, setAvatar] = useState(null);
  let [assigneeImage, setAssigneeImage] = useState(null);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  // useEffect(() => {
  //   let preArr = localStorage.getItem('indexArr');
  //   console.log('PREARRAAOUIEUIOEOIOEIOEIOEO', preArr);
  //   let parsePreArr = JSON.parse(preArr);
  //   if (parsePreArr == null) {
  //     setIsOpen(true);
  //   }
  // }, []);

  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   subtitle.style.color = '#f00';
  // }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (depart.length == 6) {
      setDeptMenuOpen(false);
    }
  }, [depart]);

  let validateFirstName = (event) => {
    setUser({ ...user, firstname: event.target.value });
    // let regExp = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
    // let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    let regExp = /^[a-zA-Z_ ]*$/;
    !regExp.test(event.target.value)
      ? setUserError({
          ...userError,
          firstnameError: 'Enter a proper First Name',
        })
      : setUserError({ ...userError, firstnameError: '' });
  };

  let validateLastName = (event) => {
    setUser({ ...user, lastname: event.target.value });
    // let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    let regExp = /^[a-zA-Z_ ]*$/;
    !regExp.test(event.target.value)
      ? setUserError({
          ...userError,
          lastnameError: 'Enter a proper Last Name',
        })
      : setUserError({ ...userError, lastnameError: '' });
  };

  let validatePhoneNumber = (event) => {
    setUser({ ...user, phone: event.target.value });
    let regExp =
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,4}$/im;
    !regExp.test(event.target.value)
      ? setUserError({
          ...userError,
          phonenumberError: 'Enter a proper Phone number',
        })
      : setUserError({ ...userError, phonenumberError: '' });
  };

  let validateRef = (event) => {
    setEnquiry({ ...enquiry, referred_by: event.target.value });
    // let regExp = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
    // let regExp = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
    let regExp = /^[a-zA-Z0-9_ ]*$/;
    !regExp.test(event.target.value)
      ? setEnquiryError({
          ...enquiryError,
          refError: 'Enter a proper Reference',
        })
      : setEnquiryError({ ...enquiryError, refError: '' });
  };

  let validateEnquiryDetails = (event) => {
    setEnquiry({ ...enquiry, enquiry_details: event.target.value });
    // let regExp = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
    // let regExp = /^[\\.a-zA-Z0-9/s,!? ]*$/;
    event.target.value == ''
      ? setEnquiryError({
          ...enquiryError,
          enq_detailsError: 'Enter a proper Enquiry Details',
        })
      : setEnquiryError({ ...enquiryError, enq_detailsError: '' });
  };

  let validateRefDetails = (event) => {
    setEnquiry({ ...enquiry, referral_contact: event.target.value });
    let regExp =
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,4}$/im;
    !regExp.test(event.target.value)
      ? setEnquiryError({
          ...enquiryError,
          ref_detailsError: 'Enter a proper Reference Details',
        })
      : setEnquiryError({ ...enquiryError, ref_detailsError: '' });
  };

  let handleBlur = () => {
    setSelectShow(false);
    setEnquiry({
      ...enquiry,
      assigned_to: enquiry.assigned_to,
    });
    let imgArr = [];
    if (enquiry.assigned_to.length > 0) {
      enquiry.assigned_to.map((enq) => {
        console.log('enquirevlaue', enq.value);
        imgArr.push(enq.image);
      });
    } else {
      setAvatar('grea-plus.PNG');
    }
    if (imgArr.length > 1) {
      setAvatar(imgArr[1]);
    } else {
      setAvatar(imgArr[0]);
    }
  };

  //click outside
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
      setSelectShow(false);
      setEnquiry({
        ...enquiry,
        assigned_to: enquiry.assigned_to,
      });
      let imgArr = [];
      if (enquiry.assigned_to.length > 0) {
        enquiry.assigned_to.map((enq) => {
          console.log('enquirevlaue', enq.value);
          imgArr.push(enq.image);
        });
      } else {
        setAvatar(imgArr[0] ? imgArr[0] : 'grea-plus.PNG');
      }
      if (imgArr.length > 1) {
        setAvatar(imgArr[1]);
      } else {
        setAvatar(imgArr[0] ? imgArr[0] : 'grea-plus.PNG');
      }
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  let validateEnquiryAssignee = (event) => {
    console.log('EVENT_dfsfisdfsfsdfsdf', event);
    setEnquiry({
      ...enquiry,
      assigned_to:
        event == '' || event == null
          ? []
          : event.length > 1
          ? [event[1]]
          : event,
    });
  };

  useEffect(() => {
    if (enquiry.assigned_to.length > 0) {
      enquiry.assigned_to.map((assignee) => {
        setAssigneeImage(assignee.image);
      });
    }
  }, [enquiry]);

  let validateEnquiryClosureDate = (date) => {
    console.log('closre_date_validate', date);
    setEnquiry({
      ...enquiry,
      closure_date: date,
    });

    setEnquiryError({
      ...enquiryError,
      closure_dateError: '',
    });
  };

  // let validateEnquiryClosureDate = (event) => {
  //   console.log('dfsfisdfsfsdfsdf', event);
  //   setEnquiry({
  //     ...enquiry,
  //     closure_date: event == '' || event == null ? '' : event,
  //   });
  //   setEnquiryError({
  //     ...enquiryError,
  //     closure_dateError: '',
  //   });
  // };

  // let departmentClicked = (code) => {
  //   setDepart([...depart].concat(code));
  //   // setDeptMenuOpen(false);
  // };

  useEffect(() => {
    let dept = localStorage.getItem('department');
    let deptParse = JSON.parse(dept);
    console.log('dept---list', deptParse);
    deptParse.map((de) => {
      console.log('eachdeptcode', de.code);
    });
  }, []);

  useEffect(() => {
    let indexArr = localStorage.getItem('indexArr');
    let indexArrParse = JSON.parse(indexArr);
    console.log('56789eoiueioeeeeeeeeeeeuoeiieoieiie', indexArrParse);
    setDept(indexArrParse);
    // console.log('enquiry-form', deptArr);
    let codename = [];
    let deptNewCode = [];
    let deptVarCode = [];
    if (indexArrParse != null) {
      indexArrParse.map((name) => {
        console.log('Mmmmmmmmmmm', name);
        codename.push(name.deptname);
        deptNewCode.push(name.code);
        deptVarCode.push(name.code);
        selectedDepart.push(name.code);
      });
    }
    // if(selectedDepart.length > 0){
    //   deptCode.push(selectedDepart);
    // }
    // setSelectedDepart(deptNewCode);
    setCode(selectedDepart);
    console.log(deptCode);
    console.log('NAMANAMAMAMAMAMAMAMAMA', codename);
    getUsers(selectedDepart.toString());
  }, []);


  
  let getUsers = (selectedDepart) => {
    
      // console.log('HIIIIIIIIIIIIIIIIIIIIIIIIIII',deptname);
      let dataUrl = `${process.env.REACT_APP_BACKEND_URL}api/get-users?array=${
        selectedDepart && selectedDepart.length > 0 ? selectedDepart : null
      }`;
      axios
        .get(dataUrl)
        .then((res) => {
          console.log('dept-code-res84384938490', res.data);
          setDeptCode(res.data);
        })
        .catch((err) => {
          console.log(err, 'deptcode-eror894388304');
        });
    
    
    
  };

  // useEffect(() => {
  //   if(selectedDepart.length > 0){
  //     console.log(selectedDepart, 'asdfghjklkjtreyukjhgf');
  //     getUsers(selectedDepart.toString());
  //   }
  // }, []);


  let getUsersForRemove = (depCode) => {
    // console.log('HIIIIIIIIIIIIIIIIIIIIIIIIIII',deptname);
    let dataUrl = `${process.env.REACT_APP_BACKEND_URL}api/get-users?array=${depCode}`;
    axios
      .get(dataUrl)
      .then((res) => {
        console.log('dept-code-REmove_res84384938490', res.data);
        res.data.map((rUsers)  => {
          enquiry.assigned_to.map((rAssignee) => {
            if(rUsers.email == rAssignee.email){
               setEnquiry({
                 ...enquiry,
                 assigned_to: [],
               });
            }
          })
        })
        //  setDeptCode(res.data);
      })
      .catch((err) => {
        console.log(err, 'deptcode-eror894388304');
      });
  };

  let handleRemoveDept = (de, depCode) => {
    console.log('deptcodename', de);
    console.log('deptblablabla', dept);
    console.log('deptblablabla_deptCode', depCode);
    console.log('deptblablabla_oldDeptpar', oldDept);
     getUsersForRemove(depCode)
     setSelectedDepart([...selectedDepart].concat(code.code));
    let deptArr = dept.filter((dep) => dep.deptname != de);
    let deptSOArr = dept.filter((dep) => dep.deptname == de);
    // toast('Department removed successfully!..');
    console.log('blalalalala', deptArr);
    console.log('deptSOArrblalalalala', deptSOArr);
    var index = selectedDepart.indexOf(depCode);
    var tempDep =[ ...selectedDepart];
    if (index >= 0) {
      tempDep.splice(index, 1);
    }
    setDept(deptArr);
    oldDept.map((old) => {
      old.code != depCode && setOldDept([...oldDept].concat(deptSOArr));
    })
    
    setSelectedDepart(tempDep);
    let string = JSON.stringify(deptArr);
    localStorage.setItem('indexArr', string);
    getUsers(tempDep.toString());
    // setEnquiry({
    //   ...enquiry,
    //   assigned_to: [],
    // });
    // checkDeptLength();
  };

  

  const aquaticCreatures = [];
  deptCode.map((code) => {
    let objCode = {
      label: code.firstname,
      value: code.firstname,
      image: code.profilephoto.substr(7),
      email: code.email,
      id: code.id,
    };
    aquaticCreatures.push(objCode);
  });

  const avatarImage = () => {
    let imgArr = [];
    if (enquiry.assigned_to.length > 0) {
      enquiry.assigned_to.map((enq) => {
        console.log('enquirevlaue', enq.value);
        imgArr.push(enq.image);
      });
    }
    if (imgArr.length > 1) {
      setAvatar(imgArr[1]);
    } else {
      setAvatar(imgArr[0]);
    }
  };

  useEffect(() => {
    // if(enquiry?.assignee?.length > 0){
    avatarImage();
    // }
  }, [enquiry.assigned_to]);

  const handleConvert = () => {
    setAvatar(null);
    setSelectShow(true);
    // setEnquiry({...enquiry, assignee: ""})
  };

  let submitRegistration = async (event) => {
    var result = 0;
    let tempuserError = {
      firstnameError: '',
      lastnameError: '',
      phonenumberError: '',
    };

    let tempenquiryError = {
      refError: '',
      ref_detailsError: '',
      enq_detailsError: '',
      closure_dateError: '',
      assigneeError: '',
    };
    try {
      event.preventDefault();
      console.log('outer-console');
      if (user.firstname == '') {
        result = 1;
        console.log('inner-console');
        tempuserError.firstnameError = 'First Name field cannot be empty';
      } else {
        // let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        let regExp = /^[a-zA-Z_ ]*$/;
        if (!regExp.test(user.firstname)) {
          result = 1;
          tempuserError.firstnameError = 'Enter a proper First Name';
        }
        // else {
        //   result = 0;
        //   }
      }

      if (user.lastname == '') {
        result = 1;
        console.log('inner-console');
        tempuserError.lastnameError = 'Last Name field cannot be empty';
      } else {
        // let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        let regExp = /^[a-zA-Z_ ]*$/;
        if (!regExp.test(user.lastname)) {
          result = 1;
          tempuserError.lastnameError = 'Enter a proper Last Name';
        }
        // else {
        //   result = 0;
        //   }
      }

      if (user.phone == '') {
        result = 1;
        console.log('inner-console');
        tempuserError.phonenumberError = 'Phone Number field cannot be empty';
      } else {
        let regExp =
          /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,4}$/im;
        if (!regExp.test(user.phone)) {
          result = 1;
          tempuserError.phonenumberError = 'Enter a proper phone number';
        }
        // else {
        //   result = 0;
        //   }
      }

      if (enquiry.referred_by == '') {
        result = 1;
        console.log('inner-console');
        tempenquiryError.refError = 'Reference field cannot be empty';
      } else {
        // let regExp = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
        let regExp = /^[a-zA-Z0-9_ ]*$/;
        if (!regExp.test(user.referred_by)) {
          result = 1;
          tempenquiryError.refError = 'Enter a proper Reference';
        }
        // else {
        //   result = 0;
        //   }
      }

      if (enquiry.referral_contact == '') {
        result = 1;
        console.log('inner-console');
        tempenquiryError.ref_detailsError =
          'Referral contact field cannot be empty';
      } else {
        let regExp =
          /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,4}$/im;
        if (!regExp.test(enquiry.referral_contact)) {
          result = 1;
          tempenquiryError.ref_detailsError = 'Enter a proper Reference contact';
        }
        // else {
        //   result = 0;
        //   }
      }

      if (enquiry.enquiry_details == '') {
        result = 1;
        console.log('inner-console');
        tempenquiryError.enq_detailsError =
          'Enquiry details field cannot be empty';
      } else {
        // let regExp = /^[\\.a-zA-Z0-9,!? ]*$/;
        if (enquiry.enquiry_details == '') {
          result = 1;
          tempenquiryError.enq_detailsError = 'Enter a proper Enquiry details';
        }
        // else {
        //   result = 0;
        //   }
      }

      if (enquiry.closure_date == '') {
        result = 1;
        console.log('inner-console');
        tempenquiryError.closure_dateError =
          'Closure date field cannot be empty';
      }

      if (enquiry.assigned_to.length == 0) {
        result = 1;
        console.log('inner-console');
        tempenquiryError.assigneeError = 'Assignee field cannot be empty';
      }
      // else {
      //   let regExp = /^[\\.a-zA-Z0-9,!? ]*$/;
      //   if (!regExp.test(enquiry.enquiry_details)) {
      //     result = 1;
      //     tempenquiryError.assigneeError = 'Enter proper Assignee details';
      //   }
      //    else {
      //     result = 0;
      //   // }
      // }

      console.log('TEMPUSERERROR', tempuserError);

      if (result == 1) {
        toast('Could not submit with error fields');
        setUserError({
          ...userError,
          firstnameError: tempuserError.firstnameError
            ? tempuserError.firstnameError
            : '',
          lastnameError: tempuserError.lastnameError
            ? tempuserError.lastnameError
            : '',
          phonenumberError: tempuserError.phonenumberError
            ? tempuserError.phonenumberError
            : '',
        });
        setEnquiryError({
          ...enquiryError,
          refError: tempenquiryError.refError ? tempenquiryError.refError : '',
          ref_detailsError: tempenquiryError.ref_detailsError
            ? tempenquiryError.ref_detailsError
            : '',
          enq_detailsError: tempenquiryError.enq_detailsError
            ? tempenquiryError.enq_detailsError
            : '',
          closure_dateError: tempenquiryError.closure_dateError
            ? tempenquiryError.closure_dateError
            : '',
          assigneeError: tempenquiryError.assigneeError
            ? tempenquiryError.assigneeError
            : '',
        });
      } else if (result == 0) {
        setLoading(true);
        let formDataUrl = `${process.env.REACT_APP_BACKEND_URL}api/create-customer`;
        let newUser = {
          firstname : user.firstname.trim(),
          lastname : user.lastname.trim(),
          phone : user.phone
        }
        await axios
          .post(formDataUrl, newUser)
          .then((response) => {
            console.log('CATCH-RESPONSEERRIN_USER', response.data);
            console.log('CATCH-RESPONSEERRIN_USER', response.data.errors);
            // console.log('CATCH-RESPONSEERRIN_USER', response.data);
            console.log('response-CREATE-CUSTOMER', response.data.customer_id);
            setCustomerId(response.data.customer_id);
            // toast('Customer created successfully');
            let enquiryDataUrl = `${process.env.REACT_APP_BACKEND_URL}api/create-enquiry`;
            console.log('enquiry-ulla -iruka-dept', dept);
            let splitDept = code.toString();
            console.log('object', splitDept);
            let custId = response.data.customer_id;
            let deptcode = splitDept;
            let referred_by = enquiry.referred_by.trim();
            let referral_contact = enquiry.referral_contact;
            let enquiry_details = enquiry.enquiry_details.trim();
            let payStatus = paymentStatus;
            let closure_date = enquiry.closure_date;
            let assigned_to = '';
            enquiry.assigned_to.map((assignee) => {
              assigned_to = assignee.id;
            });
            let enquiryData = {
              custId,
              deptcode,
              referred_by,
              referral_contact,
              enquiry_details,
              closure_date,
              assigned_to,
              payStatus,
            };
            axios
              .post(enquiryDataUrl, enquiryData)
              .then((response) => {
                // setLoading(false);
                // toast('Enquiry created successfully');
                console.log('ENQUIRY-DETAILS-RESPONSE', response.data);
                setCustomerId('');
                setMenuOpen(false);
                setAssigneeImage(null);
                setEnquiryError({ ...enquiryError, assigneeError: '' });
                let commentDataUrl = `${process.env.REACT_APP_BACKEND_URL}api/create-comment`;
                let user = localStorage.getItem('user');
                let parseUser = JSON.parse(user);
                let commented_by = parseUser.id;
                let ticket_id = response.data.enquiry_id;
                let comments = response.data.enquiry_details;
                let assigned_to = response.data.assigned_to;
                let commentData = {
                  commented_by,
                  ticket_id,
                  comments,
                  assigned_to,
                };
                axios
                  .post(commentDataUrl, commentData)
                  .then((response) => {
                    setLoading(false);
                    setUser({
                      ...user,
                      firstname: '',
                      lastname: '',
                      phone: '',
                    });
                    setEnquiry({
                      ...enquiry,
                      deptcode: '',
                      referred_by: '',
                      referral_contact: '',
                      enquiry_details: '',
                      closure_date: '',
                      assigned_to: [],
                    });
                    toast('Enquiry created successfully');
                    console.log('COMMENTSARE-CREATE', response.data);
                    history.goBack();
                  })
                  .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    // let errorList = error.response.data.errors;
                    // errorList.map((err) => {
                    //   toast(err.msg);
                    // });
                  });
              })
              .catch((error) => {
                setLoading(false);
                console.log('employee-error', error.message);
                // let errorList = error.response.data.errors;
                // errorList.map((err) => {
                //   toast(err.msg);
                // });
              });
            // dispatch(alertActions.setAlert(response.data.msg, 'success'));
          })
          .catch((error) => {
            // toast('Network error');
            setLoading(false);
            // let errorList = error.response.data.errors;
            // errorList.map((err) => {
            //   toast(err.msg);
            // });
            console.log('FORMDAA-URLresponse', error.response.data);
          });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // let errorList = error.response.data.errors;
      // errorList.map((err) => {
      //   toast(err.msg);
      // });
    }
  };

  useEffect(() => {
    if (dept.length == 0) {
      setIsOpen(true);
    }
  }, [dept]);

  useEffect(() => {
    let user = localStorage.getItem('user');
    if(user){
        let parseUser = JSON.parse(user);
        console.log('LOGGEDUSEROUEIRUIOEUIOR', parseUser.id);
    }
  }, []);

  // const [startDate, setStartDate] = useState(
  //   setHours(setMinutes(new Date(), 30), 16)
  // );

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  //for getting all the department
  useEffect(() => {
    let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/department`;
    axios
      .get(dataURL)
      .then((res) => {
        // let deptArr = dept.filter((dep) => dep.deptname != de);
        var tempArray = [];
        let preArr = localStorage.getItem('indexArr');
        console.log('PREARRAAOUIEUIOEOIOEIOEIOEO', preArr);
        let parsePreArr = JSON.parse(preArr);
        if (parsePreArr == null) {
          setIsOpen(true);
        }
        for (var j = 0; j < res.data.length; j++) {
          var flag = 0;
          console.log(parsePreArr, 'dfsdvfsfsdfsdf');
          console.log(parsePreArr.length, 'dfsdvfsfsdfsdf1');
          for (var i = 0; i < parsePreArr.length; i++) {
            console.log(res.data[j].code, 'res.data[j].code');
            console.log(parsePreArr[i].code, 'dept[i].codedept[i].code');
            if (res.data[j].code == parsePreArr[i].code) flag = 1;
          }
          if (flag == 0) tempArray.push(res.data[j]);
        }
        console.log(tempArray, 'tempArraytempArray');
        setOldDept(tempArray);
        console.log('OLD_DEPART_collection', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let departmentClicked = (code) => {
    console.log(code, 'raw_rwa_war_code');
    setDept([...dept].concat(code));
    setOldDept([...oldDept].filter((old) => old.code != code.code))
    // let newRaDept = [];
    // let newTRaDept = oldDept.filter((old) => old.code == code.code );
    // // let newTRaDept = oldDept.map((od) => {
    // //     if(od.code != code.code){
    // //       newRaDept.push(od);
    // //     }
    // // })
    // setOldDept(newTRaDept);
    console.log(code.code, 'codecode');
    console.log(selectedDepart, 'selectedDepartselectedDepart');
    let tempDepts=  [...selectedDepart];
    tempDepts.push(code.code);
    setSelectedDepart(tempDepts); 
    setCode(tempDepts);
    console.log(tempDepts, 'ajsfjsdfselectedDepartselectedDepart');
     // setDeptMenuOpen(false);
     getUsers(tempDepts.toString());
  };

  let imageUrl = `${process.env.REACT_APP_BACKEND_URL}Images/`;

 

  return (
    <div style={{ height: '100%', backgroundColor: 'rgb(245 239 239)' }}>
      <style>
        {
          '\
          .custom-toggle-slider::after  {\
              color: white !important;\
          }'
        }
        {
          '\
          .css-xn6ruc>:not(style)  {\
              background: white !important;\
          }'
        }
        {
          '\
          .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root   {\
              padding-left: 11px !important;\
          }'
        }
        {
          '\
          .rdt input{\
              border-bottom: 1px solid #949494 !important;\
              padding-bottom: 7px !important;\
              padding-left: 8px !important;\
          }'
        }
        {
          '\
          .form-control{\
              border: none !important;\
              border-radius: 0px !important;\
              color: black !important;\
              font-size : 16px !important;\
          }'
        }
        {
          '\
          .form-control::placeholder{\
             color: #666666;\
              font-size : 16px;\
          }'
        }
        {/* {
          '\
          .rdt{\
              text-align: -webkit-center !important;\
              margin: 0 auto !important;\
          }'
        } */}
        {
          '\
          .rdt{\
              text-align: -webkit-center !important;\
              margin-top:  0 auto !important;\
          }'
        }
        {
          '\
          .rdt input:hover{\
               border-bottom: 2px solid black !important;\
          }'
        }
        {
          '\
          .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{\
             width: 100% !important;\
          }'
        }
        {
          '\
          .col-md-6{\
               margin: 10px 0px !important;\
          }'
        }
        {/* {'\
          .quaqe{\
             margin: 10px !important;\
          }'} */}
        {
          '\
          .css-i44wyl{\
             width: 100% !important;\
          }'
        }
      </style>

      {/* <span className={styles.xVisible} style={{ textAlign: 'left' }}>
        <Link
          onClick={() => {
            setDept([]);
          }}
          style={{ float: 'right' }}
          to={'/admin/enquiry'}
        >
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '24px',
              color: 'black',
              paddingRight: '20px',
            }}
          >
            X
          </span>
        </Link>
      </span> */}
      <section>
        <DarkBackground disappear={loading}>
          <LoadingOverlay
            active={true}
            // spinner={<BounceLoader />}
            spinner={true}
            // text="Loading your content..."
          ></LoadingOverlay>
        </DarkBackground>
        {dept.length == 0 ? (
          <div ref={myRef}>
            {/* <button onClick={openModal}>Open Modal</button> */}
            <Modal
              isOpen={modalIsOpen}
              // onAfterOpen={afterOpenModal}
              // onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}

              <h3>Please Select any departments to continue</h3>
              <form>
                {/* <input />
                  <button>tab navigation</button>
                  <button>stays</button>
                  <button>inside</button> */}
                <Link className="btn btn-danger m-2" to={'/admin/enquiry'}>
                  Ok
                </Link>
                {/* <button className="btn btn-danger m-2" onClick={closeModal}>
                    Close
                  </button> */}
              </form>
            </Modal>
          </div>
        ) : (
          <div className={styles.formContainerParent}>
            <div className="row w-auto m-auto d-flex align-items-center justify-content-center">
              <div className="col-md-6">
                {/* <pre>{JSON.stringify(userError)}</pre>
              <pre>{JSON.stringify(enquiryError)}</pre> */}
                {/* <pre>{JSON.stringify(deptCode)}</pre>
              <pre>{JSON.stringify(code)}</pre> */}
                {/* <pre>{JSON.stringify(deptCodeName)}</pre> */}
                {/* <pre>{JSON.stringify(user)}</pre>
                <pre>{JSON.stringify(enquiry)}</pre>
                <pre>{JSON.stringify(assigneeImage)}</pre> */}
                {/* <pre>{JSON.stringify(dept)}</pre>
                <pre>{JSON.stringify(oldDept)}</pre>
                <pre>{JSON.stringify(selectedDepart)}</pre> */}
                {/* <pre>{JSON.stringify(enquiry.assigned_to)}</pre> */}
                {/* <span
                  className={styles.xResVisible}
                  style={{ textAlign: 'left' }}
                >
                  <Link
                    onClick={() => {
                      setDept([]);
                    }}
                    style={{ float: 'right' }}
                    to={'/admin/enquiry'}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '24px',
                        color: 'black',
                        paddingRight: '20px',
                      }}
                    >
                      X
                    </span>
                  </Link>
                </span> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                  }}
                >
                  <div className={styles.parentBorder}>
                    {dept.map((de, index) => (
                      <>
                        <div className={styles.parentBorder2} key={index}>
                          <div className={styles.borderImage}>
                            <img
                              src={require(`../../assets/img/department/${de.deptname}.png`)}
                              alt=""
                              width={'25'}
                            />
                          </div>
                          <div className={styles.childTxtBorder}>
                            <span className={styles.childText}>
                              {de.deptname}
                            </span>
                            <span
                              onClick={() =>
                                handleRemoveDept(de.deptname, de.code)
                              }
                              className={styles.closeIcon}
                            >
                              x
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                    <div className={styles.reactSelect}>
                      {/* <div>
                        <img
                          onClick={() => setDeptMenuOpen(!deptmenuOpen)}
                          width={'70px'}
                          style={{
                            borderRadius: '50%',
                            // padding: '5px',
                            width: '52px',
                            height: '45px',
                            cursor: 'pointer',
                            marginTop: '-15px',
                          }}
                          src={require(`../../assets/img/users/grea-plus.PNG`)}
                          alt="country"
                        />
                      </div> */}
                      <div>
                        <Popup
                          visible={deptmenuOpen}
                          onClose={() => setDeptMenuOpen(false)}
                          animationDuration
                          style={{
                            width: 'auto',
                            // maxWidth: '340px',
                            padding: '30px 0px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            position: 'absolute',
                            top: '90px',
                            // left: '72px',
                          }}
                        >
                          {selectedDepart.length == 6 ? (
                            <>
                              <h3>No Service Available</h3>
                            </>
                          ) : (
                            <>
                              {oldDept.map((code) => {
                                console.log(code.code, 'code.code');
                                console.log(dept, 'deptdept');
                                if (!selectedDepart.includes(code.code)) {
                                  if (code.visibility == 0) {
                                    return (
                                      <ul
                                        style={{
                                          listStyleType: 'none',
                                          padding: '0px',
                                        }}
                                      >
                                        <li
                                          onClick={() =>
                                            departmentClicked(code)
                                          }
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <div className={styles.countryOption}>
                                            <span>
                                              <img
                                                width={'52px'}
                                                style={{
                                                  // borderRadius: '50%',
                                                  padding: '0px !important',
                                                  margin: '0px !important',
                                                  height: '35px !important',
                                                  width: '35px',
                                                }}
                                                // src={require(`../../assets/img/department/${country.image}`)}
                                                src={
                                                  code.deptname
                                                    ? require(`../../assets/img/department/${code.deptname}.png`)
                                                    : require(`../../assets/img/department/default-user.PNG`)
                                                }
                                                alt="country"
                                              />
                                            </span>
                                            <span className={styles.spanEmail}>
                                              <span
                                                style={{
                                                  fontSize: '12px',
                                                  color: 'black',
                                                }}
                                              >
                                                {code.deptname}
                                              </span>
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    );
                                  }
                                }
                              })}
                            </>
                          )}
                        </Popup>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.formContainer}>
                  <Col style={{ margin: 'auto' }} className="col-auto">
                    <div
                      style={{ float: 'left' }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <small
                        style={{
                          fontSize: '15px',
                          paddingBottom: '7px',
                          color: '#525f91',
                        }}
                        className="fw-bold font-size-14 font-weight-bold mr-2"
                      >
                        Payment Received
                      </small>
                      <div>
                        <label
                          style={{
                            background: '#fb6340',
                            borderRadius: '50px',
                          }}
                          className="custom-toggle custom-toggle-white"
                        >
                          <input
                            onChange={paymentChangeStatus}
                            checked={paymentStatus}
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </div>
                    </div>
                  </Col>

                  {/* <div className={styles.formGroup}> */}
                  <div className="row quaqe">
                    <div className="col-md-6">
                      {/* <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      > */}
                      <TextField
                        required
                        id="standard-basic"
                        label="First Name"
                        variant="standard"
                        name="firstname"
                        value={user.firstname}
                        onChange={validateFirstName}
                        // className={`form-control ${
                        //   userError.firstnameError.length > 0
                        //     ? 'is-invalid'
                        //     : ''
                        // }`}
                      />
                      {/* </Box> */}
                      {/* <input
                          type="text"
                          name="firstname"
                          value={user.firstname}
                          onChange={validateFirstName}
                          placeholder={`Firstname`}
                          className={`form-control ${
                            userError.firstnameError.length > 0
                              ? 'is-invalid'
                              : ''
                          }`}
                        /> */}
                      {/* <div className="input-container">
                          <input
                            type="text"
                            name="firstname"
                            value={user.firstname}
                            onChange={validateFirstName}
                            placeholder=" "
                            className={`form-control ${
                              userError.firstnameError.length > 0
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          <label
                            className={user.firstname && 'filled'}
                            htmlFor={user.firstname}
                            style={{ fontSize: '15px', color: '#b5b5cc' }}
                          >
                            <span className="p-1 text-danger">*</span>First name
                          </label>
                        </div> */}
                      {userError.firstnameError.length > 0 ? (
                        <p
                          style={{ textAlign: 'left', fontSize: '12.8px' }}
                          className="text-danger text-left"
                        >
                          {userError.firstnameError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* <div className="col-md-1"></div> */}
                    <div id="lastname" className="col-md-6">
                      {/* <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      > */}
                      <TextField
                        required
                        id="standard-basic"
                        label="Last Name"
                        variant="standard"
                        name="lastname"
                        value={user.lastname}
                        onChange={validateLastName}
                        // className={`form-control ${
                        //   userError.firstnameError.length > 0
                        //     ? 'is-invalid'
                        //     : ''
                        // }`}
                      />
                      {/* </Box> */}
                      {/* <input
                          type="text"
                          name="lastname"
                          value={user.lastname}
                          onChange={validateLastName}
                          placeholder={`Lastname`}
                          className={`form-control ${
                            userError.lastnameError.length > 0
                              ? 'is-invalid'
                              : ''
                          }`}
                        /> */}
                      {userError.lastnameError.length > 0 ? (
                        <p
                          style={{ textAlign: 'left', fontSize: '12.8px' }}
                          className="text-danger text-left"
                        >
                          {userError.lastnameError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* </div> */}

                  {/* <div className={styles.formGroup}> */}
                  {/* <pre>{JSON.stringify(enquiry.closure_date)}</pre> */}
                  <div
                    // style={{ marginTop: '26px !important' }}
                    className="row quaqe"
                  >
                    <div className="col-md-6">
                      {/* <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      > */}
                      <TextField
                        required
                        type="number"
                        id="standard-basic"
                        label="Phone Number"
                        variant="standard"
                        name="phone"
                        value={user.phone}
                        onChange={validatePhoneNumber}
                        // className={`form-control ${
                        //   userError.firstnameError.length > 0
                        //     ? 'is-invalid'
                        //     : ''
                        // }`}
                      />
                      {/* </Box> */}
                      {/* <input
                          type="number"
                          name="phone"
                          value={user.phone}
                          onChange={validatePhoneNumber}
                          placeholder="Phone Number"
                          className={`form-control ${
                            userError.phonenumberError.length > 0
                              ? 'is-invalid'
                              : ''
                          }`}
                        /> */}
                      {userError.phonenumberError.length > 0 ? (
                        <p
                          style={{ textAlign: 'left', fontSize: '12.8px' }}
                          className="text-danger text-left"
                        >
                          {userError.phonenumberError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="col-md-6">
                      {enquiry && enquiry.closure_date != '' && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '-2px',
                            left: '20px',
                            fontSize: '12px',
                          }}
                        >
                          Closure Date *
                        </div>
                      )}
                      <ReactDatetimeClass
                        isValidDate={disablePastDt}
                        popperContainer={CalendarContainer}
                        inputProps={{
                          placeholder: 'Closure Date *',
                          readOnly: true,
                        }}
                        // value={startDate}
                        timeFormat={false}
                        value={enquiry.closure_date}
                        dateFormat="MMMM Do YYYY"
                        // value={startDate}
                        onChange={validateEnquiryClosureDate}
                        renderDay={(props, currentDate, selectedDate) => {
                          // let classes = props.className;
                          // classes +=
                          //   getClassNameReactDatetimeDays(currentDate);
                          return (
                            <td
                              {...props}
                              // className={classes}
                            >
                              {currentDate.date()}
                            </td>
                          );
                        }}
                      />

                      {enquiryError != '' &&
                      enquiryError.closure_dateError != '' ? (
                        <p
                          style={{
                            textAlign: 'left !important',
                            fontSize: '12.8px',
                          }}
                          className="text-danger mt-1 text-left"
                        >
                          {enquiryError.closure_dateError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className={styles.formGroup}> */}
                  <div className="row quaqe">
                    <div className="col-md-6">
                      {/* <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      > */}
                      <TextField
                        required
                        id="standard-basic"
                        label="Reference"
                        variant="standard"
                        name="referred_by"
                        value={enquiry.referred_by}
                        onChange={validateRef}
                        // className={`form-control ${
                        //   userError.firstnameError.length > 0
                        //     ? 'is-invalid'
                        //     : ''
                        // }`}
                      />
                      {/* </Box> */}
                      {/* <input
                          type="text"
                          name="referred_by"
                          value={enquiry.referred_by}
                          onChange={validateRef}
                          placeholder="Reference"
                          className={`form-control ${
                            enquiryError.refError.length > 0 ? 'is-invalid' : ''
                          }`}
                        /> */}
                      {enquiryError.refError.length > 0 ? (
                        <p
                          style={{ textAlign: 'left', fontSize: '12.8px' }}
                          className="text-danger text-left"
                        >
                          {enquiryError.refError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-md-6">
                      {/* <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                      > */}
                      <TextField
                        required
                        type="number"
                        id="standard-basic"
                        label="Reference Contact Number"
                        variant="standard"
                        name="referral_contact"
                        value={enquiry.referral_contact}
                        onChange={validateRefDetails}
                        // className={`form-control ${
                        //   userError.firstnameError.length > 0
                        //     ? 'is-invalid'
                        //     : ''
                        // }`}
                      />
                      {/* </Box> */}
                      {/* <input
                          type="number"
                          name="referral_contact"
                          value={enquiry.referral_contact}
                          onChange={validateRefDetails}
                          placeholder="Reference Contact Number"
                          className={`form-control ${
                            enquiryError.ref_detailsError.length > 0
                              ? 'is-invalid'
                              : ''
                          }`}
                        /> */}
                      {enquiryError.ref_detailsError.length > 0 ? (
                        <p
                          style={{ textAlign: 'left', fontSize: '12.8px' }}
                          className="text-danger text-left"
                        >
                          {enquiryError.ref_detailsError}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className={styles.formGroup}> */}
                  <div className={styles.newRevOrder}>
                    <div className="row quaqe">
                      <div className={styles.reverseOrder}>
                        <div ref={myRef} className="col-md-2">
                          {enquiry.assigned_to.length == 0 &&
                            selectShow == false && (
                              <div className={styles.convertImage}>
                                <img
                                  onClick={() => setSelectShow(true)}
                                  width={'70px'}
                                  style={{
                                    borderRadius: '50%',
                                    padding: '5px',
                                    width: '55.8px',
                                    height: '51.8px',
                                  }}
                                  src={require(`../../assets/img/users/grea-plus.PNG`)}
                                  alt="country"
                                />
                                <p style={{ fontSize: '13px' }}>Assignee</p>
                              </div>
                            )}
                          {enquiry.assigned_to.length > 0 && avatar != null ? (
                            <div className={styles.convertImage}>
                              {
                                <>
                                  {/* <img
                                  onClick={handleConvert}
                                  width={'70px'}
                                  style={{
                                    borderRadius: '50%',
                                    padding: '5px',
                                    width: '55.8px',
                                    height: '51.8px',
                                  }}
                                  src={
                                    avatar
                                      ? `${imageUrl}${avatar}`
                                      : require(`../../assets/img/users/default-user.PNG`)
                                  }
                                  alt="country"
                                /> */}
                                  <div
                                    onClick={handleConvert}
                                    className={styles.companyHeaderAvatar}
                                    style={{
                                      backgroundImage: avatar
                                        ? ` url(${imageUrl}${avatar})`
                                        : `url(require('../../assets/img/users/default-user.PNG'))`,
                                      backgroundSize: '100% 100%',
                                    }}
                                  ></div>
                                </>
                              }
                              {enquiry.assigned_to.length > 0 &&
                              enquiry.assigned_to[0].value ? (
                                <p
                                  style={{
                                    fontSize: '13px',
                                    paddingLeft: '7px',
                                  }}
                                >
                                  {enquiry.assigned_to[0].value}
                                </p>
                              ) : (
                                <p style={{ fontSize: '13px' }}>Assignee</p>
                              )}
                            </div>
                          ) : (
                            selectShow == true && (
                              <>
                                {/* <div className={styles.reactSelect}> */}
                                <div className={styles.convertImage}>
                                  {/* <img
                                  onClick={() => setSelectShow(true)}
                                  width={'70px'}
                                  style={{
                                    borderRadius: '50%',
                                    padding: '5px',
                                    width: '55.8px',
                                    height: '51.8px',
                                  }}
                                  src={
                                    assigneeImage
                                      ? `${imageUrl}${assigneeImage}`
                                      : require(`../../assets/img/users/grea-plus.PNG`)
                                  }
                                  alt="country"
                                /> */}
                                  <div
                                    onClick={() => setSelectShow(true)}
                                    className={styles.companyHeaderAvatar}
                                    style={{
                                      backgroundImage: assigneeImage
                                        ? ` url(${imageUrl}${assigneeImage})`
                                        : `url(require('../../assets/img/users/grea-plus.PNG'))`,
                                      backgroundSize: '100% 100%',
                                    }}
                                  ></div>
                                  {enquiry.assigned_to.length > 0 &&
                                  enquiry.assigned_to[0].value ? (
                                    <p
                                      style={{
                                        fontSize: '13px',
                                        paddingLeft: '7px',
                                      }}
                                    >
                                      {enquiry.assigned_to[0].value}
                                    </p>
                                  ) : (
                                    <p style={{ fontSize: '13px' }}>Assignee</p>
                                  )}
                                </div>

                                <Select
                                  autosize={false}
                                  menuIsOpen={menuOpen}
                                  onBlur={handleBlur}
                                  value={enquiry.assigned_to}
                                  onChange={validateEnquiryAssignee}
                                  components={{ Menu }}
                                  placeholder={'Choose Assignee'}
                                  isMulti
                                  menuPlacement="top"
                                  styles={colourStyles}
                                  isValidNewOption={isValidNewOption}
                                  options={aquaticCreatures}
                                  formatOptionLabel={(country) => (
                                    console.log(
                                      styles.countryOption,
                                      'styles.countryOption'
                                    ),
                                    (
                                      <div className={styles.countryOption}>
                                        <span>
                                          {/* <img
                                          height={'50px'}
                                          width={'50px'}
                                          style={{
                                            borderRadius: '50%',
                                            padding: '0px !important',
                                            margin: '0px !important',
                                            height: '35px !important',
                                            width: '35px',
                                          }}
                                          src={`${imageUrl}${country.image}`}
                                          alt="country"
                                        /> */}
                                          <div
                                            className={
                                              styles.companyHeaderAvatar
                                            }
                                            style={{
                                              backgroundImage: `url(${imageUrl}${country.image})`,
                                              backgroundSize: '100% 100%',
                                            }}
                                          ></div>
                                        </span>
                                        <span className={styles.spanEmail}>
                                          <span style={{ fontSize: '12px' }}>
                                            {country.label}
                                          </span>
                                          <span style={{ fontSize: '10px' }}>
                                            {country.email}
                                          </span>
                                        </span>
                                      </div>
                                    )
                                  )}
                                />
                                {/* </div> */}
                              </>
                            )
                          )}
                          {enquiry.assigned_to.length == 0 &&
                          enquiryError != '' &&
                          enquiryError.assigneeError != '' &&
                          enquiryError.assigneeError.length > 0 ? (
                            <p
                              style={{ textAlign: 'left', fontSize: '12.8px' }}
                              className="text-danger mt-2 text-left"
                            >
                              {enquiryError.assigneeError}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="col-md-1"></div>
                        <div style={{ width: 'inherit', padding: '0 15px' }}>
                          {/* <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                          }}
                          noValidate
                          autoComplete="off"
                        > */}
                          {enquiry && enquiry.enquiry_details != '' && (
                            <small>Enquiry Details *</small>
                          )}
                          <div
                            style={{
                              // marginTop: '21px',
                              borderTop: '1px solid gray',
                              borderLeft: '1px solid gray',
                              borderRight: '1px solid gray',
                              borderBottom: '1px solid gray',
                              // paddingTop: '20px',
                              marginBottom: '30px',
                            }}
                          >
                            {/* <TextField
                            id="standard-basic"
                            label="Enquiry Details"
                            variant="standard"
                            name="enquiry_details"
                            value={enquiry.enquiry_details}
                            onChange={validateEnquiryDetails}
                            // className={`form-control ${
                            //   userError.firstnameError.length > 0
                            //     ? 'is-invalid'
                            //     : ''
                            // }`}
                          /> */}
                            <textarea
                              type="text"
                              name="enquiry_details"
                              value={enquiry.enquiry_details}
                              onChange={validateEnquiryDetails}
                              placeholder="Enquiry Details *"
                              className={`form-control ${
                                enquiryError.enq_detailsError.length > 0
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              rows="2"
                            />
                          </div>

                          {/* </Box> */}
                          {/* <textarea
                            type="text"
                            name="enquiry_details"
                            value={enquiry.enquiry_details}
                            onChange={validateEnquiryDetails}
                            placeholder="Enquiry Details"
                            className={`form-control ${
                              enquiryError.enq_detailsError.length > 0
                                ? 'is-invalid'
                                : ''
                            }`}
                            rows="3"
                          /> */}
                          {enquiryError != '' &&
                          enquiryError.enq_detailsError != '' &&
                          enquiryError.enq_detailsError.length > 0 ? (
                            <p
                              style={{ textAlign: 'left', fontSize: '12.8px' }}
                              className="text-danger mt-1 text-left"
                            >
                              {enquiryError.enq_detailsError}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* </div> */}

                  <div
                    style={{ paddingBottom: '33px', paddingTop: '40px' }}
                    className={styles.btnCon}
                  >
                    <button
                      onClick={submitRegistration}
                      className="btn btn-warning m-2"
                    >
                      Save
                    </button>
                    <Link
                      onClick={() => {
                        setDept([]);
                      }}
                      to={'/admin/enquiry'}
                      className="btn btn-dark m-2"
                    >
                      Close
                    </Link>
                  </div>
                  <div style={{ marginBottom: '40px' }}></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default EnquiryForm;

import { UsercreateConstants } from '../_constants';
import { usercreateService } from '../_services';

export const usercreateActions={

    getcolumnlist,
    createuser,
    listUser,
    deleteuser,
    getuserlist,
    editUser,
    updateReduxProfile,
    getdeptname,
    getrolename

}

function getcolumnlist(){

    return async dispatch => {
        dispatch(request());
           await usercreateService.getcolumnlist()
              .then(list => {
                   dispatch(success(list))
              })
              .catch(error => {
                failure(error);
              });
          };

      function request() {

        return { type: UsercreateConstants.GET_USER_FIELDS_REQUEST } }
        function success(columns) {

          return { type: UsercreateConstants.GET_USER_FIELDS_SUCCESS, columns }
            }
        function failure(error) { return { type: UsercreateConstants.GET_USER_FIELDS_FAILURE },error }
}

function getuserlist(id){

    return async dispatch => {
    dispatch(request());
       await usercreateService.geteditlist(id)
          .then(user => {
               dispatch(success(user.data))
          })
          .catch(error => {
            failure(error);
          });
      };

  function request() { return { type: UsercreateConstants.GET_USER_REQUEST } }
    function success(userdata) {

        return { type: UsercreateConstants.GET_USER_SUCCESS,userdata }
        }
    function failure(error) { return { type: UsercreateConstants.GET_USER_FAILURE },error }
}

function getdeptname(){

    return async dispatch => {
    dispatch(request());
       await usercreateService.getdeptname()
          .then(userspro => {
               dispatch(success(userspro.data))
          })
          .catch(error => {
            failure(error);
          });
      };

  function request() { return { type: UsercreateConstants.GET_PRODEPNAME_REQUEST } }
    function success(userspro) {
        return { type: UsercreateConstants.GET_PRODEPNAME_SUCCESS,userspro }
        }
    function failure(error) { return { type: UsercreateConstants.GET_PRODEPNAME_FAILURE },error }
}

function getrolename(){

    return async dispatch => {
    dispatch(request());
       await usercreateService.getrolename()
          .then(userrole => {
               dispatch(success(userrole.data))
          })
          .catch(error => {
            failure(error);
          });
      };

  function request() { return { type: UsercreateConstants.GET_ROLE_REQUEST } }
    function success(userrole) {
        return { type: UsercreateConstants.GET_ROLE_SUCCESS,userrole }
        }
    function failure(error) { return { type: UsercreateConstants.GET_ROLE_FAILURE },error }
}

function editUser(data,listuser){
    return async dispatch => {
        dispatch(request());

        await usercreateService.edituser(data)
        .then(
                user => dispatch(success(user,listuser)),
                error  => dispatch(failure(error))
        );
    };
    function request() { return { type: UsercreateConstants.EDIT_USER_REQUEST } }
    function success(edituser,listuser) { return { type: UsercreateConstants.EDIT_USER_SUCCESS, edituser } }
    function failure(error) { return { type: UsercreateConstants.EDIT_USER_FAILURE, error } }
}

function updateReduxProfile(data, name){
    return async dispatch => { await dispatch(success(data)) };
    function success(user, name) { return { type: UsercreateConstants.UPDATE_REDUX_USER_SUCCESS, user, name } }
}


function createuser(data,listuser){
    return async dispatch => {
        dispatch(request());
        await usercreateService.createUser(data)

            .then(
                user => dispatch(success(user,listuser)),
                error  => dispatch(failure(error))
            );
    };
    function request() { return { type: UsercreateConstants.CREATE_USER_REQUEST } }
    function success(user,listuser) {
        listuser["user"]=listuser;
        return { type: UsercreateConstants.CREATE_USER_SUCCESS, user }
    }
    function failure(error) {

        return { type: UsercreateConstants.CREATE_USER_FAILURE, error }
    }
}

function listUser(_currentPage, _searchText, _pageCount){

    return async dispatch => {
    dispatch(request());
       await usercreateService.getuserlist(_currentPage, _searchText, _pageCount)
          .then(list => {
               dispatch(success(list))
          })
          .catch(error => {
            failure(error);
          });
      };

  function request() {
   return { type: UsercreateConstants.LIST_USER_REQUEST } }
    function success(list) {
        return { type: UsercreateConstants.LIST_USER_SUCCESS, list }
        }
    function failure(error) { return { type: UsercreateConstants.LIST_USER_FAILURE },error }
}



function deleteuser(id){
    return async dispatch => {

           await usercreateService.deleteuser(id)
              .then(wallet => {
                   dispatch(success('success deleted'))
              });
                };
          function success() {

            return { type: UsercreateConstants.DELETE_USER }
           }
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };
  static defaultProperty = {
    suggestions: [],
    additionalClasses: '',
    big: false,
    clearValue: false,
    _inputFocus: false
  };
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: ""
    };
  }

  onChange = e => {
    const { dispatch } = this.props;
    const userInput = e.currentTarget.value;
    this.setState({ suggestionsLoading: true, userInput: e.currentTarget.value });
    let customerData = { 'search': userInput };
    dispatch(userActions.getCustomerList(customerData)).then(() => {
      const { customersList } = this.props;
      const { customers } = customersList;
      const { data } = customers;

      let filteredSuggestions = [];
      let dataSuggested = [];
      customers && customers.forEach(v => { let phoneAppend =  (v.phone) ? `- ${v.phone}` : ``; let lastNameAppend =  (v.lastname) ? `${v.lastname}` : ``; filteredSuggestions.push(`${v.firstname} ${lastNameAppend} ${phoneAppend}`); dataSuggested.push(v); });

      this.setState({ activeSuggestion: 0, filteredSuggestions, showSuggestions: true, suggestionsLoading: false, dataSuggested });
    });
  };

  onSuggestionClick = e => {
    let elem = e.target;
    let dataItem = elem.getAttribute('data-item');
    const { setStatesModalFull } = this.props;
    setStatesModalFull(this.state.dataSuggested[dataItem]);

    this.setState({ activeSuggestion: 0, filteredSuggestions: [], showSuggestions: false, userInput: e.currentTarget.innerText });
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const { setStatesModalFull } = this.props;

    if (e.keyCode === 13) {
      setStatesModalFull(this.state.dataSuggested[0]);
      this.setState({ activeSuggestion: 0, showSuggestions: false, userInput: filteredSuggestions[activeSuggestion] });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) { return; }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) { return; }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  componentDidUpdate(prevProps, prevState, snapShot){
    const { clearValue, _inputFocus } = this.props;
    if(clearValue !== prevProps.clearValue && clearValue){
      this.setState({ userInput: "" });
    }
    if(_inputFocus !== prevProps._inputFocus && _inputFocus){
      this.refs.searchInput.focus();
    }
  }

  render() {
    const { onChange, onClick, onKeyDown, state: { activeSuggestion, filteredSuggestions, showSuggestions, userInput, suggestionsLoading } } = this;
    const { additionalClasses, big } = this.props;
    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions border rounded shadow-lg">
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              if (index === activeSuggestion) { className = ""; }
              return (
                <li key={suggestion} className={`w-100 suggestion_list suggestion_list_${index}`} data-item={index} onClick={this.onSuggestionClick} >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions border card shadow-lg w-100">
            No suggestions
          </div>
        );
      }
    }

    let classNameData = `form-control-sm`;
    if(big)
      classNameData = ``;

    return (
      <React.Fragment>
        <input
          type="search" onChange={onChange} onKeyDown={onKeyDown} value={userInput} className={`auto-complete-search form-control ${classNameData} ${additionalClasses}`} placeholder={`Search...`} ref={`searchInput`}
        />
        {suggestionsListComponent}
        {suggestionsLoading ? (<span className="search-input-loader position-absolute"><i className="fas fa-spin fa-circle-notch"></i></span>) : ''}
      </React.Fragment>
    );
  }
}

// export default Autocomplete;

function mapStateToProps(state) {
  const { customersList, } = state;
  return { customersList, };
}

const connectedAutocompletePage = connect(mapStateToProps)(Autocomplete);
export { connectedAutocompletePage as Autocomplete };
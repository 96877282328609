import { userConstants } from '../_constants';

export function customersList(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_CUSTOMER_LIST_REQUEST:
      return {
        customers: action.customers
      };
    case userConstants.GET_CUSTOMER_LIST_SUCCESS:
      return {
        customers: action.customers
      };
    case userConstants.GET_CUSTOMER_LIST_FAILURE:
      return {
        customers: action
      };
    default:
      return state
    }
}

export function customer(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_CUSTOMER_REQUEST:
      return {
        customer: action.data
      };
    case userConstants.CREATE_CUSTOMER_SUCCESS:
      return {
        customer: action.data
      };
    case userConstants.CREATE_CUSTOMER_FAILURE:
      return {
        customer: action
      };
    case userConstants.GET_CUSTOMER_REQUEST:
      return {
        customer: action.data
      };
    case userConstants.GET_CUSTOMER_SUCCESS:
      return {
        customer: action.data
      };
    case userConstants.GET_CUSTOMER_FAILURE:
      return {
        customer: action
      };
    case userConstants.UPDATE_CUSTOMER_REQUEST:
      return {
        update: action.data
      };
    case userConstants.UPDATE_CUSTOMER_SUCCESS:
      return {
        update: action.data
      };
    case userConstants.UPDATE_CUSTOMER_FAILURE:
      return {
        update: action
      };
    default:
      return state
  }
}
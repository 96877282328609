import { userConstants } from '../_constants';

export function vendorservice(state = {}, action) {
    switch (action.type) {
                  case userConstants.GET_VENDORBILLING_REQUEST:
                  return{
                    vendorservice:action
                 };
                 case userConstants.GET_VENDORBILLING_SUCCESS:
                    return{
                        vendorservice:action.vendorservice
                   };
                 case userConstants.GET_VENDORBILLING_FAILURE:
                       return{
                         error: action.error
                   };

                   case userConstants.UPDATE_VENDORBILLING_REQUEST:
                    return {
                      loading: true
                    };
                  case userConstants.UPDATE_VENDORBILLING_SUCCESS:
                    return {
                        vendor: action.vendor
                    };
                  case userConstants.UPDATE_VENDORBILLING_FAILURE:
                    return { 
                      error: action.error
                    };
      default:
      return state
    }
}



import { placesConstants } from '../_constants';
import { placesService } from '../_services';

export const placesActions = {
    placesCreate
};

function placesCreate(data){
    return async dispatch => {
        dispatch(request());
        await placesService.placesCreate(data).then(
                places => dispatch(success(places)),
                error  => dispatch(failure(error))
            );
    };
    function request() { return { type: placesConstants.CREATE_PLACE_REQUEST } }
    function success(places) { return { type: placesConstants.CREATE_PLACE_SUCCESS, places } }
    function failure(error) { return { type: placesConstants.CREATE_PLACE_FAILURE, error }  }
}
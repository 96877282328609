import React, { Component } from "react";
import { connect } from 'react-redux';
import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody } from "reactstrap";
// import { parseOptions, chartOptions } from "../../_functions";
import { userActions } from "../../_actions";
import { chartOptions, parseOptions, } from "variables/charts.jsx";

class TotalServices extends Component {
    componentWillMount() {
        if (window.Chart) {
          parseOptions(Chart, chartOptions());
        }
    }
    state = { loading: true, _chartData: { datasets:[
        { label: "Tour", backgroundColor: "#5e72e4", data: [] },
        { label: "Passport", backgroundColor: "#32325d", data: [] },
        { label: "Visa", backgroundColor: "#11cdef", data: [] },
        { label: "Food", backgroundColor: "#f5365c", data: [] },
        { label: "Currencies", backgroundColor: "#2dce89", data: [] },
        { label: "Hotel", backgroundColor: "#fb6340", data: [] },
        { label: "Flight", backgroundColor: "#8965e0", data: [] },
        { label: "Insurance", backgroundColor: "#f3a4b5", data: [] },
        { label: "Vehicle", backgroundColor: "#ccab00", data: [] },
    ], labels: [] }, _chartOptions: {tooltips: { mode: "index", intersect: false }, responsive: true, scales: { xAxes: [ { stacked: true } ], yAxes: [ { stacked: true } ] } }, _select_department: '' };

    componentDidMount() {
        const { dispatch } = this.props;
        let { _chartData } = this.state;
        dispatch(userActions.ticketgraphmonth({ type: 'services' })).then(() => {
            const { ticketGraph } = this.props;
            if(ticketGraph && ticketGraph.ticketGraph){
                const { data } = ticketGraph.ticketGraph;
                data && Object.keys(data).forEach(key => {
                    _chartData.labels.push(key);
                    if(!data[key]["1"])
                        _chartData.datasets[0].data.push(0);
                    else
                        _chartData.datasets[0].data.push(parseInt(data[key]["1"].count));
                    if(!data[key]["2"])
                        _chartData.datasets[1].data.push(0);
                    else
                        _chartData.datasets[1].data.push(parseInt(data[key]["2"].count));
                    if(!data[key]["3"])
                        _chartData.datasets[2].data.push(0);
                    else
                        _chartData.datasets[2].data.push(parseInt(data[key]["3"].count));
                    if(!data[key]["4"])
                        _chartData.datasets[3].data.push(0);
                    else
                        _chartData.datasets[3].data.push(parseInt(data[key]["4"].count));
                    if(!data[key]["5"])
                        _chartData.datasets[4].data.push(0);
                    else
                        _chartData.datasets[4].data.push(parseInt(data[key]["5"].count));
                    if(!data[key]["6"])
                        _chartData.datasets[5].data.push(0);
                    else
                        _chartData.datasets[5].data.push(parseInt(data[key]["6"].count));
                    if(!data[key]["7"])
                        _chartData.datasets[6].data.push(0);
                    else
                        _chartData.datasets[6].data.push(parseInt(data[key]["7"].count));
                    if(!data[key]["8"])
                        _chartData.datasets[7].data.push(0);
                    else
                        _chartData.datasets[7].data.push(parseInt(data[key]["8"].count));
                    if(!data[key]["9"])
                        _chartData.datasets[8].data.push(0);
                    else
                        _chartData.datasets[8].data.push(parseInt(data[key]["9"].count));
                });
                
                this.setState({ _chartData: _chartData });
            }
        });
    }

    render() {
        const { _chartData, _chartOptions } = this.state;
        if(_chartData.labels.length <= 0)
            return null;
        return (
            <>
                <Card className="mb-0">
                    <CardHeader>
                        <h6 className="surtitle">Overview</h6>
                        <h5 className="h3 mb-0">Total Services</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="chart">
                            <Bar data={_chartData} options={_chartOptions} id="chart-services" className="chart-canvas" />
                        </div>
                    </CardBody>
                </Card>
            </>
        )
    }
}

// export default Autocomplete;

function mapStateToProps(state) {
  const { ticketGraph, authentication } = state;
  return { ticketGraph, authentication };
}

const connectedTotalServicesPage = connect(mapStateToProps)(TotalServices);
export { connectedTotalServicesPage as TotalServices };
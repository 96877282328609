import { userConstants } from '../_constants';

export function expenses(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_EXPENSES_REQUEST:
      return {
        document: action.data
      };
    case userConstants.CREATE_EXPENSES_SUCCESS:
      return {
        document: action.data
      };
    case userConstants.CREATE_EXPENSES_FAILURE:
      return {};
    default:
      return state
  }
}
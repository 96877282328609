import { userConstants } from '../_constants';

export function serviceFields(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_SERVICE_FIELDS_REQUEST:
      return {
        service: action.dataArr
      };
    case userConstants.GET_SERVICE_FIELDS_SUCCESS:
      return {
        service: action.dataArr
      };
    case userConstants.GET_SERVICE_FIELDS_FAILURE:
      return {};
    default:
      return state
  }
}
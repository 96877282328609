import { placesConstants } from '../_constants';

export function placesCreate(state = {}, action) {
  switch (action.type) {
    case placesConstants.CREATE_PLACE_REQUEST:
      return { loading: true };
    case placesConstants.CREATE_PLACE_SUCCESS:
      return { place: action.places };
    case placesConstants.CREATE_PLACE_FAILURE:
      return { error: action.error };
    default:
      return state
  }
}
import { userConstants } from '../_constants';

export function special(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_SPECIAL_REQUEST:
      return {
        create: action.special
      };
    case userConstants.CREATE_SPECIAL_SUCCESS:
      return {
        create: action.special
      };
    case userConstants.CREATE_SPECIAL_FAILURE:
      return {};
    case userConstants.UPDATE_SPECIAL_REQUEST:
      return {
        update: action.special
      };
    case userConstants.UPDATE_SPECIAL_SUCCESS:
      return {
        update: action.special
      };
    case userConstants.UPDATE_SPECIAL_FAILURE:
      return {};
    case userConstants.DELETE_SPECIAL_REQUEST:
      return {
        delete: action.special
      };
    case userConstants.DELETE_SPECIAL_SUCCESS:
      return {
        delete: action.special
      };
    case userConstants.DELETE_SPECIAL_FAILURE:
      return {};
    case userConstants.LIST_SPECIAL_REQUEST:
      return {
        list: action.special
      };
    case userConstants.LIST_SPECIAL_SUCCESS:
      return {
        list: action.special
      };
    case userConstants.LIST_SPECIAL_FAILURE:
      return {};
    case userConstants.GET_TICKET_SPECIAL_REQUEST:
      return {
        ticket: action.special
      };
    case userConstants.GET_TICKET_SPECIAL_SUCCESS:
      return {
        ticket: action.special
      };
    case userConstants.GET_TICKET_SPECIAL_FAILURE:
      return {};
    default:
      return state
  }
}
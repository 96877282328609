import React, { Component } from "react";
import { connect } from 'react-redux';
import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody } from "reactstrap";
import { chartOptions, parseOptions, } from "variables/charts.jsx";
import { userActions } from "../../_actions";

class TotalConverted extends Component {
    componentWillMount() {
        if (window.Chart) {
          parseOptions(Chart, chartOptions());
        }
    }
    state = { loading: true, _chartData: { datasets:[{ data: [], label: "Tickets" }], labels: [] }, _chartOptions: { scales: { yAxes: [ { gridLines: { color: '#e9ecef', zeroLineColor: '#e9ecef' }, ticks: { callback: function(value) { if (!(value % 10)) { return value; } } } } ] }, tooltips: { callbacks: { label: function(item, data) { var label = data.datasets[item.datasetIndex].label || ""; var yLabel = item.yLabel; var content = ""; if (data.datasets.length > 1) { content += label; } content += yLabel; return content; } } }
    }, _select_department: '' };

    componentDidMount() {
        const { dispatch } = this.props;
        let { _chartData } = this.state;
        dispatch(userActions.ticketgraphmonth({type: 'converted'})).then(() => {
            const { ticketGraph } = this.props;
            if(ticketGraph && ticketGraph.ticketGraph){
                const { data } = ticketGraph.ticketGraph;
                data && data.forEach(v => {
                    _chartData.datasets[0].data.push(parseInt(v.data));
                    _chartData.labels.push(v.new_date);
                });
                this.setState({ _chartData: _chartData });
            }
        });
    }

    render() {
        const { _chartData, _chartOptions } = this.state;
        if(_chartData.labels.length <= 0)
            return null;
        return (
            <>
                <Card className="mb-0">
                    <CardHeader>
                        <h6 className="surtitle">Overview</h6>
                        <h5 className="h3 mb-0">Total converted</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="chart">
                            <Bar data={_chartData} options={_chartOptions} id="chart-sales" className="chart-canvas" />
                        </div>
                    </CardBody>
                </Card>
            </>
        )
    }
}

// export default Autocomplete;

function mapStateToProps(state) {
  const { ticketGraph, authentication } = state;
  return { ticketGraph, authentication };
}

const connectedTotalConvertedPage = connect(mapStateToProps)(TotalConverted);
export { connectedTotalConvertedPage as TotalConverted };
import React from "react";
// react library for routing
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from "reactstrap";
import styles from './sidebar.module.css';


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: true,
      role : "",
      ...this.getCollapseStates(props.routes)
    };
  }

  componentDidMount(){
    let temp = localStorage.getItem('user');
     if (temp) {
       let parseTemp = JSON.parse(temp);
       console.log('component_did_mount', parseTemp);
       this.setState({
        ...this.state,
        role : parseTemp.role
       })
     }
  }
     
 

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    // const { user } = this.props;
    let user = localStorage.getItem('user');
    if(!user){
      this.props.history.push('/');
    }
    else{

    
    let jsUser = JSON.parse(user);
    const userrole = jsUser && jsUser.role;
    const name = userrole;
    const roleToLower= name.toString().trim().toLowerCase();
    // const GetAccountRole = user.user.getdepart.deptname;
    const isAdmin = name.toString().trim().toLowerCase() === "admin";
    const isManager = name.toString().trim().toLowerCase() === "mananger";
    const isEmployee = roleToLower == 'Visa' || roleToLower == 'Passport'  || roleToLower == 'Taxi' ||  roleToLower == 'Tourism' ||  roleToLower == 'Vehicle' ||  roleToLower == 'Others' ;
    // const isAccounts = GetAccountRole.toString().trim().toLowerCase() === "accounts";
    const isAccounts = name.toString().trim().toLowerCase() === "accounts";

    return routes.map((prop, key) => {
      if ((prop.redirect || prop.isHidden) || (!isAdmin && !prop.isEmployeeVisible) || (!isAccounts && !isAdmin && prop.isEmployeeRole)) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        let views = (prop.views[0] && prop.views[0].views) ? prop.views[0].views : prop.views;
        return (
          <NavItem key={key}>
            <NavLink
              style={{ padding: '17px 23px' }}
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(views),
                [`${prop.class_name}`]: true,
              })}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon ? <i className={prop.icon} /> : null}
              <span
                style={{ marginLeft: '5px !important' }}
                className="nav-link-text"
              >
                {prop.name}
              </span>
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem
          className={this.activeRoute(prop.layout + prop.path) + ` ${prop.class_name}`}
          key={key}
        >
          <NavLink
            style={{padding : '17px 23px'}}
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={this.closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span style={{marginLeft : '5px'}} className="nav-link-text">{prop.name}</span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  }
  };
  render() {
    const { routes, logo, user } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-left justify-content-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames('sidenav-toggler d-none d-xl-block', {
                active: this.props.sidenavOpen,
              })}
               onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                {/* <i className="sidenav-toggler-line" /> */}
                {/* <i className="sidenav-toggler-line" /> */}
                {/* <i className="sidenav-toggler-line" /> */}
                <i className="fa fa-bars fa-1x" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner px-0">
          {/* {user ? (
            <Collapse navbar isOpen={false}>
              <Nav navbar>{this.createLinks(routes)}</Nav>
            </Collapse>
          ) : ''} */}
          <div className="navbar-inner">
            <Collapse navbar isOpen={true}>
              {(this.state.role == 'Taxi' ||
                this.state.role == 'Visa' ||
                this.state.role == 'Passport' ||
                this.state.role == 'Tourism' ||
                this.state.role == 'Vehicle' ||
                this.state.role == 'Others') && (
                <Nav navbar>
                  <NavItem className={styles.dashboardLink}>
                    <Link
                      activeClassName=""
                      to={'/admin/dashboardAssignee'}
                      className={styles.dashboardRLink}
                      onClick={this.closeSidenav}
                      style={{ padding: '9px 0px 14px', display: 'flex' }}
                    >
                      <i
                        style={{ color: '#5e72e4', marginTop: '3px' }}
                        className="fa fa-home"
                      />
                      <span style={{ marginLeft: '21px' }}>Dashboard</span>
                    </Link>
                  </NavItem>
                </Nav>
              )}
              {this.state.role == 'Admin' && (
                <Nav navbar>
                  <NavItem className={styles.dashboardLink}>
                    <Link
                      activeClassName=""
                      to={'/admin/dashboard'}
                      className={styles.dashboardRLink}
                      onClick={this.closeSidenav}
                      style={{ padding: '9px 0px 14px', display: 'flex' }}
                    >
                      <i
                        style={{ color: '#5e72e4', marginTop: '3px' }}
                        className="fa fa-home"
                      />
                      <span style={{ marginLeft: '21px' }}>Dashboard</span>
                    </Link>
                  </NavItem>
                </Nav>
              )}
              {this.state.role == 'Frontdesk' && (
                <Nav navbar>
                  <NavItem className={styles.dashboardLink}>
                    <Link
                      activeClassName=""
                      to={'/admin/dashboard'}
                      className={styles.dashboardRLink}
                      onClick={this.closeSidenav}
                      style={{ padding: '9px 0px 14px', display: 'flex' }}
                    >
                      <i
                        style={{ color: '#5e72e4', marginTop: '3px' }}
                        className="fa fa-home"
                      />
                      <span style={{ marginLeft: '21px' }}>Dashboard</span>
                    </Link>
                  </NavItem>
                </Nav>
              )}

              {this.state.role == 'Admin' || this.state.role == 'Frontdesk' ? (
                <Nav navbar>{this.createLinks(routes)}</Nav>
              ) : (
                <Nav className={styles.responsiveTablePro} navbar>
                  {this.createLinks(routes)}
                </Nav>
              )}
              {/* {this.state.role == 'Accountant' ? (
                <Nav navbar>{this.createLinks(routes)}</Nav>
              ) : (
                <Nav className={styles.responsiveTablePro} navbar>
                  {this.createLinks(routes)}
                </Nav>
              )}
              {this.state.role == 'Frontdesk' ? (
                <Nav navbar>{this.createLinks(routes)}</Nav>
              ) : (
                <Nav className={styles.responsiveTablePro} navbar>
                  {this.createLinks(routes)}
                </Nav>
              )} */}

              {/* <Nav className="mb-md-3" navbar>
                <NavItem className={styles.responsiveTable}>
                  <Link
                    activeClassName=""
                    to={'/admin/dashboardAssignee'}
                    className={styles.responsiveTableLink}
                    onClick={this.closeSidenav}
                    style={{ padding: '9px 0px 14px' }}
                  >
                    <i style={{ color: '#5e72e4' }} className="fa fa-home" />
                    <span style={{ marginLeft: '20px' }}>Dashboard</span>
                  </Link>
                </NavItem>
              </Nav> */}
              <Nav className="mb-md-3" navbar>
                <NavItem className={styles.responsiveTable}>
                  <Link
                    activeClassName=""
                    to={'/admin/enquiry-res-table'}
                    className={styles.responsiveTableLink}
                    onClick={this.closeSidenav}
                    style={{ padding: '9px 0px 14px' }}
                  >
                    <i style={{ color: '#5e72e4' }} className="fa fa-table" />
                    <span style={{ marginLeft: '20px' }}>Enquiry Lists</span>
                  </Link>
                </NavItem>
              </Nav>

              {this.state.role == 'Admin' && (
                <Nav className="mb-md-3" navbar>
                  <NavItem className={styles.dashboardLink}>
                    <Link
                      activeClassName=""
                      to={'/admin/remainder'}
                      className={styles.dashboardRLink}
                      onClick={this.closeSidenav}
                      style={{ padding: '4px 0px 14px', display: 'flex' }}
                    >
                      <i
                        style={{ color: '#5e72e4', paddingTop: '2px' }}
                        className="ni ni-time-alarm"
                      />
                      <span style={{ marginLeft: '24px' }}>Remainder</span>
                    </Link>
                  </NavItem>
                </Nav>
              )}
            </Collapse>
          </div>
        </div>
      </div>
    );
    return (
      <>
        <style>
          {/* {
            '\
          .sidenav{\
            pointer-events: none !important;\
          }'
          } */}
        </style>
        <Navbar
          style={{ paddingLeft: '0.5rem !important' }}
          className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
          onMouseEnter={this.onMouseEnterSidenav}
          onMouseLeave={this.onMouseLeaveSidenav}
        >
          {navigator.platform.indexOf('Win') > -1 ? (
            <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
          ) : (
            scrollBarInner
          )}
        </Navbar>
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

function mapStateToProps(state) {
  const { user } = state.authentication;
  return { user };
}

const connectedSidebarPage = connect(mapStateToProps)(withRouter(Sidebar));
export { connectedSidebarPage as Sidebar };

// export default Sidebar;

import React, { Component } from 'react';
import {
  Card, CardBody, Media, Input, Form, Button, Alert
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { userActions } from "../../_actions";
import classnames from "classnames";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { isArray } from 'util';
import moment from 'moment';
import { loadingButton, removeLoadingButton } from '../../_functions';

class CommentSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _ticketDetails: null,
      _loading: false,
      showAction: true
    };
  }

  renderDateInput(props, openCalendar, closeCalendar) {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text py-0 px-2 border-radius-25-px"><i className="far fa-calendar"></i></span>
        </div>
        <input {...props} />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { ticketDetails } = this.props;
    const { _loading } = this.state;
    if (this.props != prevProps) {
      if (ticketDetails && !_loading) {
        const { comments } = ticketDetails;
        // ticketDetails.comments = ticketDetails;;
        this.setState({
          _ticketDetails: ticketDetails,
          _loading: true
        });
      }
    }
  }

  notify = (place, title, message) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: "default",
      icon: "ni ni-bell-55",
      autoDismiss: 15
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  createComment(e) {
    e.preventDefault();
    const { textArea } = this.state;
    if (textArea) {
      let commentBtn = document.querySelector('.comment-btn');
      if (commentBtn) {
        loadingButton(commentBtn);
      }
      const { ticket, authentication, dispatch } = this.props;
      const { _ticketDetails } = this.state;
      if (_ticketDetails && _ticketDetails.id) {
        let ticket_id = _ticketDetails.id;
        let commentData = {
          ticket_id: ticket_id,
          comments: encodeURI(textArea),
          assigned_to: _ticketDetails.assigned_to,
          commented_by: authentication.user.user.id
        }
        dispatch(userActions.createComments(commentData)).then(() => {
          this.notify("tc", "", "Comment added successfully.");
          this.setState({ textArea: '' });
          document.querySelector('.comment-box').value = '';
          _ticketDetails.comments = _ticketDetails.comments;
          _ticketDetails.comments.push({
            commentuser: {
              name: authentication.user.user.name,
            },
            comments: commentData.comments,
            created_at: new Date().toJSON()
          });
          _ticketDetails.comments = _ticketDetails.comments;;
          this.setState({ _ticketDetails: _ticketDetails });
          if (document.querySelector('.nfu_date').value.trim() !== "") {
            loadingButton(commentBtn);
            let updateTicketData = {
              'id': ticket_id,
              'update': 'follow_up',
              'next_follow_up': document.querySelector('.nfu_date').value
            }
            dispatch(userActions.updateTicket(updateTicketData)).then(() => {
              document.querySelector('.nfu_date').value = '';
              document.querySelector('.comment-box').value = '';
              document.querySelector('#commentForm').reset();
              document.querySelector('.nfu_date').value = '';
              if (commentBtn) {
                removeLoadingButton(commentBtn, "Comment");
              }
              this.notify("tc", "", "Ticket updated successfully.");
              return this.props.history.push('/admin/tickets/list');
            });
          } else {
            document.querySelector('.nfu_date').value = '';
            document.querySelector('.comment-box').value = '';
            document.querySelector('#commentForm').reset();
            document.querySelector('.nfu_date').value = '';
            if (commentBtn) {
              setTimeout(() => {
                removeLoadingButton(commentBtn, "Comment");
              }, 200);
            }
          }
        });

      }
    } else {
      this.setState({
        _commentAlert: (<Alert color="danger" className="py-1 px-2 mb-2">
          Please Enter Comments
          <a className="text-right" className="float-right text-white" href="javascript:void(0);" onClick={(e) => this.setState({ _commentAlert: '' })}><i className="far fa-times-circle"></i></a>
        </Alert>)
      });

      setTimeout(() => {
        this.setState({ _commentAlert: '' });
      }, 3000);
    }
  }

  render() {
    const { _ticketDetails, _commentAlert } = this.state;
    const { isValidDate } = this.props;
    const commentList = [];
    if (_ticketDetails) {
      const { comments } = _ticketDetails;
      // comments = comments;;
      comments && comments.forEach((v, i) => {
        commentList.push(
          <Media className="media-comment w-100" key={i}>
            <img
              alt="..."
              className="avatar avatar-md media-comment-avatar rounded-circle"
              src={require("../../assets/img/theme/team-1.jpg")}
            />
            <Media className="w-100">
              <div className="media-comment-text w-100">
                <h6 className="h5 mt-0">{v && v.commentuser && v.commentuser.name}
                  <small className="float-right">
                    <a className="like active" href="#pablo" onClick={e => e.preventDefault()} >
                      <i className="fas fa-building" />
                      <span className="text-muted">{v && v.commentuser && v.commentuser.getdepart && v.commentuser.getdepart.deptname}</span>
                    </a>
                  </small>
                </h6>
                <p className="text-sm lh-160 comment-div">
                  {decodeURI(v.comments)}
                </p>
                <div className="icon-actions">
                  <a href="#pablo" className="w-100" onClick={e => e.preventDefault()}>
                    <i className="far fa-calendar position-relative mt-1" />
                    <small className="mr-2">
                      <span className="text-muted">{moment(v.created_at).format("Do MMMM YY")}</span>
                    </small>

                    <i className="far fa-clock position-relative mt-1" />
                    <small className="mr-2">
                      <span className="text-muted">{moment(v.created_at).format("hh:mm a")}</span>
                    </small>
                    {(v.assignedcomment && isArray(v.assignedcomment) && v.assignedcomment.length > 0) ?
                      (<img src={require('../../assets/img/theme/team-4.jpg')} alt={`${v.assignedcomment[0].name}`} title={`${v.assignedcomment[0].name}`} className="avatar avatar-xs float-right rounded-circle" />) : ''}
                  </a>
                </div>
              </div>
            </Media>
          </Media>
        );
      });
    }

    return (
      <Card>
        <NotificationAlert ref="notificationAlert" />
        <CardBody>
          <div className="col px-0">
            <Media className="align-items-center">
              <img
                alt="..."
                className="avatar avatar-lg rounded-circle mr-2"
                src={require("assets/img/theme/team-4.jpg")}
              />
              <Media body>
                <Form className="position-relative" id="commentForm">
                  {_commentAlert}
                  <Input
                    placeholder="Write your comment"
                    rows="3"
                    className="w-100 comment-box"
                    type="textarea"
                    onChange={(e) => { this.setState({ textArea: e.target.value }) }}
                    onFocus={(e) => { this.setState({ showAction: true }) }}
                    onBlur={(e) => { if (e.target.value.trim() == "") { this.setState({ showAction: true }); } }}
                  />
                </Form>
              </Media>
            </Media>
            <div className="col px-0">
              <div className={classnames({ "d-none": !this.state.showAction, "mt-2": true, "media-body": true, "d-flex": this.state.showAction, "ml-md-5": true, "pl-md-3": true, "ml-0": true, "pl-0": true })}>
                <ReactDatetime isValidDate={isValidDate} renderInput={this.renderDateInput} dateFormat={`YYYY-MM-DD`} closeOnSelect={true} className={`form-control d-flex w-50 p-0 h-auto mr-2 border-0 cc-rdt-pos-right`} inputProps={{ placeholder: 'Follow Up Date', className: `form-control form-control-sm nfu_date border-radius-25-px border-left-0 pl-0`, readOnly: true }} timeFormat={false} />
                <div className="d-inline-block text-right w-50">
                  <Button type="button" color="primary" size="sm" className="comment-btn h-100" onClick={(e) => { this.createComment(e); }}>Comment</Button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="mb-1 height-410 overflow-y-auto overflow-x-hidden comments-list-container">
            {commentList}
          </div>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { status, assigned, department, service, customer, authentication, ticket, comment, logs, documenttype } = state;
  return {
    status,
    assigned,
    department,
    service,
    customer,
    authentication,
    ticket,
    comment,
    logs,
    documenttype
  };
}

const connectedCommentSectionPage = connect(mapStateToProps)(CommentSection);
export { connectedCommentSectionPage as CommentSection };
import { userConstants } from '../_constants';

export function status(state = {}, action) {
  switch (action.type) {
    case userConstants.LIST_STATUS_REQUEST:
      return {
        status: action.status
      };
    case userConstants.LIST_STATUS_SUCCESS:
      return {
        status: action.status
      };
    case userConstants.LIST_STATUS_FAILURE:
      return {};
    default:
      return state
  }
}
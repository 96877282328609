/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
// node.js library that concatenates classes (strings)
import classnames from 'classnames';
// javascipt plugin for creating charts
import { Chart } from 'chart.js';
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
  CardImg,
} from 'reactstrap';

// core components
import CardsHeader from '../../../components/Headers/CardsHeaderFunc.jsx';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from '../../../variables/charts.jsx';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import styles from '../../../_views/_enquiry/enquiry.module.css';
import openImage from '../../../assets/fonts/open.png';
import closedImage from '../../../assets/fonts/closedL.png';
import completedImage from '../../../assets/fonts/completed.png';
import cancelImage from '../../../assets/fonts/deleteL.png';
import holdImage from '../../../assets/fonts/pending.png';
import cardStyles from './assigneeDashboard.module.css';
// import ReactCardCarousel from 'react-card-carousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function CONTAINER_STYLE() {
  return {
    position: 'relative',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'middle',
  };
}
let CARD_STYLE=  {
    height: '200px',
    width: '200px',
    paddingTop: '80px',
    textAlign: 'center',
    background: '#52C0F5',
    color: '#FFF',
    fontFamily: 'sans-serif',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '10px',
    boxSizing: 'border-box',
  }
// function CARD_STYLE() {
//   return {
//     height: '200px',
//     width: '200px',
//     paddingTop: '80px',
//     textAlign: 'center',
//     background: '#52C0F5',
//     color: '#FFF',
//     fontFamily: 'sans-serif',
//     fontSize: '12px',
//     textTransform: 'uppercase',
//     borderRadius: '10px',
//     boxSizing: 'border-box',
//   };
// }

function Dashboard() {
  let history = useHistory();
  let [monthCount, setMonthCount] = useState([]);

  let [loading, setLoading] = useState(false);
  let [usersList, setUsersList] = useState([]);

  let [visaOpen, setVisaOpen] = useState('');
  let [passportOpen, setPassportOpen] = useState('');
  let [taxiOpen, setTaxiOpen] = useState('');
  let [tourismOpen, setTourismOpen] = useState('');
  let [vehicleOpen, setVehicleOpen] = useState('');
  let [othersOpen, setOthersOpen] = useState('');
  let [visaInProgress, setVisaInProgress] = useState('');
  let [passportInProgress, setPassportInProgress] = useState('');
  let [taxiInProgress, setTaxiInProgress] = useState('');
  let [tourismInProgress, setTourismInProgress] = useState('');
  let [vehicleInProgress, setVehicleInProgress] = useState('');
  let [othersInProgress, setOthersInProgress] = useState('');
  let [visaHold, setVisaHold] = useState('');
  let [passportHold, setPassportHold] = useState('');
  let [taxiHold, setTaxiHold] = useState('');
  let [tourismHold, setTourismHold] = useState('');
  let [vehicleHold, setVehicleHold] = useState('');
  let [othersHold, setOthersHold] = useState('');
  let [visaClosed, setVisaClosed] = useState('');
  let [passportClosed, setPassportClosed] = useState('');
  let [taxiClosed, setTaxiClosed] = useState('');
  let [tourismClosed, setTourismClosed] = useState('');
  let [vehicleClosed, setVehicleClosed] = useState('');
  let [othersClosed, setOthersClosed] = useState('');
  let [visaCancel, setVisaCancel] = useState('');
  let [passportCancel, setPassportCancel] = useState('');
  let [taxiCancel, setTaxiCancel] = useState('');
  let [tourismCancel, setTourismCancel] = useState('');
  let [vehicleCancel, setVehicleCancel] = useState('');
  let [othersCancel, setOthersCancel] = useState('');
  let [visaCompleted, setVisaCompleted] = useState('');
  let [passportCompleted, setPassportCompleted] = useState('');
  let [taxiCompleted, setTaxiCompleted] = useState('');
  let [tourismCompleted, setTourismCompleted] = useState('');
  let [vehicleCompleted, setVehicleCompleted] = useState('');
  let [othersCompleted, setOthersCompleted] = useState('');
  let [month, setMonth] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]);
  let [dataRecords, setDataRecords] = useState([]);


  let getEnquiryStatusCount = () => {
    let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/getAll-enquiry-status-count`;
    axios
      .get(dataURL)
      .then((res) => {
        console.log('HEARDERcARD_COUNT', res.data.res1);
        res.data.res1.map((dat) => {
          if(dat.code == 'D001'){
            setVisaOpen(dat.open);
            setVisaInProgress(dat.in_progress);
            setVisaHold(dat.hold);
            setVisaClosed(dat.closed);
            setVisaCancel(dat.cancel);
            setVisaCompleted(dat.completed);
          }
          if (dat.code == 'D002') {
            setPassportOpen(dat.open);
            setPassportInProgress(dat.in_progress);
            setPassportHold(dat.hold);
            setPassportClosed(dat.closed);
            setPassportCancel(dat.cancel);
            setPassportCompleted(dat.completed);
          }
          if (dat.code == 'D003') {
            setTaxiOpen(dat.open);
            setTaxiInProgress(dat.in_progress);
            setTaxiHold(dat.hold);
            setTaxiClosed(dat.closed);
            setTaxiCancel(dat.cancel);
            setTaxiCompleted(dat.completed);
          }
          if (dat.code == 'D004') {
            setTourismOpen(dat.open);
            setTourismInProgress(dat.in_progress);
            setTourismHold(dat.hold);
            setTourismClosed(dat.closed);
            setTourismCancel(dat.cancel);
            setTourismCompleted(dat.completed);
          }
          if (dat.code == 'D005') {
            setVehicleOpen(dat.open);
            setVehicleInProgress(dat.in_progress);
            setVehicleHold(dat.hold);
            setVehicleClosed(dat.closed);
            setVehicleCancel(dat.cancel);
            setVehicleCompleted(dat.completed);
          }
          if (dat.code == 'D006') {
            setOthersOpen(dat.open);
            setOthersInProgress(dat.in_progress);
            setOthersHold(dat.hold);
            setOthersClosed(dat.closed);
            setOthersCancel(dat.cancel);
            setOthersCompleted(dat.completed);
          }
        })
        // setOpen(res.data.res1[0].open);
        // setInProgress(res.data.res1[0].in_progress);
        // setHold(res.data.res1[0].hold);
        // setClosed(res.data.res1[0].closed);
        // let monthCount = []
        // res.data.res2.map((monData) => {
        //   console.log('mondata_mondata', monData);
        //   month.map((m) => {
        //    if(m == monData.EnquiryCreatedDate){
        //     console.log(monData.EnquiryCreatedDate, 'ifMOnthecont');
        //     monthCount.push(monData.EnquiryCount)
        //    }else{

        //       monthCount.push('0');
        //     console.log('elseMonthCount');
        //    }

        //   })

        // })
        month.map((m) => {
          console.log(m, 'monthttt');
          let flag = 0;
          res.data.res2.map((monData) => {
            if (m == monData.EnquiryCreatedDate) {
              flag = 1;
              console.log(monData.EnquiryCreatedDate, 'ifMOnthecont');
              monthCount.push(monData.EnquiryCount);
            }
          });
          if (flag == 0) monthCount.push('0');
        });
        console.log('array_moncount', monthCount);
      })
      .catch((err) => {
        console.log('_hearcount_eror', err);
      });
  };

  useEffect(() => {
    getEnquiryStatusCount();
  }, []);

  //get the list of users
  let getAllUsersList = () => {
    setLoading(true);
    let dataURL = `${process.env.REACT_APP_BACKEND_URL}api/get-users-list-dashboard`;
    axios
      .get(dataURL)
      .then((res) => {
        console.log('ENQUIRYLISTING_GETTING_VALUE', res.data.res1);
        setUsersList(res.data.res1);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('ENQUIRYLISTING_GETTING_ERROR', error);
      });
  };

  useEffect(() => {
    getAllUsersList();
  }, []);

  // Colors
  var colors = {
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529',
    },
    theme: {
      default: '#172b4d',
      primary: '#5e72e4',
      secondary: '#f4f5f7',
      info: '#11cdef',
      success: '#2dce89',
      danger: '#f5365c',
      warning: '#fb6340',
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
  };

  // Example 1 of Chart inside src/views/dashboards/Dashboard.jsx
  let chartExample1 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[700],
              zeroLineColor: colors.gray[700],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || '';
            var yLabel = item.yLabel;
            var content = '';

            if (data.datasets.length > 1) {
              content += label;
            }

            content +=  yLabel + ' tickets';
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Performance',
            data: monthCount,
          },
        ],
      };
    },
    data2: (canvas) => {
      return {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Performance',
            data: monthCount,
          },
        ],
      };
    },
  };

  // Methods
  var mode = 'light';

  var fonts = {
    base: 'Open Sans',
  };
  // Chart.js global options
  function chartOptions() {
    // Options
    var options = {
      defaults: {
        global: {
          responsive: true,
          maintainAspectRatio: false,
          defaultColor: mode === 'dark' ? colors.gray[700] : colors.gray[600],
          defaultFontColor:
            mode === 'dark' ? colors.gray[700] : colors.gray[600],
          defaultFontFamily: fonts.base,
          defaultFontSize: 13,
          layout: {
            padding: 0,
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 16,
            },
          },
          elements: {
            point: {
              radius: 0,
              backgroundColor: colors.theme['primary'],
            },
            line: {
              tension: 0.4,
              borderWidth: 4,
              borderColor: colors.theme['primary'],
              backgroundColor: colors.transparent,
              borderCapStyle: 'rounded',
            },
            rectangle: {
              backgroundColor: colors.theme['warning'],
            },
            arc: {
              backgroundColor: colors.theme['primary'],
              borderColor: mode === 'dark' ? colors.gray[800] : colors.white,
              borderWidth: 4,
            },
          },
          tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
          },
        },
        doughnut: {
          cutoutPercentage: 83,
          legendCallback: function (chart) {
            var data = chart.data;
            var content = '';

            data.labels.forEach(function (label, index) {
              var bgColor = data.datasets[0].backgroundColor[index];

              content += '<span class="chart-legend-item">';
              content +=
                '<i class="chart-legend-indicator" style="background-color: ' +
                bgColor +
                '"></i>';
              content += label;
              content += '</span>';
            });

            return content;
          },
        },
      },
    };

    // yAxes
    Chart.scaleService.updateScaleDefaults('linear', {
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        color: mode === 'dark' ? colors.gray[900] : colors.gray[300],
        drawBorder: false,
        drawTicks: false,
        lineWidth: 1,
        zeroLineWidth: 1,
        zeroLineColor: mode === 'dark' ? colors.gray[900] : colors.gray[300],
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
      },
      ticks: {
        beginAtZero: true,
        padding: 10,
        callback: function (value) {
          if (!(value % 5)) {
            return value;
          }
        },
      },
    });

    // xAxes
    Chart.scaleService.updateScaleDefaults('category', {
      gridLines: {
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      ticks: {
        padding: 20,
      },
      maxBarThickness: 10,
    });

    return options;
  }

  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState('data1');
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === 'data1' ? 'data2' : 'data1');
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  let imageUrl = `${process.env.REACT_APP_BACKEND_URL}Images/`;

  
              let [localUser, setLocalUser] = useState('');
              // let localUser;
              useEffect(() => {
                let user = localStorage.getItem('user');
                let parseUser = JSON.parse(user);
                setLocalUser(parseUser.role);
              }, []);

  

  return (
    <>
      <style>
        {
          '\
          .card-profile-stats {\
            padding-top: 0px !important;\
            padding-bottom: 0px !important;\
          }'
        }
        {
          '\
          .card-body {\
            padding: 10px !important;\
          }'
        }
        {
          '\
          .react-multiple-carousel__arrow::before {\
            font-size: 13px !important;\
          }'
        }
        {
          '\
          .react-multiple-carousel__arrow::after {\
            font-size: 13px !important;\
          }'
        }
        {
          '\
          .react-multiple-carousel__arrow {\
            min-width: 28px !important;\
            min-height: 28px !important;\
          }'
        }
      </style>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {/* <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{' '} */}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    <i className="fas fa-home text-white" />
                    {/* </a> */}
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    <span className="text-white">Dashboards</span>
                    {/* </a> */}
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem> */}
                </Breadcrumb>
              </Col>
              {/* <Col className="text-right" lg="6" xs="5">
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  New
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Filters
                </Button>
              </Col> */}
            </Row>

            <Row className={cardStyles.hideCardSection}>
              <Col sm="12" md="6" xl="4">
                <Card style={{ padding: '20px 0px' }} className="card-stats">
                  <CardBody>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ width: '100%' }}>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Open
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/open.png')}
                              alt=""
                            />
                          </div>
                        </Col>
                      </div>
                      {/* <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap',
                          padding: '5px',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '13px',
                            // background: '#ffff0057',
                            borderRadius: '50px',
                            padding: '5px',
                            margin: '5px',
                          }}
                          className="font-weight-bold mb-0"
                        >
                          Visa : {visaOpen}
                        </p>
                        <p
                          style={{
                            fontSize: '13px',
                            // background: '#ff000078',
                            borderRadius: '50px',
                            padding: '5px',
                            margin: '5px',
                          }}
                          className="font-weight-bold mb-0"
                        >
                          Passport : {passportOpen}
                        </p>
                        <p
                          style={{
                            fontSize: '13px',
                            // background: '#ffff0057',
                            borderRadius: '50px',
                            padding: '5px',
                            margin: '5px',
                          }}
                          className="font-weight-bold mb-0"
                        >
                          Taxi : {taxiOpen}
                        </p>
                        <p
                          style={{
                            fontSize: '13px',
                            // background: '#00800078',
                            borderRadius: '50px',
                            padding: '5px',
                            margin: '5px',
                          }}
                          className="font-weight-bold mb-0"
                        >
                          Tourism : {tourismOpen}
                        </p>
                        <p
                          style={{
                            fontSize: '13px',
                            // background: '#ffa50085',
                            borderRadius: '50px',
                            padding: '5px',
                            margin: '5px',
                          }}
                          className="font-weight-bold mb-0"
                        >
                          Vehicle : {vehicleOpen}
                        </p>
                        <p
                          style={{
                            fontSize: '13px',
                            // background: '#ee82ee80',
                            borderRadius: '50px',
                            padding: '5px',
                            margin: '5px',
                          }}
                          className="font-weight-bold mb-0"
                        >
                          Others : {othersOpen}
                        </p>
                      </div> */}
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{visaOpen}</span>
                            <span className="description">Visa</span>
                          </div>
                          <div>
                            <span className="heading">{passportOpen}</span>
                            <span className="description">Passport</span>
                          </div>
                          <div>
                            <span className="heading">{taxiOpen}</span>
                            <span className="description">Taxi</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{tourismOpen}</span>
                            <span className="description">Tourism</span>
                          </div>
                          <div>
                            <span className="heading">{vehicleOpen}</span>
                            <span className="description">Vehicle</span>
                          </div>
                          <div>
                            <span className="heading">{othersOpen}</span>
                            <span className="description">Others</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => {
                          history.push({
                            pathname:
                              window.screen.width < 768
                                ? '/admin/enquiry-res-table'
                                : '/admin/enquiry',
                            state: { tStatus: 1 },
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className="btn btn-warning btn-sm"
                      >
                        View
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" md="6" xl="4">
                <Card style={{ padding: '20px 0px' }} className="card-stats">
                  <CardBody>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Progress
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-chart-pie-35" />
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{visaInProgress}</span>
                            <span className="description">Visa</span>
                          </div>
                          <div>
                            <span className="heading">
                              {passportInProgress}
                            </span>
                            <span className="description">Passport</span>
                          </div>
                          <div>
                            <span className="heading">{taxiInProgress}</span>
                            <span className="description">Taxi</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{tourismInProgress}</span>
                            <span className="description">Tourism</span>
                          </div>
                          <div>
                            <span className="heading">{vehicleInProgress}</span>
                            <span className="description">Vehicle</span>
                          </div>
                          <div>
                            <span className="heading">{othersInProgress}</span>
                            <span className="description">Others</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => {
                          history.push({
                            pathname:
                              window.screen.width < 768
                                ? '/admin/enquiry-res-table'
                                : '/admin/enquiry',
                            state: { tStatus: 2 },
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className="btn btn-success btn-sm"
                      >
                        View
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" md="6" xl="4">
                <Card style={{ padding: '20px 0px' }} className="card-stats">
                  <CardBody>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Hold
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            {/* <i className="ni ni-chart-pie-35" /> */}
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/pending.png')}
                              alt=""
                            />
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{visaHold}</span>
                            <span className="description">Visa</span>
                          </div>
                          <div>
                            <span className="heading">{passportHold}</span>
                            <span className="description">Passport</span>
                          </div>
                          <div>
                            <span className="heading">{taxiHold}</span>
                            <span className="description">Taxi</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{tourismHold}</span>
                            <span className="description">Tourism</span>
                          </div>
                          <div>
                            <span className="heading">{vehicleHold}</span>
                            <span className="description">Vehicle</span>
                          </div>
                          <div>
                            <span className="heading">{othersHold}</span>
                            <span className="description">Others</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => {
                          history.push({
                            pathname:
                              window.screen.width < 768
                                ? '/admin/enquiry-res-table'
                                : '/admin/enquiry',
                            state: { tStatus: 4 },
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className="btn btn-warning btn-sm"
                      >
                        View
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" md="6" xl="4">
                <Card style={{ padding: '20px 0px' }} className="card-stats">
                  <CardBody>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Closed
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            {/* <i className="fa fa-calendar-check-o" /> */}
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/closedL.png')}
                              alt=""
                            />
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{visaClosed}</span>
                            <span className="description">Visa</span>
                          </div>
                          <div>
                            <span className="heading">{passportClosed}</span>
                            <span className="description">Passport</span>
                          </div>
                          <div>
                            <span className="heading">{taxiClosed}</span>
                            <span className="description">Taxi</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{tourismClosed}</span>
                            <span className="description">Tourism</span>
                          </div>
                          <div>
                            <span className="heading">{vehicleClosed}</span>
                            <span className="description">Vehicle</span>
                          </div>
                          <div>
                            <span className="heading">{othersClosed}</span>
                            <span className="description">Others</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => {
                          history.push({
                            pathname:
                              window.screen.width < 768
                                ? '/admin/enquiry-res-table'
                                : '/admin/enquiry',
                            state: { tStatus: 3 },
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className="btn btn-primary btn-sm"
                      >
                        View
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" md="6" xl="4">
                <Card style={{ padding: '20px 0px' }} className="card-stats">
                  <CardBody>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Completed
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            {/* <i className="fa fa-calendar-check-o" /> */}
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/completed.png')}
                              alt=""
                            />
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{visaCompleted}</span>
                            <span className="description">Visa</span>
                          </div>
                          <div>
                            <span className="heading">{passportCompleted}</span>
                            <span className="description">Passport</span>
                          </div>
                          <div>
                            <span className="heading">{taxiCompleted}</span>
                            <span className="description">Taxi</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{tourismCompleted}</span>
                            <span className="description">Tourism</span>
                          </div>
                          <div>
                            <span className="heading">{vehicleCompleted}</span>
                            <span className="description">Vehicle</span>
                          </div>
                          <div>
                            <span className="heading">{othersCompleted}</span>
                            <span className="description">Others</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => {
                          history.push({
                            pathname:
                              window.screen.width < 768
                                ? '/admin/enquiry-res-table'
                                : '/admin/enquiry',
                            state: { tStatus: 6 },
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className="btn btn-success btn-sm"
                      >
                        View
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" md="6" xl="4">
                <Card style={{ padding: '20px 0px' }} className="card-stats">
                  <CardBody>
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Cancel
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                            {/* <i className="fa fa-calendar-check-o" /> */}
                            <img
                              style={{ width: '34px' }}
                              src={require('../../../assets/fonts/deleteL.png')}
                              alt=""
                            />
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{visaCancel}</span>
                            <span className="description">Visa</span>
                          </div>
                          <div>
                            <span className="heading">{passportCancel}</span>
                            <span className="description">Passport</span>
                          </div>
                          <div>
                            <span className="heading">{taxiCancel}</span>
                            <span className="description">Taxi</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            <span className="heading">{tourismCancel}</span>
                            <span className="description">Tourism</span>
                          </div>
                          <div>
                            <span className="heading">{vehicleCancel}</span>
                            <span className="description">Vehicle</span>
                          </div>
                          <div>
                            <span className="heading">{othersCancel}</span>
                            <span className="description">Others</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => {
                          history.push({
                            pathname:
                              window.screen.width < 768
                                ? '/admin/enquiry-res-table'
                                : '/admin/enquiry',
                            state: { tStatus: 5 },
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className="btn btn-danger btn-sm"
                      >
                        View
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row
              style={{ height: '340px' }}
              className={cardStyles.hideMobCardSection}
            >
              <div
              //  style={{CONTAINER_STYLE}}
              >
                <Carousel
                  // removeArrowOnDeviceType={['tablet', 'mobile']}
                  responsive={responsive}
                >
                  <div>
                    <Card
                      style={{ padding: '0px 0px', margin: '5px' }}
                      className="card-stats"
                    >
                      <CardBody>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Open
                              </CardTitle>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                <img
                                  style={{ width: '34px' }}
                                  src={require('../../../assets/fonts/open.png')}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{visaOpen}</span>
                                <span className="description">Visa</span>
                              </div>
                              <div>
                                <span className="heading">{passportOpen}</span>
                                <span className="description">Passport</span>
                              </div>
                              <div>
                                <span className="heading">{taxiOpen}</span>
                                <span className="description">Taxi</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{tourismOpen}</span>
                                <span className="description">Tourism</span>
                              </div>
                              <div>
                                <span className="heading">{vehicleOpen}</span>
                                <span className="description">Vehicle</span>
                              </div>
                              <div>
                                <span className="heading">{othersOpen}</span>
                                <span className="description">Others</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <div style={{ float: 'right' }}>
                          <button
                            onClick={() => {
                              history.push({
                                pathname:
                                  window.screen.width < 768
                                    ? '/admin/enquiry-res-table'
                                    : '/admin/enquiry',
                                state: { tStatus: 1 },
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                            className="btn btn-warning btn-sm"
                          >
                            View
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card
                      style={{ padding: '0px 0px', margin: '5px' }}
                      className="card-stats"
                    >
                      <CardBody>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Progress
                              </CardTitle>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="ni ni-chart-pie-35" />
                              </div>
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">
                                  {visaInProgress}
                                </span>
                                <span className="description">Visa</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {passportInProgress}
                                </span>
                                <span className="description">Passport</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {taxiInProgress}
                                </span>
                                <span className="description">Taxi</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">
                                  {tourismInProgress}
                                </span>
                                <span className="description">Tourism</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {vehicleInProgress}
                                </span>
                                <span className="description">Vehicle</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {othersInProgress}
                                </span>
                                <span className="description">Others</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <div style={{ float: 'right' }}>
                          <button
                            onClick={() => {
                              history.push({
                                pathname:
                                  window.screen.width < 768
                                    ? '/admin/enquiry-res-table'
                                    : '/admin/enquiry',
                                state: { tStatus: 2 },
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                            className="btn btn-success btn-sm"
                          >
                            View
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card
                      style={{ padding: '0px 0px', margin: '5px' }}
                      className="card-stats"
                    >
                      <CardBody>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Hold
                              </CardTitle>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                {/* <i className="ni ni-chart-pie-35" /> */}
                                <img
                                  style={{ width: '34px' }}
                                  src={require('../../../assets/fonts/pending.png')}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{visaHold}</span>
                                <span className="description">Visa</span>
                              </div>
                              <div>
                                <span className="heading">{passportHold}</span>
                                <span className="description">Passport</span>
                              </div>
                              <div>
                                <span className="heading">{taxiHold}</span>
                                <span className="description">Taxi</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{tourismHold}</span>
                                <span className="description">Tourism</span>
                              </div>
                              <div>
                                <span className="heading">{vehicleHold}</span>
                                <span className="description">Vehicle</span>
                              </div>
                              <div>
                                <span className="heading">{othersHold}</span>
                                <span className="description">Others</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <div style={{ float: 'right' }}>
                          <button
                            onClick={() => {
                              history.push({
                                pathname:
                                  window.screen.width < 768
                                    ? '/admin/enquiry-res-table'
                                    : '/admin/enquiry',
                                state: { tStatus: 4 },
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                            className="btn btn-warning btn-sm"
                          >
                            View
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card
                      style={{ padding: '0px 0px', margin: '5px' }}
                      className="card-stats"
                    >
                      <CardBody>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Closed
                              </CardTitle>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                {/* <i className="fa fa-calendar-check-o" /> */}
                                <img
                                  style={{ width: '34px' }}
                                  src={require('../../../assets/fonts/closedL.png')}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{visaClosed}</span>
                                <span className="description">Visa</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {passportClosed}
                                </span>
                                <span className="description">Passport</span>
                              </div>
                              <div>
                                <span className="heading">{taxiClosed}</span>
                                <span className="description">Taxi</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{tourismClosed}</span>
                                <span className="description">Tourism</span>
                              </div>
                              <div>
                                <span className="heading">{vehicleClosed}</span>
                                <span className="description">Vehicle</span>
                              </div>
                              <div>
                                <span className="heading">{othersClosed}</span>
                                <span className="description">Others</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <div style={{ float: 'right' }}>
                          <button
                            onClick={() => {
                              history.push({
                                pathname:
                                  window.screen.width < 768
                                    ? '/admin/enquiry-res-table'
                                    : '/admin/enquiry',
                                state: { tStatus: 3 },
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                            className="btn btn-primary btn-sm"
                          >
                            View
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card
                      style={{ padding: '0px 0px', margin: '5px' }}
                      className="card-stats"
                    >
                      <CardBody>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Completed
                              </CardTitle>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                {/* <i className="fa fa-calendar-check-o" /> */}
                                <img
                                  style={{ width: '34px' }}
                                  src={require('../../../assets/fonts/completed.png')}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{visaCompleted}</span>
                                <span className="description">Visa</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {passportCompleted}
                                </span>
                                <span className="description">Passport</span>
                              </div>
                              <div>
                                <span className="heading">{taxiCompleted}</span>
                                <span className="description">Taxi</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">
                                  {tourismCompleted}
                                </span>
                                <span className="description">Tourism</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {vehicleCompleted}
                                </span>
                                <span className="description">Vehicle</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {othersCompleted}
                                </span>
                                <span className="description">Others</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <div style={{ float: 'right' }}>
                          <button
                            onClick={() => {
                              history.push({
                                pathname:
                                  window.screen.width < 768
                                    ? '/admin/enquiry-res-table'
                                    : '/admin/enquiry',
                                state: { tStatus: 6 },
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                            className="btn btn-success btn-sm"
                          >
                            View
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div>
                    <Card
                      style={{ padding: '0px 0px', margin: '5px' }}
                      className="card-stats"
                    >
                      <CardBody>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Cancel
                              </CardTitle>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                {/* <i className="fa fa-calendar-check-o" /> */}
                                <img
                                  style={{ width: '34px' }}
                                  src={require('../../../assets/fonts/deleteL.png')}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{visaCancel}</span>
                                <span className="description">Visa</span>
                              </div>
                              <div>
                                <span className="heading">
                                  {passportCancel}
                                </span>
                                <span className="description">Passport</span>
                              </div>
                              <div>
                                <span className="heading">{taxiCancel}</span>
                                <span className="description">Taxi</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span className="heading">{tourismCancel}</span>
                                <span className="description">Tourism</span>
                              </div>
                              <div>
                                <span className="heading">{vehicleCancel}</span>
                                <span className="description">Vehicle</span>
                              </div>
                              <div>
                                <span className="heading">{othersCancel}</span>
                                <span className="description">Others</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <div style={{ float: 'right' }}>
                          <button
                            onClick={() => {
                              history.push({
                                pathname:
                                  window.screen.width < 768
                                    ? '/admin/enquiry-res-table'
                                    : '/admin/enquiry',
                                state: { tStatus: 5 },
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                            className="btn btn-danger btn-sm"
                          >
                            View
                          </button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Carousel>
              </div>
            </Row>
          </div>
        </Container>
      </div>
      <Container style={{ marginBottom: '40px' }} className="mt--6" fluid>
        <Row>
          <Col xl="8">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 text-white mb-0">Sales value</h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      {/* <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames('py-2 px-3', {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames('py-2 px-3', {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card style={{ height: '483px', overflowY: 'scroll' }}>
              <CardHeader>
                <h5 className="h3 mb-0">Team members</h5>
              </CardHeader>

              <CardBody style={{ padding: '0px' }}>
                <ListGroup className="list my--3" flush>
                  {usersList.map((user) => (
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            {/* <img
                              alt="..."
                              src={require(`${imageUrl}${user.profilephoto.substr(
                                7
                              )}`)}
                            /> */}
                            <div
                              // ref={tooltipRef}
                              id={user.id}
                              className={styles.companyHeaderAvatar}
                              // onClick={() => {
                              //   setSelectShow(true);
                              //   setMenuOpen(!menuOpen);
                              // }}
                              style={{
                                border: '0.5 solid',
                                backgroundImage: `url(${imageUrl}${user.profilephoto.substr(
                                  7
                                )})`,
                              }}
                            ></div>
                          </a>
                        </Col>
                        <div
                          style={{ paddingRight: '0px' }}
                          className="col ml--2"
                        >
                          <h4 className="mb-0">
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              {user.firstname} {user.lastname}
                            </a>
                          </h4>
                          <span className="text-success">●</span>{' '}
                          <small>{user.role}</small>
                        </div>
                        <Col className="col-auto">
                          <Link
                            to={`/admin/users-edit/${user.id}`}
                            className="btn btn-primary btn-sm"
                          >
                            Edit
                          </Link>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;

import React, { Component } from "react";
import { connect } from 'react-redux';
import { globalConstants } from "../../_constants";
import { Pie } from "react-chartjs-2";
import { userActions } from "../../_actions";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from 'moment';

// Colors
var colors = {
    theme: {
        default: "#172b4d",
        primary: "#5e72e4",
        secondary: "#f4f5f7",
        info: "#11cdef",
        success: "#2dce89",
        danger: "#f5365c",
        warning: "#fb6340"
    }
};

class TicketGraph extends Component {
    state = { loading: true, _chartData: {}, _departmentOptions: [], _select_department: '' };

    // componentDidMount() {
    //     const { dispatch, authentication } = this.props;
    //     const { user } = authentication;
    //     const { id } = user && user.user;
    //     dispatch(userActions.listDepartment()).then(async () => {
    //         const { department } = this.props;
    //         const { data } = department && department.department;
    //         let departmentOptions = [];
    //         department && department.department.forEach((v, i) => { departmentOptions.push({ label: v.code.trim(), value: v.id }); });
    //         await this.setState({ _departmentOptions: departmentOptions });
    //     });
    //     dispatch(userActions.getTicketStatus('', '', '', '', id)).then(() => {
    //         const { ticketStatus } = this.props;
    //         console.log(ticketStatus, 'ticketStatusticketStatus')
    //         const { status } = ticketStatus && ticketStatus.ticketStatus || { status: [] };
    //         console.log(ticketStatus, 'ticketStatus')
    //         let chartData = {};
    //         chartData.data = {};
    //         chartData.data.labels = [];
    //         chartData.data.datasets = [];
    //         chartData.data.datasets[0] = {};
    //         chartData.data.datasets[0].data = [];
    //         chartData.data.datasets[0].backgroundColor = [];
    //         chartData.data.datasets[0].label = "Ticket's Graph";
    //         chartData.options = { responsive: true, legend: { position: "bottom", display: true, fullWidth: true, }, animation: { animateScale: false, animateRotate: true }, maintainAspectRatio: true };
    //         ticketStatus && ticketStatus.ticketStatus.data.list.forEach((v, i) => {
    //             let tempName = (v.name.trim().toLowerCase() === "work in progress") ? "WIP" : v.name;
    //             // let tempName="WIP";
    //             let tempColorName = globalConstants.TICKETS.COLORS[tempName.trim().toLowerCase().replace(/ /g, '_')];
    //             chartData.data.labels.push(tempName);
    //             chartData.data.datasets[0].data.push(v.total);
    //             chartData.data.datasets[0].backgroundColor.push(colors.theme[tempColorName]);
    //             console.log(chartData, 'chartData')
    //         });
    //         this.setState({ _chartData: chartData, loading: false });
    //     });
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const { _filter, _select_department, startDate, endDate } = this.state;
    //     const { dispatch, authentication } = this.props;
    //     const { user } = authentication;
    //     const { id } = user && user.user;
    //     if (_filter && prevState._filter != _filter) {
    //         dispatch(userActions.getTicketStatus((_select_department && _select_department.value) ? _select_department.value : '', '', (startDate) ? startDate : '', (endDate) ? endDate : '', id)).then(() => {
    //             const { ticketStatus } = this.props;
    //             const { status } = ticketStatus && ticketStatus.ticketStatus || { status: [] };

    //             let chartData = {};
    //             chartData.data = {};
    //             chartData.data.labels = [];
    //             chartData.data.datasets = [];
    //             chartData.data.datasets[0] = {};
    //             chartData.data.datasets[0].data = [];
    //             chartData.data.datasets[0].backgroundColor = [];
    //             chartData.data.datasets[0].label = "Ticket's Graph";
    //             chartData.options = { responsive: true, legend: { position: "bottom", display: true, fullWidth: true, }, animation: { animateScale: false, animateRotate: true }, maintainAspectRatio: true };
    //             ticketStatus && ticketStatus.ticketStatus.data.list.forEach((v, i) => {
    //                 let tempName = (v.name.trim().toLowerCase() === "work in progress") ? "WIP" : v.name;
    //                 chartData.data.labels.push(tempName);
    //                 chartData.data.datasets[0].data.push(v.total);
    //                 chartData.data.datasets[0].backgroundColor.push(colors.theme[globalConstants.TICKETS.COLORS[v.name.trim().toLowerCase().replace(/ /g, '_')]]);
    //             });
    //             this.setState({ _chartData: chartData, _filter: !_filter });
    //         });
    //     }
    // }

    onSelectChange(value, reference) {
        let states = this.state;
        states[`_${reference}`] = value;
        this.setState({ states, _filter: true });
    }

    renderDateInput(props, openCalendar, closeCalendar) {
        return (
            //   <div className="input-group">
            //     <div className="input-group-prepend">
            //       <span className="input-group-text py-0 px-2"><i className="far fa-calendar"></i></span>
            //     </div>
            <input {...props} />
            //   </div>
        );
    }

    validEndData = (current) => {
        const { startDate } = this.state;
        if (!startDate)
            return true;
        var today = moment(startDate);
        return current.isAfter(today);
    }

    validStartData = (current) => {
        const { endDate } = this.state;
        if (!endDate)
            return true;
        var today = moment(endDate);
        return current.isBefore(today);
    }

    reset(e) {
        const { _select_department } = this.state;
        if (_select_department && _select_department.value && this.refs && this.refs['select_department']) {
            this.refs['select_department'].select.clearValue();
        }
        this.setState({ endDate: '', startDate: '', _select_department: '', _filter: true });
    }

    render() {
        const { _chartData } = this.state;
        if (!_chartData || !_chartData.data)
            return null;

        return (
            <>
                <div className="row mb-1 d-flex justify-content-center">
                    <div className="col-md-3 mb-2 px-1">
                        <ReactDatetime
                            inputProps={{
                                placeholder: "Start Date",
                                className: "form-control form-control-sm", readOnly: true
                            }}
                            closeOnSelect={true}
                            isValidDate={this.validStartData}
                            renderInput={this.renderDateInput}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                if (
                                    this.state.startDate &&
                                    this.state.endDate &&
                                    moment(this.state.startDate)._d + "" === currentDate._d + ""
                                ) {
                                    classes += " start-date";
                                } else if (
                                    this.state.startDate &&
                                    this.state.endDate &&
                                    new Date(moment(this.state.startDate)._d + "") <
                                    new Date(currentDate._d + "") &&
                                    new Date(moment(this.state.endDate)._d + "") >
                                    new Date(currentDate._d + "")
                                ) {
                                    classes += " middle-date";
                                } else if (
                                    this.state.endDate &&
                                    moment(this.state.endDate)._d + "" === currentDate._d + ""
                                ) {
                                    classes += " end-date";
                                }
                                return (
                                    <td {...props} className={classes}>
                                        {currentDate.date()}
                                    </td>
                                );
                            }}
                            value={this.state.startDate}
                            onChange={e => { this.setState({ startDate: moment(e).format('YYYY-MM-DD'), _filter: true }); }}
                        />
                    </div>
                    <div className="col-md-3 mb-2 px-1">
                        <ReactDatetime
                            inputProps={{
                                placeholder: "End Date",
                                className: "form-control form-control-sm", readOnly: true
                            }}
                            isValidDate={this.validEndData}
                            closeOnSelect={true}
                            renderInput={this.renderDateInput}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                if (
                                    this.state.startDate &&
                                    this.state.endDate &&
                                    moment(this.state.startDate)._d + "" === currentDate._d + ""
                                ) {
                                    classes += " start-date";
                                } else if (
                                    this.state.startDate &&
                                    this.state.endDate &&
                                    new Date(moment(this.state.startDate)._d + "") <
                                    new Date(currentDate._d + "") &&
                                    new Date(moment(this.state.endDate)._d + "") >
                                    new Date(currentDate._d + "")
                                ) {
                                    classes += " middle-date";
                                } else if (
                                    this.state.endDate &&
                                    moment(this.state.endDate)._d + "" === currentDate._d + ""
                                ) {
                                    classes += " end-date";
                                }
                                return (
                                    <td {...props} className={classes}>
                                        {currentDate.date()}
                                    </td>
                                );
                            }}
                            value={this.state.endDate}
                            onChange={e => { this.setState({ endDate: moment(e).format('YYYY-MM-DD'), _filter: true }); }}
                        />
                    </div>
                    {/* <div className="col-md-6 mb-2 px-1">
                        <span className="d-flex align-items-center">
                            <span className="w-100">
                                <Select ref={`select_department`} id="department" className={`form-control form-control-sm p-0 select_department`} isClearable placeholder="Select Dept." isSearchable isMulti={false} name="department" options={this.state._departmentOptions} value={this.setState._select_department} onChange={(value) => { this.onSelectChange(value, 'select_department') }} />
                            </span>
                            <span className="ml-1">
                                <button className="btn btn-sm btn-primary btn-reset" onClick={(e) => this.reset(e)}>Reset</button>
                            </span>
                        </span>
                    </div> */}
                </div>
                <div className="chart">
                    <Pie data={_chartData.data} options={_chartData.options} className="chart-canvas" width={100} height={100} id="chart-pie" />
                </div>
            </>
        )
    }
}

// export default Autocomplete;

function mapStateToProps(state) {
    const { ticketStatus, department, authentication } = state;
    return { ticketStatus, department, authentication };
}

const connectedTicketGraphPage = connect(mapStateToProps)(TicketGraph);
export { connectedTicketGraphPage as TicketGraph };
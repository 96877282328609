import { userConstants } from '../_constants';

export function department(state = {}, action) {
  switch (action.type) {
    case userConstants.LIST_DEPARTMENT_REQUEST:
      return {
        department: action.department
      };
    case userConstants.LIST_DEPARTMENT_SUCCESS:
      return {
        department: action.department
      };
    case userConstants.LIST_DEPARTMENT_FAILURE:
      return {};
    default:
      return state
  }
}
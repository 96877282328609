import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// import List from "list.js";
import { history } from '../../_helpers';
import { CommentSection } from '../../_components/common/commentSection';
import NotificationAlert from "react-notification-alert";
import { loadingButton, removeLoadingButton, addInvalid, removeInvalid } from '../../_functions';
// reactstrap components
import { Button, Card, CardHeader, Collapse, CardBody, Form, Input, Alert, Modal, Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, CardTitle, CardText, ButtonGroup } from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { SpecialRow } from "../../_components/common/special_row";
import { TourRow } from "../../_components/common/tour_row";
import { FoodRow } from "../../_components/common/food_row";
import { VehicleRow } from "../../_components/common/vehicleRow";
import { InsuranceRow } from "../../_components/common/insurance_row";
import { HotelRow } from "../../_components/common/hotel_row";
import moment from 'moment';

import { isArray, isObject } from "util";
import { _ticketCreate } from '../../_data/ticketCreate';
import { userActions, serviceActions, placesActions, vehicleActions } from '../../_actions';
import { connect } from 'react-redux';
import { Autocomplete } from '../../_components/common/autoComplete';

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    let billingArr = [];
    this.initialState();
  }

  initialState() {
    let stater = {};
    stater.create = false;
    _ticketCreate.forEach((v, i) => { stater[`${v.name}`] = ''; stater[`${v.name}State`] = ''; });
    stater[`status`] = { value: 1, label: 'Open' };

    this.state = {
      ticketCreation: stater, _ticketCreateState: [], doc_type: [], openedCollapses: [], collapse: false, saveButtonHide: false, activeTab: 1, billing: [], assignee: {}, service_tax_percent: 8, gst_percent: 16,
      specialCount: 1, hotelCount: 1, tourCount: 1, expensesCount: 1, hoexpensesCount: 1, grpOverAllTotalInd: 0, grpOverAllTotal: 0, specialState: {}, tourPlaceslistOption: [], statusOptions: [], InCount: 1, vehicleOptions: [], _vehicleList: {}, toggle_vehicle_facility: true, sOdometer: 0, eOdometer: 0, vehicleStart: { state: '', value: '' }, vehicleEnd: { state: '', value: '' }, vTiming: 0, kmChargeText1: '', kmChargeText2: '', kmChargeValue: 0, cChargeText1: '', cChargeText2: '', cChargeValue: 0, dChargeText1: '', dChargeText2: '', dChargeValue: 0, oTotal: 0, _vehicleCount: 1, vStage4Pay: 0
    };
  }

  reInitialState() {
    this.refs && this.refs['select_assignedTo'] && this.refs['select_assignedTo'].select.clearValue();
    this.refs && this.refs['select_department'] && this.refs['select_department'].select.clearValue();
    this.refs && this.refs['select_service'] && this.refs['select_service'].select.clearValue();
    this.refs && this.refs['select_status'] && this.refs['select_status'].select.clearValue();

    let stater = {};
    stater.create = false;
    _ticketCreate.forEach((v, i) => { stater[`${v.name}`] = ''; stater[`${v.name}State`] = ''; });
    stater[`status`] = { value: 1, label: 'Open' };

    this.setState({ ticketCreation: stater, doc_type: [], openedCollapses: [], collapse: false, saveButtonHide: false, activeTab: 1, billing: [], assignee: {}, specialCount: 1, hotelCount: 1, tourCount: 1, expensesCount: 1, hoexpensesCount: 1, grpOverAllTotalInd: 0, grpOverAllTotal: 0, specialState: {}, serviceState: {}, serviceCustomer: {}, e_ticket_id: '', e_cust_id: '' });
  }

  toggle(tab, stater) {
    if (this.state[stater] !== tab) {
      let staters = this.state;
      staters[`${stater}`] = tab;
      this.setState({ staters });
    }
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse))
      this.setState({ openedCollapses: [] });
    else
      this.setState({ openedCollapses: [collapse] });
  };

  async validateTicketCreationForm(e) {
    let elem = (e.currentTarget) ? e.currentTarget : e;
    const { dispatch } = this.props;
    let newState = this.state.ticketCreation;
    newState.create = true;

    await _ticketCreate.forEach((v, i) => {
      newState[`${v.name}State`] = v.valid;
      if (!newState[`${v.name}`] && v.isReq) {
        newState[`${v.name}State`] = v.invalid;
        newState.create = false;
      } else if (newState[`${v.name}`] && newState[`${v.name}`].toString().trim() === "" && v.isReq) {
        newState[`${v.name}State`] = v.invalid;
        newState.create = false;
      } else if (v.regex && newState[`${v.name}`] && newState[`${v.name}`].toString().trim() !== "") {
        var regex = new RegExp(v.regex);
        if (!regex.test(newState[`${v.name}`] && newState[`${v.name}`].toString().trim())) {
          newState[`${v.name}State`] = v.invalid;
          newState.create = false;
        } else {
          newState[`${v.name}State`] = v.valid;
        }
      }
    });

    this.setState({ ticketCreation: newState });

    if (this.state.ticketCreation.create) {
      let saveService = document.querySelector('.save-button');
      // if (elem && !elem.classList.contains("isLoading")) { loadingButton(elem); }
      // if (saveService) { loadingButton(saveService); }

      let userData = {
        firstname: this.state.ticketCreation.firstname,
        lastname: this.state.ticketCreation.lastname,
        email: this.state.ticketCreation.email,
        phone: this.state.ticketCreation.phone,
        address: this.state.ticketCreation.address,
        state: this.state.ticketCreation.state,
        city: this.state.ticketCreation.city,
        dob: this.state.ticketCreation.dob ? moment(this.state.ticketCreation.dob).format("YYYY-MM-DD") : '',
        zipcode: this.state.ticketCreation.zipcode,
        referred_by: this.state.ticketCreation.referred_by,
        referral_contact: this.state.ticketCreation.referral_contact
      };
      if (!this.state.e_cust_id) {
        dispatch(userActions.createCustomer(userData)).then(() => {
          this.postCreateCustomer(elem);
        });
      } else {
        userData.id = this.state.e_cust_id;
        dispatch(userActions.updateCustomer(userData)).then(() => {
          this.postCreateCustomer(elem);
        });
      }
    }
  };

  notify = (place, title, message) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: "default",
      icon: "ni ni-bell-55",
      autoDismiss: 15
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  postCreateCustomer(elem) {
    const { customer, dispatch, authentication } = this.props;
    const { e_cust_id, e_ticket_id } = this.state;
    let customerId = (e_cust_id) ? e_cust_id : customer['customer']['customer']['id'];
    if (customerId != '') {
      this.setState({ e_cust_id: customerId });
      let ticketData = {
        assigned_to: this.state.ticketCreation.assignedTo,
        deptcode: this.state.ticketCreation.department,
        ticket_status: (this.state.ticketCreation.status.value) ? this.state.ticketCreation.status.value : this.state.ticketCreation.status,
        poc: this.state.ticketCreation.poc,
        poc_number: this.state.ticketCreation.poc_number,
        created_by: authentication.user.user.id,
        next_follow_up: (this.state.ticketCreation.nfu) ? moment(this.state.ticketCreation.nfu).format("YYYY-MM-DD") : '',
        customer_id: customerId,
        referred_by: this.state.ticketCreation.referred_by,
        referral_contact: this.state.ticketCreation.referral_contact,
      };
      if (!e_ticket_id) {
        dispatch(userActions.createTicket(ticketData)).then(() => {
          // this.notify("tc", "", "Ticket created successfully.");
          this.postTicketCreate(elem);
        });
      } else {
        ticketData.id = e_ticket_id;
        dispatch(userActions.updateTicket(ticketData)).then(() => {
          // this.notify("tc", "", "Ticket created successfully.");
          this.postTicketCreate(elem);
        });
      }
    } else {
      this.setState({ e_cust_id: customerId });
      let ticketData = {
        assigned_to: this.state.ticketCreation.assignedTo,
        deptcode: this.state.ticketCreation.department,
        ticket_status: (this.state.ticketCreation.status.value) ? this.state.ticketCreation.status.value : this.state.ticketCreation.status,
        poc: this.state.ticketCreation.poc,
        poc_number: this.state.ticketCreation.poc_number,
        created_by: authentication.user.user.id,
        next_follow_up: (this.state.ticketCreation.nfu) ? moment(this.state.ticketCreation.nfu).format("YYYY-MM-DD") : '',
        customer_id: customerId,
        referred_by: this.state.ticketCreation.referred_by,
        referral_contact: this.state.ticketCreation.referral_contact,
      };
      if (!e_ticket_id) {
        dispatch(userActions.createTicket(ticketData)).then(() => {
          // this.notify("tc", "", "Ticket created successfully.");
          this.postTicketCreate(elem);
        });
      } else {
        ticketData.id = e_ticket_id;
        dispatch(userActions.updateTicket(ticketData)).then(() => {
          // this.notify("tc", "", "Ticket created successfully.");
          this.postTicketCreate(elem);
        });
      }
    }

    // else{
    //   let saveService = document.querySelector('.save-button');
    //   if (elem && elem.classList.contains("isLoading")) { removeLoadingButton(elem, 'Save'); }
    //   if (saveService) { removeLoadingButton(saveService, 'Save'); }
    //   let newState = this.state.ticketCreation;
    //   // Object.keys(customer.customer.error.response.data).forEach((key) => {
    //     newState[`${key}State`] = 'invalid';
    //     newState[`${key}Feedback`] = customer.message.toString();
    //     newState.create = false;
    //   // });
    //   this.setState({
    //     ticketCreation: newState
    //   });
    // }


  };

  notificationApi(ticket_id) {
    const { e_notify_id, ticketCreation } = this.state;
    const { dispatch } = this.props;
    let notificationData = {
      t_id: ticket_id,
      type: 0,
      notification_status: 0,
      assigned_to: ticketCreation.assignedTo.value ? ticketCreation.assignedTo.value : ticketCreation.assignedTo
    };
    if (!e_notify_id) {
      dispatch(userActions.createNotification(notificationData)).then(() => {
        const { notificationcreate } = this.props;
        const { notification } = notificationcreate;
        const { data } = notification;
        const { id } = data;
        this.setState({ e_notify_id: id });
      });
    } else {
      notificationData.id = e_notify_id;
      dispatch(userActions.updateNotification(notificationData))
    }
  }

  postTicketCreate(elem) {
    const { ticket, authentication, dispatch, customer } = this.props;
    const { ticketCreation, e_ticket_id, e_cust_id } = this.state;
    const { service } = ticketCreation;
    return this.props.history.push('/admin/tickets/list');

    // if ((ticket && ticket.ticket && ticket.ticket.data && ticket.ticket.data.id) || e_ticket_id) {
    //   let ticket_id = (e_ticket_id) ? e_ticket_id : ticket.ticket.data.id;
    //   this.setState({ e_ticket_id: ticket_id });
    //   this.notificationApi(ticket_id);

    //   let commentData = {
    //     ticket_id: ticket_id,
    //     comments: `Ticket ${ticket_id} was created by ${authentication.user.user.name} in the departmnent ${authentication.user.user.deptcode} at ${new Date().toGMTString()}`,
    //     commented_by: authentication.user.user.id,
    //     assigned_to: ticketCreation.assignedTo
    //   }

    //   let customerId = e_cust_id;
    //   let ticketServicesData = {
    //     service_id: service,
    //     ticket_id: ticket_id,
    //     customer_id: customerId
    //   };
    //   // this.setState({ e_ticket_id: ticket, e_cust_id: customerId });

    //   if (!e_ticket_id) {
    //     this.getServiceStatuses().then((res) => {
    //       ticketServicesData.servicesStatus = res;
    //       dispatch(userActions.createServiceTickets(ticketServicesData)).then(() => {
    //         // this.notify("tc", "", "Ticket Services added successfully.");
    //       });
    //     });
    //   } else {
    //     this.getServiceStatuses().then((res) => {
    //       ticketServicesData.servicesStatus = res;
    //       dispatch(userActions.updateServiceTickets(ticketServicesData)).then(() => {
    //         // this.notify("tc", "", "Ticket Services updated successfully.");
    //       });
    //     });
    //   }

    //   dispatch(userActions.createComments(commentData)).then(() => {
    //     let { ticketDetails, e_ticket_id } = this.state;
    //     ticketDetails = (ticketDetails) ? ticketDetails : {};
    //     ticketDetails.id = e_ticket_id;
    //     ticketDetails.assigned_to = ticketCreation.assignedTo;
    //     ticketDetails.comments = (ticketDetails.comments) ? ticketDetails.comments : [];
    //     ticketDetails.comments.push({
    //       commentuser: {
    //         name: authentication.user.user.name,
    //         getdepart: { deptname: authentication.user.user.getdepart.deptname }
    //       },
    //       comments: commentData.comments,
    //       created_at: new Date().toJSON()
    //     });
    //     this.setState({ ticketDetails: ticketDetails });
    //     this.postCommentCreate(elem);
    //   });
    // }
  }

  postCommentCreate(elem) {
    const { e_ticket_id, e_cust_id } = this.state;
    const { comment, ticket, dispatch, authentication, logs } = this.props;
    if (comment && comment.comment && comment.comment.data && comment.comment.data.id) {
      let logData = {
        ticket_id: e_ticket_id,
        assigned_to: this.state.ticketCreation.assignedTo,
        status_id: (this.state.ticketCreation.status.value) ? this.state.ticketCreation.status.value : this.state.ticketCreation.status,
        assigned_by: authentication.user.user.id,
      }
      dispatch(userActions.createLogs(logData)).then(() => {
        // this.notify("tc", "", "Logs created successfully.");
        this.postLogsCreate(elem)
      })
    }
  }

  async getdelete1(id, unit1, elem) {
    const { dispatch } = this.props;
    if (id) {
      await dispatch(userActions.hoteldelete(id)).then(() => {
        this.notify("tc", "", "Hotel has been deleted successfully");
      });
    }
  }

  postLogsCreate(elem) {
    const { customer, dispatch, logs, ticket } = this.props;
    const { doc_type, e_ticket_id, e_cust_id } = this.state;
    let customerId = e_cust_id;
    if (this.state.billing.length > 0 || this.state.doc_type.length > 0) {
      if (this.state.doc_type.length > 0) {
        doc_type.forEach((v, i) => {
          const docData = new FormData();
          docData.append('document_name', v.files);
          docData.append('customer_id', customerId);
          docData.append('document_id', v.id);
          if (v.document_id) {
            docData.append('id', v.document_id);
            dispatch(userActions.updateUploadDocument(docData)).then(() => {
              // this.notify("tc", "", `Document ${i} uploaded successfully.`);
              // document.querySelector('.ticket-create-form').reset();
              // history.go(`admin/tickets/edit/${ticket.ticket.data.id}`);
            });
          } else {
            dispatch(userActions.uploadDocument(docData)).then(() => {
              const { document } = this.props;
              const { data } = document && document.document || { data: { id: '' } };
              const { id } = data;
              doc_type[i]['document_id'] = id;
              // this.notify("tc", "", `Document ${i+1} uploaded successfully.`);
              // document.querySelector('.ticket-create-form').reset();
              // history.go(`admin/tickets/edit/${ticket.ticket.data.id}`);
            });
          }
        });
        this.setState({ doc_type: doc_type });
      }

      if (this.state.billing.length > 0) {
        let { billing, ticketCreation } = this.state;
        const { service } = ticketCreation;
        let kj = 0;
        billing.forEach((v, i) => {
          let gstAmtTmp = billing[i] && billing[i][`gst_amount`] || 0;
          const billingData = {
            'ticket_id': e_ticket_id,
            'service_id': service[kj],
            'customer_id': customerId,
            'package_amount': v[`price`],
            'service_charge': v[`service`],
            'service_tax_amount': v[`service_tax`],
            'gst_amount': gstAmtTmp,
            'amount_received': v[`paid`],
            'amount_pending': v[`bal`],
            'e_mop': billing[i] && billing[i][`mop`] || '',
            'e_paid_amount': billing[i] && billing[i][`e_paid_amount`] || 0, //e_paid_amount
            'invoice_to': billing[i] && billing[i][`invoice_toData`] && billing[i][`invoice_toData`].value || '',
            'amount_paid_by': billing[i] && billing[i][`amount_paid_byData`] && billing[i][`amount_paid_byData`].value || '',
            'vendor_name': billing[i] && !isArray(billing[i][`vendor_name`]) && billing[i][`vendor_name`] || '',
            'invoice_buyer_name': billing[i] && billing[i][`invoice_buyer_name`] || '',
            'other_amount': billing[i] && billing[i][`others`] || ''
          };
          kj = kj + 1;
          if (!billing[i][`id`]) {
            dispatch(userActions.createBilling(billingData)).then(() => {
              const billingsData = this.props.billing;
              const { document } = billingsData;
              const { data } = document;
              const { id } = data;
              billing[i][`id`] = id;
            });
          } else {
            billingData['id'] = billing[i] && billing[i][`id`];
            dispatch(userActions.updateBilling(billingData));
          }
        });
        this.setState({ billing: billing });
      }

      this.finishTickerCreate(elem);
    } else {
      this.finishTickerCreate(elem);
    }
  }

  async getServiceStatuses() {
    const { ticketCreation, select_tour_status, select_hotel_status, serviceState } = this.state;
    const { serviceValue } = ticketCreation;
    let services_temp = serviceValue;
    let servicesStatus = [];
    await Object.keys(services_temp).forEach(key => {
      if (services_temp[key] === 1) { servicesStatus.push(select_tour_status && select_tour_status.value || 1); }
      else if (services_temp[key] === 6) { servicesStatus.push(select_hotel_status && select_hotel_status.value || 1); }
      else if (services_temp[key] === 2) { servicesStatus.push(serviceState && serviceState['passport_status'] || 1); }
      else if (services_temp[key] === 3) { servicesStatus.push(serviceState && serviceState['visa_status'] || 1); }
      else { servicesStatus.push(1); }
    });
    return servicesStatus;
  }

  async saveServicesStatus() {
    const { dispatch } = this.props;
    const { services_temp, ticketCreation, e_ticket_id, e_cust_id } = this.state;
    await this.getServiceStatuses().then((res) => {
      if (res) {
        let ticketServicesData = {
          service_id: services_temp,
          servicesStatus: res,
          ticket_id: e_ticket_id,
          assigned_to: this.state.ticketCreation.assignedTo.value ? this.state.ticketCreation.assignedTo.value : this.state.ticketCreation.assignedTo,
          customer_id: e_cust_id
        };
        dispatch(userActions.updateServiceTickets(ticketServicesData));
      }
    });
  }

  vehicleSave = _class => {
    let elem = document.querySelector(`${_class}`);
    elem && loadingButton(elem);
    const { e_ticket_id, e_cust_id, select_vehicle, vehicleStart, vehicleEnd, vehicle_id, vehicle_pack_id, _vehiclePackage, _dataFin, eOdometer, sOdometer, vTiming, oDiscount, kmChargeText2, kmChargeText1, kmChargeValue, cChargeText1, cChargeText2, cChargeValue, dChargeText1, dChargeText2, dChargeValue, oTotal, toggle_vehicle_facility, vehicle_stage_3_id, kmChargeOrig, cChargeOrig, dChargeOrig } = this.state;

    const { dispatch } = this.props;
    if (_class === '.vehicle_save_stage_1') {
      let vehicle = document.querySelector('.select_vehicle');
      vehicle && removeInvalid(vehicle);
      if (vehicle && vehicle.childNodes[1].value !== '' && vehicleStart.value && vehicleEnd.value) {
        let vehicleData = { ticket_id: e_ticket_id, customer_id: e_cust_id, vehicle: JSON.stringify(select_vehicle), vehicle_start: vehicleStart.value, vehicle_end: vehicleEnd.value, stage: 1 }
        if (vehicle_id) {
          vehicleData.id = vehicle_id;
          dispatch(vehicleActions.vehicleUpdateStage1(vehicleData)).then(() => {
            elem && removeLoadingButton(elem, 'Save');
          });
        } else {
          dispatch(vehicleActions.vehicleSaveStage1(vehicleData)).then(() => {
            const { vehicleStages } = this.props;
            const { stage1 } = vehicleStages;
            const { data } = stage1;
            const { id } = data;
            this.setState({ vehicle_id: id });
            elem && removeLoadingButton(elem, 'Save');
          });
        }
      } else {
        vehicle && vehicle.childNodes[1].value === '' && addInvalid(vehicle);
        if (vehicleStart.value === '')
          this.setState({ vehicleStart: { value: vehicleStart.value, state: 'invalid' } });
        else if (vehicleEnd.value === '')
          this.setState({ vehicleEnd: { value: vehicleEnd.value, state: 'invalid' } });
      }
    } else if (_class === '.vehicle_save_stage_2') {
      let vehiclePackData = { vehicle_init: vehicle_id, pack_id: _vehiclePackage, facility: (toggle_vehicle_facility) ? 'A/C' : 'Non A/C', vehicle_data: JSON.stringify(_dataFin), stage: 2 };
      if (vehicle_pack_id) {
        vehiclePackData.id = vehicle_pack_id;
        dispatch(vehicleActions.vehicleUpdateStage2(vehiclePackData)).then(() => {
          elem && removeLoadingButton(elem, 'Save');
        });
      } else {
        dispatch(vehicleActions.vehicleSaveStage2(vehiclePackData)).then(() => {
          const { vehicleStages } = this.props;
          const { stage2 } = vehicleStages;
          const { data } = stage2;
          const { id } = data;
          this.setState({ vehicle_pack_id: id });
          elem && removeLoadingButton(elem, 'Save');
        });
      }
    } else if (_class === '.vehicle_save_stage_3') {
      let vehicleStage3 = { vehicle_init: vehicle_id, stage: 3, timing: vTiming, sodometer: sOdometer, eodometer: eOdometer, distance: ((eOdometer - sOdometer) || '0'), km_actual: kmChargeValue, km_original: kmChargeOrig, car_actual: cChargeValue, car_original: cChargeOrig, driver_actual: dChargeValue, driver_original: dChargeOrig, discount: oDiscount, total: oTotal };
      if (vehicle_stage_3_id) {
        vehicleStage3.id = vehicle_stage_3_id;
        dispatch(vehicleActions.vehicleUpdateStage3(vehicleStage3)).then(() => {
          elem && removeLoadingButton(elem, 'Save');
        });
      } else {
        dispatch(vehicleActions.vehicleSaveStage3(vehicleStage3)).then(() => {
          const { vehicleStages } = this.props;
          const { stage3 } = vehicleStages;
          const { data } = stage3;
          const { id } = data;
          this.setState({ vehicle_stage_3_id: id });
          elem && removeLoadingButton(elem, 'Save');
        });
      }
    } else {
      elem && removeLoadingButton(elem, 'Save');
    }
  }

  finishTickerCreate(elem) {
    const { e_ticket_id, e_cust_id } = this.state;
    const { ticket, customer } = this.props;
    const ticket_id = e_ticket_id;
    let customerId = e_cust_id;
    let saveButton = document.querySelector('.save-button');
    let saveHotels = document.querySelector('.hotel_save');
    let showTourPlaces = document.querySelector('.showTourPlaces');
    let saveServiceButtons = document.querySelectorAll('.saveService');
    let specialSaveButtons = document.querySelectorAll('.specialSaveBtn');
    let insruanceSaveButton = document.querySelectorAll('.insuranceSaveBtn');
    let vehicleStage1 = document.querySelector('.vehicle_save_stage_1');
    let vehicleStage2 = document.querySelector('.vehicle_save_stage_2');
    let vehicleStage3 = document.querySelector('.vehicle_save_stage_3');
    let vehicleStage4 = document.querySelectorAll('.vehicle_save_stage_4');

    if (vehicleStage1)
      this.vehicleSave('.vehicle_save_stage_1');
    if (vehicleStage2)
      this.vehicleSave('.vehicle_save_stage_2');
    if (vehicleStage3)
      this.vehicleSave('.vehicle_save_stage_3');
    vehicleStage4 && vehicleStage4.forEach((v, i) => {
      v.click();
    });
    if (saveHotels)
      this.hotel_save('.hotel_save');

    specialSaveButtons && specialSaveButtons.forEach((v, i) => { if (v.classList.contains("isLoading")) { removeLoadingButton(elem, 'Save'); v.click(); } else { v.click(); } });
    insruanceSaveButton && insruanceSaveButton.forEach((va, i) => { if (va.classList.contains("isLoading")) { removeLoadingButton(elem, 'Save'); va.click(); } else { va.click(); } });
    saveServiceButtons && saveServiceButtons.forEach((v, i) => {
      if (v.classList[1] && v.classList[1].split('-')[1])
        this.saveServices(v, v.classList[1] && v.classList[1].split('-')[1], this.state.serviceFlds && this.state.serviceFlds[v.classList[1] && v.classList[1].split('-')[1]]);
    });
    if (showTourPlaces) { this.showPlaceRows(showTourPlaces); }
    document.querySelector('.toggle').click();
    this.setState({ "collapse": true, "saveButtonHide": true });
    if (elem && elem.classList.contains("isLoading")) { removeLoadingButton(elem, 'Save'); }
    if (saveButton)
      removeLoadingButton(saveButton, "Save");

    // document.querySelector('.save-button').style.height = `${document.querySelector('.save-button').clientHeight}px`;
    // document.querySelector('.save-button').style.width = `${document.querySelector('.save-button').clientWidth}px`;
    // document.querySelector('.save-button').innerHTML = 'Save';

    this.notify("tc", "", "Ticket created successfully.");
    // setTimeout(() => { window.location.href = `/admin/tickets/edit/${ticket_id}`; }, 2500);
  }

  onDateChange = (value, idSelector) => {
    value = (value && value._d);
    let newStates = this.state.ticketCreation;
    newStates[`${idSelector}`] = (value) ? moment(value).format('YYYY-MM-DD') : '';
    newStates[`${idSelector}State`] = 'invalid';
    if (value) { newStates[`${idSelector}State`] = 'valid'; }
    this.setState({ ticketCreation: newStates });
  };

  onBillSelectChange = (value, i, idSelector, service) => {
    if (idSelector == "amount_paid_by" && value && value.value === 'vendor' && service !== 'passport') {
      const { dispatch } = this.props;
      dispatch(userActions.getVendorList(service)).then(() => {
        const { vendor } = this.props;
        const { list } = vendor;
        const { data } = list;
        let vendorArr = this.state.vendorList || [];
        data.forEach((v, i) => {
          vendorArr.push({ value: v.id, label: v.name });
        })
        this.setState({
          vendorList: vendorArr
        });
      });
    }

    let sVal = (value) ? value.value : '';
    let sValue = (value) ? value.label : '';
    let { billing } = this.state;
    billing[i] = (billing[i]) ? billing[i] : {};

    billing[i][`${idSelector}`] = sVal;
    billing[i][`${idSelector}Data`] = (value) ? value : '';
    billing[i][`${idSelector}Value`] = sValue;
    this.setState({
      billing: billing
    });
  }

  onSelectChange = (value, idSelector) => {
    let sVal = null;
    let sValue = null;
    if (idSelector == "service") {
      let val = value;
      let lastVal = (val && val.length > 0) ? val[val.length - 1] : null;
      if (lastVal) {
        const { serviceFields, dispatch } = this.props;
        if (lastVal.label.toLowerCase() !== "tour" && lastVal.label.toLowerCase() !== "hotel" && lastVal.label.toLowerCase() !== "food" && lastVal.label.toLowerCase() !== "vehicle") {
          if (!this.state.serviceFlds || (this.state.serviceFlds && !this.state.serviceFlds[lastVal.label.toLowerCase()])) {
            dispatch(userActions.getServiceFields(lastVal.label.toLowerCase().trim(), ((serviceFields && serviceFields['services']) ? serviceFields['services'] : []))).then(() => {
              const { serviceFields } = this.props;
              if (serviceFields && serviceFields.service && serviceFields.service[0]) {
                Object.keys(serviceFields.service[0]).forEach((key) => {
                  let dataArr = (this.state.serviceFlds) ? this.state.serviceFlds : {};
                  // let itemExists = false;
                  // dataArr.forEach((v) => { if(v[key]){ itemExists = true; return false; } });
                  // if(!itemExists){
                  dataArr[key] = serviceFields.service[0][key];
                  this.setState({ serviceFlds: dataArr });
                  // }
                });
              }
            });
          }
        }
      }
    }

    if (idSelector == "department") {
      if (value && value.deptcode) {
        const { dispatch } = this.props;
        const { ticketCreation } = this.state;
        this.refs.select_assignedTo.select.clearValue();
        ticketCreation['departmentState'] = '';
        ticketCreation['assignedToState'] = '';
        this.setState({ ticketCreation: ticketCreation });
        dispatch(userActions.listDepartmentAssigned(value.deptcode));
      }
    }

    if (value) {
      if (Array.isArray(value)) {
        sVal = [];
        sValue = [];
        value.forEach((v, i) => { sVal.push(v.value); sValue[v.value] = v.label; });
      } else {
        sVal = '';
        sValue = '';
        sVal = value.value;
        sValue = value.label;
      }
    }
    let newStates = this.state.ticketCreation;

    newStates[`${idSelector}`] = sVal;
    newStates[`${idSelector}Value`] = sValue;
    newStates[`${idSelector}State`] = 'is-invalid';
    this.setState({
      ticketCreation: newStates
    });
    if (sVal != null && sVal != '') {
      newStates[`${idSelector}State`] = 'is-valid';
      this.setState({
        ticketCreation: newStates
      });
    }


    if (idSelector == "service") {
      let afterSelector = document.querySelectorAll('.css-1rhbuit-multiValue');
      if (afterSelector) {
        let additionalData = sVal && sVal.length - 3;
        let counter = document.querySelector('.css-1rhbuit');
        if (additionalData > 0) {
          if (!counter) {
            let lastDataChild = afterSelector[2];
            let div = document.createElement('div');
            div.classList.add('css-1rhbuit')
            div.innerHTML = `<span>+ ${additionalData}</span>`;
            lastDataChild.parentNode.appendChild(div);
          } else {
            counter.innerHTML = `+ ${additionalData}`;
          }
        } else if (counter) { counter.parentNode.removeChild(counter); }
      }
    }
  };

  ticketCreationForm = (e, stateName, isReq, regexp, regexInvalid) => {
    let newState = this.state.ticketCreation;
    newState[stateName] = e.target.value;
    newState[stateName + "Feedback"] = "";
    newState[stateName + "State"] = "valid";
    if (e.target.value.trim() === "" && isReq) {
      newState[stateName + "State"] = "invalid";
    } else if (e.target.value.trim() !== "" && regexp) {
      var regex = new RegExp(regexp);
      if (!regex.test(e.target.value.trim())) {
        newState[stateName + "Feedback"] = regexInvalid;
        newState[stateName + "State"] = "invalid";
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({ ticketCreation: newState });
  };

  tourStage3Save(elemClass) {
    let elem = document.querySelector(`${elemClass}`);
    const { dispatch } = this.props;

    if (elem)
      loadingButton(elem);
    let rows = document.querySelectorAll(`.foodRow_st`);
    let rowSave = true;
    let rowDataInsert = {
      "ticket_id": this.state.e_ticket_id,
      "customer_id": this.state.e_cust_id,
      "tour_id": this.state.tour_id_init,
      "category": (this.state.food_places_init_chk) ? 'food' : 'vendor',
      "food_expenses": {}
    };
    rows.forEach((v, i) => {
      let tempStartDate = document.querySelector(`.start_date_food_${i}`);
      let tempEndDate = document.querySelector(`.end_date_food_${i}`);
      let tempPlace = document.querySelector(`.places_food_${i}`);
      let tempVendor = document.querySelector(`.vendor_food_${i}`);
      let tempAmount = document.querySelector(`.amount_paid_${i}`);
      let foodRows = document.querySelectorAll('.foodRow_st');
      let rowsLen = foodRows.length;

      let isLastRow = rowsLen === i + 1 && foodRows[rowsLen - 1].children[1].children[0].children[0].value === this.state.endDate_places_init;

      removeInvalid(tempStartDate);
      removeInvalid(tempEndDate);
      removeInvalid(tempPlace);
      removeInvalid(tempVendor);
      removeInvalid(tempAmount);
      if (tempStartDate.value != '' && tempEndDate.value != '' && tempPlace.children[1].value != '' && tempVendor.children[1].value != '' && tempAmount.value != '') {
        rowDataInsert.food_expenses[i] = {
          "start_date": tempStartDate.value,
          "end_date": tempEndDate.value,
          "place_id": tempPlace.children[1].value,
          "vendor_id": tempVendor.children[1].value,
          "amount": tempAmount.value
        };
      } else {
        if (!isLastRow) {
          if (elem)
            removeLoadingButton(elem, "Save");
          rowSave = false;
        }
        if (!isLastRow && tempStartDate.value === "")
          addInvalid(tempStartDate);
        if (!isLastRow && tempEndDate.value === "")
          addInvalid(tempEndDate);
        if (!isLastRow && tempPlace.children[1].value === "")
          addInvalid(tempPlace);
        if (!isLastRow && tempVendor.children[1].value === "")
          addInvalid(tempVendor);
        if (!isLastRow && tempAmount.value === "")
          addInvalid(tempAmount);
      }
    });

    if (rowSave) {
      const { tourExpensesInsert } = this.props;
      const { expenses_data } = this.state;
      if ((tourExpensesInsert && tourExpensesInsert.tourExpenses && tourExpensesInsert.tourExpenses.food && tourExpensesInsert.tourExpenses.food.length > 0) || (expenses_data && Object.keys(expenses_data).length > 0)) {
        dispatch(userActions.tourExpensesUpdate(rowDataInsert)).then(() => {
          const { tourExpensesUpdate } = this.props;
          const { data } = tourExpensesUpdate;
          const { getup } = data;

          getup && getup.forEach((v, i) => {
            let tempInput = document.querySelectorAll('.foodRow_st')[i].children[0].children[0];
            tempInput.value = v.id;
            expenses_data['id'] = v.id;
          });
          this.setState({ expenses_data: expenses_data });

          if (elem)
            removeLoadingButton(elem, "Save");
          this.notify("tc", "", "Expenses has been updated successfully");
        });
      } else {
        dispatch(userActions.tourExpensesInsert(rowDataInsert)).then(() => {
          const { tourExpensesInsert } = this.props;
          if (elem)
            removeLoadingButton(elem, "Save");
          this.notify("tc", "", "Expenses has been created successfully");
        });
      }
    }
  }

  initialize() {
    const { dispatch } = this.props;
    dispatch(userActions.listDepartment());
    // dispatch(userActions.listVendors()).then(() => { const { vendor } = this.props; if(vendor && vendor.list && vendor.list.data){ let vendorinit = [ { value: 'e-route', label: 'E-Route' } ]; vendor.list.data.forEach(v => { vendorinit.push({ value: v.id, label: v.name }); }); this.setState({ vendorinit: vendorinit }); } });
    // dispatch(userActions.listAssigned());
    // dispatch(userActions.listService());
    dispatch(userActions.listStatus()).then(() => {
      const { statusOptions } = this.state;
      const { status } = this.props;
      const { data } = (status && status.status) ? status.status : [];
      // data.forEach((v, i) => { statusOptions.push({ label: v.name, value: v.id }); });
    });
    // dispatch(userActions.listDocumentType());
    // dispatch(userActions.listTourTypes());
    // dispatch(vehicleActions.vehicleList({})).then(() => {
    //   const { vehicleOptions, _vehicleList } = this.state;
    //   const { vehicle } = this.props;
    //   const { list } = vehicle;
    //   const { data } = list;
    //   data.forEach((v, i) => { vehicleOptions.push({ label: v.name, value: v.id }); _vehicleList[v.id] = v; });
    //   this.setState({ vehicleOptions: vehicleOptions, _vehicleList: _vehicleList });
    // });

    // dispatch(userActions.listTourPlaces()).then(() => {
    //   let { tourPlaceslistOption } = this.state;
    //   const { tourPlaceslist } = this.props;
    //   tourPlaceslist && tourPlaceslist.data && tourPlaceslist.data.data && tourPlaceslist.data.data.length > 0 && tourPlaceslist.data.data.forEach((v, i) => {
    //     tourPlaceslistOption.push({ label: v.name, value: v.id });
    //   });
    // });
  }

  componentDidMount() {
    document.querySelector('.navbar-nav .ticket-menu') && document.querySelector('.navbar-nav .ticket-menu').classList.add('active');
    this.initialize();
  }

  parserFloat(data) {
    return isNaN(parseFloat(data)) ? 0 : parseFloat(data)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { status, assigned, department, service, serviceFields, dispatch } = this.props;
    const { e_ticket_id } = this.state;
    // if(prevState.e_ticket_id != e_ticket_id){ if(!e_ticket_id){ this.setState({ : { value: '1', label: 'Open' } }); } }

    if (this.props !== prevProps) {
      _ticketCreate[5].option = [];
      _ticketCreate[6].option = [];
      _ticketCreate[7].option = [];
      _ticketCreate[8].option = [];

      // department && department.department && department.department.data && department.department.data.forEach((i, v) => {
      //   _ticketCreate[6].option[v] = { value: i.id, label: i.deptname.trim(), deptcode: i.code.trim() };
      // });
      // service && service.service && service.service.data && service.service.data.forEach((i, v) => {
      //   _ticketCreate[5].option[v] = { value: i.id, label: i.name.trim() };
      // });
      status && status.status && status.status.data && status.status.data.forEach((i, v) => {
        _ticketCreate[5].option[v] = { value: i.id, label: i.name.trim() };
      });
      assigned && assigned.assigned && assigned.assigned.data && assigned.assigned.data.forEach((i, v) => {
        let dataOption = `${i.name.trim()}`;
        _ticketCreate[7].option[v] = { value: i.id, label: dataOption, badge: `${i.ticketslist.length}` };
      });

      this.setState({
        _ticketCreateState: _ticketCreate
      });
    }
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  doAction(e, _item, _action) {
    e.stopPropagation();
  };

  addDocType(e, sName) {
    e.preventDefault();
    const { ticketCreation } = this.state;
    const { doc_type } = this.state;
    if (ticketCreation.doc_type != null) {
      doc_type[ticketCreation.doc_type] = {
        'id': ticketCreation.doc_type,
        'value': ticketCreation.doc_typeValue
      };

      this.setState({
        [`${sName}`]: doc_type
      });
    }
  }

  openBrowseButton(e, value, key) {
    e.preventDefault();
    document.querySelector(`.${value.replace(/ /g, '_')}_file_${key}`).click();
  }

  deleteAttachment(e, value, key) {
    e.preventDefault();
    const { doc_type } = this.state;
    delete doc_type[key];
    this.setState({
      doc_type: doc_type
    });
  }

  loadImage(e, value, key) {
    let img = document.querySelector(`.${value}_img_${key}`);
    let preview = document.querySelector(`.${value}_preview_${key}`);
    const { doc_type } = this.state;
    preview.classList.add('d-none');
    preview.classList.remove('d-flex');
    img.src = '';
    doc_type[key].files = '';
    if (e.target && e.target.files && e.target.files[0]) {
      img.src = '...';
      img.alt = '...';
      if (e.target.files[0].type.indexOf('image') !== -1) {
        img.src = URL.createObjectURL(e.target.files[0]);
      }
      doc_type[key].files = e.target.files[0];
      preview.classList.remove('d-none');
      preview.classList.add('d-flex');
    }
    this.setState({
      doc_type: doc_type
    });
  }

  createComment(e) {
    e.preventDefault();
    const { ticket, authentication, dispatch } = this.props;
    if (ticket && ticket.ticket && ticket.ticket.data && ticket.ticket.data.id) {
      let ticket_id = ticket.ticket.data.id;
      let commentData = {
        ticket_id: ticket_id,
        assigned_to: ticket.ticket.data.assigned_to,
        comments: document.querySelector('.comment-box').value,
        commented_by: authentication.user.user.id
      }
      dispatch(userActions.createComments(commentData)).then(() => {
        // this.notify("tc", "", "Comment added successfully.");
        let updateTicketData = {
          'id': ticket_id,
          'next_follow_up': document.querySelector('.nfu_date').value
        }
        dispatch(userActions.updateTicket(updateTicketData)).then(() => {
          document.querySelector('.nfu_date').value = '';
          document.querySelector('.comment-box').value = '';
          // this.notify("tc", "", "Ticket updated successfully.");
        });
      });
    }
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

  openBilling() {
    this.setState({ billingModal: true, 'billing_error': '' });
  }

  closeBilling() {
    let { billing } = this.state;
    let tempState = true;
    let tempi = '';
    billing.forEach((v, i) => {
      let temp_serv = v.services && isArray(v.services) && v.services.length > 0 && v.services[0] && v.services[0]['name'] && v.services[0]['name'].toLowerCase();
      if (temp_serv !== 'tour') {
        billing[i] = (billing[i]) ? billing[i] : {};

        billing[i]['mopState'] = (billing[i]['mop']) ? 'valid' : 'invalid';
        billing[i]['amount_paid_byState'] = (billing[i]['amount_paid_by']) ? 'valid' : 'invalid';
        billing[i]['invoice_toState'] = (billing[i]['invoice_to']) ? 'valid' : 'invalid';
        billing[i]['invoice_buyer_nameState'] = (billing[i]['invoice_buyer_name']) ? 'valid' : 'invalid';
        billing[i]['vendor_nameState'] = (billing[i]['amount_paid_by'] == 'vendor') ? ((billing[i]['vendor_name'] && billing[i]['vendor_name'] != null) ? 'valid' : 'invalid') : 'valid';

        if (billing[i]['mopState'] === 'invalid' || billing[i]['amount_paid_byState'] === 'invalid' || billing[i]['invoice_toState'] === 'invalid' || billing[i]['invoice_buyer_nameState'] === 'invalid' || billing[i]['vendor_nameState'] === 'invalid') {
          tempState = false;
          tempi = (tempi === '') ? i : tempi;
        }
      }
    });
    if (tempState) {
      this.setState({ 'billing_error': '' });
      this.toggleModal("billingModal");
    } else {
      this.setState({
        'openedCollapses': [`collapse${tempi}`],
        'billing_error': (<Alert color="warning" className="m-0 mt--5">
          <strong>Warning!</strong> <br />Required Fields are missing. You may lose the data. <br />Do you want to continue?
          <br /> <button className="btn btn-secondary btn-sm mt--3 float-right" onClick={() => { this.setState({ 'billing_error': '', 'openedCollapses': '' }) }}>Cancel</button> <button className="btn btn-secondary btn-sm mt--3 mr-1 float-right" onClick={() => { this.toggleModal("billingModal"); this.setState({ 'openedCollapses': '' }); }}>Ok</button>
        </Alert>)
      });
    }
  }

  handleBillingInputsChange(i, e, v, action) {
    let taxPercent = this.parserFloat(this.state.service_tax_percent) / 100;
    let taxAmount = this.parserFloat(this.refs[`billing_${i}_service`].value) * taxPercent;
    let gstAmount = taxAmount * (this.parserFloat(this.state.gst_percent) / 100);
    if (v.trim().toLowerCase() === 'tour') {
      gstAmount = 0;
    }
    this.refs[`billing_${i}_service_tax`].value = taxAmount;
    // this.refs[`billing_${i}_price`].value =
    let billpriceval = (this.parserFloat(this.refs[`billing_${i}_o_price`].value) + this.parserFloat(this.refs[`billing_${i}_service`].value) + this.parserFloat(this.refs[`billing_${i}_service_tax`].value) + this.parserFloat(this.refs[`billing_${i}_others`].value) + this.parserFloat(gstAmount));
    // this.refs[`billing_${i}_bal`].value =
    let billbalval = this.parserFloat(billpriceval) - this.parserFloat(this.refs[`billing_${i}_paid`].value);

    let { billing } = this.state;
    billing[i] = (billing[i]) ? billing[i] : {};
    billing[i]['invoice_buyer_name'] = (this.refs[`billing_${i}_invoice_buyer_name`]) ? this.refs[`billing_${i}_invoice_buyer_name`].value : ((billing[i]['invoice_buyer_name']) ? billing[i]['invoice_buyer_name'] : '');
    billing[i]['o_price'] = this.parserFloat(this.refs[`billing_${i}_o_price`].value);
    billing[i]['others'] = this.parserFloat(this.refs[`billing_${i}_others`].value) || 0;
    billing[i]['service'] = this.parserFloat(this.refs[`billing_${i}_service`].value);
    billing[i]['paid'] = this.parserFloat(this.refs[`billing_${i}_paid`].value);
    billing[i]['service_tax'] = this.parserFloat(this.refs[`billing_${i}_service_tax`].value).toFixed(2);
    billing[i]['price'] = this.parserFloat(billpriceval).toFixed(2);
    billing[i]['bal'] = this.parserFloat(billbalval).toFixed(2);
    billing[i]['gst_amount'] = gstAmount.toFixed(2) || 0;
    billing[i]['e_paid_amount'] = (this.refs[`billing_${i}_e_paid_amount`]) ? (this.refs[`billing_${i}_e_paid_amount`].value) : billing[i]['e_paid_amount'] || 0;
    this.setState({
      billing: billing
    });
  }

  validDob = (current) => {
    var today = moment();
    return current.isBefore(today);
  }

  validFuture = (current) => {
    var today = moment();
    return current.isAfter(today);
  }

  validData = (current, name) => {
    if (name === 'date_of_expiry') {
      return this.validFuture(current);
    } else if (name === 'date_of_issue') {
      return this.validDob(current);
    }
  }

  regExpFromString(q) {
    let flags = q.replace(/.*\/([gimuy]*)$/, '$1');
    if (flags === q) flags = '';
    let pattern = (flags ? q.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1') : q);
    try { return new RegExp(pattern, flags); } catch (e) { return null; }
  }

  serviceStateForm = (e, key, stateName, isReq, regexp, regexInvalid, invalidFeedback, regstate) => {
    // let elem = e.target;
    // elem.value = e.target.value;
    let newState = (this.state.serviceState) ? this.state.serviceState : {};
    let serviceCustomer = (this.state.serviceCustomer) ? this.state.serviceCustomer : {};
    let KEY = key;//this.jsUcfirst(key);
    newState[`${key}_${stateName}`] = e.target.value;
    serviceCustomer[KEY] = (serviceCustomer[KEY]) ? serviceCustomer[KEY] : {};
    serviceCustomer[KEY]['data'] = (serviceCustomer[KEY]['data']) ? serviceCustomer[KEY]['data'] : [];
    serviceCustomer[KEY]['data'][0] = (serviceCustomer[KEY]['data'][0]) ? serviceCustomer[KEY]['data'][0] : {};
    serviceCustomer[KEY]['data'][0][key] = (serviceCustomer[KEY]['data'][0][key]) ? serviceCustomer[KEY]['data'][0][key] : {};
    serviceCustomer[KEY]['data'][0][key][stateName] = e.target.value;


    newState[`${key}_${stateName}State`] = "valid";
    if (e.target.value.trim() === "" && isReq) {
      newState[`${key}_${stateName}State`] = "invalid";
      newState[`${key}_${stateName}Feedback`] = invalidFeedback;
      newState.create = false;
    } else if (e.target.value.trim() !== "" && regexp) {
      let regex = this.regExpFromString(regexp);
      if (`${key}_${stateName}` == 'currencies_amount' || `${key}_${stateName}` == 'currencies_customer_exchange_rate' || `${key}_${stateName}` == 'currencies_eroute_rate')
        regex = /^[1-9]\d*(\.\d+)?$/;

      regex = new RegExp(regex);
      if (!regex.test(e.target.value.trim())) {
        newState[`${key}_${stateName}` + "Feedback"] = regexInvalid;
        newState[`${key}_${stateName}` + "State"] = "invalid";
        newState.create = false;
      } else {
        newState[stateName + "State"] = "valid";
      }
    }
    this.setState({ serviceCustomer: serviceCustomer, serviceState: newState });
  };

  jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  onServiceSelectChange = (value, key, idSelector, isReq) => {
    let { serviceCustomer } = this.state;
    let sVal = null;
    let sValue = null;
    if (value) {
      if (Array.isArray(value)) {
        sVal = [];
        sValue = [];
        value.forEach((v, i) => { sVal.push(v.value); sValue[v.value] = v.label; });
      } else {
        sVal = '';
        sValue = '';
        sVal = value.value;
        sValue = value.label;
      }
    }
    let newStates = (this.state.serviceState) ? this.state.serviceState : {};
    newStates[`${key}_${idSelector}`] = sVal;
    newStates[`${key}_${idSelector}Value`] = sValue;
    newStates[`${key}_${idSelector}State`] = 'is-valid';
    serviceCustomer = (serviceCustomer) ? serviceCustomer : {};
    serviceCustomer[key] = (serviceCustomer[key]) ? serviceCustomer[key] : {};
    serviceCustomer[key]['data'] = (serviceCustomer[key]['data']) ? serviceCustomer[key]['data'] : {};
    serviceCustomer[key]['data'][0] = (serviceCustomer[key]['data'][0]) ? serviceCustomer[key]['data'][0] : {};
    serviceCustomer[key]['data'][0][key] = (serviceCustomer[key]['data'][0][key]) ? serviceCustomer[key]['data'][0][key] : {};
    serviceCustomer[key]['data'][0][key][idSelector] = (value) ? value : '';

    if ((sVal == null || sVal === '') && isReq) { newStates[`${key}_${idSelector}State`] = 'is-invalid'; }

    this.setState({ serviceState: newStates, serviceCustomer: serviceCustomer });
  };

  componentWillUnmount() {
    document.querySelector('.navbar-nav .ticket-menu') && document.querySelector('.navbar-nav .ticket-menu').classList.remove('active');
  }

  onServiceDateChange = (value, key, idSelector, isReq) => {
    value = (value && value._d);
    let newStates = (this.state.serviceState) ? this.state.serviceState : {};
    newStates[`${key}_${idSelector}`] = moment(value).format("YYYY-MM-DD");
    newStates[`${key}_${idSelector}State`] = 'valid';
    if (isReq && !value)
      newStates[`${key}_${idSelector}State`] = 'invalid';
    this.setState({ serviceState: newStates });
  };

  saveServices(e, _type, _fields) {
    let keyFinded = Object.keys(_fields).length > 0 && Object.keys(_fields)[0];
    _fields = keyFinded && _fields[keyFinded];
    const { ticket, dispatch, service } = this.props;
    let { serviceState, serviceCustomer } = this.state;
    serviceState = (serviceState) ? serviceState : {};
    serviceCustomer = (serviceCustomer) ? serviceCustomer : {};
    let serviceValid = true;
    let saveService = document.querySelector(`.save${_type}Service`);
    if (saveService)
      loadingButton(saveService);
    let serviceObj = {};
    serviceObj[`${_type}`] = {};
    let _TYPE = this.jsUcfirst(_type);

    _fields && _fields.forEach((v, i) => {
      if (serviceCustomer && serviceCustomer[_TYPE] && serviceCustomer[_TYPE]['data'] && serviceCustomer[_TYPE]['data'][0][_type] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].toString() == "" && v.isReq) { serviceState[`${_type}_${v.state}`] = v.invalid; serviceValid = false; } else if ((serviceCustomer && serviceCustomer[_TYPE] && serviceCustomer[_TYPE]['data'] && serviceCustomer[_TYPE]['data'][0][_type] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`] && serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].toString() != "")) {
        if (v.regex) {
          let regex = this.regExpFromString(v.regex);
          if (serviceState[`${_type}_${v.name}`] == 'currencies_amount' || serviceState[`${_type}_${v.name}`] == 'currencies_customer_exchange_rate' || serviceState[`${_type}_${v.name}`] == 'currencies_eroute_rate')
            regex = /^[1-9]\d*(\.\d+)?$/;
          regex = new RegExp(regex);
          if (!regex.test(serviceState && serviceState[`${_type}_${v.name}`] && serviceState[`${_type}_${v.name}`].toString().trim())) {
            serviceState[`${v.name}` + "Feedback"] = v.regexInvalid;
            // serviceState[`${v.name}` + "State"] = "invalid";z
            serviceState[`${_type}_${v.state}`] = v.invalid;
            serviceValid = false;
          } else {
            serviceState[`${_type}_${v.state}`] = v.valid;
            serviceObj[`${_type}`][`${v.name}`] = isObject(serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`]) ?
              serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].id : serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`];
            if (serviceValid)
              serviceValid = true;
          }
        } else {
          serviceState[`${_type}_${v.state}`] = v.valid;
          serviceObj[`${_type}`][`${v.name}`] = isObject(serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`]) ? serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`].id : serviceCustomer[_TYPE]['data'][0][_type][`${v.name}`];
          if (serviceValid)
            serviceValid = true;
        }
      } else if ((serviceState && serviceState[`${_type}_${v.name}`] && serviceState[`${_type}_${v.name}`].toString().trim() !== "") || !v.isReq) {
        serviceState[`${_type}_${v.state}`] = v.valid;
        serviceObj[`${_type}`][`${v.name}`] = serviceState[`${_type}_${v.name}`];
        if (serviceValid)
          serviceValid = true;
      } else {
        serviceState[`${v.name}` + "State"] = "invalid";
        serviceState[`${_type}_${v.state}`] = v.invalid;
        serviceValid = false;
      }
    });

    this.setState({ serviceState: serviceState });
    if (serviceValid) {
      let saveButton = document.querySelector('.save-button');
      if (saveButton) { saveButton.click(); }
      this.insertOrUpdateServices(_type, serviceObj, _fields, serviceCustomer, _TYPE, serviceState);
    } else {
      if (saveService) { removeLoadingButton(saveService, 'Save'); }
    }
  }

  insertOrUpdateServices(_type, serviceObj, _fields, serviceCustomer, _TYPE, serviceState) {
    let saveService = document.querySelector(`.save${_type}Service`);
    const { dispatch, ticket } = this.props;
    const { e_ticket_id, e_cust_id } = this.state;
    if (saveService) {
      loadingButton(saveService, 'Save');
      serviceObj[`${_type}`].customer_id = e_cust_id;
      if (serviceCustomer && serviceCustomer[_type] && serviceCustomer[_type]['data'][0][_type] && serviceCustomer[_type]['data'][0][_type]['id']) {
        if (_type === "currencies") {
          serviceObj[`${_type}`]['ticket_id'] = e_ticket_id;
          serviceObj[`${_type}`]['currency_type'] = serviceState[`${_type}_currency_type`];
          serviceObj[`${_type}`]['handled_by'] = serviceState[`${_type}_handled_by`];

          var buy_sell = 'Buy';
          let buy_sellData = document.querySelector('.toggle_currencies_buy_sell');
          if (buy_sellData && buy_sellData.checked) {
            buy_sell = 'Sell';
          }
          serviceObj[`${_type}`]['buy_sell'] = buy_sell;
        }

        serviceObj[`${_type}`].status = (serviceState[`${_type}_status`]) ? serviceState[`${_type}_status`] : serviceObj[`${_type}`].status;
        serviceObj[`${_type}`].id = serviceCustomer[_type]['data'][0][_type]['id'];
        dispatch(serviceActions.updateAction(_type, serviceObj[`${_type}`])).then(() => {
          removeLoadingButton(saveService, 'Save');
        });
      } else {

        if (_type === "currencies") {
          serviceObj[`${_type}`]['ticket_id'] = e_ticket_id;
          var buy_sell = 'Buy';
          let buy_sellData = document.querySelector('.toggle_currencies_buy_sell');
          if (buy_sellData && buy_sellData.checked) {
            buy_sell = 'Sell';
          }
          serviceObj[`${_type}`]['buy_sell'] = buy_sell;
        }
        dispatch(serviceActions.createAction(_type, serviceObj[`${_type}`])).then(() => {
          const { services } = this.props;
          const { data } = services.services;
          const { id } = data;
          serviceCustomer[_type]['data'][0][_type]['id'] = id;
          this.setState({ serviceCustomer: serviceCustomer });
          removeLoadingButton(saveService, 'Save');
        });
      }
    }
  }

  validEndData2 = (current) => {
    const { startDate_places_init } = this.state;
    var today = moment(startDate_places_init);
    if (!startDate_places_init)
      return current.isAfter(today);
    today = moment(startDate_places_init);
    return current.isAfter(today);
  }

  validStartData2 = (current) => {
    const { endDate_places_init } = this.state;
    var today = moment();
    if (!endDate_places_init)
      return current.isAfter(today);
    today = moment(endDate_places_init);
    return current.isBefore(today);
  }

  validFinStartDate = (current, _endDate) => {
    let today = moment();
    let todays = '';
    if (moment(_endDate)._isValid && moment(_endDate) !== today) {
      todays = moment(_endDate);
    } else
      return current.isAfter(today);
    return current.isAfter(today) && current.isBefore(todays);
  }
  validFinEndDate = (current, _startDate) => {
    let today = moment();
    let todays = '';
    if (moment(_startDate)._i != "" && moment(_startDate) != today) {
      todays = moment(_startDate);
    }
    return current.isAfter(today) && current.isAfter(todays);
  }
  initializeTour(e) {
    let elem = e.target;
    loadingButton(elem);
    let tour_tour_id = document.querySelector('.tour_tour_id');
    let tour_id = document.querySelector('.tour_id_init');
    let select_tour_themes = document.querySelector('.select_tour_themes');
    let select_group_type = document.querySelector('.select_group_type');
    let ind_grp_pay = document.querySelector('.ind_grp_pay');
    let group_count = document.querySelector('.group_count');
    removeInvalid(select_tour_themes);
    removeInvalid(select_group_type);
    removeInvalid(group_count);
    let grpType = true;
    if (this.state.select_tour_themes && this.state.select_tour_themes.value === 2)
      grpType = select_group_type.childNodes[1].value;

    if (select_tour_themes.childNodes[1].value && grpType && parseInt(group_count.value) > 0) {
      const { e_ticket_id } = this.state;
      const { dispatch } = this.props;
      let tourInsertData = {
        "ticket_id": e_ticket_id,
        "tour_id": tour_id.value,
        "tour_themes": select_tour_themes.childNodes[1].value,
        "group_count": parseInt(group_count.value),
        "group_type": (this.state.select_tour_themes && this.state.select_tour_themes.value === 2) ? select_group_type.childNodes[1].value : '',
        "pay_type": (ind_grp_pay.checked) ? 0 : 1
      }
      if (tour_tour_id.value.trim() != "") {
        tourInsertData["id"] = tour_tour_id.value;
        dispatch(userActions.updateTour(tourInsertData)).then(() => {
          removeLoadingButton(elem, "Go");
          const { tourUpdate } = this.props;
          const { data } = tourUpdate;
          const { tour } = data;
          if (tour) {
            this.notify("tc", "", `Tour Details has been updated successfully.`);
          }
        });
      } else {
        dispatch(userActions.createTour(tourInsertData)).then(() => {
          removeLoadingButton(elem, "Go");
          const { tourCreate } = this.props;
          const { data } = tourCreate;
          const { tour } = data;
          if (tour) {
            this.setState({ group_count: parseInt(group_count.value), tour_tour_id: tour.id });
            this.notify("tc", "", `Tour Details has been created successfully.`);
          }
        });
      }
    } else {
      removeLoadingButton(elem, "Go");
      if (select_tour_themes.childNodes[1].value === "")
        addInvalid(select_tour_themes);
      if (select_group_type.childNodes[1].value === "")
        addInvalid(select_group_type);
      if (group_count.value === "" || parseInt(group_count.value) < 0)
        addInvalid(group_count);
    }
  }

  saveTour(e) {
    let elem = e.target;
    loadingButton(elem);
    let save = true;
    let grpRows = document.querySelectorAll('.grpRow_tc');
    let grpCount = {};
    grpRows.forEach((v, i) => {
      let unit = i + 1;
      let grpTemp = document.querySelector(`.group_name_${unit}`);
      let mcTemp = document.querySelector(`.member_count_${unit}`);

      grpTemp && removeInvalid(grpTemp);
      mcTemp && removeInvalid(mcTemp);
      if (grpTemp && grpTemp.value.trim() !== "" && mcTemp && mcTemp.value.trim() !== "") {
        if (!/^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _,.&]*)$/.test(grpTemp.value)) {
          save = false;
          addInvalid(grpTemp);
        }
        if (!/^[1-9]\d*(d+)?$/.test(mcTemp.value)) {
          save = false;
          addInvalid(mcTemp);
        }

        grpCount[i] = { group_count: parseInt(mcTemp.value.trim()), group_name: grpTemp.value.trim() };
      } else {
        save = false;
        if (grpTemp && grpTemp.value.trim() === "") {
          addInvalid(grpTemp);
        }
        if (mcTemp && mcTemp.value.trim() === "") {
          addInvalid(mcTemp);
        }
      }
    });

    if (save) {
      let tour_tour_id = document.querySelector('.tour_tour_id');
      let tour_id = document.querySelector('.tour_id_init');
      let select_tour_themes = document.querySelector('.select_tour_themes');
      let select_group_type = document.querySelector('.select_group_type');
      let ind_grp_pay = document.querySelector('.ind_grp_pay');
      let group_count = document.querySelector('.group_count');
      const { e_ticket_id } = this.state;
      const { tourCountlist, tourCountCreate, dispatch } = this.props;
      let tourInsertData = {
        "ticket_id": e_ticket_id,
        "tour_id": tour_id.value,
        "tour_themes": select_tour_themes.childNodes[1].value,
        "group_count": parseInt(group_count.value),
        "group_type": select_group_type.childNodes[1].value,
        "pay_type": (ind_grp_pay.checked) ? 0 : 1
      }
      if (tour_tour_id.value.trim() != "") {
        tourInsertData["id"] = tour_tour_id.value;
        dispatch(userActions.updateTour(tourInsertData)).then(() => {
          removeLoadingButton(elem, "Go");
          const { tourUpdate } = this.props;
          const { data } = tourUpdate;
          const { tour } = data;
          if (tour) {
            this.notify("tc", "", `Tour Details has been updated successfully.`);
          }
        });
      }

      let groupInsertData = {
        tour_id: tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].id || tourCountCreate && tourCountCreate.data && tourCountCreate.data.tour.id,
        group_counts: grpCount
      }

      if (tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].group_count && tourCountlist.data.data[0].group_count.length > 0) {
        dispatch(userActions.updateGroupCount(groupInsertData)).then(() => {
          removeLoadingButton(elem, "Save");
          let { group_data } = this.state;
          const { updateGroupCount } = this.props;
          const { data } = updateGroupCount;
          const { group_count } = data;
          if (group_count) {
            group_count.forEach((v, i) => {
              if (v.id) {
                // let tempGrpId = document.querySelectorAll(`.grpRow_tc`)[i].children[0].children[0];
                // if(tempGrpId){
                //   tempGrpId.value = v.id;
                group_data = (group_data) ? group_data : {};
                group_data[i + 1] = (group_data[i + 1]) ? group_data[i + 1] : {};
                group_data[i + 1]['group_id'] = v.id;
                // }
              }
            });
            this.setState({ group_data: group_data });
            this.notify("tc", "", `Groups has been updated successfully.`);
          }
        });
      } else {
        dispatch(userActions.createGroupCount(groupInsertData)).then(() => {
          removeLoadingButton(elem, "Save");
          let { group_data } = this.state;
          const { createGroupCount } = this.props;
          const { data } = createGroupCount;
          const { group_count } = data;
          if (group_count) {
            group_count.forEach((v, i) => {
              if (v.id) {
                // let tempGrpId = document.querySelectorAll(`.grpRow_tc`)[i].children[0].children[0];
                // if(tempGrpId){
                //   tempGrpId.value = v.id;
                group_data = (group_data) ? group_data : {};
                group_data[i + 1] = (group_data[i + 1]) ? group_data[i + 1] : {};
                group_data[i + 1]['group_id'] = v.id;
                // }
              }
            });
            this.setState({ group_data: group_data });
            this.notify("tc", "", `Groups has been created successfully.`);
          }
        });
      }
    } else {
      removeLoadingButton(elem, "Save");
    }
    // groupcountinsert
  }

  saveTourPlaces(e) {
    let elem = (e.target) ? e.target : e;
    loadingButton(elem);
    let tourPlacesData = {};
    document.querySelectorAll('.tourRow_st').forEach((v, i) => {
      let places = document.querySelector(`.places_tour_${i}`);
      let endDate = document.querySelector(`.end_date_tour_${i}`);
      let startDate = document.querySelector(`.start_date_tour_${i}`);
      let tourRowId = document.querySelector(`.tourRow_id_${i}`);
      if (startDate.value && endDate.value && places.childNodes[1].value) {
        tourPlacesData[i] = {
          "start_date": startDate.value,
          "end_date": endDate.value,
          "place_id": places.childNodes[1].value
        }
      }
    });

    if (tourPlacesData) {
      const { tourCountCreate, tourCountlist } = this.props;
      let tourPlacesCreateData = {
        tour_id: tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].id || tourCountCreate && tourCountCreate.data && tourCountCreate.data.tour.id,
        tour_places: tourPlacesData
      }
      const { dispatch } = this.props;
      if (tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].tour_places && tourCountlist.data.data[0].tour_places.length > 0) {
        dispatch(userActions.updateTourPlaces(tourPlacesCreateData)).then(() => {
          const { tourPlacesUpdate } = this.props;
          this.notify("tc", "", `Places has been updated successfully.`);
          removeLoadingButton(elem, "Save");
        });
      } else {
        dispatch(userActions.createTourPlaces(tourPlacesCreateData)).then(() => {
          const { tourPlacesCreate } = this.props;
          if (isObject(tourCountCreate) && Object.keys(tourCountCreate).length > 0) {
            this.notify("tc", "", `Places has been created successfully.`);
          }
          removeLoadingButton(elem, "Save");
        });
      }
    } else {
      removeLoadingButton(elem, "Save");
    }
  }

  showPlaceRows(e) {
    let elem = (e.target) ? e.target : e;
    loadingButton(elem);
    let tour_id = document.querySelector('.tour_id_init');
    let place_id = document.querySelector('.places_tour_init');
    let select_tour_type = document.querySelector('.select_tour_type_init');
    let foodPlaces = document.querySelector('.food_places_init');
    let startDate = document.querySelector('.start_date_tour_places_init');
    // let startDatePrev = startDate.previousSibling;
    let endDate = document.querySelector('.end_date_tour_places_init');
    // let endDatePrev = endDate.previousSibling;
    removeInvalid(startDate);
    // removeInvalid(startDatePrev);
    removeInvalid(endDate);
    // removeInvalid(endDatePrev);
    removeInvalid(select_tour_type);
    removeInvalid(place_id);
    if (this.state.endDate_places_init && this.state.startDate_places_init && select_tour_type.childNodes[1].value && place_id.childNodes[1].value) {
      const { dispatch } = this.props;
      const { e_cust_id, e_ticket_id } = this.state;
      if (e_ticket_id && e_cust_id) {
        let tourCountCreateData = {
          "tour_type": select_tour_type.childNodes[1].value,
          "ticket_id": e_ticket_id,
          "place_id": place_id.childNodes[1].value,
          "customer_id": e_cust_id,
          "start_date": this.state.startDate_places_init,
          "end_date": this.state.endDate_places_init,
          "food_by": (foodPlaces.checked) ? 1 : 0
        }
        if (tour_id.value) {
          tourCountCreateData['id'] = tour_id.value;
          dispatch(userActions.updateTourCount(tourCountCreateData)).then(() => {
            removeLoadingButton(elem, "Save");
            const { tourCountUpdate } = this.props;
            const { data } = tourCountUpdate;
            const { tour } = data;
            if (tour) {
              this.notify("tc", "", `Places Management updated successfully.`);
              let saveTourPlaces = document.querySelector('.save-tour-places');
              this.saveTourPlaces(saveTourPlaces);
            }
          });
        } else {
          dispatch(userActions.createTourCount(tourCountCreateData)).then(() => {
            removeLoadingButton(elem, "Save");
            const { tourCountCreate } = this.props;
            const { data } = tourCountCreate;
            const { tour } = data;
            if (tour) {
              tour_id.value = tour.id;
              this.setState({ tour_id_init: tour.id });
              this.setState({ showTourRows: true });
              this.notify("tc", "", `Places Management created successfully.`);
              let saveTourPlaces = document.querySelector('.save-tour-places')
              this.saveTourPlaces(saveTourPlaces);
            }
          });
        }
      }
    } else {
      removeLoadingButton(elem, "Save");
      if (select_tour_type.childNodes[1].value.trim() === "") {
        addInvalid(select_tour_type);
      }
      if (place_id.childNodes[1].value.trim() === "") {
        addInvalid(place_id);
      }
      if (startDate.value.trim() === "") {
        addInvalid(startDate);
        // addInvalid(startDatePrev);
      } if (endDate.value.trim() === "") {
        addInvalid(endDate);
        // addInvalid(endDatePrev);
      }
    }
  }

  createGroup(e, unit) {
    let elem = e.target;
    loadingButton(elem);
    const { tourCountlist, tourCountCreate, dispatch } = this.props;
    let gnTemp = document.querySelector(`.group_name_${unit}`);
    let mcTemp = document.querySelector(`.member_count_${unit}`);
    let grpId = document.querySelector(`.group_id_${unit}`);
    let tour_id = tourCountlist && tourCountlist.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].id || tourCountCreate && tourCountCreate.data && tourCountCreate.data.tour.id;

    removeInvalid(gnTemp);
    removeInvalid(mcTemp);
    if (mcTemp.value && !isNaN(mcTemp.value) && gnTemp.value) {
      dispatch(userActions.tourGroupsList(grpId.value, tour_id)).then(() => {
        this.toggleFullModal(gnTemp.value, mcTemp.value, grpId.value, unit);
        removeLoadingButton(elem, "Go");
      });
    } else {
      removeLoadingButton(elem, "Go");
      if (isNaN(mcTemp.value) || mcTemp.value === '')
        addInvalid(mcTemp);
      if (gnTemp.value === '')
        addInvalid(gnTemp);
    }
  }

  handleBillingTourInputsChange(i, e, v, action) {
    let { billing, grpOverAllTotalInd, grpOverAllTotal, services_temp_arr, specialState } = this.state;
    grpOverAllTotalInd = (grpOverAllTotalInd) ? grpOverAllTotalInd : 0;
    grpOverAllTotal = (grpOverAllTotal) ? grpOverAllTotal : 0;

    let services_ticket = services_temp_arr;

    let SpecialPrice = 0, GroupIndPrice = (this.state.pay_type) ? grpOverAllTotalInd : grpOverAllTotal, FoodVendorPrice = 0, HotelPrice = 0, VehiclePrice = 0, OtaTotal = 0;
    services_ticket && isArray(services_ticket) && services_ticket.forEach((v, i) => {
      if (v.services && v.services[0] && v.services[0].name && v.services[0].name.toLowerCase().trim() === "tour") {
        specialState && specialState.ticket && specialState.ticket.data && specialState.ticket.data.forEach(special => {
          SpecialPrice = SpecialPrice + this.parserFloat(special.customer_paid);
        });
        this.state.expenses_data && Object.keys(this.state.expenses_data).forEach(expenses => {
          FoodVendorPrice = FoodVendorPrice + this.parserFloat(this.state.expenses_data[expenses].expenses.amount);
        });
        this.state.expenses_data1 && Object.keys(this.state.expenses_data1).forEach(expenses1 => {
          HotelPrice = HotelPrice + this.parserFloat(this.state.expenses_data1[expenses1].expenses.amount);
        });
        SpecialPrice = this.parserFloat(SpecialPrice).toFixed(2);
        GroupIndPrice = this.parserFloat(GroupIndPrice).toFixed(2);
        FoodVendorPrice = this.parserFloat(FoodVendorPrice).toFixed(2);
        HotelPrice = this.parserFloat(HotelPrice).toFixed(2);
        VehiclePrice = this.parserFloat(VehiclePrice).toFixed(2);
        OtaTotal = this.parserFloat(this.parserFloat(SpecialPrice) + this.parserFloat(GroupIndPrice) + this.parserFloat(FoodVendorPrice) + this.parserFloat(HotelPrice) + this.parserFloat(VehiclePrice)).toFixed(2);
      }
    });
    billing = (billing) ? billing : {};
    billing[i] = (billing[i]) ? billing[i] : {};
    billing[i]['invoice_buyer_name'] = '';
    billing[i]['others'] = 0;
    billing[i]['price'] = this.parserFloat(e.currentTarget.value);
    billing[i]['service'] = 0;
    billing[i]['service_tax'] = 0;
    billing[i]['e_paid_amount'] = 0;
    billing[i]['gst_amount'] = 0;
    billing[i]['paid'] = OtaTotal;
    billing[i]['o_price'] = 0;
    billing[i]['bal'] = this.parserFloat(this.parserFloat(OtaTotal) - this.parserFloat(e.currentTarget.value)).toFixed(2);
    this.setState({ billing: billing });
  }

  hotel_save(elemClass) {
    let elem = document.querySelector(`${elemClass}`);
    const { dispatch } = this.props;
    let customerId = this.state.e_cust_id;

    if (elem)
      loadingButton(elem);
    let rows = document.querySelectorAll(`.hotelRow_st`);
    let rowSave = true;
    let rowDataInsert = {
      "ticket_id": this.state.e_ticket_id,
      "customer_id": customerId,
      "tour_id": "0",
      "category": 'hotel',
      "food_expenses": {}
    };
    var tempStartDate = '';
    var tempEndDate = '';
    var tempPlace = '';
    var tempVendor = '';
    var tempAmount = '';
    var tempHotelname = '';

    rows.forEach((v, i) => {
      let tempStartDate = document.querySelectorAll(`.hotelRow_st`)[i].children[0].children[3].children[0];
      let tempEndDate = document.querySelectorAll(`.hotelRow_st`)[i].children[1].children[0].children[0];
      let tempPlace = document.querySelectorAll(`.hotelRow_st`)[i].children[2].children[0].childNodes[1];
      let tempVendor = document.querySelectorAll(`.hotelRow_st`)[i].children[3].children[0].childNodes[1];
      let tempAmount = document.querySelectorAll(`.hotelRow_st`)[i].children[5].children[0].children[0];
      let tempHotelname = document.querySelectorAll(`.hotelRow_st`)[i].children[4].children[0];
      let rowsLen = document.querySelectorAll(`.hotelRow_st`).length;
      let gethiddenclass = document.querySelectorAll(`.hotelRow_st`)[i].children[0].children[1];
      //let isLastRow = rowsLen === i + 1 && document.querySelector(`.endDate_hotel_${rowsLen - 2}`).value === this.state.endDate_places_init;

      if (tempHotelname.value != '' && tempAmount.value != '') {
        var regex = new RegExp(/^(?:[A-Za-z]+)(?:[A-Za-z ]*)$/);
        var regex2 = new RegExp(/^\d{0,12}(\.\d{1,2})?$/);
        if (!regex.test(tempHotelname.value.trim())) {
          var namestatus = true;
        }
        else if (!regex2.test(tempAmount.value.trim())) {
          var amtstatus = true;
        }
      }

      if ((tempStartDate.value != '' || tempEndDate.value != '') && tempHotelname.value != '' && tempPlace.value != '' && tempVendor.value != '' && tempAmount.value != '' && !namestatus && !amtstatus) {
        var startdate = tempStartDate.value;
        var enddate = tempEndDate.value;

        if (tempStartDate.value != '' && tempEndDate.value == '') {
          enddate = tempStartDate.value;
        } else if (tempEndDate.value != '' && tempStartDate.value == '') {
          startdate = tempEndDate.value;
        }
        rowDataInsert.food_expenses[i] = {
          "start_date": startdate,
          "end_date": enddate,
          "place_id": tempPlace.value,
          "vendor_id": tempVendor.value,
          "amount": tempAmount.value,
          "hotel_name": tempHotelname.value,
          "gethidden_value": gethiddenclass.value
        };

      } else {
        if (elem)
          removeLoadingButton(elem, "Save");
        rowSave = false;
      }

      if (tempStartDate.value === "" && tempEndDate.value === "") {

        addInvalid(tempStartDate);
        addInvalid(tempEndDate);
      }

      if (tempPlace.value === "")
        addInvalid(tempPlace);
      if (tempVendor.value === "")
        addInvalid(tempVendor);
      if (tempAmount.value === "" || amtstatus)
        addInvalid(tempAmount);
      if (tempHotelname.value === "" || namestatus)
        addInvalid(tempHotelname);

    });

    if (rowSave) {
      const { hotelExpensesInsert } = this.props;
      const { expenses_data1 } = this.state;

      if (((hotelExpensesInsert && hotelExpensesInsert.data && hotelExpensesInsert.data.food
        && hotelExpensesInsert.data.food.length > 0) ||
        (expenses_data1 && expenses_data1[0]['expenses'] && expenses_data1[0]['expenses']['id'] && expenses_data1[0]['expenses']['id'] != ""))) {
        dispatch(userActions.hotelExpensesUpdate(rowDataInsert, 'hotel')).then(() => {
          const { expenses_data2 } = (this.state.expenses_data2) ? this.state : { expenses_data2: {} };
          let ob2 = this.state.expenses_data1;
          var hoepaidamout = 0;
          var hiddenfact = rowDataInsert.food_expenses;
          this.props.hotelExpensesUpdate.data.getup.forEach((v, i) => {
            hoepaidamout = hoepaidamout + parseFloat(v.amount);
            expenses_data2[hiddenfact[i]['gethidden_value']] = { expenses: v };
          });
          this.setState({ expenses_data1: expenses_data2, update_data: 3 });
          this.state && this.state.billing && this.state.billing.forEach((va, i) => {
            if (va.service_id == 6) {
              va.e_paid_amount = hoepaidamout;
              let packageamount = 0;
              if (va.price != null || va.gst_amount != null) {
                if (va.price == null || va.price == undefined) { va.price = 0; }
                else if (va.gst_amount == null || va.gst_amount == undefined) { va.gst_amount = 0; }
                packageamount = parseFloat(va.price) + parseFloat(va.gst_amount);
              }

              let billlingup = {
                'id': va.id,
                'ticket_id': this.state.e_ticket_id,
                'customer_id': customerId,
                'service_id': va.service_id,
                'package_amount': this.parserFloat(packageamount),
                'service_charge': (va.service == null) ? 0 : va.service,
                'service_tax_amount': (va.service_tax == null) ? 0 : va.service_tax,
                'amount_received': (va.paid == null) ? 0 : va.paid,
                'amount_pending': (va.bal == null) ? 0 : va.bal,
                'e_paid_amount': hoepaidamout,
                'other_amount': va.others,
                'gst_amount': (Number(va.gst_amount) == NaN) ? 0 : va.gst_amount,
                'e_mop': va.mop,
                'invoice_to': va.invoice_to,
                'amount_paid_by': va.amount_paid_by,
                'vendor_name': "",
                'invoice_buyer_name': va.invoice_buyer_name
              };

              dispatch(userActions.updateBilling(billlingup));
            }

          });
          if (elem)
            removeLoadingButton(elem, "Save");
          this.notify("tc", "", "Hotel has been updated successfully");
        });
      } else {
        dispatch(userActions.hotelExpensesInsert(rowDataInsert, 'hotel')).then(() => {
          const { expenses_datas } = (this.state.expenses_datas) ? this.state : { expenses_datas: {} };
          var data1 = this.props.hotelExpensesInsert.data.food;
          var hoepaidamout = 0;
          var hiddenfact = rowDataInsert.food_expenses;
          data1.forEach((v, i) => { hoepaidamout = hoepaidamout + parseFloat(v.amount); expenses_datas[hiddenfact[i]['gethidden_value']] = { expenses: v }; });
          this.setState({ update_data: 3, expenses_data1: expenses_datas, });
          let { billing } = this.state;
          let billdata = {
            'ticket_id': this.state.e_ticket_id,
            'customer_id': customerId,
            'service_id': 6,
            'package_amount': 0,
            'service_charge': 0,
            'service_tax_amount': 0,
            'amount_received': 0,
            'amount_pending': 0,
            'e_paid_amount': hoepaidamout,
            'other_amount': 0
          };
          billing && billing.forEach((va, i) => {
            if (va.service_id == 6) {
              let billlingup1 = {
                'id': va.id,
                'ticket_id': this.state.e_ticket_id,
                'customer_id': customerId,
                'service_id': va.service_id,
                'package_amount': this.parserFloat(Number(va.price) + Number(va.gst_amount)),
                'service_charge': va.service,
                'service_tax_amount': va.service_tax,
                'amount_received': va.paid,
                'amount_pending': va.bal,
                'e_paid_amount': hoepaidamout,
                'other_amount': va.others,
                'gst_amount': this.parserFloat(Number(va.gst_amount)),
                'e_mop': va.mop,
                'invoice_to': va.invoice_to,
                'amount_paid_by': va.amount_paid_by,
                'vendor_name': "",
                'invoice_buyer_name': va.invoice_buyer_name
              };

              dispatch(userActions.updateBilling(billlingup1));
              // if(va.id)
              //   billing[i].e_paid_amount = hoepaidamout;
              // else {
              //   dispatch(userActions.createBilling(billlingup1)).then(()=>{
              //     billing[i] = (billing[i]) ? billing[i] : {};
              //     billing[i]['id'] = this.props.billing.document.data.id;
              //     billing[i]['service_id'] = 6;
              //     billing[i]['e_paid_amount'] = hoepaidamout;
              //   });
              // }
            }
          });
          if (!this.state.getid) {
            dispatch(userActions.createBilling(billdata)).then(() => {
              var getbillstate = this.state.billing;
              var arr = { 'id': this.props.billing.document.data.id, 'service_id': 6, 'e_paid_amount': hoepaidamout, };
              getbillstate.push(arr);
              this.setState({ billing: getbillstate });
            });
          }
          if (elem)
            removeLoadingButton(elem, "Save");
          this.notify("tc", "", "Hotel has been created successfully");
        });
      }
    }
  }

  ontogglehandlechange(e, key, name, selector) {
    let { serviceState } = this.state;
    let newstate = (serviceState) ? serviceState : {};
    newstate[`${key}_${name}`] = e.target.checked;
    newstate[`${key}_${name}Value`] = name;
    newstate[`${key}_${name}State`] = 'is-valid';
    this.setState({ serviceState: newstate });
  }

  toggleCommentArea = e => {
    let target = e.currentTarget;
    let mainItem = e.currentTarget.previousSibling;
    let sideItem = e.currentTarget.nextSibling;
    if (mainItem.className.indexOf('col-lg-12') === -1) {
      target.childNodes[0].classList.remove('fa-chevron-right');
      target.childNodes[0].classList.add('fa-chevron-left');
      sideItem.classList.add('w-0');
      mainItem.classList.remove('col-lg-8');
      mainItem.classList.add('col-lg-12');
    } else {
      target.childNodes[0].classList.remove('fa-chevron-left');
      target.childNodes[0].classList.add('fa-chevron-right');
      sideItem.classList.remove('w-0');
      mainItem.classList.remove('col-lg-12');
      mainItem.classList.add('col-lg-8');
    }
  }

  slideInputLeft(e, reactionClass, triggerClass) {
    e.preventDefault();
    let reactionElem = document.querySelector(reactionClass);
    let triggerElem = document.querySelector(triggerClass);
    if (triggerElem) {
      triggerElem.classList.toggle('d-flex');
      triggerElem.classList.toggle('d-none');
    } if (reactionElem) {
      reactionElem.classList.toggle('inputSlideLeft');
      reactionElem.classList.toggle('d-none');
    }
  }

  setStatesModalFull(value) {
    const { active, address, city, dob, email, firstname, id, lastname, passport, phone, secondary, state, visa, zipcode } = value;
    const customerId = id;

    let { ticketCreation, e_ticket_id, serviceCustomer, serviceState } = this.state;
    if (passport) {
      const { id, nationality, passport_no, passport_purpose, place_of_birth, place_of_issue, date_of_issue, date_of_expiry, status } = passport;
      const passportId = id;
      let statusId = '';
      let statusName = '';
      let passportPurposeId = '';
      let passportPurposeName = '';
      if (passport_purpose) {
        const { id, name } = passport_purpose;
        passportPurposeId = id;
        passportPurposeName = name;
      }
      if (status) {
        const { id, name } = status;
        statusId = id;
        statusName = name;
      }

      serviceCustomer = (serviceCustomer) ? serviceCustomer : {};
      serviceCustomer['passport'] = (serviceCustomer['passport']) ? serviceCustomer['passport'] : {};
      serviceCustomer['passport']['data'] = (serviceCustomer['passport']['data']) ? serviceCustomer['passport']['data'] : {};
      serviceCustomer['passport']['data'][0] = (serviceCustomer['passport']['data'][0]) ? serviceCustomer['passport']['data'][0] : {};
      serviceCustomer['passport']['data'][0]['passport'] = (serviceCustomer['passport']['data'][0]['passport']) ? serviceCustomer['passport']['data'][0]['passport'] : {};

      serviceCustomer['passport']['data'][0]['passport'][`id`] = passportId;
      serviceCustomer['passport']['data'][0]['passport'][`nationality`] = nationality;
      serviceCustomer['passport']['data'][0]['passport'][`passport_no`] = passport_no;
      serviceCustomer['passport']['data'][0]['passport'][`place_of_birth`] = place_of_birth;
      serviceCustomer['passport']['data'][0]['passport'][`date_of_expiry`] = date_of_expiry;
      serviceCustomer['passport']['data'][0]['passport'][`date_of_issue`] = date_of_issue;
      serviceCustomer['passport']['data'][0]['passport'][`place_of_issue`] = place_of_issue;
      serviceCustomer['passport']['data'][0]['passport'][`status`] = (status) ? { value: statusId, label: statusName } : '';
      serviceCustomer['passport']['data'][0]['passport'][`passport_purpose`] = (passport_purpose) ? { value: passportPurposeId, label: passportPurposeName } : '';


      serviceState = (serviceState) ? serviceState : {};
      serviceState[`${'passport'}_${`nationalityState`}`] = nationality ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`nationality`}`] = nationality;
      serviceState[`${'passport'}_${`statusState`}`] = (status) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`status`}`] = (status) ? statusId : '';
      serviceState[`${'passport'}_${`passport_noState`}`] = (passport_no) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`passport_no`}`] = passport_no;
      serviceState[`${'passport'}_${`place_of_birthState`}`] = (place_of_birth) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`place_of_birth`}`] = place_of_birth;
      serviceState[`${'passport'}_${`date_of_expiryState`}`] = (date_of_expiry) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`date_of_expiry`}`] = date_of_expiry;
      serviceState[`${'passport'}_${`date_of_issueState`}`] = (date_of_issue) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`date_of_issue`}`] = date_of_issue;
      serviceState[`${'passport'}_${`place_of_issueState`}`] = (place_of_issue) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`place_of_issue`}`] = place_of_issue;
      serviceState[`${'passport'}_${`passport_purposeState`}`] = (passport_purpose) ? 'valid' : 'invalid';
      serviceState[`${'passport'}_${`passport_purpose`}`] = (passport_purpose) ? passportPurposeId : '';
    }

    // if(visa){
    //   const { country_destination, courier_number, email_id, entry, id, no_of_days, status, visa_type, filed_by, vendor_id, vendor } = visa;
    //   const visaId = id;
    //   let statusId = '';
    //   let statusName = '';
    //   let visaTypeId = '';
    //   let visaTypeName = '';
    //   let vendorId = '';
    //   let vendorName = '';
    //   if(visa_type){
    //     const { id, name } = visa_type;
    //     visaTypeId = id;
    //     visaTypeName = name;
    //   }
    //   if(status){
    //     const { id, name } = status;
    //     statusId = id;
    //     statusName = name;
    //   }
    //   if(vendor){
    //     const { id, name } = vendor;
    //     vendorId = id;
    //     vendorName = name;
    //   }

    //   // vendor_id


    //   serviceCustomer = (serviceCustomer) ? serviceCustomer : {};
    //   serviceCustomer['visa'] = (serviceCustomer['visa']) ? serviceCustomer['visa'] : {};
    //   serviceCustomer['visa']['data'] = (serviceCustomer['visa']['data']) ? serviceCustomer['visa']['data'] : {};
    //   serviceCustomer['visa']['data'][0] = (serviceCustomer['visa']['data'][0]) ? serviceCustomer['visa']['data'][0] : {};
    //   serviceCustomer['visa']['data'][0]['visa'] = (serviceCustomer['visa']['data'][0]['visa']) ? serviceCustomer['visa']['data'][0]['visa'] : {};
    //   serviceCustomer['visa']['data'][0]['visa'][`id`] = visaId;
    //   serviceCustomer['visa']['data'][0]['visa'][`country_destination`] = country_destination;
    //   serviceCustomer['visa']['data'][0]['visa'][`courier_number`] = courier_number;
    //   serviceCustomer['visa']['data'][0]['visa'][`email_id`] = email_id;
    //   serviceCustomer['visa']['data'][0]['visa'][`entry`] = entry;
    //   serviceCustomer['visa']['data'][0]['visa'][`no_of_days`] = no_of_days;
    //   serviceCustomer['visa']['data'][0]['visa'][`status`] = (status) ? { value: statusId, label: statusName } : '';
    //   serviceCustomer['visa']['data'][0]['visa'][`visa_type_id`] = (visa_type) ? { value: visaTypeId, label: visaTypeName } : '';
    //   serviceCustomer['visa']['data'][0]['visa'][`filed_by`] = (filed_by === "e-route") ? { value: "e-route", label: "E-route" } : { value: "vendor", label: "Vendor" };
    //   serviceCustomer['visa']['data'][0]['visa'][`vendor_id`] = (filed_by === "e-route") ? '' : { value: vendorId, label: vendorName }

    //   serviceState = (serviceState) ? serviceState : {};
    //   serviceState[`${'visa'}_${`country_destinationState`}`] = country_destination ? 'valid' : 'invalid';
    //   serviceState[`${'visa'}_${`country_destination`}`] = country_destination;
    //   serviceState[`${'visa'}_${`statusState`}`] = (status) ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`status`}`] = (status) ? statusId : '';
    //   serviceState[`${'visa'}_${`courier_numberState`}`] = (courier_number) ? 'valid' : 'invalid';
    //   serviceState[`${'visa'}_${`courier_number`}`] = courier_number;
    //   serviceState[`${'visa'}_${`email_idState`}`] = (email_id) ? 'valid' : 'invalid';
    //   serviceState[`${'visa'}_${`email_id`}`] = email_id;
    //   serviceState[`${'visa'}_${`visa_type_idState`}`] = (visa_type) ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`visa_type_id`}`] = (visa_type) ? visaTypeId : '';
    //   serviceState[`${'visa'}_${`filed_byState`}`] = (filed_by) ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`filed_by`}`] = filed_by;
    //   serviceState[`${'visa'}_${`vendor_idState`}`] = (vendor) ? 'is-valid' : (filed_by === "e-route") ? 'is-valid' : 'is-invalid';
    //   serviceState[`${'visa'}_${`vendor_id`}`] = (vendor) ? vendorId : '';

    //   serviceState[`${'visa'}_${`entryState`}`] = (entry) ? 'valid' : 'valid';
    //   serviceState[`${'visa'}_${`entry`}`] = entry;
    //   serviceState[`${'visa'}_${`no_of_daysState`}`] = (no_of_days) ? 'valid' : 'valid';
    //   serviceState[`${'visa'}_${`no_of_days`}`] = no_of_days;
    // }

    _ticketCreate.forEach((v, i) => {
      if (value && value[v.name]) {
        let val = value[v.name].toString().trim();
        ticketCreation[v.name] = val;
        ticketCreation[`${v.state}`] = v.valid;

        if (v.isReq && val === "") {
          ticketCreation[`${v.name}Feedback`] = v.invalidFeedback;
          ticketCreation[`${v.state}`] = v.invalid;
        }

        if (v.regex && val !== "") {
          var regex = new RegExp(v.regex);
          if (!regex.test(val)) {
            ticketCreation[`${v.name}Feedback`] = v.regexInvalid;
            ticketCreation[`${v.state}`] = v.invalid;
          }
        }
      } else if ((!ticketCreation[v.name] || ticketCreation[v.name].toString().trim() === "") && v.isReq) {
        ticketCreation[v.name] = "";
        ticketCreation[`${v.state}`] = v.invalid;
        ticketCreation[`${v.name}Feedback`] = v.invalidFeedback;
      } else {
        ticketCreation[v.name] = "";
        ticketCreation[`${v.state}`] = v.valid;
      }
    });
    if (!e_ticket_id)
      ticketCreation[`status`] = { value: 1, label: 'Open' };

    this.setState({ ticketCreation: ticketCreation, e_cust_id: customerId, serviceCustomer: serviceCustomer, serviceState: serviceState });
  }

  addNewPlace(_options, _stateName, _value, _stateValue) {
    if (_value && _value.__isNew__) {
      const { dispatch } = this.props;
      dispatch(placesActions.placesCreate({ name: _value.label })).then(() => {
        const { placesCreate } = this.props;
        const { place } = placesCreate;
        const { data } = place;
        const { id, name } = data;
        if (id && name) {
          _options.push({ value: id, label: name });
          this.setState({ [`${_stateName}`]: _options, [`${_stateValue}`]: { value: id, label: name } });
        }
      });
    }
  }

  setVehiclePrices = () => {
    setTimeout(() => {
      const { eOdometer, sOdometer, vTiming, oDiscount, _vehiclePackage, _dataFin, akmCharge, aCarCharge, aDriverCharge } = this.state;
      if (_dataFin && _vehiclePackage) {
        let kmChargeText1 = ``;
        let kmChargeText2 = ``;
        let kmChargeValue = 0;
        let kmChargeOrig = 0;
        let cChargeText2 = `x ${Math.ceil((vTiming) / 24)}`;
        let cChargeValue = `${aCarCharge * (Math.ceil((vTiming) / 24))}`;
        let cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
        let cChargeOrig = _dataFin.crent * (Math.ceil((vTiming) / 24));
        let dChargeText2 = `x ${Math.ceil((vTiming) / 24)}`;
        let dChargeValue = `${aDriverCharge * (Math.ceil((vTiming) / 24))}`;
        let dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
        let dChargeOrig = _dataFin.drent * (Math.ceil((vTiming) / 24));
        if (_vehiclePackage && _vehiclePackage === 1) {
          kmChargeText2 = `x ${Math.ceil((vTiming) / _dataFin.hours)}`;
          kmChargeValue = `${akmCharge * (Math.ceil((vTiming) / _dataFin.hours))}`;
          kmChargeText1 = `${akmCharge} ${kmChargeText2} = ${kmChargeValue}`;
          kmChargeOrig = _dataFin.perkm * (Math.ceil((vTiming) / _dataFin.hours));
          cChargeText2 = `x ${Math.ceil((vTiming) / _dataFin.hours)}`;
          cChargeValue = `${aCarCharge * (Math.ceil((vTiming) / _dataFin.hours))}`;
          cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
          cChargeOrig = _dataFin.crent * (Math.ceil((vTiming) / _dataFin.hours));
          dChargeText2 = `x ${Math.ceil((vTiming) / _dataFin.hours)}`;
          dChargeValue = `${aDriverCharge * (Math.ceil((vTiming) / _dataFin.hours))}`;
          dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
          dChargeOrig = _dataFin.drent * (Math.ceil((vTiming) / _dataFin.hours));
        } else if (_vehiclePackage && _vehiclePackage === 2) {
          kmChargeText2 = `x ${Math.ceil((vTiming) / 12)}`;
          kmChargeValue = `${_dataFin.hours * (Math.ceil((vTiming) / 12))}`;
          kmChargeText1 = `${_dataFin.hours} ${kmChargeText2} = ${kmChargeValue}`;
          kmChargeOrig = _dataFin.hours * (Math.ceil((vTiming) / 12));
          cChargeText2 = `x ${Math.ceil((vTiming) / 12)}`;
          cChargeValue = `${aCarCharge * (Math.ceil((vTiming) / 12))}`;
          cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
          cChargeOrig = _dataFin.crent * (Math.ceil((vTiming) / 12));
          dChargeText2 = `x ${Math.ceil((vTiming) / 12)}`;
          dChargeValue = `${aDriverCharge * (Math.ceil((vTiming) / 12))}`;
          dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
          dChargeOrig = _dataFin.drent * (Math.ceil((vTiming) / 12));
        } else if (_vehiclePackage && _vehiclePackage === 3) {
          kmChargeText2 = `x ((${eOdometer - sOdometer}) / 350) x 350`;
          kmChargeValue = `${akmCharge * (Math.ceil((eOdometer - sOdometer) / 350)) * 350}`;
          kmChargeText1 = `${akmCharge} ${kmChargeText2} = ${kmChargeValue}`;
          kmChargeOrig = _dataFin.perkm * (Math.ceil((eOdometer - sOdometer) / 350)) * 350;
          cChargeText2 = `x ((${eOdometer - sOdometer}) / 350)`;
          cChargeValue = `${aCarCharge * (Math.ceil((eOdometer - sOdometer) / 350))}`;
          cChargeText1 = `${aCarCharge} ${cChargeText2} = ${cChargeValue}`;
          cChargeOrig = _dataFin.crent * (Math.ceil((eOdometer - sOdometer) / 350));
          dChargeText2 = `x ((${eOdometer - sOdometer}) / 350)`;
          dChargeValue = `${aDriverCharge * (Math.ceil((eOdometer - sOdometer) / 350))}`;
          dChargeText1 = `${aDriverCharge} ${dChargeText2} = ${dChargeValue}`;
          dChargeOrig = _dataFin.drent * (Math.ceil((eOdometer - sOdometer) / 350));
        }
        let oTotal = (parseFloat(kmChargeValue) + parseFloat(cChargeValue) + parseFloat(dChargeValue)) - parseFloat(oDiscount || 0);
        this.setState({
          kmChargeText2: kmChargeText2, kmChargeText1: kmChargeText1, kmChargeValue: kmChargeValue, cChargeText1: cChargeText1, cChargeText2: cChargeText2, cChargeValue: cChargeValue, dChargeText1: dChargeText1, dChargeText2: dChargeText2, dChargeValue: dChargeValue, oTotal: oTotal, dChargeOrig: dChargeOrig, cChargeOrig: cChargeOrig, kmChargeOrig: kmChargeOrig
        });
      }
    }, 300);
  }

  selectVehiclePackage = (_event, _value) => {
    _event.preventDefault();
    const { _vehiclePackage, _vehicleList, toggle_vehicle_facility, select_vehicle, akmCharge, aCarCharge, aDriverCharge } = this.state;
    let _data = toggle_vehicle_facility && _vehicleList[select_vehicle['value']].vehicle_ac_info;
    if (!toggle_vehicle_facility)
      _data = !toggle_vehicle_facility && _vehicleList[select_vehicle['value']].vehicle_non_ac_info;

    let _dataFin = { 'price': _data.crent_fixed, 'name': 'Fixed', 'crent': _data.crent_fixed, 'drent': _data.drent_fixed, 'perkm': _data.perkm_fixed, 'hours': _data.hours_fixed, 'km': _data.km_fixed, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };

    if (_value && _value === 1) {
      _dataFin = { 'price': _data.crent_hourly, 'time': _data.hours_hourly, 'name': 'Hourly', 'crent': _data.crent_hourly, 'drent': _data.drent_hourly, 'perkm': _data.perkm_hourly, 'hours': _data.hours_hourly, 'km': _data.km_hourly, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };
    } else if (_value && _value === 2) {
      _dataFin = { 'price': (_data.crent_daily + _data.drent_daily + _data.perkm_daily * 350), 'name': 'Daily', 'crent': _data.crent_daily, 'drent': _data.drent_daily, 'perkm': _data.perkm_daily, 'hours': _data.hours_daily, 'km': _data.km_daily, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };
    } else if (_value && _value === 3) {
      _dataFin = { 'price': (_data.crent_kilometer + _data.drent_kilometer + _data.perkm_kilometer * 350), 'name': 'Kilometer', 'crent': _data.crent_kilometer, 'drent': _data.drent_kilometer, 'perkm': _data.perkm_kilometer, 'hours': _data.hours_kilometer, 'km': _data.km_kilometer, 'acrent': aCarCharge, 'aperkm': akmCharge, 'adrent': aDriverCharge };
    }

    this.setState({ _vehiclePackage: (_vehiclePackage === _value) ? '' : _value, _dataPack: _vehiclePackage !== _value ? _dataFin : '', aDriverCharge: _vehiclePackage !== _value ? _dataFin.drent : '', oDriverCharge: _vehiclePackage !== _value ? _dataFin.drent : '', aCarCharge: _vehiclePackage !== _value ? _dataFin.crent : '', oCarCharge: _vehiclePackage !== _value ? _dataFin.crent : '', akmCharge: _vehiclePackage !== _value ? _dataFin.perkm : '', okmCharge: _vehiclePackage !== _value ? _dataFin.perkm : '', _dataFin: _dataFin });
    this.setVehiclePrices();

    // kmChargeText1: '', kmChargeText2: '', kmChargeValue: 0, cChargeText1: '', cChargeText2: '', cChargeValue: 0, dChargeText1: '', dChargeText2: '', dChargeValue: 0
  }

  ontoggleshandlechange = (e, selector) => {
    this.setState({ [`${selector}`]: e.target.checked });
    this.setVehiclePrices();
  }

  getDifference(_start, _end, _i) {
    let __end = moment(_end, 'YYYY-MM-DD h:mm a');
    let __start = moment(_start, 'YYYY-MM-DD h:mm a');
    let hours = __start.diff(__end, 'hours');
    this.setState({ vTiming: (hours) ? hours : 0 });
  }

  validateActual = (_stateName, _value, _regex, _stateInvalid) => {
    let statusState = 'is-valid';
    if (_value && !_regex.test(_value))
      statusState = 'is-invalid';
    else if (!_value)
      statusState = 'is-invalid';
    this.setState({ [`${_stateName}`]: _value, [`${_stateInvalid}`]: statusState });
  }

  render() {
    const { ticketDetails, _ticketCreateState, loading, doc_type, services_temp, services_temp_arr, specialState, grpOverAllTotal, grpOverAllTotalInd, serviceCustomer, tourPlaceslistOption, statusOptions, vehicleOptions, _vehiclePackage, _vehicleList, select_vehicle, toggle_vehicle_facility, _dataPack, sOdometer, eOdometer, vehicleEnd, vehicleStart, vTiming, aDriverCharge, oDriverCharge, aCarCharge, oCarCharge, akmCharge, okmCharge, oDiscount, akmChargeState, aCarChargeState, aDriverChargeState, oDiscountState, akmChargeFocus, aCarChargeFocus, aDriverChargeFocus, oDiscountFocus, kmChargeText1, kmChargeText2, kmChargeValue, cChargeText1, cChargeText2, cChargeValue, dChargeText1, dChargeText2, dChargeValue, oTotal, vehicle_stage_3_id, kmChargeOrig, cChargeOrig, dChargeOrig, _vehicleCount, vStage4Pay } = this.state;
    const { authentication } = this.props;
    let userRole = (authentication && authentication.user && authentication.user.user && authentication.user.user.userrole && authentication.user.user.userrole.role && authentication.user.user.userrole.role.name);
    let approveDisabled = (userRole === 'Manager') ? false : true;

    if (!this.state._ticketCreateState)
      return null;

    const SingleValue = ({ children, ...props }) => (
      <components.SingleValue {...props}>
        {children}
      </components.SingleValue>
    );

    const { Option } = components;
    const IconOption = (props) => {
      let iconimg = '';
      let bagde = '';
      let imgSrc = require("assets/img/theme/team-1.jpg");
      if (props.data.badge) {
        iconimg = (<span className="mr-1"><img src={imgSrc} className="avatar rounded" /> </span>);
        bagde = (<span className="ml-1 badge badge-dark badge-pill text-white d-flex text-right">{props.data.badge}</span>);
      }
      return (
        <Option {...props} key={`${props.data.label}_${props.data.id}`} className="d-flex align-items-center">
          {iconimg}
          <span className="mx-1">{props.data.label}</span>
          {bagde}
        </Option>
      );
    }

    const { ticketCreation } = this.state;
    const { serviceValue } = ticketCreation;
    const servicesLinks = [];
    const serviceStatusLinks = [];
    const servicesPanes = [];
    const billingAccordians = [];
    let yrki = 0;

    let hasTourService = 0;
    let hasHotelService = 0;
    let hasVehicleService = 0;
    let hasInsuranceService = 0;
    serviceValue && isArray(serviceValue) && serviceValue.forEach((v, i) => {
      if (v.trim() === "Tour") { hasTourService = 1; }
      if (v.trim() === "Hotel") { hasHotelService = 1; }
      if (v.trim() === "Insurance") { hasInsuranceService = 1; }
      if (v.trim() === "Vehicle") { hasVehicleService = 1; }
    });

    serviceValue && isArray(serviceValue) && serviceValue.forEach((v, i) => {
      if (v.toLowerCase().trim() === "tour") {
        let SpecialPrice = 0, GroupIndPrice = (this.state.pay_type) ? grpOverAllTotalInd : grpOverAllTotal, FoodVendorPrice = 0, HotelPrice = 0, VehiclePrice = 0;
        specialState && specialState.ticket && specialState.ticket.data && specialState.ticket.data.forEach(special => {
          SpecialPrice = SpecialPrice + this.parserFloat(special.customer_paid);
        });
        this.state.expenses_data && Object.keys(this.state.expenses_data).forEach(expenses => {
          FoodVendorPrice = FoodVendorPrice + this.parserFloat(this.state.expenses_data[expenses].expenses && this.state.expenses_data[expenses].expenses.amount || 0);
        });
        this.state.expenses_data1 && Object.keys(this.state.expenses_data1).forEach(expenses1 => {
          HotelPrice = HotelPrice + this.parserFloat(this.state.expenses_data1[expenses1].expenses.amount);
        });
        SpecialPrice = this.parserFloat(SpecialPrice).toFixed(2);
        GroupIndPrice = this.parserFloat(GroupIndPrice).toFixed(2);
        FoodVendorPrice = this.parserFloat(FoodVendorPrice).toFixed(2);
        HotelPrice = this.parserFloat(HotelPrice).toFixed(2);
        VehiclePrice = this.parserFloat(VehiclePrice).toFixed(2);
        let OtaTotal = this.parserFloat(this.parserFloat(SpecialPrice) + this.parserFloat(GroupIndPrice) + this.parserFloat(FoodVendorPrice) + this.parserFloat(HotelPrice) + this.parserFloat(VehiclePrice)).toFixed(2);
        // billingFullAccordians = true;
        billingAccordians.push(
          <Card key={i} className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => this.collapsesToggle(`collapse${i}`)}
              aria-expanded={this.state.openedCollapses.includes(`collapse${i}`)}
              className="py-2"
            >
              <div className="row m-0  height-md-30px">
                <div className="col-md-3 col-sm-12 height-30px pl-0">
                  <h3 className="mb-0 d-block">
                    <span className="w-50"><em><small>{v}</small></em> :</span> <span className="text-success float-right w-50">
                      <input type="text" className="form-control form-control-sm cc-form-control text-success" value={this.state.billing[i] && this.state.billing[i]['price'] || ''} ref={`billing_${i}_price`} onChange={e => { this.handleBillingTourInputsChange(i, e, v, 'price') }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} />
                      {/* {this.state.billing[i] && this.state.billing[i][`price`] || 0} */}
                    </span>
                  </h3>
                </div>
                <div className="col-1 text-center d-none d-md-block border-left">
                </div>
                <div className="col-md-3 col-sm-12 height-30px pl-0 pr-lg-0">
                  <h3 className="mb-0 d-block">
                    <em><small>Customer Paid</small></em> : <span className="text-primary float-right">{OtaTotal}</span>
                  </h3>
                </div>
                <div className="col-1 text-center d-none d-md-block border-right">
                </div>
                <div className="col-md-3 col-sm-12 height-30px">
                  <h3 className="mb-0 d-block">
                    <em><small>Balance</small></em> : <span className={classnames({ "text-info": (this.state.billing[i] && this.state.billing[i][`bal`] || 0) >= 0, "text-danger": (this.state.billing[i] && this.state.billing[i][`bal`] || 0) < 0, "float-right": true })}>{this.state.billing[i] && this.state.billing[i][`bal`] || 0}</span>
                  </h3>
                </div>
              </div>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes(`collapse${i}`)}
            >
              <CardBody>
                <Form className="needs-validation" noValidate>
                  <div className="w-100 border rounded mb-2 p-2 shadow d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 font-weight-normal">Special Services</h4>
                    <p className="mb-0">₹ {SpecialPrice}</p>
                  </div>
                  <div className="w-100 border rounded mb-2 p-2 shadow d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 font-weight-normal">Group / Individual Pay</h4>
                    <p className="mb-0">₹ {GroupIndPrice}</p>
                  </div>
                  <div className="w-100 border rounded mb-2 p-2 shadow d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 font-weight-normal">Food / Vendor</h4>
                    <p className="mb-0">₹ {FoodVendorPrice}</p>
                  </div>
                  <div className="w-100 border rounded mb-2 p-2 shadow d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 font-weight-normal">Hotel</h4>
                    <p className="mb-0">₹ {HotelPrice}</p>
                  </div>
                  <div className="w-100 border rounded mb-2 p-2 shadow d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 font-weight-normal">Vehicle</h4>
                    <p className="mb-0">₹ {VehiclePrice}</p>
                  </div>
                </Form>
              </CardBody>
            </Collapse>
          </Card>
        );
      }
      if (hasTourService && v.toLowerCase().trim() === "vehicle") {
        // do nothing
      } else if (!hasTourService && v.toLowerCase().trim() === "vehicle") {
        billingAccordians.push(
          <Card key={i} className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => this.collapsesToggle(`collapse${i}`)}
              aria-expanded={this.state.openedCollapses.includes(`collapse${i}`)}
              className="py-2"
            >
              <div className="row m-0  height-md-30px">
                <div className="col-md-3 col-sm-12 height-30px pl-0">
                  <h3 className="mb-0 d-block">
                    <span className="w-50"><em><small>{v}</small></em> :</span> <span className="text-success float-right w-50">
                      ₹ {oTotal + vStage4Pay}
                    </span>
                  </h3>
                </div>
                <div className="col-1 text-center d-none d-md-block border-left">
                </div>
                <div className="col-md-3 col-sm-12 height-30px pl-0 pr-lg-0">
                  <h3 className="mb-0 d-block">
                    <span className="w-50"><em><small>Customer Paid</small></em> :</span> <span className="text-primary float-right w-50">
                      <input type="text" className="form-control form-control-sm cc-form-control text-success" value={this.state.billing[i] && this.state.billing[i]['price'] || ''} ref={`billing_${i}_price`} onChange={e => { this.handleBillingTourInputsChange(i, e, v, 'price') }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} /></span>
                  </h3>
                </div>
                <div className="col-1 text-center d-none d-md-block border-right">
                </div>
                <div className="col-md-3 col-sm-12 height-30px">
                  <h3 className="mb-0 d-block">
                    <em><small>Balance</small></em> : <span className={classnames({ "text-info": (this.state.billing[i] && this.state.billing[i][`bal`] || 0) >= 0, "text-danger": (this.state.billing[i] && this.state.billing[i][`bal`] || 0) < 0, "float-right": true })}>{this.state.billing[i] && this.state.billing[i][`bal`] || 0}</span>
                  </h3>
                </div>
              </div>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes(`collapse${i}`)}
            >
              <CardBody>
                <Form className="needs-validation" noValidate>
                  <div className="w-100 border rounded mb-2 p-2 shadow d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 font-weight-normal">Vehicle</h4>
                    <p className="mb-0">₹ {oTotal}</p>
                  </div>
                  <div className="w-100 border rounded mb-2 p-2 shadow d-flex justify-content-between align-items-center">
                    <h4 className="mb-0 font-weight-normal">Other Charges</h4>
                    <p className="mb-0">₹ {vStage4Pay}</p>
                  </div>
                </Form>
              </CardBody>
            </Collapse>
          </Card>
        );
      }
      if (hasTourService && v.toLowerCase().trim() === "hotel") {
        // do nothing
      } else if (v.toLowerCase().trim() !== "tour" && v.toLowerCase().trim() !== "currencies" && v.toLowerCase().trim() !== "vehicle") {
        billingAccordians.push(
          <Card key={`${v.toLowerCase()}_plain_card`} className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => this.collapsesToggle(`collapse${i}`)}
              aria-expanded={this.state.openedCollapses.includes(
                `collapse${i}`
              )}
              className="py-2"
            >
              <div className="row m-0  height-md-30px">
                <div className="col-md-3 col-sm-12 height-30px pl-0">
                  <h3 className="mb-0 d-block">
                    <em><small>{v}</small></em> : <span className="text-success float-right">{this.state.billing[i] && this.state.billing[i][`price`] || 0}</span>
                  </h3>
                </div>
                <div className="col-1 text-center d-none d-md-block positon-relative top--2">
                  <h4 className="display-4 font-weight-light">|</h4>
                </div>
                <div className="col-md-3 col-sm-12 height-30px pl-0 pr-lg-0">
                  <h3 className="mb-0 d-block">
                    <em><small>Customer Paid</small></em> : <span className="text-primary float-right w-50">
                      <input type="number" className="form-control form-control-sm cc-form-control text-primary text-right" value={this.state.billing[i] && this.state.billing[i][`paid`] || 0} ref={`billing_${i}_paid`} onChange={e => { this.handleBillingInputsChange(i, e, v, 'paid') }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} />
                    </span>
                  </h3>
                </div>
                <div className="col-1 text-center d-none d-md-block positon-relative top--2">
                  <h4 className="display-4 font-weight-light">|</h4>
                </div>
                <div className="col-md-3 col-sm-12 height-30px pl-0">
                  <h3 className="mb-0 d-block">
                    <em><small>Balance</small></em> : <span className={classnames({ "text-info": (this.state.billing[i] && this.state.billing[i][`bal`] || 0) >= 0, "text-danger": (this.state.billing[i] && this.state.billing[i][`bal`] || 0) < 0, "float-right": true })}>{this.state.billing[i] && this.state.billing[i][`bal`] || 0}</span>
                  </h3>
                </div>
              </div>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={this.state.openedCollapses.includes(`collapse${i}`)}
            >
              <CardBody>
                <Form className="needs-validation" noValidate>
                  <div className="form-row mb-2">
                    <Col md="6" sm="12" className={classnames({ "border-right": (v.toLowerCase() != 'tour') })}>
                      {(v.toLowerCase() != 'tour') ?
                        (<Col className="pb-1 d-flex justify-content-start align-items-center">
                          <Col className="justify-content-end d-flex pl-0">
                            <label className="form-control-label mb-0 mr-2">E-Route Mode of Pay: </label></Col><Col md="5" sm="12" className="px-0">
                            <Select ref={`select_mop`} id="mop" className={`form-control form-control-sm p-0 select_mop`} isClearable placeholder="Select MOP" components={{ Option: IconOption }} isSearchable isMulti={false} name="mop" value={this.state.billing[i] && this.state.billing[i]['mopData']} options={[
                              { value: "cash", label: "Cash" },
                              { value: "to_bank", label: "To Bank" },
                              { value: "counter", label: "Counter" },
                            ]} onChange={(value) => { this.onBillSelectChange(value, i, 'mop', v) }} /></Col>
                        </Col>) : ''}

                      {(v.toLowerCase() != 'insurance') ? (v.toLowerCase() != 'hotel') ? (v.toLowerCase() != 'tour') ?
                        ((v.toLowerCase() != 'passport') ? (<Col className="pb-1 d-flex justify-content-start align-items-center">
                          <Col className="justify-content-end d-flex pl-0">
                            <label className="form-control-label mb-0 mr-2">Amount Paid By </label></Col><Col md="5" sm="12" className="px-0">
                            <Select ref={`select_amount_paid_by`} id="amount_paid_by" className={`form-control form-control-sm p-0 select_amount_paid_by`} isClearable placeholder="Select Paid By" components={{ Option: IconOption }} isSearchable isMulti={false} value={this.state.billing[i] && this.state.billing[i]['amount_paid_byData']} name="amount_paid_by" options={[
                              { value: "e-route", label: "E-route" },
                              { value: "vendor", label: "Vendor" },
                            ]} onChange={(value) => { this.onBillSelectChange(value, i, 'amount_paid_by', v) }} />
                          </Col>
                        </Col>) : (<Col className="pb-1 d-flex justify-content-start align-items-center">
                          <Col className="justify-content-end d-flex pl-0">
                            <label className="form-control-label mb-0 mr-2">Amount Paid By </label></Col><Col md="5" sm="12" className="px-0">
                            <Select ref={`select_amount_paid_by`} id="amount_paid_by" className={`form-control form-control-sm p-0 select_amount_paid_by`} isClearable placeholder="Select Paid By" components={{ Option: IconOption }} isSearchable isMulti={false} value={this.state.billing[i] && this.state.billing[i]['amount_paid_byData']} name="amount_paid_by" options={[
                              { value: "e-route", label: "E-route" }
                            ]} onChange={(value) => { this.onBillSelectChange(value, i, 'amount_paid_by', v) }} />
                          </Col>
                        </Col>)) : '' : '' : ''}

                      {(v.toLowerCase() != 'tour') ?
                        (<Col className={classnames({ "pb-1": (this.state.billing[i] && this.state.billing[i]['amount_paid_by'] && this.state.billing[i]['amount_paid_by'] === 'vendor'), "d-flex": (this.state.billing[i] && this.state.billing[i]['amount_paid_by'] && this.state.billing[i]['amount_paid_by'] === 'vendor'), "d-none": ((this.state.billing[i] && this.state.billing[i]['amount_paid_by'] && this.state.billing[i]['amount_paid_by'] !== 'vendor') || !this.state.billing[i] || this.state.billing[i] && !this.state.billing[i]['amount_paid_by']), "justify-content-start": true, "align-items-center": true })}>
                          <Col className="justify-content-end d-flex pl-0">
                            <label className="form-control-label mb-0 mr-2">Vendor Name </label></Col><Col md="5" sm="12" className="px-0">
                            <Select ref={`select_vendor_name`} id="vendor_name" className={`form-control form-control-sm p-0 select_vendor_name`} isClearable placeholder="Select a Vendor" components={{ Option: IconOption }} isSearchable isMulti={false} value={this.state.billing[i] && this.state.billing[i]['vendor_nameData']} name="vendor_name" options={this.state.vendorList || []} onChange={(value) => { this.onBillSelectChange(value, i, 'vendor_name', v) }} />
                          </Col>
                        </Col>) : ''}
                      {(v.toLowerCase() != 'tour') ?
                        (<Col className="pb-1 d-flex justify-content-start align-items-center">
                          <Col className="justify-content-end d-flex pl-0">
                            <label className="form-control-label mb-0 mr-2">Invoice To </label></Col><Col md="5" sm="12" className="px-0">
                            <Select ref={`select_invoice_to`} id="invoice_to" className={`form-control form-control-sm p-0 select_invoice_to`} isClearable placeholder="Select Invoice To" components={{ Option: IconOption }} isSearchable isMulti={false} value={this.state.billing[i] && this.state.billing[i]['invoice_toData']} name="invoice_to" options={[
                              { value: "company", label: "Company" },
                              { value: "individual", label: "Individual" },
                            ]} onChange={(value) => { this.onBillSelectChange(value, i, 'invoice_to', v) }} />
                          </Col>
                        </Col>) : ''}
                      {(v.toLowerCase() != 'tour') ?
                        (<Col className={classnames({ "pb-1": (this.state.billing[i] && this.state.billing[i]['invoice_to'] && this.state.billing[i]['invoice_to'] !== ''), "d-flex": (this.state.billing[i] && this.state.billing[i]['invoice_to'] && this.state.billing[i]['invoice_to'] !== ''), "d-none": (this.state.billing[i] && this.state.billing[i]['invoice_to'] && this.state.billing[i]['invoice_to'] === '' || !this.state.billing[i] || this.state.billing[i] && !this.state.billing[i]['invoice_to']), "justify-content-start": true, "align-items-center": true })}>
                          <Col className="justify-content-end d-flex pl-0">
                            <label className="form-control-label mb-0 mr-2">Invoice Buyer Name </label></Col><Col md="5" sm="12" className="px-0">
                            <input type="text" className="form-control form-control-sm cc-form-control" value={this.state.billing[i] && this.state.billing[i]['invoice_buyer_name'] || ''} ref={`billing_${i}_invoice_buyer_name`} onChange={e => { this.handleBillingInputsChange(i, e, v, 'invoice_buyer_name') }} /></Col>
                        </Col>) : ''}
                      {(v.toLowerCase() != 'tour') ?
                        (<Col className="pb-1 d-flex justify-content-start align-items-center">
                          <Col className="justify-content-end d-flex pl-0">
                            <label className="form-control-label mb-0 mr-2">E-Route Paid: </label></Col><Col md="5" sm="12" className="px-0">
                            <input type="text" className="form-control form-control-sm cc-form-control" value={this.state.billing[i] && this.state.billing[i]['e_paid_amount'] || ''} ref={`billing_${i}_e_paid_amount`} onChange={e => { this.handleBillingInputsChange(i, e, v, 'e_paid_amount') }} /></Col>
                        </Col>) : ''}
                    </Col>
                    <Col md="6" sm="12">
                      <Col className="d-flex justify-content-start align-items-center">
                        <Col md="7" sm="12" className="justify-content-end d-flex">
                          <label className="form-control-label mb-0 mr-2">{v}: </label></Col><Col md="5" sm="12">
                          <input type="number" className="form-control form-control-sm cc-form-control" value={this.state.billing[i] && this.state.billing[i]['o_price'] || 0} ref={`billing_${i}_o_price`} onChange={e => { this.handleBillingInputsChange(i, e, v, 'o_price') }} /></Col>
                      </Col>

                      <Col className="d-flex justify-content-start align-items-center">
                        <Col md="7" sm="12" className="justify-content-end d-flex">
                          <label className="form-control-label mb-0 mr-2">Others: </label></Col><Col md="5" sm="12">
                          <input type="number" className="form-control form-control-sm cc-form-control" value={this.state.billing[i] && this.state.billing[i]['others'] || 0} ref={`billing_${i}_others`} onChange={e => { this.handleBillingInputsChange(i, e, v, 'others') }} /></Col>
                      </Col>

                      <Col className="d-flex justify-content-start align-items-center">
                        <Col md="7" sm="12" className="justify-content-end d-flex">
                          <label className="form-control-label mb-0 mr-2">Service Charge: </label></Col><Col md="5" sm="12">
                          <input type="number" className="form-control form-control-sm cc-form-control" value={this.state.billing[i] && this.state.billing[i]['service'] || 0} ref={`billing_${i}_service`} onChange={e => { this.handleBillingInputsChange(i, e, v, 'service') }} /></Col>
                      </Col>

                      <Col className="d-none justify-content-start align-items-center">
                        <Col md="7" sm="12" className="justify-content-end d-flex">
                          <label className="form-control-label mb-0 mr-2 font-italic">Service Tax: </label></Col><Col md="5" sm="12">
                          <input type="number" readOnly ref={`billing_${i}_service_tax`} className="form-control form-control-sm cc-form-control" value={this.state.billing[i] && this.state.billing[i]['service_tax'] || 0} /></Col>
                      </Col>
                      {(v.toLowerCase() != 'tour') ?
                        (<Col className="pb-1 d-flex justify-content-start align-items-center">
                          <Col className="justify-content-end d-flex pl-0" md="7" sm="12">
                            <label className="form-control-label mb-0 mr-2">GST: </label></Col><Col md="5" sm="12">
                            <input type="text" className="form-control form-control-sm cc-form-control" value={this.state.billing[i] && this.state.billing[i]['gst_amount'] || 0} ref={`billing_${i}_gst_amount`} onChange={e => { this.handleBillingInputsChange(i, e, v, 'gst_amount') }} readOnly /></Col>
                        </Col>) : ''}
                    </Col>

                  </div>
                </Form>
              </CardBody>
            </Collapse>
          </Card>
        );
      }

      // if(v.trim() === "Passport" || v.trim() === "Visa")
      if (v.trim() !== "Tour" && v.trim() !== "Hotel" && v.trim() !== "Food" && v.trim() !== "Insurance" && v.trim() !== "Vehicle")
        servicesLinks.push(<NavItem key={`${v.trim()}_service_tab`} className="pr-1"> <NavLink className={classnames({ active: (this.state.activeTab === i + 1), 'border-0 py-2': true })} onClick={() => { this.setState({ 'activeTab': i + 1 }); }}> {v} </NavLink> </NavItem>);

      let servicesPane = [];
      this.state.serviceFlds && this.state.serviceFlds[v.toLowerCase()] && Object.keys(this.state.serviceFlds[v.toLowerCase()]).forEach(kuc => {
        this.state.serviceFlds[v.toLowerCase()][kuc].forEach((vv, ii) => {
          if (v.trim() !== "Tour" && v.trim() !== "Hotel" && v.trim() !== "Food" && v.trim() !== "Insurance" && v.trim() !== "Vehicle") {
            servicesPane.push(<Col key={`${v.toLowerCase()}_${vv.name}`} className={classnames({ "mb-2": true })} md={vv.bs4Size}>
              <label className="form-control-label" htmlFor={vv.name} > {vv.label} </label>
              {(vv.type == "input" || vv.type == "text") ?
                (<><Input bsSize="sm" id={`${v.toLowerCase()}_${vv.name}`} placeholder={vv.label} type="text" valid={this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.state}`] === vv.valid} invalid={this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.state}`] === vv.invalid} onChange={(e) => this.serviceStateForm(e, v.toLowerCase(), vv.name, vv.isReq, vv.regex, vv.regexInvalid, vv.invalidFeedback, vv.regstate)} value={serviceCustomer && serviceCustomer[v.toLowerCase()] && serviceCustomer[v.toLowerCase()]['data'][0][v.toLowerCase()] && serviceCustomer[v.toLowerCase()]['data'][0][v.toLowerCase()][`${vv.name}`] || ''} /></>) : ''}
              {/*  || this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.name}`] */}

              {(vv.type == "select" && vv.multiple === true) ? (<><Select ref={`select_${v.toLowerCase()}_${vv.name}`} id={vv.name} className={`form-control form-control-sm p-0 ${(this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.state}`])} select_${vv.name}`} isClearable placeholder={vv.placeholder} components={{ Option: IconOption }} isSearchable isMulti={vv.multiple} name={vv.name} options={vv.option} onChange={(value) => { this.onServiceSelectChange(value, v.toLowerCase(), vv.name, vv.isReq) }} /></>) : ''}

              {(vv.type == "select" && !vv.multiple) ? (
                <><Select ref={`select_${v.toLowerCase()}_${vv.name}`} id={vv.name} className={`form-control form-control-sm p-0 ${(this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.state}`])} select_${vv.name}`} isClearable placeholder={vv.placeholder} components={{ Option: IconOption }} isSearchable isMulti={vv.multiple} name={vv.name} options={vv.option} onChange={(value) => { this.onServiceSelectChange(value, v.toLowerCase(), vv.name, vv.isReq) }} value={serviceCustomer && serviceCustomer[v.toLowerCase()] && serviceCustomer[v.toLowerCase()]['data'][0][v.toLowerCase()] && serviceCustomer[v.toLowerCase()]['data'][0][v.toLowerCase()][`${vv.name}`] || ''} /></>
              ) : ''}

              {(vv.type == "date") ? <ReactDatetime isValidDate={(current) => this.validData(current, vv.name)} closeOnSelect={true} className={`form-control form-control-sm p-0 h-auto ${(this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.state}`] === 'valid') ? 'is-valid' : ((this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.state}`] === 'invalid' && vv.isReq)) ? 'is-invalid' : ''}`} value={serviceCustomer && serviceCustomer[v.toLowerCase()] && serviceCustomer[v.toLowerCase()]['data'][0][v.toLowerCase()] && serviceCustomer[v.toLowerCase()]['data'][0][v.toLowerCase()][`${vv.name}`] || this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.name}`] || ''} inputProps={{ placeholder: vv.placeholder, className: `form-control form-control-sm border-0`, readOnly: true, id: `${vv.name}_date` }} timeFormat={false} onChange={value => this.onServiceDateChange(value, v.toLowerCase(), vv.name, vv.isReq)} /> : ''}


              {(vv.type == "togglebox") ? (<label className="custom-toggle d-block"> <input type="checkbox" className={`toggle_${kuc}_${vv.name}`} ref={`toggle_${kuc}_${vv.name}`} id={vv.name} onChange={(value) => { this.ontogglehandlechange(value, kuc, vv.name, `.toggle_${kuc}_${vv.name}`) }} /> <span className="custom-toggle-slider rounded-circle" data-label-off="Buy"
                data-label-on="Sell" /> </label>) : ''}

              <div className="valid-feedback"></div>
              <div className="invalid-feedback">
                {(this.state.serviceState && this.state.serviceState[`${v.toLowerCase()}_${vv.name}Feedback`]) ? this.state.serviceState[`${v.toLowerCase()}_${vv.name}Feedback`] : vv.invalidFeedback}
              </div>
            </Col>)
          }
        });
      });

      if (v.trim() !== "Tour" && v.trim() !== "Hotel" && v.trim() !== "Food" && v.trim() !== "Insurance" && v.trim() !== "Vehicle") {
        servicesPanes.push(<TabPane key={`${i}_${yrki}`} tabId={i + 1}>
          <Row className="mt-4">
            {/* {serviceLnks} */}
            <div className={`col-12`}>
              <TabContent activeTab={this.state[`${i}ActiveTab`]}>
                <Row>
                  {servicesPane}
                </Row>
                <div className="mt-2 d-flex justify-content-start align-items-center">
                  <Button size="sm" color="primary" className={`saveService saveService-${v.toLowerCase()} save${v.toLowerCase()}Service`} onClick={(e) => this.validateTicketCreationForm(e)}>Save</Button>
                </div>
              </TabContent>
            </div>
          </Row>
        </TabPane>);
      }
      yrki = yrki + 1;
    });

    let servicesT = [];
    if (this.state.ticketCreation && this.state.ticketCreation.service) {
      servicesT = this.state.ticketCreation.service.filter((serv) => { return serv === 1; });
    }
    const { tourTypeslist } = this.props;
    // let tourPlaceslistOption = [];
    let tourTypeslistOption = [];
    tourTypeslist && tourTypeslist.data && tourTypeslist.data.data && tourTypeslist.data.data.length > 0 && tourTypeslist.data.data.forEach((v, i) => {
      tourTypeslistOption.push({ label: v.name, value: v.id });
    });

    let food_places_init_inp = document.querySelector('.food_places_init');
    let tourTypeOptions = tourTypeslistOption;
    if (food_places_init_inp && food_places_init_inp.checked) {
      tourTypeOptions = tourTypeOptions.filter(v => { return v.value != 2 });
    }
    let { food_places_init } = (this.state.food_places_init) ? this.state : { food_places_init: tourTypeOptions };

    let tourRowstemp = [];
    let foodRowstemp = [];
    let hotelRowstemp = [];
    let unit = "places_init";
    const { tourCountlist } = this.props;
    for (let tempu = 0; tempu < (this.state.tourCount); tempu++) {
      tourRowstemp.push(<TourRow notify={(pos, title, text) => this.notify(pos, title, text)} tourCountlist={this.props.tourCountlist} tourCountCreate={this.props.tourCountCreate} oStartData={this.state.startDate_places_init} oEndData={this.state.endDate_places_init} setStater={(stateName, data) => { this.setState({ [`${stateName}`]: data }); }} jsUcfirst={(text) => this.jsUcfirst(text)} key={tempu} data={tourCountlist && tourCountlist.data && tourCountlist.data.data && tourCountlist.data.data[0] && tourCountlist.data.data[0].tour_places && tourCountlist.data.data[0].tour_places[tempu] || {}} unit={tempu} renderDateInput={() => this.renderDateInput} optiondata={tourPlaceslistOption} isLast={tempu === this.state.tourCount - 1} isLastClick={() => this.setState({ tourCount: this.state.tourCount + 1 })} />);
    }

    for (let tempu = 0; tempu < this.state.expensesCount; tempu++) {
      foodRowstemp.push(<FoodRow notify={(pos, title, text) => this.notify(pos, title, text)} e_ticket_id={this.state.e_ticket_id} tour_id_init={this.state.tour_id_init} food_places_init_chk={this.state.food_places_init_chk} tourExpensesInsert={this.props.tourExpensesInsert} expenses_data={this.state.expenses_data} customerId={this.state.e_cust_id} setStater={(stateName, data) => { this.setState({ [`${stateName}`]: data }); }} vendorsOption={this.state.vendorinit} oStartData={this.state.startDate_places_init} oEndData={this.state.endDate_places_init} jsUcfirst={(text) => this.jsUcfirst(text)} key={tempu} unit={tempu} renderDateInput={() => this.renderDateInput} optiondata={tourPlaceslistOption} isLast={tempu === this.state.expensesCount - 1} expenses_data={this.state.expenses_data} isLastClick={() => this.setState({ expensesCount: this.state.expensesCount + 1 })} />);
    }
    if (hasHotelService) {
      var excount = this.state.hoexpensesCount;
      for (let tempuu = 1; tempuu <= this.state.hoexpensesCount; tempuu++) {
        hotelRowstemp.push(<HotelRow
          vendorsOption={this.state.vendorinit}
          oStartData={this.state.startDate_places_init}
          oEndData={this.state.endDate_places_init}
          jsUcfirst={(text) => this.jsUcfirst(text)}
          key={tempuu}
          unit={tempuu - 1}
          getcount={this.state.hoexpensesCount}
          renderDateInput={() => this.renderDateInput}
          optiondata={tourPlaceslistOption}
          isGetid={this.state.update_data}
          getdelete={(id, unit1, elem) => this.getdelete1(id, unit1, elem)}
          isLast={tempuu === this.state.hoexpensesCount}
          expenses_data1={this.state.expenses_data1}
          setStater={(stateName, data) => { this.setState({ [`${stateName}`]: data }); }}
          isLastClick1={() => this.setState({ hoexpensesCount: this.state.hoexpensesCount + 1 })}
          isLastClick2={() => this.setState({ hoexpensesCount: this.state.hoexpensesCount - 1 })} />);
      }
    }

    let grpRow = [];
    for (let i = 1; i <= this.state.group_count; i++) {
      let { group_count, group_name, group_id, groupPaid, grpTotal, grpTotalInd } = this.state.group_data && this.state.group_data[i] || { group_count: 1, group_name: '', group_id: '', groupPaid: 0, grpTotal: 0.00, grpTotalInd: 0 };
      grpRow.push(<div className="row mx-0 mb-2 grpRow_tc" key={i}>
        <div className="col-md-4 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
          <input type="hidden" className={`group_id_${i}`} value={group_id} />
          <input type="text" className={`group_name_${i} form-control form-control-sm`} placeholder="Group Name" value={group_name} onChange={(e) => { let group_data = (this.state.group_data) ? this.state.group_data : {}; group_data[i] = (group_data[i]) ? group_data[i] : {}; group_data[i]['group_name'] = e.target.value; this.setState({ group_data: group_data }); let elem = document.querySelector(`.group_name_${i}`); removeInvalid(elem); if (!/^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _.]*)$/.test(elem.value)) { addInvalid(elem); } }} />
        </div>
        <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
          <input type="text" className={`member_count_${i} form-control form-control-sm`} placeholder="Count" value={group_count} onChange={(e) => { let elem = e.target; removeInvalid(elem); if (elem.value.trim() === "") { addInvalid(elem); } else if (elem.value && !/^[1-9]\d*(d+)?$/.test(elem.value)) { addInvalid(elem); } let group_data = (this.state.group_data) ? this.state.group_data : {}; group_data[i] = (group_data[i]) ? group_data[i] : {}; group_data[i]['group_count'] = e.target.value; this.setState({ group_data: group_data }); }} />
        </div>
        <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
          <input type="text" readOnly className={`group_total_${i} form-control form-control-sm`} placeholder="Group Total" value={(this.state.pay_type) ? grpTotalInd : grpTotal} />
        </div>
        {/* {(group_id) ?
        (<div className="col-md-1 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
          <button className="btn btn-primary btn-sm w-100" onClick={(e) => this.createGroup(e, i)}>Go</button>
        </div>) : ''}
        {(!this.state.pay_type && group_id && groupPaid && group_count) ? (
        <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
          <p className="mb-0">{groupPaid}/{group_count} Paid</p>
        </div>
        ) : ''} */}
      </div>);
    }
    servicesT.forEach((v, i) => {
      servicesLinks.push(
        <NavItem key={i} className="pr-1">
          <NavLink className={classnames({ active: (this.state.activeTab === `active_${`Tour`}`), 'border-0': true, 'py-2': true })} onClick={() => { this.setState({ 'activeTab': `active_${`Tour`}`, [`${`Tour`}ActiveTab`]: 1, Tour_1_ActiveTab: 1 }); }}> {`Tour`} </NavLink>
        </NavItem>
      );

      serviceStatusLinks.push(<Select ref={`select_tour_status`} id={`tour_status`} className={`form-control form-control-sm select_${`tour_status`}  p-0 float-right h-100 ${(this.state.activeTab === 'active_Tour') ? 'd-block' : 'd-none'}`} isClearable placeholder={`Tour Status`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`tour_status`} options={statusOptions} value={this.state.select_tour_status || { value: '1', label: 'Open' }} isDisabled={this.state.select_tour_status ? false : true} onChange={(value) => { this.setState({ 'select_tour_status': value }); }} />);

      servicesPanes.push(
        <TabContent key={i} activeTab={this.state.activeTab}>
          <TabPane tabId={`active_${`Tour`}`} className={classnames({ "d-block": (this.state.activeTab === `active_${`Tour`}`) })}>
            <Row className="mt-0 mx-0">
              <div className={`col-md-12 px-0 mt-4`}>
                <Nav tabs className="nav-pills border-0">
                  <NavItem className="p-0 mr-1">
                    <NavLink className={classnames({ active: (this.state[`Tour_1_ActiveTab`] == 1), 'border-0': true, ' px-2 py-2': true })}
                      onClick={() => { this.toggle(1, `Tour_1_ActiveTab`); }}>
                      Stage 1
                    </NavLink>
                  </NavItem>
                  {(this.state.tour_id_init) ? (
                    <NavItem className="p-0 mr-1">
                      <NavLink className={classnames({ active: (this.state[`Tour_1_ActiveTab`] == 2), 'border-0': true, ' px-2 py-2': true })}
                        onClick={() => { this.toggle(2, `Tour_1_ActiveTab`); }}>
                        Stage 2
                      </NavLink>
                    </NavItem>) : ''}
                  {(this.state.tour_tour_id) ? (<NavItem className={classnames({ "p-0 mr-1": true, "d-block": (this.state.food_places_init_chk || this.state.select_tour_type && this.state.select_tour_type.value === 2), "d-none": (!this.state.food_places_init_chk || this.state.select_tour_type && this.state.select_tour_type.value !== 2) })}>
                    <NavLink className={classnames({ active: (this.state[`Tour_1_ActiveTab`] == 3), 'border-0': true, ' px-2 py-2': true })}
                      onClick={() => { this.toggle(3, `Tour_1_ActiveTab`); }}>
                      Stage 3
                    </NavLink>
                  </NavItem>) : ''}

                  {(this.state.startDate_places_init && this.state.endDate_places_init && this.state[`Tour_1_ActiveTab`] && this.state[`Tour_1_ActiveTab`] == 3) ? (<a onClick={(e) => e.preventDefault()} className="btn btn-secondary btn-sm text-dark d-flex align-items-center justify-content-between px-2 stage-3-dates font-16px"><i className="far fa-calendar-alt mr-1"></i>{this.state.startDate_places_init} <i className="fas fa-long-arrow-alt-right mx-2"></i> <i className="far fa-calendar-alt mr-1"></i>{this.state.endDate_places_init}</a>) : ''}
                </Nav>
              </div>
              <div className="col-md-12 px-0">
                <div className={classnames({ "d-none": this.state[`Tour_1_ActiveTab`] !== 1, "d-block": this.state[`Tour_1_ActiveTab`] === 1 })}>
                  <Card body className="my-2">
                    {/* <CardTitle className="mb-0">PLACES MANAGEMENT : </CardTitle> */}
                    <Row className="mt-2 mx-0">
                      <div className="col-md-2 col-6 pl-0 pr-1 mb-2">
                        <label className="form-control-label"> Start Date </label>
                        <ReactDatetime
                          ref={`start_date_tour_${unit}`}
                          inputProps={{ placeholder: "Start Date", className: `form-control form-control-sm start_date_tour_${unit}`, readOnly: true }}
                          isValidDate={this.validStartData2}
                          renderInput={this.renderDateInput}
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              this.state[`startDate_places_init`] &&
                              this.state[`endDate_places_init`] &&
                              moment(this.state[`startDate_places_init`])._d + "" === currentDate._d + ""
                            ) {
                              classes += " start-date";
                            } else if (
                              this.state[`startDate_places_init`] &&
                              this.state[`endDate_places_init`] &&
                              new Date(moment(this.state[`startDate_places_init`])._d + "") <
                              new Date(currentDate._d + "") &&
                              new Date(moment(this.state[`endDate_places_init`])._d + "") >
                              new Date(currentDate._d + "")
                            ) {
                              classes += " middle-date";
                            } else if (
                              this.state[`endDate_places_init`] &&
                              moment(this.state[`endDate_places_init`])._d + "" === currentDate._d + ""
                            ) {
                              classes += " end-date";
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          value={this.state[`startDate_places_init`] || ''}
                          onChange={e => { this.setState({ 'startDate_places_init': moment(e).format('YYYY-MM-DD') }); }}
                        />
                      </div>
                      <div className="col-md-2 col-6 pl-0 pr-lg-1 pr-0 mb-2">
                        <label className="form-control-label"> End Date </label>
                        <ReactDatetime
                          ref={`end_date_tour_${unit}`}
                          inputProps={{
                            placeholder: "End Date",
                            className: `form-control form-control-sm end_date_tour_${unit}`,
                            readOnly: true
                          }}
                          isValidDate={this.validEndData2}
                          renderInput={this.renderDateInput}
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              this.state[`startDate_places_init`] &&
                              this.state[`endDate_places_init`] &&
                              moment(this.state[`startDate_places_init`])._d + "" === currentDate._d + ""
                            ) {
                              classes += " start-date";
                            } else if (
                              this.state[`startDate_places_init`] &&
                              this.state[`endDate_places_init`] &&
                              new Date(moment(this.state[`startDate_places_init`])._d + "") <
                              new Date(currentDate._d + "") &&
                              new Date(moment(this.state[`endDate_places_init`])._d + "") >
                              new Date(currentDate._d + "")
                            ) {
                              classes += " middle-date";
                            } else if (
                              this.state[`endDate_places_init`] &&
                              moment(this.state[`endDate_places_init`])._d + "" === currentDate._d + ""
                            ) {
                              classes += " end-date";
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          value={this.state[`endDate_places_init`] || ''}
                          onChange={e => { this.setState({ 'endDate_places_init': moment(e).format('YYYY-MM-DD') }); }}
                        />
                      </div>
                      <div className={classnames({ "pl-0 col-md-3 col-12 pr-lg-1 pr-0": true })}>
                        <label className="form-control-label" htmlFor="places_tour_init"> Dest. Place </label>
                        <CreatableSelect ref={`places_tour_init`} id={`places_tour_init`} className={`form-control form-control-sm p-0 select_${`places`} places_tour_init`} isClearable placeholder={`Select Place`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`places`} options={tourPlaceslistOption} value={this.state[`places_tour_init`]} onChange={(value) => { this.setState({ places_tour_init: value }); this.addNewPlace(tourPlaceslistOption, 'tourPlaceslistOption', value, 'places_tour_init'); }} />
                      </div>
                      <div className="col-md-3 col-12 mb-2 pl-0 pr-lg-1 pr-0">
                        <label className="form-control-label" htmlFor="tour_type"> Tour Type </label>
                        <Select ref={`select_tour_type`} id={`tour_type`} className={`form-control form-control-sm p-0 select_${`tour_type_init`}`} isClearable placeholder={`Tour Type`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`tour_type`} options={food_places_init} value={this.state.select_tour_type} onChange={(value) => { this.setState({ 'select_tour_type': value }); }} />
                        {/* <div className="valid-feedback"></div> */}
                        {/* <div className="invalid-feedback">Tour type is required</div> */}
                      </div>
                      <div className={classnames({ "pl-0 col-md-2 d-flex col-6 mr-0 justify-content-between pr-0": true })}>
                        <div className="mr-1">
                          <label className="form-control-label" htmlFor="food_by"> Food </label>
                          <label className="custom-toggle d-block my-1">
                            <input ref={`tour_id`} className="tour_id_init" value={this.state.tour_id_init} type="hidden" id="tour_id" />
                            <input ref={`food_by`} className="food_places_init" type="checkbox" id="food_by" onChange={() => {
                              let { food_places_init_chk } = this.state;
                              this.setState({ food_places_init_chk: !food_places_init_chk });

                              let food_places_init_inp = document.querySelector('.food_places_init');
                              let select_tour_type = document.querySelector('.select_tour_type_init');
                              let tourTypeOptions = tourTypeslistOption;
                              if (food_places_init_inp && food_places_init_inp.checked) {
                                tourTypeOptions = tourTypeOptions.filter(v => { return v.value != 2 });
                                if (select_tour_type.childNodes[1].value == "2")
                                  this.refs.select_tour_type.select.clearValue();
                              }
                              this.setState({ food_places_init: tourTypeOptions });
                            }} />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </div>
                        {/* </div>
                    <div className={classnames({ "pl-0 pl-lg-1 pr-0 col-md-1 col-6": true })}> */}
                        <div>
                          <label className="form-control-label invisible d-block"> E </label>
                          <button className={classnames({ "showTourPlaces btn btn-primary btn-sm float-right mt-1": true })} onClick={(e) => { this.validateTicketCreationForm(e) }}>Save</button>
                        </div>
                      </div>
                    </Row>
                  </Card>
                  <Card body className={classnames({ "my-2": true, "d-none": !this.state.showTourRows, "d-block": this.state.showTourRows })}>
                    <Row className={classnames({ "d-block": true })}>
                      <CardTitle className="mb-2 mx-0 col">ADD PLACES : </CardTitle>
                      <div className={`col-12`}>
                        {tourRowstemp}
                      </div>
                    </Row>
                    <hr className="mt-4 mb-2" />
                    <div className={`col-12 px-0`}>
                      <button className="btn btn-success btn-sm save-tour-places" onClick={(e) => this.validateTicketCreationForm(e)}>Save</button>
                    </div>
                  </Card>
                </div>
                <div className={classnames({ "d-none": this.state[`Tour_1_ActiveTab`] !== 2, "d-block": this.state[`Tour_1_ActiveTab`] === 2 })}>
                  <Card body className="my-2">
                    {/* <CardTitle className="mb-0">TOUR MANAGEMENT : </CardTitle> */}
                    <Row className="mt-2">
                      <div className={`col-12`}>
                        <TabContent activeTab={this.state[`${`Tour`}ActiveTab`]}>
                          <div className="row m-0">
                            <div className="col-md-3 mb-2 pl-0">
                              <label className="form-control-label" htmlFor="tour_themes"> Tour Theme </label>
                              <Select ref={`select_tour_themes`} id={`tour_themes`} className={`form-control form-control-sm p-0 select_${`tour_themes`}`} isClearable placeholder={`Tour Theme`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`tour_themes`} options={[
                                { "value": 1, "label": "Family" },
                                { "value": 2, "label": "Group" },
                                { "value": 3, "label": "Fixed Departure" },
                                { "value": 4, "label": "Honey moon" }
                              ]} value={this.state.select_tour_themes} onChange={(value) => { this.setState({ 'select_tour_themes': value }); }} />
                              <div className="valid-feedback"></div>
                              <div className="invalid-feedback">Tour Theme is required</div>
                            </div>
                            <div className={classnames({ "col-md-3 mb-2 pl-0": true, "d-none": this.state.select_tour_themes && this.state.select_tour_themes.value !== 2 })}>
                              <label className="form-control-label" htmlFor="group_type"> Group Type </label>
                              <Select ref={`select_group_type`} id={`group_type`} className={`form-control form-control-sm p-0 select_${`group_type`}`} isClearable placeholder={`Group Type`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`group_type`} options={[
                                { "value": 1, "label": "Student" },
                                { "value": 2, "label": "Teacher" },
                                { "value": 3, "label": "Staffs" }
                              ]} value={this.state.select_group_type} onChange={(value) => { this.setState({ 'select_group_type': value }); }} />
                              <div className="valid-feedback"></div>
                              <div className="invalid-feedback">Group Type is required</div>
                            </div>
                            <div className="col-md-3 mb-2 pl-0">
                              <label className="form-control-label" htmlFor="group_count"> Group Count </label>
                              <input id="group_count" value={this.state.group_count} onChange={(e) => { this.setState({ group_count: e.target.value, grp_count_invalid: 'Group Count is required' }); let regex = /^[1-9]\d*(d+)?$/; if (!regex.test(e.target.value)) { addInvalid(e.target); this.setState({ grp_count_invalid: 'Only Numbers Accepted.' }); } else { removeInvalid(e.target); } }} className="form-control form-control-sm group_count" ref="group_count" type="number" />
                              <div className="valid-feedback"></div>
                              <div className="invalid-feedback">{(this.state.grp_count_invalid) ? this.state.grp_count_invalid : 'Group Count is required'}</div>
                            </div>
                            <div className="col-md-2 mb-2 pl-0">
                              <label className="form-control-label" htmlFor="ind_grp_pay"> Payment </label>
                              <label className="custom-toggle d-block my-1 ind_grp_pay_label">
                                <input ref={`tour_tour_id`} className="tour_tour_id" value={this.state.tour_tour_id} type="hidden" id="tour_tour_id" />
                                <input ref={`ind_grp_pay`} className="ind_grp_pay" defaultChecked type="checkbox" id="ind_grp_pay" onChange={() => { this.setState({ pay_type: !this.state.pay_type }) }} />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="Group"
                                  data-label-on="Individual"
                                />
                              </label>
                            </div>
                            <div className="col-md-1 mb-2 px-0">
                              <label className="form-control-label invisible"> G </label>
                              <button className="btn btn-sm btn-primary form-control form-control-sm d-block" onClick={(e) => this.initializeTour(e)}>Go</button>
                            </div>
                          </div>
                        </TabContent>
                      </div>
                    </Row>
                  </Card>
                  <Card body className={classnames({ "my-2 d-block": this.state.tour_tour_id, "d-none": !this.state.tour_tour_id })}>
                    <CardTitle className="mb-0">GROUP MANAGEMENT : </CardTitle>
                    <div className="col-12 px-0 mt-3">
                      {(grpRow.length > 0) ? (<><div className="row mx-0 mb-2">
                        <div className="col-md-4 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
                          <h5 className="mb-0">Group Name</h5>
                        </div>
                        <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
                          <h5 className="mb-0">Group Count</h5>
                        </div>
                        <div className="col-md-2 mb-lg-0 mb-2 pl-0 pr-lg-3 pr-0">
                          <h5 className="mb-0">Amt. Paid (₹)</h5>
                        </div>
                      </div><hr className="my-0 mb-2" /></>) : ''}
                      {grpRow}
                    </div>
                    <div className={`col-12 px-0`}>
                      <hr className="mt-4 mb-2" />
                      <button className="btn btn-success btn-sm" onClick={(e) => this.saveTour(e)}>Save</button>
                    </div>
                  </Card>
                </div>
                <div className={classnames({ "d-none": this.state[`Tour_1_ActiveTab`] !== 3, "d-block": this.state[`Tour_1_ActiveTab`] === 3 })}>
                  <Card body className="my-2">
                    <CardTitle className="mb-0">{(this.state.food_places_init_chk) ? 'FOOD MANAGEMENT :' : 'VENDOR MANAGEMENT :'} </CardTitle>
                    <Row className="mt-2">
                      <div className={`col-12`}>
                        {foodRowstemp}
                      </div>
                      <div className="col-12">
                        <hr className="mt-4 mb-2" />
                        <button className="btn btn-sm btn-success tour_stage_3_save" onClick={() => this.tourStage3Save('.tour_stage_3_save')}>Save</button>
                      </div>
                    </Row>
                  </Card>
                </div>
              </div>
            </Row>
          </TabPane>
        </TabContent>
      );
    });

    if (hasHotelService) {
      servicesLinks.push(
        <NavItem key={`hotel_service_nav`} className="pr-1">
          <NavLink className={classnames({ active: (this.state.activeTab === 'hotel_service'), 'border-0': true, 'py-2': true })}
            onClick={() => {
              this.setState({
                'activeTab': 'hotel_service',
                [`hotel_serviceActiveTab`]: 1
              });
            }}

          > Hotel </NavLink>
        </NavItem>
      );

      servicesPanes.push(
        <TabContent key={`hotel_service`} activeTab={this.state.activeTab}>
          <TabPane tabId={`hotel_service`} className={classnames({ "d-block": (this.state.activeTab === 'hotel_service') })}>
            <Row className="mt-4">
              <div className={`col-12`}>
                <TabContent activeTab={this.state[`hotel_serviceActiveTab`]}>
                  {hotelRowstemp}
                  <div className="col-12 px-0">
                    <hr className="mt-4 mb-2" />
                    <button className="btn btn-sm btn-success hotel_save" onClick={(e) => this.validateTicketCreationForm(e)}>Save</button>
                  </div>
                </TabContent>
              </div>
            </Row>
          </TabPane>
        </TabContent>
      );
    }

    if (hasVehicleService) {
      servicesLinks.push(
        <NavItem key={`vehicle_service_nav`} className="pr-1">
          <NavLink className={classnames({ active: (this.state.activeTab === 'vehicle_service'), 'border-0': true, 'py-2': true })} onClick={() => { this.setState({ 'activeTab': 'vehicle_service', [`vehicle_serviceActiveTab`]: 1, [`Vehicle_1_ActiveTab`]: 1 }); }} > Vehicle </NavLink>
        </NavItem>
      );

      servicesPanes.push(
        <TabContent key={`vehicle_service`} activeTab={this.state.activeTab}>
          <TabPane tabId={`vehicle_service`} className={classnames({ "d-block": (this.state.activeTab === 'vehicle_service') })}>
            <Row className="mt-4">
              <div className={`col-12`}>
                <TabContent activeTab={this.state[`vehicle_serviceActiveTab`]}>
                  <Row className="mt-0 mx-0">
                    <div className={`col-md-12 px-0 d-md-flex justify-content-between`}>
                      <Nav tabs className="nav-pills border-0">
                        <NavItem className="p-0 mr-1">
                          <NavLink className={classnames({ active: (this.state[`Vehicle_1_ActiveTab`] == 1), 'border-0': true, ' px-2 py-2': true })}
                            onClick={() => { this.toggle(1, `Vehicle_1_ActiveTab`); }}>
                            Stage 1
                          </NavLink>
                        </NavItem>
                        {(this.state.vehicle_id) ? (
                          <NavItem className="p-0 mr-1" >
                            <NavLink className={classnames({ active: (this.state[`Vehicle_1_ActiveTab`] == 2), 'border-0': true, ' px-2 py-2': true })}
                              onClick={() => { this.toggle(2, `Vehicle_1_ActiveTab`); }}>
                              Stage 2
                            </NavLink>
                          </NavItem>
                        ) : ''}
                        {(this.state.vehicle_pack_id) ? (
                          <NavItem className={classnames({ "p-0 mr-1": true })}>
                            <NavLink className={classnames({ active: (this.state[`Vehicle_1_ActiveTab`] == 3), 'border-0': true, ' px-2 py-2': true })}
                              onClick={() => { this.toggle(3, `Vehicle_1_ActiveTab`); }}>
                              Stage 3
                            </NavLink>
                          </NavItem>
                        ) : ''}
                        {(this.state.vehicle_stage_3_id) ? (
                          <NavItem className={classnames({ "p-0 mr-1": true })}>
                            <NavLink className={classnames({ active: (this.state[`Vehicle_1_ActiveTab`] == 4), 'border-0': true, ' px-2 py-2': true })}
                              onClick={() => { this.toggle(4, `Vehicle_1_ActiveTab`); }}>
                              Stage 4
                            </NavLink>
                          </NavItem>
                        ) : ''}
                      </Nav>
                      <div className={`${this.state[`Vehicle_1_ActiveTab`] === 2 ? '' : 'd-none'}`}>
                        <button className={`btn btn-sm rounded ${!toggle_vehicle_facility ? 'bg-gradient-warning' : 'bg-gradient-info'} d-flex justify-content-between align-items-center mt-md-0 mt-2`}>
                          <span className={`${!toggle_vehicle_facility ? 'text-white' : 'text-white'} mr-1`}>Non A/C</span>
                          <label className="custom-toggle vehicle_facility_toggle vehicle_facility_wrap d-block mr-2">
                            <input type="checkbox" defaultChecked ref={`toggle_vehicle_facility`} id={`toggle_vehicle_facility`} onChange={(value) => { this.ontoggleshandlechange(value, `toggle_vehicle_facility`) }} />
                            <span className="custom-toggle-slider rounded-circle" data-label-off="" data-label-on="" />
                          </label>
                          <span className={`${toggle_vehicle_facility ? 'text-white' : 'text-white'} ml-1`}>A/C</span>
                        </button>
                      </div>
                      <div className={`${this.state[`Vehicle_1_ActiveTab`] === 3 ? '' : 'd-none'}`}>
                        <div className="d-flex justify-content-between align-items-center bg-gradient-neutral shadow p-2 rounded">
                          <span>{vehicleStart.value}</span>
                          <i className="fas fa-arrow-right mx-2"></i>
                          <span>{vehicleEnd.value}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 px-0">
                      <div className={classnames({ "d-none": this.state[`Vehicle_1_ActiveTab`] !== 4, "d-block": this.state[`Vehicle_1_ActiveTab`] === 4 })}>
                        <div className="row mx-0 mt-3 mb-2">
                          <div className="col-md-2 pl-md-0 pr-md-1 px-0 mb-md-0 mb-2"> <h4 className="mb-0">Details</h4> </div>
                          <div className="col-md-2 px-0 mb-md-0 mb-2 px-md-1"> <h4 className="mb-0">Cust. Paid</h4> </div>
                          <div className="col-md-2 px-0 mb-md-0 mb-2 px-md-1"> <h4 className="mb-0">E-Route. Paid</h4> </div>
                          <div className="col-md-2 px-0 mb-md-0 mb-2 px-md-1"> <h4 className="mb-0">Paid By</h4> </div>
                          <div className="col-md-4 pl-md-1 px-0 pr-md-0 d-block d-md-flex">
                            <div className="col px-0 mb-md-0 mb-2 px-md-0"> <h4 className="mb-0">Remarks</h4> </div>
                            <div className="col-auto px-0 mb-md-0 mb-2 pl-md-1 pr-md-0"> <h4 className="mb-0">Actions</h4> </div>
                          </div>
                        </div>
                        {(this.state.vehicle_id && this.state.vehicle_stage_3_id) ? (
                          <VehicleRow vehicle_init={this.state.vehicle_id} optiondata={this.state.vendorinit} setStater={(stateName, value) => this.setState({ [`${stateName}`]: value })} />
                        ) : ''}
                      </div>
                      <div className={classnames({ "d-none": this.state[`Vehicle_1_ActiveTab`] !== 3 || !_dataPack, "d-block": this.state[`Vehicle_1_ActiveTab`] === 3 && _dataPack })}>
                        <Card body className="mt-5">
                          <Card body className="bg-gradient-success mt--5 mb-0 p-3 shadow-lg">
                            <Row className="mx-0">
                              <div className="col-12 px-0 mb-0 d-flex justify-content-between">
                                <h3 className="mb-0 text-white">{_dataPack && _dataPack.name}</h3>
                                <p className="mb-0 text-white">₹{_dataPack && _dataPack.price.toFixed(2)}</p>
                                {/* <Row className="mx-0">
                                <Col md="3" className="px-1 mb-lg-0 mb-2">
                                  <div className="rounded px-2 py-2 bg-gradient-secondary d-flex justify-content-between">
                                  <h4 className="mb-0 text-dark">One:</h4>
                                  <p className="mb-0 text-dark">$ 7.00</p>
                                  </div>
                                </Col>
                                <Col md="3" className="px-1 mb-lg-0 mb-2">
                                  <div className="rounded px-2 py-2 bg-gradient-secondary d-flex justify-content-between">
                                  <h4 className="mb-0 text-dark">One:</h4>
                                  <p className="mb-0 text-dark">$ 7.00</p>
                                  </div>
                                </Col>
                                <Col md="3" className="px-1 mb-lg-0 mb-2">
                                  <div className="rounded px-2 py-2 bg-gradient-secondary d-flex justify-content-between">
                                  <h4 className="mb-0 text-dark">One:</h4>
                                  <p className="mb-0 text-dark">$ 7.00</p>
                                  </div>
                                </Col>
                                <Col md="3" className="px-1 mb-lg-0 mb-2">
                                  <div className="rounded px-2 py-2 bg-gradient-secondary d-flex justify-content-between">
                                  <h4 className="mb-0 text-dark">One:</h4>
                                  <p className="mb-0 text-dark">$ 7.00</p>
                                  </div>
                                </Col>
                              </Row> */}
                              </div>
                            </Row>
                          </Card>
                          <Row className="mx-0 mt-4">
                            <div className="col-md-3 col-12 px-1 mb-2">
                              <label className="form-control-label mb-0"> Start Odometer </label>
                              <input className="sOdometer form-control form-control-sm" value={sOdometer} onChange={(e) => { this.setState({ sOdometer: e.currentTarget.value || 0 }); this.setVehiclePrices(); }} />
                            </div>
                            <div className="col-md-3 col-12 px-1 mb-2">
                              <label className="form-control-label mb-0"> End Odometer </label>
                              <input className="eOdometer form-control form-control-sm" value={eOdometer} onChange={(e) => { this.setState({ eOdometer: e.currentTarget.value || 0 }); this.setVehiclePrices(); }} />
                            </div>
                            <div className="col-md-3 col-12 px-1 mb-2">
                              <label className="form-control-label mb-0"> Distance </label>
                              <input className="distanceCovered form-control form-control-sm bg-secondary" readOnly value={eOdometer - sOdometer} />
                            </div>
                            <div className="col-md-3 col-12 px-1 mb-2">
                              <label className="form-control-label mb-0"> Timing (in Hours) </label>
                              <input className="distanceCovered form-control form-control-sm bg-secondary" readOnly value={vTiming} />
                            </div>
                          </Row>
                          <hr className="my-2" />
                          <Row className="mx-0">
                            <div className="col-md-12 px-1">
                              <h3>Calculations</h3>
                            </div>
                            {((_dataPack && _dataPack.name && _dataPack.name === "Kilometer") || (_dataPack && _dataPack.name && _dataPack.name === "Hourly")) ? (
                              <div className="col-12 px-1 mb-2">
                                <div className="mb-2 row mx-0">
                                  <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                    <label className="form-control-label mb-0"> KM Charge (Original) </label>
                                    <p className="bg-secondary okmCharge px-3 border rounded mb-0">
                                      {kmChargeOrig}
                                    </p>
                                  </div>
                                  <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                    <label className="form-control-label mb-0"> KM Charge (Actual) </label>
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span className={`input-group-text ${akmChargeFocus ? 'border-primary' : ''}`} id="inputGroup-sizing-sm">₹</span>
                                      </div>
                                      <input className={`border-left-0 pl-0 akmCharge form-control form-control-sm ${akmChargeState}`} value={akmCharge || ''} onChange={(e) => { this.validateActual(`akmCharge`, e.currentTarget.value, /^[0-9]\d{0,9}(\.\d{1,2})?%?$/, `akmChargeState`); this.setVehiclePrices(); }} onFocus={() => this.setState({ akmChargeFocus: true })} onBlur={(e) => this.setState({ akmChargeFocus: false, akmCharge: parseFloat(e.currentTarget.value) })} />
                                    </div>
                                  </div>
                                  <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                    <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                    <p className="bg-secondary akmCharge px-3 border rounded mb-0">
                                      {kmChargeText2}
                                    </p>
                                  </div>
                                  <div className="col-md-1 px-1 mb-lg-0 mb-2">
                                    <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                    <p className="akmCharge mb-0 text-center">=</p> </div>
                                  <div className="col-md-2 px-1 mb-lg-0 mb-2">
                                    <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                    <p className="bg-secondary akmCharge px-3 border rounded mb-0">
                                      ₹ {kmChargeValue}</p>
                                  </div>
                                </div>
                              </div>
                            ) : ''}
                            <div className="col-12 px-1 mb-2">
                              <div className="mb-2 row mx-0">
                                <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0"> Car Charge (Original) </label>
                                  <p className="bg-secondary oCarCharge px-3 border rounded mb-0">
                                    {cChargeOrig}
                                  </p>
                                </div>
                                <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0"> Car Charge (Actual) </label>
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text ${aCarChargeFocus ? 'border-primary' : ''}`} id="inputGroup-sizing-sm">₹</span>
                                    </div>
                                    <input className={`border-left-0 pl-0 aCarCharge form-control form-control-sm ${aCarChargeState}`} value={aCarCharge || ''} onChange={(e) => { this.validateActual(`aCarCharge`, e.currentTarget.value, /^[0-9]\d{0,9}(\.\d{1,2})?%?$/, `aCarChargeState`); this.setVehiclePrices(); }} onFocus={() => this.setState({ aCarChargeFocus: true })} onBlur={(e) => this.setState({ aCarChargeFocus: false, aCarCharge: parseFloat(e.currentTarget.value) })} />
                                  </div>
                                </div>
                                <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                  <p className="bg-secondary aCarCharge px-3 border rounded mb-0">
                                    {cChargeText2}
                                  </p>
                                </div>
                                <div className="col-md-1 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                  <p className="aCarCharge mb-0 text-center">=</p> </div>
                                <div className="col-md-2 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                  <p className="bg-secondary aCarCharge px-3 border rounded mb-0">
                                    ₹ {cChargeValue}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 px-1 mb-2">
                              <div className="mb-2 row mx-0">
                                <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0"> Driver Charge (Original) </label>
                                  <p className="bg-secondary oDriverCharge px-3 border rounded mb-0">
                                    {dChargeOrig}
                                  </p>
                                </div>
                                <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0"> Driver Charge (Actual) </label>
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text ${aDriverChargeFocus ? 'border-primary' : ''}`} id="inputGroup-sizing-sm">₹</span>
                                    </div>
                                    <input className={`border-left-0 pl-0 aDriverCharge form-control form-control-sm ${aDriverChargeState}`} value={aDriverCharge || ''} onChange={(e) => { this.validateActual(`aDriverCharge`, e.currentTarget.value, /^[0-9]\d{0,9}(\.\d{1,2})?%?$/, `aDriverChargeState`); this.setVehiclePrices(); }} onFocus={() => this.setState({ aDriverChargeFocus: true })} onBlur={(e) => this.setState({ aDriverChargeFocus: false, aDriverCharge: parseFloat(e.currentTarget.value) })} />
                                  </div>
                                </div>
                                <div className="col-md-3 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                  <p className="bg-secondary aDriverCharge px-3 border rounded mb-0">
                                    {dChargeText2}
                                  </p>
                                </div>
                                <div className="col-md-1 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                  <p className="aDriverCharge mb-0 text-center">=</p> </div>
                                <div className="col-md-2 px-1 mb-lg-0 mb-2">
                                  <label className="form-control-label mb-0 invisible d-md-block d-none mb-1"> K </label>
                                  <p className="bg-secondary aDriverCharge px-3 border rounded mb-0">
                                    ₹ {dChargeValue}</p>
                                </div>
                              </div>
                            </div>
                            <div className="offset-md-6 col-md-6 col-12 px-1 mb-2">
                              <div className="mb-2 row mx-0">
                                <div className="col-6 px-1 mb-2">
                                </div>
                                <div className="col-6 px-1 mb-2">
                                  <label className="form-control-label mb-0"> Discount </label>
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span className={`input-group-text ${oDiscountFocus ? 'border-primary' : ''}`} id="inputGroup-sizing-sm">₹</span>
                                    </div>
                                    <input className={`border-left-0 pl-0 oDiscount form-control form-control-sm ${oDiscountState}`} value={oDiscount || ''} onChange={(e) => { this.validateActual(`oDiscount`, e.currentTarget.value, /^[0-9]\d{0,9}(\.\d{1,2})?%?$/, `oDiscountState`); this.setVehiclePrices(); }} onFocus={() => this.setState({ oDiscountFocus: true })} onBlur={(e) => this.setState({ oDiscountFocus: false, oDiscount: e.currentTarget.value && parseFloat(e.currentTarget.value).toFixed(2) || '0' })} />
                                  </div>
                                  {/* this.setState({ oDiscount: (e.currentTarget.value) }) */}
                                </div>
                              </div>
                            </div>
                          </Row>
                          <hr className="my-2" />
                          <Row>
                            <div className="offset-md-9 col-md-3 col-12 mb-2">
                              <div className="d-flex justify-content-between align-items-end">
                                <small>Total : </small>
                                <h4 className="display-4 mb-0">₹
                                  {oTotal}
                                </h4>
                              </div>
                            </div>
                          </Row>
                          {oTotal ? (
                            <Row>
                              <div className="col-md-12">
                                <button className="btn btn-sm btn-primary vehicle_save_stage_3" onClick={(e) => this.validateTicketCreationForm(e)}>Save</button>
                              </div>
                            </Row>
                          ) : ''}
                        </Card>

                      </div>
                      <div className={classnames({ "d-none": this.state[`Vehicle_1_ActiveTab`] !== 1, "d-block": this.state[`Vehicle_1_ActiveTab`] === 1 })}>
                        <Card body className="my-2">
                          {/* <CardTitle className="mb-0">PLACES MANAGEMENT : </CardTitle> */}
                          <Row className="mt-2 mx-0">
                            <div className="col-md-3 col-12 px-1 mb-lg-0 mb-2">
                              <label className="form-control-label mb-0"> Vehicle </label>
                              <Select ref={`select_vehicle`} id={`select_vehicle`} className={`form-control form-control-sm p-0 select_${`vehicle`} select_vehicle`} isClearable placeholder={`Select Vehicle`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`places`} options={vehicleOptions} value={this.state[`select_vehicle`]} onChange={(value) => { this.setState({ select_vehicle: value }); }} />
                              {/*  */}
                            </div>
                            <div className="col-md-3 px-1 mb-lg-0 mb-2">
                              <label className="form-control-label mb-0"> Start Date &amp; Time </label>
                              <ReactDatetime isValidDate={(current) => this.validFinStartDate(current, vehicleEnd.value)} dateFormat={`YYYY-MM-DD h:mm a`} closeOnSelect={false} className={`form-control form-control-sm p-0 h-auto ${(vehicleStart.state === 'valid') ? 'is-valid' : ((vehicleStart.state === 'invalid')) ? 'is-invalid' : ''}`} inputProps={{ placeholder: '2019-07-28 10:30 AM', className: `form-control form-control-sm border-0`, readOnly: true, id: `${`vehicleStart`}_dates`, value: vehicleStart.value }} timeFormat={true} onChange={value => { this.getDifference(vehicleEnd.value, moment(value).format('YYYY-MM-DD h:mm a')); this.setState({ vehicleStart: { state: 'valid', value: moment(value).format('YYYY-MM-DD h:mm a') } }); }} />
                            </div>

                            <div className="col-md-3 px-1 mb-lg-0 mb-2">
                              <label className="form-control-label mb-0"> End Date &amp; Time </label>
                              <ReactDatetime isValidDate={(current) => this.validFinEndDate(current, vehicleStart.value)} dateFormat={`YYYY-MM-DD h:mm a`} closeOnSelect={false} className={`form-control form-control-sm p-0 h-auto ${(vehicleEnd.state === 'valid') ? 'is-valid' : ((vehicleEnd.state === 'invalid')) ? 'is-invalid' : ''}`} inputProps={{ placeholder: '2019-07-28 10:30 AM', className: `form-control form-control-sm border-0`, readOnly: true, id: `${`vehicleEnd`}_dates`, value: vehicleEnd.value }} timeFormat={true} onChange={value => { this.getDifference(moment(value).format('YYYY-MM-DD h:mm a'), vehicleStart.value); this.setState({ vehicleEnd: { state: 'valid', value: moment(value).format('YYYY-MM-DD h:mm a') } }); }} />
                            </div>
                            <div className="col-md-3 px-1 mb-lg-0 mb-2">
                              <label className="form-control-label mb-1 invisible d-block"> S </label>
                              <button className="btn btn-sm btn-success vehicle_save vehicle_save_stage_1" onClick={(e) => { this.validateTicketCreationForm(e); }}>Save</button>
                            </div>
                          </Row>
                        </Card>
                      </div>
                      {select_vehicle && select_vehicle['value'] && _vehicleList && _vehicleList[select_vehicle['value']] ? (
                        <div className={classnames({ "d-none": this.state[`Vehicle_1_ActiveTab`] !== 2, "d-block": this.state[`Vehicle_1_ActiveTab`] === 2 })}>
                          <div className="my-4">
                            {/* <Card body className="my-2"> */}
                            {/* <CardTitle className="mb-0">PLACES MANAGEMENT : </CardTitle> */}
                            <Row className="mt-2 mx-0">
                              {/* <div className="col-md-12 px-0 mb-2 d-flex align-items-center justify-content-between"> */}
                              {/* <div className="pricing card-group flex-column flex-md-row mb-3"> */}
                              <div className="col-lg-3 col-md-6 px-1">
                                <Card className={`card-pricing ${_vehiclePackage === 1 ? 'bg-translucent-primary shadow-lg zoom-in-sm' : ''} border-0 text-center mb-lg-0 mb-3`}>
                                  <CardHeader className="bg-transparent">
                                    <h4 className={`text-uppercase ls-1 text-translucent-primary py-3 mb-0 ${_vehiclePackage === 1 ? 'text-white' : ''}`}> Hourly </h4>
                                  </CardHeader>
                                  <CardBody className="px-lg-3">
                                    <div className={`text-dark font-weight-bold ${_vehiclePackage === 1 ? 'text-white' : ''}`}>₹{toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.perkm_hourly.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.perkm_hourly.toFixed(2)}</div>
                                    <span className={`${_vehiclePackage === 1 ? 'text-white' : 'text-muted'}`}>/ Kilometer</span>
                                    <ul className="list-unstyled my-4">
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left"> <div>
                                          <div className={`icon icon-xs icon-shape ${_vehiclePackage === 1 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-user" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 1 ? 'text-white' : ''}`}>No Driver Beta</p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 1 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-taxi" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 1 ? 'text-white' : ''}`}>Based on Hours</p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 1 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-hand-holding-usd" /> </div>
                                          </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 1 ? 'text-white' : ''}`}> Single Price </p> </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <Button className={` ${_vehiclePackage !== 1 ? 'bg-translucent-primary text-white border-primary' : ''} btn-sm`} type="button" onClick={(e) => { this.selectVehiclePackage(e, 1) }}> {_vehiclePackage === 1 ? 'Selected' : 'Select Package'} </Button>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-3 col-md-6 px-1">
                                <Card className={`card-pricing ${_vehiclePackage === 2 ? 'bg-translucent-primary shadow-lg zoom-in-sm' : ''} rounded border-0 text-center mb-lg-0 mb-3`}>
                                  <CardHeader className="bg-transparent">
                                    <h4 className={`text-uppercase ls-1 text-translucent-primary py-3 mb-0 ${_vehiclePackage === 2 ? 'text-white' : ''}`}> Daily </h4>
                                  </CardHeader>
                                  <CardBody className="px-lg-3">
                                    <div className={`text-dark font-weight-bold ${_vehiclePackage === 2 ? 'text-white' : ''}`}>₹{toggle_vehicle_facility ? (_vehicleList[select_vehicle['value']].vehicle_ac_info.crent_daily + _vehicleList[select_vehicle['value']].vehicle_ac_info.drent_daily + (_vehicleList[select_vehicle['value']].vehicle_ac_info.perkm_daily * 350)).toFixed(2) : (_vehicleList[select_vehicle['value']].vehicle_non_ac_info.crent_daily + _vehicleList[select_vehicle['value']].vehicle_non_ac_info.drent_daily + (_vehicleList[select_vehicle['value']].vehicle_non_ac_info.perkm_daily * 350)).toFixed(2)}</div>
                                    <span className={`${_vehiclePackage === 2 ? 'text-white' : 'text-muted'}`}>/ 12 Hours </span>
                                    <ul className="list-unstyled my-4">
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 2 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-user" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 2 ? 'text-white' : ''}`}> Driver Beta: ₹{toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.drent_daily.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.drent_daily.toFixed(2)}</p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 2 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-taxi" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 2 ? 'text-white' : ''}`}> Per Km Charge: ₹{toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.perkm_daily.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.perkm_daily.toFixed(2)} </p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 2 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-hand-holding-usd" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 2 ? 'text-white' : ''}`}> Car Rent: ₹{toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.crent_daily.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.crent_daily.toFixed(2)}</p> </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <Button className={` ${_vehiclePackage !== 2 ? 'bg-translucent-primary text-white border-primary' : ''} btn-sm`} type="button" onClick={(e) => { this.selectVehiclePackage(e, 2) }}> {_vehiclePackage === 2 ? 'Selected' : 'Select Package'} </Button>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-3 col-md-6 px-1">
                                <Card className={`card-pricing ${_vehiclePackage === 3 ? 'bg-translucent-primary shadow-lg zoom-in-sm' : ''} border-0 text-center mb-lg-0 mb-3`}>
                                  <CardHeader className="bg-transparent">
                                    <h4 className={`text-uppercase ls-1 text-translucent-primary py-3 mb-0 ${_vehiclePackage === 3 ? 'text-white' : ''}`}> Kilometer </h4>
                                  </CardHeader>
                                  <CardBody className="px-lg-3">
                                    <div className={`text-dark font-weight-bold ${_vehiclePackage === 3 ? 'text-white' : ''}`}>₹{toggle_vehicle_facility ? (_vehicleList[select_vehicle['value']].vehicle_ac_info.crent_kilometer + _vehicleList[select_vehicle['value']].vehicle_ac_info.drent_kilometer + (_vehicleList[select_vehicle['value']].vehicle_ac_info.perkm_kilometer * 350)).toFixed(2) : (_vehicleList[select_vehicle['value']].vehicle_non_ac_info.crent_kilometer + _vehicleList[select_vehicle['value']].vehicle_non_ac_info.drent_kilometer + (_vehicleList[select_vehicle['value']].vehicle_non_ac_info.perkm_kilometer * 350)).toFixed(2)}</div>
                                    <span className={`${_vehiclePackage === 3 ? 'text-white' : 'text-muted'}`}>/ 350 Km</span>
                                    <ul className="list-unstyled my-4">
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left"> <div>
                                          <div className={`icon icon-xs icon-shape ${_vehiclePackage === 3 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-user" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 3 ? 'text-white' : ''}`}> Driver Beta: ₹{toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.drent_kilometer.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.drent_kilometer.toFixed(2)}</p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 3 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-taxi" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 3 ? 'text-white' : ''}`}> Per Km Charge: ₹{toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.perkm_kilometer.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.perkm_kilometer.toFixed(2)} </p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 3 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-hand-holding-usd" /> </div>
                                          </div>
                                          {/* <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 3 ? 'text-white' : ''}`}> Car Rent: ₹{(toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.crent_kilometer.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.crent_kilometer.toFixed(2)) || 'No Car Rent'} </spanp</div> */}
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 3 ? 'text-white' : ''}`}> {'No Car Rent'} </p> </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <Button className={` ${_vehiclePackage !== 3 ? 'bg-translucent-primary text-white border-primary' : ''} btn-sm`} type="button" onClick={(e) => { this.selectVehiclePackage(e, 3) }}> {_vehiclePackage === 3 ? 'Selected' : 'Select Package'} </Button>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-3 col-md-6 px-1">
                                <Card className={`card-pricing ${_vehiclePackage === 4 ? 'bg-translucent-primary shadow-lg zoom-in-sm' : ''} border-0 text-center mb-lg-0 mb-3`}>
                                  <CardHeader className="bg-transparent">
                                    <h4 className={`text-uppercase ls-1 text-translucent-primary py-3 mb-0 ${_vehiclePackage === 4 ? 'text-white' : ''}`}> Fixed </h4>
                                  </CardHeader>
                                  <CardBody className="px-lg-3">
                                    <div className={`text-dark font-weight-bold ${_vehiclePackage === 4 ? 'text-white' : ''}`}>₹{toggle_vehicle_facility ? (_vehicleList[select_vehicle['value']].vehicle_ac_info.crent_fixed + _vehicleList[select_vehicle['value']].vehicle_ac_info.drent_fixed + (_vehicleList[select_vehicle['value']].vehicle_ac_info.perkm_fixed * 350)).toFixed(2) : (_vehicleList[select_vehicle['value']].vehicle_non_ac_info.crent_fixed + _vehicleList[select_vehicle['value']].vehicle_non_ac_info.drent_fixed + (_vehicleList[select_vehicle['value']].vehicle_non_ac_info.perkm_fixed * 350)).toFixed(2)}</div>
                                    <span className={`${_vehiclePackage === 4 ? 'text-white' : 'text-muted'}`}>/ Day</span>
                                    <ul className="list-unstyled my-4">
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left"> <div>
                                          <div className={`icon icon-xs icon-shape ${_vehiclePackage === 4 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-user" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 4 ? 'text-white' : ''}`}>No Driver Beta</p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 4 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-taxi" /> </div> </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 4 ? 'text-white' : ''}`}> No Car Rent </p> </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-left">
                                          <div> <div className={`icon icon-xs icon-shape ${_vehiclePackage === 4 ? 'text-muted bg-white' : 'text-white bg-translucent-primary'} rounded-circle`}> <i className="fas fa-hand-holding-usd" /> </div>
                                          </div>
                                          <div> <p className={`mb-0 medium pl-2 ${_vehiclePackage === 4 ? 'text-white' : ''}`}>Car Rent: ₹{toggle_vehicle_facility ? _vehicleList[select_vehicle['value']].vehicle_ac_info.crent_fixed.toFixed(2) : _vehicleList[select_vehicle['value']].vehicle_non_ac_info.crent_fixed.toFixed(2)}</p> </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <Button className={` ${_vehiclePackage !== 4 ? 'bg-translucent-primary text-white border-primary' : ''} btn-sm`} type="button" onClick={(e) => { this.selectVehiclePackage(e, 4) }}> {_vehiclePackage === 4 ? 'Selected' : 'Select Package'} </Button>
                                  </CardBody>
                                </Card>
                              </div>
                              {_vehiclePackage ? (
                                <div className="col-md-12 px-1 mt-3">
                                  <button className="btn btn-sm btn-success vehicle_save vehicle_save_stage_2" onClick={(e) => { this.validateTicketCreationForm(e); }}>Save</button>
                                </div>
                              ) : ''}
                            </Row>
                            {/* </Card> */}
                          </div>
                        </div>) : ''}
                    </div>
                  </Row>
                  {/* <div className="col-12 px-0">
                  <hr className="mt-4 mb-2"/>
                  <button className="btn btn-sm btn-success vehicle_save" onClick={(e) => this.validateTicketCreationForm(e) }>Save</button>
                </div> */}
                </TabContent>
              </div>
            </Row>
          </TabPane>
        </TabContent>
      );
    }

    if (hasInsuranceService) {
      servicesLinks.push(
        <NavItem key={`insurance_service_nav`} className="pr-1">
          <NavLink className={classnames({ active: (this.state.activeTab === 'insurance_service'), 'border-0': true, 'py-2': true })}
            onClick={() => {
              this.setState({
                'activeTab': 'insurance_service',
                [`insurance_serviceActiveTab`]: 1
              });
            }}

          > Insurance </NavLink>
        </NavItem>
      );
    }

    servicesLinks.push(
      <NavItem key={`special_service_nav`} className="pr-1">
        <NavLink className={classnames({ active: (this.state.activeTab === 'special_service'), 'border-0': true, 'py-2': true })} onClick={() => {
          this.setState({
            'activeTab': 'special_service',
            [`special_serviceActiveTab`]: 1
          });
        }}> Special Service </NavLink>
      </NavItem>
    );

    if (hasHotelService) {
      serviceStatusLinks.push(
        <Select ref={`select_hotel_status`} id={`hotel_status`} className={`form-control form-control-sm select_${`hotel_status`} p-0 float-right h-100 ${(this.state.activeTab === 'hotel_service') ? 'd-block' : 'd-none'}`} isClearable placeholder={`Tour Status`} components={{ Option: IconOption }} isSearchable isMulti={false} name={`hotel_status`} options={statusOptions} value={this.state.select_hotel_status || { value: '1', label: 'Open' }} isDisabled={this.state.select_hotel_status ? false : true} onChange={(value) => { this.setState({ 'select_hotel_status': value }); }} />
      );
    }

    let tempSpecials = [];
    let InsuranceService = [];
    // const { specialState } = this.state;
    // if(specialState && specialState.ticket && specialState.ticket.data && isArray(specialState.ticket.data)){
    for (let tempj = 0; tempj <= this.state.specialCount; tempj++) {
      tempSpecials.push(<SpecialRow setStater={(stateName, data) => { let states = this.state; states[`${stateName}`] = data; this.setState({ states }); }} specialState={specialState} data={specialState && specialState.ticket && specialState.ticket.data && isArray(specialState.ticket.data) && specialState.ticket.data[tempj]} ticket_id={this.state.e_ticket_id} jsUcfirst={(text) => this.jsUcfirst(text)} notify={(pos, title, text) => this.notify(pos, title, text)} key={tempj} unit={tempj} optiondata={this.state.vendorinit} isLast={this.state.specialCount === tempj} isLastClick={() => this.setState({ specialCount: this.state.specialCount + 1 })} fUpdate={() => this.forceUpdate()} validateTicketCreationForm={(e) => this.validateTicketCreationForm(e)} />);
    }
    // }
    for (let tempj1 = 0; tempj1 < this.state.InCount; tempj1++) {
      InsuranceService.push(<InsuranceRow setStater={(stateName, data) => {
        let states = this.state; states[`${stateName}`] = data;
        this.setState({ states });
      }}
        insuranceState={this.state && this.state.insuranceState}
        data={this.state && this.state.insuranceState && this.state.insuranceState.list && this.state.insuranceState.list.data && isArray(this.state.insuranceState.list.data) && this.state.insuranceState.list.data[tempj1]}
        ticket_id={this.state.e_ticket_id}
        jsUcfirst={(text) => this.jsUcfirst(text)}
        notify={(pos, title, text) => this.notify(pos, title, text)}
        key={tempj1} unit={tempj1} optiondata={this.state.vendorinit}
        getbilling={this.state.billing}
        billingstater={(stateName, data) => { this.setState({ [`${stateName}`]: data }); }}
        isLast={this.state.InCount === tempj1 + 1}
        isLastClickin={() => this.setState({ InCount: this.state.InCount + 1 })}
        fUpdate={() => this.forceUpdate()}
        customer_id={this.state.e_cust_id}
        validateTicketCreationForm={(e) => this.validateTicketCreationForm(e)}
      />);
    }

    servicesPanes.push(
      <TabContent key={`special_service`} activeTab={this.state.activeTab}>
        <TabPane tabId={`special_service`} className={classnames({ "d-block": (this.state.activeTab === 'special_service') })}>
          <Row className="mt-4">
            <div className={`col-12`}>
              <TabContent activeTab={this.state[`special_serviceActiveTab`]}>
                {/* {servicesPane} */}
                <div className="">
                  <table className="align-items-center table-flush table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th className="sort px-1 py-2">Details</th>
                        <th className="sort px-1 py-2 text-center">Cust. Paid</th>
                        <th className="sort px-1 py-2 text-center">E-Route Paid</th>
                        <th className="sort px-1 py-2 text-center">Paid By</th>
                        <th className="sort px-1 py-2 text-center">Remarks</th>
                        {/* <th className="sort px-1 py-2 text-center">CR/DR</th> */}
                        <th className="sort px-1 py-2 text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tempSpecials}
                    </tbody>
                  </table>
                </div>
              </TabContent>
            </div>
          </Row>
        </TabPane>
      </TabContent>
    );

    servicesPanes.push(
      <TabContent key={`insurance_service`} activeTab={this.state.activeTab}>
        <TabPane tabId={`insurance_service`} className={classnames({ "d-block": (this.state.activeTab === 'insurance_service') })}>
          <Row className="mt-4">
            <div className={`col-12`}>
              <TabContent activeTab={this.state[`insurance_serviceActiveTab`]}>
                {/* {servicesPane} */}
                <div className="">
                  <table className="align-items-center table-flush table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th className="sort px-1 py-2">Name</th>
                        <th className="sort px-1 py-2 text-center">Insurance No.</th>
                        <th className="sort px-1 py-2 text-center">Amount</th>
                        <th className="sort px-1 py-2 text-center">Paid By</th>
                        {/* <th className="sort px-1 py-2 text-center">Remarks</th> */}
                        <th className="sort px-1 py-2 ">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {InsuranceService}
                    </tbody>
                  </table>
                </div>
              </TabContent>
            </div>
          </Row>
        </TabPane>
      </TabContent>
    );

    const footerbuttons = [];
    // if(!this.state.saveButtonHide){
    footerbuttons.push(<Button
      className="mt-2 save-button"
      color="primary"
      type="button"
      key="root-save-button"
      ref="saveButton"
      size="sm"
      onClick={e => { this.validateTicketCreationForm(e) }}
    >
      Save
    </Button>);
    // }


    // if(this.state.saveButtonHide){
    //   footerbuttons.push(<Button
    //     className="mt-2 save-button"
    //     color="primary"
    //     type="button"
    //     ref="saveButton"
    //     size="sm"
    //     onClick={(e) => { this.leadToCustomer(e) }}
    //   >
    //     Lead to Customer
    //   </Button>);
    // }

    const MultiValueContainer = ({ children, ...props }) => {
      return (
        <components.MultiValueContainer {...props}>
          <span><img src="https://cdn.dribbble.com/users/102974/screenshots/2726841/head_bob.gif" className="img-fluid" /></span>
          {children}
        </components.MultiValueContainer>
      )
    };

    const { documenttype } = this.props;
    const doc_type_options = [];
    const doc_type_list = [];
    doc_type.forEach((v, i) => {
      let imgSrc = '';
      let imgAlt = '';
      let imgWrapClass = 'd-none';
      if (v.files) {
        imgSrc = "...";
        imgAlt = "...";
        if (v.files.type.indexOf('image') !== -1) {
          imgSrc = URL.createObjectURL(v.files);
        }
        imgWrapClass = 'd-flex';
      }

      doc_type_list.push(
        <div className="row mt-4 mx-5 py-2 px-3 border rounded" key={i}>
          <div className="col-3 pl-0 d-flex align-items-center">
            <label className="form-control-label mb-0" htmlFor={`${v.value.replace(/ /g, '_')}_${i}`}>{v.value}</label>
          </div>
          <div className="col-3 d-none">
            <input type="file" className={`${v.value.replace(/ /g, '_')}_file_${i}`} onChange={(e) => { this.loadImage(e, v.value.replace(/ /g, '_'), v.id) }} name={`${v.value.replace(/ /g, '_')}_file_${i}`} />
          </div>
          <div className={`col d-flex ${v.value.replace(/ /g, '_')}_preview_${i} align-items-center`}>
            <div className={`avatar avatar-sm mr-2 ${imgWrapClass}`}>
              <img className={`${v.value.replace(/ /g, '_')}_img_${i} h-100 avatar-img rounded`} src={`${imgSrc}`} alt={`${imgAlt}`} />
            </div>

            <Button className="border-0 h-100" size="sm" type="button" onClick={(e) => this.openBrowseButton(e, v.value.replace(/ /g, '_'), v.id)} color="primary" name={`${v.value.replace(/ /g, '_')}_browse_${i}`}>
              <span className="btn-inner--icon">
                <i className="ni ni-cloud-upload-96" />
              </span>
              <span className="btn-inner--text"> Browse</span>
            </Button>

            <Button className="py-1 border-0 h-100" size="sm" type="button" color="danger" onClick={(e) => { this.deleteAttachment(e, v.value.replace(/ /g, '_'), v.id) }} name={`${v.value.replace(/ /g, '_')}_delete_${i}`}>
              <span className="btn-inner--icon">
                <i className="fas fa-trash"></i>
              </span>
            </Button>
          </div>
        </div>
      );
    });
    if (documenttype && documenttype.documenttype && documenttype.documenttype.data) {
      documenttype.documenttype.data.map((v, i) => {
        doc_type_options[i] = { value: v.id, label: v.document_type };
      });
    }

    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <SimpleHeader name="Create Ticket" parentName="Tickets" buttons={{ 'New': () => this.reInitialState() }} />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col px-1">
              <div className={classnames({ "px-0": true, "col-md-12": this.state.e_ticket_id, "col-md-12": !this.state.e_ticket_id })}>
                <div className="card-wrapper">
                  <Card className="mb-2">
                    <CardBody>
                      <span className="search-wrapper d-flex justify-content-center align-items-center mb-3">
                        <span className="search-icon align-items-center d-flex">
                          <p className="mb-0 mr-1">Search Customers</p>
                          <img src={require('../../assets/img/icons/search.png')} height={22} width={22} className="cursor-pointer" onClick={(e) => { this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon'); }} />
                        </span>
                        <span className="search-input-wrapper w-75 d-none align-items-center">
                          <Autocomplete setStatesModalFull={(obj) => this.setStatesModalFull(obj)} additionalClasses="d-inline" suggestions={['One', 'Two', 'Three']} />
                          <span className="input-clear-icon cursor-pointer ml--4" onClick={(e) => this.slideInputLeft(e, '.search-wrapper .search-input-wrapper', '.search-wrapper .search-icon')}>&times;</span>
                        </span>
                      </span>
                      <Form className="needs-validation ticket-create-form" noValidate onSubmit={(e) => { this.validateTicketCreationForm(e) }}>
                        <div className="form-row mc-minimize" ref="ticket" onClick={(e) => this.doAction(e, "", "hide")}>
                          {this.state._ticketCreateState.map((v, i) => {
                            var rowLimit = 5;
                            if (i < rowLimit) {
                              var last_item_size = v.bs4Size;
                              let dots = '';
                              if ((i + 1) == rowLimit && this.state.collapse) {
                                dots = (<span> <label className="form-control-label invisible">More</label> <Button className="toggle d-block" color="link" size="sm" onClick={(e) => { e.preventDefault(); this.setState({ 'collapse': false }); }} > ... </Button></span>);
                              }
                              return (
                                <Col key={i} className="mb-2 d-flex" md={last_item_size}>
                                  <span className="w-100">
                                    <label className="form-control-label" htmlFor={v.name} > {v.label} </label>

                                    {(v.type == "input") ?
                                      (<><Input bsSize="sm" id={v.name} placeholder={v.label} type="text" valid={this.state.ticketCreation[v.state] === v.valid} invalid={this.state.ticketCreation[v.state] === v.invalid} onChange={e => this.ticketCreationForm(e, v.name, v.isReq, v.regex, v.regexInvalid)} value={this.state.ticketCreation && this.state.ticketCreation[v.name] || ''} /></>) : ''}

                                    {(v.type == "select" && v.multiple === true) ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])}`} isClearable placeholder={v.placeholder} styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }} components={{ Option: IconOption }} isSearchable isMulti={v.multiple} name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} /></>) : ''}

                                    {(v.type == "select" && v.multiple === false && v.name === "status") ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])}`} isClearable placeholder={v.placeholder} styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }} components={{ Option: IconOption }} isSearchable name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} value={this.state.ticketCreation && this.state.ticketCreation[`${v.name}`]} isDisabled={(v.name === "status") ? !this.state.e_ticket_id : false} /></>) : ''}

                                    {(v.type == "select" && v.multiple === false && v.name !== "status") ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])}`} isClearable placeholder={v.placeholder} styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }} components={{ Option: IconOption }} isSearchable name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} isDisabled={(v.name === "status") ? !this.state.e_ticket_id : false} /></>) : ''}

                                    {(v.type == "date") ? <ReactDatetime isValidDate={this.validDob} dateFormat={`YYYY-MM-DD`} closeOnSelect={true} className={`form-control form-control-sm p-0 h-auto ${(this.state.ticketCreation[v.state] === 'valid') ? 'is-valid' : ((this.state.ticketCreation[v.state] === 'invalid')) ? 'is-invalid' : ''}`} inputProps={{ placeholder: v.placeholder, className: `form-control form-control-sm border-0`, readOnly: true, id: `${v.name}_dates`, value: this.state.ticketCreation && this.state.ticketCreation[v.name] }} timeFormat={false} onChange={value => this.onDateChange(value, v.name, v.regex)} /> : ''}

                                    {(v.type == "button") ? <div className="form-control form-control-sm p-0 h-auto border-0"><Button className={`form-control form-control-sm border-0`} color="success" type="button" onClick={(e) => { this.doAction(e, v.label, "show") }}>{v.placeholder}</Button></div> : ''}

                                    <div className="valid-feedback"></div>
                                    <div className="invalid-feedback">
                                      {(this.state.ticketCreation[`${v.name}Feedback`]) ? this.state.ticketCreation[`${v.name}Feedback`] : v.invalidFeedback}
                                    </div>
                                  </span>
                                  {dots}
                                </Col>
                              )
                            }
                          })}
                          <Collapse isOpen={!this.state.collapse}>
                            <div className="form-row px-1">
                              {this.state._ticketCreateState.map((v, i) => {
                                var rowLimit = (!this.state.collapse) ? 5 : 5;
                                if (i >= rowLimit) {
                                  return (
                                    <Col key={i} className="mb-2" md={v.bs4Size}>
                                      <label className="form-control-label" htmlFor={v.name} > {v.label} </label>

                                      {(v.type == "input") ?
                                        (<><Input bsSize="sm" id={v.name} placeholder={v.label} type="text" valid={this.state.ticketCreation[v.state] === v.valid} invalid={this.state.ticketCreation[v.state] === v.invalid} onChange={e => this.ticketCreationForm(e, v.name, v.isReq, v.regex, v.regexInvalid)} value={this.state.ticketCreation && this.state.ticketCreation[v.name] || ''} /></>) : ''}

                                      {(v.type == "select" && v.multiple === true) ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])}`} isClearable placeholder={v.placeholder} styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }} components={{ Option: IconOption }} isSearchable isMulti={v.multiple} name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} /></>) : ''}

                                      {(v.type == "select" && v.multiple === false && v.name === "status") ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])}`} isClearable placeholder={v.placeholder} styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }} components={{ Option: IconOption }} isSearchable isMulti={v.multiple} name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} value={this.state.ticketCreation && this.state.ticketCreation[`${v.name}`]} isDisabled={(v.name === "status") ? !this.state.e_ticket_id : false} /></>) : ''}

                                      {(v.type == "select" && v.multiple === false && v.name !== "status") ? (<><Select ref={`select_${v.name}`} id={v.name} className={`form-control form-control-sm p-0 ${(this.state.ticketCreation[v.state])}`} isClearable placeholder={v.placeholder} styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }} components={{ Option: IconOption }} isSearchable isMulti={v.multiple} name={v.name} options={v.option} onChange={(value) => { this.onSelectChange(value, v.name) }} isDisabled={(v.name === "status") ? !this.state.e_ticket_id : false} /></>) : ''}

                                      {(v.type == "date") ? <ReactDatetime isValidDate={this.validDob} dateFormat={`YYYY-MM-DD`} closeOnSelect={true} className={`form-control form-control-sm p-0 h-auto ${(this.state.ticketCreation[v.state] === 'valid') ? 'is-valid' : ((this.state.ticketCreation[v.state] === 'invalid')) ? 'is-invalid' : ''}`} inputProps={{ placeholder: v.placeholder, className: `form-control form-control-sm border-0`, readOnly: true, id: `${v.name}_dates`, value: this.state.ticketCreation && this.state.ticketCreation[v.name] }} timeFormat={false} onChange={value => this.onDateChange(value, v.name, v.regex)} /> : ''}

                                      {(v.type == "button") ? <div className="form-control form-control-sm p-0 h-auto border-0"><Button className={`form-control form-control-sm border-0`} color="success" type="button" onClick={(e) => { this.doAction(e, v.label, "show") }}>{v.placeholder}</Button></div> : ''}

                                      <div className="valid-feedback"></div>
                                      <div className="invalid-feedback">
                                        {(this.state.ticketCreation[`${v.name}Feedback`]) ? this.state.ticketCreation[`${v.name}Feedback`] : v.invalidFeedback}
                                      </div>
                                    </Col>
                                  );
                                }
                              })}
                            </div>
                          </Collapse>
                        </div>

                        {(!this.state.collapse) ? (
                          <Button className={classnames({ "mt-2": true, "toggle": true, "d-none": !this.state.saveButtonHide })} size="sm" color="link" onClick={(e) => { e.preventDefault(); this.setState({ 'collapse': true }); }} > ..less </Button>) : ''}
                        {/* <Button className="mt-2 add-doc-button ml-0" size="sm" color="primary" type="button" onClick={(e) => { this.setState({ documentModal: true }); }} > Document </Button> */}
                        {/* <Button className="mt-2 add-billing-button" color="primary" size="sm" type="button" onClick={(e) => { this.openBilling() }} > Billing </Button> */}
                        {footerbuttons}
                        {this.state.e_ticket_id ? (
                          <div className="btn-group-toggle d-inline btn-group-approve" data-toggle="buttons">
                            {approveDisabled && hasTourService ? (<Button className={classnames({ 'active': this.state.approved == 1, 'mt-2': true })} color="secondary" size="sm" tag="label" disabled>
                              <input autoComplete="off" type="checkbox" value={this.state.approved == 1} />
                              {this.state.approved == 1 ? (<><i className="fas fa-check"></i> Approved</>) : `Waiting for Approval`}
                            </Button>) : (hasTourService) ? (<Button className={classnames({ 'active': this.state.approved == 1, 'mt-2': true })} color="secondary" size="sm" tag="label">
                              <input autoComplete="off" type="checkbox" value={!this.state.approved == 1} onChange={() => { const { dispatch } = this.props; const { e_ticket_id } = this.state; let ticketData = { id: e_ticket_id, approved: this.state.approved == 1 ? '0' : '1' }; dispatch(userActions.updateTicket(ticketData)); this.setState({ approved: !this.state.approved == 1 }); }} />
                              {this.state.approved == 1 ? (<><i className="fas fa-check"></i> Approved</>) : `Mark as Approved`}
                            </Button>) : ''}
                          </div>) : ''}
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </div>

              <div className={classnames({ "col-md-12": true, "px-0": true, "d-none": (!this.state.ticketCreation.serviceValue || (this.state.ticketCreation.serviceValue && this.state.ticketCreation.serviceValue.length == 0)) })} >
                {/* , "d-none": true */}
                <div className="card-wrapper">
                  <Card>
                    <CardBody>
                      <div>
                        <div className="d-flex mx-0 justify-content-between align-items-center">
                          <Nav tabs className="nav-pills border-0">
                            {servicesLinks}
                          </Nav>
                          <div className="col-md-3 px-0">
                            {serviceStatusLinks}
                          </div>
                        </div>
                        <TabContent activeTab={this.state.activeTab}>
                          {servicesPanes}
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            {this.state.e_ticket_id ? (<><a className="btn btn-primary rounded-circle btn-sm position-absolute text-white toggleCommentArea" onClick={(e) => this.toggleCommentArea(e)}><i className="fas fa-chevron-right"></i></a><div className="col-md-4 px-1">
              <div className="card-wrapper">
                <CommentSection isValidDate={this.validFuture} ticketDetails={ticketDetails} />
              </div>
            </div></>) : ''}
          </Row>

          <Modal
            id="documentModal"
            className="modal-lg modal-dialog-centered"
            isOpen={this.state.documentModal}
            toggle={() => this.toggleModal("documentModal")}
          >
            <div className="modal-header text-center">
              <h5 className="modal-title w-100" id="documentModalLabel">
                Document
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("documentModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="row m-0 p-3">
                <div className="col-5">
                  <label htmlFor="doc_type" className="form-control-label">Document Type:</label>
                  <Select ref={`select_doc_type`} id={`doc_type`} className={`form-control form-control-sm p-0`} isClearable placeholder={`Select Document Type`} styles={{ singleValue: (base) => ({ ...base, padding: 5, borderRadius: 5, color: '#8898aa', display: 'flex' }) }} components={{ SingleValue }} isSearchable isMulti={0} name={`doc_type`} options={doc_type_options} onChange={(value) => { this.onSelectChange(value, `doc_type`) }} />
                </div>
                <div className="col-3">
                  <label htmlFor="doc_type" className="form-control-label invisible">Document Type:</label>
                  <Button
                    className="doc_type_add"
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={(e) => this.addDocType(e, `doc_type`)}
                  >
                    Add Document
                  </Button>
                </div>
              </div>
              {doc_type_list}
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                size='sm'
                onClick={() => this.toggleModal("documentModal")}
              >
                Close
              </Button>
            </div>
          </Modal>

          <Modal
            backdrop="static"
            id="billingModal"
            className="modal-lg modal-dialog-centered"
            isOpen={this.state.billingModal}
            toggle={() => this.toggleModal("billingModal")}
          >
            <div className="modal-header text-center">
              <h5 className="modal-title w-100" id="billingModalLabel">
                Billing
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.closeBilling()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="table-responsive billing-list">
                <div className="accordion px-4 pt-2">
                  {billingAccordians}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {(this.state.billing_error) ? this.state.billing_error :
                (<Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  size="sm"
                  onClick={() => this.closeBilling()}
                >
                  Close
                </Button>)}
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { status, assigned, department, service, serviceFields, customer, authentication, ticket, comment, logs, documenttype, vendor, services, billing, document, notificationcreate, notificationupdate, tourCountCreate, tourCountUpdate, tourPlacesCreate, tourPlacesUpdate, tourCountlist, tourCreate, tourTypeslist, tourPlaceslist, tourUpdate, createGroupCount, updateGroupCount, tourGroupsList, tourExpensesInsert, tourExpensesUpdate, listVisaTypes, hotelExpensesInsert, hotelExpensesUpdate, hotellist, placesCreate, vehicle, vehicleStages, vehicleUpdateStages, insurance } = state;
  return { vendor, status, assigned, document, department, service, serviceFields, customer, authentication, ticket, comment, logs, documenttype, services, billing, notificationcreate, notificationupdate, tourCountCreate, tourCountUpdate, tourPlacesCreate, vehicle, tourPlacesUpdate, tourCountlist, tourCreate, tourTypeslist, tourPlaceslist, tourUpdate, createGroupCount, updateGroupCount, tourGroupsList, tourExpensesInsert, tourExpensesUpdate, listVisaTypes, hotelExpensesInsert, hotelExpensesUpdate, hotellist, placesCreate, vehicleStages, vehicleUpdateStages, insurance };
}

const connectedCreatePage = connect(mapStateToProps)(Create);
export { connectedCreatePage as Create };

// export default CreateTicket;